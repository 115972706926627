import * as React from "react";
import styles from  "./styles.module.sass";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ITabItemProps } from "./components/TabItem";
import { hot } from "react-hot-loader";
import { noop } from "../../Utils";

interface ITabNavigationProps {
  children: Array<React.ReactElement<ITabItemProps> | null>;
  disabled?: boolean;
  onChangeTab?: (tabIndex?: number) => void;
}

interface ITabNavigationState {
  currentTabIndex: number;
}

class TabNavigation extends React.Component<
  ITabNavigationProps,
  ITabNavigationState
> {
  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex: 0,
    };
  }

  public handleChange = (event, nextTabIndex) => {
    if (this.props.onChangeTab) {
      this.props.onChangeTab(nextTabIndex);
    }
    this.setState({ currentTabIndex: nextTabIndex });
  };
  public handleChangeIndex = (nextTabIndex) => {
    this.setState({ currentTabIndex: nextTabIndex });
  };

  public render() {
    return (
      <div className={styles.root}>
        <Tabs
          value={this.state.currentTabIndex}
          onChange={this.props.disabled ? noop : this.handleChange}
          variant="fullWidth"
          classes={{
            indicator: styles.indicator,
          }}
        >
          {React.Children.map(
            this.props.children as any,
            (child: React.ReactElement<ITabItemProps>) => {
              return (
                child && (
                  <Tab
                    className={styles.tab}
                    classes={{
                      selected: styles.selectedTab,
                    }}
                    label={child.props.title}
                  />
                )
              );
            }
          )}
        </Tabs>
        {React.Children.map(this.props.children, (child, index) => {
          if (index === this.state.currentTabIndex) {
            return child;
          }
          return null;
        })}
      </div>
    );
  }
}

export default hot(module)(TabNavigation);
