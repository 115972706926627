import { ACTION_PASS_ON_FORM_VALUES } from "../../constants";
import { select, takeEvery } from "redux-saga/effects";
import { selectForm } from "../selectors";
import { IPassOnFormValuesAction } from "../../types/wizard-types";
function* passOnFormValuesSaga(action: IPassOnFormValuesAction) {
  const formValues = yield select(selectForm);
  action.payload(formValues);
}

export function* watchPassOnFormValuesSaga() {
  yield takeEvery(ACTION_PASS_ON_FORM_VALUES, passOnFormValuesSaga);
}
