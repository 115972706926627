import axios from "./axios";
import * as Requests from "./requests";

export const getSuggestedModifierGroups = (token: string) => {
  return axios.get(Requests.ORDERING_MENU_MODIFIER_GROUPS_API, {
    headers: {
      token,
    },
  });
};
