export default {
  accepted: "ACCEPTED",
  got_picked_up: "GOT PICKED UP",
  ready_for_pickup: "READY FOR PICKUP",
  rejected: "REJECTED",
  received: "RECEIVED",
  noOrders: "No orders have been found",
  seen: "SEEN",
  noOrderHistory: "No order history",
  customLabel: "Custom:",
  sent: "SENT",
  canceled_by_agent: "CANCELED",
  canceled_by_customer: "CANCELED BY CUSTOMER",
  canceled_by_cashier: "CANCELED",
  scheduled: "SCHEDULED",
  customer_no_show: "CUSTOMER NO SHOW",
  delivered: "DELIVERED",
  out_for_delivery: "OUT FOR DELIVERY",
  connecting: "Connecting...",
  analytics: "Analytics",
  title: "Web portal orders",
  qr_title: "QR portal orders ({{count}})",
  placeholder: "Search by order id, phone number",
  time: "Time",
  order_types: "Order Types",
  name: "Name",
  orderId: "Order ID",
  branch: "Branch",
  pickDate: "Pick Date",
  brand: "Brand",
  price: "Price",
  order_state: "Order State",
  timeF: "Time:",
  branchF: "Branches:",
  order_types_F: "Order Types:",
  all: "All",
  today: "Today",
  yesterday: "Yesterday",
  _3Days: "3 days",
  _7Days: "7 days",
  _30Days: "30 days",
  custom: "Custom",
  pickup: "Pickup",
  ordering: "Ordering",
  portalOrdering: "Portal ordering",
  portalVisits: "Portal visits",
  addedItems: "Added items",
  tooltipContent: "converted users from the previous step",
  ordersCreated: "Orders created",
  ordersCompleted: "Orders completed",
  tooltip:
    "Your customers can make delivery & pickup order from this link through the web browser",
  delivery: "Delivery",
  firstStep: "First step:",
  secondStep: "Second step:",
  copyLink: "Copy Link",
  preview: "Preview",
  firstStepPh: "Copy the link below and past it on your social media pages",
  secondStepPh: "Publish web portal",
  switchTitle: "Web portal status",
  published: "Published",
  unpublished: "Not published",
  canceled_by_courier: "CANCELED",
  rejected_for_payment_failure: "REJECTED FOR PAYMENT FAILURE"
};
