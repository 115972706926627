import { IStoreViralitySettings } from "../../types/wizard-types";
import axios from "../axios";
import { storeViralitySettingsURL } from "../requests";
export const updateStoreViralitySettingsApi = (
  token: string,
  payload: IStoreViralitySettings
) => {
  return axios.put(storeViralitySettingsURL, payload, {
    headers: { token },
  });
};
