import { DownOutlined } from "@ant-design/icons";
import { Slider, Menu, Dropdown, Button, InputNumber } from "antd";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import "./RangePicker.less";
import Strings from "../../i18n/Manager/strings/home";
import { useTranslation } from "../../shopx-shared-components/hooks/useTranslation";
import i18n from "../../i18n/i18n";
import styles from "./styles.module.sass";
import { CURRENCIES } from "../../Utils";

interface RangePickerProps {
  label: string;
  min?: number;
  max?: number;
  from: number;
  to: number;
  setValues: Function;
  withApply?: boolean;
  width?: string;
  prefix?: string;
  step?: number;
  country_iso_code?: string;
}

export const RangePicker: React.FC<RangePickerProps> = ({
  label,
  max = 100,
  min = 0,
  from,
  to,
  setValues,
  width,
  withApply = true,
  prefix = "",
  step,
  country_iso_code,
}) => {
  const { t } = useTranslation("home");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [internalFrom, setInternalFrom] = useState<number>(from);
  const [internalTo, setInternalTo] = useState<number>(to);
  const [inputFocused, setInputFocused] = useState({ to: false, from: false });
  const setExternalValues = () => {
    if (internalFrom > min && internalTo < max) {
      setValues([internalFrom, internalTo]);
    } else {
      const newInternalFrom = internalFrom < min ? min : internalFrom;
      const newInternalTo = internalTo > max ? max : internalTo;
      setValues([newInternalFrom, newInternalTo]);
    }
  };

  useEffect(() => {
    setInternalFrom(from);
  }, [from]);

  useEffect(() => {
    setInternalTo(to);
  }, [to]);

  useEffect(() => {
    if (!isOpen && !withApply) {
      setExternalValues();
    }
  }, [isOpen]);

  const applyChanges = () => {
    setExternalValues();
    setIsOpen(false);
  };
  const currency = useMemo(() => {
    if (country_iso_code)
      return i18n.language === "ar"
        ? CURRENCIES.ar[country_iso_code]
        : CURRENCIES.en[country_iso_code];
    else return undefined;
  }, [country_iso_code]);
  return (
    <>
      <Dropdown
        onVisibleChange={() => setIsOpen(!isOpen)}
        trigger={["click"]}
        visible={isOpen}
        placement="bottomRight"
        overlayStyle={{ background: "#fff" }}
        overlay={
          <Menu
            style={{
              minWidth: 266,
              background: "#fff !important",
              padding: ".5px 0 5px 0",
              boxShadow:
                "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
            }}
          >
            <Menu.Item className="range-item">
              <div>
                <Slider
                  range={{ draggableTrack: true }}
                  min={min}
                  max={max}
                  step={step ?? max / 100}
                  value={[internalFrom, internalTo]}
                  onChange={(v) => {
                    if (v[0] !== internalFrom) {
                      setInternalFrom(v[0]);
                    }

                    if (v[1] !== internalTo) {
                      setInternalTo(v[1]);
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "18px 0",
                  }}
                >
                  <div className={styles.inputWrapper}>
                    <div
                      className={styles.inputWithLabel}
                      style={{
                        minWidth: inputFocused["from"] ? "165.5px" : "166.5px",
                        border: inputFocused["from"]
                          ? "1px solid #ffbd7a"
                          : "none",
                        backgroundColor: inputFocused["from"]
                          ? "#fff"
                          : "#f2f2f7",
                      }}
                    >
                      <span className={styles.label}>From:</span>
                      <InputNumber
                        autoFocus
                        className="range-number-input"
                        size="small"
                        prefix={"From:"}
                        min={min}
                        max={max}
                        value={internalFrom}
                        style={{
                          backgroundColor: inputFocused["from"]
                            ? "#fff"
                            : "#f2f2f7",
                        }}
                        onFocus={() =>
                          setInputFocused({
                            to: false,
                            from: true,
                          })
                        }
                        onBlur={() =>
                          setInputFocused((prevState) => ({
                            ...prevState,
                            from: false,
                          }))
                        }
                        onChange={(value) =>
                          setInternalFrom(
                            value !== NaN && value ? value : internalFrom
                          )
                        }
                        inputMode="numeric"
                      />
                      {currency && (
                        <span className={styles.currency}>{currency}</span>
                      )}
                    </div>
                  </div>
                  <div style={{ margin: "0 5px" }} />
                  <div className={styles.inputWrapper}>
                    <div
                      className={styles.inputWithLabel}
                      style={{
                        minWidth: inputFocused["to"] ? "165.5px" : "166.5px",
                        border: inputFocused["to"]
                          ? "1px solid #ffbd7a"
                          : "none",
                        backgroundColor: inputFocused["to"]
                          ? "#fff"
                          : "#f2f2f7",
                      }}
                    >
                      <span className={styles.label}>To:</span>
                      <InputNumber
                        className="range-number-input"
                        size="small"
                        prefix={"To:"}
                        style={{
                          backgroundColor: inputFocused["to"]
                            ? "#fff"
                            : "#f2f2f7",
                        }}
                        min={internalFrom}
                        max={max}
                        value={internalTo}
                        onChange={(value) =>
                          setInternalTo(
                            value !== NaN && value ? value : internalTo
                          )
                        }
                        inputMode="numeric"
                        onFocus={() =>
                          setInputFocused({
                            to: true,
                            from: false,
                          })
                        }
                        onBlur={() =>
                          setInputFocused((prevState) => ({
                            ...prevState,
                            to: false,
                          }))
                        }
                      />{" "}
                      {currency && (
                        <span className={styles.currency}>{currency}</span>
                      )}
                    </div>
                  </div>
                </div>
                {withApply && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="padding-10px"
                      size="small"
                      onClick={() => {
                        setInternalFrom(min);
                        setInternalTo(max);
                      }}
                    >
                      {t(Strings.reset)}
                    </Button>

                    <Button
                      className="padding-10px mr-10px"
                      disabled={from === internalFrom && to === internalTo}
                      size="small"
                      type="link"
                      onClick={applyChanges}
                    >
                      {t(Strings.apply)}
                    </Button>
                  </div>
                )}
              </div>
            </Menu.Item>
          </Menu>
        }
      >
        <Button
          style={
            width
              ? {
                  width: width,
                  justifyContent: "space-between",
                }
              : {}
          }
        >
          <span style={{ marginLeft: ".5rem" }}>{label}</span>
          <img
            style={{
              width: "14px",
              height: "14px",
              margin: i18n.language === "ar" ? "0 8px 0 0" : "0 0 0 8px",
              transform: isOpen ? "rotate(180deg)" : undefined,
            }}
            src={require("../../assets/feather _ chevron-down.svg").default}
          />
        </Button>
      </Dropdown>
    </>
  );
};
