import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getRecommendedGiftApi } from "../../../axios";
import { getRecommendedGiftAction } from "../../../constants";
import { IRecommendedGift } from "../../../types/wizard-types";
import {
  getRecommendedGiftFailure,
  getRecommendedGiftSuccess,
} from "../../actions";
import { selectToken } from "../../selectors";
function* getRecommendedGiftSaga() {
  try {
    const token: string = yield select(selectToken);
    const res: AxiosResponse<IRecommendedGift> = yield call(
      getRecommendedGiftApi,
      token
    );
    yield put(getRecommendedGiftSuccess(res.data));
  } catch (error) {
    yield put(getRecommendedGiftFailure(error as Error));
  }
}

export function* watchGetRecommendedGiftSaga() {
  yield takeLatest(getRecommendedGiftAction.requested, getRecommendedGiftSaga);
}
