import React, { useState } from "react";
import styles from "./styles.module.sass";
import { CustomerType, Filters, Gender, TimeKeys } from "../..";
import { TimeFilter } from "../../../../Home/components";
import MultiSelect, {
  mapStringArryToSelectedArray,
} from "../../../../../../../@koinz-ui/MultiSelect";
import { useSelect } from "../../../../../../../hooks/useSelect";
import { RangePicker } from "../../../../../../../@koinz-ui/RangePicker";
import { Button, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useTranslation } from "../../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../../i18n/Manager/strings/insights";
import i18n from "../../../../../../../i18n/i18n";

interface IProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  closeDrawer: Function;
  setTimeKeys: React.Dispatch<React.SetStateAction<any>>;
  timeKeys: TimeKeys;
}

export const AllFiltersDrawerContent: React.FC<IProps> = ({
  filters,
  setFilters,
  closeDrawer,
  setTimeKeys,
  timeKeys,
}) => {
  const { allBranches } = useSelect((state) => state.branchesReducer);
  const { country_iso_code } = useSelect((state) => state.storeReducer.store);
  const [filtersCopy, setFiltersCopy] = useState<Filters>(filters);
  const [timeKeysCopy, setTimeKeysCopy] = useState<TimeKeys>(timeKeys);
  const { t } = useTranslation("insights");

  React.useEffect(() => {
    setFiltersCopy(filters);
  }, []);

  const setFilterCopyValue = (key, value) => {
    const copyOfFilters = { ...filtersCopy };
    copyOfFilters[key] = value;

    if (key === "customer_type" && value === CustomerType.nonAppUser) {
      copyOfFilters["include_non_aged"] = 1;
      copyOfFilters["age"] = [16, 65];
      copyOfFilters["gender"] = undefined;
    }

    setFiltersCopy(copyOfFilters);
  };

  // branch filter
  const handleBranchFilterMultiSelectChange = (values) => {
    if (values.length === allBranches.length) {
      setFilterCopyValue("branch_ids", []);
    } else {
      setFilterCopyValue(
        "branch_ids",
        values.map((v) => v.id)
      );
    }
  };

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  return (
    <div className={styles.row}>
      <section className={styles.notLastSection}>
        <h3 className={styles.title}>{t(Strings.general)}</h3>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.branch)}</span>
          <MultiSelect
            label=""
            width="360px"
            canSelectAll={true}
            onApply={handleBranchFilterMultiSelectChange}
            options={branchesOptions}
            selected={
              filtersCopy.branch_ids && filtersCopy.branch_ids.length > 0
                ? mapStringArryToSelectedArray(filtersCopy.branch_ids || [])
                : allBranches.map((b) => ({ id: b.id }))
            }
          />
        </div>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.ticket_size)}</span>
          <RangePicker
            country_iso_code={country_iso_code}
            width="360px"
            label={`${
              filtersCopy.average_ticket_size[0] === 0 &&
              filtersCopy.average_ticket_size[1] === 1000
                ? t(Strings.all)
                : `${filtersCopy.average_ticket_size[0]} - ${filtersCopy.average_ticket_size[1]} `
            }`}
            min={0}
            max={10000}
            from={filtersCopy.average_ticket_size[0]}
            to={filtersCopy.average_ticket_size[1]}
            setValues={(value) =>
              setFilterCopyValue("average_ticket_size", value)
            }
            withApply={false}
          />
        </div>
      </section>

      {/* ######### */}
      <section className={styles.notLastSection}>
        <h3 className={styles.notFirstTitle}>{t(Strings.customerSegment)}</h3>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.customerType)}</span>
          <Select
            value={filtersCopy.customer_type || ""}
            style={{ width: 360 }}
            onChange={(val) => {
              setFilterCopyValue("customer_type", (val as CustomerType) || "");
            }}
          >
            <Select.Option value={""}>{t(Strings.all)}</Select.Option>
            <Select.Option value={CustomerType.appUser}>
              {t(Strings.appCustomer)}
            </Select.Option>
            <Select.Option value={CustomerType.nonAppUser}>
              {t(Strings.nonAppCustomer)}
            </Select.Option>
          </Select>
        </div>

        {filtersCopy.customer_type !== CustomerType.nonAppUser && (
          <div className={`${styles.input} square-input`}>
            <span className="input-title">{t(Strings.customerGender)}</span>
            <Select
              value={filtersCopy.gender || ""}
              style={{ width: 360 }}
              onChange={(val) => {
                setFilterCopyValue("gender", (val as Gender) || "");
              }}
            >
              <Select.Option value={""}>{t(Strings.all)}</Select.Option>
              <Select.Option value={Gender.male}>
                {t(Strings.male)}
              </Select.Option>
              <Select.Option value={Gender.female}>
                {t(Strings.female)}
              </Select.Option>
            </Select>
          </div>
        )}

        {filtersCopy.customer_type !== CustomerType.nonAppUser && (
          <div className={`${styles.input} square-input`}>
            <span className="input-title">{t(Strings.customerAge)}</span>
            <RangePicker
              width="360px"
              label={`${
                filtersCopy.age[0] === 16 && filtersCopy.age[1] === 65
                  ? t(Strings.all)
                  : `${filtersCopy.age[0]} - ${filtersCopy.age[1]} `
              }`}
              min={16}
              max={65}
              from={filtersCopy.age[0]}
              to={filtersCopy.age[1]}
              setValues={(value) => setFilterCopyValue("age", value)}
              withApply={false}
              step={1}
            />
          </div>
        )}

        <div className={styles.additionalInput}>
          <Checkbox
            disabled={filtersCopy.customer_type === CustomerType.nonAppUser}
            checked={filtersCopy.include_non_aged === 1 ? true : false}
            onChange={(e) =>
              setFilterCopyValue("include_non_aged", e.target.checked ? 1 : 0)
            }
          >
            <span className={styles.additionalInputText}>
              {t(Strings.usersWithUnregisteredAge)}
            </span>
          </Checkbox>
        </div>
      </section>

      {/* ######### */}
      <section className={styles.section}>
        <h3 className={styles.notFirstTitle}>{t(Strings.moreAdvanced)}</h3>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.totalVisits)}</span>
          <RangePicker
            width="360px"
            label={`${
              filtersCopy.visits_count[0] === 1 &&
              filtersCopy.visits_count[1] === 50
                ? "All"
                : `${filtersCopy.visits_count[0]} - ${filtersCopy.visits_count[1]} `
            }`}
            min={0}
            max={500}
            from={filtersCopy.visits_count[0]}
            to={filtersCopy.visits_count[1]}
            setValues={(value) => setFilterCopyValue("visits_count", value)}
            withApply={false}
            step={1}
          />
        </div>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.totalPoints)}</span>
          <RangePicker
            width="360px"
            label={`${
              filtersCopy.total_points[0] === 1 &&
              filtersCopy.total_points[1] === 5000
                ? "All"
                : `${filtersCopy.total_points[0]} - ${filtersCopy.total_points[1]} `
            }`}
            min={0}
            max={50000}
            from={filtersCopy.total_points[0]}
            to={filtersCopy.total_points[1]}
            setValues={(value) => setFilterCopyValue("total_points", value)}
            withApply={false}
          />
        </div>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.redemptions)}</span>
          <Select
            value={filtersCopy.redemptions_count || ""}
            style={{ width: 360 }}
            onChange={(val) => {
              setFilterCopyValue("redemptions_count", val);
            }}
          >
            <Select.Option value={""}>{t(Strings.all)}</Select.Option>
            <Select.Option value={"1+"}>
              {t(Strings.onePlusRedemption)}
            </Select.Option>
            <Select.Option value={"0"}>{t(Strings.noRedemption)}</Select.Option>
          </Select>
        </div>
        <div className={`${styles.input} square-input`}>
          <span className="input-title">{t(Strings.lastVisit)}</span>
          <TimeFilter
            width={"360px"}
            placement="topLeft"
            withoutLabel={true}
            valueKey={timeKeysCopy.last_visit_timestamp}
            value={
              filtersCopy && filtersCopy.last_visit_timestamp
                ? {
                    from: filtersCopy?.last_visit_timestamp[0],
                    to: filtersCopy?.last_visit_timestamp[1],
                  }
                : undefined
            }
            onChange={(val, key) => {
              setTimeKeysCopy((prev) => ({
                ...prev,
                last_visit_timestamp: key,
              }));
              setFilterCopyValue("last_visit_timestamp", [val.from, val.to]);
            }}
          />
        </div>
      </section>
      {/*  */}

      <section className={styles.bottom}>
        <Button
          type="primary"
          className={styles.button}
          onClick={() => {
            setTimeKeys(timeKeysCopy);
            setFilters({ ...filtersCopy, page: 0 });
            closeDrawer();
          }}
        >
          {t(Strings.applyFilters)}
        </Button>
      </section>
    </div>
  );
};
