import {
  welcome,
  completeSteps,
  chooseLanguage,
  next,
  terms,
  progressAutoSave,
  estimatedTime,
  termsBtn,
} from "../strings/chooseLanguage";

export default {
  [welcome]: "مرحباً بك!",
  [completeSteps]: "أكمل الخطوات الآتية لبدء تجربتك",
  [chooseLanguage]: "من فضلك اختر اللغة",
  [next]: "التالي",
  [terms]: "بالضفط على التالى تكون قد وافقت على ",
  [termsBtn]: "الشروط والأحكام",
  [progressAutoSave]: "سيتم حفظ تقدمك تلقائيًا",
  [estimatedTime]: "الوقت المقدر للإكمال: 1 يوم",
};
