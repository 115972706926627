import { ISetMoreAction } from "../../types";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";

export interface IAddMoreReducerState {
  addMore: boolean;
}

const ADD_MORE = "addMore";

const initialState: any = Map({
  [ADD_MORE]: false,
}).toJS();

export default (
  state = initialState,
  action: ISetMoreAction
): IAddMoreReducerState => {
  switch (action.type) {
    case constants.ACTION_SET_MORE: {
      return mutateState(state, (map) => {
        map.set(ADD_MORE, (action as ISetMoreAction).payload);
      });
    }
    default:
      return state;
  }
};
