import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getBrandTags } from "../../axios/getBrandTags";
import * as actions from "../actions/StoreSettings";
import { handleOptimism } from "./handleOptimism";

function* getBrandTagsSaga() {
  const token = yield select(selectToken);
  const res = yield call(getBrandTags, token);
  return (res.data || { store_tags: [] }).store_tags;
}

export function* watchGetBrandTagsSaga() {
  yield takeLatest(
    actions.getBrandTags.type,
    handleOptimism(getBrandTagsSaga, {
      success: actions.getBrandTagsSuccess as any,
      failure: actions.getBrandTagsFailure as any,
    })
  );
}
