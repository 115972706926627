import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import styles from "./styles.module.sass";
import { Input, Spin, Table } from "antd";
import Strings from "../../../i18n/Manager/strings/insights";
import { useTranslation } from "../../../shopx-shared-components/hooks/useTranslation";
import { numberFormatter } from "../../../Utils/numbers";
import { LoadingOutlined } from "@ant-design/icons";
import { Search } from "react-feather";
import moment from "moment";
import i18n from "../../../i18n/i18n";
import { useDispatch } from "react-redux";
import { useSelect } from "../../../hooks/useSelect";
import { Queries } from "../../../Queries/Manager";
import StoreData from "../../../contexts/StoreData";
import customer_pic_placeholder from "../../../assets/user-placeholder.svg";
import { Select } from "antd";
import { getBranches } from "../../../redux-store/actions";
import { useIsVisible } from "react-is-visible";
import ProfileModal from "../../../Components/Leaderboard/ProfileModal";

const { Option } = Select;

interface IProps {}

// order_by { undefined: last visit, 0: total points, 1: current points, 2: visits}
interface IFilters {
  page: number;
  store_id: string;
  branch_id?: string;
  order_by?: number;
  query?: string;
}

const columns = [
  {
    title: "Customer",
    dataIndex: "",
    key: "",
    render: (customer) => (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          style={{
            height: "32px",
            width: "32px",
            borderRadius: "50%",
            marginRight: "8px",
          }}
          src={
            customer?.image
              ? `${process.env.REACT_APP_ENDPOINT}${customer?.image}`
              : customer_pic_placeholder
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              color: "1c1c1e",
              marginBottom: "0px",
            }}
          >
            {customer?.name}
          </p>
          <p
            style={{
              fontSize: "12px",
              color: "#8e8e93",
              marginBottom: "0px",
            }}
          >
            {moment(customer?.last_operation_time)
              .locale(i18n.language)
              .format("DD MMM YYYY, hh:mm")}
          </p>
        </div>
      </div>
    ),
    width: 250,
  },
  {
    title: "Visits",
    dataIndex: "visits",
    key: "",
    width: 250,
  },
  {
    title: "Current Points",
    dataIndex: "points",
    key: "",
    width: 250,
  },
  {
    title: "Life Time Points",
    dataIndex: "total_purchasing",
    key: "",
    width: 250,
  },
];

const CustomerReceipts: React.FC<IProps> = (props) => {
  const { t } = useTranslation("insights");

  const dispatch = useDispatch();
  const seeMoreIndicatorRef = useRef(null);
  const seeMoreIndicatorIsVisible = useIsVisible(seeMoreIndicatorRef);
  const [seeMore, setSeeMore] = useState(false);
  const [customerPopupId, setCustomerPopupId] = useState<string | undefined>();
  const [customerPopup, setCustomerPopup] = useState<boolean>(false);

  const [customerData, setCustomerData] = useState<any[]>([]);

  const { token } = useSelect((state) => state.authReducer);

  const [filters, setFilters] = useState<IFilters>({
    page: 0,
    store_id: "",
  });

  const { data, status } = Queries.useGetCustomerReceipts(token, filters);
  const { data: totalCustomers, status: totalCustomersLoadingStatus } =
    Queries.useGetCustomerInsightsTotal(token, {});

  const { storeData } = useContext(StoreData);
  const { allBranches } = useSelect((state) => state.branchesReducer);

  // getting branches if it doesn't exist on first render
  useEffect(() => {
    if (allBranches && allBranches.length === 0) {
      dispatch(getBranches());
    }
  }, []);

  // setting seeMore when user scrolls to end of the table
  useEffect(() => {
    if (!seeMore && seeMoreIndicatorIsVisible) setSeeMore(true);
    else setSeeMore(false);
  }, [seeMoreIndicatorIsVisible]);

  // setting store_id in filters
  useEffect(() => {
    const store_id = storeData?.id;
    if (store_id && !filters.store_id) {
      setFilters((prevState) => ({ ...prevState, store_id }));
    }
  }, [storeData]);

  // settingCustomer data when data is loaded
  useEffect(() => {
    if (
      status === "success" &&
      data &&
      (filters.query || filters.branch_id || filters.order_by)
    ) {
      setCustomerData([...(Array.isArray(data) ? data : data?.customers)]);
    } else if (status === "success" && data && data.customers) {
      if (filters.page > 0) {
        setCustomerData((prevState) => [...prevState, ...data?.customers]);
      } else {
        setCustomerData([...data?.customers]);
      }
    }
  }, [status, data]);

  // handle see more
  useEffect(() => {
    if (seeMore) {
      setFilters((prevState) => ({
        ...prevState,
        page: prevState.page + 50,
      }));
      setSeeMore(false);
    }
  }, [seeMore]);

  const [filterSearchText, setFilterSearchText] = useState("");

  const handleSearchInputChange = (e) => {
    const val = e.target.value;
    setFilterSearchText(val);
    if (val === "")
      setFilters((prevState) => ({ ...prevState, query: undefined }));
  };

  const applySearchFilter = () => {
    setFilters((prevState) => ({
      ...prevState,
      query: filterSearchText ? filterSearchText : undefined,
      page: 0,
    }));
  };

  const searchInputLoading = Boolean(filters.query) && status === "loading";

  const SearchIconJSX = (
    <Search color="#8E8E93" size={18} className={styles.searchInputIcon} />
  );
  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  const branchOptions = useMemo(() => {
    return allBranches.map((branch) => (
      <Option value={branch.id} key={branch.id}>
        {i18n.language.includes("en") ? branch.en_name : branch.ar_name}
      </Option>
    ));
  }, [allBranches]);

  const sortByCriteria = [
    {
      title: [t(Strings.lastVisit)],
      value: "",
    },
    {
      title: [t(Strings.totalPoints)],
      value: 0,
    },
    {
      title: [t(Strings.current_points)],
      value: 1,
    },
    {
      title: [t(Strings.visits)],
      value: 2,
    },
  ];

  const sortByOptions = useMemo(() => {
    return sortByCriteria.map((element) => (
      <Option key={element.value} value={element.value}>
        {element.title}
      </Option>
    ));
  }, [sortByCriteria]);

  useEffect(() => {
    if (customerPopupId) setCustomerPopup(true);
    else setCustomerPopup(false);
  }, [customerPopupId]);

  return (
    <div className={styles.wrapper}>
      {/* Screen Title */}
      <div className={styles.heading}>
        <span className={styles.headingText}>{t(Strings.customers)}</span>
      </div>
      {/* customers table */}
      <div className={styles.tableWrapper}>
        <div className={styles.tableHead}>
          <div className={styles.title}>
            {t(Strings.all_customers, {
              customer_counts:
                totalCustomersLoadingStatus === "success"
                  ? numberFormatter(Number(totalCustomers?.total))
                  : 0,
            })}
          </div>
          <div className={styles.searchAndActions}>
            <div className={styles.search}>
              <Input
                size="small"
                placeholder={t(Strings.search)}
                value={filterSearchText}
                onPressEnter={applySearchFilter}
                onChange={handleSearchInputChange}
                disabled={searchInputLoading}
                suffix={
                  searchInputLoading && <Spin indicator={CustomSpinner} />
                }
                prefix={SearchIconJSX}
              />
            </div>
          </div>
        </div>
        {/* filters */}
        <div className={styles.filtersWrapper}>
          {/* branches filter */}
          <Select
            defaultValue=""
            style={{ width: "150px", marginRight: "12px" }}
            onChange={(val) =>
              setFilters((prevState) => ({
                ...prevState,
                page: 0,
                branch_id: val === "" ? undefined : val,
              }))
            }
          >
            <Option value={""}>{t(Strings.all)}</Option>
            {branchOptions}
          </Select>
          {/* sort by filter */}
          <Select
            defaultValue=""
            style={{ width: "150px", marginRight: "12px" }}
            onChange={(val) =>
              setFilters((prevState) => ({
                ...prevState,
                page: 0,
                order_by: typeof val === "number" ? val : undefined,
              }))
            }
          >
            {sortByOptions}
          </Select>
        </div>
        {/* rows */}
        <div className={styles.tableBody} id="customer-list-table">
          <Table
            columns={columns}
            pagination={false}
            loading={
              status === "loading" && filters.page === 0
                ? { indicator: CustomSpinner }
                : false
            }
            dataSource={customerData}
            onRow={(row) => ({
              onClick: () => {
                setCustomerPopupId(row.id);
              },
            })}
          />
        </div>
      </div>
      {customerPopup && customerPopupId && (
        <ProfileModal
          customer={{ id: customerPopupId }}
          close={() => {
            setCustomerPopupId(undefined);
          }}
          modal={customerPopup}
        />
      )}
      {customerData.length < filters.page + 50 ? null : (
        <div
          ref={seeMoreIndicatorRef}
          style={{
            width: "180px",
            height: "25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "10px",
            position: "absolute",
            bottom: "5px",
            backgroundColor: "black",
          }}
        ></div>
      )}
    </div>
  );
};

export default CustomerReceipts;
