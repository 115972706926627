import { action, payload } from "ts-action";
import { genericAction } from "../../../../../../constants";
import { IGetSingleBranch } from "../../../../../../types/shared";

export const getBranchDataForEditAction = genericAction(
  "ACTION_GET_BRANCH_DATA_FOR_EDIT"
);

export const getBranchDataForEdit = action(
  getBranchDataForEditAction.requested,
  payload<{ branch_id: string }>()
);
export const getBranchDataForEditSuccess = action(
  getBranchDataForEditAction.fulfilled,
  payload<IGetSingleBranch>()
);
export const getBranchDataForEditFailure = action(
  getBranchDataForEditAction.rejected,
  payload<any>()
);

export const clearBranchDataForEdit = action(
  "ACTION_CLEAR_BRANCH_DATA_FOR_EDIT"
);
