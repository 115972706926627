import React from "react";
import styles from "./styles.module.sass";
import { useSelect } from "../../../../hooks/useSelect";

type AppSwitcherProps = {
  isWhiteLabeled: boolean;
  onToggleActive: (active: boolean) => void;
  className?: string;
};
export const AppSwitcher: React.FC<AppSwitcherProps> = ({
  isWhiteLabeled,
  onToggleActive,
  className,
}) => {
  const { store } = useSelect((state) => state.storeReducer);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div
        onClick={() => onToggleActive(false)}
        className={isWhiteLabeled ? styles.inactive : styles.active}
      >
        Koinz
      </div>
      <div
        onClick={() => onToggleActive(true)}
        className={isWhiteLabeled ? styles.active : styles.inactive}
      >
        {store?.name}
      </div>
    </div>
  );
};

export default AppSwitcher;
