import { reducer, on } from "ts-action";
import {
  updateStoreWorkingHours,
  updateStoreWorkingHoursFailure,
  updateStoreWorkingHoursSuccess,
} from "../../../actions/Manager";

interface IState {
  isLoading: boolean;
  error: null | Error;
}

const initialState: IState = {
  isLoading: false,
  error: null,
};

export const updateStoreWorkingHoursReducer = reducer<IState>(
  [
    on(updateStoreWorkingHours, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    })),
    on(updateStoreWorkingHoursSuccess, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }),
    on(updateStoreWorkingHoursFailure, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }),
  ],
  initialState
);
