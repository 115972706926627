import React from "react";
import styles from "./styles.module.sass";
import Arrow from "../../icons/Arrow";
import { useRtlClass } from "../../../../lib";
import i18n from "../../../../i18n/i18n";
const SubMenuTitle: React.FC<{
  icon: React.ReactNode;
  title?: string;
  collapsed?: boolean;
  clicked: boolean;
}> = ({ title, icon, collapsed, clicked }) => {
  return (
    <div
      style={{ padding: i18n.language === "ar" ? "26px" : "15px" }}
      className={`${
        collapsed
          ? clicked
            ? styles.dropdownSelected
            : styles.dropdownWrapper
          : styles.dropdown
      } ${useRtlClass(styles)}`}
    >
      <div className={styles.dropdownContent}>
        {collapsed ? (
          <React.Fragment>
            {/* <div
              className={expend ? styles.arrowLeftClicked : styles.arrowLeft}
            >
              <Arrow clicked={clicked} />
            </div> */}
            <div style={{ display: "flex" }}>
              <div className={styles.iconDropdown}>{icon}</div>
              <span className={styles.dropdownTitle}>{title}</span>
            </div>
          </React.Fragment>
        ) : (
          <div className={clicked ? styles.clicked : undefined}>{icon}</div>
        )}
      </div>
    </div>
  );
};

export default SubMenuTitle;
