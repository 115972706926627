import axios from "./axios";
import * as Requests from "./requests";

export const downloadExcelSheetApi = (token: string) => {
  return axios.get(Requests.DOWNLOAD_EXCEL_SHEET, {
    headers: {
      token,
    },
  });
};
