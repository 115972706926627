import axios from "../axios/axios";
import Pusher from "pusher-js";
import { pusher } from "./pusher";

const disconnectSocket = (socket: Pusher, channel_name) => {
  socket.unsubscribe(channel_name);
  socket.unbind_all();
  socket.disconnect();
};

export const authenticateSocket = (
  token: string,
  store_id: string,
  isLoggedIn: boolean,
  channel_name: string,
  isOnline: boolean
) => {
  const socket = pusher(token, store_id);
  if (isLoggedIn && store_id && isOnline) {
    socket.connection.bind("pusher:connection_established", (data) => {
      return axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/pusher/manager/auth`,
        {
          socket_id: data.socket_id,
          channel_name: `${channel_name}.${store_id}`,
        },
        {
          headers: { token },
        }
      );
    });
  } else {
    disconnectSocket(socket, channel_name);
  }
};
