type PickupIconProps = {
  color: string;
};
export const PickupIcon = ({ color }: PickupIconProps) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.12"
        d="M9.87201 20.264C14.9619 20.264 19.088 16.1379 19.088 11.048C19.088 5.95817 14.9619 1.83203 9.87201 1.83203C4.78215 1.83203 0.656006 5.95817 0.656006 11.048C0.656006 16.1379 4.78215 20.264 9.87201 20.264Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2552 5.96325C16.1417 6.2874 15.7869 6.45824 15.4627 6.34476C15.1386 6.23127 14.9678 5.87649 15.0812 5.55234C15.1948 5.22819 15.5495 5.05735 15.8738 5.17083C16.1978 5.28432 16.3687 5.6391 16.2552 5.96325Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.412 5.40527L14.7764 5.63007L14.9557 6.18696L15.4466 6.07368L15.412 5.40527Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64031 5.78945C8.64031 5.78945 8.45709 6.96719 8.7019 8.22395C8.78175 8.63465 9.16388 8.91537 9.57963 8.86925L9.93989 8.82933L9.58809 5.43848L8.64031 5.78945Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85397 3.7666C9.85397 3.7666 9.23126 3.8677 8.79714 4.57883C8.51344 5.04338 8.37932 5.56942 8.32052 5.8782C8.29349 6.02036 8.39541 6.15417 8.53964 6.16562L9.61442 6.25197L9.85397 3.7666Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 15.8789L9.52783 16.56L10.0398 17.2072L11.0683 16.5824L10.59 15.8789Z"
        fill="#F6EBE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51402 16.3931L8.96085 16.7545C8.87842 16.8263 8.86387 16.949 8.92752 17.038L10.435 19.1446C10.4827 19.2115 10.5863 19.196 10.6124 19.1181L10.6138 19.114C10.6825 18.9094 10.6717 18.6874 10.5877 18.4886C10.4442 18.1491 10.26 17.5752 10.408 17.1177C10.4346 17.0354 10.3687 16.9539 10.2821 16.9561C10.0711 16.9613 9.77255 16.8916 9.72169 16.4694C9.70973 16.3705 9.58913 16.3277 9.51402 16.3931Z"
        fill="#1F252A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2395 18.0215L10.1604 19.2808L10.9851 19.2508L11.0897 18.0519L10.2395 18.0215Z"
        fill="#F6EBE6"
      />
      <mask
        id="mask0_5650_633"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="18"
        width="4"
        height="3"
      >
        <path
          d="M9.98218 18.8511H12.8835V20.0784H9.98218V18.8511Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5650_633)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.018 19.1933L9.98218 19.8532C9.99126 19.9621 10.0817 20.0464 10.1912 20.0477L12.7813 20.0784C12.8635 20.0793 12.9123 19.9867 12.8649 19.9195L12.8624 19.9159C12.738 19.7396 12.5526 19.6169 12.3427 19.5672C11.9841 19.482 11.4124 19.291 11.131 18.9013C11.0804 18.831 10.9758 18.836 10.9263 18.907C10.8057 19.0803 10.5728 19.2798 10.2023 19.071C10.1156 19.0222 10.0097 19.0941 10.018 19.1933Z"
          fill="#1F252A"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3313 9.66748L14.2997 13.5887C14.3961 13.9793 14.2659 14.391 13.9622 14.6549L10.7752 17.3753L10.6082 15.6268L12.5442 13.8749L11.968 12.0682L13.3313 9.66748Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2679 8.94531L13.3312 9.66758L10.0474 9.9259V9.05508L13.2679 8.94531Z"
        fill="#F6EBE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0474 9.92581L9.9563 18.3651H11.3597L12.7094 10.9581L13.3313 9.66748L10.0474 9.92581Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.436 3.2251C11.436 3.2251 12.6641 3.49096 13.1919 5.9953L14.8883 5.55757L15.0578 6.23516L12.6452 7.65152L11.436 3.2251Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9644 11.1312C10.8508 11.4554 10.4961 11.6262 10.1719 11.5127C9.84773 11.3993 9.67699 11.0445 9.79047 10.7203C9.90396 10.3961 10.2587 10.2253 10.5829 10.3388C10.907 10.4523 11.0779 10.8071 10.9644 11.1312Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42822 3.97771C9.72101 3.65129 10.5583 2.91551 10.9908 2.98845C11.5896 3.08966 12.206 3.36108 12.4694 4.17001C12.9178 5.54572 13.2679 8.94555 13.2679 8.94555L10.0474 9.05532L9.42822 3.97771Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4881 4.35477L10.1721 6.85468L10.775 10.0522L10.0374 10.2964L9.02079 7.19574C8.99944 7.11651 8.98726 7.03501 8.98438 6.9531L9.27128 4.39521C9.26705 4.02814 9.57902 3.7368 9.94495 3.76621C10.2518 3.79086 10.4881 4.04702 10.4881 4.35477Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1799 3.7525L11.0932 3.75335C10.8059 3.7559 10.5709 3.52502 10.5683 3.2378L10.551 1.32872L11.5865 1.31934L11.6047 3.32013C11.6068 3.55679 11.4167 3.75033 11.1799 3.7525Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.358 1.31243C12.3636 1.9339 11.8644 2.4423 11.2428 2.44787C10.6214 2.45355 10.113 1.95422 10.1075 1.33275C10.1018 0.711283 10.6011 0.202986 11.2225 0.197312C11.8441 0.191741 12.3524 0.69096 12.358 1.31243Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6369 2.72142C11.3419 2.7241 11.1008 2.5155 11.0984 2.25542L11.0898 1.30588L12.3577 1.29443L12.3648 2.06828C12.3681 2.42534 12.0422 2.71771 11.6369 2.72142Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3274 1.56396L12.5765 1.92381C12.6119 1.98251 12.5672 2.05545 12.4956 2.05607L12.2112 2.1125L12.3274 1.56396Z"
        fill={color}
      />
      <mask
        id="mask1_5650_633"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="0"
        width="3"
        height="3"
      >
        <path
          d="M11.2225 0.197266C10.601 0.20294 10.1018 0.71134 10.1075 1.33281C10.113 1.95418 10.6214 2.4535 11.2428 2.44783C11.8644 2.44225 12.3636 1.93385 12.358 1.31238C12.3524 0.694421 11.8497 0.197266 11.2329 0.197266C11.2295 0.197266 11.226 0.197266 11.2225 0.197266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_5650_633)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3477 1.81103C11.3477 1.81103 11.7099 1.45253 11.4958 0.879858C11.4958 0.879858 12.304 0.964763 12.4755 0.684565C12.4755 0.684565 11.7846 -0.688673 10.539 0.00171132C9.2934 0.692096 9.88083 2.11527 9.88083 2.11527L10.9173 2.40279L11.3477 1.81103Z"
          fill="#121C21"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4099 1.80837C11.4115 1.97911 11.2743 2.11879 11.1035 2.12034C10.9328 2.12199 10.7931 1.98468 10.7915 1.81404C10.79 1.6432 10.9271 1.50351 11.0979 1.50197C11.2687 1.50042 11.4083 1.63763 11.4099 1.80837Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5305 1.9917L10.562 2.52775C10.562 2.52775 11.144 2.6533 11.1392 2.11962L10.7949 2.18544L10.5305 1.9917Z"
        fill="#121C21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.567 3.08398C10.567 3.08398 10.7712 3.35665 11.2687 3.31745C11.2687 3.31745 11.5112 3.29042 11.6155 3.16084L11.7995 3.34695L11.6992 3.74239L10.6574 3.87516L10.5098 3.54761L10.567 3.08398Z"
        fill={color}
      />
      <path
        d="M10.0969 3.37744C10.0969 3.37744 11.4363 4.46718 10.2634 6.85454"
        stroke={color}
        strokeWidth="0.813773"
      />
      <path
        d="M10.5827 9.03267L10.1721 6.85474L10.3994 5.05615"
        stroke={color}
        strokeWidth="0.406886"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9573 10.7016L10.7274 10.0679L10.1721 10.2516L10.2891 10.7416L10.9573 10.7016Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1702 4.78577V4.64134L15.3714 4.44873H16.0936L16.3129 4.6173V4.78577H15.1702Z"
        fill="#F6EBE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1702 4.84229L15.2891 6.2081C15.2991 6.33262 15.4031 6.42856 15.528 6.42856H15.9671C16.0916 6.42856 16.1954 6.33324 16.2059 6.20913L16.3316 4.84229H15.1702Z"
        fill="#F6EBE6"
      />
      <mask
        id="mask2_5650_633"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="-1"
        width="9"
        height="22"
      >
        <path
          d="M8.31616 20.0784H16.4199V-0.192383H8.31616V20.0784Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_5650_633)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0879 4.88711H16.4199V4.74268H15.0879V4.88711Z"
          fill="#D7C4BF"
        />
        <path
          d="M15.2336 5.57209L15.4185 5.53516"
          stroke={color}
          strokeWidth="1.22066"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default PickupIcon;
