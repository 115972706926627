export default {
  accepted: "جارى التحضير",
  got_picked_up: "تم الاستلام",
  ready_for_pickup: "جاهز للإستلام",
  received: "الواردة",
  noOrderHistory: "لا يوجد طلبات",
  customLabel: "بداية من:",
  rejected: "تم رفض الطلب",
  seen: "شوهد من قبل الفرع",
  noOrders: "لم يتم العثور علي طلبات",
  sent: "تم الارسال للفرع",
  canceled_by_customer: "تم الغاء الطلب من طرف العميل",
  canceled_by_cashier: "تم الغاء الطلب",
  canceled_by_agent: "تم الغاء الطلب",
  customer_no_show: " لا يظهر",
  scheduled: "مجدولة",
  out_for_delivery: "خارج للتوصيل",
  delivered: "تم التوصيل",
  connecting: "جاري الاتصال ...",
  analytics: "تحليلات",
  title: "الطلبات السابقة",
  qr_title: "طلبات منصة QR ({{count}})",
  placeholder: "ابحث ب كود الطلب، رقم الهاتف...",
  time: "الوقت",
  order_types: "نوع الطلب",
  name: "الاسم",
  price: "السعر",
  order_state: "حالة الطلب",
  orderId: "كود الطلب",
  all: "الكل",
  branch: "الفرع",
  brand: "المتجر",
  unpublished: "غير منشور",
  pickDate: "أختر ميعاد",
  timeF: "الوقت:",
  branchF: "الفروع:",
  order_types_F: "نوع الطلب:",
  today: "اليوم",
  yesterday: "امس",
  _3Days: "ثلاثة ايام",
  _7Days: "سبعة ايام",
  _30Days: "ثلاثون يوما",
  custom: "تحديد مدة",
  pickup: "Pickup",
  ordering: "Ordering",
  portalOrdering: "Portal ordering",
  portalVisits: "زيارات الرابط",
  addedItems: "إضافة أصناف",
  tooltipContent: "converted users from the previous step",
  ordersCreated: "طلبات",
  ordersCompleted: "طلبات مكتملة",
  tooltip:
    "يمكن لعملائك تقديم طلب التسليم والاستلام من هذا الرابط من خلال متصفح الويب",
  delivery: "خدمه توصيل",
  firstStep: "الخطوة الاولي:",
  secondStep: "الخطوة الثانية:",
  copyLink: "انسخ الرابط",
  preview: "استعراض",
  firstStepPh: "انسخ الرابط أدناه",
  secondStepPh: "نشر ويب بورتال",
  switchTitle: "حالة ويب بورتال",
  published: "تم النشر",
  canceled_by_courier: "تم الغاء الطلب",
  rejected_for_payment_failure: "مرفوض لتعذر عملية الدفع"

};
