import { put, takeLatest, call, select } from "redux-saga/effects";
import * as actions from "../actions/get-competition-actions";
import { selectToken } from "../selectors";
import { getCompetition } from "../../axios/getCompetition";
import { getCompetitionAction } from "../../constants";
import { AxiosResponse } from "axios";
import { notify } from "react-notify-toast";
import { initialize } from "redux-form";
import { ICompetitionCreate } from "../../types";
import { translateLeaderboard } from "../../i18n/i18n";
import Strings from "../../i18n/strings/leaderboard";

export interface ILeaderBoard {
  end_time: string;
  start_time: string;
  created_at: number;
  id: string;
  updated_at: number;
}

export interface ILeaderBoardGift {
  leaderboard_id: string;
  gift_description: string;
  gift_photo: string;
  gift_rank: number;
  updated_at: any;
  created_at: any;
  gift_name: string;
  id: string;
}

export interface IGetCompetitionResponse {
  leaderBoard: ILeaderBoard;
  leaderBoardGifts: ILeaderBoardGift[];
}

function* initializeGiftForm(formName, formData) {
  yield put(initialize(formName, formData));
}

const convertURLToFile = (url): Promise<File> => {
  return new Promise<File>((resolve) => {
    fetch(url + "?_=" + new Date().getTime())
      .then((res) => res.blob())
      .then((blob) => {
        resolve(new File([blob], "File name"));
      });
  });
};

function* initializeEditGiftForms(competitionData: ICompetitionCreate) {
  const giftsSortedByRank = competitionData.gifts.sort(
    (a, b) => a.gift_rank - b.gift_rank
  );
  for (
    let currentGiftRank = 1;
    currentGiftRank <= competitionData.gifts.length;
    currentGiftRank++
  ) {
    const currentGiftIndex = currentGiftRank - 1;
    const currentGift = giftsSortedByRank[currentGiftIndex];
    const url = currentGift.gift_photo as string;
    const giftPhotoFileInstance = yield call(
      convertURLToFile,
      process.env.REACT_APP_LUMEN_ENDPOINT + url
    );
    yield initializeGiftForm(`gift_${currentGiftRank}`, {
      ...currentGift,
      gift_photo: [giftPhotoFileInstance],
    });
  }
}

function* getCompetitionSaga(action: any) {
  try {
    const token: string = yield select(selectToken);
    const response: AxiosResponse<IGetCompetitionResponse> = yield call(
      getCompetition,
      action.payload.id,
      token
    );
    const competitionData: ICompetitionCreate = {
      ...response.data.leaderBoard,
      gifts: response.data.leaderBoardGifts,
    };
    if (action.payload.setForm) {
      yield* initializeEditGiftForms(competitionData);
    }
    yield put(actions.getCompetitionSuccess(competitionData));
  } catch (e) {
    notify.show(translateLeaderboard(Strings.errorLoadingCompetition), "error");
    yield put(actions.getCompetitionFailure(e));
  }
}

export function* watchGetCompetitionSaga() {
  yield takeLatest(getCompetitionAction.requested, getCompetitionSaga);
}
