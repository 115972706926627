import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteBannerCardAction } from "../../constants";
import {
  deleteBannerCardFailure,
  deleteBannerCardSuccess,
  deleteBannerCard,
  resetBannerCardsState,
} from "../actions/brand-visuals-actions";
import { deleteBannerCardApi } from "../../axios/banner-cards";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { translate } from "../../helpers/translate";
import { notify } from "react-notify-toast";
import strings from "../../i18n/strings/brandVisuals";
const actionTypes = union({ deleteBannerCard });
function* deleteBannerCardSaga({ payload }: typeof actionTypes) {
  const t = translate("brandVisuals");
  try {
    const token = yield select(selectToken);
    const res = yield call(deleteBannerCardApi, token, payload);
    yield put(deleteBannerCardSuccess(res.data));
    notify.show(t(strings.bannerCardDeletedSuccessfully), "success");
  } catch (error) {
    notify.show(t(strings.bannerCardDeletedFailed), "error");
    yield put(deleteBannerCardFailure(error as Error));
  }
}

export default function* watchDeleteBannerCardSaga() {
  yield takeLatest(deleteBannerCardAction.requested, deleteBannerCardSaga);
}
