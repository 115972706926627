import convertingRatio from "./convertingRatio";
import onBoardingSteps from "./onBoardingSteps";

export default {
  title: "صفحات متجرك على وسائل التواصل الاجتماعي",
  subtitle: "أضف روابط صفحاتك:",
  note: "لا بأس اذا لم يكن لديك أي منها",
  link: "ربط",
  submit: "تأكيد",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  next: onBoardingSteps.next,
  save: "حفظ",
};
