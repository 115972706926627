import React from "react";
import { SubMenu } from "../index";
import { Link } from "react-router-dom";
import styles from "../../styles.module.sass";
import { InsightsIcon } from "../../icons/InsightsIcon";
import Strings from "../../../../i18n/strings/navigation";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
interface IProps {
  activeNavigate: (values) => boolean;
  collapsed: boolean;
}
const CollapseInsights: React.FC<IProps> = ({ activeNavigate, collapsed }) => {
  const { t } = useTranslation("navigation");
  return (
    <SubMenu
      active={activeNavigate("insights")}
      key={"insights"}
      title={t(Strings.insights)}
      icon={<InsightsIcon clicked={activeNavigate("insights")} />}
      clicked={activeNavigate("insights")}
      collapsed={collapsed}
    >
      <Link
        to={"/insights/transactions"}
        className={
          activeNavigate("/insights/transactions")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.transactions)}
      </Link>
      <Link
        to={"/insights/redemptions"}
        className={
          activeNavigate("/insights/redemptions")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.redemptions)}
      </Link>
      <Link
        to={"/insights/customers"}
        className={
          activeNavigate("/insights/customers")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.customers)}
      </Link>
      <Link
        to={"/insights/branches"}
        className={
          activeNavigate("/insights/branches")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.branches)}
      </Link>
      <Link
        to={"/insights/fraud-center"}
        className={
          activeNavigate("/insights/fraud-center")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.fraudCenter)}
      </Link>
    </SubMenu>
  );
};

export default CollapseInsights;
