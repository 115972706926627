import React from "react"
import styles from  './styles.module.sass'
import { uDIC } from "../../lib/useDefaultAndInsertedClassName"

const GenericButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button type='button' {...props} className={uDIC(styles.genericButtonDefaults, props.className)} />
  )
}

export {
  GenericButton,
}
