import * as Requests from "./requests";
import axios from "./axios";
import { IModifiersGroup } from "../lib";

export const createOrderingModifierGroup = (
  token,
  modifiers: IModifiersGroup
) => {
  return axios.post<{ id: string }>(
    Requests.CREATE_ORDERING_MENU_MODIFIERS_GROUPS_API,
    modifiers,
    {
      headers: {
        token,
      },
    }
  );
};
