import * as React from "react";
import styles from "./styles.module.sass";
import { FilledButton } from "../FilledButton";
import { useAppVersion } from "./use-app-version";
import { updateNotifierStrings } from "./i18n/strings";
import { useRtlClass } from "../../hooks/useRtlClassName";
import UpdateFoundIcon from "./assets/update-found-icon";
import { useTranslation } from "../../hooks/useTranslation";

interface IProps {
  appName: string;
}

export const UpdateNotifier: React.FC<IProps> = ({ appName }) => {
  const { shouldUpdate, update } = useAppVersion();
  const { t } = useTranslation("updateNotifier");
  const rtlClass = useRtlClass(styles);

  return shouldUpdate ? (
    <div className={`${styles.updateNotifierWrapper} ${rtlClass}`}>
      {UpdateFoundIcon}
      <h3 className={styles.updateFoundTitle}>
        {t(updateNotifierStrings.updateFoundTitle, { appName })}
      </h3>
      <p className={styles.updateFoundDescription}>
        {t(updateNotifierStrings.updateFoundDescription)}
      </p>
      <FilledButton onClick={update}>
        {t(updateNotifierStrings.refreshButtonText)}
      </FilledButton>
    </div>
  ) : null;
};
