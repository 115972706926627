import { useState } from "react";
import { useQuery } from "react-query";
import { useDeepCompareEffect } from "use-deep-compare";
import {
  getBranchesPerformance,
  IParams,
} from "../../../axios/Manager/Analytics";

export interface IBranchesPerformance {
  top_branches: IBranch[];
  lowest_branches: IBranch[];
}

export interface IBranch {
  branch_id: string;
  en_name: string;
  ar_name: string;
  acceptance_rate: IRate;
  fulfillment_rate: IRate;
  on_track: number;
  time_to_accept: IRate;
}
export interface IRate {
  value: number;
  direction?: string;
}

export interface IBranchesTable<T = any> {
  key: T;
  branch_name: JSX.Element;
  acceptance_rate: JSX.Element;
  status: JSX.Element;
  time_to_accept: JSX.Element;
  fulfillment_rate: JSX.Element;
}

export const useGetBranchesPerformance = (token, params: IParams) => {
  const [initialState, setInitialState] = useState<IBranchesPerformance>({
    top_branches: [],
    lowest_branches: [],
  });
  const queryResult = useQuery(
    ["branches-performance", params],
    () => {
      return getBranchesPerformance(token, {
        ...params,
      }).then((res) => res.data);
    },
    {
      enabled: !!params,
      retry: 1,
      cacheTime: 0,
    }
  );
  useDeepCompareEffect(() => {
    if (queryResult.status === "success" && queryResult.data) {
      setInitialState({
        lowest_branches: queryResult.data?.lowest_branches,
        top_branches: queryResult.data?.top_branches,
      });
    } else {
      setInitialState({
        lowest_branches: [],
        top_branches: [],
      });
    }
  }, [queryResult.status, queryResult.data]);
  return {
    ...queryResult,
    values: initialState,
  };
};
