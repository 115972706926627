import { action, payload } from "ts-action";
import {
    getFraudlentCustomerList,
} from "../../constants";
import { IFilters, IFraudlentCutomer } from "../../types/wizard-types";


// get actions
export const getFraudlentCustomerListRequested = action(
    getFraudlentCustomerList.requested,
    payload<IFilters>()
);
export const getFraudlentCustomerListSuccess = action(
    getFraudlentCustomerList.fulfilled,
    payload<{ data: IFraudlentCutomer[], hasMore: boolean }>()
);
export const getFraudlentCustomerListFailure = action(
    getFraudlentCustomerList.rejected,
    payload<Error>()
);


// update actions
// to be added later

