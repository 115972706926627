import memoize from "lodash/memoize";
import { CURRENCIES } from "../helpers/currencies";
import i18n from "../i18n/i18n";
import * as React from "react";
import { readAsBase64 } from "../redux-store/sagas";

// tslint:disable-next-line: no-empty
export const noop = () => {};

export const scrollToBottom = () =>
  window.scrollTo({
    top: window.document.body.clientHeight,
    behavior: "smooth",
  });
export const stopPropagationAndDo = memoize(
  (doFn = noop) =>
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      doFn(e);
    }
);

export const compareObjects = (
  oldItem,
  newItem
): { hasChanged: boolean; changedProps: { [x: string]: any } } => {
  let hasChanged = false;
  let changedProps = {};
  Object.keys(newItem).forEach((key) => {
    const propHasChanged = oldItem[key] !== newItem[key];
    hasChanged = hasChanged || propHasChanged;
    if (propHasChanged) {
      changedProps = {
        ...changedProps,
        [key]: newItem[key],
      };
    }
  });
  return {
    hasChanged,
    changedProps,
  };
};

export const getLocalizedCurrency = memoize((iso_code: string) => {
  const lang = i18n.language;
  const langCurrencies = CURRENCIES[lang.slice(0, 2)];
  return langCurrencies[iso_code] || CURRENCIES.en[iso_code];
});
export const convertDataURLToFile = (url: string): Promise<File[]> => {
  const isDataUrl = url.slice(0, 4) === "data";
  const urlTrail = isDataUrl ? "" : "?_=" + new Date().getTime();
  return fetch(url + urlTrail)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      return [new File([blob], "File name", { type: blob.type || "image" })];
    });
};
export const convertDataURLToBase64 = (url: string): Promise<string> => {
  const isDataUrl = url.slice(0, 4) === "data";
  const urlTrail = isDataUrl ? "" : "?_=" + new Date().getTime();
  return fetch(url + urlTrail)
    .then((res) => res.blob())
    .then(async (blob) => {
      return await readAsBase64(
        new File([blob], "File name", { type: blob.type || "image" })
      );
    });
};

export const askPermission = (): Promise<any> => {
  // here we ask user for permission
  // it is a good idea to show the ask-permission pop up when the time is right
  // and user know why this happens and what can it change
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });
    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
};

export const sendNotification = (title: string, body: string): void => {
  // this is a simple function for sending a very basic notification
  navigator.serviceWorker.getRegistration().then((registration: any) => {
    const options: NotificationOptions = {
      body,
    };
    registration.showNotification(title, options);
  });
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
