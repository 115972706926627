import { useQuery } from "react-query";
import { getCustomerReceipts } from "../../../axios/Manager/Analytics";

export const useGetCustomerReceipts = (token, requestConfigs) => {
  const result = useQuery(
    ["customers-receipts", requestConfigs],
    () => {
      if (!requestConfigs.store_id) return [];
      return getCustomerReceipts(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs.store_id,
    }
  );

  return result;
};
