import React from "react";
import styles from "./styles.module.sass";
import { uDIC } from "../useDefaultAndInsertedClassName";
import { ClipLoader } from "react-spinners";

interface IProps {
  label: string;
  className?: string;
  onClick?: () => void;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  loading?: boolean;
  loaderColor?: string;
}
const GenericButtonWithoutIcon: React.FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  return (
    <button
      type="button"
      {...props}
      className={uDIC(styles.genericButtonDefaults, props.className)}
    >
      {props.loading ? (
        <React.Fragment>
          <ClipLoader size={20} color="#fff" />
        </React.Fragment>
      ) : (
        `${props.label || props.children}`
      )}
    </button>
  );
};

export { GenericButtonWithoutIcon };
