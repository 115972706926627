import { useContext } from "react";
import { Theme, Tooltip, withStyles } from "@material-ui/core";
import styles from "./styles.module.sass";
import Avatar from "../../../../../../assets/icons_info_gender_male.svg";
import Female from "../../../../../../assets/female.svg";
import { useGetCurrency } from "../../../../../../Components/Pickup/SetupPickup/OrderHistory/PastOrder/PastOrderTable/OrderDetails";
import StoreData from "../../../../../../contexts/StoreData";
import { useTranslation } from "../../../../../../Components/WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { Interpolate } from "react-i18next";
import i18n from "../../../../../../i18n/i18n";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
/* eslint-disable-next-line */
export interface ProgressBarProps {
  icon?: string;
  precentage: number;
  average_ticket_size?: number;
  gender?: string;
  males_percentage?: number;
  label: string;
  width: number;
  marginBottom?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { t } = useTranslation("home");
  const { storeData } = useContext(StoreData);
  const currency = useGetCurrency(storeData?.country_iso_code as string);
  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#ffff",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0 2px 4px 0 rgba(142, 142, 147, 0.12)",
      fontSize: 11,
      border: "solid 1px #e5e5ea",
      borderRadius: "4px",
    },
  }))(Tooltip);
  return (
    <LightTooltip
      title={
        <div className={`${styles.toolTipMain} ${useRtlClass(styles)}`}>
          <span className={styles.tooltipTitle}>
            <Interpolate
              i18nKey={"home:avg_ticket_size_with_price"}
              price={
                <span className={styles.price}>
                  {props.average_ticket_size} {currency}
                </span>
              }
            />
            {/* AVG ticket size:{" "} */}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className={styles.tooltipContent}>
              <div className={styles.iconWrapper}>
                <img className={styles.icon} src={Avatar} />
              </div>

              <span className={styles.tooltipGender}>
                {props.males_percentage}% {t(Strings.males)}
              </span>
            </div>
            <div
              className={styles.tooltipContent}
              style={{
                padding: i18n.language === "ar" ? "0 20px 0 0" : "0 0 0 20px",
              }}
            >
              <div className={styles.iconWrapper}>
                <img className={styles.icon} src={Female} />
              </div>

              <span className={styles.tooltipGender}>
                {props.males_percentage
                  ? Math.abs(100 - Number(props.males_percentage))
                  : 0}
                % {t(Strings.females)}
              </span>
            </div>
          </div>
        </div>
      }
      aria-label="add"
    >
      <div className={styles.main} style={{ marginBottom: props.marginBottom }}>
        <span className={styles.label}>{props.label}</span>
        <div
          className={styles.progressBarWrapper}
          style={{
            width: `${props.width}%`,
          }}
        >
          <div
            className={styles.progressBar}
            style={{
              width: `${props.precentage}%`,
            }}
          ></div>
        </div>
      </div>
    </LightTooltip>
  );
};

export default ProgressBar;
