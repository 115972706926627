import axios from "./axios";
import * as Requests from "./requests";
import { CASHIER_ACCOUNT_STATUS } from "../Components/WizardComponents/Cashiers/Cashiers/cashiers-hooks";
import { ICashier } from "./getCashiers";

export const searchCashiers = (token: string, phoneNumber: string) => {
  return axios.get<{
    code: CASHIER_ACCOUNT_STATUS;
    cashier?: ICashier;
  }>(Requests.searchCashiers, {
    headers: {
      token,
    },
    params: {
      phoneNumber,
    },
  });
};
