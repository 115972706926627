import * as Requests from "./requests";
import axios from "./axios";

export const getBranchInfoAPI = (token: string, id: string) => {
  return axios.get(Requests.branchInfoURL(id), {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
