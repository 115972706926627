import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IBranch } from "../types/shared";

export interface ICashier {
  created_at: number;
  updated_at: number;
  call_center?: 1;
  password?: string;
  integration_id: string;
  phoneNumber: string;
  branch_ids: string[];
  branches: IBranch[];
  shopxId: string;
  dummy?: boolean;
  name: string;
  id: string;
  type?: number;
}

export const getCashiers = (token: string): AxiosReturn<ICashier[]> => {
  return axios.get(Requests.cashiers, {
    headers: {
      token,
    },
  });
};
