import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IStore } from "../types";

export const getStore = (token): AxiosReturn<IStore> => {
  return axios.get(Requests.store, {
    headers: {
      token,
    },
  });
};
