import menu from "./menu";
export default {
  addFromMenu: "أضف من القائمة",
  customGift: "أضف جائزة خاصة",
  giftName: "اسم الجائزة",
  price: "السعر",
  atLeast: "(قم بإضافة 7 منتجات على الأقل)",
  giftList: "قائمة الجوائز",
  giftItems: "عدد الجوائز",
  progressAutoSaved: menu.progressAutoSaved,
  markComplete: menu.markComplete,
  addSpecialItemSuccess: 'تم إضافة "{{name}}" إلى قائمة هداياك بنجاح!',
  addSpecialItemFailure: 'فشل إضافة "{{name}}" إلى قائمة هداياك',
  itemHasNoImageError:
    "أي منتج في قائمة الهدايا يجب أن يكون به صورة، رجاءاً قم بتعديل هذا المنتج في صفحة قائمة المنتجات",
};
