import * as React from "react";
import { IStore } from "../types";
import { IUser } from "../Components/BrandSettings/DashboardUsers/users-hooks";

interface IContextValues {
  storeData: IStore | null;
  token: string;
  isLoggedIn: boolean;
  signUpComplete: boolean;
  userData: IUser | null;
  setStoreData: (store: IStore, token: string, signUpComplete: boolean) => void;
  setUserData: (user: IUser) => void;
}

export default React.createContext<IContextValues>({
  storeData: null,
  userData: null,
  isLoggedIn: false,
  setStoreData: (store: IStore, token: string, signUpComplete: boolean) => null,
  token: "",
  signUpComplete: false,
  setUserData: (user: IUser) => null,
});
