import { useEffect, useState, useContext } from "react";
import { getStoreProfile } from "../axios/storeProfile";
import { goToLogin } from "../axios/axios";
import { notify } from "react-notify-toast";
import { translateLeaderboard } from "../i18n/i18n";
import Strings from "../i18n/strings/leaderboard";
import { getCheckSignup } from "../axios/getCheckSignup";
import { useProgressState } from "../Utils";
import StoreData from "../contexts/StoreData";
import { userProfileAPI } from "../axios/userProfile";
import moment from "moment";
import * as Mixpanel from "mixpanel-browser";
import { trackEvent } from "../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../constants/events-for-tracking";

const DS_STAGING_RELEASE_DATE = +moment("25-07-2019", "DD-MM-YYYY").format("x");

export const useGetStoreData = (token: string) => {

  const [localToken, setLocalToken] = useState(token);
  // const [storeData, setStoreData] = useState<IStore>();
  useEffect(() => {
    if (window.location.href.includes("signup") && window.location.href.includes("token")) {
      const token = window.location.href.split("token=")[1];
      setLocalToken(token);
    }
  }, []);
  const { setStoreData, storeData, userData, setUserData } =
    useContext(StoreData);

  const { loading, setLoading, setSuccess, failure, setFailure, success } =
    useProgressState();

  useEffect(() => {
    const availableToken = token || localToken;
    if (storeData && availableToken) {
      localStorage.setItem(
        "authData",
        JSON.stringify({
          token: availableToken,
          storeData,
        })
      );
    }
  }, [token, storeData, localToken]);

  useEffect(() => {
    const localData = localStorage.getItem("authData");
    if (localData) {
      const parsedData = JSON.parse(localData);
      setLocalToken(parsedData.token);
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        setLocalToken(token);
      }
    }
  }, []);

  const getProfile = async () => {
    const availableToken = token || localToken;
    if (!availableToken) {
      return;
    } else {
      setLoading();
      const {
        data: { signup_completed },
      } = await getCheckSignup(availableToken);
      const { data: user } = await userProfileAPI(availableToken);
      Mixpanel.identify(user.email);

      const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
      if (!isKoinzAdminRemote) {
        trackEvent(EVENTS_FOR_TRACKING["Logged In"]);
      }

      const userSignedUpBeforeRelease =
        user.created_at < DS_STAGING_RELEASE_DATE;
      const userIsRootAdmin = user.user_type === 0;
      const userShallNotBeSignedUp =
        userIsRootAdmin || userSignedUpBeforeRelease;
      getStoreProfile(availableToken)
        .then(({ data }) => {
          Mixpanel.people.set({
            $first_name: user.name,
            $created: moment(`${user.created_at}`).toDate(),
            $last_login: new Date(),
            Brand: data.store.name,
            country_iso_code: data.store.country_iso_code,
          });
          setSuccess();
          setLocalToken(availableToken);
          setUserData(user);
          setStoreData(
            data.store,
            availableToken,
            signup_completed || userShallNotBeSignedUp
          );
        })
        .catch((e) => {
          setFailure();
          if (e?.response?.status === 401) {
            localStorage.removeItem("using_universal_password");
            localStorage.removeItem("authData");
            goToLogin();
          } else if (e?.response?.status === 500) {
            notify.show(
              translateLeaderboard(Strings.errorOccurredRetrying),
              "warning"
            );
            setTimeout(getProfile, 1000);
          }
        });
    }
  };
  useEffect(() => {
    getProfile();
  }, [token, localToken]);
  return {
    token: localToken,
    storeData,
    loading,
    success,
    failure,
    userData,
  };
};
