import * as React from "react";
import styles from "./styles.module.sass";
import { useCallback } from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import { useTranslation } from "../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../i18n/strings/branches";
import { useCanUseDriveThruOrdering } from "../../../../../hooks/use-can-use-drive-thru-ordering";
const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#1C1C1C",
    color: "#fff",
    boxShadow: " 0px 4px 8px 0px rgba(142, 142, 147, 0.12)",
    fontSize: 14,
    border: "solid 1px #E5E5EA",
    borderRadius: "8px",
    padding: "12px 16px",
    width: "fit-content",
  },
}))(Tooltip);
export interface ISelectItemProps {
  activePickup: boolean;
  activeDriveThru?: boolean;
  activeDelivery: boolean;
  pos_vendor?: string;
  item: { name: string; value: string };
  onClick: (
    item: { name: string; value: string },
    type: string,
    newVal: boolean
  ) => void;
}

export const SelectItem: React.FC<ISelectItemProps> = (props) => {
  const {
    item,
    activeDelivery,
    activePickup,
    onClick,
    activeDriveThru,
    pos_vendor,
  } = props;
  const canUseDriveThruOrdering = useCanUseDriveThruOrdering();
  const { t } = useTranslation("branches");
  const handlePickupClick = useCallback(() => {
    onClick(item, "Pickup", !activePickup);
  }, [onClick, item, activePickup]);
  const handleDeliveryClick = useCallback(() => {
    onClick(item, "Delivery", !activeDelivery);
  }, [onClick, item, activeDelivery]);

  const handleDriveThruClick = useCallback(() => {
    if (activePickup && pos_vendor) {
      onClick(item, "DriveThru", !activeDriveThru);
    }
  }, [onClick, item, activeDriveThru, activePickup, pos_vendor]);
  const driveThruCheckbox = React.useMemo(() => {
    return (
      <div
        className={`${styles.checkBox} ${activeDriveThru
          ? styles.activePickup
          : !activePickup || !pos_vendor
            ? styles.disable
            : ""
          } ${styles.leftCheckBox}`}
        onClick={handleDriveThruClick}
      >
        <img src={require("../../../assets/path.svg").default} />
      </div>
    );
  }, [activeDriveThru, activePickup, pos_vendor, handleDriveThruClick]);
  return (
    <div
      className={`${styles.main} ${activeDelivery || activePickup ? styles.active : ""
        }`}
    >
      <div className={styles.text}>{item.name}</div>
      <div className={styles.checkBoxWrapper}>
        <div
          className={`${styles.checkBox} ${activePickup ? styles.activePickup : ""
            } ${styles.leftCheckBox}`}
          onClick={handlePickupClick}
        >
          <img src={require("../../../assets/path.svg").default} />
        </div>
        {canUseDriveThruOrdering && (
          <React.Fragment>
            {pos_vendor ? (
              driveThruCheckbox
            ) : (
              <LightTooltip placement="top" title={t(Strings.driveThruTooltip)}>{driveThruCheckbox}</LightTooltip>
            )}
          </React.Fragment>
        )}
        <div
          className={`${styles.checkBox} ${activeDelivery ? styles.activeDelivery : ""
            } ${styles.rightCheckBox}`}
          onClick={handleDeliveryClick}
        >
          <img src={require("../../../assets/path.svg").default} />
        </div>
      </div>
    </div>
  );
};
