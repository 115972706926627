import * as Requests from "./requests";
import axios from "./axios";

export interface ICreateCategoryProps {
  menu_id: string;
  ar_name?: string;
  en_name?: string;
  is_bundle?: number;
  order: number;
}
export const createOrderingMenuCategory = (
  token,
  cat: ICreateCategoryProps
) => {
  return axios.post<{ id: string }>(
    Requests.ORDERING_MENU_CATEGORIES_API,
    cat,
    {
      headers: {
        token,
      },
    }
  );
};
