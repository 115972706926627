import * as React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";

interface IProps {
  button: React.ReactElement<any>;
  message: string;
  img?: string;
}

class NotFoundItem extends React.Component<IProps> {
  public render() {
    const { message, button, img } = this.props;
    return (
      <div className={styles.notFound}>
        <img src={img || require("../../../../assets/not-found.svg").default} />
        <div className={styles.text}>{message}</div>
        {button}
      </div>
    );
  }
}

export default hot(module)(NotFoundItem);
