import { select, call, takeLatest, put } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import {
  submitPickupBranches,
  submitPickupBranchesFailure,
  submitPickupBranchesSuccess,
} from "../actions/pickupBranchesActions";
import { union } from "ts-action";
import { toggleOrderingServicesAPI } from "../../axios/toggleOrderingServices";
import { handleOptimism } from "./handleOptimism";
import { getBranches } from "../../../../redux-store/actions";

const actionType = union({ submitPickupBranches } as any);

function* submitPickupBranchesSaga(action: any) {
  const token = yield select(selectToken);
  const res = yield call(toggleOrderingServicesAPI, token, action.payload);
  if (res.status === 200) {
    yield put(getBranches());
  }
  return action.payload;
}

export function* watchSubmitPickupBranchesSaga() {
  yield takeLatest(
    submitPickupBranches.type,
    handleOptimism(submitPickupBranchesSaga, {
      failure: submitPickupBranchesFailure as any,
      success: submitPickupBranchesSuccess as any,
    })
  );
}
