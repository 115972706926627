import * as React from "react";
import styles from  "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import { ChromePicker } from "react-color";

interface IProps {
  label: string;
  note: string;
  customLabel: boolean;
}

interface IState {
  displayColorPicker: boolean;
}

export class RenderColorPickerField extends React.Component<
  WrappedFieldProps & IProps,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }

  public handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  public handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  public render() {
    const {
      input,
      label,
      note,
      customLabel,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div className={styles.colorPickerWrapper}>
        <label
          style={
            customLabel
              ? { fontWeight: 500, fontSize: "2.4rem", color: "black" }
              : {}
          }
          htmlFor={input.name}
          className={styles.label}
        >
          <span>{label}</span>
          <span className={styles.note}>{note}</span>
          {touched &&
            ((error && <span className={styles.formError}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </label>
        {this.state.displayColorPicker ? (
          <div
            style={{
              position: "absolute",
              bottom: "5rem",
              zIndex: 2,
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={this.handleClose}
            />
            <ChromePicker color={input.value} onChange={input.onChange} />
          </div>
        ) : null}

        <div className={styles.wrapper}>
          <div
            onClick={this.handleClick}
            className={styles.box}
            style={{ background: input.value.hex }}
          />
          <div onClick={this.handleClick} className={styles.value}>
            {input.value.hex}
          </div>
        </div>
      </div>
    );
  }
}
