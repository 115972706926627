import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { getStoreInfoAction } from "../../constants/actions";
import {
  getStoreFailure,
  getStoreInfo,
  getStoreInfoSuccess,
} from "../actions/past-order-actions";
import { getStoreInfoAPI } from "../../axios/getStoreInfoAPI";

const actionType = union({ getStoreInfo } as any);
function* getStoreInfoSaga(action: any) {
  const token = yield select(selectToken);
  try {
    const res = yield call(getStoreInfoAPI, token, action.payload);
    yield put(getStoreInfoSuccess(res.data));
  } catch (e) {
    yield put(getStoreFailure(e));
  }
}

export function* watchGetStoreInfoSaga() {
  yield takeLatest(getStoreInfoAction.requested, getStoreInfoSaga);
}
