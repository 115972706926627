import React, { useState } from "react";
import styles from "./styles.module.sass";
import ProfileModal from "../../ProfileModal";
import { ILeaderBoardRankingCustomer } from "../../../../axios/getUsers";
import { imagesURL } from "../../Card";
import Aux from "../../AuxComponent";
import { translate, InjectedTranslateProps } from "react-i18next";
import Strings from "../../../../i18n/strings/leaderboard";
import i18n from "../../../../i18n/i18n";
import { useGetCurrency } from "../../../../Utils";

interface IProps extends InjectedTranslateProps {
  customer: ILeaderBoardRankingCustomer;
}

export const prefixImage = (image = "") => {
  if (image.indexOf("http") === -1) {
    return imagesURL + "/" + image;
  }
  return image;
};
export const NAify = (data) => data || i18n.t("N/A");

const User: React.FC<IProps> = (props) => {
  const [modal, setModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);
  const { customer, t } = props;
  const handleImage = customer.image ? prefixImage(customer.image) : require("../../../../assets/star.svg").default;
  return (
    <Aux>
      <div className={styles.wrapper} onClick={onOpenModal}>
        <div className={styles.rank}>#{customer.rank}</div>
        <div className={styles.user}>
          <img
            src={handleImage}
            className={styles.userImg}
          />
          <span className={styles.userName}>
            {NAify(customer.name).substring(0, 15)}
            {NAify(customer.name).length > 15 && "..."}
          </span>
        </div>
        <div className={styles.email}>
          {NAify(customer.email).substring(0, 20)}
          {NAify(customer.email).length > 20 && "..."}
        </div>
        <div className={styles.phone}>{customer.phone_number}</div>
        <div className={styles.pointsWrapper}>
          <span style={{ margin: 0 }} className={styles.points}>{`${customer.total_invoices
            }${useGetCurrency()}`}</span>
        </div>
        <div className={styles.pointsWrapper}>
          <div className={styles.imgWrapper}>
            <img
              src={require("../../../../assets/star.svg").default}
              className={styles.img}
            />
          </div>
          <span className={styles.points}>
            {t(Strings.pointsWithCount, { count: customer.total_points })}
          </span>
        </div>
      </div>
      {modal && (
        <ProfileModal customer={customer} close={onCloseModal} modal={modal} />
      )}
    </Aux>
  );
};

export default translate("leaderboard")(User) as any;
