import axios from "./axios";
import * as Requests from "./requests";
import { IMinOrderValue } from "../lib/types";

export const setMinOrderingValue = (token: string, val: IMinOrderValue) => {
  return axios.put(Requests.setMinOrderingValueAPI, val, {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
