import axios from "../axios";
import { storeItem } from "../requests";

export const editLoyaltyMenuItemApi = <
  T extends { [key: string]: any } & { id: string }
>(
  token: string,
  payload: T
) => {
  return axios.put(storeItem(payload.id), payload, {
    headers: {
      token,
    },
  });
};
