import * as React from "react";
import i18n from "./i18n/i18n";

export const CriticalReviewsNumber = React.createContext({
  criticalNumbers: 0,
  update: () => null,
});

export const getRtlClass = (stylesModule) => {
  const isRtl = i18n.language === "ar";
  const rtlClass = stylesModule.rtl;
  const hasRtlClass = !!stylesModule.rtl;
  const shouldReturnRtlClass = isRtl && hasRtlClass;
  return shouldReturnRtlClass ? rtlClass : "";
};

export const useRtlClass = (stylesModule) =>
  React.useMemo(() => getRtlClass(stylesModule), [i18n.language]);
export const useCurrentLang = () => React.useMemo(() => i18n.language, [i18n]);
