import React from "react";
interface IProps {
  clicked: boolean;
}
const HomeIcon = ({ clicked }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={clicked ? "#ffa351" : "#aeaeb2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.5 7.5L10 1.667 17.5 7.5v9.166c0 .442-.176.866-.488 1.179-.313.313-.737.488-1.179.488H4.167c-.442 0-.866-.175-1.179-.488-.312-.313-.488-.736-.488-1.178V7.5z"
      />
      <path
        stroke={clicked ? "#ffa351" : "#aeaeb2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.5 18.333V10h5v8.333"
      />
    </svg>
  );
};

export default HomeIcon;
