import { action, payload } from "ts-action";
import { getRecommendedGiftAction } from "../../../constants";
import { IRecommendedGift } from "../../../types/wizard-types";

export const getRecommendedGift = action(getRecommendedGiftAction.requested);
export const getRecommendedGiftSuccess = action(
  getRecommendedGiftAction.fulfilled,
  payload<IRecommendedGift>()
);
export const getRecommendedGiftFailure = action(
  getRecommendedGiftAction.rejected,
  payload<Error>()
);
