import { call, put, select, takeLatest } from "redux-saga/effects";
import { addLoyaltySpecialItemAction } from "../../../constants";
import { selectToken } from "../../selectors";
import { union } from "ts-action";
import {
  addLoyaltySpecialItem,
  addLoyaltySpecialItemFailure,
  addLoyaltySpecialItemSuccess,
} from "../../actions";
import { addSpecialItem } from "../../../axios/addSpecialItem";
import { TranslationOptions } from "i18next";
import i18n from "../../../i18n/i18n";
import { reset } from "redux-form";
import { customNotify } from "../../../helpers/customNotification/customNotification";
import Strings from "../../../i18n/strings/giftList";

const t = (key: string, options: TranslationOptions) =>
  i18n.t(key, { ns: "giftList", ...options });
const actionTypes = union({
  addLoyaltySpecialItem,
});
function* addLoyaltySpecialItemSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const { price, name, image } = payload;
    yield put(reset("addSpecialItemForm"));
    const addLoyaltyItemRes = yield call(addSpecialItem, token, {
      price: +price,
      name,
      image,
      ...(payload.is_voucher
        ? {
            is_voucher: 1,
            promo_id: payload.promo_id,
            promo_code: payload.promo_code,
          }
        : {}),
    });
    yield put(addLoyaltySpecialItemSuccess(addLoyaltyItemRes.data));
    customNotify.success(t(Strings.addSpecialItemSuccess, { name }));
  } catch (error) {
    yield put(addLoyaltySpecialItemFailure(error as Error));
    customNotify.error(
      t(Strings.addSpecialItemFailure, { name: payload.name })
    );
  }
}

export function* watchAddLoyaltySpecialItemSaga() {
  yield takeLatest(
    addLoyaltySpecialItemAction.requested,
    addLoyaltySpecialItemSaga
  );
}
