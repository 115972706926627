export default {
  required: "Required",
  isEmpty: `Field can't be empty`,
  maxLength: "Must be {{max}} characters or less",
  minLength: "Must be at least {{min}} characters or more",
  number: "Must be a number",
  minValue: "Must be at least {{min}}",
  maxValue: "Must not exceed {{max}}",
  image: "Not a valid image file",
  url: "Invalid URL",
  mustBeNumber: "Input must be a number",
  mustBeInteger: "Input must be an integer",
  range: "Tax percentage must be in range of 0% and 100%",
  vat: "Tax percentage must be in range of 0% and 100%",
  location: "Please pin the exact location on the map",
  atLeastOneField: "Please fill at least one of either field",
};
