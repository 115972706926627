import axios from "./axios";
import * as Requests from "./requests";

export interface IWebPortalFilters {
  start_time?: number;
  end_time?: number;
  branch_id?: string;
  ordering_source?: string;
  order_type?: string;
  order_code_or_customer_phone_number?: string;
  in_branch_order_type?: any;
}
export const getWebPortalHistoryApi = (
  token: string,
  page: number,
  per_page: number,
  filters?: IWebPortalFilters
) => {
  return axios.get(Requests.getWebPortalHistoryURL, {
    params: {
      page,
      per_page,
      ...filters,
    },
    headers: { token },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
