export default {
  categoryTitle: "التصنيفات",
  // Add Category
  addCategory: "اضافة تصنيف",
  createNewCategory: "انشاء تصنيف جديد",
  CancelButton: "الغاء",
  submitButton: "تأكيد",
  arabicCategoryNamePlaceholder: "مثال : الافطار",
  englishCategoryNamePlaceholder: "Ex: Breakfast",
  notificationsAddedToCategories: "أضافة الى التصنيفات",
  notificationsFailedToCategories: "لايوجد تصنيف مضاف",
  labelEnglishCategoryName: "اسم التصنيف باللغة الانجليزية",
  labelArabicCategoryName: "اسم التصنيف باللغة العربية",
  isBundle: "عرض خاص ؟ ",
  categoryUpdated: "تم تعديل الفئة بنجاح",
  // Edit Category
  editCategoryTitle: "تعديل تصنيف",
  editCategory: "تعديل تصنيف",
  editCategoryCancelButton: "الغاء",
  editCategorySubmitButton: "تأكيد",
  editCategoryArabicCategoryNamePlaceholder: "مثال :  الافطار",
  editCategoryenglishCategoryNamePlaceholder: "Ex: Breakfast",
  editCategoryLabelEnglishCategoryName: "التصنيف باللغة الانجليزية",
  editCategoryLabelArabicCategoryName: "التصنيف باللغة العربية",

  // Delete Category
  delete: "مسح",
  category: "تصنيف",
  warning: "تحذير : انت ستقوم بحذف ",
  items: "عناصر",
  errorCat: "عملية غير مكتملة",
  deleteCategorySubmitButton: "مسح تنصيف",
  deleteCategoryCancelButton: "الغاء",
};
