import * as React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { IModifiersGroup } from "../../../../../../../redux-store/actions/lib";
import Strings from "../../../../../../../i18n/strings/orderHistory";
import { useTranslation } from "../../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
export enum Gift {
  false,
  true,
}

interface ISingleItemProps {
  itemNum: number;
  itemName: string;
  itemPrice: number;
  category_ar_name: string;
  category_en_name: string;
  comment: string;
  is_gift: boolean | Gift;
  currency: string;
  modifiers: IModifiersGroup[];
}

const SingleItem: React.FC<ISingleItemProps> = (props) => {
  const { t } = useTranslation("orderHistory");
  const {
    itemName,
    itemNum,
    itemPrice,
    comment,
    modifiers,
    currency,
    category_ar_name,
    category_en_name,
    is_gift,
  } = props;
  const modifiersItems = modifiers.map((item) => {
    return (
      <div className={styles.mod} key={item.id}>
        <div className={styles.whiteSpace} />
        <div className={styles.modNames}>
          <p className={styles.extras}>
            {item.en_name} - {item.ar_name}
          </p>

          {item.modifiers.map((mod) => (
            <React.Fragment>
              {mod.ar_name && mod.en_name ? (
                <span key={mod.id} className={styles.itemSpan}>
                  {mod.en_name} - {mod.ar_name}
                </span>
              ) : (
                <span key={mod.id} className={styles.itemSpan}>
                  {mod.name}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className={styles.modPrices}>
          <p className={styles.extrasSpace} />
          {item.modifiers.map((mod) => (
            <span className={styles.itemPriceSpan} key={mod.id}>
              {mod.price} {currency}
            </span>
          ))}
        </div>
      </div>
    );
  });

  return (
    <>
      {is_gift !== Gift.true ? (
        <div>
          <div className={`${styles.itemWrapper}`}>
            <div className={styles.itemHeaders}>
              <span className={styles.itemNumber}>x{itemNum}</span>
              <div className={styles.content}>
                <span className={styles.header}>{itemName}</span>
              </div>
              <div className={styles.price}>
                <span>
                  {itemPrice} {currency}
                </span>
              </div>
            </div>
            <div className={styles.catWrapper}>
              {category_ar_name !== null && category_en_name !== null && (
                <div className={`${styles.categoryWrapper}`}>
                  <span className={styles.category}>{category_en_name}</span>{" "}
                  {category_ar_name && (
                    <React.Fragment>
                      <span className={styles.separator}>-</span>
                      <span className={styles.category}>
                        {category_ar_name}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
            {modifiersItems}
            {comment && (
              <div className={styles.commentWrapper}>
                <span className={styles.commentTitle}>
                  {t(Strings.comment)}
                </span>
                <span className={styles.commentSubTitle}>{comment}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.itemWrapper}>
          <div className={styles.itemHeaders}>
            <span className={styles.itemNumber}>x{itemNum}</span>

            <div className={styles.giftWrapper}>
              <img
                className={styles.giftIcon}
                src={
                  require("../../../../../../../assets/invalid-name.svg")
                    .default
                }
              />
              <div className={styles.giftContent}>
                <span className={styles.giftHeader}>{itemName}</span>
                {/* <div className={styles.giftSubHeader}>
                  <span className={styles.giftTitle}>
                    {t(Strings.redemption)}
                  </span>
               <div className={styles.giftCode}>
                    <span className={styles.giftCodeTitle}>eee</span>
                  </div> 
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default hot(module)(SingleItem);
