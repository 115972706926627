import React, { useState } from "react";
import { Button, Checkbox, Dropdown, Input, Menu, Alert } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Search } from "react-feather";
import "./MultiSelect.less";
import { useTranslation } from "../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../i18n/Manager/strings/home";
import i18n from "../../i18n/i18n";
interface RequiredOptionProps {
  id: string | number;
  title: string;
  [key: string]: any;
}

interface RequiredSelectedOptionProps {
  id: string | number;
  [key: string]: any;
}

export interface MultiSelectProps {
  label?: string;
  options: RequiredOptionProps[];
  selected: RequiredSelectedOptionProps[];
  onApply: any;
  search?: boolean;
  canSelectAll?: boolean;
  defaultSelected?: RequiredOptionProps[];
  triggers?: ("click" | "hover")[];
  canSetEmpty?: boolean;
  showAllSelected?: boolean;
  width?: string;
  maxSelectionsNo?: number;
  onReset?: () => any;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  onApply,
  selected,
  label,
  defaultSelected,
  search = true,
  canSelectAll = true,
  canSetEmpty = false,
  showAllSelected = false,
  width,
  maxSelectionsNo,
  onReset,
}) => {
  const { t } = useTranslation("home");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [internalOptions, setInternalOptions] = useState<RequiredOptionProps[]>(
    []
  );
  const [internalSelected, setInternalSelected] = useState<
    RequiredSelectedOptionProps[]
  >([]);

  const [searchValue, setSearchValue] = useState<string>("");

  React.useEffect(() => {
    if (defaultSelected && Array.isArray(defaultSelected))
      setInternalSelected(defaultSelected);
  }, [defaultSelected]);

  React.useEffect(() => {
    if (options.length > 0) {
      setInternalOptions(options);
    }
  }, [options]);

  React.useEffect(() => {
    if (maxSelectionsNo) {
      if (selected.length <= maxSelectionsNo) {
        setInternalSelected(selected);
      }
    } else {
      setInternalSelected(selected);
    }
  }, [selected]);

  const onAllClick = () => {
    if (internalSelected.length === internalOptions.length) {
      setInternalSelected([]);
    } else {
      setInternalSelected(internalOptions);
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const resultOptions = options.filter((o) =>
      o.title.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())
    );
    setSearchValue(e.target.value.trim());
    setInternalOptions(resultOptions);
  };

  const itemOnChange = (option: RequiredOptionProps) => {
    const index = internalSelected.findIndex((s) => s.id === option.id);
    if (index > -1) {
      let copyOfSelected = [...internalSelected];
      copyOfSelected.splice(index, 1);
      setInternalSelected(copyOfSelected);
    } else {
      setInternalSelected([...internalSelected, option]);
    }
  };

  const maxSelectionsExceeded =
    maxSelectionsNo &&
    internalSelected.length < options.length &&
    internalSelected.length > maxSelectionsNo;

  return (
    <Dropdown
      onVisibleChange={() => setIsOpen(!isOpen)}
      trigger={["click"]}
      visible={isOpen}
      placement="bottomRight"
      overlayStyle={{ background: "#fff" }}
      overlay={
        <div
          style={{
            minWidth: 266,
            background: "#fff !important",
            padding: ".5px 5px 5px 5px",
            boxShadow:
              "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
          }}
        >
          {search && (
            <div style={{ padding: "0 0.7rem", background: "#fff" }}>
              <Input
                placeholder={t(Strings.search_branch_name)}
                style={{ padding: "0 .5rem" }}
                onChange={onSearch}
                prefix={
                  <Search
                    color="#8E8E93"
                    size={15}
                    style={{ marginBottom: ".1rem" }}
                  />
                }
              />
              <hr style={{ color: "#222", opacity: 0.3 }} />
            </div>
          )}{" "}
          <Menu className="multi-select-menu-items">
            {canSelectAll && !searchValue && (
              <Menu.Item>
                <Checkbox
                  onChange={onAllClick}
                  checked={
                    internalSelected.length === options.length ? true : false
                  }
                >
                  <span style={{ transform: "translateY(-3px)" }}>
                    {t(Strings.all)}
                  </span>
                </Checkbox>
              </Menu.Item>
            )}
            {canSelectAll && !searchValue && (
              <hr style={{ color: "#222", opacity: 0.3 }} />
            )}
            {internalOptions.map((option, i) => (
              <Menu.Item>
                <Checkbox
                  key={i}
                  onChange={() => itemOnChange(option)}
                  checked={
                    internalSelected.findIndex((s) => s.id === option.id) > -1
                      ? true
                      : false
                  }
                >
                  <span style={{ transform: "translateY(-3px)" }}>
                    {option.title}
                  </span>
                </Checkbox>
              </Menu.Item>
            ))}
          </Menu>
          <hr style={{ color: "#222", opacity: 0.1 }} />
          {maxSelectionsExceeded && (
            <Alert
              message="Can't select more than 5 branches"
              type="error"
              style={{ height: "25px", marginBottom: "7px" }}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="padding-10px"
              size="small"
              onClick={() => {
                setInternalSelected(defaultSelected ?? internalOptions);
                onReset && onReset();
              }}
            >
              {t(Strings.reset)}
            </Button>
            <Button
              className="padding-10px mr-10px"
              disabled={
                (internalSelected.length === 0 && !canSetEmpty) ||
                Boolean(maxSelectionsExceeded)
              }
              size="small"
              type="link"
              onClick={() => {
                onApply(internalSelected);
                setIsOpen(false);
              }}
            >
              {t(Strings.apply)}
            </Button>
          </div>
        </div>
      }
    >
      <Button
        onClick={() => setIsOpen(true)}
        style={
          width
            ? {
                width: width,
                justifyContent: "space-between",
              }
            : {}
        }
      >
        <span>
          {label && `${label}: `}
          {selected.length === options.length
            ? t(Strings.all)
            : selected.length === 1
            ? `${options.find((option) => option.id === selected[0].id)?.title}`
            : showAllSelected
            ? `${selected.map(
                (s) => `${options.find((o) => o.id === s.id)?.title}`
              )}`
            : `${selected.length} selected`}
        </span>
        <img
          style={{
            width: "14px",
            height: "14px",
            margin: i18n.language === "ar" ? "0 8px 0 0" : "0 0 0 8px",
            transform: isOpen ? "rotate(180deg)" : undefined,
          }}
          src={require("../../assets/feather _ chevron-down.svg").default}
        />
      </Button>
    </Dropdown>
  );
};

export const mapStringArryToSelectedArray = (arr: any[]) => {
  return arr.map((el) => ({ id: el }));
};

export default MultiSelect;
