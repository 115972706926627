import { call, put, select, takeLatest } from "redux-saga/effects";
import { editStoreAction } from "../../constants";
import { IEditStoreAction } from "../../types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { change } from "redux-form";
import { delay } from "redux-saga/effects";
import i18n from "../../i18n/i18n";
import imageCompression from "browser-image-compression";
import { editStore } from "../../axios/editStore";
import Strings from "../../i18n/strings/branches";
import { customNotify } from "../../helpers/customNotification/customNotification";
import { TranslationOptions } from "i18next";
const t = (key: string, options: TranslationOptions) =>
  i18n.t(key, { ns: "branches", ...options });

export const readAsBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (ev: any) => resolve(ev.target.result);
    reader.readAsDataURL(file);
  });
};

export const compressBase64Images = (
  imageFile: Blob | File
): Promise<string> => {
  return new Promise((resolve) => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    imageCompression(imageFile as any, options).then((cp) => {
      readAsBase64(cp).then((cpBase64) => resolve(cpBase64));
    });
  });
};

const background_color = (v) => Promise.resolve(v.hex);
const cover_image = (v) => compressBase64Images(v[0]);
const store_image = (v) => compressBase64Images(v[0]);
const processors = {
  background_color,
  cover_image,
  store_image,
};

function* editStoreSaga(action: IEditStoreAction) {
  const { changedData } = action.payload;
  try {
    const selectedToken = yield select(selectToken);
    const processValues = () =>
      Promise.all(
        changedData.map(({ newValue, key }) => {
          return new Promise(async (resolve) => {
            // {[key]: processors[key] ? processors[key](newValue) : newValue}
            if (processors[key]) {
              const resolvedProcessedValue = await processors[key](newValue);
              resolve({ [key]: resolvedProcessedValue });
            }
            resolve({ [key]: newValue });
          });
        })
      );
    const processedValues = yield call(processValues);
    const processedValuesObj = processedValues.reduce(
      (acc, value) => ({ ...acc, ...value }),
      {}
    );
    yield call(editStore, selectedToken, processedValuesObj);
    yield put(actions.editStoreSuccess());
    customNotify.success(t(Strings.brandSavedSuccess, { brandName: "" }));
  } catch (e) {
    yield put(actions.editStoreFailure(e));
    yield delay(500);
    for (const { key, oldValue } of changedData) {
      if (oldValue) {
        yield put(change("storeForm", key, oldValue));
      }
    }
    yield put(actions.resetEditFailed());
  }
}

export function* watchEditStoreSaga() {
  yield takeLatest(editStoreAction.requested, editStoreSaga);
}
