import advancedSettings from "./advancedSettings";

export default {
  fraudCenter: "Fraud Center",

  branchesAndCashiers: "Branches & Cashiers",
  brandSettings: "Brand Settings",
  dashboardUsers: "Dashboard Users",
  leaderBoard: "Leaderboard",
  reviews: "Reviews",
  reviewsResolution: "Issue Resolution",
  reviewsIssues: "Issues",
  orderingPortal: "Web Portal Ordering",
  brandListing: "Brand listing",
  pickup: "Ordering",
  pickupBranches: "Ordering Branches",
  orderHistory: "Order History",
  qrOrdering: "QR Ordering",
  pickupMenu: "Menu",
  myProfile: "My Profile",
  settings: "Settings",
  brandInfo: "Brand Info",
  brandVisuals: "Brand Visuals",
  menuItems: "Menu Items",
  giftList: "Gift List",
  socialLinks: "Social Links",
  convertingRatio: "Converting Ratio",
  support: "Support",
  help: "Help",
  logout: "Logout",
  main: "Main",
  myAccount: "My Account",
  branchesAndMain: "Branches & Main",
  advancedSettings: "Advanced settings",
  blockedUsers: advancedSettings.blockedUsers,
  menu: "Menu",
  home: "Home",
  feedback: "Feedback",
  adsManager: "Ads manager",
  orders: "Orders",
  insights: "Insights",
  transactions: "Transactions",
  redemptions: "Redemptions",
  customers: "Customers",
  branches: "Branches",
  profile_settings: "Profile Settings",
  giftingPoints: "Gifting Points",
  posSettings: "POS Settings",
};
