import i18n from "../../../../../../../i18n/i18n";
import Strings from "../../../../../../../i18n/Manager/strings/home";
import { translate } from "../../../../../../../helpers/translate";
const t = translate("home");
const LINE_GRAPH_ID = "orders-line-graph";
const CHART_TYPE = "line";
const BORDER_COLOR = "#f09440";
const BORDER_WIDTH = 2;
const FILL = true;
const POINT_BACKGROUND_COLOR = "#f09440";
const HOVER_BACKGROUND_COLOR = "#f09440";
const POINT_RADIUS = 2;
const BACKGROUND_COLOR = "rgba(240, 148, 64, 0.2)";

const TENSION = 0.3;

const toolTipWrapperTableStyles =
  "position: relative; left: 10px; top: -10px; width: 156px; height: 52px; background-color: #FFFFFF; color: #8E8E93; border: 1px solid #E5E5EA; box-sizing: border-box; box-shadow: 0px 2px 4px rgba(142, 142, 147, 0.12); font-size: 12px; font-weight: bold; padding: 8px;";

const TOOLTIP = {
  enabled: false,
  external: (context) => {
    const { tooltip } = context;
    const indexedDataset =
      tooltip && Array.isArray(tooltip.dataPoints)
        ? tooltip.dataPoints[0]
        : null;

    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = `<table style="${toolTipWrapperTableStyles}"></table>`;
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip (when hover leaves)
    let tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    // Set Text
    if (tooltipModel.body && indexedDataset) {
      let innerHtml = `<thead>`;

      innerHtml +=
        `<tr style="text-align:left;"><th style="width: 100%;"> <div style="margin-top: 4px; margin-left: 8px;">` +
        `${formatDate(
          indexedDataset.dataset.data[indexedDataset.dataIndex].date
        )}` +
        "</div></th></tr>";

      innerHtml += "</thead><tbody>";

      innerHtml += `<tr><td style="color: black; width: 100%; text-align: "center"">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; padding-left: 8px; padding-right: 8px;" >
      
      <div>
       ${t(Strings.orders_chart_tooltip, {
         orders:
           indexedDataset.dataset.data[indexedDataset.dataIndex].yAxisValue,
       })}
      
       </div>
       </div>
      </td>
       </tr>`;

      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      if (tableRoot) tableRoot.innerHTML = innerHtml;
    }

    let position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";
    // tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + "px " + tooltipModel.padding + "px";
    tooltipEl.style.pointerEvents = "none";
  },
};

const formatDate = (stringDate: string) => {
  const parsedDate = Date.parse(stringDate);
  const dateObj = new Date(parsedDate);

  const dayNo = new Intl.DateTimeFormat(i18n.language, {
    day: "2-digit",
  }).format(parsedDate);

  const monthName = new Intl.DateTimeFormat(i18n.language, {
    month: "short",
  }).format(parsedDate);

  const year = new Intl.DateTimeFormat(i18n.language, {
    year: "numeric",
  }).format(parsedDate);

  const day = dateObj.toLocaleString(i18n.language, { weekday: "short" });
  return `${day} ${dayNo} ${monthName} ${year}`;
};

const SCALES = {
  x: {
    barThickness: 34,
    grid: {
      display: false,
    },
    ticks: {
      autoSkip: true,
      maxTicksLimit: 12,
    },
  },
  y: {
    grid: {
      display: true,
      color: "#F2F2F7",
    },
    ticks: {
      callback: function (value, index, values) {
        return value >= 1000 ? `${value / 1000}k` : value;
      },
      stepSize: 1,
      precision: 10,
      crossAlign: "near",
      autoSkip: true,
      maxTicksLimit: 8,
    },
    min: 0,
  },
};

const PARSING = {
  yAxisKey: "yAxisValue",
  xAxisKey: "label",
};

const OPTIONS = {
  parsing: PARSING,
  responsive: true,
  plugins: {
    tooltip: TOOLTIP,
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0.3,
      hitRadius: 4,
    },
  },
  interaction: {
    mode: "point",
  },
  scales: SCALES,
};

export {
  LINE_GRAPH_ID,
  CHART_TYPE,
  BORDER_COLOR,
  BORDER_WIDTH,
  FILL,
  HOVER_BACKGROUND_COLOR,
  OPTIONS,
  POINT_BACKGROUND_COLOR,
  POINT_RADIUS,
  BACKGROUND_COLOR,
  TENSION,
};
