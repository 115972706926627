import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteLoyaltySpecialItemAction } from "../../../constants";
import { selectToken } from "../../selectors";
import { deleteSpecialItem } from "../../../axios/deleteSpecialItem";
import { union } from "ts-action";
import {
  deleteLoyaltySpecialItem,
  deleteLoyaltySpecialItemFailure,
  deleteLoyaltySpecialItemSuccess,
} from "../../actions";

const actionTypes = union({
  deleteLoyaltySpecialItem,
});
function* deleteLoyaltySpecialItemSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    yield call(deleteSpecialItem, token, payload);
    yield put(deleteLoyaltySpecialItemSuccess(payload));
  } catch (error) {
    yield put(deleteLoyaltySpecialItemFailure(error as Error));
  }
}

export function* watchDeleteLoyaltySpecialItemSaga() {
  yield takeLatest(
    deleteLoyaltySpecialItemAction.requested,
    deleteLoyaltySpecialItemSaga
  );
}
