import axios from "../../axios";
import * as routes from "../../api-routes/Manager/Analytics";

export const getTodaysTotalSalesReq = (token) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTodaysTotalValue(
      "sales"
    )}`,
    {
      headers: {
        token,
      },
    }
  );
};

export const getTodaysTotalOrdersReq = (token) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTodaysTotalValue(
      "orders"
    )}`,
    {
      headers: {
        token,
      },
    }
  );
};

export const getTodaysTotalRedemptionsReq = (token) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTodaysTotalValue(
      "redemptions"
    )}`,
    {
      headers: {
        token,
      },
    }
  );
};

export const getTodaysTotalCustomersReq = (token) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTodaysTotalValue(
      "today_customers"
    )}`,
    {
      headers: {
        token,
      },
    }
  );
};
