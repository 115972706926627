import * as actions from "../../constants/actions";
import { IModifiersGroup, IModifier, ICreateModifiersGroup } from "../../lib";
import { action, payload } from "ts-action";
import { optAction, optReaction } from "./lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const getOrderingModifierGroups = action(
  actions.getOrderingModifierGroupsAction.requested,
  payload<string>()
);
export const getOrderingModifierGroupsSuccess = action(
  actions.getOrderingModifierGroupsAction.fulfilled,
  payload<IModifiersGroup[]>()
);
export const getOrderingModifierGroupsFailure = action(
  actions.getOrderingModifierGroupsAction.rejected,
  payload<Error>()
);

export const createOrderingSuggestedModifiersGroup = optAction(
  actions.createOrderingSuggestedModifiersGroupsAction.requested,
  payload<ICreateModifiersGroup>()
);

export const createOrderingSuggestedModifiersGroupSuccess = optReaction(
  actions.createOrderingSuggestedModifiersGroupsAction.fulfilled,
  payload<IModifiersGroup>(),
  COMMIT
);

export const createOrderingSuggestedModifiersGroupFailure = optReaction(
  actions.createOrderingSuggestedModifiersGroupsAction.rejected,
  payload<Error>(),
  REVERT
);

export const getSuggestedModifiersGroups = action(
  actions.getSuggestedModifiersGroupsAction.requested
);
export const getSuggestedModifiersGroupsSuccess = action(
  actions.getSuggestedModifiersGroupsAction.fulfilled,
  payload<IModifiersGroup[]>()
);
export const getSuggestedModifiersGroupsFailure = action(
  actions.getSuggestedModifiersGroupsAction.rejected,
  payload<Error>()
);

export const createOrderingModifiersGroups = optAction(
  actions.createOrderingModifiersGroupsAction.requested,
  payload<IModifiersGroup>()
);
export const createOrderingModifiersGroupsSuccess = optReaction(
  actions.createOrderingModifiersGroupsAction.fulfilled,
  payload<IModifiersGroup>(),
  COMMIT
);
export const createOrderingModifiersGroupsFailure = optReaction(
  actions.createOrderingModifiersGroupsAction.rejected,
  payload<Error>(),
  REVERT
);

export const deleteOrderingSuggestedModifiersGroup = optAction(
  actions.deleteOrderingSuggestedModifiersGroupAction.requested,
  payload<string>()
);
export const deleteOrderingSuggestedModifiersGroupSuccess = optReaction(
  actions.deleteOrderingSuggestedModifiersGroupAction.fulfilled,
  payload<IModifiersGroup>(),
  COMMIT
);
export const deleteOrderingSuggestedModifiersGroupFailure = optReaction(
  actions.deleteOrderingSuggestedModifiersGroupAction.rejected,
  payload<Error>(),
  REVERT
);

export const editOrderingSuggestedModifiersGroup = optAction(
  actions.editOrderingSuggestedModifiersGroupAction.requested,
  payload<IModifiersGroup>()
);
export const editOrderingSuggestedModifiersGroupSuccess = optReaction(
  actions.editOrderingSuggestedModifiersGroupAction.fulfilled,
  payload<IModifiersGroup>(),
  COMMIT
);
export const editOrderingSuggestedModifiersGroupFailure = optReaction(
  actions.editOrderingSuggestedModifiersGroupAction.rejected,
  payload<Error>(),
  REVERT
);

export const deleteOrderingModifier = action(
  actions.deleteOrderingModifier.requested,
  payload<string>()
);

export const deleteOrderingModifierSuccess = action(
  actions.deleteOrderingModifier.fulfilled,
  payload<IModifier>()
);
export const deleteOrderingModifierFailure = action(
  actions.deleteOrderingModifier.rejected,
  payload<Error>()
);
