import axios from "./axios";
import * as Requests from "./requests";
import { IItem } from "../lib";

export const getOrderingMenuItems = (
  token,
  category_id,
  branch_id?: string
) => {
  return axios.get<IItem[]>(Requests.ORDERING_MENU_ITEMS_API, {
    params: {
      category_id,
      branch_id,
    },
    headers: {
      token,
    },
  });
};
