import React from "react";
interface IProps {
  clicked: boolean;
}
const AdsManagerIcon = ({ clicked }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke={clicked ? "#ffa351" : "#aeaeb2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.667 5l11.666-3.333v11.666L6.667 10M6.667 10V5h-5v5h5zM4.167 10v8.333"
      />
    </svg>
  );
};

export default AdsManagerIcon;
