import moment from "moment";
import { translate } from "../../../../../helpers/translate";
import i18n from "../../../../../i18n/i18n";
import Strings from "../../../../../i18n/Manager/strings/insights";
import { numberFormatter } from "../../../../../Utils/numbers";

const t = translate("insights");

const IN_BRANCH_ORDER_TYPE = {
  dine_in: t(Strings.dine_in),
  take_away: t(Strings.takeaway)
}

export const transactionTypeLabel = {
  pickup: t(Strings.pickup),
  delivery: t(Strings.delivery),
  webPortal: t(Strings.web_portal),
  offline: t(Strings.offline),
};

export const columns = (currency) => [
  {
    title: t(Strings.transaction_time),
    dataIndex: "",
    key: "",
    render: (obj) => (
      <span>
        {moment(obj.transaction_time)
          .locale(i18n.language.includes("ar") ? "ar" : "en")
          .format("DD MMM YYYY - hh:mm a")}
      </span>
    ),
  },
  {
    title: t(Strings.branch),
    render: (obj) => (
      <span>
        {i18n.language.includes("ar") && obj.branch_ar_name
          ? obj.branch_ar_name
          : obj.branch_en_name}
      </span>
    ),
    key: i18n.language.includes("en") ? "branch_en_name" : "branch_ar_name",
    sorter: false,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: t(Strings.type),
    dataIndex: "",
    key: "",
    render: (obj) => (
      <div>
        <span>{transactionTypeLabel[obj.order_type] || obj.order_type}</span>
        {
          obj.in_branch_order_type && (
            <span style={{
              color: "#38c172",
            }}>{IN_BRANCH_ORDER_TYPE[obj.in_branch_order_type]}</span>
          )
        }
      </div>
    ),
    sorter: false,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: t(Strings.customer),
    dataIndex: "",
    render: (obj) => (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{
            width: "32px",
            height: "32px",
            backgroundColor: "#ccc",
            borderRadius: "50%",
            margin: i18n.language === "ar" ? "0 0 0 8px" : "0 8px 0 0",
          }}
        >
          {obj.customer_image ? (
            <img
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
              }}
              src={process.env.REACT_APP_ENDPOINT + obj.customer_image}
            />
          ) : null}
        </div>
        <span style={{ fontSize: "14px", color: "#1C1C1E" }}>
          {obj.customer_name}
        </span>
      </div>
    ),
    key: "",
    sorter: false,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: t(Strings.price),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => (
      <span>
        {" "}
        {numberFormatter(Number(obj.receipt))} {currency}
      </span>
    ),
  },
  {
    title: t(Strings.points),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => <span>{obj.points || 0}</span>,
  },
  {
    title: "Payment method",
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => (
      <span style={{ fontSize: "14px" }}>{obj.payment_method}</span>
    ),
  },
];
