export default {
  openingHour: "openingHour",
  preparationTime: "preparationTime",
  delivery: "delivery",
  brandCat: "brandCat",
  brandTags: "brandTags",
  coverPhoto: "coverPhoto",
  friday: "friday",
  saturday: "saturday",
  posTitle: "posTitle",
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  AM: "AM",
  PM: "PM",
  minimumOrderValue: "minimumOrderValue",
  selectTimeZoneLabel: "selectTimeZoneLabel",
  setUpPrepTimeTitle: "setUpPrepTimeTitle",
  setUpPrepTimeSubTitle: "setUpPrepTimeSubTitle",
  from: "from",
  to: "to",
  minutes: "minutes",
  description: "description",
  setUpDeliveryTitle: "setUpDeliveryTitle",
  setUpDeliverySubTitle: "setUpDeliverySubTitle",
  time: "time",
  cost: "cost",
  uploadPhoto: "uploadPhoto",
  done: "done",
  unsupported: "unsupported",
  routingMethod: "routingMethod",
  deliveryMethod: "deliveryMethod",
  paymentMethodTitle: "paymentMethodTitle",
  branchesWorkingHours: "Branches Working Hours",
  pointOfSale: "pointOfSale",
  pleaseEnterGroupId: "pleaseEnterGroupId",
  pleaseEnterPaymentMethodId: "pleaseEnterPaymentMethodId",
  groupId: "groupId",
  paymentMethodId: "paymentMethodId",
  save: "save",
  cancel: "cancel",
  discard: "discard",
  posWarningModalText: "posWarningModalText",
  posSettingsUpdatedSuccessfully: "posSettingsUpdatedSuccessfully",
  somethingWentWrong: "somethingWentWrong",
  posSettings: "posSettings",
  posSettingsDescription: "posSettingsDescription",
  posEntitiesSettings: "posEntitiesSettings",
  posEntitiesSettingsDescription: "posEntitiesSettingsDescription",
  selectPOSEntity: "selectPOSEntity",
  menuGroupID: "menuGroupID",
  onlinePaymentMethodID: "onlinePaymentMethodID",
  whatIsThisAndWhyDoINeedIt: "whatIsThisAndWhyDoINeedIt",
  offlineOnlyMenuGroupIdHint_1: "offlineOnlyMenuGroupIdHint_1",
  offlineOnlyMenuGroupIdHint_2: "offlineOnlyMenuGroupIdHint_2",
  offlineOnlyMenuGroupId: "offlineOnlyMenuGroupId",
  offlineOnlyMenuGroupIdPlaceholder: "offlineOnlyMenuGroupIdPlaceholder",
  optional: "optional",
};