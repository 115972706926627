export default {
  menu: "Import your Menu",
  giftList: "Create your Gift List",
  convertingRatio: 'Set your "Cash-to-Points" Rate',
  finished: "Congratulations!",
  nameBio: "Name & Bio",
  brandVisuals: "Brand Visuals",
  socialLinks: "Social Links",
  branches: "Main Listing",
  errorRetrying: "An error has occurred, retrying...",
  unauthorized: "Unauthorized access",
};
