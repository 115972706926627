import React, { useState, useMemo } from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import ZonesModal from "./ZonesModal";
import { IDeliveryZone } from "../../../../../../../../redux-store/actions/lib";
import { useTranslation } from "../../../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import Strings from "../../../../../../../../i18n/strings/orderHistory";
import { useRtlClass } from "../../../../../../../../../../shopx-shared-components/hooks/useRtlClassName";
interface IProps {
  delivery_zones: IDeliveryZone[];
  delivery_fees: number;
  currency: string;
  delivery_time: number;
}

const DeliveryInformation: React.FC<IProps> = ({
  delivery_zones,
  delivery_fees,
  currency,
  delivery_time,
}) => {
  const [open, setOpen] = useState(false);
  const openModalHandler = () => setOpen(true);
  const closeModalHandler = () => setOpen(false);
  const { t } = useTranslation("orderHistory");
  const deliveryZonesCount = useMemo(() => delivery_zones.length, [
    delivery_zones,
  ]);
  const seeMore = useMemo(() => delivery_zones.length - 1, [delivery_zones]);
  const rtl = useRtlClass(styles);
  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      <span className={styles.title}>{t(Strings.deliveryInformation)}</span>
      <div className={styles.deliveryWrapper}>
        <div className={styles.deliveryCostWrapper}>
          <img
            className={styles.img}
            src={require("../../../../../../../../assets/coin.svg").default}
          />
          <div className={styles.costWrapper}>
            <span className={styles.costTitle}>{t(Strings.deliveryCost)}</span>
            <span className={styles.costSubTitle}>
              {delivery_fees} {currency}
            </span>
          </div>
        </div>
        <div style={{ margin: "0 20px" }} />
        <div className={styles.deliveryCostWrapper}>
          <img
            className={styles.img}
            src={require("../../../../../../../../assets/clock.svg").default}
          />
          <div className={styles.costWrapper}>
            <span className={styles.costTitle}>{t(Strings.deliveryTime)}</span>
            <span className={styles.costSubTitle}>
              {delivery_time} {t(Strings.min)}
            </span>
          </div>
        </div>
      </div>

      <span className={styles.zonesCount}>
        {t(Strings.deliveryTime)} ({deliveryZonesCount})
      </span>
      <div className={styles.zonesWrapper}>
        {delivery_zones && delivery_zones[0] && (
          <div>
            <span className={styles.city}>
              {rtl
                ? delivery_zones[0].city_ar_name
                : delivery_zones[0].city_en_name}
            </span>
            <span className={styles.area}>
              {rtl
                ? delivery_zones[0].district_ar_name
                : delivery_zones[0].district_en_name}{" "}
              -{" "}
              {rtl
                ? delivery_zones[0].sub_district_ar_name
                : delivery_zones[0].sub_district_en_name}
            </span>
          </div>
        )}
      </div>
      {seeMore !== 0 && (
        <span onClick={openModalHandler} className={styles.seeMore}>
          {t(Strings.seeMoreTrans, { seeMore })}
        </span>
      )}
      {open && (
        <ZonesModal
          delivery_zones={delivery_zones}
          open={open}
          onClose={closeModalHandler}
        />
      )}
    </div>
  );
};

export default hot(module)(DeliveryInformation);
