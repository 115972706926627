import { OrderingPortalStatus } from "../../../types";

export type QRPortalTypes = "dine_in" | "take_away";
export interface ITotalOrders {
  total: string;
  change: string;
}
export interface IMenu {
  vat: number;
  name: string;
  created_at: number;
  id: string;
  updated_at: number;
}

export interface IOrderingMenuCategory {
  en_name?: string;
  created_at: number;
  ar_name?: string;
  is_bundle?: number;
  id: string;
  updated_at: number;
  order: number;
  total_items: number;
}

export interface IEditCategory {
  order_id: string;
  menu_id: string;
  ar_name?: string;
  en_name?: string;
  is_bundle?: number;
  order: number;
}

export enum DeliveryMethods {
  in_house_delivery = "in_house_delivery",
  third_party_delivery = "third_party_delivery",
}
export interface IStorePickup {
  web_portal_status: OrderingPortalStatus;
  is_call_center?: boolean;
  pickup_prep_min: number;
  pickup_prep_max: number;
  pickup_cover_image: string;
  updated_at: number;
  timezone: string;
  payment_methods: string[];
  created_at: number;
  id: string;
  pickup_status: string;
  pickup_settings_completed: boolean;
  delivery_settings_completed: boolean;
  delivery_method: DeliveryMethods;
}

export enum RoutingMethod {
  standalone = "standalone",
  call_center = "call_center",
  pos = "pos",
}
export interface IGeneral {
  koinz_main_group_id?: string
  pickup_prep_min?: number;
  pickup_prep_max?: number;
  country_iso_code?: string;
  web_portal_status: OrderingPortalStatus;
  is_call_center?: boolean;
  pickup_cover_image?: string;
  delivery_time?: number;
  payment_methods: string[];
  delivery_fees?: number;
  updated_at: number;
  timezone: string;
  routing_method?: RoutingMethod;
  created_at: number;
  id: string;
  pickup_status: string;
  pickup_settings_completed: boolean;
  delivery_settings_completed: boolean;
  delivery_management_status?: "enabled" | "disabled";
  delivery_method?: DeliveryMethods;
}

export interface IBrandCategories {
  name_ar: string;
  name_en: string;
  inactive_image: string;
  active_image: string;
  id: string;
}
export interface IBrandTags {
  name_ar: string;
  name_en: string;
  id: string;
}

export interface IInterval {
  end_day: string;
  start_time: string;
  start_day: string;
  end_time: string;
}

interface IGetInterval extends IInterval {
  created_at: number;
  updated_at: number;
  id: string;
}

export interface IWorkingHours {
  intervals: IGetInterval[];
}

export enum FormSubmissionStateTypes {
  submitting = "submitting",
  submitted = "submitted",
  failed = "failed",
  initial = "initial",
}

export interface IMinOrderValue {
  minimum_order_value_enabled: number;
  minimum_order_value?: number;
}
