import moment from "moment";
import { CustomerBox } from "../CustomerBox";
import { translate } from "../../../../../helpers/translate";
import Strings from "../../../../../i18n/Manager/strings/insights";
import i18n from "../../../../../i18n/i18n";
import styles from "./styles.module.sass";
import { Menu, Dropdown } from "antd";
import { useMemo, useState } from "react";
import GenericConfirmationModal from "../../../../../Components/GenericConfirmationModal";
import {
  removeFraudlentCustomerPointsRequested,
  reviewFraudlentCustomerRequested,
} from "../../../../../redux-store/actions/edit-fraud-list-actions";
import { useDispatch } from "react-redux";
import { useSelect } from "../../../../../hooks/useSelect";
import { blockFraudlentCustomerRequested } from "../../../../../redux-store/actions/edit-fraud-list-actions";

const t = translate("insights");

export const columns = [
  {
    title: t(Strings.customer),
    dataIndex: "",
    key: "",
    render: (obj) => {
      return (
        <div
          onClick={() => obj.onClick(obj.customer_id)}>
          <CustomerBox
            name={obj.phone_number}
            subTitle={`${t(Strings.lastVisit)}: ${moment(
              Number(obj.last_visit_date) * 1000
            )
              .locale(i18n.language.includes("ar") ? "ar" : "en")
              .format("DD MMMM YYYY - h : mm A")}`}
          />
        </div>
      );
    },
    // width: 320,
  },
  {
    // title: t(Strings.online_orders),
    title: t(Strings.numOfTransactions),
    dataIndex: "",
    key: "number_of_visits",
    sorter: false,
    render: (obj) => <span onClick={() => obj.onClick(obj.customer_id)}>{obj.number_of_visits || 0}</span>,
  },
  {
    // title: t(Strings.visits),
    title: t(Strings.currentPoints),
    dataIndex: "",
    key: "current_points",
    // sorter: true,
    render: (obj) => <span onClick={() => obj.onClick(obj.customer_id)}>{obj.current_points || 0}</span>,
  },
  {
    // title: t(Strings.current_points),
    title: t(Strings.fraudRank),
    dataIndex: "",
    key: "fraud_rank",
    sorter: false,
    render: (obj) => (

      <span onClick={() => obj.onClick(obj.customer_id)} className={`${styles.rank} ${styles[obj?.fraud_rank?.toLowerCase()]}`}>
        {t(Strings[obj.fraud_rank?.toUpperCase()])}
      </span>
    ),
  },
  {
    // title: t(Strings.life_time_points),
    title: t(Strings.reason),
    dataIndex: "",
    key: "fraud_reason",
    sorter: false,
    render: (obj) => <span onClick={() => obj.onClick(obj.customer_id)}>{obj.fraud_reason}</span>,
  },
  {
    // title: t(Strings.life_time_points),
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
          padding: "0px 32px ",
        }}
      >
        <span> {t(Strings.action)}</span>
      </div>
    ),
    dataIndex: "",
    key: "life_time_points",
    sorter: false,
    render: (obj) => {
      return (
        <>
          <ActionsContainer state={obj.state} phone_number={obj.phone_number} is_reviewed={obj.is_reviewed} />
        </>
      );
    },
  },
];

const ActionsContainer: React.FC<{
  is_reviewed: 1 | 0,
  phone_number: string,
  state: "none" | "points-remove" | "blocked" | "reviewed";
}> = ({ is_reviewed, state, phone_number }) => {
  const t = translate("insights")
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertType, setShowAlertType] = useState<"remove" | "block">(
    "block"
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const loadingStatus = useSelect(
    (state) => state.fraudlentCustomersReducer.status
  );
  const isBlocked = useMemo(() => { return state === "blocked" }, [state])
  const isLoading = useMemo(() => loadingStatus === "loading", [loadingStatus]);
  const dispatch = useDispatch();
  const alertBehavior = () => {
    if (showAlertType === "block") {
      dispatch(blockFraudlentCustomerRequested({ phone_number }));
    } else {
      dispatch(removeFraudlentCustomerPointsRequested({ phone_number }));
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <div
        className={`${styles.dropdownContent} ${styles.content} `}
        style={{ padding: "10px 0" }}
      >
        {/* mark as reviewed */}
        {state === "none" && is_reviewed === 0 && (
          <button
            onClick={() => {
              dispatch(reviewFraudlentCustomerRequested({ phone_number, body: { is_reviewed: is_reviewed ? 0 : 1 } }));
            }}
            style={{
              padding: "15px 10px",
              fontSize: "16px",
              color: "#fff",
              borderRadius: "5px",
              textAlign: "center",
              background: "#FFA351",
            }}
          >
            {t(Strings.markAsReviewed)}

          </button>
        )}
        {/* reviewed  */}
        {is_reviewed === 1 && !["blocked", "points-remove"].includes(state) && (
          <span
            style={{
              padding: "15px 0px",
              fontSize: "16px",
              borderRadius: "5px",
              textAlign: "center",
              color: "#38C172",
            }}
          >
            {t(Strings.reviewed)}
          </span>
        )}
        {state === "points-remove" && (
          <span
            style={{
              padding: "15px 0px",
              fontSize: "16px",
              borderRadius: "5px",
              textAlign: "center",
              color: "#EE5253",
            }}
          >
            {t(Strings.pointsRemoved)}

          </span>
        )}
        {/* blocked */}
        {state === "blocked" && (
          <span
            style={{
              padding: "15px 0px",
              fontSize: "16px",
              borderRadius: "5px",
              textAlign: "center",
              color: "#EE5253",
            }}
          >
            {t(Strings.blocked)}
          </span>
        )}
      </div>
      {
        !isBlocked &&
        <Dropdown
          onVisibleChange={() => {
            setShowDropdown(!showDropdown);
          }}
          trigger={["click"]}
          visible={showDropdown}
          placement="bottomRight"
          overlayStyle={{ background: "#fff", justifyContent: "flex-end" }}
          overlay={
            <Menu
              style={{
                borderRadius: "8px",
                background: "#fff !important",
                padding: ".5px 0 5px 0",
                boxShadow:
                  "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
              }}
            >
              {
                !!is_reviewed && !["blocked", "points-remove"].includes(state) ?
                  <Menu.Item
                    onClick={() => {
                      dispatch(reviewFraudlentCustomerRequested({ phone_number, body: { is_reviewed: is_reviewed ? 0 : 1 } }));
                    }}
                    style={{
                      width: "auto",
                      padding: "10px 25px",
                      borderBottom: "solid  1px #f2f2f7",
                    }}
                  >
                    {t(Strings.unreviewed)}
                  </Menu.Item>
                  : <>
                    <Menu.Item
                      onClick={() => {
                        setShowAlertType("block");
                        setShowAlert(!showAlert);
                      }}
                      style={{
                        width: "auto",
                        padding: "10px 25px",
                        borderBottom: "solid  1px #f2f2f7",
                      }}
                    >
                      {t(Strings.blockCustomer)}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setShowAlertType("remove");
                        setShowAlert(!showAlert);
                      }}
                      style={{
                        width: "auto",
                        padding: "10px 25px",
                        borderBottom: "solid  1px #f2f2f7",
                      }}
                    >
                      {t(Strings.removePointsCta)}
                    </Menu.Item>
                  </>
              }
            </Menu>
          }
        >
          <div className={`${styles.dropdownContent} ${styles.content}`}>
            <button
              className={styles.btn}
              disabled={isBlocked}
              onClick={() => {
                if (!isBlocked) setShowDropdown(!showDropdown);
              }}
            >
              <img
                style={{
                  margin: i18n.language === "ar" ? "0 8px 0 0" : "0 0 0 8px",
                }}
                className={styles.img}
                src={require("../../../../../assets/dots-img.svg").default}
              />
            </button>
          </div>

        </Dropdown>
      }
      {isBlocked &&
        <div className={`${styles.dropdownContent} ${styles.content}`} style={{ padding: "22px 15px", }} >
          <button
            className={`${styles.btn} ${styles.disabled}`}
            disabled={isBlocked}
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <img
              style={{
                margin: i18n.language === "ar" ? "0 8px 0 0" : "0 0 0 8px",
              }}
              className={styles.img}
              src={require("../../../../../assets/dots-img.svg").default}
            />
          </button>
        </div>}

      <GenericConfirmationModal
        isLoading={isLoading}
        modal={showAlert}
        toggle={() => setShowAlert(!setShowAlert)}
        alertType={showAlertType}
        behavior={() => alertBehavior()}
      />
    </div>
  );
};
