import React, { useEffect, useMemo, useRef } from "react";
import styles from "./styles.module.sass";
import "./styles.less";
import { useSelect } from "../../../../hooks/useSelect";
import {
  ColdDrinkIcon,
  TeaIcon,
  EspressoIcon,
  PickupIcon,
  CellularWifiBatteryIcon,
  TimeIcon,
  ChevronIcon,
  SunIcon,
  TabBarIcon,
  Macchiato,
  Cortado,
} from "../../../../assets/white-label";
import { Carousel } from "antd";
import { useDispatch } from "react-redux";
import { selectBannerCard } from "../../../../redux-store/actions/brand-visuals-actions";
import { CarouselRef } from "antd/lib/carousel";
import { useRtlClass } from "../../../../lib";
import { mapById } from "../../../../Utils";

export const WhiteLabelMobileMockup: React.FC = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const { bannerCards, selectedBannerCard, bannerCardModalVisible } = useSelect(
    (state) => state.bannerCardsReducer
  );
  const store = useSelect((state) => state.storeReducer.store);
  const rtl = useRtlClass(styles);

  const storeImage = useMemo(
    () => process.env.REACT_APP_IMAGE_ENDPOINT + store?.store_image,
    [store?.store_image]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--active-dot-color",
      store.background_color
    );
  }, [store.background_color]);

  useEffect(() => {
    if (
      carouselRef.current &&
      typeof selectedBannerCard?.id !== "undefined" &&
      !bannerCardModalVisible
    ) {
      carouselRef.current?.goTo(selectedBannerCard.order);
    }
  }, [selectedBannerCard, bannerCardModalVisible, carouselRef.current]);
  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      <div className={styles.notch}>
        <div className={styles.camera} />
        <div className={styles.speaker} />
      </div>
      <div className={styles.statusBar}>
        <img src={TimeIcon} />
        <img src={CellularWifiBatteryIcon} />
      </div>
      <div className={styles.headerWrapper}>
        <img src={storeImage} className={styles.storeLogo} />
        <div className={styles.storeInfoWrapper}>
          <PickupIcon color={store?.background_color} />
          <div className={styles.storeInfo}>
            <span className={styles.branchName}>Riyadh - Muruj...</span>
            <img src={ChevronIcon} />
          </div>
        </div>
      </div>
      <div className={styles.welcome}>
        <span className={styles.welcomeText}>
          Good Morning, <span className={styles.userName}>Ahmed</span>
        </span>
        <img src={SunIcon} />
      </div>
      <div className={styles.bannerCardsWrapper}>
        <div className={styles.bannerCardPlaceholder}>
          <Carousel
            dots={{ className: "custom-dots" }}
            className="custom-carousel"
            ref={carouselRef}
            afterChange={(order) => {
              dispatch(
                selectBannerCard({
                  banner_card_id: bannerCards[order]?.id,
                  order: bannerCards[order]?.order,
                }))
            }
            }
          >
            {bannerCards
              .sort((a, b) => a.order - b.order)
              .filter((bannerCard) => bannerCard.image)
              .map((bannerCard) => (
                <div
                  key={bannerCard.order}
                  onClick={() =>
                    dispatch(
                      selectBannerCard({
                        banner_card_id: bannerCard.id,
                        order: bannerCard.order,
                      })
                    )
                  }
                  className={styles.bannerCard}
                >
                  <img
                    src={bannerCard.image}
                    className={styles.bannerCardImage}
                  />
                </div>
              ))}
          </Carousel>
        </div>
      </div>
      <div style={{ height: "30px" }} />
      <div className={styles.drinks}>
        <EspressoIcon color={store?.background_color} />
        <img src={TeaIcon} />
        <img src={ColdDrinkIcon} />
      </div>
      <span className={styles.drinkName}>Espresso</span>
      <div className={styles.drinkCards}>
        <Macchiato color={store?.background_color} />
        <Cortado color={store?.background_color} />
      </div>
      <TabBarIcon color={store?.background_color} className={styles.tab} />
    </div>
  );
};

export default WhiteLabelMobileMockup;
