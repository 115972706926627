import React, { useState } from "react";
import styles from "./styles.module.sass";
import { IPointsOperation } from "../../../axios/getUserProfile";
import { useGetCurrency, useLocalizedMoment } from "../../../Utils";
import { translate, InjectedTranslateProps } from "react-i18next";
import Strings from "../../../i18n/strings/leaderboard";
import { Mutations } from "../../../Queries/PointsOperation/index";
import { DeleteOperationPopup } from "./components";
import { MutationStatus } from "react-query";
import { useDeepCompareEffect } from "use-deep-compare";
interface IProps {
  pointsOperation: IPointsOperation;
  deletePointsOperations: (operation_id) => void;
  deletePointsOperationStatus: MutationStatus;
}

const PointsOperationCard: React.FC<IProps & InjectedTranslateProps> = (
  props
) => {
  const {
    pointsOperation,
    t,
    deletePointsOperations,
    deletePointsOperationStatus,
  } = props;
  const currency = useGetCurrency();
  const [openDeleteOperationPopup, setOpenDeleteOperationPopup] =
    useState(false);
  const [showInvoiceInput, setShowInvoiceInput] = useState(false);
  const [invoiceValue, setInvoiceValue] = useState<number>(0);
  useDeepCompareEffect(() => {
    setInvoiceValue(pointsOperation.price);
  }, [pointsOperation]);
  const handleShowInvoiceInput = () => setShowInvoiceInput(!showInvoiceInput);
  const handleInvoiceChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInvoiceValue(Number(e.target.value));
  const { mutate, status } = Mutations.useEditPointsOperations();
  const handleEditInvoiceSubmit = (e) => {
    e.preventDefault();
    mutate({
      operation_id: pointsOperation.id,
      price: invoiceValue,
    });
    setShowInvoiceInput(false);
  };
  const handleCloseDeleteOperationPopup = () =>
    setOpenDeleteOperationPopup(false);
  return (
    <div className={styles.cardWrapper}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={styles.date}>
          <img
            className={styles.logo}
            src={require("../../../assets/icon-time.svg").default}
          />
          <p className={styles.text}>
            {useLocalizedMoment(pointsOperation.created_at, "x").format(
              "DD MMM, YYYY, hh:mm a"
            )}
          </p>
        </div>
        {openDeleteOperationPopup && (
          <DeleteOperationPopup
            status={deletePointsOperationStatus}
            deletePointsOperations={() =>
              deletePointsOperations(pointsOperation.id)
            }
            open={openDeleteOperationPopup}
            onClose={handleCloseDeleteOperationPopup}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "16px", height: "16px", cursor: "pointer" }}
            src={require("../../../assets/edit.svg").default}
            onClick={handleShowInvoiceInput}
          />
          <img
            style={{
              width: "16px",
              height: "16px",
              marginLeft: "8px",
              cursor: "pointer",
            }}
            src={require("../../../assets/trash.svg").default}
            onClick={() => setOpenDeleteOperationPopup(true)}
          />
        </div>
      </div>

      {!!pointsOperation.is_compensation && (
        <div className={styles.compensation}>Compensation</div>
      )}
      <div className={styles.statsWrapper}>
        <div className={styles.status}>
          <h5 className={styles.statusLabel}>
            {t(Strings.customerReceiptPoints)}
          </h5>
          <p className={styles.statusValue}>{pointsOperation.points}</p>
        </div>
        <div className={styles.status}>
          <h5 className={styles.statusLabel}>
            {t(Strings.customerReceiptRemainingPoints)}
          </h5>
          <p className={styles.statusValue}>{pointsOperation.remaining}</p>
        </div>
        <div
          style={{ width: "100%", margin: ".5rem 0 0" }}
          className={styles.status}
        >
          <h5 className={styles.statusLabel}>{t(Strings.invoice)}</h5>
          {showInvoiceInput ? (
            <form onSubmit={handleEditInvoiceSubmit}>
              <input
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  outline: "none",
                  marginTop: "4px",
                  padding: "8px",
                  fontSize: "1.4rem",
                  fontWeight: 500,
                  color: "#2D2D2D",
                }}
                step={"any"}
                value={Number(invoiceValue).toString()}
                type="number"
                onChange={handleInvoiceChange}
              />
            </form>
          ) : (
            <p className={styles.statusValue}>
              {status === "success" ? invoiceValue : pointsOperation.price}{" "}
              {currency}
            </p>
          )}
        </div>
        {!pointsOperation.is_compensation && (
          <>
            {pointsOperation.branch_name && (
              <div className={styles.branchStatus}>
                <h5 className={styles.statusLabel}>{t(Strings.branch)}</h5>
                <p className={styles.statusValue}>
                  {pointsOperation.branch_name}
                </p>
              </div>
            )}
            {pointsOperation.cashier && (
              <div className={styles.cashierStatus}>
                <h5 className={styles.statusLabel}>{t(Strings.cashierName)}</h5>
                <p className={styles.statusValue}>
                  {pointsOperation.cashier.name}
                </p>
              </div>
            )}

            {pointsOperation.receipt_code && (
              <div className={styles.cashierStatus}>
                <h5 className={styles.statusLabel}>{t(Strings.receiptCode)}</h5>
                <p className={styles.statusValue}>
                  {pointsOperation.receipt_code}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default translate("leaderboard")(PointsOperationCard);
