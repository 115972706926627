import React from "react";
import styles from "./styles.module.sass";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { useToggleState } from "../../../Utils";

export const imagesURL = process.env.REACT_APP_IMAGE_ENDPOINT;

const Card: React.FC = () => {
  const [cardOptionsOpen, toggleCardOptions$] = useToggleState(false);
  const [, toggleEditModal] = useToggleState(false);
  const toggleCardOptions = (e: any) => {
    e.stopPropagation();
    toggleCardOptions$();
  };

  const isOptionsOpen = () => {
    return !cardOptionsOpen
      ? styles.switch
      : `${styles.switch} ${styles.shadow}`;
  };

  return (
    <div>
      <Dropdown isOpen={cardOptionsOpen} toggle={toggleCardOptions as any}>
        <DropdownToggle className={isOptionsOpen()}>
          <img src={require("../../../assets/3dots.svg").default} />
        </DropdownToggle>

        <DropdownMenu className={styles.menuWrapper}>
          <DropdownItem onClick={toggleEditModal} className={styles.item}>
            <p style={{ margin: 0, fontSize: "1.6rem" }}>Edit...</p>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Card;
