import React from "react";
import styles from "./styles.module.sass";
import { IRedemption } from "../../../axios/getUserProfile";
import { imagesURL } from "../Card";
import { useLocalizedMoment } from "../../../Utils";
import { translate, InjectedTranslateProps } from "react-i18next";
import Strings from "../../../i18n/strings/leaderboard";

interface IProps extends InjectedTranslateProps {
  redemptionOperation: IRedemption;
}

const RedemptionOperationCard: React.FC<IProps> = (props) => {
  const { redemptionOperation, t } = props;
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.date}>
        <img
          className={styles.logo}
          src={require("../../../assets/icon-time.svg").default}
        />
        <p className={styles.text}>
          {useLocalizedMoment(redemptionOperation.created_at, "x").format(
            "DD MMM, YYYY, hh:mm a"
          )}
        </p>
      </div>
      <div className={styles.statsWrapper}>
        <div className={styles.p_status}>
          <h5 className={styles.statusLabel}>
            {t(Strings.customerReceiptPoints)}
          </h5>
          <p className={styles.statusValue}>{redemptionOperation.points}</p>
        </div>
        <div className={styles.imgWrapper}>
          <img
            className={styles.img}
            src={imagesURL + redemptionOperation.image}
          />
          <p className={styles.imgName}>{redemptionOperation.name}</p>
        </div>
        <div className={styles.b_status}>
          <h5 className={styles.statusLabel}>{t(Strings.branch)}</h5>
          <p className={styles.statusValue}>
            {redemptionOperation.branch_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default translate("leaderboard")(RedemptionOperationCard);
