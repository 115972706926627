import React from "react";
import styles from  "./Login/styles.module.sass";
import loadable, { DefaultComponent } from "@loadable/component";
import { DefaultLoader } from "./Leaderboard/LoadingPageInfo";

export function loadableWithBounce<T>(
  component: () => Promise<DefaultComponent<T>>
) {
  return loadable(component, {
    fallback: (
      <div className={styles.wrapper}>
        <DefaultLoader />
      </div>
    ),
  });
}
