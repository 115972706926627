import cr from "./convertingRatio";

export default {
  back: "العودة",
  blockedUsersTitle: "العملاء المحظورون ({{count}})",
  fullName: "اﻹسم بالكامل",
  phoneNumber: "رقم الجوال",
  addBlockedUser: "أضف عميلاً لقائمة الحظر",
  blockListEmpty: "ليس لديك أي عملاء محظورين",
  addBlockedUserTitle: "إضافة عميل لقائمة الحظر",
  addBlockedUserButton: "حظر العميل",
  confirmBlockUserTitle: "قم بحظر العميل من نظام الولاء",
  confirmBlockUserSubTitlePart1: " لن يستطيع العميل القيام بعمليات ",
  confirmBlockUserSubTitlePart2: " استلام النقاط وتبديل ",
  confirmBlockUserSubTitlePart3: " الهدايا ",
  confirmBlockUserSubTitlePart4: " وسيتم حذف جميع ",
  confirmBlockUserSubTitlePart5: "عملياته",
  customerNoName: "بلا اسم",
  confirmBlockUserButton: "حظر العميل",
  cancelBlockUser: "إلغاء",
  confirmUnBlockUserTitle: "الغاء حظر ",
  confirmUnBlockUserSubTitle: "هل أنت متأكد من أنك تريد إلغاء حظر {{name}}؟",
  confirmUnBlockUserButton: "نعم قم بإلغاء الحظر",
  cancelUnBlockUser: "لا",
  unBlockUserButton: "إلغاء الحظر",
  somethingWentWrong: cr.somethingWentWrong,
  errorPhoneInvalid: "رقم الجوال غير صحيح",
  errorPhoneExists: "هذا الرقم تم حظره من قبل!",
  userBlockedSuccess: "تم حظر هذا الرقم بنجاح!",
};
