export const COUNTRIES = {
  en: {
    eg: "Egypt",
    sa: "Saudi Arabia",
    ae: "United Arab Emirates",
    om: "Oman",
    kw: "Kuwait",
    in: "India"
  },
  ar: {
    eg: "مصر",
    sa: "المملكة العربية السعودية",
    ae: "الامارات العربية المتحدة",
    om: "عمان",
    kw: "الكويت",
    in: "الهند"
  }
};
