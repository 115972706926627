import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { useGetStoreData } from "../../../hooks/login-hook";
import { loginSuccess } from "../../../redux-store/actions";

const PreviewModeAuthenticationScreen: React.FC = () => {
  const location = useLocation();
  const [tokenForHook, setTokenForHook] = useState<string>("");
  const { storeData, token } = useGetStoreData(tokenForHook);

  const parsedToken = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("token") as string;
  }, []);

  const parsedStoreId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("store_id") as string;
  }, []);

  const parsedIsRemoteAuthentication = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("is_remote_authentication") as string;
  }, []);

  const parsedAccountManagerRole = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("role")?.includes("account_manager");
  }, [])

  useEffect(() => {
    if (storeData && token && !parsedAccountManagerRole) {
      localStorage.setItem("token", token);
      loginSuccess({ ...storeData, token });
      window.location.href = "/#/home";
    }
  }, [storeData, token, parsedAccountManagerRole]);
  useEffect(() => {
    if (storeData && token && parsedAccountManagerRole) {
      localStorage.setItem("token", token);
      loginSuccess({ ...storeData, token });
      window.location.href = "/#/settings/users";
    }
  }, [storeData, token, parsedAccountManagerRole])
  useEffect(() => {
    if (parsedStoreId && parsedIsRemoteAuthentication) {
      const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
      const isRemoteAuthentication = localStorage.getItem(
        "isRemoteAuthentication"
      );
      const storeId = localStorage.getItem("storeId");
      // check if the data already stored
      if (
        isKoinzAdminRemote &&
        storeId === parsedStoreId &&
        isRemoteAuthentication
      ) {
        // complete the remote login as koinz admin
        setTokenForHook(parsedToken);
      } else {
        localStorage.setItem("isKoinzAdminRemote", "true");
        localStorage.setItem("storeId", parsedStoreId);
        localStorage.setItem("token", parsedToken);
        localStorage.setItem(
          "isRemoteAuthentication",
          parsedIsRemoteAuthentication
        );
        window.location.reload();
      }
    }
  }, [parsedStoreId, parsedIsRemoteAuthentication]);

  const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Spin indicator={antIcon} />
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          Authenticating as admin in preview mode...
        </span>
        <span style={{ color: "#8e8e93", fontSize: "1.3rem" }}>
          Please wait, you will be redirected to <br /> the dashboard in a
          seconds
        </span>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (data) => dispatch(loginSuccess(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(PreviewModeAuthenticationScreen) as any;
