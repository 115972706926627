import history from "../../../../helpers/history";
import { take, race, call, all, put } from "redux-saga/effects";
import {
  editStoreSettingsSuccess,
  submitOrderingSettingsFailure,
  submitOrderingSettingsSuccess,
} from "../actions/StoreSettings";
import { editStoreAction } from "../../../../constants";
import { translateLeaderboard } from "../../../../i18n/i18n";
import Strings from "../../../../i18n/strings/leaderboard";
import { customNotify } from "../../SharedComponent/Notification";
import {
  editStoreSettingsAction,
  editStoreWorkingHoursAction,
  setRoutingMethodAction,
} from "../../constants/actions";
function* pickupSettingsSuccessSaga() {
  history.push(`/pickup/done`);
  yield put(submitOrderingSettingsSuccess());
}

const constructMessageFromError = (err) => {
  try {
    const errors = err?.payload?.response?.data?.validation_errors;
    let fullMessage = "";
    if (errors) {
      Object.keys(errors).forEach((errorKey) => {
        fullMessage += errors[errorKey].join("\n");
      });
      return fullMessage;
    }
    return translateLeaderboard(Strings.somethingWentWrong);
  } catch {
    return translateLeaderboard(Strings.somethingWentWrong);
  }
};

export function* watchPickupSettingsSuccessSaga() {
  while (true) {
    const { allSucceeded, oneFailed } = yield race({
      allSucceeded: all([
        take(editStoreSettingsSuccess.type),
        take(editStoreAction.fulfilled),
        take(setRoutingMethodAction.fulfilled),
      ]),
      oneFailed: take([
        editStoreWorkingHoursAction.rejected,
        editStoreSettingsAction.rejected,
        editStoreAction.rejected,
        setRoutingMethodAction.rejected,
      ]),
    });
    if (allSucceeded) {
      yield call(pickupSettingsSuccessSaga);
      customNotify.success(
        translateLeaderboard(Strings.successStoreEditsSaved)
      );
    } else {
      customNotify.error(
        translateLeaderboard(constructMessageFromError(oneFailed))
      );
      yield put(submitOrderingSettingsFailure());
    }
  }
}
