import * as actions from "../../constants/actions";
import { IItem, ICreateOrderingItem } from "../../lib";
import { payload } from "ts-action";
import { optAction, optReaction } from "./lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const getOrderingItems = optAction(
  actions.getOrderingItemsAction.requested,
  payload<{ category_id: string }>()
);
export const getOrderingItemsSuccess = optReaction(
  actions.getOrderingItemsAction.fulfilled,
  payload<IItem[]>(),
  COMMIT
);
export const getOrderingItemsFailure = optReaction(
  actions.getOrderingItemsAction.rejected,
  payload<{}>(),
  REVERT
);
export const createOrderingItem = optAction(
  actions.createOrderingItemAction.requested,
  payload<ICreateOrderingItem>()
);
export const createOrderingItemSuccess = optReaction(
  actions.createOrderingItemAction.fulfilled,
  payload<IItem>(),
  COMMIT
);
export const createOrderingItemFailure = optReaction(
  actions.createOrderingItemAction.rejected,
  payload<IItem>(),
  REVERT
);
export const reOrderItem = optAction(
  actions.reOrderItemAction.requested,
  payload<{ id: string; newOrder: number }>()
);
export const reOrderItemSuccess = optReaction(
  actions.reOrderItemAction.fulfilled,
  payload<{ id: string; newOrder: number }>(),
  COMMIT
);
export const reOrderItemFailure = optReaction(
  actions.reOrderItemAction.rejected,
  payload<{ id: string; newOrder: number }>(),
  REVERT
);

export const deleteOrderingItem = optAction(
  actions.deleteOrderingMenuItemAction.requested,
  payload<string>()
);
export const deleteOrderingItemSuccess = optReaction(
  actions.deleteOrderingMenuItemAction.fulfilled,
  payload<IItem>(),
  COMMIT
);
export const deleteOrderingItemFailure = optReaction(
  actions.deleteOrderingMenuItemAction.rejected,
  payload<Error>(),
  REVERT
);

export const editOrderingItem = optAction(
  actions.editOrderingItemAction.requested,
  payload<IItem>()
);
export const editOrderingItemSuccess = optReaction(
  actions.editOrderingItemAction.fulfilled,
  payload<IItem>(),
  COMMIT
);
export const editOrderingItemFailure = optReaction(
  actions.editOrderingItemAction.rejected,
  payload<IItem>(),
  REVERT
);

export const changeItemCategory = optAction(
  actions.changeOrderingItemCategoryAction.requested,
  payload<{ itemId: string; categoryId: string }>()
);

export const changeItemCategorySuccess = optReaction(
  actions.changeOrderingItemCategoryAction.fulfilled,
  payload<{ itemId: string; categoryId: string }>(),
  COMMIT
);

export const changeItemCategoryFailure = optReaction(
  actions.changeOrderingItemCategoryAction.rejected,
  payload<{ itemId: string; categoryId: string }>(),
  REVERT
);
