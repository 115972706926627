export default {
  toUnlockThisGift: "toUnlockThisGift",
  customerWillPay: "customerWillPay",
  somethingWentWrong: "somethingWentWrong",
  cashToPointsRateTitle: "cashToPointsRateTitle",
  cashToPointsRateSubtitle: "cashToPointsRateSubtitle",
  ifYourRateIs: "ifYourRateIs",
  notRecommended: "notRecommended",
  recommended: "recommended",
  progressAutoSaved: "progressAutoSaved",
  markComplete: "markComplete",
};
