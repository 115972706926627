import { action, payload } from "ts-action";
import { getCompetitionsAction } from "../../constants";
import { LeaderBoardItem } from "../../models/LeaderBoardItem";
import { Moment } from "moment";
export const getCompetitions = action(
  getCompetitionsAction.requested,
  payload<{ start_time: Moment }>()
);
export const getCompetitionsSuccess = action(
  getCompetitionsAction.fulfilled,
  payload<LeaderBoardItem[]>()
);
export const getCompetitionsFailure = action(
  getCompetitionsAction.rejected,
  payload<Error>()
);
