import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./line-graph.module.scss";
// types
import { LineGraphInterface } from "./types";

export const LineGraph: React.FC<LineGraphInterface> = (props) => {
  const { width, height, values, lineGraphConfigs } = props;

  const koinzLineGraphRef = useRef<any>({});

  useEffect(() => {
    const canvas = document.getElementById(
      lineGraphConfigs.LINE_GRAPH_ID
    ) as HTMLCanvasElement;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    koinzLineGraphRef.current = new Chart(ctx, {
      type: lineGraphConfigs.CHART_TYPE,
      data: {
        labels: values.labels,
        datasets: values.datasets,
      },
      options: { ...lineGraphConfigs.OPTIONS },
    });

    return () => koinzLineGraphRef.current.destroy();
  }, []);

  // updating data
  useEffect(() => {
    if (typeof koinzLineGraphRef.current !== undefined && values.datasets) {
      koinzLineGraphRef.current.data.datasets = values.datasets;
      koinzLineGraphRef.current.update();
    }
  }, [values.datasets, koinzLineGraphRef]);

  // updating labels
  useEffect(() => {
    if (typeof koinzLineGraphRef.current !== undefined && values.labels) {
      koinzLineGraphRef.current.data.labels = values.labels;
      koinzLineGraphRef.current.update();
    }
  }, [values.labels, koinzLineGraphRef]);

  return (
    <canvas id={lineGraphConfigs.LINE_GRAPH_ID} width={width} height={height} />
  );
};

export default LineGraph;
