import {
  useGetCustomersSegmentOverview,
  useGetCustomersSegmentChart,
} from "./customerSegment.hook";
import { useGetLiveUpdates } from "./liveUpdates.hook";
import {
  useGetRecentReviews,
  useGetCriticalReviewsNumbers,
  useGetOverallRating,
  useGetRatesNumbers,
  useGetTotalReviewsNumbers,
} from "./recentReviews";
import {
  useGetCustomerInsights,
  useGetCustomerInsightsTotal,
} from "./Insights/insights.customer.hook";
import {
  useGetRedemptionInsights,
  useGetTopRedeemedGifts,
  useGetRedemptionInsightsTotal,
} from "./Insights/insights.redemption.hook";
import {
  useGetTransactionInsights,
  useGetTransactionInsightsTotal,
} from "./Insights/insights.transaction.hook";
import {
  useGetBranchInsights,
  useGetBranchInsightsTotal,
} from "./Insights/insights.branch.hook";
import { useGetBranchesPerformance } from "./branchesPerformance.hooks";
import { useGetTodaysTotalCustomers } from "./todaysTotalCustomers.hook";
import { useGetTodaysTotalOrders } from "./todaysTotalOrders.hook";
import { useGetTodaysTotalRedemptions } from "./todaysTotalRedemptions.hook";
import { useGetTodaysTotalSales } from "./todaysTotalSales.hook";
import { useGetCustomerReceipts } from "./getCustomerReceipts.hook";

export const Queries = {
  useGetCustomersSegmentOverview,
  useGetCustomersSegmentChart,
  useGetLiveUpdates,
  useGetRecentReviews,
  useGetCriticalReviewsNumbers,
  useGetOverallRating,
  useGetRatesNumbers,
  useGetTotalReviewsNumbers,
  useGetCustomerInsights,
  useGetCustomerInsightsTotal,
  useGetRedemptionInsights,
  useGetTopRedeemedGifts,
  useGetRedemptionInsightsTotal,
  useGetBranchInsights,
  useGetBranchInsightsTotal,
  useGetTransactionInsights,
  useGetTransactionInsightsTotal,
  useGetBranchesPerformance,
  useGetTodaysTotalCustomers,
  useGetTodaysTotalOrders,
  useGetTodaysTotalRedemptions,
  useGetTodaysTotalSales,
  useGetCustomerReceipts,
};

export * from "./customersChart.hook";
export * from "./ordersChart.hook";
export * from "./redemptionsChart.hook";
export * from "./salesChart.hook";
export * from "./totalOrders.hook";
export * from "./customersVisits.hook";
