import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IEditSpecialItemData, IStoreItem } from "../types/wizard-types";

export const editSpecialItem = (
  token,
  data: IEditSpecialItemData
): AxiosReturn<IStoreItem> => {
  return axios.put(Requests.specialItemsByID(data.id), data, {
    headers: {
      token,
    },
  });
};
