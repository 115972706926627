import { call, put, select, takeLatest } from "redux-saga/effects";
import { nextLeaderboardAction } from "../../constants";
import { selectToken } from "../selectors";
import { nextLeaderboardFailure, nextLeaderboardSuccess } from "../actions";
import { INextLeaderboardAction } from "../../types";
import { hasNextLeaderboard } from "../../axios/getHasNextLeaderboard";
import { AxiosResponse } from "axios";

function* hasNextCompetitionSaga(action: INextLeaderboardAction) {
  try {
    const token: string = yield select(selectToken);
    const res: AxiosResponse<{}> = yield call(hasNextLeaderboard, token);
    const hasLeaderboard: boolean = res.status === 200;
    yield put(nextLeaderboardSuccess(hasLeaderboard));
  } catch (e) {
    yield put(nextLeaderboardFailure(e));
  }
}

export function* watchHasNextCompetitionSaga() {
  yield takeLatest(nextLeaderboardAction.requested, hasNextCompetitionSaga);
}
