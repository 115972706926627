import React, {
  useState,
} from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import Drawer from "@material-ui/core/Drawer";
import { translate } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { RenderGenericField } from "../../../FormElements/RenderGenericField";
import {
  email,
  isEmpty,
  maxLength,
  phoneNumberValidation,
  required,
} from "../../../../redux-store/validators";
import { FormEvent } from "react";
import { Dispatch } from "redux";
import { passOnFormValues as passOnFormValuesRequest } from "../../../../redux-store/actions/passOnFormValuesActions";
import { connect } from "react-redux";
import { InjectedTranslateProps } from "react-i18next";
import i18n from "../../../../i18n/i18n";
import Strings from "../../../../i18n/strings/users";
import { IRootReducerState } from "../../../../redux-store/rootReducer";
import { RenderChipsField } from "../../../FormElements/RenderChipsField";
import { UserTypes } from "../users-hooks";
import {
  ISelectItemData,
  RenderDropDownSelect,
} from "../../../FormElements/RenderDropDownSelect";
import { ICreateUser } from "../../../../axios/createUser";
import { IEditUserData } from "../../../../axios/editUser";
import { useProgressState } from "../../../../Utils";
import { ClipLoader } from "react-spinners";
import { IBranch } from "../../../../types/shared";
import CloseIcon from "../../../../assets/icon-close.svg";
import { Countries } from '../../../../static/countries'
import StoreData from "../../../../contexts/StoreData";
import { InitialCountryIsoCode } from "./InitialCountryIsoCode";
import ArrowIcon from "../../../../assets/down-arrow.svg";


const translateUsersExternal = (
  str: string,
  options?: i18n.TranslationOptions
) =>
  i18n.t(str, {
    ...options,
    ns: "users",
  });

export interface IFormFields {
  name: string;
  email: string;
  branches?: string[];
  role?: string;
}

interface IDispatchProps {
  passOnFormValues: (fn) => void;
}

interface IProps extends IDispatchProps {
  isOpen: boolean;
  readOnly?: boolean;
  toggleDrawer: () => void;
  titleText?: string;
  buttonText?: string;
  userType: number;
  onSubmit: (formValues: ICreateUser | IEditUserData) => Promise<any>;
  branches: IBranch[];
}

export const CUSTOMER_SERVICE = "customer_service";
export const IT_MANAGER = "it_manager";
export const OPERATIONS_MANAGER = "operations_manager";
export const MARKETING_MANAGER = "marketing_manager";
export const jobRolesSelectItems: ISelectItemData[] = [
  {
    value: IT_MANAGER,
    label: translateUsersExternal(Strings.title7),
  },
  {
    value: OPERATIONS_MANAGER,
    label: translateUsersExternal(Strings.title5),
  },
  {
    value: MARKETING_MANAGER,
    label: translateUsersExternal(Strings.title6),
  },
];

const userAddTitleTypeStrings = {
  [UserTypes.owner_CEO]: Strings.addanOwneracc,
  [UserTypes.branch_area_manager]: Strings.addaBranchMangerUser,
  [UserTypes.senior_manager]: Strings.addaSeniorManagmentUser,
  [UserTypes.customer_service]: Strings.addaCustomerServicesUser,
};

const userAddSubTitleTypeStrings = {
  [UserTypes.owner_CEO]: Strings.description1,
  [UserTypes.branch_area_manager]: Strings.description2,
  [UserTypes.senior_manager]: Strings.description3,
  [UserTypes.customer_service]: Strings.description4,
};

const AddUser: React.FC<
  InjectedFormProps<IFormFields> & InjectedTranslateProps & IProps
> = (props) => {
  const { storeData } = React.useContext(StoreData);
  const [isFeildEmpty, setIsFeildEmpty] = useState(false);
  const [value, setValue] = useState();
  React.useEffect(() => props.destroy, []);
  const {
    isOpen,
    titleText,
    t,
    userType,
    branches,
    readOnly,
    buttonText,
    touch,
    valid,
    passOnFormValues,
    onSubmit,
    dirty,
  } =
    props;

  const { loading, setLoading, setSuccess } = useProgressState();

  const handleAddUserSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      touch("name", "email", "branches", "role", "countryCode", "phoneNumber");
      if (valid && dirty) {
        passOnFormValues(({ addUserForm }) => {
          if (onSubmit) {
            setLoading();
            const { values } = addUserForm;
            const submitValues = {
              branch_ids: values.branches
                ? values.branches.map((a) => a.id)
                : undefined,
              id: values.id,
              name: values.name,
              email: values.email,
              role: values.role,
              user_type: userType,
              phoneNumber: values.phoneNumber,
              countryCode: values.countryCode,
            };
            onSubmit(submitValues).then(setSuccess);
          }
        });
      }
    },
    [setLoading, setSuccess, onSubmit, valid, dirty, touch]
  );

  const handleToggleDrawer = React.useCallback(() => props.toggleDrawer(), []);

  const getCountries = React.useMemo(() => Array.isArray(Countries) ? Countries.map((item) => {
    const handlelanguage = i18n.language === 'ar' ? `${item.ar_name} ${item.code}` : ` ${item.code} ${item.en_name}`;
    return (
      {
        value: item.code,
        label: handlelanguage,
        icon: item.icon
      }
    )
  }) : [], [i18n.language]);

  const getIsoCode = React.useMemo(() => Countries.find((items) => items.ISO_code === storeData?.country_iso_code), []);
  const handleOnChange = () => setIsFeildEmpty(true);

  return (
    <Drawer
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      open={isOpen}
      anchor={i18n.language === "ar" ? "left" : "right"}
      onClose={handleToggleDrawer}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {titleText}
          {t(userAddTitleTypeStrings[userType])}
          <img onClick={handleToggleDrawer} src={CloseIcon} alt="close-icon" />
        </div>
        <p className={styles.pragHeader}>
          {t(userAddSubTitleTypeStrings[userType])}
        </p>

        <form
          autoComplete="off"
          onSubmit={handleAddUserSubmit}
          className={styles.form}
        >
          <Field
            disabled={readOnly}
            name="name"
            type="text"
            placeholder={t(Strings.fullName)}
            component={RenderGenericField}
            label={t(Strings.fullName)}
            validate={[required, isEmpty, maxLength(70)]}
          />
          <div className={styles.separator} />
          <Field
            disabled={readOnly}
            name="email"
            placeholder={t(Strings.email)}
            component={RenderGenericField}
            label={t(Strings.email)}
            validate={[required, email]}
          />
          <div className={styles.separator} />
          <div style={{
            position: 'relative'
          }}>

            <div>
              <span className={styles.phoneNumberTitle}>{t(Strings.countryCode)}</span>
            </div>
            <Field
              name="countryCode"
              component={RenderDropDownSelect}
              options={getCountries}
              onChange={handleOnChange}
            />
            {
              !isFeildEmpty && (
                <div className={styles.emptyFeildContainer} >
                  <InitialCountryIsoCode
                    initialCountry={getIsoCode}
                  />
                </div>
              )
            }
            <div className={i18n.language === 'en' ? styles.arrowContainerEn : styles.arrowContainerAr}>
              <img
                src={ArrowIcon}
                width={15}
                height={15}
              />
            </div>
          </div>
          <div className={styles.phoneNumberFeild}>
            <div>
              <span className={styles.phoneNumberTitle}>{t(Strings.phoneNumber)}</span>
            </div>
            <Field
              placeholder={t(Strings.phoneNumber)}
              name="phoneNumber"
              component={RenderGenericField}
              validate={[required, phoneNumberValidation]}
            />
          </div>
          <div>
          </div>
          <div className={styles.separator} />
          {userType === UserTypes.branch_area_manager && (
            <Field
              disabled={readOnly}
              name="branches"
              options={branches}
              component={RenderChipsField}
              label={t(Strings.assignToBranches)}
              validate={[required]}
            />
          )}
          {userType === UserTypes.senior_manager && (
            <>
              <div className={styles.separator} />
              <Field
                disabled={readOnly}
                name="role"
                placeholder={t(Strings.jobTitle)}
                component={RenderDropDownSelect as any}
                label={t(Strings.jobTitle)}
                options={jobRolesSelectItems}
                validate={[required, isEmpty, maxLength(70)]}
              />
            </>
          )}
          {!readOnly && (
            <ButtonWithSpinner
              loading={loading}
              type="submit"
              className={styles.btn}
            >
              {buttonText || t(Strings.addUser)}
            </ButtonWithSpinner>
          )}
        </form>
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  passOnFormValues: (fn) => dispatch(passOnFormValuesRequest(fn)),
});
const mapStateToProps = (state: IRootReducerState) => ({
  branches: state.branchesReducer.allBranches,
});

const AddUserForm = reduxForm({
  destroyOnUnmount: false,
  form: "addUserForm",
})(AddUser as any);

interface IButtonWithSpinnerProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
}

export const ButtonWithSpinner: React.FC<IButtonWithSpinnerProps> = ({
  loading,
  children,
  ...props
}) => {
  return (
    <button disabled={props.disabled || loading} {...props}>
      {loading ? (
        <ClipLoader sizeUnit={"px"} size={20} color={"white"} loading={true} />
      ) : (
        children
      )}
    </button>
  );
};

export default hot(module)(
  connect<{}, IDispatchProps, IProps>(
    mapStateToProps as any,
    mapDispatchToProps
  )(translate("users")(AddUserForm as any) as any) as any
);
