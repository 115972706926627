import { IGenericAction } from "../../constants";
import { Action, AnyAction, Reducer } from "redux";
import { mutateState } from "../../helpers/mutate-state";

const LOADED = "loaded";
const FAILED = "failed";
const LOADING = "loading";

export interface ILoadingReducerState {
  [LOADED]: boolean;
  [FAILED]: boolean;
  [LOADING]: boolean;
}

const loadingReducer = (
  state,
  action: Action,
  genericLoadingAction: IGenericAction
) => {
  switch (action.type) {
    case genericLoadingAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(LOADING, false);
        map.set(LOADED, true);
        map.set(FAILED, false);
      });
    }
    case genericLoadingAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADING, false);
        map.set(LOADED, false);
        map.set(FAILED, true);
      });
    }
    case genericLoadingAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADING, true);
        map.set(LOADED, false);
        map.set(FAILED, false);
      });
    }
    default:
      return state;
  }
};

export function constructLoadingReducer<
  S extends ILoadingReducerState = any,
  A extends Action = AnyAction
>(
  genericLoadingAction: IGenericAction,
  reducer: Reducer<S, Action>,
  initialState: S
) {
  return (
    state = {
      ...initialState,
      [LOADED]: false,
      [FAILED]: false,
      [LOADING]: false,
    },
    action: A
  ) => {
    return reducer(loadingReducer(state, action, genericLoadingAction), action);
  };
}
