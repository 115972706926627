import { call, put, select, takeLatest } from "redux-saga/effects";
import { getFraudlentCustomerList } from "../../constants";
import { selectToken } from "../selectors";
import { getFraudlentCustomers } from "../../axios/getFraudlentCustomers";
import { getFraudlentCustomerListFailure, getFraudlentCustomerListSuccess } from "../actions/get-fraud-center-actions";
import { payload } from "ts-action";

function* getFraudCustomersListSaga(action) {
  try {
    const token = yield select(selectToken);
    const params = action.payload;
    const response = yield call(getFraudlentCustomers, token, params);
    yield put(
      getFraudlentCustomerListSuccess({
        data: response.data, hasMore: response.data.length < params.size ? false : true
      })
    );
  } catch (e: unknown) {
    yield put(getFraudlentCustomerListFailure(e as Error));
  }
}

export function* watchGetFraudCustomersListSaga() {
  yield takeLatest(getFraudlentCustomerList.requested, getFraudCustomersListSaga);
}
