export default {
  onBoardingStepsTitle: "بوابة إعداد نظام نقاط Koinz",
  subtitlePart1: "Koinz تساعدك على نقل تجربة عملائك",
  subtitlePart2: "إلى المستقبل!",
  dataAnalyticsTitle: "البيانات والتقارير الذكية",
  feedbackToolTitle: "أداة التقييمات",
  loyaltyProgramTitle: "برنامج النقاط",
  dataAnalyticsDescriptionPart1: "تعرف على عملائك كما",
  dataAnalyticsDescriptionPart2: "لم تعرفهم من قبل!",
  feedbackToolDescriptionPart1: "نعطيك تقييمات مفصلة",
  feedbackToolDescriptionPart2: "تساعدك على كسب رضا",
  feedbackToolDescriptionPart3: "عملائك!",
  loyaltyProgramDescriptionPart1: "نظام جمع وتبديل الجوائز",
  loyaltyProgramDescriptionPart2: "الذي سيجعل عملائك يعودون باستمرار!",
  next: "التالي",
  back: "السابق",
};
