import * as Mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";

export const trackEvent = (eventName: string, ...args: string[]) => {
  const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
  const using_universal_password = localStorage.getItem(
    "using_universal_password"
  );

  if (isKoinzAdminRemote || using_universal_password) {
    return new Promise<void>((res) => {});
  }

  return new Promise<void>((res) => {
    Mixpanel.track(
      eventName,
      undefined,
      () => res()
      // args.length ? {args} : undefined
    );
    hj("tagRecording", [
      eventName,
      // ...args
    ]);

    ReactGA.ga("send", "event", {
      eventCategory: "Custom event",
      eventAction: eventName,
    });
  });
};
