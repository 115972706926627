import { call, put, select, takeLatest } from "redux-saga/effects";
import { editSpecialItemAction } from "../../constants";
import {
  IEditSpecialItemAction,
  IStoreItem,
  OptimisticSuccess,
} from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { editSpecialItem } from "../../axios/editSpecialItem";

function* editSpecialItemSaga({ payload }: IEditSpecialItemAction) {
  try {
    const token = yield select(selectToken);
    const editLoyaltyItemRes = yield call(editSpecialItem, token, {
      ...payload,
      price: payload.price ? +(payload.price as number) : undefined,
    });
    const item = editLoyaltyItemRes.data as IStoreItem;
    yield put(
      actions.editSpecialItemSuccess({ ...item, state: OptimisticSuccess })
    );
  } catch (e) {
    yield put(actions.editSpecialItemFailure(payload));
  }
}

export function* watchEditSpecialItemSaga() {
  yield takeLatest(editSpecialItemAction.requested, editSpecialItemSaga);
}
