import * as constants from "../../constants";
import {
  INextLeaderboardAction,
  INextLeaderboardSuccessAction,
  INextLeaderboardFailureAction,
} from "../../types";

export const nextLeaderboard = (): INextLeaderboardAction => ({
  type: constants.nextLeaderboardAction.requested,
});

export const nextLeaderboardSuccess = (
  hasLeadernoard: boolean
): INextLeaderboardSuccessAction => ({
  type: constants.nextLeaderboardAction.fulfilled,
  payload: hasLeadernoard,
});

export const nextLeaderboardFailure = (
  error: Error
): INextLeaderboardFailureAction => ({
  type: constants.nextLeaderboardAction.rejected,
  payload: error,
});
