import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteCompetitionAction } from "../../constants";
import { selectForm, selectToken } from "../selectors";
import { deleteCompetitionFailure, deleteCompetitionSuccess } from "../actions";
import { IDeleteCompetitionAction } from "../../types";
import { notify } from "react-notify-toast";
import { deleteCompetition } from "../../axios/deleteCompetition";
import { IFormState } from "../rootReducer";
import { confirmPassword } from "../../axios/confirmPassword";
import { translateLeaderboard } from "../../i18n/i18n";
import Strings from "../../i18n/strings/leaderboard";
import { startSubmit, setSubmitSucceeded, setSubmitFailed } from "redux-form";

function* deleteCompetitionSaga(action: IDeleteCompetitionAction) {
  try {
    const token: string = yield select(selectToken);
    const form: IFormState = yield select(selectForm);
    yield put(startSubmit("confirmationForm"));
    const { password } = form.confirmationForm.values as any;
    yield call(confirmPassword, password, token);
    yield call(deleteCompetition, action.payload, token);
    yield put(deleteCompetitionSuccess(action.payload));
    yield put(setSubmitSucceeded("confirmationForm"));
    notify.show(
      translateLeaderboard(Strings.successCompetitionDeleted),
      "success"
    );
  } catch (e) {
    yield put(setSubmitFailed("confirmationForm"));
    yield put(deleteCompetitionFailure(e));
    notify.show(translateLeaderboard(Strings.errorWrongPassword), "error");
  }
}

export function* watchDeleteCompetitionSaga() {
  yield takeLatest(deleteCompetitionAction.requested, deleteCompetitionSaga);
}
