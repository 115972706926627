import { Action } from "redux";

import { put, retry } from "redux-saga/effects";
import {} from "redux-saga";

import { COMMIT, REVERT } from "redux-optimistic-ui";
import { customNotify } from "../../SharedComponent/Notification/index";
import { IOptReactionCreator } from "../actions/lib";

const appendOptimistic = (action, type, id) => {
  return {
    ...action,
    meta: {
      ...action.meta,
      optimistic: { type, id },
    },
  };
};

interface IReactions {
  success: IOptReactionCreator;
  failure: IOptReactionCreator;
}

export function handleOptimism<
  A extends Action<string> & {
    payload: any;
    meta: { optimistic: { id: number } };
  }
>(saga: (action: A) => any, { failure, success }: IReactions) {
  return function*(action: A) {
    const {
      meta: {
        optimistic: { id },
      },
      payload,
    } = action;
    try {
      const SECOND = 1000;
      const result = yield retry(3, 5 * SECOND, saga, action);

      yield put(appendOptimistic(success(result, id), COMMIT, id));
    } catch (e) {
      yield put(appendOptimistic(failure(payload, id), REVERT, id));
      customNotify.error("There is something wrong");
    }
  };
}

// i18n.t(Strings.somethingWentWrong, {ns: 'orderingStatus'})
