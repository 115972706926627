import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { orderTrackingAction } from "../../constants/actions";
import { orderTrackingSuccess } from "../actions/order-tracking-action";
import { IOrderTracking } from "../actions/lib";

interface IState {
  status: IOrderTracking;
}

const initialState: IState = {
  status: {
    statuses: [],
  },
};

export const orderTrackingReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(orderTrackingSuccess, (state: IState, { payload }) => ({
        ...state,
        status: payload,
      })),
    ] as any,
    initialState
  ),
  orderTrackingAction
);
