import { useCallback } from "react";
import i18n from "../../../../../../i18n/i18n";

export const useTranslation = (ns?: string) => {
  const t = useCallback(
    (key: string, options?: i18n.TranslationOptions) => {
      return i18n.t(key, { ns, ...options });
    },
    [i18n]
  );

  return {
    t,
    i18n,
  };
};
