import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const getCheckSignup = (
  token
): AxiosReturn<{ signup_completed: boolean }> => {
  return axios.get(Requests.getCheckSignup, {
    headers: {
      token,
    },
  });
};
