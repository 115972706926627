import { DeliveryMethods } from "../Components/Pickup/lib/types";
import {
  IGeographicItem,
  IZoneItem,
} from "../Components/WizardComponents/Branches/AddBranchForm/lib";

export interface IOptimisticObject<FallBackType = any> {
  optimisticId: string;
  state: number;
  fallBack?: FallBackType;
}

export enum PickupStatus {
  service_enabled = "service_enabled",
  service_disabled = "service_disabled",
}

export enum DeliveryManagementStatus {
  disabled = "disabled",
  enabled = "enabled",
}
export interface IBranch extends IOptimisticObject {
  address: string;
  detailed_address?: string;
  ar_detailed_address?: string;
  en_detailed_address?: string;
  updated_at: number;
  assigned_cashiers_count: number;
  numbers: string[];
  name: string;
  ar_name?: string;
  en_name?: string;
  created_at: number;
  id: string;
  integration_id?: string;
  type?: number;
  lat: number;
  lng: number;
  call_center?: 1;
  deleted?: true;
  pickup_status?: PickupStatus;
  delivery_status?: PickupStatus;
  drive_thru_status?: PickupStatus;
  offline_loyalty_status?: PickupStatus;
  government_code?: string;
  delivery_zones?: string[] | IDeliveryZone[];
  delivery_management_status?: DeliveryManagementStatus;
  store?: {
    delivery_management_status: DeliveryManagementStatus;
    delivery_method: DeliveryMethods;
  };
  qr_dine_in_status: PickupStatus;
  qr_take_away_status: PickupStatus;
  pos_vendor?: string;
}

export interface IGetSingleBranch extends IBranch {
  delivery_zones: IDeliveryZone[];
}

export interface IDeliveryZone {
  actual_delivery_cost: number;
  city_ar_name: string;
  city_en_name: string;
  city_id: string;
  district_ar_name: string;
  district_en_name: string;
  district_id: string;
  sub_district_ar_name: string;
  sub_district_en_name: string;
  sub_district_id: string;
  delivery_time: number;
  delivery_fees: number;
}

export interface IGroupedZones {
  district: IGeographicItem;
  city: IGeographicItem;
  zones: IZoneItem[];
}
