import { Modal } from "antd";
import styles from "./styles.module.sass";
import {
  CrossIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../assets/white-label";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BannerCard,
  addBannerCard,
  deleteBannerCard,
  resetBannerCardsState,
} from "../../../../redux-store/actions/brand-visuals-actions";
import { useSelect } from "../../../../hooks/useSelect";
import { ClipLoader } from "react-spinners";
import strings from "../../../../i18n/strings/brandVisuals";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import { resizeImageToFit } from "../../../../Utils";
import { useRtlClass } from "../../../../lib";
import Dropzone from "react-dropzone";

type AddBannerCardModalProps = {
  visible: boolean;
  onClose: () => void;
  bannerCard: BannerCard;
};
export const AddBannerCardModal = memo(
  ({ visible, onClose, bannerCard }: AddBannerCardModalProps) => {
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const { loading, success } = useSelect((state) => state.bannerCardsReducer);
    const dispatch = useDispatch();
    const rtl = useRtlClass(styles);
    const { t } = useTranslation("brandVisuals");
    const handleCloseModal = () => {
      setUploadedImage(null);
      onClose();
      dispatch(resetBannerCardsState());
    };
    const handleAddBannerCard = () => {
      if (uploadedImage) {
        dispatch(
          addBannerCard({
            order: bannerCard.order,
            image: uploadedImage,
          })
        );
      }
    };

    const handleDeleteBannerCard = () => {
      if (bannerCard?.id && bannerCard?.image) {
        dispatch(deleteBannerCard(bannerCard.id));
      } else {
        setUploadedImage(null);
      }
    };

    useEffect(() => {
      if (success) {
        handleCloseModal();
        dispatch(resetBannerCardsState());
      }
    }, [success]);

    useEffect(() => {
      if (visible && bannerCard.image) {
        setUploadedImage(bannerCard.image);
      }
    }, [visible, bannerCard.image]);

    const handleDrop = async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        try {
          const resizedImageBlob = await resizeImageToFit(file, 343, 190);
          if (resizedImageBlob) {
            const reader = new FileReader();
            reader.onload = () => {
              setUploadedImage(reader.result as string);
            };
            reader.readAsDataURL(resizedImageBlob);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    return (
      <Modal
        closable={false}
        centered={true}
        title={""}
        footer={""}
        visible={visible}
        bodyStyle={{
          padding: 0,
        }}
        onCancel={handleCloseModal}
      >
        <div className={`${styles.wrapper} ${rtl}`}>
          <div className={styles.header}>
            <span className={styles.title}>{t(strings.uploadBanner)}</span>
            <img
              onClick={handleCloseModal}
              className={styles.closeIcon}
              src={CrossIcon}
              alt={"Close Banner Card Modal"}
            />
          </div>


          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps, open, isDragActive }) => (
              <div className={styles.body}>
                <div
                  className={`${styles.imageWrapper} ${isDragActive && styles.isDraggingOver
                    }`}
                >
                  <div className={styles.content} {...getRootProps()}>
                    <input id="upload-image" {...getInputProps()} />
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        className={styles.image}
                        alt="Banner Image"
                      />
                    ) : (
                      <Fragment>
                        <span className={styles.description}>
                          {t(strings.dragAndDropImageOrBrowse)}
                        </span>
                        <span className={styles.bannerDescription}>
                          {t(strings.bannerShouldBe)}
                        </span>
                      </Fragment>
                    )}
                  </div>
                </div>
                {uploadedImage && (
                  <button onClick={open} className={styles.changeBannerBtn}>
                    <UploadIcon className={styles.uploadIcon} color={"#FF9000"} />
                    {t(strings.changeBanner)}
                  </button>
                )}
              </div>
            )}

          </Dropzone>


          <div
            style={{
              justifyContent: uploadedImage ? "space-between" : "flex-end",
            }}
            className={styles.footer}
          >
            {uploadedImage && (
              <button
                onClick={handleDeleteBannerCard}
                className={styles.disabledAddBannerBtn}
              >
                <TrashIcon color="#8E8E93" className={styles.trashIcon} />{" "}
                {t(strings.delete)}
              </button>
            )}
            <button
              onClick={handleAddBannerCard}
              disabled={!uploadedImage || uploadedImage === bannerCard.image}
              className={
                uploadedImage && uploadedImage !== bannerCard.image
                  ? styles.addBannerBtn
                  : styles.disabledAddBannerBtn
              }
            >
              {loading ? (
                <ClipLoader color={"#fff"} size={24} />
              ) : (
                t(strings.addBanner)
              )}
            </button>
          </div>
        </div>
      </Modal >
    );
  }
);

export default AddBannerCardModal;
