import { on, reducer } from "ts-action";
import { getStoreViralitySettingsAction } from "../../../constants";
import { IStoreViralitySettings } from "../../../types/wizard-types";
import {
  getStoreViralitySettingsSuccess,
  updateStoreViralitySettingsSuccess,
} from "../../actions";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";

const initialState: IStoreViralitySettings = {
  enabled: 0,
  method: "fixed_points_value",
  value: undefined,
};
export const getStoreViralitySettingsReducer =
  withLoadingReducer<IStoreViralitySettings>(
    reducer<IStoreViralitySettings>(
      [
        on(getStoreViralitySettingsSuccess, (state, { payload }) => ({
          ...state,
          ...payload,
        })),
        on(updateStoreViralitySettingsSuccess, (state, { payload }) => ({
          ...state,
          ...payload,
        })),
      ],
      initialState
    ),
    getStoreViralitySettingsAction
  );
