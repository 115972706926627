import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.sass";
import { Button, Input, Table, Spin } from "antd";
import { columns } from "./columns";
import "./style.less";
import { Search, Download } from "react-feather";
import { Queries, useGetTotalOrders } from "../../../../../Queries/Manager";
import { useSelect } from "../../../../../hooks/useSelect";
import { LoadingOutlined } from "@ant-design/icons";
import { useIsVisible } from "react-is-visible";
import { useDispatch } from "react-redux";
import { getBranches, getStore } from "../../../../../redux-store/actions";
import { getLocalizedCurrency } from "../../../../../Utils";
import Strings from "../../../../../i18n/Manager/strings/insights";
import { useTranslation } from "../../../../../shopx-shared-components/hooks/useTranslation";
import { numberFormatter } from "../../../../../Utils/numbers";
import { TimeFilter } from "../../Home/components";
import FulfillmentRateChart from "./fulfillment";
import { trackEvent } from "../../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../../constants/events-for-tracking";
import {
  AmplitudeEvents,
  amplitudeLogEvent,
} from "../../../../../tracking/amplitude";
import StoreData from "../../../../../contexts/StoreData";

export interface Filters {
  page: number;
  size: number;
  filter?: string;
  from?: number;
  to?: number;
  unit?: string;
}

const BranchInsightsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelect((state) => state.authReducer);
  const { store } = useSelect((state) => state.storeReducer);
  const currency = getLocalizedCurrency(store.country_iso_code);
  const { storeData, userData } = React.useContext(StoreData);
  //
  const listPaginationLoaderRef = useRef(null);
  const isVisible = useIsVisible(listPaginationLoaderRef);
  //
  const [isMore, setIsMore] = useState<boolean>(false);
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [searchInputLoading, setSearchInputLoading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>({});
  const [branchesData, setBranchesData] = useState<any[] | undefined>(
    undefined
  );
  // localization
  const { t } = useTranslation("insights");
  const initialFiltersValue = {
    page: 0,
    size: 20,
  };

  const [filters, setFilters] = useState<Filters>(initialFiltersValue);

  // Query
  const {
    data: fulfillmentRate,
    status: fulfillmentRateStatus,
    refetch: fulfillmentRateRefetch,
  } = useGetTotalOrders("fulfillment");
  const { data, status, refetch } = Queries.useGetBranchInsights(
    token,
    filters
  );
  const {
    data: totalBranches,
    status: totalBranchesStatus,
    refetch: totalBranchesRefetch,
  } = Queries.useGetBranchInsightsTotal(token, {
    ...filters,
    page: undefined,
    size: undefined,
  });

  useEffect(() => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed branches insights page"]);
    amplitudeLogEvent(AmplitudeEvents.branches_insights, {
      user_name: userData?.name,
      user_id: userData?.id,
      user_role: userData?.title || userData?.role,
      store_id: storeData?.id,
      store_name: storeData?.name,
    });
  }, []);

  useEffect(() => {
    if (isVisible && !totalBranches && totalBranchesStatus === "idle") {
      totalBranchesRefetch();
    }
    if (isVisible && !data && status === "idle") {
      refetch();
    }
    if (isVisible && !fulfillmentRate && fulfillmentRateStatus === "idle") {
      fulfillmentRateRefetch();
    }
  }, [isVisible]);
  useEffect(() => {
    setFilters({
      ...filters,
      page: 0,
    });
  }, [filterSearchText]);

  useEffect(() => {
    if (status === "success" && data.metadata) {
      setMetadata(data.metadata);
    }

    if (status === "success" && data.branches.length > 0) {
      if (filters.page === 0) {
        setBranchesData(data.branches);
      } else {
        setBranchesData(
          (prev) => (prev && [...prev, ...data.branches]) || data.branches
        );
      }

      if (data.branches.length < filters.size) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    } else {
      if (filters.page === 0) {
        setBranchesData([]);
      }
      setIsMore(false);
    }
  }, [status, data]);

  useEffect(() => {
    if (status !== "loading") {
      setSearchInputLoading(false);
    }
  }, [status, data, filters.page]);

  // Get store with branches .. for "window refresh"
  useEffect(() => {
    dispatch(getStore());
    dispatch(getBranches());
  }, [dispatch]);

  // When bottom loader is visibile
  useEffect(() => {
    if (isMore && isVisible && status !== "loading") {
      setFilters((filters) => ({
        ...filters,
        page: filters.page + 1,
      }));
    }
  }, [isVisible, status]);

  // Apply text search
  const applySearchFilter = (e) => {
    e.target.blur();
    if (filterSearchText !== filters.filter) {
      setFilters({
        ...filters,
        filter: filterSearchText !== "" ? filterSearchText : undefined,
        page: 0,
      });
      setSearchInputLoading(true);
    }
  };

  const SearchIconJSX = (
    <Search color="#8E8E93" size={18} className={styles.searchInputIcon} />
  );
  const DownloadIconJSX = (
    <Download className={styles.exportButtonIcon} size={20} />
  );
  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.heading}>
        <span className={styles.headingText}>{t(Strings.branches)}</span>
      </div>
      {fulfillmentRateStatus === "success" && (
        <div className={styles.fulfillmentChartWrapper}>
          <FulfillmentRateChart totalOrdersData={fulfillmentRate} />
        </div>
      )}
      <div className={styles.tableWrapper}>
        <div className={styles.tableHead}>
          <div className={styles.tableHeadRowOne}>
            <div className={styles.title}>
              {totalBranchesStatus === "success" &&
                t(Strings.all_branches, {
                  branch_counts: numberFormatter(Number(totalBranches?.total)),
                })}
            </div>
            <div className={styles.searchAndActions}>
              <div className={styles.search}>
                <Input
                  className="insights-header-search-input"
                  size="small"
                  placeholder={t(Strings.searchBranchName)}
                  value={filterSearchText}
                  onPressEnter={applySearchFilter}
                  onChange={(e) => setFilterSearchText(e.target.value)}
                  disabled={searchInputLoading}
                  suffix={
                    searchInputLoading && <Spin indicator={CustomSpinner} />
                  }
                  prefix={SearchIconJSX}
                />
              </div>
              {/* <div className={styles.export}>
                <Button
                  className="insights-header-export-button "
                  type="primary"
                  icon={DownloadIconJSX}
                >
                  {t(Strings.export_csv)}
                </Button>
              </div> */}
            </div>
          </div>
        </div>

        <div className={styles.filtersRow}>
          <div className={styles.input} style={{ marginRight: "3rem" }}>
            <TimeFilter
              showArrow
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 0,
                  from: value.from,
                  to: value.to,
                  unit: value.unit,
                }))
              }
            />
          </div>
        </div>

        <div className={styles.tableBody} id="branch-list">
          <Table
            columns={columns(currency, metadata)}
            rowClassName={"insights-table-row"}
            pagination={false}
            loading={
              status === "loading" && filters.page === 0
                ? { indicator: CustomSpinner }
                : false
            }
            dataSource={branchesData}
          />

          {
            <div
              ref={listPaginationLoaderRef}
              className={`${isMore && styles.tableLoader}`}
            >
              {isMore && <Spin indicator={CustomSpinner} />}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default BranchInsightsPage;
