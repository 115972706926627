export default {
  onBoardingStepsTitle: "THIS PORTAL ONBOARDS YOUR BRAND TO KOINZ",
  subtitlePart1: "Koinz helps you take your customer",
  subtitlePart2: "experience to the future!",
  dataAnalyticsTitle: "DATA & ANALYTICS",
  feedbackToolTitle: "FEEDBACK TOOL",
  loyaltyProgramTitle: "LOYALTY PROGRAM",
  dataAnalyticsDescriptionPart1: "Know your customers as",
  dataAnalyticsDescriptionPart2: "never before!",
  feedbackToolDescriptionPart1: "Detailed reviews and ",
  feedbackToolDescriptionPart2: "customer feedback channel.",
  feedbackToolDescriptionPart3: "",
  loyaltyProgramDescriptionPart1: "The collect and redeem system",
  loyaltyProgramDescriptionPart2: "that will keep your customers coming back!",
  next: "Next",
  back: "Back",
};
