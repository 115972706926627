import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../selectors";
import { getSpecialItems } from "../../../axios/editSpecialItems";
import {
  getLoyaltySpecialItemsFailure,
  getLoyaltySpecialItemsSuccess,
} from "../../actions";
import { getLoyaltySpecialItemsAction } from "../../../constants";

function* getLoyaltySpecialItemsSaga() {
  console.log("getLoyaltySpecialItemsSaga");
  try {
    const token = yield select(selectToken);
    const res = yield call(getSpecialItems, token);
    yield put(getLoyaltySpecialItemsSuccess(res.data.items));
    console.log({ res });
  } catch (error) {
    console.log("first error", error);
    yield put(getLoyaltySpecialItemsFailure(error as Error));
  }
}

export function* watchGetLoyaltySpecialItemsSaga() {
  yield takeLatest(
    getLoyaltySpecialItemsAction.requested,
    getLoyaltySpecialItemsSaga
  );
}
