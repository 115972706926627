import amplitude from "amplitude-js";

export const amplitudeInit = () =>
  amplitude
    .getInstance()
    .init(process.env.REACT_APP_AMPLITUDE_API_KEY as string);

export const amplitudeLogEvent = (eventName: string, eventProps?: {}) => {
  const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
  const using_universal_password = localStorage.getItem(
    "using_universal_password"
  );

  if (!isKoinzAdminRemote && !using_universal_password) {
    return amplitude.getInstance().logEvent(eventName, eventProps);
  } else {
    return null;
  }
};

export enum AmplitudeEvents {
  reviews_page_viewed = "reviews_page_viewed",
  issue_resolved = "issue_resolved",
  compensation = "compensation",
  home_page = "home_page",
  branches_insights = "branches_insights",
  transactions_insights = "transactions_insights",
  customers_insights = "customers_insights",
  redemption_insights = "redemption_insights",
}
