import {
  getCompetition,
  getCompetitionSuccess,
  getCompetitionFailure,
  resetCompetition,
} from "../actions/get-competition-actions";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { union } from "ts-action";
import { ICompetitionCreate } from "../../types";

const COMPETITION = "COMPETITION";
const LOADING = "LOADING";
const LOADED = "LOADED";
const FAILED = "FAILED";

export interface ICompetitionReducerState {
  [COMPETITION]: ICompetitionCreate;
  [LOADING]: boolean;
  [LOADED]: boolean;
  [FAILED]: boolean;
}

const initialState: ICompetitionReducerState = Map({
  [COMPETITION]: {},
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
}).toJS();

const allActions = union({
  getCompetition,
  getCompetitionSuccess,
  getCompetitionFailure,
  resetCompetition,
} as any);

export type getCompetitionActions = typeof allActions;

const competitionReducer = (
  state = initialState,
  action: any
): ICompetitionReducerState => {
  switch (action.type) {
    case constants.getCompetitionAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(COMPETITION, (action as any).payload);
        map.set(LOADING, false);
        map.set(LOADED, true);
        map.set(FAILED, false);
      });
    }
    case constants.getCompetitionAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADING, true);
        map.set(LOADED, false);
        map.set(FAILED, false);
      });
    }
    case constants.getCompetitionAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADING, false);
        map.set(LOADED, false);
        map.set(FAILED, true);
      });
    }
    case constants.ACTION_RESET_COMPETITION: {
      return mutateState(state, (map) => {
        map.set(COMPETITION, {});
        map.set(LOADING, false);
        map.set(LOADED, false);
        map.set(FAILED, false);
      });
    }
    default:
      return state;
  }
};

export default competitionReducer; 