import { call, put, select, takeLatest } from "redux-saga/effects";
import { removeFraudlentCustomerPoints } from "../../constants";
import { selectToken } from "../selectors";
import { removeFraudlentCustomerPoints as apiCall } from "../../axios/removeFraudlentCustomerPoints";
import { removeFraudlentCustomerPointsSuccess, removeFraudlentCustomerPointsFailure } from "../actions/edit-fraud-list-actions";
import { AxiosError } from "axios";

function* removeFraudCustomerPointsSaga(action) {
  try {
    const token = yield select(selectToken);
    const payload = action.payload;
    const response = yield call(apiCall, payload.phone_number, token)
    yield put(
      removeFraudlentCustomerPointsSuccess({ phone_number: payload.phone_number, state: "points-remove" })
    );
  } catch (e: unknown) {
    yield put(removeFraudlentCustomerPointsFailure(e as AxiosError));
  }
}

export function* watchRemoveFraudCustomerPointsSaga() {
  yield takeLatest(removeFraudlentCustomerPoints.requested, removeFraudCustomerPointsSaga);
}
