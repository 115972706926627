export default {
  viewDetails: "View",
  editDetails: "Edit",
  updateDetails: "Update",
  uncompletedBranches: "Out of date branches",
  completeBranchDetails: "Update branch details to enable ordering",
  completedBranches: "Up to date branches",
  completed: "Up to date",
  addBranches: "Add new branch",
  description:
    "In order to enable ordering, update all of the out of date branches",
  Back: "Back",
  Next: "Next",
};
