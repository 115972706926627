import { useState, useEffect, useCallback } from "react";
import {
  OrderingPortalStatus,
  getOrderingPortalApi,
} from "../../Pickup/axios/getOrderingWebPortalStatus";
import { editOrderingPortalApi } from "../../Pickup/axios/editOrderingWebPortalStatus";
import { useProgressState } from "../../../Utils";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export const useOrderingPortalToggle = (token: string) => {
  const [orderingPortalStatus, setOrderingPortalStatus] = useLocalStorage(
    "ordering-portal-status",
    OrderingPortalStatus.service_disabled
  );
  const { setFailure, setLoading, setSuccess, ...progress } =
    useProgressState();
  const orderingPortalSwitch = useCallback(
    (newStatus: OrderingPortalStatus) => {
      setLoading();
      setOrderingPortalStatus(newStatus);
      editOrderingPortalApi(token, newStatus)
        .then(setSuccess)
        .catch(() => {
          setOrderingPortalStatus(orderingPortalStatus);
          setFailure();
        });
    },
    [setFailure, setLoading, setSuccess]
  );
  useEffect(() => {
    setLoading();
    getOrderingPortalApi(token)
      .then((res) => {
        setSuccess();
        setOrderingPortalStatus(res.data.web_portal_status);
      })
      .catch(setFailure);
  }, [token]);
  return {
    orderingPortalSwitch,
    orderingPortalStatus,
    ...progress,
  };
};
