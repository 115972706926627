import { call, put, select, takeLatest } from "redux-saga/effects";
import { editLoyaltyItemAction } from "../../constants";
import { IEditLoyaltyItemAction, IStoreItem } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { editLoyaltyItem } from "../../axios/editLoyaltyItem";
import { delay } from "redux-saga/effects";
import { customNotify } from "../../helpers/customNotification/customNotification";
import i18n from "../../i18n/i18n";
import { TranslationOptions } from "i18next";
import { trackEvent } from "../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";
import Strings from "../../i18n/strings/giftList";

const t = (key: string, options?: TranslationOptions) =>
  i18n.t(key, { ns: "giftList", ...options });

// The problem is as follows, scraping items we do not scrape their images
// The system requires any item that is in the loyalty program meaning both
// SpecialItems, and StoreItems -with the property is_in_loyalty_program set to 1-
// to have images for some reason, so here is a validation code that is returned
// whenever that happens
export const CODE_ITEM_HAS_NO_IMAGE = 1;

export const notifyItemHasNoImage = () => {
  customNotify.error(t(Strings.itemHasNoImageError), 10000);
};

function* editLoyaltyItemSaga(action: IEditLoyaltyItemAction) {
  const { payload } = action;
  try {
    const token = yield select(selectToken);
    const editedLoyaltyItemRes = yield call(editLoyaltyItem, token, {
      is_in_loyalty_program: payload.is_in_loyalty_program,
      id: payload.id,
    });
    const item = editedLoyaltyItemRes.data as IStoreItem;
    yield put(actions.editLoyaltyItemSuccess(item));
    if (payload.is_in_loyalty_program) {
      customNotify.success(
        t(Strings.addSpecialItemSuccess, { name: payload.name })
      );
      trackEvent(EVENTS_FOR_TRACKING["Added a gift list item"]);
    }
  } catch (e) {
    yield delay(1000);
    if (
      e &&
      e.response &&
      e.response.data &&
      e.response.data.code === CODE_ITEM_HAS_NO_IMAGE
    ) {
      notifyItemHasNoImage();
    } else if (payload.is_in_loyalty_program) {
      customNotify.error(
        t(Strings.addSpecialItemFailure, { name: payload.name }),
        10000
      );
    }
    yield put(actions.editLoyaltyItemFailure(payload));
  }
}

export function* watchEditLoyaltyItemSaga() {
  yield takeLatest(editLoyaltyItemAction.requested, editLoyaltyItemSaga);
}
