export default {
  invalid_phone_number: "Please enter a valid phone number",
  payment_method: "Payment method",
  transaction_time: "Transaction time",
  branch: "Branch",
  type: "Type",
  customer: "Customer",
  fulfillment_rate: "Fulfillment rate",
  price: "Price",
  points: "Points",
  status: "Status",
  time_to_accept: "Time to accept",
  rate: "Rate",
  export_csv: "Export CSV",
  acceptance_rate: "Acceptance rate",
  search: "Search by phone number...",
  searchWithOrderCode: "Search by phone number، or order code ...",
  searchBranchName: "Search by branch name...",
  all: "All",
  on_track: "On-track",
  off_track: "Off-track",
  general: "General",
  customerSegment: "Customer segment",
  appCustomer: "App customer",
  nonAppCustomer: "Non app customer",
  male: "Male",
  female: "Female",
  usersWithUnregisteredAge: "Users with unregistered age",
  moreAdvanced: "More advanced",
  onePlusRedemption: "+1 Redemptions",
  noRedemption: "No Redemptions",
  applyFilters: "Apply filters",
  all_filter: "All filters",
  selectedFilters: "Selected filters",
  branchesSelected: "Branches selected",
  customerType: "Customer type",
  customerAge: "Age group",
  customerGender: "Customer gender",
  totalVisits: "Total visits",
  totalPoints: "Total points",
  lastVisit: "Last visit date",
  resetAll: "Reset all",
  all_transactions: "All Transaction ({{transaction_counts}})",
  all_redemptions: "All Redemptions ({{redemption_counts}})",
  all_customers: "All Customers ({{customer_counts}})",
  all_branches: "All Branches ({{branch_counts}})",
  transactions: "Transactions",
  customers: "Customers",
  branches: "Branches",
  redemptions: "Redemptions",
  gift: "Gift",
  online_orders: "Online orders",
  visits: "Visits",
  current_points: "Current points",
  life_time_points: "Life time points",
  ticket_size: "Ticket size:",
  branch_dropdown_title: "Branch:",
  more_filters: "More filters",
  average_ticket_size: "Average ticket size",
  branch_name: "Branch",
  delivery: "Delivery",
  pickup: "Pickup",
  web_portal: "web portal",
  offline: "offline",
  online: "online",
  sortBy: "Sort by",
  asc: "Ascending",
  desc: "Descending",
  dine_in: "Dine in",
  takeaway: "Takeaway",
  // fraud list  en 
  action: "Action",
  reason: "Reason",
  fraudRank: "Fraud Rank",
  markAsReviewed: "Mark as reviewed",
  currentPoints: "current Points",
  numOfTransactions: "Number of transactions",
  reviewed: "Reviewed",
  pointsRemoved: "Points Removed",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  SUSPECT: "SUSPECT",

  blocked: "Blocked",
  removePointsConfirmationMsg: "Are you sure you want to remove user’s points?",
  cancelCta: "Cancel",
  removePointsCta: "Remove points",
  blockCustomerCta: "Block user",
  blockCustomerconfirmationMsg: "Are you sure you want to block user? (This will remove all of the customer past and future points)",
  unreviewed: "Unreviewed",
  phoneNumberPlaceholder: "Search by phone number...",
  //=> watch tower
  wallet: "Wallet",
  compensations: "Compensations",
  recentVisits: "Recent visits",
  promoCodeChecker: "promo-code-checker",
  actionCenter: "Action center",
  blockCustomer: "Block customer",
  blockedBy: "blocked by",
};
