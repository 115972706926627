import { IMenu } from "../../lib/types";
import { reducer, on } from "ts-action";
import { LoadingStatus, withLoadingReducer } from "./withLoadingState";
import { getOrderingMenusAction } from "../../constants/actions";
import {
  getMenusSuccess,
  createMenuSuccess,
  deleteOrderingMenuSuccess,
  deleteOrderingMenuFailure,
  deleteOrderingMenu,
} from "../actions/menuActions";

interface IState {
  menus: string[];
  menusById: { [x: string]: IMenu };
  deleted_status?: LoadingStatus;
}

const initialState: IState = {
  menus: [],
  menusById: {},
  deleted_status: undefined,
};

export const orderingMenusReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getMenusSuccess, (state: IState, { payload }) => ({
        ...state,

        menus: payload
          .sort((a, b) => a.created_at - b.created_at)
          .map((a) => a.id),
        menusById: payload.reduce(
          (acc, next) => ({ ...acc, [next.id]: next }),
          state.menusById
        ),
      })),
      on(deleteOrderingMenuSuccess, (state: IState, {}) => ({
        ...state,
        deleted_status: LoadingStatus.success,
      })),
      on(deleteOrderingMenuFailure, (state: IState, {}) => ({
        ...state,
        deleted_status: LoadingStatus.failed,
      })),
      on(deleteOrderingMenu, (state: IState, {}) => ({
        ...state,
        deleted_status: LoadingStatus.loading,
      })),
      on(createMenuSuccess, ({ menus, menusById }, { payload }) => ({
        menus: [...menus, payload.id],
        menusById: {
          ...menusById,
          [payload.id]: payload,
        },
      })),
    ] as any,
    initialState
  ),
  getOrderingMenusAction
);
