import React from "react";
import styles from "./styles.module.sass";
import { useSelect } from "../../../../hooks/useSelect";
import DraggableCard from "../DraggableBannerCard";
import {
  BannerCard,
  reorderBannerCard,
} from "../../../../redux-store/actions/brand-visuals-actions";
import ConstructSortableDnDList from "../../../../Components/WizardComponents/Menu/SortableDnDList/index";
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  XYCoord,
} from "react-dnd";
import { useDispatch } from "react-redux";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import strings from "../../../../i18n/strings/brandVisuals";
const SortableDnDList = ConstructSortableDnDList<BannerCard>("BannerCards");

export const BannerCards: React.FC = () => {
  const { t } = useTranslation("brandVisuals");
  const dispatch = useDispatch();
  const { bannerCards } = useSelect((state) => state.bannerCardsReducer);
  const reOrder = ({ id, order: old_order }: BannerCard, order: number) => {
    dispatch(
      reorderBannerCard({ banner_card_id: id, new_order: order, old_order })
    );
  };

  const renderItem = (
    item: BannerCard,
    isDragging: boolean,
    connectDragSource: ConnectDragSource,
    connectDragPreview: ConnectDragPreview,
    connectDropTarget: ConnectDropTarget,
    position: XYCoord | null
  ) => {
    return (
      <>
        <div className={styles.secondWrapper} key={item.id}>
          <DraggableCard
            bannerCard={item}
            dndIntegration={{
              connectDragPreview,
              connectDragSource,
              connectDropTarget,
              isDragging,
              position,
            }}
          />
        </div>
      </>
    );
  };
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{t(strings.bannerCards)}</span>
      <span className={styles.description}>{t(strings.recommendedSize)}</span>
      <div className={styles.cards}>
        <SortableDnDList
          data={bannerCards}
          onReOrder={reOrder}
          renderItem={renderItem}
        />
      </div>
    </div>
  );
};

export default BannerCards;
