import { getOrderingQRPortalStatusAction } from "./../../../constants/actions";
import { getOrderingQRPortalStatusSuccess } from "./../../actions/Portal/index";
import { getOrderingQRPortalApi } from "./../../../axios/Portal";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../redux-store/selectors";
import { getOrderingQRPortalStatusFailure } from "../../actions/Portal";

function* getOrderingQRPortalStatusSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getOrderingQRPortalApi, token);
    yield put(getOrderingQRPortalStatusSuccess(res.data));
  } catch (error) {
    yield put(getOrderingQRPortalStatusFailure(error as any));
  }
}

export function* watchGetOrderingQRPortalSaga() {
  yield takeLatest(
    getOrderingQRPortalStatusAction.requested,
    getOrderingQRPortalStatusSaga
  );
}
