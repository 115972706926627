/* eslint-disable no-restricted-globals */
import { call, put, select, takeLatest } from "redux-saga/effects";
import { addBranchAction } from "../../constants";
import { IBranch } from "../../types/shared";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { addBranch } from "../../axios/addBranch";
import { delay } from "redux-saga/effects";
import { customNotify } from "../../helpers/customNotification/customNotification";
import i18n from "../../i18n/i18n";
import { TranslationOptions } from "i18next";
import Strings from "../../i18n/strings/branches";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";
import { trackEvent } from "../../tracking/trackEvent";
import { IAddBranchAction } from "../../types/wizard-types";

const t = (key: string, options: TranslationOptions) =>
  i18n.t(key, { ns: "branches", ...options });

function* addBranchSaga({ payload }: IAddBranchAction) {
  try {
    const token = yield select(selectToken);
    const { optimisticId, fallBack, state, id, ...branchData } = payload;
    const addedBranch = yield call(addBranch, token, branchData);
    const branch = addedBranch.data as IBranch;
    yield put(actions.addBranchSuccess({ ...branch, optimisticId }));
    trackEvent(EVENTS_FOR_TRACKING["Added a branch"]);
    customNotify.success(t(Strings.addBranchSuccess, { branchName: name }));
  } catch (e) {
    yield delay(1000);
    yield put(actions.addBranchFailure(payload));
    customNotify.error(
      t(Strings.addBranchFailure, { branchName: payload.name })
    );
  }
}

export function* watchAddBranchSaga() {
  yield takeLatest(addBranchAction.requested, addBranchSaga);
}
