export default {
  open: "فتح",
  close: "غلق",
  delivery: "خدمة التوصيل",
  pickup: "إستلام من الفرع",
  save: "حفظ",
  branchesOpeningHours: "Branches opening hours",
  openingHoursCustomizedToEveryBranch:
    "Opening hours customized to every branch",
  updateBranches: "Update Branches",
  noBranchesFound: "No Branches Found",
  searchForBranchName: "Search for branch name…",
  pleaseWaitWhileUpdatingAllBranches:
    "Please wait while updating all branches...",
  customizeOpeningHoursPerBranch: "+ Customize opening hours per branch",
};
