import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  getDistricts,
  getDistrictsSuccess,
  searchForArea,
} from "../actions/get-districts-actions";
import { getDistrictsAction } from "../../constants/actions";
import { IGeographicItem } from "../../../WizardComponents/Branches/AddBranchForm/lib";

interface IState {
  districts: string[];
  districtsById: { [x: string]: IGeographicItem };
  shownDistricts: string[];
}

const initialState: IState = {
  districts: [],
  districtsById: {},
  shownDistricts: [],
};

export const getAreasReducer = withLoadingReducer(
  reducer<IState>([
    on(getDistrictsSuccess, (state: {}, { payload }) => ({
      ...state,
      districts: payload.map((area) => area.id),
      districtsById: payload.reduce(
        (acc, area) => ({
          ...acc,
          [area.id]: area,
        }),
        {}
      ),
      shownDistricts: payload.map((area) => area.id),
    })),
    on(getDistricts, () => ({
      districts: [],
      districtsById: {},
      shownDistricts: [],
    })),
    on(searchForArea, (state: IState, action) => {
      const { districts, districtsById } = state;
      const term = action.payload.trim().toLowerCase();
      if (!term) {
        return { ...state, shownDistricts: districts };
      }
      const searchedAreas = districts.filter((c) => {
        const area = districtsById[c];
        const formattedEnAreaName = area.EnName.toLowerCase();
        const enNameMatches = formattedEnAreaName.includes(term.toLowerCase());
        const arNameMatches = area.ArName.includes(term.toLowerCase());
        return enNameMatches || arNameMatches;
      });

      return { ...state, shownDistricts: searchedAreas };
    }),
  ] as any, initialState),
  getDistrictsAction
);
