import axios from "../../axios";
import * as routes from "../../api-routes/Manager/Analytics";

export const getOnlineOrdersGraphReq = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getOnlineOrdersChart}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};
