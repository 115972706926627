import sl from "../en/socialLinks";
import convertingRatio from "../en/convertingRatio";

export default {
  myProfileHeader: "My Profile",
  myProfileSubHeader: "Select a branch to see its assigned cashiers",
  PI: "Personal information",
  title: "Dashboard users",
  profilePicture: "Profile Picture",
  uploadImg: "Upload Image",
  subTitle: "Manage the people who access your dashboard",
  allUsers: "All users ({{count}})",
  fullName: "Full Name",
  addBranchTitle: "Add new branch",
  email: "Email",
  phoneNumber: 'Phone Number',
  countryCode: 'Country Code',
  assignToBranches: "Assign to branches",
  otherJobTitle: "Other job title",
  jobTitle: "Job title",
  searchForUser: "Search for users…",
  editUser: "Edit",
  viewUser: "View",
  addUser: "Add user",
  addUserTT: "Add user",
  name: "Name",
  role: "Role",
  brandSettings: "Brand settings",
  dashboardUsers: "Dashboard Users",
  missingRole: "Missing role",
  pendingInvitations: "Pending Invitations",
  resendInvitation: "Resend invitation",
  lastLogin: "Last Login",
  pendingUsers: "Pending Users",
  description1: "Full access to all dashboard information and settings",
  description2: "Customized access to all dashboard information and settings",
  description3: "Limited access to certain branches data",
  description4: "Limited access to reviews only",
  description5: "Any one who’s responsible for handling operation",
  description6: "Mark on the position that your didn’t have",
  all: "All",
  managePositions: "Manage Positions",
  title1: "CEO/Owner",
  title2: "Senior Management",
  title3: "Area / Branch manager",
  title4: "Customer Services",
  title5: "Operation manager",
  title6: "Marketing manager",
  title7: "IT manager",
  addaCustomerServicesUser: " a Customer Service User",
  addaBranchMangerUser: " Branch Manger User",
  addaSeniorManagmentUser: " a Senior Management User",
  addanOwneracc: " a CEO/Owner Account",
  accessToAssignedTitle: "Access to assigned branch data",
  saveBtn: "Save",
  cancelBtn: "Cancel",
  unknown: "UNKNOWN",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  submit: sl.submit,
  deleteUser: "Delete User",
  deleteUserDescription:
    "Your are about to delete this user. confirm to continue",
  confirmDelete: "Confirm",
  cancel: "Cancel",
  modalDescription: "Are you sure you want to",
  modalPhoneNumberText: "whose phone number is",
  modalAssignTitle: "assign",
  modalUnassignTitle: "unassign",
  modalPOCTitle: "as a POC",
  assignUserButtonStaticText: "Yes",
  unAssignUserButtonStaticText: "No",
  modalSubTitleStaticText: "so, he will receive",
  modalNotReceiveMsg: "so, he will not receive",
  modaWhatsappMsg: "any WhatsApp alerts for this brand"
};