import { isValidPhoneNumber } from 'libphonenumber-js';
import memoize from "lodash/memoize";
import Strings from "../i18n/strings/validators";
import i18n from "../i18n/i18n";
import { Validator } from "redux-form";
interface IAddress {
  address: string;
  latLng: {
    lat: number;
    lng: number;
  };
}

export const validateAddress = (address: IAddress) => {
  if (address && address.latLng && address.latLng.lat && address.latLng.lng) {
    return undefined;
  }
  return t(Strings.location);
};

const t = (str, options?) => i18n.t(str, { ns: "validators", ...options });
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

let phoneNumberRegx = /^\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const phoneNumberValidation = (value) => value && !phoneNumberRegx.test(value)
  ? 'Invalid Phone Number' : undefined;
export const validatePhoneNumber = (country_code) => (value) => country_code && value && !isValidPhoneNumber(value, country_code === "+2" ? "EG" : "SA")
  ? 'Invalid Phone Number' : undefined;
export const required = (value) =>
  value && value.length ? undefined : t(Strings.required);
export const atLeastOneField = (value) =>
  value && value.length ? undefined : t(Strings.atLeastOneField);
export const requiredColorPicker = (value) =>
  value && value.hex ? undefined : t(Strings.required);
export const isEmpty = (value) =>
  value && value.trim() ? undefined : t(Strings.isEmpty);
export const maxLength = memoize((max) => (value) =>
  value && value.length > max ? t(Strings.maxLength, { max }) : undefined
);


export const minLength = memoize((min) => (value) =>
  value && value.length < min ? t(Strings.minLength, { min }) : undefined);

export const number = (value) => {
  return !isNaN(Number(value)) ? undefined : t(Strings.mustBeNumber);
};
export const integer = (value) => {
  return Number.isInteger(Number(value)) ? undefined : t(Strings.mustBeInteger);
};
export const percentage = (value) => {
  return value && +value >= 0 && +value <= 100 ? undefined : t(Strings.range);
};
export const percentageVat = (value) => {
  return value !== undefined
    ? +value >= 0 && +value <= 1
      ? undefined
      : t(Strings.vat)
    : t(Strings.vat);
};

export const MinMax = (value) => {
  let error;
  if (value && value.pickup_prep_min && value.pickup_prep_max) {
    error =
      value.pickup_prep_min < value.pickup_prep_max
        ? undefined
        : "Minimum preparation time cant be greater than Maximum preparation time";
  } else {
    error = "Please enter a valid value";
  }
  return error;
};

export const validateKeys = <T extends {}>(
  keys: Array<keyof T>,
  validators: Validator[]
) => (value) => {
  for (const validator of validators) {
    for (const key of keys) {
      const message = validator((value || {})[key]);
      if (message) {
        return message;
      }
    }
  }
  return undefined;
};

export const requiredFile = (value) =>
  value instanceof Blob ? undefined : t(Strings.required);

//   (
//   value: { delivery_time?: string; delivery_fees?: string } = {}
// ) => {
//   return required(String(value.delivery_fees || value.delivery_time));
// };

// export const isDate = value =>{

//   let error;
//   if(value && value.minTime && value.maxTime !== 'undefined'){
//     const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
//     error = isValid?'':'Minimum preparation time cant be greater than Maximum preparation time'
//   }else{
//     error = 'undefined value';
//   }
//   return error;
// }

export const minValue = memoize((min) => (value) =>
  value < min ? t(Strings.minValue, { min }) : undefined
);
export const maxValue = memoize((max) => (value) =>
  value && value > max ? t(Strings.maxValue, { max }) : undefined
);
export const maxAndMinValidate = (maximum: number, minimum: number) =>
  minimum <= maximum
    ? undefined
    : "Minimum Item can't be greater than Maximum Item";
export const asyncValidateGift = ({ gift_photo }) => {
  return Promise.all(
    gift_photo.map(async (next) => {
      try {
        const isImage = await isTrueImage(next);
        if (!isImage) {
          throw { gift_photo: t(Strings.image) };
        }
      } catch (e) {
        throw { gift_photo: t(Strings.image) };
      }
    })
  );
};
const isTrueImage = (file) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve(!!image.width);
    image.onerror = () => resolve(false);
    image.src = URL.createObjectURL(file);
  });
};

export const isElmenusURL = (value) =>
  value && value.includes("elmenus.com") ? undefined : t(Strings.url);
export const isHungerURL = (value) =>
  value && value.includes("hungerstation.com") ? undefined : t(Strings.url);
export const isFacebookURL = (value) =>
  value && value.includes("facebook.com") ? undefined : t(Strings.url);
export const isTwitterURL = (value) =>
  value && value.includes("twitter.com") ? undefined : t(Strings.url);
export const isSnapchatURL = (value) =>
  value && value.includes("snapchat.com") ? undefined : t(Strings.url);
export const isInstagramURL = (value) =>
  value && value.includes("instagram.com") ? undefined : t(Strings.url);

export const arrayNotEmpty = (value: any[]) =>
  value && value.length > 0 ? undefined : t(Strings.required);

export const validateDelivery = validateKeys<{
  delivery_time?: string;
  delivery_fees?: string;
}>(["delivery_fees", "delivery_time"], [required, number, minValue(0)]);

interface IAddressValueType {
  address?: string;
  latLng?: {
    lat: string;
    lng: string;
  };
}

export const validateMapAddress = (addressValue?: IAddressValueType) => {
  if (
    addressValue &&
    addressValue.address &&
    addressValue.latLng &&
    addressValue.latLng.lat &&
    addressValue.latLng.lng
  ) {
    return undefined;
  }
  return t(Strings.required);
};
