import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getOrderingModifierGroupsAction } from "../../constants/actions";
import {
  getOrderingModifierGroupsSuccess,
  getOrderingModifierGroupsFailure,
} from "../actions/modifierGroupsAction";
import { getOrderingMenuModifierGroups } from "../../axios/getOrderingMenuModifierGroups";

function* getModifierGroupsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getOrderingMenuModifierGroups, token);

    yield put(getOrderingModifierGroupsSuccess(res.data));
  } catch (error) {
    yield put(getOrderingModifierGroupsFailure(error));
  }
}

export function* watchGetModifierGroupsSaga() {
  yield takeLatest(
    getOrderingModifierGroupsAction.requested,
    getModifierGroupsSaga
  );
}
