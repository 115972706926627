import axios from "../axios";
import { bannerCardsURL } from "../requests";
export const addBannerCardApi = (
  token: string,
  data: {
    order: number;
    image: string;
  }
) => {
  return axios.post(bannerCardsURL, data, {
    headers: { token },
  });
};
