import { on, reducer } from "ts-action";
import {
  BannerCard,
  addBannerCard,
  addBannerCardFailure,
  addBannerCardSuccess,
  deleteBannerCard,
  deleteBannerCardFailure,
  deleteBannerCardSuccess,
  getBannerCards,
  getBannerCardsFailure,
  getBannerCardsSuccess,
  reorderBannerCard,
  reorderBannerCardFailure,
  reorderBannerCardSuccess,
  resetBannerCardsState,
  selectBannerCard,
  setBannerCardModalVisibility,
} from "../actions/brand-visuals-actions";
import update from "immutability-helper";
import { mapById } from "../../Utils";

export type BannerCardsState = {
  selectedBannerCard: Omit<BannerCard, "id" | "image"> & {
    id?: string;
    image?: string;
  };
  bannerCards: BannerCard[];
  loading: boolean;
  failed: boolean;
  success: boolean;
  bannerCardModalVisible: boolean;
};
export const bannerCardsReducer = reducer<BannerCardsState>(
  [
    on(getBannerCards, (state, _) => ({ ...state, loading: true })),
    on(getBannerCardsSuccess, (state, { payload }) => {
      const bannerCardByOrder = mapById(payload, "order");
      return {
        ...state,
        bannerCards: state.bannerCards.map((card) => {
          const updatedCard = bannerCardByOrder[card.order];
          if (updatedCard) {
            return {
              ...updatedCard,
              image: process.env.REACT_APP_IMAGE_ENDPOINT + updatedCard.image,
            };
          }
          return card;
        }),
        loading: false,
        failed: false,
        success: true,
      };
    }),
    on(getBannerCardsFailure, (state, _) => ({
      ...state,
      loading: false,
      failed: true,
      success: false,
    })),
    on(addBannerCard, (state, _) => ({ ...state, loading: true })),
    on(addBannerCardSuccess, (state, { payload }) => {
      return {
        ...state,
        bannerCards: state.bannerCards.map((card) => {
          if (card.order === payload.order) {
            return {
              ...payload,
              image: process.env.REACT_APP_IMAGE_ENDPOINT + payload.image,
            };
          }

          return card;
        }),
        loading: false,
        failed: false,
        success: true,
      };
    }),
    on(addBannerCardFailure, (state, _) => ({
      ...state,
      loading: false,
      failed: true,
      success: false,
    })),
    on(deleteBannerCard, (state, _) => ({
      ...state,
      loading: false,
      failed: false,
      success: true,
    })),
    on(deleteBannerCardFailure, (state, _) => ({
      ...state,
      loading: false,
      failed: true,
      success: false,
    })),
    on(deleteBannerCardSuccess, (state, { payload }) => ({
      ...state,
      bannerCards: state.bannerCards.map((card) => {
        if (card.id === payload.id) {
          return {
            ...card,
            id: "",
            image: "",
            created_at: 0,
            updated_at: 0,
          };
        }
        return card;
      }),
      loading: false,
      failed: false,
      success: true,
    })),
    on(reorderBannerCard, (state, _) => ({
      ...state,
      loading: true,
      failed: false,
      success: false,
    })),
    on(reorderBannerCardFailure, (state, { payload }) => {
      const updatedBannerCards = state.bannerCards.map((card) => ({
        ...card,
        order: payload.id === card.id ? payload.order : card.order,
      }));
      return {
        ...state,
        bannerCards: updatedBannerCards,
        loading: false,
        failed: true,
        success: false,
      };
    }),
    on(reorderBannerCardSuccess, (state, { payload }) => {
      const oldIndex = state.bannerCards.findIndex((card) => card.id === payload.id);
      const newIndex = payload.order;
      const newBannerCards = [...state.bannerCards];

      const [removedItem] = newBannerCards.splice(oldIndex, 1);
      newBannerCards.splice(newIndex, 0, removedItem);

      const start = Math.min(oldIndex, newIndex);
      const end = Math.max(oldIndex, newIndex);
      for (let i = start; i <= end; i++) {
        newBannerCards[i].order = i;
      }

      return update(state, {
        bannerCards: { $set: newBannerCards },
        loading: { $set: false },
        failed: { $set: false },
        success: { $set: true },
      });
    }),

    on(resetBannerCardsState, (state, _) => ({
      ...state,
      selectedBannerCard: {
        order: 0,
        id: undefined,
        image: undefined,
        created_at: 0,
        updated_at: 0,
      },
      loading: false,
      failed: false,
      success: false,
    })),
    on(selectBannerCard, (state, { payload }) => {
      const bannerCardById = mapById(state.bannerCards, "id");
      return {
        ...state,
        selectedBannerCard: bannerCardById[payload.banner_card_id],
      };
    }),
    on(setBannerCardModalVisibility, (state, { payload }) => {
      return {
        ...state,
        bannerCardModalVisible: payload,
      };
    }),
  ],
  {
    bannerCardModalVisible: false,
    bannerCards: Array.from({ length: 5 }, (_, index) => ({
      order: index,
      id: `banner-card-${index + 1}`,
      image: "",
      created_at: 0,
      updated_at: 0,
    })),
    loading: false,
    failed: false,
    success: false,
    selectedBannerCard: {
      order: 0,
      id: undefined,
      image: undefined,
      created_at: 0,
      updated_at: 0,
    },
  }
);
