import axios from "./axios";
import * as Requests from "./requests";

export const setRoutingMethodAPI = (token: string, is_call_center: boolean) => {
  return axios.put(
    Requests.SET_ROUTING_METHOD_API,
    { is_call_center },
    {
      headers: {
        token,
      },
      baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
    }
  );
};
