export default {
  changeItemCategory: "Failed to change item category, retrying...",
  createCategory: "Failed to create category, retrying...",
  createItem: "Failed to create menu item, retrying...",
  createModifierGroup: "Failed to create modifier group, retrying...",
  deleteCategory: "Failed to delete category, retrying...",
  deleteItem: "Failed to delete item, retrying...",
  deleteSuggestedModifierGroup: "Failed to delete modifier group, retrying...",
  editCategory: "Failed to edit category, retrying...",
  editItem: "Failed to edit item, retrying...",
  editModifierGroup: "Failed to edit modifier group, retrying...",
  reorderItem: "Failed to reorder item, retrying...",
  reorderCategory: "Failed to reorder category, retrying...",
};
