export default {
  whyUse: "لماذا",
  wizardName: "Koinz Run",
  questionMark: "؟",
  wizardDescriptionPart1: "التحول التقني ليس سهلاً! لهذا قمنا بتطوير",
  wizardDescriptionPart2:
    "لمساعدتك في إضافة بيانات متجرك على منصة Koinz بسهولة.",
  menu: "القائمة",
  // menuDescription: 'أضف قائمتك في 3 نقرات مع أداتنا السريعة السهلة في الاستخدام',
  menuDescription:
    "نوفر لك أداة سهلة الاستخدام تمكنك من إضافة قائمتك في 3 نقرات",
  giftList: "الجوائز",
  giftListDescription: "أسهل طريقة لاختيار وإضافة هدايا سيحبها عملائك",
  startNow: "ابدأ الآن",
};
