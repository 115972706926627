import axios from "../../axios";
import * as routes from "../../api-routes/Manager/Analytics";

export const getTotalOrdersValue = async (
  token,
  type,
  comparison_days?: number
) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTotalOrdersValue(
      type
    )}`,
    {
      headers: {
        token,
      },
      params: {
        comparison_days,
      },
    }
  );
  return data;
};
