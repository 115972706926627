import sl from "../en/socialLinks";
import convertingRatio from "../en/convertingRatio";
import brandVidjwels from "./brandVisuals";

export default {
  myProfileHeader: "My Profile",
  myProfileSubHeader: "Manager your dashboard profile",
  PI: "Personal Information",
  profilePicture: "Profile Picture",
  uploadImg: "Upload Image",
  password: "Password",
  changePassword: "Change Password",
  fullName: "Full Name",
  phoneNumber: "Phone Number",
  email: "Email",
  assignToBranches: "Your Branches",
  role: "Role",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  save: sl.save,
  oldPassword: "Old Password",
  newPassword: "New Password",
  cancel: "Cancel",
  unsupportedFileType: brandVidjwels.unsupportedFileType,
  cropProfilePic: "Crop profile picture",
  passwordChangeSuccess: "Password has been changed successfully",
  passwordChangeFailure: "Your old password is wrong!",
  profileEditSuccess: "Your profile has been edited successfully",
  overviewEmail: "Weekly Overview Emails",
  criticalReviewsEmail: "Issues Reporting Emails",
  overviewEmailDescription:
    "This weekly email will keep you up to date on how your brand is performing using Koinz",
  emailReportingTitle: "Manage Recived email",
  criticalReviewsEmailDescription:
    "This is an alert email that you receive whenever a customer leaves a bad review on any of their visit.",
  // criticalReviewsEmailDescription: '',
  confirmChangePasswordTitle: "confirmChangePasswordTitle",
  confirmChangePasswordDescription: "confirmChangePasswordDescription",
  confirm: "confirm",
};
