import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
// styles
import styles from "./styles.module.sass";
import "./styles.less";
// assets
import LiveUpdatesIcon from "../../../../../../assets/live-updates.gif";
// components
import { LiveUpdateTransaction } from "./components";
import MultiSelect from "../../../../../../@koinz-ui/MultiSelect";
import { Queries } from "../../../../../../Queries/Manager";
import { TransactionType } from "./components/LiveUpdateTransaction";
import StoreData from "../../../../../../contexts/StoreData";
import { pusher } from "../../../../../../pusher/pusher";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Spin, Popover } from "antd";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useIsVisible } from "react-is-visible";
export enum TypeFilter {
  redemptions = "redemptions",
  online_visits = "online_visits",
  offline_visits = "offline_visits",
  reviews = "reviews",
}

export const LiveUpdates: React.FC = () => {
  const liveUpdatesDiv: any = useRef(null);
  const { token, storeData } = useContext(StoreData);
  const { t } = useTranslation("home");
  const [filters, setFilters] = useState<{
    page: number;
    size: number;
    filter: TypeFilter[];
  }>({
    page: 1,
    size: 20,
    filter: [
      TypeFilter.offline_visits,
      TypeFilter.online_visits,
      TypeFilter.reviews,
      TypeFilter.redemptions,
    ],
  });
  const [data, setSortedData] = useState<any[]>([]);
  const liveUpdatesRef = useRef(null);
  const isVisible = useIsVisible(liveUpdatesRef);
  const {
    data: queryData,
    status,
    refetch,
  } = Queries.useGetLiveUpdates(token, filters);
  const [newRecords, setNewRecords] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const [receivedTransaction, setReceivedTransaction] =
    useState<boolean>(false);
  useEffect(() => {
    if (isVisible && !queryData && status === "idle") {
      refetch();
    }
  }, [isVisible]);
  const setData = (data: any[]) => {
    setSortedData((oldData) => {
      let dataToSort: any[] = [];

      // if a new filter applied set only the new data
      if (shouldReset) {
        dataToSort = [...data];
        setShouldReset(false);
      } else {
        // if same filter but new pagination set old+new data
        dataToSort = [...oldData, ...data];
      }

      return [...dataToSort.sort(sortTransactions)];
    });
  };

  // tell the component u have a new socket event trigger
  useEffect(() => {
    if (receivedTransaction) {
      setTimeout(() => {
        setReceivedTransaction(false);
      }, 15000);
    }
  }, [receivedTransaction]);

  // Sort transactions based on created_at
  const sortTransactions = (a, b) => {
    const aTarget =
      a.type === TransactionType.onlineOrder
        ? a.data.onlineOrder.created_at
        : a.type === TransactionType.offlineOrder
        ? a.data.offlineOrder.created_at
        : a.type === TransactionType.redemption
        ? a.data.redemption.created_at
        : a.data.review.created_at;

    const bTarget =
      b.type === TransactionType.onlineOrder
        ? b.data.onlineOrder.created_at
        : b.type === TransactionType.offlineOrder
        ? b.data.offlineOrder.created_at
        : b.type === TransactionType.redemption
        ? b.data.redemption.created_at
        : b.data.review.created_at;

    if (aTarget < bTarget) return 1;
    if (aTarget > bTarget) return -1;

    return -1;
  };

  const loadMore = () => {
    setTimeout(() => {
      setFilters((f) => ({ ...filters, page: f.page + 1 }));
    }, 1000);
  };

  useEffect(() => {
    if (status === "success") {
      // pagination controls
      if (queryData.length < filters.size) {
        setHasMore(false);
      }

      setData(queryData);
    }
  }, [status]);

  useEffect(() => {
    if (status === "success" && newRecords.length > 0) {
      setData(newRecords);
      setNewRecords([]);
    }
  }, [newRecords, status]);

  useEffect(() => {
    const socket = pusher(token, storeData?.id as string);
    const channel = socket.subscribe(`presence-live_updates.${storeData?.id}`);

    channel.bind("new_visit", (res) => {
      setReceivedTransaction(true);
      setNewRecords([...newRecords, { ...res, new: true }]);
    });
    channel.bind("new_review", (res) => {
      setReceivedTransaction(true);
      setNewRecords([...newRecords, { ...res, new: true }]);
    });
    channel.bind("new_redemption", (res) => {
      setReceivedTransaction(true);
      setNewRecords([...newRecords, { ...res, new: true }]);
    });

    return () => {
      socket.unsubscribe(`presence-live_updates.${storeData?.id}`);
    };
  }, []);

  const transactions: any[] = useMemo(
    () => [...data],
    [data, filters.page, filters.size, filters.filter]
  );

  const onTypeFilterChange = (values: { id: string; [key: string]: any }[]) => {
    const newFilters: TypeFilter[] = values.map((v) => v.id as TypeFilter);
    // check that the user doesn't click on apply with the same filters selected
    const isEquals =
      newFilters.length === filters.filter.length &&
      newFilters.every((value, index) => value === filters.filter[index]);

    if (!isEquals) {
      // tell the component u have a new type filter to set only the new data
      setShouldReset(true);
      // Set filters
      setFilters({
        ...filters,
        page: 1,
        filter: values.map((v) => v.id as TypeFilter),
      });
    }
  };

  const scrollToTop = () => {
    liveUpdatesDiv.current.scrollIntoView();
    setReceivedTransaction(false);
  };

  // Loader UI for antD Spin component
  let loaderIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

  return (
    <div
      ref={liveUpdatesRef}
      className={`${styles.wrapper} ${useRtlClass(styles)} ${
        receivedTransaction && styles.animatedWrapper
      } live-updates`}
    >
      <div className={styles.header}>
        <span className={styles.headerTitle}>
          <div className={styles.liveUpdatesIconWrapper}>
            <img className={styles.liveUpdatesIcon} src={LiveUpdatesIcon} />
          </div>
          <span className={styles.title}>{t(Strings.live_updates)}</span>
        </span>
        <div>
          <MultiSelect
            onApply={onTypeFilterChange}
            options={[
              { id: TypeFilter.online_visits, title: t(Strings.online) },
              { id: TypeFilter.offline_visits, title: t(Strings.offline) },
              { id: TypeFilter.redemptions, title: t(Strings.redemptions) },
              { id: TypeFilter.reviews, title: t(Strings.reviews) },
            ]}
            selected={filters?.filter.map((f) => ({ id: f }))}
            showAllSelected={true}
          />
        </div>
      </div>
      {shouldReset && status === "loading" ? (
        <div className={styles.loaderBody}>
          <Spin indicator={loaderIcon} />
        </div>
      ) : (
        <div className={styles.body}>
          <Popover
            placement="bottom"
            visible={receivedTransaction}
            overlayClassName={"live-updates"}
            content={
              <div className={styles.newMessage} onClick={scrollToTop}>
                <ArrowUpOutlined className={styles.icon} />
                <span className={styles.text}>
                  {t(Strings.you_have_a_new_transaction)}
                </span>
              </div>
            }
          ></Popover>

          <div className={styles.scroll} id={"scrollbarDiv"}>
            <InfiniteScroll
              dataLength={transactions.length}
              next={loadMore}
              scrollableTarget={"scrollbarDiv"}
              hasMore={hasMore}
              loader={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "24px",
                  }}
                >
                  <Spin indicator={loaderIcon} />
                </div>
              }
            >
              {transactions.length > 0 &&
                transactions.map((transaction, i) => (
                  <div
                    className={`${styles.card}`}
                    ref={i === 0 ? liveUpdatesDiv : null}
                    key={i}
                  >
                    <LiveUpdateTransaction
                      newTransaction={transaction.new ?? false}
                      type={transaction.type}
                      customer={transaction.data?.customer ?? undefined}
                      branch={transaction.data.branch}
                      data={
                        transaction.type === TransactionType.onlineOrder
                          ? transaction.data.onlineOrder
                          : transaction.type === TransactionType.offlineOrder
                          ? transaction.data.offlineOrder
                          : transaction.type === TransactionType.redemption
                          ? transaction.data.redemption
                          : transaction.data.review
                      }
                    />
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};
