export default {
  whyUse: "Why use",
  wizardName: "Koinz Run",
  questionMark: "?",
  wizardDescriptionPart1:
    "Tech transition isn’t the easiest thing in the world! that’s why we built",
  wizardDescriptionPart2:
    "to help you on-board your brand and perfect your presence on Koinz Platform easily.",
  menu: "Menu",
  menuDescription:
    "Import your menu in just 3 clicks, with our quick & easy to use tool.",
  giftList: "Gift list",
  giftListDescription:
    "The easiest tool to select & add gifts your customers will love.",
  startNow: "Start now",
};
