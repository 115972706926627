const images = {
  egyFlag: require("../images/egyFlag.png"),
  ksaFlage: require("../images/ksaFlag.png"),
  collapesArrow: require("../images/collapesArrow.png"),
  closeBrandIcon: require("../images/closeBrandIcon.png"),
  infoIcon: require("../ic_info.svg").default,
  closeModalIcon: require("../images/closeModalIcon.png"),
  csvIcon: require("../images/csvIcon.png"),
  downloadCsvIcon: require("../images/downloadCsvIcon.png"),
  uploadCsvIcon: require("../images/uploadCsvIcon.png"),
  uploadCSVRefresh: require("../images/uploadCSVRefresh.png"),
  faildUploadCSVIcon: require("../images/faildUploadCSVIcon.png"),
  refreshSMIcon: require("../images/ic_refresh.svg"),
  refreshLGIcon: require("../images/file_loading.svg"),
  fileIcon: require("../images/ic-file.svg"),
  checkIcon: require("../images/ic_check.svg"),
  chevron_down: require("../images/chevron-down.svg").default,
  percentage: require("../images/ic_percentage.svg").default,
  percentage_active: require("../images/ic_percentage_active.svg").default,
  target: require("../images/ic_target.svg").default,
  logo: require("../images/on-dark.svg").default,
  minusIcon: require("../images/minus.svg").default,
  whitecheckicon: require("../images/check.svg").default,
  ic_chevron_down: require("../images/ic_chevron_down.svg").default,
  ic_users: require("../images/ic_users.svg").default,
  ic_edit: require("../images/ic_edit.svg").default,
  ic_percentage_campaign: require("../images/ic_percentage_campaign.svg")
    .default,
  link: require("../images/ic-external-link.svg").default,
  discount: require("../images/discount.svg").default,
  search: require("../images/ic_search.svg").default,
  checkLg: require("../images/check-icon-lg.svg").default,
  uploadError: require("../images/upload-csv-error.svg").default,
  uploadFileIcon: require('../images/upload_file_icon.png').default,
  checkUploadIcon: require('../images/checkIcon.png').default,
  crossIcon: require('../images/crossIcon.png').default,
  successfullySendIcon: require('../images/successfully_send_icon.png').default,
};
export default images;
export { default as IconPlus } from "./IconPlus";
export { default as CrossIcon } from "./CrossIcon";
export { ReactComponent as ChevronDown } from "../images/ic_chevron_down.svg";
