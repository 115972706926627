import { useQuery } from "react-query";
import { getSalesGraphReq } from "../../../axios/Manager/Analytics";

export const useGetSalesChartQuery = (token, requestConfigs) => {
  const result = useQuery(
    ["salesGraph", requestConfigs],
    () => {
      if (!requestConfigs.store_id) return [];
      return getSalesGraphReq(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};
