import convertingRatio from "./convertingRatio";
import onBoardingSteps from "./onBoardingSteps";
export default {
  title: "Brand Visuals",
  logoLabel: "Your Brand Logo",
  logoDescription: "Logo should be at least 4KB and at most 1MB",
  logoUploadButtonText: "Upload Logo",
  coverLabel: "Add Cover Photo",
  coverDescription: "Cover should be at least 4KB and at most 1MB",
  coverUploadButtonText: "Upload Cover",
  unsupportedFileType: "Unsupported file type...",
  brandColorLabel: "Select your brand color",
  brandColorNote:
    "*Please note that the color you're about to select will appear for the users of the Koinz application",
  mockUpTitle: "This how your brand will look like on the app",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  next: onBoardingSteps.next,
  delete: "Delete",
  changeBanner: "Change Banner",
  uploadBanner: "Upload Banner",
  addBanner: "Add Banner",
  edit: "Edit",
  dragAndDropImageOrBrowse: "Drag and drop image or browse",
  bannerShouldBe: "Banner should be 343px * 190px",
  recommendSizeText: "Banner should be 343px * 190px",
  bannerCards: "Banner Cards",
  recommendedSize: "Recommended Size: 343px * 190px",
  bannerCardReorderedFailed: "Banner card reorder failed",
  bannerCardReorderedSuccessfully: "Banner card reordered successfully",
  bannerCardAddedSuccessfully: "Banner card added successfully",
  bannerCardAddedFailed: "Banner card added failed",
  bannerCardDeletedSuccessfully: "Banner card deleted successfully",
  bannerCardDeletedFailed: "Banner card deleted failed"
};
