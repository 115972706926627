import * as React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import DeliveryInformation from "./DeliveryInformation";
import { OrderType } from "../CustomerInformation";
import { Tooltip } from "reactstrap";
import { Placement } from "bootstrap";
import {
  IStore,
  IStoreAppInfo,
} from "../../../../../../../redux-store/actions/lib";

import { useState } from "react";
import { useProcessImageResource } from "../../../../../../BrandSettingsWraper/BrandSettings/ImgUploading/useProcessImageResource";
import Strings from "../../../../../../../i18n/strings/orderHistory";
import { useTranslation } from "../../../../../../../../WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import { useRtlClass } from "../../../../../../../../../lib";
export const useToggleState = (initialValue = false): [boolean, () => void] => {
  const [value, setValue] = useState(initialValue);
  return [value, () => setValue(!value)];
};

interface IBrandInformationProps {
  brandInfo: IStore;
  google: any;
  delivery_time: number;
  store: IStoreAppInfo;
  currency: string;
  orderType: OrderType;
  delivery_fees: number;
}

export const useTruncationFull = (str: string, maxLength = 12) => {
  return React.useMemo(() => {
    const truncatedName = str.slice(0, maxLength);
    return truncatedName.length < str.length
      ? `${truncatedName}...`
      : truncatedName;
  }, [str]);
};

interface ITextOverflowToolTipProps {
  maxLength: number;
  text: string;
  placement?: Placement;
}

const uuid = () => {
  return Math.floor(Math.random() * 100000);
};

export const TextOverflowToolTip: React.FC<ITextOverflowToolTipProps> = ({
  maxLength,
  text,
  placement = "top",
}) => {
  const id = React.useMemo(uuid, []);
  const [tooltipOpen, toggleToolTip] = useToggleState();
  return (
    <div id={"dd" + id}>
      {(text || "").length > maxLength && (
        <Tooltip
          style={{ fontSize: "1.4rem" }}
          placement={placement as Placement}
          target={"#dd" + id}
          toggle={toggleToolTip}
          isOpen={tooltipOpen}
        >
          {text}
        </Tooltip>
      )}
      <span>{useTruncationFull(text || "", maxLength)}</span>
    </div>
  );
};

const BrandInfo: React.FC<IBrandInformationProps> = (props) => {
  const {
    brandInfo,
    store,
    currency,
    orderType,
    delivery_fees,
    delivery_time,
  } = props;
  const avatar = React.useMemo(
    () =>
      store.name !== undefined
        ? store.name
            .split(/\s/)
            .reduce(
              (response: any, word: any) => response + word.slice(0, 1),
              ""
            )
        : "DF",
    [store]
  );
  const storeImage = useProcessImageResource(store.store_image);
  const { t } = useTranslation("orderHistory");
  const rtl = useRtlClass(styles);
  return (
    <div className={`${styles.wrapper} ${rtl}`}>
      <span className={styles.brandTitle}>{t(Strings.brandInformation)}</span>
      <div className={styles.brandWrapper}>
        <div className={styles.brand}>
          <div className={styles.brandAvatarWrapper}>
            {storeImage ? (
              <img className={styles.brandAvatarIcon} src={storeImage} />
            ) : (
              avatar
            )}
          </div>
        </div>
        <div style={{ margin: "2rem 0" }} />
        <div className={styles.brand}>
          <div className={styles.brandInfo}>
            <span className={styles.title}>{store.name}</span>
            <span className={styles.address}>
              <TextOverflowToolTip text={brandInfo.address} maxLength={50} />
            </span>
          </div>
        </div>
      </div>
      <div className={styles.customerPhoneWrapper}>
        <div className={styles.contactWrapper}>
          <img
            src={require("../../../../../../../assets/phone-call.svg").default}
          />
        </div>
        <div style={{ margin: "0 12px" }} />
        <div className={styles.phoneWrapper}>
          <span className={styles.phone}>{store.country_code}</span>
          <div style={{ margin: "0 .4rem" }} />
          <span className={styles.phone}>{brandInfo.numbers[0]}</span>
        </div>
      </div>

      <div className={styles.brandAddressWrapper}>
        <span className={styles.brandAddressTitle}>
          {t(Strings.branchAddress)}
        </span>
        <span className={styles.brandAddress}>
          {" "}
          <TextOverflowToolTip text={brandInfo.address} maxLength={50} />
        </span>
      </div>
      <div className={styles.brandAddressWrapper}>
        <span className={styles.brandAddressTitle}>
          {t(Strings.branchAppVersion)}
        </span>
        <span className={styles.brandAddress}>
          {brandInfo.app_version || t(Strings.unknown)}
        </span>
      </div>
      <div className={styles.brandAddressWrapper}>
        <span className={styles.brandAddressTitle}>
          {t(Strings.branchLocationOnMap)}
        </span>
        <div
          style={{ position: "relative", width: "32.2rem", height: "18.4rem" }}
        >
          <Map
            // google={window.google}
            // style={{ position: "absolute" }}
            google={props.google}
            center={{ lat: brandInfo.lat, lng: brandInfo.lng }}
            zoom={14}
          >
            <Marker position={{ lat: brandInfo.lat, lng: brandInfo.lng }} />
          </Map>
        </div>
      </div>
      {orderType === OrderType.delivery && (
        <div>
          <div className={styles.separator} />
          <DeliveryInformation
            delivery_zones={brandInfo.delivery_zones}
            currency={currency}
            delivery_fees={delivery_fees}
            delivery_time={delivery_time}
          />
        </div>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
})(hot(module)(BrandInfo));
