export default {
  email: "البريد الاكتروني",
  enter_email: "أدخل بريدك الإلكتروني",
  invalid_email: "بريد إلكتروني خاطئ",
  password: "كلمه السر",
  enter_password: "ادخل كلمه السر ",
  invalid_password: "كلمه السر خاطئة",
  log_in: "سجل الدخول",
  logging_in: "تسجيل الدخول...",
  quick_facts_about: "معلومات سريعة عن {{ar_store_name}}",
  todays_progress: "يحدث الآن",
  transactions: "عمليات",
  analyze_transactions: "تحليل العمليات",
  analyze_redemptions: "تحليل الهدايا",
  from_yesterday: "عن قيمة الأمس",
  live_updates: "تحديثات مباشرة",
  new_customers: "عملاء جدد",
  returning_customers: "عملاء عائدون",
  customer_segment: "فئات العملاء",
  time: "وقت: {{time}}",
  branch: "فرع",
  customer_type: "نوع العميل",
  customers: "عملاء",
  customers_chart_tooltip: "عملاء: {{customers}}",
  ordersAndTransactions: "طلبات و عمليات",
  orders: "طلبات",
  redemptions: "هدايا",
  sales: "مبيعات",
  ordersTitle: "الطلبات",
  feedbackTitle: "اراء العملاء",
  adsManagerTitle: "مدير الحملات",
  insights_card_title: "تقارير",
  invoices: "الفواتير: {{invoices}}",
  orders_chart_tooltip: "طلبات و عمليات : {{orders}}",
  redemptions_chart_tooltip: "هدايا: ${{redemptions}}",
  see_more: "شاهد المزيد",
  less: "شاهد اقل",
  new: "{{percentage}} :جديد",
  returning: "{{percentage}} :عائدون",
  home: "الرئيسية",
  good_morning: "صباح الخير ، {{user_name}}",
  good_afternoon: "مساء الخير ، {{user_name}}",
  good_evening: "مساء الخير ، {{user_name}}",
  overview: "نظرة شاملة",
  customer_visits: "زيارات العملاء",
  track_your_orders_progress: "تابع تقدم طلباتك",
  manage_customer_satisfaction: "إدارة رضا العملاء",
  increase_your_customer_base: "زيادة قاعدة عملائك",
  analyze_your_restaurant_data: "حلل بيانات مطعمك",
  avg_ticket_size: "متوسط سعر عملية الشراء",
  your_data_will_appear_here: "سوف تظهر البيانات مع مرور وقت اكبر",
  most_of_your_customers_are: "معظم عملائك هم",
  male_customer: "ذكور",
  female_customer: "إناث",
  analyze_customers: "تحليل العملاء",
  view_order_details: "مشاهدة تفاصيل الطلب",
  all_customers: "جميع العملاء",
  app_customers: "عملاء التطبيق",
  non_app_customers: "عملاء لم يستخدموا التطبيق",
  search_branch_name: "بحث من خلال اسم الفرع ..",
  apply: "تطبيق",
  reset: "إعادة تعيين",
  custom_time: "وقت محدّد",
  all_time: "كل الأوقات",
  today: "اليوم",
  yesterday: "أمس",
  quarter_to_date: "منذ بداية الربع المالي الحالي إلى الآن",
  last_year: " العام الماضي",
  select_all: "اختر الكل",
  offline_transaction: "عمليات الولاء",
  offline_redemption: "عملية استبدال من الفرع",
  offline_visit: "زيارة الفرع",
  offline: "الطلب من الفرع",
  online: "اونلاين",
  delivery: "خدمة التوصيل",
  pickup: "استلام من الفرع",
  total: "المجموع: {{total_price}} {{currency}}",
  customers_tab: "{{label}} {{percentage}}",
  sales_tab: "{{label}} {{percentage}}",
  redemptions_tab: "{{label}} {{percentage}}",
  orders_tab: "{{label}} {{percentage}}",
  males: "ذكور",
  females: "إناث",
  age_group: "في الفئة العمرية ({{age_group}})",
  returning_customers_count: "عملاء عائدون",
  new_customers_count: "عملاء جدد",
  male_customers: "ذكور",
  female_customers: "إناث",
  reviewed: "تقييمات {{order_type}}",
  redeemed: "تم تبديل هدية {{order_type}}",
  offline_order: "الطلب من الفرع",
  delivery_order: "خدمة التوصيل",
  pickup_order: "طلب استلام من الفرع",
  ordered_through_web_portal: "تم الطلب عن طريق الويب بورتال",
  ordered_through_koinz_application: "تم الطلب عن طريق تطبيق كوينز",
  ordered_through_visiting_the_restaurants: "تم الطلب عن طريق زيارة الفرع",
  reviews: "التقييمات",
  see_live_updates_about_your_customers_interactions_here:
    "شاهد تحديثات مباشرة حول تفاعلات عملائك هنا",
  you_have_a_new_transaction: "لديك عملية جديدة",
  show_more: "شاهد المزيد",
  show_less: "شاهد اقل",
  more_items: "{{count}} المزيد من العناصر",
  all: "الكل",
  here_is_store_name_process: "إليك أرقام {{store_name}}...",
  store_performance: "آداء {{store_name}}",
  last_date: "اخر {{count}} {{unit}}",
  day: "يوم",
  days: "ايام",
  month: "شهر",
  months: "شهور",
  year: "سنة",
  years: "سنوات",
  years_range: "Years Range",
  cancel: "إلغاء",
  more_preset_ranges: "المزيد من النطاقات",
  customer_percentage_from_last_30_days:
    "{{customer_percentage}} عملاء لآخر 30 يومًا",
  order_percentage_from_last_30_days:
    "{{order_percentage}} طلبات لآخر 30 يومًا",
  fulfillment_percentage_from_last_30_days:
    "{{fulfillment_percentage}} اخر 30 يوم",
  redemption_percentage_from_last_30_days:
    "{{redemption_percentage}} استبدال هدايا لآخر 30 يومًا",
  sales_percentage_from_last_30_days:
    "{{sales_percentage}} مبيعات لآخر 30 يومًا",
  avg_ticket_size_with_price: "{{price}} :متوسط سعر عملية الشراء",
  percentage_from_last_30_days: "{{percentage}} اخر 30 يوم",
  online_orders: "الطلبات الاونلاين",
  offline_orders: "الطلبات من الفرع",
  all_orders: "كل الطلبات",
  pickup_orders: "طلبات الاستلام من الفرع",
  delivery_orders: "طلبات التوصيل",
  web_portal_orders: "ويب بورتال",
  offline_visits: "زيارة الفرع",
  redemption_type: "نوع عملية استبدال الهدية",
  order_type: "نوع الطلب",
  transaction_type: " نوع العملية",
  customer_feedback: "ملاحظات العملاء",
  overall_rating: "التقييم عام",
  unhappy_customers: "العملاء غير الراضين",
  recent_reviews: "اخر التقييمات",
  branches_performance: "اداء الفروع",
  branch_name: "اسم الفرع",
  fulfillment_rate: "معدل الاكمال",
  acceptance_rate: "معدل القبول",
  time_to_accept: "متوسط وقت القبول",
  status: "الحاله",
  top_branches: "اعلي الفروع",
  lowest_branches: "أقل الفروع",
  on_track: "علي المسار",
  off_track: "خارج المسار",
  analyze_branches: "تحليل الفروع",
  analyze_feedback: "تحليل التقييم",
  there_is_no_branches_available: "لا يوجد أي فروع متاحة",
  this_year: "هذا العام (يناير - ديسمبر)",
  time_range_more_than_a_year_error: "هذا النطاق غير صحيح (أكبر من 365 يوم)",
  seconds: "{{seconds}} ثوان",
  second: "ثانية واحدة",
  minute: "دقيقة واحدة",
  minutes: "{{minutes}} دقائق",
  hour: "ساعة واحدة",
  twoHours: "ساعتان",
  hours: "ساعات",
  one_day: "يوم واحد",
  two_day: "يومان",
  two_seconds: "ثانيتان",
  two_minutes: "دقيقتان",
  days_duration: "{{days}} أيام",
  one_month: "شهر واحد",
  two_month: "شهران",
  months_duration: "{{months}} أشهر",
  year_duration: "عام واحد",
  two_years_duration: "عامان",
  years_duration: "{{years}} أعوام",
  solveIssue: "حل الشكوى",
  netSales: "صافي المبيعات",
};
