import cr from "./convertingRatio";
export default {
  back: "Back",
  blockedUsersTitle: "Blocked Users ({{count}})",
  fullName: "Full Name",
  phoneNumber: "Phone number",
  addBlockedUser: "New blocked user",
  blockListEmpty: "There are no blocked users!",
  addBlockedUserTitle: "New blocked user",
  addBlockedUserButton: "Add customer",
  confirmBlockUserTitle: "Block Customer From Reciving Points",
  confirmBlockUserSubTitlePart1: "The customer won’t be able to",
  confirmBlockUserSubTitlePart2: " receive Koinz points & redeem ",
  confirmBlockUserSubTitlePart3: " gifts ",
  confirmBlockUserSubTitlePart4: " and all of his ",
  confirmBlockUserSubTitlePart5: "operations will be deleted",
  customerNoName: "No-Name",
  confirmBlockUserButton: "Block Customer",
  cancelBlockUser: "Cancel",
  confirmUnBlockUserTitle: "Unblock ",
  confirmUnBlockUserSubTitle: "Are you sure that you want to unblock {{name}}?",
  confirmUnBlockUserButton: "Unblock",
  cancelUnBlockUser: "Cancel",
  unBlockUserButton: "Unblock",
  somethingWentWrong: cr.somethingWentWrong,
  errorPhoneInvalid: "Phone Number is invalid",
  errorPhoneExists: "This phone number is already blocked!",
  userBlockedSuccess: "Phone number has been blocked successfully!",
};
