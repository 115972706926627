import React, { useRef, useState } from "react";
// styles
import styles from "./styles.module.sass";
import { Interpolate } from "react-i18next";
// components
import {
  OrdersChart,
  CustomersChart,
  SalesChart,
  FeatherArrowIcon,
  RedemptionsChart,
} from "../index";
// antd
import { Tabs } from "antd";
// Queries
import { useGetTotalOrders } from "../../../../../../Queries/Manager";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import { useIsVisible } from "react-is-visible";

const { TabPane } = Tabs;
const OverallProgress = () => {
  const [type, setType] = useState<
    "customers" | "orders" | "redemptions" | "sales"
  >("customers");
  const overAllProgressRef = useRef(null);
  const overallProgressVisible = useIsVisible(overAllProgressRef);
  const handleTabsChange = (key) => {
    if (key === "2") {
      setType("orders");
    } else if (key === "3") {
      setType("redemptions");
    } else if (key === "4") {
      setType("sales");
    } else {
      setType("customers");
    }
  };
  const {
    data: orders,
    refetch: ordersRefetch,
    status: ordersStatus,
  } = useGetTotalOrders("orders");
  const {
    data: customers,
    refetch: customersRefetch,
    status: customersStatus,
  } = useGetTotalOrders("customers");
  const {
    data: redemptions,
    refetch: redemptionsRefetch,
    status: redemptionStatus,
  } = useGetTotalOrders("redemptions");
  const {
    data: sales,
    refetch: salesRefetch,
    status: salesStatus,
  } = useGetTotalOrders("sales");
  React.useEffect(() => {
    if (redemptionStatus === "idle" && overallProgressVisible && !redemptions) {
      redemptionsRefetch();
    }
    if (customersStatus === "idle" && overallProgressVisible && !customers) {
      customersRefetch();
    }
    if (salesStatus === "idle" && overallProgressVisible && !sales) {
      salesRefetch();
    }
    if (ordersStatus === "idle" && overallProgressVisible && !orders) {
      ordersRefetch();
    }
  }, [overallProgressVisible]);

  const { t } = useTranslation("home");
  return (
    <div className={styles.wrapper} ref={overAllProgressRef}>
      <Tabs
        onChange={handleTabsChange}
        className={styles.tabs}
        tabBarStyle={{
          height: "56px",
          backgroundColor: "#FFFFF",
          color: "#8E8E93",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TabPane
          tab={
            <div className={styles.tabWrapper}>
              <span>
                <Interpolate
                  i18nKey={"home:customers_tab"}
                  percentage={
                    <React.Fragment>
                      {typeof customers?.change !== "undefined" && (
                        <span style={{ margin: "0 .3rem" }}>
                          ({customers?.change}%)
                        </span>
                      )}
                    </React.Fragment>
                  }
                  label={t(Strings.customers)}
                />
              </span>
              {typeof customers?.change !== "undefined" && (
                <React.Fragment>
                  {Number(customers?.change) > 0 ? (
                    <div className={styles.iconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "customers" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  ) : (
                    <div className={styles.rotateIconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "customers" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          }
          key="1"
        >
          <div className={styles.chartWrapper}>
            <CustomersChart totalOrdersData={customers} />
          </div>
        </TabPane>

        <TabPane
          tab={
            <div className={styles.tabWrapper}>
              <span>
                <Interpolate
                  i18nKey={"home:orders_tab"}
                  percentage={
                    <React.Fragment>
                      {typeof customers?.change !== "undefined" && (
                        <span style={{ margin: "0 .3rem" }}>
                          ({orders?.change}%)
                        </span>
                      )}
                    </React.Fragment>
                  }
                  label={t(Strings.ordersAndTransactions)}
                />
              </span>
              {typeof orders?.change !== "undefined" && (
                <React.Fragment>
                  {Number(orders?.change) > 0 ? (
                    <div className={styles.iconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "orders" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  ) : (
                    <div className={styles.rotateIconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "orders" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          }
          key="2"
        >
          <div className={styles.chartWrapper}>
            <OrdersChart totalOrdersData={orders} />
          </div>
        </TabPane>
        <TabPane
          tab={
            <div className={styles.tabWrapper}>
              <span>
                <Interpolate
                  i18nKey={"home:redemptions_tab"}
                  percentage={
                    <React.Fragment>
                      {typeof customers?.change !== "undefined" && (
                        <span style={{ margin: "0 .3rem" }}>
                          ({redemptions?.change}%)
                        </span>
                      )}
                    </React.Fragment>
                  }
                  label={t(Strings.redemptions)}
                />
              </span>
              {typeof redemptions?.change !== "undefined" && (
                <React.Fragment>
                  {Number(redemptions?.change) > 0 ? (
                    <div className={styles.iconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "redemptions" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  ) : (
                    <div className={styles.rotateIconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "redemptions" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          }
          key="3"
        >
          <div className={styles.chartWrapper}>
            <RedemptionsChart totalOrdersData={redemptions} />
          </div>
        </TabPane>
        <TabPane
          tab={
            <div className={styles.tabWrapper}>
              <span>
                <Interpolate
                  i18nKey={"home:sales_tab"}
                  percentage={
                    <React.Fragment>
                      {typeof customers?.change !== "undefined" && (
                        <span style={{ margin: "0 .3rem" }}>
                          ({sales?.change}%)
                        </span>
                      )}
                    </React.Fragment>
                  }
                  label={t(Strings.netSales)}
                />
              </span>
              {typeof sales?.change !== "undefined" && (
                <React.Fragment>
                  {Number(sales?.change) > 0 ? (
                    <div className={styles.iconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "sales" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  ) : (
                    <div className={styles.rotateIconWrapper}>
                      <FeatherArrowIcon
                        width={"12"}
                        height={"12"}
                        stroke={type === "sales" ? "#ffa351" : "#8e8e93"}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          }
          key="4"
        >
          <div className={styles.chartWrapper}>
            <SalesChart totalOrdersData={sales} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OverallProgress;
