import { union } from "ts-action";
import { put, takeLatest, select } from "redux-saga/effects";
import { defaultRetry } from "../../../WizardComponents/Branches/AddBranchForm/redux/getSingleBranchSaga";
import {
  setRoutingMethod,
  setRoutingMethodFailure,
  setRoutingMethodSuccess,
} from "../actions/min-order-value";
import { addCallCenter } from "../../../../axios/addCallCenter";
import { selectToken } from "../../../../redux-store/selectors";
import { setRoutingMethodAPI } from "../../axios/setRoutingMethodAPI";

const actionType = union({ setRotingMethod: setRoutingMethod } as any);

function* setRoutingMethodSaga(action: any) {
  try {
    const isCallCenterRouting = action.payload.is_call_center;
    const token = yield select(selectToken);
    if (isCallCenterRouting) {
      yield defaultRetry(addCallCenter, token);
    }
    yield defaultRetry(setRoutingMethodAPI, token, isCallCenterRouting);
    yield put(setRoutingMethodSuccess(action.payload));
  } catch {
    yield put(setRoutingMethodFailure());
  }
}

export function* watchSetRoutingMethodSaga() {
  yield takeLatest(setRoutingMethod.type, setRoutingMethodSaga);
}
