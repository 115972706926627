import sl from "./socialLinks";

export default {
  name: "الاسم",
  phone: "رقم الهاتف",
  email: "الايميل",
  role: "المنصب",
  password: "الرقم السري",
  signUpTitle: "الاشتراك",
  haveAnAccount: "هل لديك حساب؟",
  submit: sl.submit,
  logInHere: "قم بتسجيل الدخول من هنا",
};
