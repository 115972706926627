export default {
  title: "title",
  allowReceiptIdTitle: "allowReceiptIdTitle",
  allowReceiptIdDescription: "allowReceiptIdDescription",
  allowPointsExpirationTitle: "allowPointsExpirationTitle",
  allowPointsExpirationDescription: "allowPointsExpirationDescription",
  allowHideMenuItemsTitle: "allowHideMenuItemsTitle",
  allowHideMenuItemsDescription: "allowHideMenuItemsDescription",
  blockedUsers: "blockedUsers",
  blockedUsersDescription: "blockedUsersDescription",
  customerExpirationPeriodTitle: "customerExpirationPeriodTitle",
  days: "days",
  months: "months",
  years: "years",
  confirm: "confirm",
  cancel: "cancel",
  uninstallPointOfSale: "uninstallPointOfSale",
  ordering: "ordering",
  loyalty: "loyalty",
  "Uninstall POS Loyalty": "Uninstall POS Loyalty",
  "Uninstall POS Ordering": "Uninstall POS Ordering",
  "Do you want to uninstall loyalty pos integration":
    "Do you want to uninstall loyalty pos integration",
  doYouWantToUninstallOrderingPosIntegration: "doYouWantToUninstallOrderingPosIntegration",
};
