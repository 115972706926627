export default {
  enterPersonalInfo: "Enter your personal information",
  namePlaceholder: "Your Name",
  jobPlaceholder: "Your Job Role",
  passwordPlaceholder: "Password",
  signUp: "Sign up",
  registerSuccess: "Registration successful",
  owner: "Owner",
  it_manager: "IT Manager",
  marketing_manager: "Marketing Manager",
  operations_manager: "Operations Manager",
  other: "Other",
};
