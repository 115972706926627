import React, { useState, useEffect, useRef } from "react";
// styles
import styles from "./styles.module.sass";
// ant
import { Collapse } from "antd";
import {
  DownOutlined as DownIcon,
  UpOutlined as UpIcon,
} from "@ant-design/icons";
// icons
import InfoIcon from "../../../../../../assets/group-2.svg";
import ArrowIcon from "../../../../../../assets/feather _ arrow-up.svg";
import { useSelect } from "../../../../../../hooks/useSelect";
import BarChart from "../BarGraph";
import * as barChartConfigs from "../BarGraph/barChart-configs";
// queries
import {
  useGetCustomersVisitsOverview,
  useGetCustomersVisitsChart,
} from "../../../../../../Queries/Manager";
// components
import { TypeFilter, YearsAndMonthsTimeFilter } from "../index";
// helpers
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
import i18n from "../../../../../../i18n/i18n";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../Components/WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import { useIsVisible } from "react-is-visible";
import { monthsNames, arMonthNames } from "../../consts";

type dataPointType = {
  value: number;
  date: string;
  label: string;
};

type chartReqConfigsType = {
  store_id: string | null;
  from: number;
  to: number;
  unit: "month" | "year";
  branch_id?: string;
};

const CustomersVisits: React.FC = () => {
  const customersVisitsRef = useRef<any>(null);
  const isVisible = useIsVisible(customersVisitsRef);
  const { Panel } = Collapse;
  const [expand, setExpand] = useState(false);

  const [currentTimeFilterUnit, setCurrentTimeFilterUnit] = useState<
    "month" | "year"
  >("month");
  const [noData, setNoData] = useState(false);
  const [isBrandOlderThan60D, setIsBrandOlderThan60D] = useState(false);
  const { t } = useTranslation("home");
  const [chartValues, setChartValues] = useState<{
    datasets: {
      label: string;
      data: dataPointType[];
      backgroundColor: string;
    }[];
    labels: string[];
  }>({
    datasets: [],
    labels: [],
  });
  // overview req configs
  const [overviewRequestConfigs, setOverviewRequestConfigs] = useState({
    from: 0,
    to: 0,
    unit: "day",
  });
  // chart req configs
  const [chartRequestConfigs, setChartRequestConfigs] =
    useState<chartReqConfigsType>({
      store_id: null,
      from: 0,
      to: 0,
      unit: "month",
      branch_id: undefined,
    });

  const { allBranches } = useSelect((state) => state.branchesReducer);

  const {
    store: { created_at, id: store_id },
    token,
  } = useSelect((state) => {
    return {
      ...state.storeReducer,
      ...state.authReducer,
    };
  });

  // queries
  const {
    data: cVisitsOverviewData,
    refetch: cVisitsOverviewRefetch,
    status: cVisitsOverviewStatus,
  } = useGetCustomersVisitsOverview(token, overviewRequestConfigs);

  const {
    data: cVisitsChartData,
    isLoading: cVisitsChartIsLoading,
    refetch: cVisitsChartRefetch,
    status: cVisitsChartStatus,
  } = useGetCustomersVisitsChart(token, chartRequestConfigs);

  useEffect(() => {
    if (isVisible && !cVisitsChartData && cVisitsChartStatus === "idle") {
      cVisitsChartRefetch();
    }
    if (isVisible && !cVisitsOverviewData && cVisitsOverviewStatus === "idle") {
      cVisitsOverviewRefetch();
    }
  }, [isVisible]);
  // TODO: Remove duplications into utils
  // setting overview req configs
  useEffect(() => {
    if (typeof created_at === "number") {
      const now = new Date();
      const nowTimestamp = now.getTime();
      const difference = nowTimestamp - created_at;
      const timestampOfADay = 60 * 60 * 24 * 1000;

      const daysFromStoreCreation = difference / timestampOfADay;

      if (daysFromStoreCreation < 1) {
        setNoData(true);
      } else {
        setOverviewRequestConfigs((prevValue) => ({
          ...prevValue,
          from:
            daysFromStoreCreation <= 30
              ? created_at
              : new Date(now.getTime() - 30 * timestampOfADay).getTime(),
          to: nowTimestamp,
          unit: "day",
        }));

        setIsBrandOlderThan60D(daysFromStoreCreation >= 60);
      }
    }
  }, [created_at]);

  // setting chart req configs
  useEffect(() => {
    if (store_id && typeof created_at === "number") {
      const now = new Date();
      const nowTimestamp = now.getTime();
      const difference = nowTimestamp - created_at;
      const timestampOfADay = 60 * 60 * 24 * 1000;

      const daysFromStoreCreation = difference / timestampOfADay;
      if (daysFromStoreCreation > 1) {
        setChartRequestConfigs((prevState: chartReqConfigsType) => ({
          ...prevState,
          store_id,
          from: new Date(now.getTime() - 180 * timestampOfADay).getTime(),
          to: nowTimestamp,
          unit: "month",
          branch_id: undefined,
        }));
      }
    }
  }, [store_id, created_at]);

  // handling chart data
  useEffect(() => {
    if (!cVisitsChartIsLoading && cVisitsChartData) {
      setChartValues((prevValue) => ({
        ...prevValue,
        datasets: mapChartData(cVisitsChartData, currentTimeFilterUnit),
      }));
    }
  }, [cVisitsChartData, cVisitsChartIsLoading]);

  // mapping data with labels
  const mapChartData = (data, currentTimeFilterUnit: "year" | "month") => {
    const newCustomersCountMap = {};

    const returningCustomersCountMap = {};

    const monthesLabels = i18n.language.includes("ar")
      ? arMonthNames
      : monthsNames["normal"];

    data.forEach((element) => {
      const monthLabel = element.month ? monthesLabels[element.month - 1] : 0;

      const yearLabel = element.year;

      const activeSelector =
        currentTimeFilterUnit === "year" ? yearLabel : monthLabel;

      const formattedDate =
        currentTimeFilterUnit === "year"
          ? `${yearLabel}`
          : `${monthLabel} ${yearLabel}`;
      // handling new users counts
      if (newCustomersCountMap[activeSelector]) {
        newCustomersCountMap[activeSelector] = {
          value:
            newCustomersCountMap[activeSelector].value +
            element.new_customers_count,
          date: formattedDate,
          label: activeSelector,
        };
      } else {
        newCustomersCountMap[activeSelector] = {
          value: element.new_customers_count,
          date: formattedDate,
          label: activeSelector,
        };
      }

      // handling returning users counts
      if (returningCustomersCountMap[activeSelector]) {
        returningCustomersCountMap[activeSelector] = {
          value:
            returningCustomersCountMap[activeSelector].value +
            element.returning_customers_count,
          date: formattedDate,
          label: activeSelector,
        };
      } else {
        returningCustomersCountMap[activeSelector] = {
          value: element.returning_customers_count,
          date: formattedDate,
          label: activeSelector,
        };
      }
    });

    const returningCustomersData =
      currentTimeFilterUnit === "month"
        ? Object.values(returningCustomersCountMap)
        : Object.values(returningCustomersCountMap);

    const newCustomersData =
      currentTimeFilterUnit === "month"
        ? Object.values(newCustomersCountMap)
        : Object.values(newCustomersCountMap);

    return [
      {
        label: t(Strings.returning_customers_count),
        data: returningCustomersData as dataPointType[],
        backgroundColor: "#FFA351",
      },
      {
        label: t(Strings.new_customers_count),
        data: newCustomersData as dataPointType[],
        backgroundColor: "#FFD1A8",
      },
    ];
  };

  const handleBranchFilterChange = (value) => {
    if (value === "all") {
      setChartRequestConfigs({
        ...chartRequestConfigs,
        branch_id: undefined,
      });
    } else {
      setChartRequestConfigs({
        ...chartRequestConfigs,
        branch_id: value,
      });
    }
  };

  const handleTimeFilterChange = (
    value: {
      from: number;
      to: number;
      unit: "day" | "hour";
    },
    filterUnit: "month" | "year"
  ) => {
    setCurrentTimeFilterUnit(filterUnit);
    setChartRequestConfigs((prevValue: any) => ({
      ...prevValue,
      from: value.from,
      to: value.to,
      unit: value.unit,
    }));
  };

  const HeaderContent = (
    <div className={styles.headerWrapper} onClick={() => setExpand(!expand)}>
      {/* title */}
      <div className={styles.headerTitleWrapper}>
        <div className={styles.title}>
          {t(Strings.customer_visits)}
          <div className={styles.infoIcon}>
            <img className={styles.icon} src={InfoIcon} />
          </div>
        </div>
        <button className={styles.seeMoreButton}>
          {expand ? t(Strings.less) : t(Strings.see_more)}
          <span>
            {expand ? (
              <img
                src={
                  require("../../../../../../assets/feather _ chevron-down.svg")
                    .default
                }
                width={16}
                height={16}
                style={{ transform: "rotate(180deg)" }}
              />
            ) : (
              <img
                src={
                  require("../../../../../../assets/feather _ chevron-down.svg")
                    .default
                }
                width={16}
                height={16}
              />
            )}
          </span>
        </button>
      </div>
      {/* no data wrapper */}
      {noData && (
        <div className={styles.noDataWrapper}>
          <img src={InfoIcon} alt="" className={styles.noDataInfoIcon} />
          <span className={styles.noDataDesc}>
            {t(Strings.your_data_will_appear_here)}
          </span>
        </div>
      )}

      {/* numbers */}
      {!noData && (
        <div className={styles.numbersWrapper}>
          {/* returning customers */}
          <div className={styles.customersCountWrapper}>
            {/* legend */}
            <div className={styles.legendWrapper}>
              <div
                className={styles.legend}
                style={{ backgroundColor: "#FFA351" }}
              ></div>
              <span className={styles.legendText}>
                {t(Strings.returning_customers)}
              </span>
            </div>
            {/* Customers Count */}

            <span className={styles.customersCount}>
              {cVisitsOverviewData
                ? cVisitsOverviewData.returning_customers_count
                : 0}
            </span>

            {/* 30 days comparasion */}
            {isBrandOlderThan60D &&
              Boolean(cVisitsOverviewData?.returning_customers_change) &&
              +cVisitsOverviewData?.returning_customers_change > 0 && (
                <span className={styles.last30DaysCompared}>
                  {t(Strings.percentage_from_last_30_days, {
                    percentage: `${
                      cVisitsOverviewData &&
                      cVisitsOverviewData.returning_customers_change > 0
                        ? "+"
                        : ""
                    }${
                      cVisitsOverviewData
                        ? +cVisitsOverviewData.returning_customers_change
                        : 0
                    }%`,
                  })}
                  <img
                    className={styles.arrowIcon}
                    style={
                      cVisitsOverviewData &&
                      +cVisitsOverviewData.new_customers_change < 0
                        ? {
                            transform: "rotate(180deg)",
                          }
                        : {}
                    }
                    src={ArrowIcon}
                  />
                </span>
              )}
          </div>

          {/* new customers */}
          <div
            className={styles.customersCountWrapper}
            style={{ marginLeft: "50px" }}
          >
            {/* legend */}
            <div className={styles.legendWrapper}>
              <div
                className={styles.legend}
                style={{ backgroundColor: "#FFD1A8" }}
              ></div>
              <span className={styles.legendText}>
                {t(Strings.new_customers)}
              </span>
            </div>
            {/* Customers Count */}
            <span className={styles.customersCount}>
              {" "}
              {cVisitsOverviewData
                ? cVisitsOverviewData.new_customers_count
                : 0}
            </span>
            {/* 30 days comparasion */}
            {isBrandOlderThan60D &&
              Boolean(cVisitsOverviewData?.new_customers_change) &&
              +cVisitsOverviewData?.new_customers_change > 0 && (
                <span className={styles.last30DaysCompared}>
                  {t(Strings.percentage_from_last_30_days, {
                    percentage: `${
                      cVisitsOverviewData &&
                      +cVisitsOverviewData.new_customers_change > 0
                        ? "+"
                        : ""
                    }${
                      cVisitsOverviewData
                        ? +cVisitsOverviewData.new_customers_change
                        : 0
                    }%`,
                  })}
                  <img
                    className={styles.arrowIcon}
                    style={
                      cVisitsOverviewData &&
                      +cVisitsOverviewData.new_customers_change < 0
                        ? {
                            transform: "rotate(180deg)",
                          }
                        : {}
                    }
                    src={ArrowIcon}
                  />
                </span>
              )}
          </div>
        </div>
      )}
      {/* Bar */}
      {!noData && (
        <div className={styles.percentBar}>
          <div
            className={styles.innerPercentBar}
            style={{
              width: cVisitsOverviewData
                ? `${
                    (cVisitsOverviewData.returning_customers_count /
                      (cVisitsOverviewData.new_customers_count +
                        cVisitsOverviewData.returning_customers_count)) *
                    100
                  }%`
                : "0%",
            }}
          ></div>
        </div>
      )}
    </div>
  );

  return (
    <div
      ref={customersVisitsRef}
      className={`${styles.main} ${useRtlClass(styles)}`}
    >
      <Collapse defaultActiveKey={[]}>
        <Panel showArrow={false} key={"1"} header={HeaderContent}>
          <div className={styles.panelContentWrapper}>
            {/* filters */}
            <div className={styles.filtersWrapper}>
              {/* branch filter */}
              {/* <div className={styles.branchFilterWrapper}> */}
              <TypeFilter
                onChange={handleBranchFilterChange}
                typesArr={allBranches}
                showSearch={true}
                prefixText={t(Strings.branch)}
                includeAllOption
                width={"200px"}
              />
              {/* </div> */}
              {/* time filter */}
              <YearsAndMonthsTimeFilter
                showArrow
                onChange={handleTimeFilterChange}
              />
            </div>
            <div className={styles.barChartWrapper}>
              <BarChart
                barChartConfigs={barChartConfigs}
                values={chartValues}
                height={180}
                width={420}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default CustomersVisits;
