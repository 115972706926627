import { call, put, select, takeLatest } from "redux-saga/effects";
import { getCashiersAction } from "../../constants";
import { selectToken } from "../selectors";
import { getCashiersSuccess, getCashiersFailure } from "../actions";
import { getCashiers } from "../../axios/getCashiers";

function* getCashiersSaga() {
  try {
    const token: string = yield select(selectToken);
    const res = yield call(getCashiers, token);
    yield put(getCashiersSuccess(res.data));
  } catch (e) {
    yield put(getCashiersFailure(e));
  }
}

export function* watchGetCashiersSaga() {
  yield takeLatest(getCashiersAction.requested, getCashiersSaga);
}
