import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/modifierGroupsAction";
import { createOrderingModifierGroup } from "../../axios/createOrderingMenuModifiersGroups";
import { selectToken } from "../../../../redux-store/selectors";
import { createOrderingModifiersGroupsAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

function* createModifierGroupSaga(payload) {
  try {
    const token = yield select(selectToken);
    const createdModifier = yield call(
      createOrderingModifierGroup,
      token,
      payload
    );
    return createdModifier.data;
  } catch (e) {
    customNotify.error(
      translateMenuError(menuErrorMessages.createModifierGroup)
    );
    throw e;
  }
}

export function* watchCreateModifierGroupSaga() {
  yield takeLatest(
    createOrderingModifiersGroupsAction.requested,
    handleOptimism(createModifierGroupSaga, {
      success: actions.createOrderingSuggestedModifiersGroupSuccess as any,
      failure: actions.createOrderingSuggestedModifiersGroupFailure as any,
    })
  );
}
