import React from "react";
import Modal from "@material-ui/core/Modal";
import styles from "./styles.module.sass";
import { ClipLoader } from "react-spinners";
import { useRtlClass } from "../../../../lib";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import * as Strings from "../../../../i18n/strings/MultipleSelect";
interface IProps {
  open: boolean;
  onClose: () => void;
  className?: string;
  description?: string;
  title: string;
  warningIcon?: any;
  confirmText?: string;
  onConfirm: () => void;
  loading: boolean;
}
export const ConfirmationPopup: React.FC<IProps> = ({
  open,
  onClose,
  className,
  onConfirm,
  title,
  description,
  warningIcon,
  confirmText,
  loading,
}) => {
  const rtl = useRtlClass(styles);
  const { t } = useTranslation("MultipleSelect");
  return (
    <Modal className={`${styles.main} ${rtl}`} open={open} onClose={onClose}>
      <div className={`${className} ${styles.wrapper}`}>
        <span className={styles.title}>{title}</span>
        <div className={styles.imageWrapper}>
          {warningIcon && (
            <img className={styles.warningImage} src={warningIcon} />
          )}
        </div>
        {description && (
          <span className={styles.description}>{description}</span>
        )}
        <div className={styles.buttonsWrapper}>
          <button
            disabled={loading}
            onClick={onClose}
            className={styles.cancel}
            style={
              loading
                ? {
                    opacity: 0.6,
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            {t(Strings.cancel)}
          </button>
          <button
            disabled={loading}
            className={styles.confirm}
            onClick={onConfirm}
          >
            {" "}
            {loading ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              <React.Fragment>
                {confirmText ? confirmText : t(Strings.confirm)}
              </React.Fragment>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationPopup;
