import { useContext, useMemo } from "react";
import StoreData from "../contexts/StoreData";
import { PickupStatus } from "../types/shared";

export const useCanUseDriveThruOrdering = () => {
    const { storeData } = useContext(StoreData);
    const canUseDriveThruOrdering = useMemo(
        () => storeData?.drive_thru_status === PickupStatus.service_enabled && storeData.pos_ordering_enabled,
        [storeData?.drive_thru_status, storeData?.pos_ordering_enabled]
    )
    return canUseDriveThruOrdering;
};