import { call, put, select, takeLatest } from "redux-saga/effects";
import { ICreateMenuAction } from "../../../../types/index";
import * as actions from "../actions/menuActions";
import { createOrderingMenu as createMenu } from "../../axios/createOrderingMenu";
import { selectToken } from "../../../../redux-store/selectors";
import { createOrderingMenuAction } from "../../constants/actions";

function* createMenuSaga({ payload }: ICreateMenuAction) {
  try {
    const token = yield select(selectToken);
    const createdMenu = yield call(createMenu, token, payload);

    yield put(actions.createMenuSuccess({ ...createdMenu.data }));
  } catch (e) {
    yield put(actions.createMenuFailure(new Error()));
  }
}

export function* watchCreateMenuSaga() {
  yield takeLatest(createOrderingMenuAction.requested, createMenuSaga);
}
