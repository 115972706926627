import axios from "./axios";
import * as Requests from "./requests";

export enum OrderingPortalStatus {
  publish = "publish",
  unpublish = "unpublish",
  service_disabled = "service_disabled",
}
export interface IOrderingPortal {
  web_portal_status: OrderingPortalStatus;
}
export const getOrderingPortalApi = (token: string) => {
  return axios.get<IOrderingPortal>(Requests.orderingWebPortalStatus, {
    headers: { token },
  });
};
