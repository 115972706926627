import { call, put, select, takeLatest } from "redux-saga/effects";
import { createCompetitionAction, GIFT_FORM_FIELDS } from "../../constants";
import { selectAddMore, selectForm, selectToken } from "../selectors";
import { createCompetitionFailure, createCompetitionSuccess } from "../actions";
import { isValid, touch } from "redux-form";
import {
  ICompetitionCreate,
  ICreateCompetitionAction,
  IGift,
} from "../../types";
import { notify } from "react-notify-toast";
import { IFormState } from "../rootReducer";
import { createCompetition } from "../../axios/createCompetition";
import history from "../../helpers/history";
import Strings from "../../i18n/strings/leaderboard";
import { translateLeaderboard } from "../../i18n/i18n";
import { trackEvent } from "../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";

const getBase64FromFile = (file: File) => {
  return new Promise<string>((res) => {
    const reader = new FileReader();
    reader.onload = (data) => {
      res((data.target as any).result);
    };
    reader.readAsDataURL(file);
  });
};

export function* getGift(form) {
  const { gift_photo, gift_name, gift_description } = form.values as any;
  const formatted_gift_photo = yield call(getBase64FromFile, gift_photo[0]);
  return { gift_photo: formatted_gift_photo, gift_name, gift_description };
}

export function* areGiftFormsValid(formsLength: number) {
  let formIsValid = true;
  for (
    let currentFormIndex = 1;
    currentFormIndex <= formsLength;
    currentFormIndex++
  ) {
    const currentIsValid = yield select(isValid(`gift_${currentFormIndex}`));
    formIsValid = formIsValid && currentIsValid;
  }
  return formIsValid;
}

export function* touchGiftForms(formsLength: number) {
  for (
    let currentFormIndex = 1;
    currentFormIndex <= formsLength;
    currentFormIndex++
  ) {
    yield put(touch(`gift_${currentFormIndex}`, ...GIFT_FORM_FIELDS));
  }
}

export function* getGiftsData(formsLength: number) {
  const form: IFormState = yield select(selectForm);
  let gifts: IGift[] = [];
  for (
    let currentFormIndex = 1;
    currentFormIndex <= formsLength;
    currentFormIndex++
  ) {
    const currentGiftData = yield* getGift(form[`gift_${currentFormIndex}`]);
    gifts = [
      ...gifts,
      {
        ...currentGiftData,
        gift_rank: currentFormIndex - 1,
      },
    ];
  }
  return gifts;
}

function* createCompetitionSaga(action: ICreateCompetitionAction) {
  try {
    const token: string = yield select(selectToken);
    const addMore: string = yield select(selectAddMore);
    const formsLength = addMore ? 5 : 3;
    const shouldCreate = yield* areGiftFormsValid(formsLength);
    if (!shouldCreate) {
      yield* touchGiftForms(formsLength);
      notify.show(
        translateLeaderboard(Strings.errorEnterSpecifiedNumberOfGifts),
        "error"
      );
      throw new Error("Forms are not valid");
    } else {
      const gifts = yield* getGiftsData(formsLength);
      const data: ICompetitionCreate = {
        start_time: action.payload.start_time,
        end_time: action.payload.end_time,
        gifts,
      };
      const res = yield call(createCompetition, data, token);
      notify.show(
        translateLeaderboard(Strings.successCompetitionCreated),
        "success"
      );
      yield call(history.push as any, `/`);
      yield put(createCompetitionSuccess(res));
      trackEvent(EVENTS_FOR_TRACKING["Created a leaderboard"]);
    }
  } catch (e) {
    yield put(createCompetitionFailure(e));
    notify.show(translateLeaderboard(Strings.somethingWentWrong), "error");
  }
}

export function* watchCreateCompetitionSaga() {
  yield takeLatest(createCompetitionAction.requested, createCompetitionSaga);
}
