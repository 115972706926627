import { call, put, select, takeLatest } from "redux-saga/effects";
import { editCategoryAction } from "../../constants";
import {
  IEditCategoryAction,
  ICategory,
  OptimisticSuccess,
} from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { editCategory } from "../../axios/editCategory";
import { deleteOptimisticProps } from "./editStoreItemSaga";

function* editCategorySaga(action: IEditCategoryAction) {
  try {
    const token = yield select(selectToken);
    const editedCategoryRes = yield call(
      editCategory,
      token,
      deleteOptimisticProps(action.payload)
    );
    const category = editedCategoryRes.data as ICategory;
    yield put(
      actions.editCategorySuccess({
        ...action.payload,
        ...category,
        state: OptimisticSuccess,
      })
    );
  } catch (e) {
    yield put(actions.editCategoryFailure(action.payload));
  }
}

export function* watchEditCategorySaga() {
  yield takeLatest(editCategoryAction.requested, editCategorySaga);
}
