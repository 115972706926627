import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { updateStoreViralitySettingsApi } from "../../../axios";
import { updateStoreViralitySettingsAction } from "../../../constants";
import {
  updateStoreViralitySettingsSuccess,
  updateStoreViralitySettings,
  updateStoreViralitySettingsFailure,
} from "../../actions";
import { selectToken } from "../../selectors";

const actionTypes = union({ updateStoreViralitySettings });
function* updateStoreViralitySettingsSaga({ payload }: typeof actionTypes) {
  try {
    const token: string = yield select(selectToken);
    yield call(updateStoreViralitySettingsApi, token, { ...payload });
    yield put(updateStoreViralitySettingsSuccess({ ...payload }));
  } catch (error) {
    yield put(updateStoreViralitySettingsFailure(error as Error));
  }
}

export function* watchUpdateStoreViralitySettingsSaga() {
  yield takeLatest(
    updateStoreViralitySettingsAction.requested,
    updateStoreViralitySettingsSaga
  );
}
