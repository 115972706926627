import {
  ICountriesReducerState,
  IGetCountriesFailureAction,
  IGetCountriesSuccessAction,
} from "../../types";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { getLocalizedName } from "../../Components/Reviews/CategoriesFilters";

const COUNTRIES = "countries";

const initialState: ICountriesReducerState = Map({
  [COUNTRIES]: [],
}).toJS();

type getCountryAction = IGetCountriesSuccessAction | IGetCountriesFailureAction;
type appStateAction = getCountryAction;

export default (
  state = initialState,
  action: appStateAction
): ICountriesReducerState => {
  switch (action.type) {
    case constants.getCountriesAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(
          COUNTRIES,
          (action as IGetCountriesSuccessAction).payload.map((d) => {
            return {
              ...d,
              label: getLocalizedName(d),
              value: d.ISO_code,
            };
          })
        );
      });
    }
    default:
      return state;
  }
};
