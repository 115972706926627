import { useQuery } from "react-query";
import {
  getTransactionInsightsAPI,
  getTransactionInsightsTotalAPI,
} from "../../../../axios/Manager/Analytics/Insights/transaction";

export const useGetTransactionInsights = (token, requestConfigs) => {
  const result = useQuery(
    ["transactions-insights", requestConfigs],
    () => {
      return getTransactionInsightsAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};

export const useGetTransactionInsightsTotal = (token, requestConfigs) => {
  const result = useQuery(
    ["transactions-insights-total", requestConfigs],
    () => {
      return getTransactionInsightsTotalAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};
