import {useState, useCallback} from "react";

export const useProgressState = () => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    setSuccess: useCallback(() => {
      setSuccess(true);
      setFailure(false);
      setLoading(false);
    }, [setSuccess, setFailure, setLoading]),
    setFailure: useCallback(() => {
      setFailure(true);
      setSuccess(false);
      setLoading(false);
    }, [setSuccess, setFailure, setLoading]),
    setLoading: useCallback(() => {
      setFailure(false);
      setSuccess(false);
      setLoading(true);
    }, [setSuccess, setFailure, setLoading]),
    success,
    failure,
    loading,
  }
};
