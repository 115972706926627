import advancedSettings from "./advancedSettings";

export default {
  fraudCenter: "تتبع الاحتيال",
  branchesAndCashiers: "فروع وكاشيرات",
  brandSettings: "اعدادات المتجر",
  dashboardUsers: "مستخدمي لوحة المستخدمين",
  leaderBoard: "المسابقة",
  reviews: "التقييمات",
  reviewsResolution: "حل الشكوى",
  reviewsIssues: "الشكاوى",
  orderingPortal: "الطلبات من خلال الويب",
  brandListing: "معلومات المتجر",
  brandInfo: "الاسم ووصف المتجر",
  brandVisuals: "الصور والألوان",
  menuItems: "قائمة المنتجات",
  giftList: "قائمة الهدايا",
  pickup: "الطلب",
  pickupBranches: "الفروع",
  pickupMenu: "قائمة المنتجات",
  orderHistory: "سجل الطلبات",
  qrOrdering: "QR منصة",
  socialLinks: "التواصل الاجتماعي",
  convertingRatio: "معدل التحويل",
  support: "الدعم",
  help: "المساعدة",
  logout: "تسجيل الخروج",
  main: "الرئيسية",
  myAccount: "حسابى",
  branchesAndMain: "الفروع الاساسية",
  settings: "الإعدادات",
  myProfile: "حسابي",
  advancedSettings: "اعدادات متقدمة",
  blockedUsers: advancedSettings.blockedUsers,
  menu: "القائمة",
  home: "الرئيسية",
  feedback: "اراء العملاء",
  adsManager: "مدير الحملات",
  orders: "طلبات",
  insights: "تقارير",
  transactions: "عمليات",
  redemptions: "هدايا",
  customers: "عملاء",
  branches: "فروع",
  profile_settings: "إعدادات الملف الشخصي",
  giftingPoints: "نقاط الهدايا",
  posSettings: "إعدادات نقاط البيع",
};
