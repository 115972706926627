import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IBranch } from "../types/shared";

export const getBranches = (token, params?): AxiosReturn<IBranch[]> => {
  return axios.get(Requests.branches, {
    headers: {
      token,
    },
    params,
  });
};
