import axios from "./axios";
import * as Requests from "./requests";

export const getExtraInfoAPI = (token, id) => {
  return axios.get(Requests.extraInfoURL(id), {
    headers: {
      token,
    },
  });
};
