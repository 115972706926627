import React from "react";
const FeatherArrowIcon: React.FC<{
  width: string;
  height: string;
  stroke: string;
}> = ({ height, stroke, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 22"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 20.167V1.833M4.166 7.667L10 1.834l5.833 5.833"
      />
    </svg>
  );
};

export default FeatherArrowIcon;
