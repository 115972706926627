import * as React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { translate } from "react-i18next";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import {
  required,
  requiredColorPicker,
} from "../../../../redux-store/validators";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { InjectedTranslateProps } from "react-i18next";
import { IRootReducerState } from "../../../../redux-store/rootReducer";
import { MobileMock } from "../MobileMock";
import { RenderPhotoPicker } from "../../../FormElements/RenderPhotoPicker";
import { RenderCoverPhotoPicker } from "../../../FormElements/RenderCoverPhotoPicker";
import { RenderColorPickerField } from "../../../FormElements/RenderColorPickerField";
import { editStore, getStore } from "../../../../redux-store/actions";
import { IFormChangedData, IStore } from "../../../../types/wizard-types";
import Strings from "../../../../i18n/strings/brandVisuals";
import TStrings from "../../../../i18n/strings/translations";
import { ClipLoader } from "react-spinners";
import { trackEvent } from "../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../constants/events-for-tracking";

interface IProps {
  getStore: () => void;
  editFailed: boolean;
  saving: boolean;
  loaded: boolean;
  storeData: IStore;
  editStore: (data: IFormChangedData[]) => void;
  formValues: {
    store_image: any;
    cover_image: any;
    background_color: any;
  };
}

class BrandVisuals extends React.Component<
  InjectedFormProps & InjectedTranslateProps & IProps
> {
  public onSaveClicked = () => {
    const { valid, touch, formValues } = this.props;
    touch("store_image", "cover_image", "background_color");
    if (valid) {
      const dataToEdit = Object.keys(formValues).map((key) => {
        return { key, newValue: formValues[key] };
      });
      trackEvent(EVENTS_FOR_TRACKING["Updated the brand visuals"]);
      this.props.editStore(dataToEdit);
    }
  };

  public startAsyncValidation = () => {
    setTimeout(this.props.asyncValidate);
  };

  public componentDidMount() {
    trackEvent(EVENTS_FOR_TRACKING["Viewed the brand visuals"]);
  }

  public componentWillMount() {
    this.props.getStore();
  }

  public render() {
    const {
      saving,
      formValues: { store_image, cover_image, background_color },
      loaded,
      storeData,
      t,
    } = this.props;

    return loaded ? (
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <p className={styles.subHeader}>
            {/*Air plant farm-to-table art party lomo, kogi mixtape lyft freegan.*/}
          </p>
          <form autoComplete="off" className={styles.form}>
            <Field
              name="store_image"
              type="file"
              customLabel={1}
              focusBtn={true}
              description={t(Strings.logoDescription)}
              buttonText={t(Strings.logoUploadButtonText)}
              unsupportedFileTypeText={t(Strings.unsupportedFileType)}
              label={t(Strings.logoLabel)}
              isCroppable={true}
              component={RenderPhotoPicker as any}
              validate={[required]}
              onPick={this.startAsyncValidation}
            />
            <div className={styles.separator} />
            <Field
              name="cover_image"
              type="file"
              customLabel={1}
              description={t(Strings.coverDescription)}
              buttonText={t(Strings.coverUploadButtonText)}
              unsupportedFileTypeText={t(Strings.unsupportedFileType)}
              label={t(Strings.coverLabel)}
              component={RenderCoverPhotoPicker as any}
              validate={[required]}
              onPick={this.startAsyncValidation}
            />
            <div className={styles.separator} />
            <Field
              name="background_color"
              customLabel={1}
              note={t(Strings.brandColorNote)}
              label={t(Strings.brandColorLabel)}
              component={RenderColorPickerField as any}
              validate={[requiredColorPicker]}
            />
            <div className={styles.separator} />
          </form>
          <button
            onClick={this.onSaveClicked}
            disabled={saving}
            className={`${styles.btn}`}
          >
            {saving ? (
              <ClipLoader
                sizeUnit={"px"}
                size={20}
                color={"white"}
                loading={true}
              />
            ) : (
              t(TStrings.save, { ns: "translations" })
            )}
          </button>
        </div>
        <MobileMock
          title={t(Strings.mockUpTitle)}
          logo={store_image ? URL.createObjectURL(store_image[0]) : undefined}
          cover={cover_image ? URL.createObjectURL(cover_image[0]) : undefined}
          backGroundColor={background_color ? background_color.hex : "#FF9000"}
          brandName={storeData.name}
        />
      </div>
    ) : null;
  }
}

let BrandVisualsForm = reduxForm({
  form: "storeForm",
})(BrandVisuals as any) as any;

const selector = formValueSelector("storeForm");
BrandVisualsForm = connect((state) => {
  // can select values individually
  const store_image = selector(state, "store_image");
  const cover_image = selector(state, "cover_image");
  const background_color = selector(state, "background_color");
  return {
    formValues: {
      store_image,
      cover_image,
      background_color,
    },
  };
})(BrandVisualsForm);

const mapStateToProps = (state: IRootReducerState) => {
  const { loaded, store, editFailed, saving } = state.storeReducer;
  return {
    saving,
    loaded,
    storeData: store,
    editFailed,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getStore: () => dispatch(getStore()),
  editStore: (data: IFormChangedData[]) => dispatch(editStore(data)),
});

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate("brandVisuals")(BrandVisualsForm))
);
