import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const getCriticalNumbers = (
  token,
  filters: any = {}
): AxiosReturn<{ critical_reviews_number: number }> => {
  return axios.get(
    Requests.getCriticalNumbers,
    {
      baseURL: process.env.REACT_APP_DASHBOARD_ENDPOINT,
      headers: {
        token,
      },
      params: { ...filters, start_time: filters.start_time || 1534975200000 },
    }
  );
};
