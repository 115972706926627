import { action, payload } from "ts-action";
import { getCashiersAction } from "../../constants";
import { ICashier } from "../../axios/getCashiers";

export const getCashiers = action(getCashiersAction.requested);
export const getCashiersSuccess = action(
  getCashiersAction.fulfilled,
  payload<ICashier[]>()
);
export const getCashiersFailure = action(
  getCashiersAction.rejected,
  payload<Error>()
);
