import React from "react";
interface IProps {
  clicked: boolean;
}
const FeedbackIcon = ({ clicked }: IProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={clicked ? "#ffa351" : "#aeaeb2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.5 12.5c0 .442-.176.866-.488 1.178-.313.313-.737.489-1.179.489h-10L2.5 17.5V4.167c0-.442.176-.866.488-1.179.313-.312.737-.488 1.179-.488h11.666c.442 0 .866.176 1.179.488.312.313.488.737.488 1.179V12.5z"
      />
    </svg>
  );
};

export default FeedbackIcon;
