import { takeLatest, put, select, call } from "redux-saga/effects";
import { getWebPortalHistoryAction } from "../../constants/actions";
import { selectToken } from "../../../../redux-store/selectors";
import {
  getWebPortalHistorySuccess,
  getWebPortalHistoryFailure,
  getWebPortalHistory,
} from "../actions/ordering-portal-link-action";
import { getWebPortalHistoryApi } from "../../../../axios/getWebPortalHistory";
import { union } from "ts-action";

const actionTypes = union({ getWebPortalHistory });
function* getWebPortalHistorySaga({
  payload: { page, per_page, filters },
}: typeof actionTypes) {
  try {
    const token = yield select(selectToken);

    const res = yield call(
      getWebPortalHistoryApi,
      token,
      page,
      per_page,
      filters
    );
    const data = res.data?.orders || [];
    const totalOrders = res.data?.total_orders || 0;
    yield put(
      getWebPortalHistorySuccess({
        hasMore: !!data.length,
        orders: data,
        total_orders: totalOrders,
        filters,
        page,
      } as any)
    );
  } catch (e) {
    yield put(getWebPortalHistoryFailure(e as Error));
  }
}

export function* watchGetWebPortalHistorySaga() {
  yield takeLatest(
    getWebPortalHistoryAction.requested,
    getWebPortalHistorySaga
  );
}
