import axios from "../../axios";
import * as routes from "../../api-routes/Manager/Analytics";

export const getCustomerReceipts = (token: string, requestConfigs: any) => {
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}${routes.getCustomerReceipts}`,
    { ...requestConfigs },
    {
      headers: {
        token,
      },
    }
  );
};
