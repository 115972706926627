import React, { useMemo, useState } from "react";
// styles
import styles from "./styles.module.sass";
// assets
import UserIcon from "../../../../../../../../Components/Pickup/assets/feather _ user.svg";
import { RATING_ICONS } from "./emojis";
import i18n from "../../../../../../../../i18n/i18n";
import formatDistance from "date-fns/distance_in_words_to_now";
import ar from "date-fns/locale/ar";
import { useRtlClass } from "../../../../../../../../shopx-shared-components/hooks/useRtlClassName";
import Strings from "../../../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../../../shopx-shared-components/hooks/useTranslation";
import { useHistory } from "react-router";
import { useDeepCompareMemo } from "use-deep-compare";

export enum TransactionType {
  review = "review",
}

enum OrderType {
  delivery = "delivery",
  pickup = "pickup",
  offline = "offline",
}

export enum OrderingSource {
  web_portal = "ordering_portal",
  customer_app = "customer_app",
}

interface IProps {
  newTransaction?: boolean;
  data: any;
  customer: {
    id?: string;
    name?: string;
    image?: string;
    phone_number?: string;
    country_code?: string;
  };
  branch: { ar_name: string; en_name: string };
}
export const ReviewCard: React.FC<IProps> = ({
  data,
  customer,
  branch,
  newTransaction,
}) => {
  const { t } = useTranslation("home");
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const toggleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  const orderTypeTitle = {
    [OrderType.delivery]: `(${t(Strings.delivery_order)})`,
    [OrderType.pickup]: `(${t(Strings.pickup_order)})`,
    [OrderType.offline]: `(${t(Strings.offline_order)})`,
  };

  let orderTime;
  if (i18n.language === "ar") {
    orderTime = formatDistance(new Date(data.created_at), {
      addSuffix: true,
      locale: ar,
    });
  } else {
    orderTime = formatDistance(new Date(data.created_at), { addSuffix: true });
  }

  const userImage = useMemo(() => {
    if (customer.image) {
      return process.env.REACT_APP_ENDPOINT + customer.image;
    } else return null;
  }, [customer.image]);
  const history = useHistory();
  const shouldSolve = useDeepCompareMemo(
    () => data.rate < 3 && data.resolved !== 1,
    [data]
  );
  return (
    <div
      className={`${styles.wrapper} ${useRtlClass(styles)} ${
        newTransaction && styles.newTransactionWrapper
      }`}
    >
      {/*Time and Branch*/}
      <div className={styles.headerWrapper}>
        <span
          className={styles.headerTitle}
          data-test-id={"cy-live-updates-order-time"}
        >
          {orderTime}
        </span>
        <span
          className={styles.headerTitle}
          data-test-id={"cy-live-updates-order-branch-name"}
        >
          {i18n.language.includes("en") ? branch.en_name : branch.ar_name}
        </span>
      </div>
      {/* Customer Details */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className={styles.userWrapper}>
            <div className={styles.avatarWrapper}>
              <img
                className={
                  userImage ? styles.userIconPlaceholder : styles.userIcon
                }
                src={userImage ? userImage : UserIcon}
              />
              {/* If review_component is true and rating prop has value show RATING ICON */}

              <img src={RATING_ICONS[data.rating]} className={styles.emoji} />
            </div>
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {customer.name ? (
                  <span
                    data-test-id={"cy-live-updates-customer-phone-number"}
                    className={styles.telephone}
                  >
                    {customer.name}
                  </span>
                ) : (
                  <span
                    data-test-id={"cy-live-updates-customer-phone-number"}
                    className={styles.telephone}
                  >
                    {customer.country_code ?? ""} {customer.phone_number ?? ""}
                  </span>
                )}
                {data.type && (
                  <span
                    style={{
                      margin: "0 4px",
                      fontSize: "12px",
                      color: "#8e8e93",
                    }}
                  >
                    {orderTypeTitle[data.type]}
                  </span>
                )}
              </div>

              {/* Tags */}
              <div className={styles.categoriesAndTagsChips}>
                {/* Tags */}
                {(data.categories || []).map((tag) => (
                  <div className={styles.categoriesChips}>
                    <div
                      className={
                        tag.selected
                          ? data.rating <= 2
                            ? styles.categoryRedChip
                            : styles.categoryChip
                          : data.rating <= 2
                          ? styles.tagRedChip
                          : styles.tagChip
                      }
                    >
                      <span
                        data-test-id={"cy-live-update-transaction-tag"}
                        className={tag.selected ? styles.category : styles.tag}
                      >
                        {i18n.language.includes("en")
                          ? tag.en_name
                          : tag.ar_name
                          ? tag.ar_name
                          : tag.en_name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Order Type */}
          {/* Review */}
          {
            <div className={styles.reviewWrapper}>
              {/* Text */}
              {data.feedback && (
                <div
                  className={styles.text}
                  data-test-id={"cy-live-update-transaction-review-text"}
                >
                  {seeMore
                    ? data.feedback?.slice(0, data.feedback.length)
                    : data.feedback?.slice(0, 135) +
                      `${
                        String(data.feedback)?.length > 135 && !seeMore
                          ? "..."
                          : ""
                      }`}
                  {String(data.feedback)?.length > 135 && (
                    <span
                      className={`${styles.readMore}`}
                      onClick={toggleSeeMore}
                    >
                      {seeMore ? t(Strings.show_less) : t(Strings.show_more)}
                    </span>
                  )}
                </div>
              )}
            </div>
          }
        </div>
        {shouldSolve && (
          <button
            className={styles.solveBtn}
            onClick={() => history.push("/reviews/view/" + data.id)}
          >
            {t(Strings.solveIssue)}
          </button>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
