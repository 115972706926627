import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../selectors";
import { getPromotionsAPI } from "../../../axios/promotions/getPromotionsAPI";
import { getPromotions, getPromotionsFailure, getPromotionsSuccess } from "../../actions";
import { union } from "ts-action";
import { getPromotionsAction } from "../../../constants";

const actionType = union({ getPromotions });
function* getPromotionsSage({ payload }: typeof actionType) {
    try {
        const token = yield select(selectToken);
        const res = yield call(getPromotionsAPI, token, payload);
        yield put(getPromotionsSuccess({
            data: res.data,
            hasMore: !!res.data.length
        }));
    } catch (error) {
        yield put(getPromotionsFailure(error as Error));
    }
}

export function* watchGetPromotionsSaga() {
    yield takeLatest(getPromotionsAction.requested, getPromotionsSage);
}