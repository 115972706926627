import axios from "./axios";
import { IModifiersGroup } from "../lib";

export const editSuggestedModifiersGroupAPI = (
  token: string,
  data: IModifiersGroup
) => {
  return axios.put("/api/manager/suggested-modifiers-groups/" + data.id, data, {
    headers: {
      token,
    },
  });
};
