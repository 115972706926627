import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";


export const removeFraudlentCustomerPoints = (phone_number: string, token: string): AxiosReturn<any> => {
  return axios.post(Requests.removeFraudlentCustomerPoints(phone_number), {}, {
    baseURL: process.env.REACT_APP_DASHBOARD_ENDPOINT,
    headers: {
      token,
    },

  });
};
