import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import i18n from "../../i18n/i18n";

export interface ISelectItemData<ValueType = any> {
  label: string;
  value: ValueType;
  icon?: any;
  isNext?: boolean;
  disabled?: boolean
}

interface ISelectItemProps {
  item: ISelectItemData;
  onSelect: (item: any) => any;
}

const SelectItem: React.SFC<ISelectItemProps> = ({ item, onSelect }) => {
  const handleSelect = () => onSelect(item.value);
  return (
    <DropdownItem
      className={styles.item}
      style={{
        textAlign: i18n.language === "ar" ? "right" : "left",
      }}
      onClick={handleSelect}
    >
      <img src={item.icon}
        style={{
          width: 25,
          height: 20
        }}
      />
      {item.label}
      {item.isNext}
    </DropdownItem>
  );
};

interface IRenderDropDownSelectState {
  isOpen: boolean;
}

type DefaultProps = Readonly<typeof defaultProps>;
interface IRenderDropDownSelectProps extends DefaultProps {
  label: string;
  type: string;
  className: string;
  disabled: boolean;
  options: ISelectItemData[];
}

const defaultProps = {
  renderSelectToggle: (item: ISelectItemData) => <div style={{
    marginLeft: 24
  }}>
    <img style={{
      width: 25,
    }}
      src={item.icon}
    /> {item.label}</div>,
};

export class RenderDropDownSelect extends React.Component<
  WrappedFieldProps & IRenderDropDownSelectProps,
  IRenderDropDownSelectState
> {
  public static defaultProps = defaultProps;
  public state = {
    isOpen: false,
  };

  public toggleSelectMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  public handleBlur = () => {
    const { input } = this.props;
    input.onBlur("");
    input.onChange(input.value);
  };
  public render() {
    const {
      input,
      label,
      meta: { touched, error, invalid, active, valid },
      options,
      className,
    } = this.props;
    const isInvalid = touched && invalid;
    const isValid = touched && valid;
    const selectedLabel = options.find((opt) => opt.value === input.value) || {
      label,
      value: "",
      icon: ""
    };
    return (
      <div
        className={`${className ? styles.fullWidthDrop : styles.dropDownPart
          } ${className || ""}`}
      >
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
        </label>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggleSelectMenu}>
          <DropdownToggle
            style={{
              ...(active ? { borderColor: "#fdbb11" } : {}),
              ...(isValid ? { borderColor: "#38c172" } : {}),
              ...(isInvalid ? { borderColor: "#db3030" } : {}),
            }}
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
            className={styles.switch}
          >
            {this.props.renderSelectToggle(selectedLabel)}
          </DropdownToggle>

          <DropdownMenu className={styles.menuWrapper}>
            {options.map((option, index) => (
              <SelectItem key={index} item={option} onSelect={input.onChange} />
            ))}
          </DropdownMenu>
        </Dropdown>
        {isInvalid && (
          <p
            style={{
              margin: 0,
              padding: "1rem",
              color: "#db3030",
              fontSize: "1.2rem",
            }}
          >
            *{error}
          </p>
        )}
      </div>
    );
  }
}
