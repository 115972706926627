import { useContext } from "react";
import { useQuery } from "react-query";
import { getTodaysTotalRedemptionsReq } from "../../../axios/Manager/Analytics";
import StoreData from "../../../contexts/StoreData";

export const useGetTodaysTotalRedemptions = () => {
  const { token } = useContext(StoreData);
  return useQuery(
    ["todays-total-redemptions"],
    async () => await getTodaysTotalRedemptionsReq(token),
    {
      retry: 3,
      enabled: !!token,
    }
  );
};
