import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, withRouter } from "react-router";
import { LanguageDropDown } from "./components";
// antd
import { Layout } from "antd";
import ProfileDropDown from "./components/ProfileDropDown";
import { collapseAtom, hamburgerAtom } from "../../Components/atoms/index";
import { useRecoilState, useRecoilValue } from "recoil";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useRtlClass } from "../../shopx-shared-components/hooks/useRtlClassName";
import { useTranslation } from "../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../i18n/strings/navigation";
import StoreData from "../../contexts/StoreData";
import { useOrderingPortalToggle } from "../OrderingPortal/OrderingPortalHeader/useOrderPortalToggle";
import { OrderingPortalStatus } from "../Pickup/axios/getOrderingWebPortalStatus";
import {
  mainRoute,
  orderHistoryRoute,
  orderingPortalRoute,
  orderingRoutes as defaultOrderingRoutes,
  settingsRoutes as defaultSettingsRoutes,
  insightsRoutes,
  branchesAndCashierRoute,
  brandListingRoutes,
} from "./consts";
import { useDeepCompareMemo } from "use-deep-compare";
import { useSelect } from "../../hooks/useSelect";
import { useUserAccess } from "../AuthenticatedRoutes";
const { Header: AntHeader } = Layout;

const Header: React.FC<RouteComponentProps> = ({ location }) => {
  const { token } = useContext(StoreData);
  const { orderingPortalStatus } = useOrderingPortalToggle(token);
  const { service_disabled } = OrderingPortalStatus;
  const { t } = useTranslation("navigation");
  const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
  const storeSettings = useSelect(
    (state) => state.storeSettingsReduces.General
  );
  const { qr_portal_status } = useSelect((state) => ({
    qr_portal_status: state.getOrderingQRPortalStatusReducer.status,
  }));
  const {
    hasAccessToLeaderboard,
    hasFullAccess,
    hasAccessToBranches,
    hasPickUpEnabled,
  } = useUserAccess();
  const { hasPosEntities } = useSelect((state) => ({
    hasPosEntities: !!state.getPosEntitiesReducer.entitiesIds.length,
  }))
  const orderingRoutes: mainRoute = useDeepCompareMemo(() => {
    return defaultOrderingRoutes(
      hasPickUpEnabled,
      storeSettings.delivery_settings_completed ||
      storeSettings.pickup_settings_completed,
      orderingPortalStatus !== service_disabled,
      qr_portal_status !== service_disabled
    );
  }, [storeSettings, hasPickUpEnabled, orderingPortalStatus, qr_portal_status]);
  const settingsRoutes: mainRoute = useMemo(() => {
    return defaultSettingsRoutes(hasAccessToBranches, hasFullAccess, hasPosEntities);
  }, [hasAccessToBranches, hasFullAccess, hasPosEntities]);

  const selectedMainRoute = useDeepCompareMemo(() => {
    if (location.pathname.includes("pickup/setup")) return orderingRoutes;
    else if (location.pathname.includes("insights")) return insightsRoutes;
    else if (location.pathname.includes("brand")) return brandListingRoutes;
    else if (location.pathname.includes("settings")) return settingsRoutes;
  }, [location.pathname, orderingRoutes, settingsRoutes]);

  const pageTitle = useMemo(() => {
    if (location.pathname.includes("reviews")) return [t(Strings.feedback)];
    else if (location.pathname.includes("home")) return [t(Strings.home)];
    else if (location.pathname.includes("leaderboard"))
      return [t(Strings.leaderBoard)];
    else if (location.pathname.includes("home")) return [t(Strings.home)];
    else if (location.pathname.includes("my-account"))
      return [t(Strings.myProfile)];
  }, [location.pathname]);

  const history = useHistory();

  const handleSubRouteClick = (e) => {
    const { id } = e.target;
    if (id && selectedMainRoute) {
      history.replace(selectedMainRoute[id].route);
    }
  };

  const [isProfileDropDownOpen, setIsProfileDropDownOpen] =
    useState<boolean>(false);
  const [isLanguageDropDownOpen, setIsLanguageDropDownOpen] =
    useState<boolean>(false);

  const profileDropDownEl = document.getElementById(
    "profile-drop-down-container"
  ) as HTMLDivElement;

  const languageDropDownEl = document.getElementById(
    "drop-down-container"
  ) as HTMLDivElement;

  // managing clicks outside
  useEffect(() => {
    document.addEventListener("click", (e) => {
      let isClickInsideProfileDiv =
        profileDropDownEl &&
        profileDropDownEl.contains(e.target as HTMLDivElement);

      let isClickInsideLanguageDiv =
        languageDropDownEl &&
        languageDropDownEl.contains(e.target as HTMLDivElement);

      if (!isClickInsideProfileDiv && isProfileDropDownOpen) {
        setIsProfileDropDownOpen(false);
      }
      if (!isClickInsideLanguageDiv && isLanguageDropDownOpen) {
        setIsLanguageDropDownOpen(false);
      }
    });
    return () => {
      document.removeEventListener("click", () => { });
    };
  }, [
    profileDropDownEl,
    languageDropDownEl,
    isLanguageDropDownOpen,
    isProfileDropDownOpen,
  ]);
  const [hamburgerClicked, setHamburgerClicked] = useRecoilState(hamburgerAtom);
  const windowSize = useWindowSize();
  const navbarCollapsed = useRecoilValue(collapseAtom);
  const headerStyles = useMemo(() => {
    if (windowSize.width <= 1152) {
      return styles.headerWrapperWithDrawer;
    } else if (navbarCollapsed) {
      return styles.collapseWrapper;
    } else {
      return styles.wrapper;
    }
  }, [windowSize.width, navbarCollapsed]);
  return (
    <div
      className={`${headerStyles} ${useRtlClass(styles)}`}
      style={{ zIndex: windowSize.width <= 1152 ? 11 : 99 }}
    >
      {isKoinzAdminRemote && (
        <div className={styles.dashboardPreviewAlert}>
          <span>Dashboard Live Preview</span>
        </div>
      )}
      <AntHeader className={styles.header}>
        {windowSize.width <= 1152 ? (
          <img
            onClick={() => setHamburgerClicked(!hamburgerClicked)}
            className={styles.menuIcon}
            src={require("../../assets/feather _ menu.svg").default}
          />
        ) : null}
        <div className={styles.subRoutesContainer}>
          {pageTitle && <span className={styles.pageTitle}>{pageTitle}</span>}
          {selectedMainRoute &&
            Object.entries(selectedMainRoute).map(([key, value]) => (
              <button
                key={key}
                className={`${styles.subRoute} ${location.pathname.includes(value.keyWord.toLowerCase())
                  ? styles.subRouteOnFocus
                  : ""
                  }`}
                id={key}
                onClick={handleSubRouteClick}
              >
                {key}
              </button>
            ))}
        </div>
        <ProfileDropDown
          isProfileDropDownOpen={isProfileDropDownOpen}
          setIsProfileDropDownOpen={setIsProfileDropDownOpen}
        />
        <LanguageDropDown
          isLanguageDropDownOpen={isLanguageDropDownOpen}
          setIsLanguageDropDownOpen={setIsLanguageDropDownOpen}
        />
      </AntHeader>
    </div>
  );
};

export default hot(module)(withRouter(Header));
