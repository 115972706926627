import * as React from "react";
import { constructDraggableContainer } from "./Card";
import update from "immutability-helper";
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  XYCoord,
} from "react-dnd";
import { IIdentifiedObject } from "../../../../types/wizard-types";

export interface ISortableDnDListProps<DataType extends IIdentifiedObject> {
  data: DataType[];
  renderItem: (
    item: DataType,
    isDragging: boolean,
    connectDragSource: ConnectDragSource,
    connectDragPreview: ConnectDragPreview,
    connectDropTarget: ConnectDropTarget,
    position: XYCoord | null,
    index: number
  ) => React.ReactChild;
  onReOrder: (
    item: DataType,
    nextIndex: number,
    reorderedItems: DataType[]
  ) => void;
  onDrop?: (draggedId: string, droppedId: string) => void;
}

export interface ISortableDnDListState<DataType> {
  cardsById: { [x: string]: DataType };
  cardsByIndex: DataType[];
}

function processDnDCollection<DataType extends IIdentifiedObject>(
  data: DataType[]
) {
  const cardsById: { [x: string]: DataType } = {};
  const cardsByIndex: DataType[] = [];

  for (let i = 0; i < data.length; i += 1) {
    const card = data[i];
    cardsById[card.id] = card;
    cardsByIndex[i] = card;
  }

  return {
    cardsById,
    cardsByIndex,
  };
}

export default function<DataType extends IIdentifiedObject>(
  itemType: string,
  dropAbleItems?: string[]
) {
  const Card = constructDraggableContainer(itemType, dropAbleItems);
  return class SortableDnDList extends React.Component<
    ISortableDnDListProps<DataType>,
    ISortableDnDListState<DataType>
  > {
    public state = {
      cardsById: {},
      cardsByIndex: [],
    } as ISortableDnDListState<DataType>;

    constructor(props: ISortableDnDListProps<DataType>) {
      super(props);
      this.state = processDnDCollection<DataType>(this.props.data);
    }

    public componentWillReceiveProps = (
      nextProps: Readonly<ISortableDnDListProps<DataType>>
    ) => {
      if (nextProps.data !== this.props.data) {
        this.setState({
          ...this.state,
          ...processDnDCollection<DataType>(nextProps.data),
        });
      }
    };

    public renderCard = (item: DataType, index: number) => (
      isDragging: boolean,
      connectDragSource: ConnectDragSource,
      connectDragPreview: ConnectDragPreview,
      connectDropTarget: ConnectDropTarget,
      position: XYCoord | null
    ) =>
      this.props.renderItem(
        item,
        isDragging,
        connectDragSource,
        connectDragPreview,
        connectDropTarget,
        position,
        index
      );

    public render = () => {
      const { cardsByIndex } = this.state;
      return cardsByIndex.map((card, index) => (
        <Card
          onDrop={this.handleDropCard}
          key={card.id}
          id={card.id}
          moveCard={this.moveCard}
        >
          {this.renderCard(card, index)}
        </Card>
      ));
    };

    private handleDropCard = (draggedId: string, droppedId: string) => {
      const { cardsById, cardsByIndex } = this.state;
      const { onReOrder, onDrop } = this.props;
      const card = cardsById[draggedId];
      const cardIndex = cardsByIndex.indexOf(card);
      onReOrder(card, cardIndex, this.state.cardsByIndex);
      if (onDrop) {
        onDrop(draggedId, droppedId);
      }
    };
    private moveCard = (id: string, afterId: string) => {
      const { cardsById, cardsByIndex } = this.state;

      const card = cardsById[id];
      const afterCard = cardsById[afterId];

      if (card && afterCard) {
        const cardIndex = cardsByIndex.indexOf(card);
        const afterIndex = cardsByIndex.indexOf(afterCard);
        const nextState = update(this.state, {
          cardsByIndex: {
            $splice: [
              [cardIndex, 1],
              [afterIndex, 0, card],
            ],
          },
        });
        this.setState(nextState);
      }
    };
  };
}
