import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { union } from "ts-action";
import {
  toggle1,
  toggle2,
  toggle3,
  toggle4,
  toggle5,
  resetCollapse,
} from "../actions";

const COLLAPSE_1 = "COLLAPSE_1";
const COLLAPSE_2 = "COLLAPSE_2";
const COLLAPSE_3 = "COLLAPSE_3";
const COLLAPSE_4 = "COLLAPSE_4";
const COLLAPSE_5 = "COLLAPSE_5";

export interface ICollapseReducerState {
  [COLLAPSE_1]: boolean;
  [COLLAPSE_2]: boolean;
  [COLLAPSE_3]: boolean;
  [COLLAPSE_4]: boolean;
  [COLLAPSE_5]: boolean;
}

const initialState: ICollapseReducerState = Map({
  [COLLAPSE_1]: false,
  [COLLAPSE_2]: false,
  [COLLAPSE_3]: false,
  [COLLAPSE_4]: false,
  [COLLAPSE_5]: false,
}).toJS();

const allActions = union({
  toggle1,
  toggle2,
  toggle3,
  toggle4,
  toggle5,
  resetCollapse,
} as any);

const collapseReducer = (
  state = initialState,
  action: any
): ICollapseReducerState => {
  switch (action.type) {
    case constants.ACTION_TOGGLE_1: {
      return mutateState(state, (map) => {
        const prevCollapse = map.get(COLLAPSE_1);
        map.set(COLLAPSE_1, !prevCollapse);
        map.set(COLLAPSE_2, false);
        map.set(COLLAPSE_3, false);
        map.set(COLLAPSE_4, false);
        map.set(COLLAPSE_5, false);
      });
    }
    case constants.ACTION_TOGGLE_2: {
      return mutateState(state, (map) => {
        const prevCollapse = map.get(COLLAPSE_2);
        map.set(COLLAPSE_1, false);
        map.set(COLLAPSE_2, !prevCollapse);
        map.set(COLLAPSE_3, false);
        map.set(COLLAPSE_4, false);
        map.set(COLLAPSE_5, false);
      });
    }
    case constants.ACTION_TOGGLE_3: {
      return mutateState(state, (map) => {
        const prevCollapse = map.get(COLLAPSE_3);
        map.set(COLLAPSE_1, false);
        map.set(COLLAPSE_2, false);
        map.set(COLLAPSE_3, !prevCollapse);
        map.set(COLLAPSE_4, false);
        map.set(COLLAPSE_5, false);
      });
    }
    case constants.ACTION_TOGGLE_4: {
      return mutateState(state, (map) => {
        const prevCollapse = map.get(COLLAPSE_4);
        map.set(COLLAPSE_1, false);
        map.set(COLLAPSE_2, false);
        map.set(COLLAPSE_3, false);
        map.set(COLLAPSE_4, !prevCollapse);
        map.set(COLLAPSE_5, false);
      });
    }
    case constants.ACTION_TOGGLE_5: {
      return mutateState(state, (map) => {
        const prevCollapse = map.get(COLLAPSE_5);
        map.set(COLLAPSE_1, false);
        map.set(COLLAPSE_2, false);
        map.set(COLLAPSE_3, false);
        map.set(COLLAPSE_4, false);
        map.set(COLLAPSE_5, !prevCollapse);
      });
    }
    case constants.ACTION_RESET_COLLAPSE: {
      return mutateState(state, (map) => {
        map.set(COLLAPSE_1, false);
        map.set(COLLAPSE_2, false);
        map.set(COLLAPSE_3, false);
        map.set(COLLAPSE_4, false);
        map.set(COLLAPSE_5, false);
      });
    }
    default:
      return state;
  }
};

export default collapseReducer;
export type collapseActions = typeof allActions;
