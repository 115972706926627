import axios from "./axios";
import * as Requests from "./requests";

// single responsibility for this is to send data to server
export const deleteCashier = (token: string, cashier_id: string) => {
  return axios.delete(Requests.cashiers + "/" + cashier_id, {
    headers: {
      token,
    },
  });
};
