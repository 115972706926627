import axios from "../../axios";
import * as Requests from "../../requests";
import { IUpdateBranchesWorkingHoursPayload } from "../../../../../types";

// get branch working hours
export const getBranchWorkingHoursApi = (token: string, branch_id) => {
  return axios.get(Requests.getBranchWorkingHoursURL(branch_id), {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};

// update branches working hours
export const updateBranchesWorkingHoursApi = (
  token: string,
  payload: IUpdateBranchesWorkingHoursPayload
) => {
  return axios.put(Requests.updateBranchesWorkingHoursURL, payload, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};
