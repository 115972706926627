import crs from "./convertingRatio";
import obs from "./onBoardingSteps";
export default {
  somethingWentWrong: crs.somethingWentWrong,
  next: obs.next,
  back: obs.back,
  uploading: "جاري تحويل القائمة",
  menuProviderLinkLabel: "أدخل رابط متجرك على {{menuProvider}}",
  grabMenu: "تحويل",
  addingFromProviderTitle: "إضافة قائمتك باستخدام {{menuProvider}}",
  addingFromProviderSubtitle:
    "قم بالوصول إلى صفحة متجرك على موقع {{menuProvider}} ثم قم بنسخ الرابط من شريط العنوان بالأعلى",
};
