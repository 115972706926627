import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import StoreData from "../contexts/StoreData";
import { getCashiers, ICashier } from "../axios/getCashiers";
import { deleteCashier as deleteCashierAPI } from "../axios/deleteCashier";
import {
  createCashier as createCashierAPI,
  ICreateCashier,
} from "../axios/createCashier";
import {
  editCashier as editCashierAPI,
  IEditCashierData,
} from "../axios/editCashier";
import { useProgressState } from "../Utils";
import { IIdentifiedObject } from "../types/wizard-types";
import { processCashierBranches } from "../Components/WizardComponents/Cashiers/Cashiers";
import { addCallCenterCashier } from "../axios/addCallCenterCashier";
import { trackEvent } from "../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../constants/events-for-tracking";
import { IBranch } from "../types/shared";

export const mapById = (arr: IIdentifiedObject[]) => {
  return arr.reduce((acc, next) => ({ ...acc, [next.id]: next }), {});
};
export const useCashiers = (branches: IBranch[]) => {
  const { token } = useContext(StoreData);
  const {
    success,
    setSuccess,
    failure,
    setFailure,
    setLoading,
  } = useProgressState();
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [submissionSucceeded, setSubmissionSucceeded] = useState(false);
  const [cashiers, setCashiers] = useState<ICashier[]>([]);
  const branchesIdMap: { [brancId: string]: IBranch } = useMemo(
    () => mapById(branches),
    [branches]
  );
  const cashiersRef = useRef(cashiers);
  cashiersRef.current = cashiers;

  const deleteCashier = useCallback(
    async (cashier: ICashier) => {
      await deleteCashierAPI(token, cashier.id);
      trackEvent(EVENTS_FOR_TRACKING["Deleted a branch or a cashier"]);
      setCashiers(cashiersRef.current.filter((c) => c.id !== cashier.id));
    },
    [token, cashiers]
  );

  const createCashier = useCallback(
    async (cashier: ICreateCashier) => {
      const {
        data: { cashier: cashierRes },
        status,
      } = await createCashierAPI(token, cashier);
      const cashierData = processCashierBranches(cashierRes, branchesIdMap);
      if (status === 200) {
        setSubmissionSucceeded(true);
      } else {
        setSubmissionFailed(true);
      }
      setCashiers([cashierData, ...cashiersRef.current]);
    },
    [token, cashiers, branchesIdMap]
  );

  const createCallCenterCashier = useCallback(
    async (cashier: ICreateCashier) => {
      const {
        data: { cashier: cashierRes },
        status,
      } = await addCallCenterCashier(token, cashier);
      const cashierData = processCashierBranches(cashierRes, branchesIdMap);
      if (status === 200) {
        setSubmissionSucceeded(true);
      } else {
        setSubmissionFailed(true);
      }
      setCashiers([cashierData, ...cashiersRef.current]);
    },
    [token, cashiers, branchesIdMap]
  );

  const editCashier = useCallback(
    async (cashier: IEditCashierData) => {
      const { data, status } = await editCashierAPI(token, cashier);
      if (status === 200) {
        setSubmissionSucceeded(true);
      } else {
        setSubmissionFailed(true);
      }
      setCashiers(
        cashiersRef.current.map((cashier1) => {
          if (cashier1.id === data.id) {
            return processCashierBranches(data, branchesIdMap);
          }
          return cashier1;
        })
      );
    },
    [token, cashiers, branchesIdMap]
  );

  const restoreCashier = useCallback(
    async (cashier: IEditCashierData) => {
      await createCashier(cashier as ICreateCashier);
      return await editCashier(cashier as IEditCashierData);
    },
    [token, createCashier]
  );

  useEffect(() => {
    setLoading();
    if (branches && branches.length) {
      getCashiers(token)
        .then((data) => {
          const mappedCashiersBranches = data.data
            .filter((c) => !c.dummy)
            .map((c) => {
              const cashierBranches = c.branch_ids.map((a) => branchesIdMap[a]);
              return { ...c, branches: cashierBranches };
            });
          setCashiers(mappedCashiersBranches);
          setSuccess();
        })
        .catch(setFailure);
    }
  }, [token]);

  return {
    cashiers,
    success,
    failure,
    deleteCashier,
    submissionFailed,
    setSubmissionFailed,
    setSubmissionSucceeded,
    submissionSucceeded,
    createCashier,
    editCashier,
    restoreCashier,
    createCallCenterCashier,
  };
};
