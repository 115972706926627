import { useContext } from "react";
import { useQuery } from "react-query";
import { getTodaysTotalOrdersReq } from "../../../axios/Manager/Analytics";
import StoreData from "../../../contexts/StoreData";

export const useGetTodaysTotalOrders = () => {
  const { token } = useContext(StoreData);
  return useQuery(
    ["todays-total-orders"],
    async () => await getTodaysTotalOrdersReq(token),
    {
      retry: 3,
      enabled: !!token,
    }
  );
};
