import convertingRatio from "./convertingRatio";
import sl from "./socialLinks";

export default {
  actualDeliveryFees: "Actual delivery fees",
  errorMessage:
    "Missing branch data, please update all of the missing data before adding delivery zones.",
  syncOrderingMenuSuccess: "Syncing...",
  syncOrderingMenuFailure: "a sync process is already running",
  addBranchTitle: "Add New Branch",
  brandSavedSuccess: "Brand data saved successfully !",
  editBranch: "Save Updates",
  addZone: "Add Zone",
  branchNameLabel: "Name",
  viewBranchTitle: "Branch Details",
  branchNamePlaceholder: "Branch Name",
  enterTheLocationManually: "Enter the location manually",
  longitude: "Longitude",
  latitude: "Latitude",
  arBranchNameLabel: "Name in Arabic",
  arBranchNamePlaceholder: "اسم الفرع",
  syncBranchesAndCashier: "Sync Branches & Cashiers",
  phoneNumberLabel: "Contact number",
  addressWithoutMapPlaceholder: "22 street name, square, etc.",
  arAddressWithoutMapPlaceholder: "٢٢ اسم الشارع، الحي، الخ",
  arAddressWithoutMapLabel: "Address in Arabic",
  searchForDistrict: "Search for District",
  searchForArea: "Search for Area",
  addressWithoutMapLabel: "Address",
  phoneNumberPlaceholder: "01XXXXXXXX",
  integrationIdLabel: "Integration ID",
  integrationIdPlaceholder: "128bcd",
  addressLabel: "Map location",
  addressPlaceholder: "Search Google Maps",
  addBranch: "Add Branch",
  title: "Branches & Cashiers",
  subtitle: "Manage Cashiers & Branches below",
  branchesCount: "Branches ({{count}})",
  branchSearchPlaceholder: "Search for branches...",
  newBranch: "New Branch",
  callCenterToggleTitle: "Call center?",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  markComplete: convertingRatio.markComplete,
  noBranchesAdded: "No Branches have been added",
  noMatch: "No Match!",
  addBranchSuccess: '"{{branchName}}" branch added successfully',
  addBranchZonesSuccess: "Updated branch's delivery zones successfully!",
  addBranchZonesLoading: "Updating branch's delivery zones...",
  addBranchZonesFailure:
    "Failed to update branch's delivery zones, try again and if problem persists contact support.",
  addBranchFailure: 'Failed to add "{{branchName}}" branch',
  initializeBranchFailed: "Failed to load branch data, please try again later",
  brandSettings: "Brand settings",
  Main: "Branches & Main",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  editBranchTitle: "Update Branch",
  submit: sl.submit,
  submitAddBranch: "Add Branch",
  addBranchButtonTitle: "Add Branch",
  callCenterTitle: "📞 Do you have a call center?",
  callCenterDesc: "This will add a call center branch to your brand",
  callCenterSeeCashiers: "View Cashiers",
  assignedCashiers: "Assigned Cashiers",
  show: "Show",
  more: "More",
  less: "Less",
  deleteBranch: "Delete Branch",
  deleteBranchDescription:
    "Your are about to delete this branch. confirm to continue",
  confirmDelete: "Confirm",
  cancel: "Cancel",
  government: "State, Province, or Governorate",
  orderingStatus: "Ordering Status",
  enabled: "Enabled",
  disabled: "Disabled",
  addDeliveryZones: "Add delivery zones",
  zonesAdded: "Zones added",
  atLeastOneZone:
    " You have to add at least 1 delivery zone to activate the delivery system",
  deliverySystem: "Delivery",
  deliverySystemContent:
    " Your restaurant will delivery the orders the has been requested to your customers.",
  pickupSystem: "Pickup",
  pickupSystemContent: "Customers can pickup their orders from the branch.",
  editDeliveryZones: "Edit Delivery Zones",
  district: "District",
  area: "Area",
  zone: "Zone",
  editZone: "Edit Zone",
  branchInfo: "BRANCH INFORMATION",
  deliveryZone: "Delivery zones",
  deliveryFees: "Delivery fees",
  deliveryTime: "Delivery time",
  zoneName: "Zone name",
  pound: "EGP",
  time: "Min",
  apply: "Apply",
  checkAll: "Check all",
  unCheckAll: "Uncheck All",
  addAllDeliveryTimeAndFees: "Fill all delivery fees, times, and actual cost  ",
  zones: "Zones",
  deleteDistrictZonesDescription:
    "Your are about to delete this district and its zones. confirm to continue",
  deleteDistrictZones: "Delete District",
  selectZonesBeforeFillingSelected:
    "Please select the zones to which you would apply identical delivery fees and time.",
  zonesWithNoArea: "Zones with no area",
  mayContainMultipleZones: "May contain multiple zones",
  restaurantHeading: "Restaurant delivering",
  restaurantParagraph: "Restaurant will send the order to the customer.",
  koinzDeliveryHeading: "Koinz delivering",
  koinzDeliveryParagraph: "Koinz courier will send the order to your customer.",
  offlineLoyaltyStatus: "Offline Loyalty Status",
  driveThruTooltip: "This branch is not integrated with the pos",
};
