import {
  getBranchWorkingHoursAction,
  getStoreWorkingHoursAction,
  updateStoreWorkingHoursAction,
  updateBranchesWorkingHoursAction,
  checkOnStoreWHUpdatingStatusAction,
  clearBranchesWorkingHoursAction,
} from "../../../../constants/actions";
import { action, payload } from "ts-action";
import {
  IStoreWorkingHours,
  IUpdateBranchesWorkingHoursPayload,
} from "../../../../../../types/Manager";

// get store working hours
export const getStoreWorkingHours = action(
  getStoreWorkingHoursAction.requested
);
export const getStoreWorkingHoursSuccess = action(
  getStoreWorkingHoursAction.fulfilled,
  payload<IStoreWorkingHours>()
);
export const getStoreWorkingHoursFailure = action(
  getStoreWorkingHoursAction.rejected,
  payload<Error>()
);

// update store working hours
export const updateStoreWorkingHours = action(
  updateStoreWorkingHoursAction.requested,
  payload<IStoreWorkingHours>()
);
export const updateStoreWorkingHoursSuccess = action(
  updateStoreWorkingHoursAction.fulfilled,
  payload<any>()
);
export const updateStoreWorkingHoursFailure = action(
  getStoreWorkingHoursAction.rejected,
  payload<Error>()
);

// get branch working hours
export const getBranchWorkingHours = action(
  getBranchWorkingHoursAction.requested,
  payload<string>()
);
export const getBranchWorkingHoursSuccess = action(
  getBranchWorkingHoursAction.fulfilled,
  payload<{ [branch_id: string]: IStoreWorkingHours }>()
);
export const getBranchWorkingHoursFailure = action(
  getBranchWorkingHoursAction.rejected,
  payload<Error>()
);

export const clearBranchesWorkingHours = action(
  clearBranchesWorkingHoursAction
);

// update branch working hours
export const updateBranchesWorkingHours = action(
  updateBranchesWorkingHoursAction.requested,
  payload<IUpdateBranchesWorkingHoursPayload>()
);
export const updateBranchesWorkingHoursSuccess = action(
  updateBranchesWorkingHoursAction.fulfilled,
  payload<any>()
);
export const updateBranchesWorkingHoursFailure = action(
  updateBranchesWorkingHoursAction.rejected,
  payload<Error>()
);

// checkup on store updating store branches working hours status
export const checkOnStoreWHUpdatingStatus = action(
  checkOnStoreWHUpdatingStatusAction.requested
);
export const checkOnStoreWHUpdatingStatusSuccess = action(
  checkOnStoreWHUpdatingStatusAction.fulfilled,
  payload<any>()
);
export const checkOnStoreWHUpdatingStatusFailure = action(
  checkOnStoreWHUpdatingStatusAction.rejected,
  payload<Error>()
);
