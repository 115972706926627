export default {
  viewDetails: "عرض التفاصيل",
  editDetails: "تعديل التفاصيل",
  updateDetails: "تحديث التفاصيل",
  uncompletedBranches: "فروع غير محدثة",
  completeBranchDetails:
    "قم باكمال تفاصيل هذه الفروع حتى يمكننا تفعيل خدمة Ordering لها",
  completedBranches: "فروع محدثة",
  completed: "مكتمل",
  addBranches: "اضافة فرع",
  description:
    "يرجى ملء جميع المعلومات الخاصة بمتجرك مثل العنوان ورقم الهاتف لإكمال بيانات فرعك.",
  Next: "التالى",
  Back: "رجوع",
};
