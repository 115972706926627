import axios from "../../../axios";
import * as routes from "../../../api-routes/Manager/Analytics";

export const getRedemptionInsightsAPI = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getRedemptionInsights}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};

export const getRedemptionInsightsTotalAPI = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getRedemptionInsightsTotal}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};

export const getRedeemedGiftsAPI = (token) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTopRedeemedGifts}`,
    {
      headers: {
        token,
      },
    }
  );
};

export const exportCustomerRedemptionsCSV = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.exportCustomerRedemptionsCSV}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};
