import React from "react";
interface IProps {
  clicked: boolean;
}
const BrandListings = ({ clicked }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill={clicked ? "#ffa351" : "#aeaeb2"} fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M19.99 11.76l-8-8a2.559 2.559 0 0 0-2.098-.737l-3.941.369c-1.245.138-2.236 1.106-2.352 2.351l-.392 3.919a2.533 2.533 0 0 0 .738 2.098l8.045 7.999c.484.484 1.153.76 1.867.76.715 0 1.36-.276 1.868-.76l4.287-4.311c.484-.484.76-1.153.76-1.867a2.733 2.733 0 0 0-.783-1.821zm-.577 1.867c0 .346-.138.668-.369.899l-4.288 4.287c-.23.231-.553.392-.899.392-.345 0-.668-.138-.899-.392l-8.022-7.998a1.401 1.401 0 0 1-.392-.9v-.138l.392-3.919A1.3 1.3 0 0 1 6.09 4.706l3.919-.392h.138c.346 0 .669.138.9.369l8.021 7.999c.208.276.346.6.346.945z" />
          <path d="M9.017 6.527C7.772 6.527 6.78 7.54 6.78 8.74c0 .6.231 1.152.646 1.59.438.438.991.669 1.59.669.6 0 1.153-.23 1.591-.645.438-.438.669-.992.669-1.591 0-.6-.23-1.176-.669-1.59-.438-.416-.991-.623-1.59-.646zm.875 2.236a.99.99 0 0 1-.253.645.915.915 0 0 1-1.544-.645.99.99 0 0 1 .253-.646.933.933 0 0 1 .622-.253c.23 0 .438.092.646.253.184.208.276.415.276.646z" />
        </g>
      </g>
    </svg>
  );
};

export default BrandListings;
