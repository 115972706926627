import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export interface IEmojies {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
}

export const getEmojies = (
  token,
  filters?
): AxiosReturn<{ reviews_rates_number: IEmojies }> => {
  return axios.get(Requests.getEmojies, {
    headers: {
      token,
    },
    params: filters,
  });
};
