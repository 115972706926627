import c from "./convertingRatio";

export default {
  menu: "Menu",
  syncOrderingMenuSuccess: "Syncing...",
  syncOrderingMenuFailure: "a sync process is already running",
  addMenuItems: "Add menu items",
  addedItemsCount: "Menu items added",
  syncPosMenu: "Sync Menu",
  addItem: "Add item",
  addCategory: "Add Category",
  uploadMenu: "Import your menu:",
  uploadByExcel: "Import Excel sheet",
  uploadByElMenus: "Extract from ElMenus",
  uploadByHungerStation: "Extract from HungerStation",
  uploadExternally: "Upload menu externally",
  uploadManually: "Add Manually",
  itemName: "Item name",
  itemPhoto: "Item photo",
  itemPrice: "Price",
  itemCategory: "Category",
  itemIntegrationId: "Integration ID",
  newCategory: "New Category",
  emptyCategories: "No category has been added",
  categoryName: "Category Name",
  emptyItems: "No items added",
  deleteCategoryTitle: "Delete {{name}} Category",
  youAreAboutToDelete: "You are about to delete",
  item: "{{count}} item",
  item_plural: "{{count}} items",
  inThisCategory: "in this category",
  somethingWentWrong: c.somethingWentWrong,
  progressAutoSaved: c.progressAutoSaved,
  markComplete: c.markComplete,
  addingMenu: "Your menu is being added",
  waitDontGo: "Please wait and don’t close your browser",
  grabbingData: "Grabbing your data",
  failed: "Failed to import. please try again.",
  editItem: "Edit Item",
  delete: "Delete",
  addStoreItemSuccess:
    '"{{itemName}}" added to "{{categoryName}}" successfully!',
  addStoreItemFailure: 'Failed to add "{{itemName}}"',
  youCanAddGiftListItemsFrom:
    "You can add an item to your gift list by using the menu on your right",
  itemVisibility: "Item visibility",
};
