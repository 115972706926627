import { action, payload } from "ts-action";
import { ACTION_UPLOAD_CUSTOMER_POINTS_CSV, getCustomerInfoAction } from "../../constants/actions";
import { uploadCustomerPointsCSVAction } from "../../constants/actions"
import { ICustomerInfo } from "./lib";

export const getCustomerInfo = action(
  getCustomerInfoAction.requested,
  payload<string>()
);

export const getCustomerInfoSuccess = action(
  getCustomerInfoAction.fulfilled,
  payload<ICustomerInfo>()
);
export const getCustomerInfoFailure = action(
  getCustomerInfoAction.rejected,
  payload<Error>()
);


export const uploadCustomerPoints = action(
  uploadCustomerPointsCSVAction.requested,
  payload<FormData>()
);

export const uploadCustomerPointsSuccess = action(
  uploadCustomerPointsCSVAction.fulfilled,
  payload<any>()
);
export const uploadCustomerPointsFailure = action(
  uploadCustomerPointsCSVAction.rejected,
  payload<Error>()
);

export const uploadCustomerPointsIdle = action(
  `${ACTION_UPLOAD_CUSTOMER_POINTS_CSV}_IDLE`,
);


