import axios from "./axios";
import * as Requests from "./requests";
import { AxiosReturn } from "./types";
import { IBlockedUser } from "../hooks/use-blocked-users";

export interface ICreateBlockedUser {
  phone_number: string;
}

export const createBlockedUser = (
  token,
  blockedUser: ICreateBlockedUser
): AxiosReturn<IBlockedUser> => {
  return axios.post(Requests.blockedUsers, blockedUser, {
    headers: {
      token,
    },
  });
};
