import axios from "../../../axios";
import * as routes from "../../../api-routes/Manager/Analytics";

export const getCustomerInsightsAPI = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getCustomerInsights}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};

export const getCustomerInsightsTotalAPI = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTotalCustomerInsights}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};
