import { call, put, select, takeLatest } from "redux-saga/effects";
import { addCategoryAction } from "../../constants";
import {
  IAddCategoryAction,
  ICategory,
  OptimisticSuccess,
} from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { addCategory } from "../../axios/addCategory";

function* addCategorySaga(action: IAddCategoryAction) {
  try {
    const token = yield select(selectToken);
    const { name, optimisticId } = action.payload;
    const addedCategory = yield call(addCategory, token, name);
    const category = addedCategory.data as ICategory;
    yield put(
      actions.addCategorySuccess({
        ...category,
        state: OptimisticSuccess,
        optimisticId,
        items: [],
      })
    );
  } catch (e) {
    yield put(actions.addCategoryFailure(action.payload));
  }
}

export function* watchAddCategorySaga() {
  yield takeLatest(addCategoryAction.requested, addCategorySaga);
}
