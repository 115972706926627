import { reducer, on } from "ts-action";
import {
  updateBranchesWorkingHoursSuccess,
  updateBranchesWorkingHours,
  updateBranchesWorkingHoursFailure,
} from "../../../actions/Manager";

const initialState = {
  updateResponse: {},
  isLoading: false,
  error: null,
  loadingStatus: null,
};

export const updateBranchesWorkingHoursReducer = reducer<{
  updateResponse: any;
  isLoading: boolean;
  error: Error | null;
  loadingStatus: "loading" | "success" | "error" | null;
}>(
  [
    on(updateBranchesWorkingHours, (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
      updateResponse: {},
      loadingStatus: "loading",
    })),
    on(updateBranchesWorkingHoursSuccess, (state, { payload }) => ({
      ...state,
      updateResponse: payload,
      isLoading: false,
      error: null,
      loadingStatus: "success",
    })),
    on(updateBranchesWorkingHoursFailure, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
      updateResponse: {},
      loadingStatus: "error",
    })),
  ],
  initialState
);
