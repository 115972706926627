import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.sass";
import { Input, Table, Spin, Alert } from "antd";
import { columns } from "./columns";
import "./style.less";
import { Search, Download } from "react-feather";
import { Queries } from "../../../../../Queries/Manager";
import { useSelect } from "../../../../../hooks/useSelect";
import { LoadingOutlined } from "@ant-design/icons";
import { useIsVisible } from "react-is-visible";
import { useDispatch } from "react-redux";
import { getBranches, getStore } from "../../../../../redux-store/actions";
import ProfileModal from "../../../../../Components/Leaderboard/ProfileModal";
import Strings from "../../../../../i18n/Manager/strings/insights";
import { useTranslation } from "../../../../../shopx-shared-components/hooks/useTranslation";
import MultiSelect, {
  mapStringArryToSelectedArray,
} from "../../../../../@koinz-ui/MultiSelect";
import { TimeFilter } from "../../Home/components";
import { numberFormatter } from "../../../../../Utils/numbers";
import { RedeemedGiftCart } from "./RedeemedGift";
import { getLocalizedCurrency } from "../../../../../Utils";
import i18n from "../../../../../i18n/i18n";
import {
  CSVButton as ExportCSVButton,
  CSVButton,
  ExportCSVModal,
} from "../components";
import { exportCustomerRedemptionsCSV } from "../../../../../axios/Manager/Analytics/Insights/redemption";
import StoreData from "../../../../../contexts/StoreData";
import { pusher } from "../../../../../pusher/pusher";
import { trackEvent } from "../../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../../constants/events-for-tracking";
import {
  AmplitudeEvents,
  amplitudeLogEvent,
} from "../../../../../tracking/amplitude";
export interface Filters {
  page: number;
  size: number;
  filter?: string;
  branch_ids?: any[];
  from?: number;
  to?: number;
  unit?: string;
}

const RedemptionInsightsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelect((state) => state.authReducer);
  const { store } = useSelect((state) => state.storeReducer);
  const currency = getLocalizedCurrency(store.country_iso_code);
  //
  const listPaginationLoaderRef = useRef(null);
  const isVisible = useIsVisible(listPaginationLoaderRef);
  //
  const [isMore, setIsMore] = useState<boolean>(false);
  const [customerPopup, setCustomerPopup] = useState<boolean>(false);
  const [customerPopupId, setCustomerPopupId] = useState<string | undefined>();
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [searchInputLoading, setSearchInputLoading] = useState<boolean>(false);
  const [redemptionData, setredemptionData] = useState<any[] | undefined>(
    undefined
  );
  // localization
  const { t } = useTranslation("insights");
  const initialFiltersValue = {
    page: 0,
    size: 20,
    branch_ids: [],
  };

  const { allBranches } = useSelect((state) => state.branchesReducer);
  const [filters, setFilters] = useState<Filters>(initialFiltersValue);

  // Query
  const { data, status } = Queries.useGetRedemptionInsights(token, filters);
  const { data: topRedeemedGifts, status: topRedeemedGiftsStatus } =
    Queries.useGetTopRedeemedGifts(token);
  const { data: totalRedemptions, status: totalRedemptionsStatus } =
    Queries.useGetRedemptionInsightsTotal(token, {
      ...filters,
      page: undefined,
      size: undefined,
    });

  useEffect(() => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed redemptions insights page"]);
    amplitudeLogEvent(AmplitudeEvents.redemption_insights, {
      user_name: userData?.name,
      user_id: userData?.id,
      user_role: userData?.title || userData?.role,
      store_id: storeData?.id,
      store_name: storeData?.name,
    });
  }, []);

  useEffect(() => {
    if (customerPopupId) setCustomerPopup(true);
    else setCustomerPopup(false);
  }, [customerPopupId]);

  useEffect(() => {
    if (status === "success" && data.length > 0) {
      if (filters.page === 0) {
        setredemptionData(data);
      } else {
        setredemptionData((prev) => (prev && [...prev, ...data]) || data);
      }

      if (data.length < filters.size) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    } else {
      //
      if (filters.page === 0) {
        setredemptionData([]);
      }

      setIsMore(false);
    }
  }, [status, data]);

  useEffect(() => {
    if (status !== "loading") {
      setSearchInputLoading(false);
    }
  }, [status, data, filters.page]);

  // Get store with branches .. for "window refresh"
  useEffect(() => {
    dispatch(getStore());
    dispatch(getBranches());
  }, [dispatch]);

  // When bottom loader is visibile
  useEffect(() => {
    if (isMore && isVisible && status !== "loading") {
      setFilters((filters) => ({
        ...filters,
        page: filters.page + 1,
      }));
    }
  }, [isVisible, status]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: 0 }));
  }, [filters.branch_ids, filters.filter]);

  // Apply text search
  const applySearchFilter = (e) => {
    e.target.blur();
    if (filterSearchText !== filters.filter) {
      setFilters({
        ...filters,
        filter: filterSearchText !== "" ? filterSearchText : undefined,
        page: 0,
      });
      setSearchInputLoading(true);
    }
  };

  // set new filter update (with page reset to first page)
  const setFilterValue = (key, value) => {
    const copyOfFilters = { ...filters };
    copyOfFilters["page"] = 0;
    value = value === "" ? undefined : value;
    copyOfFilters[key] = value;
    setFilters(copyOfFilters);
  };

  // branch filter
  const handleBranchFilterMultiSelectChange = (values) => {
    if (values.length === allBranches.length) {
      setFilterValue("branch_ids", []);
    } else {
      setFilterValue(
        "branch_ids",
        values.map((v) => v.id)
      );
    }
  };

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  const SearchIconJSX = (
    <Search color="#8E8E93" size={18} className={styles.searchInputIcon} />
  );

  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  // export CSV Button
  const DownloadIconJSX = (
    <Download className={styles.exportButtonIcon} size={20} />
  );

  const { storeData, userData } = useContext(StoreData);

  const [downloadButtonStatus, setDownloadButtonStatus] = useState<
    "default" | "loading" | "disabled"
  >("disabled");

  const [socketExportStatus, setSocketExportStatus] = useState<
    "failed" | "default"
  >("default");

  const [isExpCSVModalVisible, setExpCSVModalVisible] = useState(false);
  const [exportModalTimeFilterVal, setExportModalTimeFilterVal] = useState({
    from: 0,
    to: 0,
  });

  const handleDownloadCSV = () => {
    // requesting file for download
    const { branch_ids, filter } = filters;
    const { from, to } = exportModalTimeFilterVal;

    if (socketExportStatus === "failed") setSocketExportStatus("default");
    setDownloadButtonStatus("loading");

    exportCustomerRedemptionsCSV(token, {
      branch_ids,
      filter,
      from,
      to,
    })
      .then((res) => {
        if (res.status === 200) setDownloadButtonStatus("loading");
        else setDownloadButtonStatus("default");
      })
      .catch((e) => {
        setDownloadButtonStatus("default");
        setSocketExportStatus("failed");
      });
  };

  // handling file download
  useEffect(() => {
    const socket = pusher(token, storeData?.id as string);
    const channel = socket.subscribe(`presence-file_export.${storeData?.id}`);

    // binding events
    channel.bind(`file_export_redemptions_${token}_success`, (res) => {
      const { file_path } = res;
      if (file_path) {
        const anchorEl = document.createElement("a");
        anchorEl.href = `${process.env.REACT_APP_WAREHOUSE_ENDPOINT}${file_path}`;
        anchorEl.download = "true";
        anchorEl.click();

        setTimeout(() => {
          setExpCSVModalVisible(false);
          setDownloadButtonStatus("disabled");
          setSocketExportStatus("default");
        }, 3000);
      }
    });

    channel.bind(`file_export_redemptions_${token}_failed`, (e) => {
      setDownloadButtonStatus("default");
      setSocketExportStatus("failed");
    });
  }, []);

  const handleModalTimeFilterChange = (value, isExportTimeRangeValid) => {
    const { from, to } = value;
    setDownloadButtonStatus(() =>
      isExportTimeRangeValid ? "default" : "disabled"
    );
    if (isExportTimeRangeValid) setExportModalTimeFilterVal({ from, to });
  };

  useEffect(() => {
    if (!isExpCSVModalVisible) {
      setDownloadButtonStatus("disabled");
      setSocketExportStatus("default");
    }
  }, [isExpCSVModalVisible]);

  // default of last 28 days
  const today = new Date();
  const now = new Date().getTime();
  const timestampOfADay = 60 * 60 * 24 * 1000;

  const defaultFrom = new Date(
    today.getTime() - 28 * timestampOfADay
  ).getTime();
  const defaultTo = now;

  return (
    <div className={styles.pageWrapper}>
      {/* download csv modal */}
      <ExportCSVModal
        initialTimeFilterVal={{
          from: filters.from || defaultFrom,
          to: filters.to || defaultTo,
        }}
        isVisible={isExpCSVModalVisible}
        onCancel={() => {
          setExpCSVModalVisible(false);
          setDownloadButtonStatus("disabled");
        }}
        onTimeFilterChange={handleModalTimeFilterChange}
      >
        {socketExportStatus === "failed" && (
          <Alert
            style={{ width: "100%", marginBottom: "10px" }}
            message="File Export Failed"
            type="error"
          />
        )}
        <CSVButton
          onClick={handleDownloadCSV}
          buttonStatus={downloadButtonStatus}
          style={{ width: "504px", height: "48px" }}
        >
          {socketExportStatus === "failed" ? "Try Again" : "Download CSV file"}
        </CSVButton>
      </ExportCSVModal>
      {/* heading */}
      <div className={styles.heading}>
        <span className={styles.headingText}>{t(Strings.redemptions)}</span>
      </div>

      {topRedeemedGiftsStatus === "success" && topRedeemedGifts.length > 0 && (
        <div className={styles.cardWrapper}>
          {topRedeemedGifts.map((gift, i) => (
            <RedeemedGiftCart
              rank={i + 1}
              name={gift.name}
              image={""}
              price={gift.price}
              redemptionsCount={gift.total_redemptions}
              title={t(Strings.redemptions)}
            />
          ))}
        </div>
      )}

      <div className={styles.tableWrapper}>
        <div className={styles.tableHead}>
          <div className={styles.tableHeadRowOne}>
            <div className={styles.title}>
              {totalRedemptionsStatus === "success" &&
                t(Strings.all_redemptions, {
                  redemption_counts: numberFormatter(
                    Number(totalRedemptions?.total)
                  ),
                })}
            </div>
            <div className={styles.searchAndActions}>
              <div className={styles.search}>
                <Input
                  className="insights-header-search-input"
                  size="small"
                  placeholder={t(Strings.search)}
                  value={filterSearchText}
                  onPressEnter={applySearchFilter}
                  onChange={(e) => setFilterSearchText(e.target.value)}
                  disabled={searchInputLoading}
                  suffix={
                    searchInputLoading && <Spin indicator={CustomSpinner} />
                  }
                  prefix={SearchIconJSX}
                />
              </div>
              <div className={styles.export}>
                <ExportCSVButton
                  onClick={() => setExpCSVModalVisible(true)}
                  buttonStatus={"default"}
                  icon={DownloadIconJSX}
                  style={{ height: "55px" }}
                >
                  {t(Strings.export_csv)}
                </ExportCSVButton>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className={styles.filtersRow}>
          <div>
            <MultiSelect
              label={t(Strings.branch)}
              canSelectAll={true}
              onApply={handleBranchFilterMultiSelectChange}
              options={branchesOptions}
              selected={
                filters.branch_ids && filters.branch_ids.length > 0
                  ? mapStringArryToSelectedArray(filters.branch_ids || [])
                  : allBranches.map((b) => ({ id: b.id }))
              }
            />
          </div>

          <div className={styles.input} style={{ marginRight: "3rem" }}>
            <TimeFilter
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 0,
                  from: value.from,
                  to: value.to,
                  unit: value.unit,
                }))
              }
            />
          </div>
        </div>
        {/*  */}
        <div className={styles.tableBody} id="customer-list">
          <Table
            columns={columns(currency)}
            rowClassName={"insights-table-row"}
            pagination={false}
            loading={
              status === "loading" && filters.page === 0
                ? { indicator: CustomSpinner }
                : false
            }
            dataSource={redemptionData}
            onRow={(row) => ({
              onClick: () => {
                setCustomerPopupId(row.customer_id);
              },
            })}
          />

          {
            <div
              ref={listPaginationLoaderRef}
              className={`${isMore && styles.tableLoader}`}
            >
              {isMore && <Spin indicator={CustomSpinner} />}
            </div>
          }

          {customerPopup && customerPopupId && (
            <ProfileModal
              customer={{ id: customerPopupId }}
              close={() => {
                setCustomerPopupId(undefined);
              }}
              modal={customerPopup}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RedemptionInsightsPage;
