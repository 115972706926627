import * as React from "react";
import styles from "./styles.module.sass";
import { SelectItem } from "./SelectItem";
import { change, WrappedFieldProps } from "redux-form";
import SearchIcon from "@material-ui/icons/Search";
import { translate, InjectedTranslateProps } from "react-i18next";
import * as Strings from "../../../../i18n/strings/MultipleSelect";
import { GenericButtonWithoutIcon } from "../../SharedComponent/GenericButtonWithoutIcon";
import history from "../../../../helpers/history";
import { useRtlClass } from "../../../../lib";
import { useDispatch } from "react-redux";
import { editBranchStatues } from "../../../../redux-store/actions";
import { ConfirmationPopup } from "../ConfirmationPopup/index";
import { submitPickupBranches } from "../../redux-store/actions/pickupBranchesActions";
import { useSelect } from "../../../../hooks/useSelect";
import { PickupStatus } from "../../../../types/shared";
import { useDeepCompareMemo } from "use-deep-compare";
import { useCanUseDriveThruOrdering } from "../../../../hooks/use-can-use-drive-thru-ordering";

interface IMultipleSelectItemData {
  name: string;
  value: string;
  pos_vendor?: string;
}

interface IMultipleSelectProps extends WrappedFieldProps {
  items: IMultipleSelectItemData[];
}

//

const MultipleSelect: React.FC<
  IMultipleSelectProps & InjectedTranslateProps
> = ({ items, input: { value = {}, onChange }, t }) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [activeButton, setActiveButton] = React.useState(false);
  const canUseDriveThruOrdering = useCanUseDriveThruOrdering()
  const dispatch = useDispatch();
  const branchNumber = React.useMemo(() => {
    const selectedItemsIds = Object.values(value)
      .map((itemsMap: any) => {
        return Object.keys(itemsMap).filter((key) => itemsMap[key]);
      })
      .reduce((acc, next) => [...acc, ...next], []);
    const uniqueIds = new Set(selectedItemsIds);
    return uniqueIds.size;
  }, [value]);

  const setDriveThruStatus = (params) => {
    return canUseDriveThruOrdering ? { ...params } : {}
  }
  // branchNumber?setIsDisableNext(false):setIsDisableNext(true);
  const handleChange = (
    item: IMultipleSelectItemData,
    type: string,
    newVal: boolean
  ) => {
    let payload: any = {};
    if (type === "Pickup") {
      console.log("newVal", newVal, payload.drive_thru_branch_ids);
      payload = {
        pickup_branch_ids: {
          ...value.pickup_branch_ids,
          [item.value]: newVal,
        },
        ...(!newVal && canUseDriveThruOrdering && {
          drive_thru_branch_ids: {
            ...value.drive_thru_branch_ids,
            [item.value]: newVal,
          },
        })
      };
      dispatch(
        editBranchStatues({
          delivery_branches_ids: {
            ...payload.delivery_branch_ids,
          },
          pickup_branches_ids: {
            ...value.pickup_branches_ids,
            [item.value]: newVal,
          },
          ...setDriveThruStatus({
            drive_thru_branch_ids: {
              ...payload.drive_thru_branch_ids,
            }
          })
          ,
        })
      );
    } else if (type === "DriveThru") {
      payload = {
        drive_thru_branch_ids: {
          ...value.drive_thru_branch_ids,
          [item.value]: newVal,
        },
      }
      dispatch(
        editBranchStatues({
          drive_thru_branch_ids: {
            ...value.drive_thru_branch_ids,
            [item.value]: newVal,
          },
          pickup_branches_ids: {
            ...payload.pickup_branch_ids,
          },
          delivery_branches_ids: {
            ...payload.delivery_branch_ids,
          },
        })
      );
    } else if (type === "Delivery") {
      payload = {
        delivery_branch_ids: {
          ...value.delivery_branch_ids,
          [item.value]: newVal,
        },
      };
      dispatch(
        editBranchStatues({
          delivery_branches_ids: {
            ...value.delivery_branch_ids,
            [item.value]: newVal,
          },
          pickup_branches_ids: {
            ...payload.pickup_branch_ids,
          },
          ...setDriveThruStatus({
            drive_thru_branch_ids: {
              ...payload.drive_thru_branch_ids,
            }
          })
        })
      );
    }
    onChange({
      ...value,
      ...payload,
    });
  };

  const contains = (str: string, strToSearchIn: string): boolean =>
    strToSearchIn.toLowerCase().includes(str.toLowerCase());
  const filterItems = (
    collection: IMultipleSelectItemData[],
    searchValue: string
  ) =>
    collection.filter((item) =>
      contains(searchValue, item.name ? item.name : "")
    );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const searchedItems = React.useMemo(() => {
    return filterItems(items, searchQuery);
  }, [searchQuery, items]);
  React.useMemo(() => {
    branchNumber ? setActiveButton(true) : setActiveButton(false);
  }, [branchNumber]);

  const nextHandler = () =>
    history.push(`/pickup/setup/pickup-branches/complete-branches`);

  const rtl = useRtlClass(styles);
  const [disableConfirmationPopup, setDisableConfirmationPopup] =
    React.useState(false);
  const handleOpenDisableConfirmationPopup = () =>
    setDisableConfirmationPopup(true);
  const handleCloseDisableConfirmationPopup = () =>
    setDisableConfirmationPopup(false);
  const { status: submitOrderingBranchesStatus, branches } = useSelect(
    (state) => ({
      status: state.submitPickupBranchesReducer.status,
      branches: state.branchesReducer.defaultBranches,
    })
  );

  const allBranchesDisabled = useDeepCompareMemo(
    () =>
      (branches || []).every(
        (branch) =>
          branch.pickup_status === PickupStatus.service_disabled &&
          branch.delivery_status === PickupStatus.service_disabled
      ),
    [branches]
  );

  React.useEffect(() => {
    if (submitOrderingBranchesStatus === "success") {
      handleCloseDisableConfirmationPopup();
    }
  }, [submitOrderingBranchesStatus]);
  const disableAllBranches = () => {
    if (activeButton && allBranchesDisabled) {
      dispatch(
        change("PickupBranchesForm", "selectedBranchesMap", {
          pickup_branch_ids: {},
          delivery_branch_ids: {},
          ...setDriveThruStatus({
            drive_thru_branch_ids: {}
          })
        })
      );
      handleCloseDisableConfirmationPopup();
    } else {
      dispatch(
        submitPickupBranches({
          delivery_branches_ids: [],
          pickup_branches_ids: [],
          ...setDriveThruStatus({
            drive_thru_branch_ids: []
          })
        })
      );
    }
  };
  return (
    <div className={`${styles.main} ${rtl}`}>
      <div className={styles.wrapper}>
        <div className={styles.searchWrapper}>
          <input
            type="text"
            onChange={handleInputChange}
            placeholder={t(Strings.searchPlaceholder)}
          />
          <SearchIcon nativeColor="#b2b2b2" />
        </div>
        <div className={styles.headerWrapper}>
          <span className={styles.branchName}>{t(Strings.branchName)}</span>
          <div style={{
            justifyContent: canUseDriveThruOrdering ? "space-evenly" : "space-around"
          }} className={styles.typeNameWrapper}>
            <span className={styles.typeName}>Pickup</span>
            {canUseDriveThruOrdering && (
              <span className={styles.typeName}>
                Drive Thru
              </span>
            )}
            <span className={styles.typeName}>Delivery</span>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.notFoundBranches}>
          {searchQuery && items.length > 0 && !searchedItems.length && (
            <span className={styles.notExistItem}>
              {t(Strings.notExistItem)}
            </span>
          )}
        </div>
        <div className={styles.branches}>
          {!!searchedItems.length &&
            searchedItems?.map((item) => (
              <SelectItem
                key={item.value}
                item={item}
                activeDriveThru={value?.drive_thru_branch_ids?.[item.value]}
                activePickup={value?.pickup_branch_ids?.[item.value]}
                activeDelivery={value?.delivery_branch_ids?.[item.value]}
                onClick={handleChange}
                pos_vendor={item.pos_vendor}
              />
            ))}
          {/* value.pickup_branch_ids[item.value] === undefined? false: */}
          {/* { value.delivery_branch_ids[item.value] === undefined? false:} */}
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <div className={styles.countWrapper}>
          <span className={styles.count}>{branchNumber}</span>
          <span className={styles.branchSelected}>
            {t(Strings.branchSelected)}
          </span>
        </div>
        <div className={styles.buttonsWrapper}>
          {disableConfirmationPopup && (
            <ConfirmationPopup
              loading={submitOrderingBranchesStatus === "loading"}
              description={t(Strings.disableOrderingQ)}
              open={disableConfirmationPopup}
              onClose={handleCloseDisableConfirmationPopup}
              onConfirm={disableAllBranches}
              title={t(Strings.disableOrdering)}
              warningIcon={require("../../../../assets/warning2.svg").default}
            />
          )}
          <GenericButtonWithoutIcon
            label={t(Strings.disableAll)}
            className={activeButton ? styles.disableBtn : styles.disabled}
            onClick={handleOpenDisableConfirmationPopup}
            disabled={!activeButton}
          />
          <GenericButtonWithoutIcon
            label={t(Strings.next)}
            className={`${styles.btn} ${activeButton ? styles.activBtn : styles.unactivBtn
              }`}
            onClick={nextHandler}
            disabled={!activeButton}
          />
        </div>
      </div>
    </div>
  );
};

export default translate("MultipleSelect")(MultipleSelect);
