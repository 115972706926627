import { call, put, select, takeLatest } from "redux-saga/effects";
import { addBannerCardAction } from "../../constants";
import {
  addBannerCardFailure,
  addBannerCardSuccess,
  addBannerCard,
  resetBannerCardsState,
} from "../actions/brand-visuals-actions";
import { addBannerCardApi } from "../../axios/banner-cards";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { notify } from "react-notify-toast";
import strings from "../../i18n/strings/brandVisuals";
import { translate } from "../../helpers/translate";
const actionTypes = union({ addBannerCard });

function* addBannerCardSaga({ payload }: typeof actionTypes) {
  const t = translate("brandVisuals");
  try {
    const token = yield select(selectToken);
    const res = yield call(addBannerCardApi, token, payload);
    yield put(addBannerCardSuccess(res.data));
    notify.show(t(strings.bannerCardAddedSuccessfully), "success");
  } catch (error) {
    notify.show(t(strings.bannerCardAddedFailed), "error");
    yield put(addBannerCardFailure(error as Error));
  }
}

export default function* watchAddBannerCardSaga() {
  yield takeLatest(addBannerCardAction.requested, addBannerCardSaga);
}
