export default {
  paymentTitle: "اختر طرق دفع العميل",
  paymentContent: "اختر طريقة الدفع التي تريد إتاحتها للعملاء ",
  cashOnly: "كاش فقط",
  cashOnlyContent: "العملاء سيدفعون كاش فقط",
  cashAndCard: "الكاش و البطاقات",
  cashAndCardContent: "العملاء سيدفعون كاش او ببطاقات الائتمان",
  cardOnly: "البطاقات فقط",
  cardOnlyContent: "العملاء سيقومون بالدفع عن طريق بطاقات الائتمان فقط",
};
