import * as constants from "../../constants";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";
import {
  IAddBranchAction,
  IAddBranchFailureAction,
  IAddBranchSuccessAction,
  IDeleteBranchAction,
  IDeleteBranchFailureAction,
  IDeleteBranchSuccessAction,
  IEditBranchAction,
  IEditBranchFailureAction,
  IEditBranchStatuesAction,
  IEditBranchSuccessAction,
  IEditQRPortalBranchStatus,
  IGetBranchesSuccessAction,
  ISearchBranchesAction,
  OptimisticLoading,
} from "../../types/wizard-types";
import { IBranch, PickupStatus } from "../../types/shared";
import {
  constructLoadingReducer,
  ILoadingReducerState,
} from "./constructLoadingReducer";
import { editBranchZonesAction } from "../../Components/Pickup/constants/actions";
import { LoadingStatus } from "../../Components/Pickup/redux-store/reducers/withLoadingState";

const BRANCHES = "branches";
const ALL_BRANCHES = "allBranches";
const SEARCH_VALUE = "searchValue";
const IS_CALL_CENTER = "isCallCenter";
const LOADING_STATUS = "LOADING_STATUS";
const GET_BRANCHES_LOADING_STATUS = "GET_BRANCHES_LOADING_STATUS";
const EDITING_STATUS = "EDITING_STATUS";
const DEFAULT_BRANCHES = "defaultBranches";
export interface IBranchesReducerState extends ILoadingReducerState {
  [DEFAULT_BRANCHES]: IBranch[];
  [BRANCHES]: IBranch[];
  [ALL_BRANCHES]: IBranch[];
  [SEARCH_VALUE]: string;
  [IS_CALL_CENTER]: boolean;
  [LOADING_STATUS]: LoadingStatus;
  [GET_BRANCHES_LOADING_STATUS]: LoadingStatus;
  [EDITING_STATUS]: LoadingStatus | undefined;
}

const contains = (str: string, strToSearchIn: string): boolean =>
  strToSearchIn.toLowerCase().includes(str.toLowerCase());

const filterBranches = (branches: IBranch[], searchValue: string) => {
  if (searchValue) {
    return branches.filter((branch) =>
      contains(searchValue, branch?.name || "")
    );
  }
  return branches;
};

const initialState: IBranchesReducerState = Map({
  [BRANCHES]: [],
  [ALL_BRANCHES]: [],
  [SEARCH_VALUE]: "",
  [IS_CALL_CENTER]: false,
  [LOADING_STATUS]: LoadingStatus.loading,
  [GET_BRANCHES_LOADING_STATUS]: LoadingStatus.loading,
  [EDITING_STATUS]: undefined,
  [DEFAULT_BRANCHES]: [],
}).toJS();

type branchActions =
  | IGetBranchesSuccessAction
  | IAddBranchFailureAction
  | IAddBranchSuccessAction
  | IAddBranchAction
  | IEditBranchAction
  | IEditBranchFailureAction
  | IEditBranchSuccessAction
  | IDeleteBranchFailureAction
  | IDeleteBranchAction
  | IDeleteBranchSuccessAction
  | ISearchBranchesAction
  | IEditBranchStatuesAction
  | IEditQRPortalBranchStatus;

const branchesReducer = (
  state: IBranchesReducerState,
  action: branchActions
): IBranchesReducerState => {
  switch (action.type) {
    case constants.getBranchesAction.requested: {
      return mutateState(state, (map) => {
        map.set(GET_BRANCHES_LOADING_STATUS, LoadingStatus.loading);
      });
    }
    case constants.ACTION_EDIT_QR_PORTAL_BRANCH_STATUS: {
      return mutateState(state, (map) => {
        const { id, status, type } = (action as IEditQRPortalBranchStatus)
          .payload;
        const branches = state[ALL_BRANCHES].map((branch) =>
          branch.id === id
            ? type === "dine_in"
              ? { ...branch, qr_dine_in_status: status }
              : { ...branch, qr_take_away_status: status }
            : branch
        );
        map.set(BRANCHES, branches);
        map.set(ALL_BRANCHES, branches);
      });
    }
    case constants.editBranchStatuesAction: {
      return mutateState(state, (map) => {
        const branches = state[ALL_BRANCHES].map((b) => {
          return {
            ...b,
            pickup_status:
              (action as IEditBranchStatuesAction).payload.pickup_branches_ids[
                b?.id
              ] === true
                ? PickupStatus.service_enabled
                : b.pickup_status
                  ? b.pickup_status
                  : PickupStatus.service_disabled,
            delivery_status:
              (action as IEditBranchStatuesAction).payload
                .delivery_branches_ids[b?.id] === true
                ? PickupStatus.service_enabled
                : b.delivery_status
                  ? b.delivery_status
                  : PickupStatus.service_disabled,
            drive_thru_status:
              typeof (action as IEditBranchStatuesAction).payload
                .drive_thru_branches_ids?.[b?.id] === "boolean"
                ? (action as IEditBranchStatuesAction).payload
                  .drive_thru_branches_ids[b?.id] === true
                  ? PickupStatus.service_enabled
                  : PickupStatus.service_disabled
                : b.drive_thru_status,
          };
        });
        map.set(BRANCHES, branches);
        map.set(ALL_BRANCHES, branches);
      });
    }
    case constants.getBranchesAction.requested: {
      return mutateState(state, (map) => {
        map.set(GET_BRANCHES_LOADING_STATUS, LoadingStatus.loading);
      });
    }
    case constants.getBranchesAction.fulfilled: {
      return mutateState(state, (map) => {
        const branches = (action as IGetBranchesSuccessAction).payload
          .filter((c) => !c.call_center)
          .sort((a, b) => a.name?.localeCompare(b.name));
        map.set(
          IS_CALL_CENTER,
          (action as IGetBranchesSuccessAction).payload.length !==
          branches.length
        );
        map.set(BRANCHES, branches);
        map.set(ALL_BRANCHES, branches);
        map.set(DEFAULT_BRANCHES, branches);
        map.set(GET_BRANCHES_LOADING_STATUS, LoadingStatus.success);
      });
    }
    case constants.ACTION_SEARCH_BRANCHES: {
      return mutateState(state, (map) => {
        const searchValue = (action as ISearchBranchesAction).payload;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        map.set(SEARCH_VALUE, searchValue);
        map.set(BRANCHES, filterBranches(oldBranches, searchValue));
      });
    }
    case constants.clearPrevStateAction: {
      return mutateState(state, (map) => {
        map.set(LOADING_STATUS, LoadingStatus.loading);
        map.set(EDITING_STATUS, undefined);
      });
    }
    case constants.addBranchAction.requested: {
      return mutateState(state, (map) => {
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const searchValue = map.get(SEARCH_VALUE) as string;
        map.set(ALL_BRANCHES, [action.payload, ...oldBranches]);
        map.set(
          BRANCHES,
          filterBranches([action.payload as any, ...oldBranches], searchValue)
        );
        map.set(LOADING_STATUS, LoadingStatus.loading);
      });
    }
    case constants.addBranchAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IAddBranchFailureAction;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const searchValue = map.get(SEARCH_VALUE) as string;
        const newBranches = oldBranches.filter(
          (item) => item.optimisticId !== payload.optimisticId
        );
        map.set(ALL_BRANCHES, newBranches);
        map.set(BRANCHES, filterBranches(newBranches, searchValue));
        map.set(LOADING_STATUS, LoadingStatus.failed);
      });
    }
    case constants.addBranchAction.fulfilled: {
      return mutateState(state, (map) => {
        const { payload } = action as IAddBranchSuccessAction;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const searchValue = map.get(SEARCH_VALUE) as string;
        const newBranches = oldBranches.map((item) =>
          item.optimisticId === payload.optimisticId ? payload : item
        );
        map.set(ALL_BRANCHES, newBranches);
        map.set(BRANCHES, filterBranches(newBranches, searchValue));
        map.set(LOADING_STATUS, LoadingStatus.success);
      });
    }
    case constants.deleteBranchAction.requested: {
      return mutateState(state, (map) => {
        const { payload } = action as IDeleteBranchAction;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const newBranches = oldBranches.filter((i) => i.id !== payload.id);
        const searchValue = map.get(SEARCH_VALUE) as string;
        map.set(BRANCHES, filterBranches(newBranches, searchValue));
        map.set(ALL_BRANCHES, newBranches);
      });
    }
    case constants.deleteBranchAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IDeleteBranchFailureAction;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        map.set(ALL_BRANCHES, [payload, ...oldBranches]);
        const searchValue = map.get(SEARCH_VALUE) as string;
        map.set(
          BRANCHES,
          filterBranches([payload, ...oldBranches], searchValue)
        );
      });
    }
    case constants.editBranchAction.rejected: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditBranchFailureAction;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const newBranches = oldBranches.map((branch) => {
          if (branch.id === payload.id) {
            return branch.fallBack;
          }
          return branch;
        });
        const searchValue = map.get(SEARCH_VALUE) as string;
        map.set(BRANCHES, filterBranches(newBranches, searchValue));
        map.set(ALL_BRANCHES, newBranches);
        map.set(EDITING_STATUS, LoadingStatus.failed);
      });
    }
    case constants.editBranchAction.requested:
    case editBranchZonesAction.submitting: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditBranchAction;

        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const oldBranchData = oldBranches.filter(
          (b) => b.id === payload.id
        )[0] as IBranch;
        const newBranches: any = oldBranches.map((branch) => {
          if (branch.id === payload.id) {
            return {
              ...oldBranchData,
              ...payload,
              state: OptimisticLoading,
              fallBack: oldBranchData,
            };
          }
          return branch;
        });
        const searchValue = map.get(SEARCH_VALUE) as string;
        map.set(ALL_BRANCHES, newBranches);
        map.set(BRANCHES, filterBranches(newBranches, searchValue));
        map.set(EDITING_STATUS, LoadingStatus.loading);
      });
    }
    case constants.editBranchAction.fulfilled: {
      return mutateState(state, (map) => {
        const { payload } = action as IEditBranchSuccessAction;
        const oldBranches = map.get(ALL_BRANCHES) as IBranch[];
        const newBranches = oldBranches.map((branch) => {
          if (branch.id === payload.id) {
            return { ...branch, ...payload };
          }
          return branch;
        });
        const searchValue = map.get(SEARCH_VALUE) as string;
        map.set(BRANCHES, filterBranches(newBranches, searchValue));
        map.set(ALL_BRANCHES, newBranches);
        map.set(EDITING_STATUS, LoadingStatus.success);
      });
    }
    default:
      return state;
  }
};

export default constructLoadingReducer(
  constants.getBranchesAction,
  branchesReducer as any,
  initialState
);
