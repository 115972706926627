import { action, payload } from "ts-action";
import {
  getStoreViralitySettingsAction,
  updateStoreViralitySettingsAction,
} from "../../../constants";
import { IStoreViralitySettings } from "../../../types/wizard-types";

export const getStoreViralitySettings = action(
  getStoreViralitySettingsAction.requested
);

export const getStoreViralitySettingsSuccess = action(
  getStoreViralitySettingsAction.fulfilled,
  payload<IStoreViralitySettings>()
);
export const getStoreViralitySettingsFailure = action(
  getStoreViralitySettingsAction.rejected,
  payload<Error>()
);

export const updateStoreViralitySettings = action(
  updateStoreViralitySettingsAction.requested,
  payload<IStoreViralitySettings>()
);
export const updateStoreViralitySettingsSuccess = action(
  updateStoreViralitySettingsAction.fulfilled,
  payload<IStoreViralitySettings>()
);
export const updateStoreViralitySettingsFailure = action(
  updateStoreViralitySettingsAction.rejected,
  payload<Error>()
);
