import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteSpecialItemAction } from "../../constants";
import { IDeleteSpecialItemAction } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { deleteSpecialItem } from "../../axios/deleteSpecialItem";
import { delay } from "redux-saga/effects";

function* deleteSpecialItemSaga(action: IDeleteSpecialItemAction) {
  try {
    const token = yield select(selectToken);
    yield call(deleteSpecialItem, token, action.payload.id);
    yield put(actions.deleteSpecialItemSuccess(action.payload));
  } catch (e) {
    yield delay(1000);
    yield put(actions.deleteSpecialItemFailure(action.payload));
  }
}

export function* watchDeleteSpecialItemSaga() {
  yield takeLatest(deleteSpecialItemAction.requested, deleteSpecialItemSaga);
}
