import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getCustomerInfoAction } from "../../constants/actions";
import { ICustomerInfo } from "../actions/lib";
import { getCustomerInfoSuccess, uploadCustomerPoints, uploadCustomerPointsFailure, uploadCustomerPointsIdle, uploadCustomerPointsSuccess } from "../actions/customer-actions";

interface IState {
  customerInfo: ICustomerInfo;
  uploadBulkCustomerPoints: {
    status: "idle" | "loading" | "error" | "success"
  };
}

const initialState: IState = {
  customerInfo: {
    customer: {
      image: "",
      store_image: "",
      app_version: "",
      birth_date: 0,
      country_code: "",
      created_at: 0,
      email: "",
      id: "",
      last_active_time: 0,
      name: "",
      password: "",
      phone_number: "",
      shopxId: "",
      update_data_screen_status: 0,
      updated_at: 0,
      area: {
        ArName: "",
        EnName: "",
        code: "",
      },
      country: {
        ArName: "",
        EnName: "",
        ISO_code: "",
        country_code: "",
        last_city_code: 0,
        pickup_status: "",
      },
      government: {
        ArName: "",
        EnName: "",
        address_by_google: "",
        code: "",
        latitude: 0,
        longitude: 0,
        rocket_code: "",
      },
    },
    total_orders: 0,

  },
  uploadBulkCustomerPoints: {
    status: "idle",
  }

};

export const getCustomerInfoReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getCustomerInfoSuccess, (state: {}, { payload }) => ({
        ...state,
        customerInfo: { ...payload },
      })),
      on(uploadCustomerPoints, (state: {}, { payload }) => ({
        ...state,
        uploadBulkCustomerPoints: {
          status: "loading",
        }
      })), on(uploadCustomerPointsSuccess, (state: {}, { payload }) => ({
        ...state,
        uploadBulkCustomerPoints: {
          status: "success",
        }
      })),
      on(uploadCustomerPointsFailure, (state: {}, { payload }) => ({
        ...state,
        uploadBulkCustomerPoints: {
          status: "error",
        }
      })),
      on(uploadCustomerPointsIdle, (state: {}) => ({
        ...state,
        uploadBulkCustomerPoints: {
          status: "idle",
        }
      })),

    ] as any,
    initialState
  ),
  getCustomerInfoAction
);
