import { action } from "ts-action";
import {
  ACTION_TOGGLE_1,
  ACTION_TOGGLE_2,
  ACTION_TOGGLE_3,
  ACTION_TOGGLE_4,
  ACTION_TOGGLE_5,
  ACTION_RESET_COLLAPSE,
} from "../../constants";
export const toggle1 = action(ACTION_TOGGLE_1);
export const toggle2 = action(ACTION_TOGGLE_2);
export const toggle3 = action(ACTION_TOGGLE_3);
export const toggle4 = action(ACTION_TOGGLE_4);
export const toggle5 = action(ACTION_TOGGLE_5);
export const resetCollapse = action(ACTION_RESET_COLLAPSE);
