import {
  IGetFraudlentCustomerListSuccessAction,
  IGetFraudlentCustomerListFailureAction,
  IFraudlentCutomer,
  IFraudlentCustomerListAction,
  IRemoveFraudlentCustomerPointsSuccessAction,
  IRemoveFraudlentCustomerPointsFailureAction,
  IRemoveFraudlentCustomerPointsRequestedAction,
  IGetFraudlentCustomerListRequestedAction,
  IBlockFraudlentCustomerRequestedAction,
  IBlockFraudlentCustomerFailureAction,
  IBlockFraudlentCustomerSuccessAction,
  IReviewFraudlentCustomerRequestedAction,
  IReviewFraudlentCustomerFailureAction,
  IReviewFraudlentCustomerSuccessAction,
} from "../../types/wizard-types";
import * as constants from "../../constants";
import { notify } from "react-notify-toast";

interface IFraudlentListState {
  data: IFraudlentCutomer[];
  status: "loading" | "idle" | "success" | "error";
  hasMore: boolean;
}

const initialState: IFraudlentListState = {
  data: [],
  status: "idle",
  hasMore: true,
};

export const fraudlentCustomersReducer = (
  state: IFraudlentListState = initialState,
  action: IFraudlentCustomerListAction
) => {
  switch (action.type) {
    //get
    case constants.getFraudlentCustomerList.requested: {
      const { payload } = action as IGetFraudlentCustomerListRequestedAction;
      return {
        ...state,
        status: "loading",
        data: payload.page === 0 ? [] : [...state.data],
        hasMore: true,
      };
    }

    case constants.getFraudlentCustomerList.fulfilled: {
      const { payload } = action as IGetFraudlentCustomerListSuccessAction;
      return {
        data: [...state.data, ...payload.data],
        status: "success",
        hasMore: payload.hasMore,
      };
    }

    //remove
    case constants.removeFraudlentCustomerPoints.requested: {
      const { payload } =
        action as IRemoveFraudlentCustomerPointsRequestedAction;
      return { ...state, status: "loading" };
    }
    //block
    case constants.blockFraudlentCustomer.requested: {
      const { payload } = action as IBlockFraudlentCustomerRequestedAction;
      return { ...state, status: "loading" };
    }
    //review
    case constants.reviewFraudlentCustomer.requested: {
      const { payload } = action as IReviewFraudlentCustomerRequestedAction;
      return { ...state, status: "loading" };
    }

    // on reject
    case constants.removeFraudlentCustomerPoints.rejected:
    case constants.reviewFraudlentCustomer.rejected:
    case constants.getFraudlentCustomerList.rejected:
    case constants.blockFraudlentCustomer.rejected: {
      const { payload } = action as IRemoveFraudlentCustomerPointsFailureAction;
      notify.show(payload.response ? payload.response.data.message : "Something went wrong performing action", "error");
      return { ...state, status: "error" };
    }

    case constants.blockFraudlentCustomer.fulfilled: {
      const { payload } = action as IBlockFraudlentCustomerSuccessAction;
      notify.show(
        `${payload.state === "blocked"
          ? "customer has been blocked"
          : payload.state === "points-remove"
            ? "customer's points has been removed"
            : "customer has been reviewd "
        } sccessfully`,
        "success"
      );

      return {
        data: [
          ...state.data.map((item) => {
            if (item.phone_number === payload.phone_number) {
              return { ...item, state: payload.state };
            }
            return item;
          }),
        ],
        status: "success",
      };
    }
    case constants.reviewFraudlentCustomer.fulfilled: {
      const { payload } = action as IReviewFraudlentCustomerSuccessAction;
      notify.show(
        "customer has been reviewd sccessfully",
        "success"
      );
      return {
        data: [
          ...state.data.map((item) => {
            if (item.phone_number === payload.phone_number) {
              return { ...item, is_reviewed: payload.is_reviewed };
            }
            return item;
          }),
        ],
        status: "success",
      };
    }
    case constants.removeFraudlentCustomerPoints.fulfilled: {
      const { payload } = action as IRemoveFraudlentCustomerPointsSuccessAction;
      notify.show(
        `${payload.state === "blocked"
          ? "customer has been blocked"
          : payload.state === "points-remove"
            ? "customer's points has been removed"
            : "customer has been reviewd "
        } sccessfully`,
        "success"
      );
      return {
        data: [
          ...state.data.map((item) => {
            if (item.phone_number === payload.phone_number) {
              return { ...item, state: payload.state };
            }
            return item;
          }),
        ],
        status: "success",
      };
    }

    default:
      return state;
  }
};
