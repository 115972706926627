import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ICategory, IOrderedObject } from "../types/wizard-types";
import { IOptimisticObject } from "../types/shared";

export interface IEditCategory extends IOrderedObject, IOptimisticObject {
  name?: string;
  id: string;
  integartion?: any;
}

export const editCategory = (
  token: string,
  categoryToEdit: IEditCategory
): AxiosReturn<{ category: ICategory }> => {
  return axios.put(Requests.categoryByID(categoryToEdit.id), categoryToEdit, {
    headers: {
      token,
    },
  });
};
