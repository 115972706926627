import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ICategory } from "../types/wizard-types";

export const getCategories = (
  token
): AxiosReturn<{ categories: ICategory[] }> => {
  return axios.get(Requests.categories, {
    headers: {
      token,
    },
  });
};
