import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IFilters, IFraudlentCutomer } from "../types/wizard-types";


export const getFraudlentCustomers = (token: string, filters: IFilters): AxiosReturn<IFraudlentCutomer[]> => {
  return axios.get(Requests.getFraudlentCustomes, {
    baseURL: process.env.REACT_APP_DASHBOARD_ENDPOINT,
    headers: {
      token,
    },
    params: filters,
  });
};
