export default {
  email: "Email",
  enter_email: "Enter your email",
  invalid_email: "Invalid email",
  password: "Password",
  enter_password: "Enter your password",
  invalid_password: "Invalid password",
  log_in: "Log in",
  logging_in: "Logging in",
  quick_facts_about: "Quick facts about {{en_store_name}}",
  todays_progress: "Today's Progress",
  transactions: "Transactions",
  analyze_transactions: "Analyze transactions",
  analyze_redemptions: "Analyze redemptions",
  from_yesterday: "from yesterday",
  live_updates: "Live updates",
  new_customers: "New Customers",
  returning_customers: "Returning Customers",
  customer_segment: "Customer Segment",
  time: "Time: {{time}}",
  branch: "Branch",
  customer_type: "Customer type",
  customers: "Customers",
  orders: "Orders",
  redemptions: "Redemption",
  sales: "Sales",
  ordersTitle: "Orders",
  feedbackTitle: "Feedback",
  adsManagerTitle: "Ads Manager",
  insights_card_title: "Insights",
  see_more: "See more",
  less: "Less",
  new: "New: {{percentage}}",
  invoices: "Invoices: {{invoices}}",
  customers_chart_tooltip: "Customers: {{customers}}",
  orders_chart_tooltip: "Orders and transactions: {{orders}}",
  redemptions_chart_tooltip: "Redemptions: ${{redemptions}}",
  home: "Home",
  good_morning: "Good morning, {{user_name}}",
  good_afternoon: "Good afternoon, {{user_name}}",
  good_evening: "Good evening, {{user_name}}",
  overview: "Overview",
  customer_visits: "Customer visits",
  track_your_orders_progress: "Track your orders progress",
  manage_customer_satisfaction: "Manage customer satisfaction ",
  increase_your_customer_base: "Increase your customer base ",
  analyze_your_restaurant_data: "Analyze your restaurant data",
  analyze_feedback: "Analyze feedback",
  avg_ticket_size: "AVG ticket size",
  avg_ticket_size_with_price: "AVG ticket size: {{price}}",
  returning: "Returning: {{percentage}}",
  your_data_will_appear_here:
    "Your data will appear here you get more customers",
  most_of_your_customers_are: "Most of your customers are ",
  male_customer: "Male customer",
  female_customer: "Female customer",
  analyze_customers: "Analyze customers",
  view_order_details: "View order details",
  all_customers: "All customers",
  app_customers: "App customers",
  non_app_customers: "Non-app customers",
  search_branch_name: "Search branch name..",
  apply: "Apply",
  reset: "Reset",
  custom_time: "Custom time",
  all_time: "All-time",
  today: "Today",
  yesterday: "Yesterday",
  quarter_to_date: "Quarter to date",
  last_year: "Last year",
  select_all: "Select all",
  offline_transaction: "Offline transaction",
  offline_redemption: "Offline redemption",
  offline_visit: "Offline visit",
  delivery: "Delivery",
  pickup: "Pickup",
  total: "Total : {{total_price}} {{currency}}",
  customers_tab: "{{label}} {{percentage}}",
  sales_tab: "{{label}} {{percentage}}",
  redemptions_tab: "{{label}} {{percentage}}",
  orders_tab: "{{label}} {{percentage}}",
  males: "Males",
  females: "Females",
  age_group: "from ({{age_group}} years old)",
  returning_customers_count: "Returning customers count",
  new_customers_count: "New customers count",
  male_customers: "Male Customers",
  female_customers: "Female Customers",
  reviewed: "Reviewed {{order_type}}",
  redeemed: "Redeemed {{order_type}}",
  offline_order: "Offline order",
  delivery_order: "Delivery order",
  pickup_order: "Pickup order",
  ordered_through_web_portal: "Ordered through web portal",
  ordered_through_koinz_application: "Ordered through koinz application",
  ordered_through_visiting_the_restaurants:
    "Ordered through visiting the restaurants ",
  reviews: "Reviews",
  see_live_updates_about_your_customers_interactions_here:
    "See live updates about  your customers interactions here",
  offline: "Offline",
  online: "Online",
  you_have_a_new_transaction: "You have a new transaction",
  show_more: "Show more",
  show_less: "Show less",
  more_items: "{{count}} More items",
  all: "All",
  here_is_store_name_process: "Here is {{store_name}} progress...",
  store_performance: "{{store_name}} performance",
  last_date: "Last {{count}} {{unit}}",
  day: "Day",
  days: "Days",
  month: "Month",
  months: "Months",
  year: "Year",
  years: "Years",
  years_range: "Years Range",
  cancel: "Cancel",
  more_preset_ranges: "More preset ranges",
  customer_percentage_from_last_30_days:
    "{{customer_percentage}} customer from last 30 days",
  order_percentage_from_last_30_days:
    "{{order_percentage}} order from last 30 days",
  fulfillment_percentage_from_last_30_days:
    "{{fulfillment_percentage}} from last 30 days",
  redemption_percentage_from_last_30_days:
    "{{redemption_percentage}} redemption from last 30 days",
  sales_percentage_from_last_30_days:
    "{{sales_percentage}} sale from last 30 days",
  percentage_from_last_30_days: "{{percentage}} from last 30 days",
  ordersAndTransactions: "Orders and transactions",
  online_orders: "Online Orders",
  offline_orders: "Offline Orders",
  all_orders: "All Orders",
  pickup_orders: "Pickup Orders",
  delivery_orders: "Delivery Orders",
  web_portal_orders: "Web Portal Orders",
  offline_visits: "Offline visits",
  redemption_type: "Redemption type",
  order_type: "Order type",
  transaction_type: "Transaction type",
  customer_feedback: "Customers feedback",
  overall_rating: "Overall rating",
  unhappy_customers: "Unhappy customers",
  recent_reviews: "Recent reviews",
  branches_performance: "Branches Performance",
  branch_name: "Branch name",
  fulfillment_rate: "Fulfillment rate",
  acceptance_rate: "Acceptance rate",
  time_to_accept: "Time to accept",
  status: "Status",
  top_branches: "Top branches",
  lowest_branches: "Lowest branches",
  on_track: "On-track",
  off_track: "Off-track",
  analyze_branches: "Analyze branches",
  there_is_no_branches_available: "There’s no branches available",
  this_year: "This year (Jan - Today)",
  time_range_more_than_a_year_error:
    "Range is not valid (Larger than 365 days)",
  seconds: "{{seconds}} seconds",
  second: "a second",
  minute: "a minute",
  minutes: "{{minutes}} minutes",
  hour: "an hour",
  twoHours: "2 hours",
  hours: "{{hours}} hours",
  one_day: "a day",
  two_seconds: "2 seconds",
  two_minutes: "2 minutes",
  two_day: "2 days",
  days_duration: "{{days}} days",
  one_month: "a month",
  two_month: "2 months",
  months_duration: "{{months}} months",
  year_duration: "a year",
  two_years_duration: "2 years",
  years_duration: "{{years}} years",
  solveIssue: "Solve",
  netSales: "Net Sales",
};
