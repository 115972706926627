import axios from "../axios";
import { getPromotionsURL } from "../requests";

interface IParams {
    page: number;
    per_page: number;
    is_exclusive?: boolean;

}
export const getPromotionsAPI = (token: string, params: IParams) => {
    return axios.get(getPromotionsURL + `${params.is_exclusive ? "?vouchers_only" : ""}`, {
        headers: {
            token
        },
        baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
        params: {
            page: params.page,
            per_page: params.per_page
        }
    });
}