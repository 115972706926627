import styles from "./styles.module.sass";
import ArrowRight from "../../../../../../assets/feather _ chevron-right.svg";
import InsightsIcon from "../../../../../../assets/feather _ magaphone.svg";
import OrdersIcon from "../../../../../../assets/feather _ bag.svg";
import FeedbackIcon from "../../../../../../assets/feather _ message-square.svg";
import AdsManagerIcon from "../../../../../../assets/feather _ chart.svg";
import { CriticalReviewsNumber } from "../../../../../../lib";
import { useContext } from "react";
import { useHistory } from "react-router";
import { useUserAccess } from "../../../../../../Components/AuthenticatedRoutes";
import StoreData from "../../../../../../contexts/StoreData";
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from "antd";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
export const HomeNavigationCards: React.FC = () => {
  const { criticalNumbers } = useContext(CriticalReviewsNumber);
  const { push } = useHistory();
  const { hasPickUpEnabled } = useUserAccess();
  const { token, storeData } = useContext(StoreData);
  const { t } = useTranslation("home");
  return (
    <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
      {hasPickUpEnabled && (
        <Link to={"/pickup/setup/order-history"} className={styles.ordersCard}>
          <div className={styles.card}>
            <div className={styles.iconWrapper}>
              <img className={styles.icon} src={OrdersIcon} />
            </div>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>{t(Strings.ordersTitle)}</span>
              <span className={styles.subtitle}>
                {t(Strings.track_your_orders_progress)}
              </span>
            </div>
          </div>
          <img src={ArrowRight} className={styles.arrowRight} />
        </Link>
      )}

      <Link
        to={"/reviews"}
        className={!hasPickUpEnabled ? styles.ordersCard : styles.feedbackCard}
      >
        {" "}
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <img className={styles.icon} src={FeedbackIcon} />
          </div>
          <div className={styles.titleWrapper}>
            <div className={styles.feedbackWrapper}>
              <span className={styles.feedbackTitle}>
                {t(Strings.feedbackTitle)}{" "}
              </span>
              {!!criticalNumbers && (
                <div className={styles.feedback}>
                  <span className={styles.number}>+{criticalNumbers}</span>
                </div>
              )}
            </div>
            <span className={styles.subtitle}>
              {t(Strings.manage_customer_satisfaction)}
            </span>
          </div>
        </div>
        <img src={ArrowRight} className={styles.arrowRight} />
      </Link>
      <a
        target="_blank"
        href={`
            ${process.env.REACT_APP_KOINZ_REACH_URL}${
          JSON.parse(localStorage.getItem("isKoinzAdminRemote") as any)
            ? `/#/login?token=${token}&store_id=${storeData?.id}&is_remote_authentication=true`
            : `/#/login?token=${token}`
        }
        `}
        className={styles.adsManagerCard}
      >
        {" "}
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <img className={styles.icon} src={AdsManagerIcon} />
          </div>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{t(Strings.adsManagerTitle)}</span>
            <span className={styles.subtitle}>
              {" "}
              {t(Strings.increase_your_customer_base)}
            </span>
          </div>
        </div>
        <img src={ArrowRight} className={styles.arrowRight} />
      </a>
      <NavLink to={"/insights/transactions"} className={styles.insightsCard}>
        <div className={styles.card}>
          <div className={styles.iconWrapper}>
            <img className={styles.icon} src={InsightsIcon} />
          </div>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>
              {t(Strings.insights_card_title)}
            </span>
            <span className={styles.subtitle}>
              {t(Strings.analyze_your_restaurant_data)}
            </span>
          </div>
        </div>
        <img src={ArrowRight} className={styles.arrowRight} />
      </NavLink>
    </div>
  );
};

export default HomeNavigationCards;
