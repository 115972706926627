export default {
  changeItemCategory: "فشل تغيير صنف المنتج، جاري اعادة المحاولة...",
  createCategory: "فشل اضافة الصنف، جاري اعادة المحاولة...",
  createItem: "فشل اضافة المنتج، جاري اعادة المحاولة...",
  createModifierGroup: "فشل اضافة مجموعة الاضافات، جاري اعادة المحاولة...",
  deleteCategory: "فشل حذف الصنف، جاري اعادة المحاولة...",
  deleteItem: "فشل حذف المنتج، جاري اعادة المحاولة...",
  deleteSuggestedModifierGroup:
    "فشل حذف مجموعة الاضافات، جاري اعادة المحاولة...",
  editCategory: "فشل تعديل الصنف، جاري اعادة المحاولة...",
  editItem: "فشل تعديل المنتج، جاري اعادة المحاولة...",
  editModifierGroup: "فشل تعديل مجموعة الاضافات، جاري اعادة المحاولة...",
  reorderItem: "فشل اعادة ترتيب المنتج، جاري اعادة المحاولة...",
  reorderCategory: "فشل اعادة ترتيب الصنف، جاري اعادة المحاولة...",
};
