import { action, payload } from "ts-action";
import {
  ACTION_RESET_COMPETITION,
  getCompetitionAction,
} from "../../constants";
import { ICompetitionCreate } from "../../types";
export const getCompetition = action(
  getCompetitionAction.requested,
  payload<{ id: string; setForm?: boolean }>()
);
export const getCompetitionSuccess = action(
  getCompetitionAction.fulfilled,
  payload<ICompetitionCreate>()
);
export const getCompetitionFailure = action(
  getCompetitionAction.rejected,
  payload<Error>()
);
export const resetCompetition = action(ACTION_RESET_COMPETITION);
