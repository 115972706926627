import React from "react";
interface IProps {
  clicked: boolean;
}
const LeaderboardIcon = ({ clicked }: IProps) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbol"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/nav/cup">
          <g id="Rectangle" transform="translate(3.000000, 3.000000)">
            <g id="Mask" fill={clicked ? "#ffa351" : "#aeaeb2"}>
              <path
                d="M14.5197,7.42515 C14.5422,7.23015 14.5497,7.03515 14.5497,6.84015 L14.5497,2.92515 C15.5172,3.21765 16.21545,4.11015 16.21545,5.16765 C16.21545,6.23265 15.50295,7.14015 14.5197,7.42515 L14.5197,7.42515 Z M13.18545,6.84015 C13.18545,9.26265 11.21295,11.23515 8.7897,11.23515 C6.3672,11.23515 4.3947,9.26265 4.3947,6.84015 L4.3947,1.36515 L13.18545,1.36515 L13.18545,6.84015 Z M9.66045,15.31515 L7.9197,15.31515 L7.9197,12.53265 C8.20545,12.57765 8.4972,12.5994 8.7897,12.5994 C9.0897,12.5994 9.3747,12.57765 9.66045,12.53265 L9.66045,15.31515 Z M1.3647,5.16765 C1.3647,4.11015 2.0697,3.21765 3.0297,2.92515 L3.0297,6.84015 C3.0297,7.03515 3.0447,7.23015 3.0597,7.42515 C2.0847,7.14015 1.3647,6.23265 1.3647,5.16765 L1.3647,5.16765 Z M14.5497,1.52265 L14.5497,0.68265 C14.5497,0.30015 14.25045,0.00015 13.8672,0.00015 L3.7122,0.00015 C3.3372,0.00015 3.0297,0.30015 3.0297,0.68265 L3.0297,1.52265 C1.3122,1.84515 -0.0003,3.35265 -0.0003,5.16765 C-0.0003,7.11015 1.4997,8.70765 3.3972,8.86515 C3.6822,9.62265 4.1247,10.3194 4.7172,10.9119 C5.2572,11.4444 5.8797,11.86515 6.5547,12.15015 L6.5547,15.31515 L5.7147,15.31515 C5.3322,15.31515 5.0322,15.61515 5.0322,15.99765 C5.0322,16.37265 5.3322,16.67265 5.7147,16.67265 L11.87295,16.67265 C12.24795,16.67265 12.5547,16.37265 12.5547,15.99765 C12.5547,15.61515 12.24795,15.31515 11.87295,15.31515 L11.02545,15.31515 L11.02545,12.15015 C11.7072,11.86515 12.3297,11.4444 12.8622,10.9119 C13.4547,10.3194 13.90545,9.62265 14.19045,8.86515 C16.08795,8.70765 17.5797,7.11015 17.5797,5.16765 C17.5797,3.35265 16.27545,1.84515 14.5497,1.52265 L14.5497,1.52265 Z"
                id="Fill-1"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LeaderboardIcon;
