import { useQuery } from "react-query";
import {
  getBranchInsightsAPI,
  getBranchInsightsTotalAPI,
} from "../../../../axios/Manager/Analytics/Insights/branch";

export const useGetBranchInsights = (token, requestConfigs) => {
  const result = useQuery(
    ["customer-insights", requestConfigs],
    () => {
      return getBranchInsightsAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};

export const useGetBranchInsightsTotal = (token, requestConfigs) => {
  const result = useQuery(
    ["customer-insights-total", requestConfigs],
    () => {
      return getBranchInsightsTotalAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};
