import convertingRatio from "./convertingRatio";
export default {
  title: "Upload your menu by Excel sheet",
  uploading: "Uploading",
  checking: "Checking",
  error: "Error",
  completed: "Completed",
  uploadExcelFile: "Upload Excel File",
  downloadTemplate: "Download Menu Template",
  emptyFile: "File is empty",
  invalidFileFormat: "Invalid file format",
  missingData: "Missing data at row {{row}} column {{column}}",
  invalidDataType: "Data types at row number {{row}} are invalid",
  somethingWentWrong: convertingRatio.somethingWentWrong,
};
