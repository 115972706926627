import React, { useState } from "react";
import { userProfileAPI } from "../../../../../../axios/userProfile";
import { useTranslation } from "../../../../../../Components/WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import { useSelect } from "../../../../../../hooks/useSelect";
import i18n from "../../../../../../i18n/i18n";
import Strings from "../../../../../../i18n/Manager/strings/home";
const WelcomeMessage: React.FC = () => {
  const [userName, setUserName] = useState("");
  const { token } = useSelect((state) => state.authReducer);
  const { store } = useSelect((state) => state.storeReducer);
  //localization
  const { t } = useTranslation("home");
  //   based on current hour say hellow to the user

  const getUserAndSetName = async () => {
    const { data: user } = await userProfileAPI(token);
    setUserName(user.name);
  };

  React.useEffect(() => {
    getUserAndSetName();
  }, []);
  const getWelcomeMessage = React.useMemo(() => {
    const today = new Date();
    const currentHour = today.getHours();

    if (currentHour < 12) {
      return t(Strings.good_morning, {
        user_name: userName,
      });
    } else if (currentHour > 12 && currentHour < 18) {
      return t(Strings.good_afternoon, {
        user_name: userName,
      });
    } else if (currentHour < 18) {
      return t(Strings.good_evening, {
        user_name: userName,
      });
    }
  }, [userName]);
  return (
    <div style={{ marginBottom: "48px" }}>
      <span style={{ fontSize: 24, fontWeight: "bold" }}>
        {String.fromCodePoint(0x1f44b)} {getWelcomeMessage}
      </span>
      <span style={{ fontSize: 16 }}>
        {t(Strings.here_is_store_name_process, {
          store_name: i18n?.language?.includes("ar")
            ? store.ar_name
            : store.en_name,
        })}
      </span>
    </div>
  );
};

export default WelcomeMessage;
