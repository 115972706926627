import * as React from "react";
import styles from "./styles.module.sass";
import { InjectedTranslateProps } from "react-i18next";
import { hot } from "react-hot-loader";
import { translate } from "react-i18next";
import { ICashier } from "../../../../axios/getCashiers";
import { useNameInitials } from "../../../Reviews/ReviewResolution/Note";
import Icon from "@material-ui/core/Icon/Icon";
import { useCallback } from "react";
import Strings from "../../../../i18n/strings/cashiers";
import { useRtlClass } from "../../../../lib";
import DeleteSingleItemModal from "../../../DeleteSingleItemModal";
import { useToggleState } from "../../../../Utils";
import {
  TextOverflowToolTip,
  useTruncationFull,
} from "../../../Reviews/ReviewResolution/LatestActivities/LatestActivity";

interface IProps extends InjectedTranslateProps {
  cashier: ICashier;
  onClickViewCashier: (cashier: ICashier) => void;
  onClickEditCashier: (cashier: ICashier) => void;
  onDeleteCashier: (cashier: ICashier) => void;
}

interface INamedObj {
  name: string;
}

const Cashier: React.FunctionComponent<IProps> = ({
  cashier,
  onClickViewCashier,
  onDeleteCashier,
  onClickEditCashier,
  t,
}) => {
  const { name, phoneNumber, branches } = cashier;
  const initials = useNameInitials(name);
  const handleClickViewCashier = useCallback(
    () => onClickViewCashier(cashier),
    [cashier]
  );
  const handleClickEditCashier = useCallback(
    () => onClickEditCashier(cashier),
    [cashier]
  );
  const handleClickDeleteCashier = useCallback(() => onDeleteCashier(cashier), [
    cashier,
  ]);
  const rtlClass = useRtlClass(styles);
  const [isOpen, toggle] = useToggleState();
  const [disabled, setDisabled] = React.useState(false);
  const cashierBranches = React.useMemo(() => {
    const branchCollection: INamedObj[] = cashier.call_center
      ? [{ name: t(Strings.callCenterBranch) }]
      : branches;
    return branchCollection.reduce((acc, branch, index) => {
      const isNotLastItem = index !== branchCollection.length - 1;
      if (branch) {
        return acc + branch.name + (isNotLastItem ? ", " : "");
      }
      return acc;
    }, "");
  }, [cashier, branches]);
  const truncatedBranches = useTruncationFull(cashierBranches, 100);

  const tooltipId = React.useMemo(() => {
    return "branches-" + cashier.id;
  }, [cashier.id]);
  return (
    <div className={`${rtlClass}${styles.wrapper}`}>
      <DeleteSingleItemModal
        disabled={disabled}
        setDisabled={setDisabled}
        modal={isOpen}
        onClickDelete={handleClickDeleteCashier}
        toggle={toggle}
        text={t(Strings.deleteCashierDescription)}
        title={t(Strings.deleteCashier)}
        buttonText={t(Strings.confirmDelete)}
        cancelText={t(Strings.cancel)}
      />
      <div className={styles.userInfo}>
        <div className={styles.initials}>{initials}</div>
        <p className={styles.name}>{name}</p>
        {branches.length ? (
          ""
        ) : (
          <div className={styles.unassigned}>{t(Strings.unassigned)}</div>
        )}
      </div>

      <p className={`${styles.phone}`}>{phoneNumber}</p>
      <div className={styles.branch}>
        {branches.length ? (
          <>
            <TextOverflowToolTip
              maxLength={100}
              text={cashierBranches}
              target={"#" + tooltipId}
            />
            <Icon className={styles.icon}>location_on</Icon>
            <p id={tooltipId} className={styles.branchName}>
              {truncatedBranches}
            </p>
          </>
        ) : (
          <i>{t(Strings.unassigned)}</i>
        )}
      </div>
      <div className={styles.btnWrapper}>
        <button className={styles.btn} onClick={handleClickViewCashier}>
          <img
            className={styles.img}
            src={require("../../../../assets/icon-view.svg").default}
          />
        </button>
        <button className={styles.btn} onClick={handleClickEditCashier}>
          <img
            className={styles.img}
            src={require("../../../../assets/icon-edit.svg").default}
          />
        </button>
        <button className={styles.btn} onClick={toggle}>
          <img
            className={styles.img}
            src={require("../../../../assets/icon-delete.svg").default}
          />
        </button>
      </div>
    </div>
  );
};

export default hot(module)(translate("cashiers")(Cashier));
