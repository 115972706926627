import {
  ISignupAction,
  ISignupFailureAction,
  ISignupSuccessAction,
} from "../../types/wizard-types";
import { signupAction } from "../../constants";

export const signup = (token): ISignupAction => ({
  type: signupAction.requested,
  payload: token,
});
export const signupSuccess = (data): ISignupSuccessAction => ({
  type: signupAction.fulfilled,
  payload: data,
});
export const signupFailure = (error): ISignupFailureAction => ({
  type: signupAction.rejected,
  payload: error,
});
