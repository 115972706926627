import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getPastOrderFailure,
  getPastOrderSuccess,
  getPastOrders,
} from "../actions/past-order-actions";
import { selectToken } from "../../../../redux-store/selectors";
import { getPastOrderAPI } from "../../axios/getPastOrder";
import { union } from "ts-action";
import { getPastOrderAction } from "../../constants/actions";

const actionType = union({ getPastOrders } as any);
function* pastOrdersSaga(action: any) {
  const token = yield select(selectToken);
  const { filters, page, per_page } = action.payload;

  try {
    const res = yield call(getPastOrderAPI, token, page, per_page, filters);
    const data = res.data?.orders || [];
    const totalOrders = res.data?.total_orders || 0;
    yield put(
      getPastOrderSuccess({
        hasMore: !!data.length,
        orders: data,
        total_orders: totalOrders,
        filters,
        page,
      } as any)
    );
  } catch (e) {
    yield put(getPastOrderFailure(e));
  }
}

export function* watchGetPastOrdersSaga() {
  yield takeLatest(getPastOrderAction.requested, pastOrdersSaga);
}
