import axios from "./axios";
import * as Requests from "./requests";

export const getOrderDetailsAPI = (token, id) => {
  return axios.get(Requests.orderDetailsURL(id), {
    headers: {
      token,
    },
  });
};
