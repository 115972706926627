export default {
  open: "Open",
  close: "Close",
  delivery: "Delivery",
  pickup: "Pickup",
  save: "Save",
  branchesOpeningHours: "Branches opening hours",
  openingHoursCustomizedToEveryBranch:
    "Opening hours customized to every branch",
  updateBranches: "Update Branches",
  noBranchesFound: "No Branches Found",
  pleaseWaitWhileUpdatingAllBranches:
    "Please wait while updating all branches...",
  customizeOpeningHoursPerBranch: "+ Customize opening hours per branch",
  searchForBranchName: "Search for branch name…",
};
