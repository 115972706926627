import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export interface ILeaderBoardRankingCustomer {
  shopxId: string;
  total_invoices: number;
  created_at: number;
  rank: number;
  total_points: number;
  phone_number: string;
  id: string;
  updated_at: number;
  image?: string;
  app_version?: string;
  country_code: string;
  sign_up_time?: number;
  facebook_id?: string;
  last_active_time?: number;
  name?: string;
  email?: string;
  gender?: number;
  birth_date?: number;
  district_code?: string;
  government_code?: string;
}

export interface IUsersResponse {
  customers: ILeaderBoardRankingCustomer[];
  totalPoints: number[];
  totalCustomers: number;
}

export const getUsers = (token, filters): AxiosReturn<IUsersResponse> => {
  return axios.get(Requests.getUsers(filters.leaderboard_id), {
    baseURL: process.env.REACT_APP_LUMEN_ENDPOINT,
    headers: {
      token,
    },
    params: filters,
  });
};
