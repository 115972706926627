import { atom } from "recoil";

export const posAddressState = atom({
  key: "posAddressState",
  default: "",
});

export const collapseAtom = atom<boolean>({
  key: "collapseAtom",
  default: false,
});


export const hamburgerAtom = atom<boolean>({
  key: "hamburgerKey",
  default: false,
});
