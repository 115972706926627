import Great from "../../../../../../../../Components/Pickup/assets/great.svg";
import Wow from "../../../../../../../../Components/Pickup/assets/wow.svg";
import Bad from "../../../../../../../../Components/Pickup/assets/bad.svg";
import Terrible from "../../../../../../../../Components/Pickup/assets/terrible.svg";
import Okay from "../../../../../../../../Components/Pickup/assets/okay.svg";
export enum RATING {
  terrible = 1,
  bad = 2,
  okay = 3,
  great = 4,
  wow = 5,
}
export const Emojis = {
  Bad,
  Wow,
  Terrible,
  Okay,
  Great,
};
export const RATING_ICONS = {
  [RATING.terrible]: Terrible,
  [RATING.bad]: Bad,
  [RATING.okay]: Okay,
  [RATING.great]: Great,
  [RATING.wow]: Wow,
};
