import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/categoryActions";
import { createOrderingMenuCategory } from "../../axios/createOrderingMenuCategory";
import { selectToken } from "../../../../redux-store/selectors";
import { createOrderingCategoryAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";
import { translateMenuError } from "./changeItemCategorySaga";

function* createCategorySaga(action) {
  try {
    const token = yield select(selectToken);
    const res = yield call(createOrderingMenuCategory, token, action.payload);
    return res.data;
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.createCategory));
    throw e;
  }
}

export function* watchCreateCategorySaga() {
  yield takeLatest(
    createOrderingCategoryAction.requested,
    handleOptimism(createCategorySaga, {
      failure: actions.createOrderingCategoryFailure as any,
      success: actions.createOrderingCategorySuccess as any,
    })
  );
}
