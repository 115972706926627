import moment from "moment";
import i18n from "../i18n/i18n";
import { useMemo } from "react";

export const localizedMoment = (...args: any) =>
  moment(...args).locale(i18n.language);

export const useLocalizedMoment = (...args: any) => {
  return useMemo(() => localizedMoment(...args), [...args, i18n.language]);
};
