export const CURRENCIES = {
  en: {
    bd: "BDT",
    be: "EUR",
    bf: "XOF",
    bg: "BGN",
    ba: "BAM",
    bb: "BBD",
    wf: "XPF",
    bl: "EUR",
    bm: "BMD",
    bn: "BND",
    bo: "BOB",
    bh: "BHD",
    bi: "BIF",
    bj: "XOF",
    bt: "BTN",
    jm: "JMD",
    bv: "NOK",
    bw: "BWP",
    ws: "WST",
    bq: "USD",
    br: "BRL",
    bs: "BSD",
    je: "GBP",
    by: "BYR",
    bz: "BZD",
    ru: "RUB",
    rw: "RWF",
    rs: "RSD",
    tl: "USD",
    re: "EUR",
    tm: "TMT",
    tj: "TJS",
    ro: "RON",
    tk: "NZD",
    gw: "XOF",
    gu: "USD",
    gt: "GTQ",
    gs: "GBP",
    gr: "EUR",
    gq: "XAF",
    gp: "EUR",
    jp: "JPY",
    gy: "GYD",
    gg: "GBP",
    gf: "EUR",
    ge: "GEL",
    gd: "XCD",
    gb: "GBP",
    ga: "XAF",
    sv: "USD",
    gn: "GNF",
    gm: "GMD",
    gl: "DKK",
    gi: "GIP",
    gh: "GHS",
    om: "OMR",
    tn: "TND",
    jo: "JOD",
    hr: "HRK",
    ht: "HTG",
    hu: "HUF",
    hk: "HKD",
    hn: "HNL",
    hm: "AUD",
    ve: "VEF",
    pr: "USD",
    ps: "ILS",
    pw: "USD",
    pt: "EUR",
    sj: "NOK",
    py: "PYG",
    iq: "IQD",
    pa: "PAB",
    pf: "XPF",
    pg: "PGK",
    pe: "PEN",
    pk: "PKR",
    ph: "PHP",
    pn: "NZD",
    pl: "PLN",
    pm: "EUR",
    zm: "ZMK",
    eh: "MAD",
    ee: "EUR",
    eg: "EGP",
    za: "ZAR",
    ec: "USD",
    it: "EUR",
    vn: "VND",
    sb: "SBD",
    et: "ETB",
    so: "SOS",
    zw: "ZWL",
    sa: "SAR",
    es: "EUR",
    er: "ERN",
    me: "EUR",
    md: "MDL",
    mg: "MGA",
    mf: "EUR",
    ma: "MAD",
    mc: "EUR",
    uz: "UZS",
    mm: "MMK",
    ml: "XOF",
    mo: "MOP",
    mn: "MNT",
    mh: "USD",
    mk: "MKD",
    mu: "MUR",
    mt: "EUR",
    mw: "MWK",
    mv: "MVR",
    mq: "EUR",
    mp: "USD",
    ms: "XCD",
    mr: "MRO",
    im: "GBP",
    ug: "UGX",
    tz: "TZS",
    my: "MYR",
    mx: "MXN",
    il: "ILS",
    fr: "EUR",
    io: "USD",
    sh: "SHP",
    fi: "EUR",
    fj: "FJD",
    fk: "FKP",
    fm: "USD",
    fo: "DKK",
    ni: "NIO",
    nl: "EUR",
    no: "NOK",
    na: "NAD",
    vu: "VUV",
    nc: "XPF",
    ne: "XOF",
    nf: "AUD",
    ng: "NGN",
    nz: "NZD",
    np: "NPR",
    nr: "AUD",
    nu: "NZD",
    ck: "NZD",
    xk: "EUR",
    ci: "XOF",
    ch: "CHF",
    co: "COP",
    cn: "CNY",
    cm: "XAF",
    cl: "CLP",
    cc: "AUD",
    ca: "CAD",
    cg: "XAF",
    cf: "XAF",
    cd: "CDF",
    cz: "CZK",
    cy: "EUR",
    cx: "AUD",
    cr: "CRC",
    cw: "ANG",
    cv: "CVE",
    cu: "CUP",
    sz: "SZL",
    sy: "SYP",
    sx: "ANG",
    kg: "KGS",
    ke: "KES",
    ss: "SSP",
    sr: "SRD",
    ki: "AUD",
    kh: "KHR",
    kn: "XCD",
    km: "KMF",
    st: "STD",
    sk: "EUR",
    kr: "KRW",
    si: "EUR",
    kp: "KPW",
    kw: "KWD",
    sn: "XOF",
    sm: "EUR",
    sl: "SLL",
    sc: "SCR",
    kz: "KZT",
    ky: "KYD",
    sg: "SGD",
    se: "SEK",
    sd: "SDG",
    do: "DOP",
    dm: "XCD",
    dj: "DJF",
    dk: "DKK",
    vg: "USD",
    de: "EUR",
    ye: "YER",
    dz: "DZD",
    us: "USD",
    uy: "UYU",
    yt: "EUR",
    um: "USD",
    lb: "LBP",
    lc: "XCD",
    la: "LAK",
    tv: "AUD",
    tw: "TWD",
    tt: "TTD",
    tr: "TRY",
    lk: "LKR",
    li: "CHF",
    lv: "EUR",
    to: "TOP",
    lt: "LTL",
    lu: "EUR",
    lr: "LRD",
    ls: "LSL",
    th: "THB",
    tf: "EUR",
    tg: "XOF",
    td: "XAF",
    tc: "USD",
    ly: "LYD",
    va: "EUR",
    vc: "XCD",
    ae: "AED",
    ad: "EUR",
    ag: "XCD",
    af: "AFN",
    ai: "XCD",
    vi: "USD",
    is: "ISK",
    ir: "IRR",
    am: "AMD",
    al: "ALL",
    ao: "AOA",
    aq: "",
    as: "USD",
    ar: "ARS",
    au: "AUD",
    at: "EUR",
    aw: "AWG",
    in: "INR",
    ax: "EUR",
    az: "AZN",
    ie: "EUR",
    id: "IDR",
    ua: "UAH",
    qa: "QAR",
    mz: "MZN",
  },
  ar: {
    sa: "ر.س.",
    eg: "ج.م.",
    qa: "ر.ق.",
  },
};
