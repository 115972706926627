import Pusher from "pusher-js";

export const pusher = (token: string, store_id: string) => {
  const socket = new Pusher(process.env.REACT_APP_PUSHER_API_KEY as string, {
    authEndpoint: `${process.env.REACT_APP_ENDPOINT}/api/pusher/manager/auth`,
    auth: {
      headers: {
        token: token,
      },
      params: {
        store_id,
      },
    },
    cluster: "eu",
  });

  return socket;
};
