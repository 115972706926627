export default {
  invalid_phone_number: "أدخل رقم هاتف صالح",
  transaction_time: "وقت المعاملة",
  branch: "فروع",
  type: "نوع",
  customer: "عميل",
  price: "سعر",
  points: "نقاط",
  all: "الكل",
  payment_method: "طريقة الدفع",
  general: "عام",
  customerSegment: "شرائح العملاء",
  appCustomer: "عملاء التطبيق",
  nonAppCustomer: "عملاء لم يستخدموا التطبيق ",
  male: "رجل",
  female: "نساء",
  usersWithUnregisteredAge: "المستخدمون مع الاعمار الغير مسجلة",
  moreAdvanced: "أكثر تطورا",
  onePlusRedemption: "+1 عمليات التبديل",
  noRedemption: "بدون عمليات التبديل",
  applyFilters: "تطبيق الفلاتر",
  all_filter: "كل الفلاتر ",
  selectedFilters: "الفلاتر المختارة",
  branchesSelected: "فروع مختارين",
  customerType: "نوع العميل",
  customerAge: "الفئة العمرية",
  fulfillment_rate: "معدل الاكمال",
  customerGender: "الجنس",
  totalVisits: "إجمالي الزيارات",
  totalPoints: "مجموع النقاط",
  lastVisit: "تاريخ آخر زيارة",
  resetAll: "إعادة ضبط الكل",
  search: "ابحث باستخدام رقم الهاتف ...",
  searchWithOrderCode: "ابحث باستخدام رقم الهاتف ،او كود الطلب ...",
  searchBranchName: "ابحث باستخدام اسم الفرع...",
  time_to_accept: "متوسط وقت القبول",
  acceptance_rate: "معدل القبول",
  status: "الحاله",
  rate: "التقييم",
  export_csv: "Export CSV",
  on_track: "علي المسار",
  off_track: "خارج المسار",
  all_transactions: "كل العمليات ({{transaction_counts}})",
  all_redemptions: "كل التبديلات ({{redemption_counts}})",
  all_customers: "كل العملاء ({{customer_counts}})",
  all_branches: "كل الفروع ({{branch_counts}})",
  transactions: "العمليات",
  customers: "العملاء",
  branches: "الفروع",
  redemptions: "هدايا",
  gift: "الهديه",
  online_orders: "طلبات",
  visits: "الزيارات",
  current_points: "النقاط الحالية",
  life_time_points: "عمر النقاط",
  ticket_size: "سعر عملية الشراء:",
  branch_dropdown_title: "فرع:",
  more_filters: "المزيد من الفلاتر",
  average_ticket_size: "متوسط سعر عملية الشراء",
  branch_name: "فرع",
  delivery: "خدمة التوصيل",
  pickup: "استلام من الفرع",
  web_portal: "ويب بورتال",
  offline: "الولاء",
  online: "النقاط",
  sortBy: "ترتيب حسب",
  asc: "تصاعديا",
  desc: "تنازليا",
  dine_in: "الاكل في الفرع",
  takeaway: "تيك اواي",
  // fraud list  ar 
  action: "الاجراء",
  reason: "السبب",
  fraudRank: "درجة الاحتيال",
  markAsReviewed: "اتمام المراجعة",
  currentPoints: "عدد النقاط الحالي",
  numOfTransactions: "عدد العمليات",
  reviewed: "تمت مراجعته",
  pointsRemoved: "تم خصم النقاط",
  LOW: "منخفض",
  MEDIUM: "متوسط",
  HIGH: "مرتفع ",
  SUSPECT: "مشتبه به",

  blocked: "تم الحظر",
  removePointsConfirmationMsg: "هل انت متأكد من أنك تريد ازالة النقاط من العميل؟",
  cancelCta: "الغاء",
  removePointsCta: "ازاله النقط",
  blockCustomerCta: "حظر العميل",
  blockCustomerconfirmationMsg: "هل انت متأكد من أنك تريد حظر العميل؟ (هذا الاجراء سوف يزيل كل نقاط العميل االحالية و المستقبلية)",
  unreviewed: "لم تتم المراجعة",
  phoneNumberPlaceholder: "البحث برقم التليفون",
  //=> watch tower
  wallet: "المحفظة",
  compensations: "التعويضات",
  recentVisits: "الزيارات الاخيره",
  promoCodeChecker: "التحقق من البروموكود",
  actionCenter: "مركز الاجراءات",
  blockCustomer: "حظر العميل",
  blockedBy: "تم الحظر بواسطة",
};
