import * as React from "react";
import styles from "./styles.module.sass";
import cashierStyles from "../Cashier/styles.module.sass";
import { InjectedTranslateProps } from "react-i18next";
import { hot } from "react-hot-loader";
import { translate } from "react-i18next";
import Cashier from "../Cashier";
import Skeleton from "react-skeleton-loader";
import { ICashier } from "../../../../axios/getCashiers";
import { ButtonWithIcon } from "../../ButtonWithIcon";
import NotFoundItem from "../../Menu/NotFoundItem";
import Strings from "../../../../i18n/strings/cashiers";

interface IProps {
  cashiers: ICashier[];
  filteredCashiers: ICashier[];
  selectedBranchId: string;
  loaded: boolean;
  failed: boolean;
  onClickViewCashier: (cashier: ICashier) => void;
  onClickEditCashier: (cashier: ICashier) => void;
  onDeleteCashier: (cashier: ICashier) => void;
  openAddCashier: () => void;
}

class CashiersTable extends React.Component<IProps & InjectedTranslateProps> {
  public render() {
    const {
      cashiers,
      loaded,
      failed,
      filteredCashiers,
      selectedBranchId,
      onClickViewCashier,
      onClickEditCashier,
      onDeleteCashier,
      openAddCashier,
      t,
    } = this.props;
    let render = (
      <>
        {new Array(5).fill(0).map((a, i) => {
          return (
            <div key={i} className={cashierStyles.wrapper}>
              <p className={cashierStyles.userInfo}>
                <Skeleton widthRandomness={0} width={"15rem"} height={"2rem"} />
              </p>
              <p className={cashierStyles.phone}>
                <Skeleton widthRandomness={0} width={"15rem"} height={"2rem"} />
              </p>
              <p className={cashierStyles.branch}>
                <Skeleton widthRandomness={0} width={"25rem"} height={"4rem"} />
              </p>
              <div className={cashierStyles.btnWrapper}>
                <button className={cashierStyles.btn}>
                  <img
                    className={cashierStyles.img}
                    src={require("../../../../assets/icon-view.svg").default}
                  />
                </button>
                <button className={cashierStyles.btn}>
                  <img
                    className={cashierStyles.img}
                    src={require("../../../../assets/icon-edit.svg").default}
                  />
                </button>
                <button className={cashierStyles.btn}>
                  <img
                    className={cashierStyles.img}
                    src={require("../../../../assets/icon-delete.svg").default}
                  />
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
    if (failed) {
      render = (
        <div style={{ fontSize: "2rem", margin: "auto" }}>
          {t(Strings.somethingWentWrong)}
        </div>
      );
    }
    if (loaded) {
      render = (
        <>
          {cashiers.length === 0 ? (
            <NotFoundItem
              button={
                <ButtonWithIcon
                  btnStyle={styles.addBranch}
                  textStyles={styles.text}
                  name={t(Strings.newCashier)}
                  icon={"add"}
                  onClick={openAddCashier}
                />
              }
              img={require("../../../../assets/no-branches.svg").default}
              message={t(Strings.noCashiersAreAddedYet)}
            />
          ) : filteredCashiers.length === 0 ? (
            <div className={styles.notMatch}>
              {!selectedBranchId
                ? t(Strings.thereIsNoMatch)
                : "There is no assigned cashiers!"}
            </div>
          ) : (
            filteredCashiers.map((cashier) => (
              <Cashier
                onClickViewCashier={onClickViewCashier}
                onDeleteCashier={onDeleteCashier}
                cashier={cashier}
                onClickEditCashier={onClickEditCashier}
                key={cashier.id}
              />
            ))
          )}
        </>
      );
    }
    return (
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <p className={styles.name}>
            <div style={{ width: "6.5rem" }} />
            {t(Strings.name)}
          </p>
          <p className={styles.phone}>{t(Strings.phoneNumber)}</p>
          <p className={styles.branch}>{t(Strings.branch)}</p>
          <div style={{ flex: 1 }} />
        </div>
        <div className={styles.tableContent}>{render}</div>
      </div>
    );
  }
}

export default hot(module)(translate("cashiers")(CashiersTable));
