import { orderingMenusReducer } from "./orderingMenusReducer";
import { orderingMenuItemsReducer } from "./orderingMenuItemsReducer";
import { orderingMenuCategoriesReducer } from "./orderingMenuCategoriesReducer";
import { suggestedModifierGroupsReducer } from "./suggestedModifierGroupReducer";
import { storeSettingsReduces } from "./storeSettings";
import { getZonesReducer } from "./getZonesReducer";
import { getAreasReducer } from "./getAreasReducer";
import { editBranchZonesReducer } from "./editBranchZonesReducer";
import { minOrderValueReducer } from "./minOrderValueReducer";
import { pastOrdersReducer } from "./pastOrderReducer";
import { orderTrackingReducer } from "./orderStatusReducer";
import { getCustomerInfoReducer } from "./customerInfoReducer";
import { orderDetailsReducer } from "./orderDetailsReducer";
import { orderReducer } from "./orderReducer";
import { getBranchInfoReducer } from "./getBranchInfoReducer";
import { statisticsReducer } from "./statisticsReducer";
import { getStoreInfoReducer } from "./getStoreInfoReducer";
import { extraInfoReducer } from "./extraInfoReducer";
import { orderingPortalLinkReducer } from "./orderingPortalLinkReducer";
import { webPortalHistoryReducer } from "./webPortalHistoryReducer";
import { orderingPortalStatisticsReducer } from "./orderingPortalStatisticsReducer";
import { getMenuScrapingStateReducer } from "./menuScrapingStateReducer";
import { verifyZoneCSVReducer } from "./verifyZoneCSVReducer";
import * as ManagerReducers from "./Manager";
import * as PortalReducers from "./Portal";
import { submitPickupBranchesReducer } from "./submitPickupBranchesReducer";
export default {
  orderingMenusReducer,
  getMenuScrapingStateReducer,
  getBranchInfoReducer,
  orderingMenuItemsReducer,
  orderingMenuCategoriesReducer,
  getStoreInfoReducer,
  suggestedModifierGroupsReducer,
  storeSettingsReduces,
  orderingPortalLinkReducer,
  getZonesReducer,
  extraInfoReducer,
  getAreasReducer,
  statisticsReducer,
  orderReducer,
  webPortalHistoryReducer,
  editBranchZonesReducer,
  minOrderValueReducer,
  pastOrdersReducer,
  orderTrackingReducer,
  getCustomerInfoReducer,
  orderDetailsReducer,
  orderingPortalStatisticsReducer,
  verifyZoneCSVReducer,
  submitPickupBranchesReducer,
  ...ManagerReducers,
  ...PortalReducers,
};

export * from "./Manager";
export * from "./Portal";