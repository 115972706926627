import { ICompleteTutorialAction, IResetTutorialAction } from "../../types";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";

export interface ITutorialReducerState {
  hasSeenTutorial: boolean;
}

const HAS_SEEN_TUTORIAL = "hasSeenTutorial";

const initialState: ITutorialReducerState = Map({
  [HAS_SEEN_TUTORIAL]: false,
}).toJS();

export default (
  state = initialState,
  action: IResetTutorialAction | ICompleteTutorialAction
): ITutorialReducerState => {
  switch (action.type) {
    case constants.ACTION_COMPLETED_TUTORIAL: {
      return mutateState(state, (map) => {
        map.set(HAS_SEEN_TUTORIAL, true);
      });
    }
    case constants.ACTION_RESET_TUTORIAL: {
      return mutateState(state, (map) => {
        map.set(HAS_SEEN_TUTORIAL, false);
      });
    }
    default:
      return state;
  }
};
