import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

import "./bar-graph.module.sass";

type dataPointType = {
  value: number;
  date: string;
  label: string;
};
interface BarGraphValues {
  labels: string[];
  datasets: {
    label: string;
    data: dataPointType[];
    backgroundColor: string;
  }[];
}

interface LineGraphOptions {
  hover?: any;
  tooltips?: any;
  dotRadius?: number;
}

export interface BarGraphProps {
  values: BarGraphValues;
  width: number;
  height: number;
  options?: LineGraphOptions;
  barChartConfigs: any;
}

export function BarGraph(props: BarGraphProps) {
  const { width, height, values, barChartConfigs } = props;

  const koinzBarChartRef = useRef<any>({});

  useEffect(() => {
    const canvas = document.getElementById(
      barChartConfigs.BAR_CHART_ID
    ) as HTMLCanvasElement;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    koinzBarChartRef.current = new Chart(ctx, {
      type: barChartConfigs.CHART_TYPE,
      data: {
        labels: values.labels,
        datasets: values.datasets,
      },
      options: { ...barChartConfigs.OPTIONS },
    });

    return () => koinzBarChartRef.current.destroy();
  }, []);

  // updating data
  useEffect(() => {
    if (typeof koinzBarChartRef !== undefined) {
      koinzBarChartRef.current.data.datasets = values.datasets;
      koinzBarChartRef.current.update();
    }
  }, [values.datasets, koinzBarChartRef]);

  // updating labels
  useEffect(() => {
    if (typeof koinzBarChartRef !== undefined) {
      koinzBarChartRef.current.data.labels = values.labels;
      koinzBarChartRef.current.update();
    }
  }, [values.labels, koinzBarChartRef]);

  return (
    <div className="wrapper">
      <canvas
        id={barChartConfigs.BAR_CHART_ID}
        width={width}
        height={height}
      ></canvas>
    </div>
  );
}

export default BarGraph;
