import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteBranchAction } from "../../constants";
import { IDeleteBranchAction } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { deleteBranch } from "../../axios/deleteBranch";
import { delay } from "redux-saga/effects";
import { trackEvent } from "../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";

function* deleteBranchSaga(action: IDeleteBranchAction) {
  try {
    const token = yield select(selectToken);
    yield call(deleteBranch, token, action.payload.id);
    yield put(actions.deleteBranchSuccess(action.payload));
    trackEvent(EVENTS_FOR_TRACKING["Deleted a branch or a cashier"]);
  } catch (e) {
    yield delay(1000);
    yield put(actions.deleteBranchFailure(action.payload));
  }
}

export function* watchDeleteBranchSaga() {
  yield takeLatest(deleteBranchAction.requested, deleteBranchSaga);
}
