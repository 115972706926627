import axios from "./axios";
import * as Requests from "./requests";
import { IStore } from "../types";

export const getStoreProfile = (token: string) => {
  return axios.get<{ store: IStore }>(Requests.store, {
    headers: {
      token,
    },
  });
};
