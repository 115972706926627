import React from "react";
import { Button } from "antd";
import styles from "./styles.module.sass";
import { LoadingOutlined } from "@ant-design/icons";

interface IProps {
  onClick: () => void;
  buttonStatus: "default" | "loading" | "disabled";
  icon?: React.ReactElement;
  style?: {
    [key: string]: any;
  };
}

const CSVButton: React.FC<IProps> = ({
  onClick,
  buttonStatus = "default",
  children,
  icon,
  style,
}) => {
  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  const defaultStyle = {};

  const disabledStyles = {
    backgroundColor: "#F2F2F7",
    pointer: "none",
  };

  const loadingStyles = {};

  return (
    <Button
      // className={
      //   ["loading", "disabled"].includes(buttonStatus)
      //     ? "export-button-disabled"
      //     : "export-button"
      // }
      type="primary"
      icon={icon}
      onClick={onClick}
      disabled={["loading", "disabled"].includes(buttonStatus)}
      style={{
        ...defaultStyle,
        ...(buttonStatus === "disabled" ? disabledStyles : {}),
        ...(buttonStatus === "loading" ? loadingStyles : {}),
        ...style,
      }}
    >
      {buttonStatus === "loading" ? CustomSpinner : children}
    </Button>
  );
};

export default CSVButton;
