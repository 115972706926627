export default {
  pickupBranches: "Ordering Branches",
  menu: "Menu",
  brandSettings: "Brand Settings",
  step: "Step 1.",
  setupPickup: "Setup ordering services",
  pickupBranchesDescription:
    "Select branches that you want to enable ordering in",
  brandSettingsDescription: "Setup menu categories & menu items",
  menuDescription: "Setup brand settings",
};
