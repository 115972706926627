import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { importMenuAction } from "../../constants/actions";
import {
  importMenu,
  importMenuFailure,
  importMenuSuccess,
} from "../actions/menu-scraping-actions";
import { importMenuApi } from "../../axios/importMenuApi";

const actionType = union({ importMenu } as any);
function* importMenuSaga({
  payload: { menu_scraping_type, en_url, ar_url },
}: any) {
  const token = yield select(selectToken);
  try {
    const res = yield call(importMenuApi, token, {
      menu_scraping_type,
      en_url,
      ar_url,
    });
    yield put(importMenuSuccess(res.data));
  } catch (e) {
    yield put(importMenuFailure(e));
  }
}

export function* watchImportMenuSaga() {
  yield takeLatest(importMenuAction.requested, importMenuSaga);
}
