import axios from "../axios";
import { bannerCardsURL } from "../requests";
export const reorderBannerCardApi = (
  token: string,
  data: {
    new_order: number;
    banner_card_id: string;
  }
) => {
  return axios.put(bannerCardsURL + "/reorder", data, {
    headers: { token },
  });
};
