import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import * as actions from "../actions/StoreSettings";
import { getAllBrandCategory } from "../../axios/getAllBrandCategories";
import { handleOptimism } from "./handleOptimism";

function* getAllBrandCategoriesSaga() {
  const token = yield select(selectToken);
  const res = yield call(getAllBrandCategory, token);
  return res.data.store_categories;
}

export function* watchGetAllBrandCategoriesSaga() {
  yield takeLatest(
    actions.getAllBrandCategories.type,
    handleOptimism(getAllBrandCategoriesSaga, {
      failure: actions.getAllBrandCategoriesFailure as any,
      success: actions.getAllBrandCategoriesSuccess as any,
    }) 
  );
}
