import { Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import StoreData from "../../../../../../../../contexts/StoreData";
import i18n from "../../../../../../../../i18n/i18n";
import { Queries } from "../../../../../../../../Queries/Manager";
import {
  IBranch,
  IBranchesTable,
} from "../../../../../../../../Queries/Manager/Analytics/branchesPerformance.hooks";
import "./styles.less";
import styles from "./styles.module.sass";
import { useDeepCompareEffect } from "use-deep-compare";
import { LoadingOutlined } from "@ant-design/icons";
import { columns } from "./columns";
import "moment/locale/ar";
import Strings from "../../../../../../../../i18n/Manager/strings/home";
import { translate } from "../../../../../../../../helpers/translate";
import humanizeDuration from "humanize-duration";
const t = translate("home");

const DIRECTION = {
  up: require("../../../../../../../../assets/feather _ arrow-up_chart.svg")
    .default,
  down: require("../../../../../../../../assets/feather _ arrow-down.svg")
    .default,
};
const hasTruthyValue = (values: IBranch[]) => {
  return values?.filter(
    ({ fulfillment_rate, acceptance_rate }) =>
      fulfillment_rate.value > 0 || acceptance_rate.value > 0
  );
};
const tables = (items: IBranch[]): IBranchesTable<any>[] => {
  return (items || []).map((item, index) => ({
    key: index,
    branch_name: (
      <span className={styles.branchName}>
        {i18n.language === "ar" ? item.ar_name : item.en_name}
      </span>
    ),
    acceptance_rate: (
      <div className={styles.percentageWrapper}>
        {item.acceptance_rate.direction && (
          <img
            style={{
              marginRight: i18n.language === "ar" ? 0 : "8px",
              marginLeft: i18n.language === "ar" ? "8px" : 0,
            }}
            className={styles.percentageIcon}
            src={DIRECTION[item.acceptance_rate.direction]}
          />
        )}
        <span className={styles.percentage}>
          {Number(item.acceptance_rate.value || 0)}%
        </span>
      </div>
    ),
    status: (
      <div className={styles.statusWrapper}>
        <div
          style={{
            marginRight: i18n.language === "ar" ? 0 : "8px",
            marginLeft: i18n.language === "ar" ? "8px" : 0,
          }}
          className={!!item.on_track ? styles.onTrackDot : styles.offTrackDot}
        />
        <span className={styles.statusTitle}>
          {!!item.on_track ? t(Strings.on_track) : t(Strings.off_track)}
        </span>
      </div>
    ),
    time_to_accept: (
      <div className={styles.percentageWrapper}>
        {item.time_to_accept.direction && (
          <img
            className={styles.percentageIcon}
            src={DIRECTION[item.time_to_accept.direction]}
          />
        )}
        <span className={styles.percentage}>
          {humanizeDuration(item.time_to_accept.value, {
            language: i18n.language === "ar" ? "ar" : "en",
            maxDecimalPoints: 0,
          })}
        </span>
      </div>
    ),
    fulfillment_rate: (
      <div className={styles.percentageWrapper}>
        {item.fulfillment_rate.direction && (
          <img
            className={styles.percentageIcon}
            src={DIRECTION[item.fulfillment_rate.direction]}
          />
        )}
        <span className={styles.percentage}>
          {Number(item.fulfillment_rate.value || 0)}%
        </span>
      </div>
    ),
  }));
};

const BranchesPerformanceTable: React.FC<{
  to: number;
  from: number;
  branchPerformanceVisible: boolean;
}> = ({ from, to, branchPerformanceVisible }) => {
  const [state, setState] = useState<any>();
  const { token } = useContext(StoreData);
  const { values, status, data, refetch } = Queries.useGetBranchesPerformance(
    token,
    {
      from,
      to,
    }
  );
  useEffect(() => {
    if (branchPerformanceVisible && status === "idle" && !data) {
      refetch();
    }
  }, [branchPerformanceVisible]);
  useDeepCompareEffect(() => {
    if (
      !!values.top_branches.length &&
      !values.lowest_branches.length &&
      status === "success"
    ) {
      setState([...tables(hasTruthyValue(values.top_branches) as [])]);
    } else if (
      !!values.lowest_branches.length &&
      !values.top_branches.length &&
      status === "success"
    ) {
      setState([...tables(hasTruthyValue(values.lowest_branches) as [])]);
    } else if (
      !!values.top_branches.length &&
      !!values.lowest_branches.length &&
      status === "success"
    ) {
      setState({
        top_branches: {
          branch_name: (
            <span className={styles.topBranches}>
              {t(Strings.top_branches)}
            </span>
          ),
          children: [...tables(hasTruthyValue(values?.top_branches) as [])],
        },
        lowest_branches: {
          branch_name: (
            <span className={styles.topBranches}>
              {t(Strings.lowest_branches)}
            </span>
          ),
          children: [...tables(hasTruthyValue(values.lowest_branches) as [])],
        },
      });
    } else {
      setState([]);
    }
  }, [values.top_branches, status, values.lowest_branches]);

  return (
    <Table
      columns={columns}
      className={
        !!values?.top_branches.length && !!values?.lowest_branches.length
          ? "branches-performance-table"
          : "branches-performance-grouping-table"
      }
      expandable={{
        expandIcon: () => null,
        defaultExpandAllRows: true,
      }}
      rowClassName={"row-branches-performance"}
      dataSource={
        !!values?.lowest_branches.length &&
        !!values?.top_branches.length &&
        state?.top_branches &&
        state?.lowest_branches
          ? [
              {
                ...state.top_branches,
              },
              {
                ...state.lowest_branches,
              },
            ]
          : Array.isArray(state)
          ? state
          : [{}]
      }
      loading={
        status === "loading"
          ? { indicator: <LoadingOutlined style={{ fontSize: 30 }} spin /> }
          : false
      }
      pagination={false}
      locale={{
        emptyText:
          status === "success" &&
          !values?.lowest_branches.length &&
          !values?.top_branches.length ? (
            <div className={styles.emptyTextWrapper}>
              <img
                className={styles.emptyIcon}
                src={
                  require("../../../../../../../../assets/feather _ info.svg")
                    .default
                }
              />
              <span className={styles.emptyText}>
                {t(Strings.there_is_no_branches_available)}
              </span>
            </div>
          ) : (
            <div />
          ),
      }}
      tableLayout="fixed"
      scroll={
        columns.length < 4
          ? { x: 0, y: 480 }
          : columns.length <= 5
          ? { x: 552, y: 480 }
          : columns.length > 6 && columns.length <= 12
          ? { x: 1400, y: 480 }
          : { x: 2000, y: 480 }
      }
    />
  );
};

export default BranchesPerformanceTable;
