import { useQuery } from "react-query";
import {
  getCustomerInsightsAPI,
  getCustomerInsightsTotalAPI,
} from "../../../../axios/Manager/Analytics/Insights/customer";

export const useGetCustomerInsights = (token, requestConfigs) => {
  const result = useQuery(
    ["customer-insights", requestConfigs],
    () => {
      return getCustomerInsightsAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};

export const useGetCustomerInsightsTotal = (token, requestConfigs) => {
  const result = useQuery(
    ["customer-insights-total", requestConfigs],
    () => {
      return getCustomerInsightsTotalAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};
