import * as actions from "../../constants/actions";
import { payload, action } from "ts-action";
import { IMinOrderValue } from "../../lib/types";

export const getMinValue = action(actions.getMinOrderValueAction.requested);
export const getMinValueSuccess = action(
  actions.getMinOrderValueAction.fulfilled,
  payload<IMinOrderValue>()
);
export const getMinValueFailure = action(
  actions.getMinOrderValueAction.rejected,
  payload<Error>()
);

export const setMinValue = action(
  actions.setMinOrderValueAction.requested,
  payload<IMinOrderValue>()
);
export const setMinValueSuccess = action(
  actions.setMinOrderValueAction.fulfilled,
  payload<IMinOrderValue>()
);
export const setMinValueFailure = action(
  actions.setMinOrderValueAction.rejected,
  payload<Error>()
);

export const setRoutingMethod = action(
  actions.setRoutingMethodAction.requested,
  payload<{ is_call_center: boolean }>()
);

export const setRoutingMethodSuccess = action(
  actions.setRoutingMethodAction.fulfilled,
  payload<{ is_call_center: boolean }>()
);
export const setRoutingMethodFailure = action(
  actions.setRoutingMethodAction.rejected
);

export const setPaymentMethod = action(
  actions.setPaymentMethodAction.requested,
  payload<{ payment_methods: string[], pos_online_payment_method_id?: string }>()
);
export const setPaymentMethodSuccess = action(
  actions.setPaymentMethodAction.fulfilled,
  payload<string[]>()
);

export const setPaymentMethodFailure = action(
  actions.setPaymentMethodAction.rejected,
  payload<Error>()
);
