import { call, put, select, takeLatest } from "redux-saga/effects";
import { getStoreAction } from "../../constants";
import { IStore } from "../../types";
import { selectToken } from "../selectors";
import { getStore } from "../../axios/getStore";
import * as actions from "../actions";
import { initialize } from "redux-form";
import { convertDataURLToFile } from "../../Utils";
import { imgBURL } from "../../index";

function* getStoreSaga() {
  try {
    const selectedToken = yield select(selectToken);
    const storeRes = yield call(getStore, selectedToken);
    const store = storeRes.data.store as IStore;
    // const imgBURL = process.env.OLD_ENDPOINT;
    let cover_image;
    let store_image;
    try {
      cover_image = store.cover_image
        ? yield call(convertDataURLToFile, imgBURL + store.cover_image)
        : "";
      store_image = store.store_image
        ? yield call(convertDataURLToFile, imgBURL + store.store_image)
        : "";
      // tslint:disable-next-line: no-empty
    } catch (e) { }
    const background_color = {
      hex: store.background_color ? store.background_color : "#FF9000",
    };
    yield put(
      initialize("storeForm", {
        ...store,
        cover_image,
        store_image,
        background_color,
      })
    );
    yield put(actions.getStoreSuccess({ ...store }));
  } catch (e) {
    yield put(actions.getStoreFailure(e));
  }
}

export function* watchGetStoreSaga() {
  yield takeLatest(getStoreAction.requested, getStoreSaga);
}
