import { useQuery } from "react-query";
import {
  getOrdersGraphReq,
  getOnlineOrdersGraphReq,
} from "../../../axios/Manager/Analytics";
import {
  OrderingSource,
  OrderType,
} from "../../../Screens/Manager/Analytics/Home/components/OrdersChart";

export const useGetOrdersChartQuery = (
  token,
  requestConfigs,
  isOnlineOrder
) => {
  const result = useQuery(
    ["ordersGraph", token, requestConfigs, isOnlineOrder],
    () => {
      return !isOnlineOrder
        ? getOrdersWrapper(token, requestConfigs)
        : getOnlineOrdersWrapper(token, requestConfigs);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};

const getOrdersWrapper = (token, requestConfigs) =>
  getOrdersGraphReq(token, {
    ...requestConfigs,
  }).then((res) => res.data);

const getOnlineOrdersWrapper = (token, requestConfigs) =>
  getOnlineOrdersGraphReq(token, {
    ...requestConfigs,
  }).then((res) => res.data);

// fulfillment rates
export const useGetFulfillmentRateChart = (token, requestConfigs) => {
  const result = useQuery(
    ["onlineOrdersGraphForFulfillment", requestConfigs],
    () => {
      return getOnlineOrdersGraphReq(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};
