import { useMutation } from "react-query";
import { deletePointsOperationAPI } from "../../axios/PointsOperation/deletePointsOperation.api";
import { useSelect } from "../../hooks/useSelect";

export const useDeletePointsOperation = () => {
  const { token } = useSelect((state) => state.authReducer);
  return useMutation((operation_id) =>
    deletePointsOperationAPI(token, operation_id)
  );
};
