export default {
  required: "حقل مطلوب",
  isEmpty: "لا يجب أن تترك هذا الحقل فارغاً",
  maxLength: "يجب ألا يتخطى {{max}} حرفاً",
  minLength: "يجب أن يكون {{min}} حرفاً على الأقل",
  number: "يجب أن يكون رقماً",
  minValue: "يجب ألا يقل عن {{min}}",
  maxValue: "يجب ألا يزيد عن {{max}}",
  image: "الملف غير صالح",
  url: "رابط غير صالح",
  mustBeNumber: "قيمه المدخل يجب ان تكون رقما",
  mustBeInteger: "قيمه المدخل يجب ان تكون عدداً صحيحاً",
  range: "يجب ان تكون الضريبة بين 0% و 100%",
  vat: "يجب ان تكون الضريبة بين 0% و 100%",

  location: "رجاءاً قم باختيار الموقع على الخريطة",
  atLeastOneField: "رجاءاً قم بملئ واحد من هذين الحقلين",
};
