import moment from "moment";

export const GIFT_FORM_FIELDS = ["gift_photo", "gift_name", "gift_description"];

export const LAUNCH_DATE = moment("01/01/2019", "DD/MM/YYYY").subtract(1, "ms");

// REDUX

export interface IGenericAction {
  fulfilled: string;
  rejected: string;
  requested: string;
}

export const genericAction = (action: string) => ({
  fulfilled: `${action}_FULFILLED`,
  rejected: `${action}_REJECTED`,
  requested: `${action}_REQUESTED`,
});

export const genericFormFlowAction = (action: string) => ({
  submitting: `${action}_SUBMITTING`,
  submitted: `${action}_SUBMITTED`,
  failed: `${action}_FAILED`,
  initial: `${action}_INITIAL`,
});

const ACTION_SIGNUP = "ACTION_SIGNUP";
export const signupAction = genericAction(ACTION_SIGNUP);
export const clearPrevStateAction = "ACTION_CLEAR_PREV_STATE";
const ACTION_LOGIN = "ACTION_LOGIN";
export const loginAction = genericAction(ACTION_LOGIN);

const ACTION_LOGOUT = "ACTION_LOGOUT";
export const logoutAction = genericAction(ACTION_LOGOUT);

const ACTION_GET_COUNTRIES = "ACTION_GET_COUNTRIES";
export const getCountriesAction = genericAction(ACTION_GET_COUNTRIES);

const ACTION_CREATE_COMPETITION = "ACTION_CREATE_COMPETITION";
export const createCompetitionAction = genericAction(ACTION_CREATE_COMPETITION);

const ACTION_EDIT_COMPETITION = "ACTION_EDIT_COMPETITION";
export const editCompetitionAction = genericAction(ACTION_EDIT_COMPETITION);

const ACTION_DELETE_COMPETITION = "ACTION_DELETE_COMPETITION";
export const deleteCompetitionAction = genericAction(ACTION_DELETE_COMPETITION);

const ACTION_GET_COMPETITIONS = "ACTION_GET_COMPETITIONS";
export const getCompetitionsAction = genericAction(ACTION_GET_COMPETITIONS);

const ACTION_GET_COMPETITION = "ACTION_GET_COMPETITION";
export const getCompetitionAction = genericAction(ACTION_GET_COMPETITION);

const ACTION_NEXT_LEADERBOARD = "ACTION_NEXT_LEADERBOARD";
export const nextLeaderboardAction = genericAction(ACTION_NEXT_LEADERBOARD);

export const ACTION_SET_MORE = "ACTION_SET_MORE";
export const ACTION_RESET_COMPETITION = "ACTION_RESET_COMPETITION";
export const ACTION_TOGGLE_1 = "ACTION_TOGGLE_1";
export const ACTION_TOGGLE_2 = "ACTION_TOGGLE_2";
export const ACTION_TOGGLE_3 = "ACTION_TOGGLE_3";
export const ACTION_TOGGLE_4 = "ACTION_TOGGLE_4";
export const ACTION_TOGGLE_5 = "ACTION_TOGGLE_5";
export const ACTION_RESET_COLLAPSE = "ACTION_RESET_COLLAPSE";

const ACTION_GET_USER_RANKING = "ACTION_GET_USER_RANKING";
export const getUserRankingAction = genericAction(ACTION_GET_USER_RANKING);

const ACTION_GET_CASHIERS = "ACTION_GET_CASHIERS";
export const getCashiersAction = genericAction(ACTION_GET_CASHIERS);

export const ACTION_COMPLETED_TUTORIAL = "ACTION_COMPLETED_TUTORIAL";
export const ACTION_RESET_TUTORIAL = "ACTION_RESET_TUTORIAL";

const ACTION_EDIT_STORE = "ACTION_EDIT_STORE";
export const editStoreAction = genericAction(ACTION_EDIT_STORE);

export const ACTION_CHANGE_STORE_STATE = "ACTION_CHANGE_STORE_STATE";

export const ACTION_RESET_EDIT_FAILED = "ACTION_RESET_EDIT_FAILED";

const ACTION_GET_STORE = "ACTION_GET_STORE";
export const getStoreAction = genericAction(ACTION_GET_STORE);

const ACTION_GET_STORE_ITEMS = "ACTION_GET_STORE_ITEMS";
export const getStoreItemsAction = genericAction(ACTION_GET_STORE_ITEMS);

const ACTION_EDIT_STORE_ITEM = "ACTION_EDIT_STORE_ITEM";
export const editStoreItemAction = genericAction(ACTION_EDIT_STORE_ITEM);

const ACTION_ADD_STORE_ITEM = "ACTION_ADD_STORE_ITEM";
export const addStoreItemAction = genericAction(ACTION_ADD_STORE_ITEM);
const ACTION_DELETE_STORE_ITEM = "ACTION_DELETE_STORE_ITEM";
export const deleteStoreItemAction = genericAction(ACTION_DELETE_STORE_ITEM);

const ACTION_GET_CATEGORIES = "ACTION_GET_CATEGORIES";
export const getCategoriesAction = genericAction(ACTION_GET_CATEGORIES);

const ACTION_EDIT_CATEGORY = "ACTION_EDIT_CATEGORY";
export const editCategoryAction = genericAction(ACTION_EDIT_CATEGORY);

const ACTION_ADD_CATEGORY = "ACTION_ADD_CATEGORY";
export const addCategoryAction = genericAction(ACTION_ADD_CATEGORY);

const ACTION_DELETE_CATEGORY = "ACTION_DELETE_CATEGORY";
export const deleteCategoryAction = genericAction(ACTION_DELETE_CATEGORY);

const ACTION_GET_GIFT_LIST = "ACTION_GET_GIFT_LIST";
export const getGiftListAction = genericAction(ACTION_GET_GIFT_LIST);

const ACTION_ADD_SPECIAL_ITEM = "ACTION_ADD_SPECIAL_ITEM";
export const addSpecialItemAction = genericAction(ACTION_ADD_SPECIAL_ITEM);

const ACTION_EDIT_SPECIAL_ITEM = "ACTION_EDIT_SPECIAL_ITEM";
export const editSpecialItemAction = genericAction(ACTION_EDIT_SPECIAL_ITEM);

const ACTION_DELETE_SPECIAL_ITEM = "ACTION_DELETE_SPECIAL_ITEM";
export const deleteSpecialItemAction = genericAction(
  ACTION_DELETE_SPECIAL_ITEM
);

const ACTION_EDIT_LOYALTY_ITEM = "ACTION_EDIT_LOYALTY_ITEM";
export const editLoyaltyItemAction = genericAction(ACTION_EDIT_LOYALTY_ITEM);

export const ACTION_PASS_ON_FORM_VALUES = "ACTION_PASS_ON_FORM_VALUES";

export const ACTION_FILTER_ADD_FROM_MENU = "ACTION_FILTER_ADD_FROM_MENU";

const ACTION_GET_BRANCHES = "ACTION_GET_BRANCHES";
export const getBranchesAction = genericAction(ACTION_GET_BRANCHES);
const ACTION_EDIT_BRANCH_STATUSES = "ACTION_EDIT_BRANCH_STATUSES";
export const editBranchStatuesAction = ACTION_EDIT_BRANCH_STATUSES;
const ACTION_ADD_BRANCH = "ACTION_ADD_BRANCH";
export const addBranchAction = genericAction(ACTION_ADD_BRANCH);

const ACTION_EDIT_BRANCH = "ACTION_EDIT_BRANCH";
export const editBranchAction = genericAction(ACTION_EDIT_BRANCH);

const ACTION_DELETE_BRANCH = "ACTION_DELETE_BRANCH";
export const deleteBranchAction = genericAction(ACTION_DELETE_BRANCH);

export const ACTION_SEARCH_BRANCHES = "ACTION_SEARCH_BRANCHES";

// edit qr portal branch status action
export const ACTION_EDIT_QR_PORTAL_BRANCH_STATUS =
  "ACTION_EDIT_QR_PORTAL_BRANCH_STATUS";

// store virility settings

const ACTION_UPDATE_STORE_VIRALITY_SETTINGS =
  "ACTION_UPDATE_STORE_VIRALITY_SETTINGS";
export const updateStoreViralitySettingsAction = genericAction(
  ACTION_UPDATE_STORE_VIRALITY_SETTINGS
);

const ACTION_GET_STORE_VIRALITY_SETTINGS = "ACTION_GET_STORE_VIRALITY_SETTINGS";
export const getStoreViralitySettingsAction = genericAction(
  ACTION_GET_STORE_VIRALITY_SETTINGS
);

const ACTION_GET_RECOMMENDED_GIFT = "ACTION_GET_RECOMMENDED_GIFT";
export const getRecommendedGiftAction = genericAction(
  ACTION_GET_RECOMMENDED_GIFT
);

// fraudlent customers list actions
// get
export const ACTION_GET_FRAUDLENT_CUSTOMER_LIST =
  "ACTION_GET_FRAUDLENT_CUSTOMER_LIST";
export const getFraudlentCustomerList = genericAction(
  ACTION_GET_FRAUDLENT_CUSTOMER_LIST
);

// update
export const ACTION_REMOVE_FRAUDLENT_CUSTOMER_POINTE =
  "ACTION_REMOVE_FRAUDLENT_CUSTOMER_POINTE";
export const removeFraudlentCustomerPoints = genericAction(
  ACTION_REMOVE_FRAUDLENT_CUSTOMER_POINTE
);
// update
export const ACTION_BLOCK_FRAUDLENT_CUSTOMER =
  "ACTION_BLOCK_FRAUDLENT_CUSTOMER";
export const blockFraudlentCustomer = genericAction(
  ACTION_BLOCK_FRAUDLENT_CUSTOMER
);
// / update
export const ACTION_REVIEW_FRAUDLENT_CUSTOMER =
  "ACTION_REVIEW_FRAUDLENT_CUSTOMER";
export const reviewFraudlentCustomer = genericAction(
  ACTION_REVIEW_FRAUDLENT_CUSTOMER
);

// banner cards

export const ACTION_GET_BANNER_CARDS = "ACTION_GET_BANNER_CARDS";
export const getBannerCardsAction = genericAction(ACTION_GET_BANNER_CARDS);

export const ACTION_REORDER_BANNER_CARD = "ACTION_REORDER_BANNER_CARD";
export const reorderBannerCardAction = genericAction(
  ACTION_REORDER_BANNER_CARD
);

export const ACTION_DELETE_BANNER_CARD = "ACTION_DELETE_BANNER_CARD";
export const deleteBannerCardAction = genericAction(ACTION_DELETE_BANNER_CARD);

export const ACTION_ADD_BANNER_CARD = "ACTION_ADD_BANNER_CARD";
export const addBannerCardAction = genericAction(ACTION_ADD_BANNER_CARD);

export const resetBannerCardsStateAction = "ACTION_RESET_BANNER_CARDS_STATE";

export const ACTION_GET_POS_ENTITIES = "ACTION_GET_POS_ENTITIES";
export const getPosEntitiesAction = genericAction(ACTION_GET_POS_ENTITIES);

export const ACTION_UPDATE_POS_ENTITY = "ACTION_UPDATE_POS_ENTITY";
export const updatePosEntityAction = genericAction(ACTION_UPDATE_POS_ENTITY);

export const ACTION_GET_PROMOTIONS = "ACTION_GET_PROMOTIONS";
export const getPromotionsAction = genericAction(ACTION_GET_PROMOTIONS);

export const ACTION_GET_VOUCHERS = "ACTION_GET_VOUCHERS";
export const getVouchersAction = genericAction(ACTION_GET_VOUCHERS);

export const ACTION_GET_LOYALTY_MENU = "ACTION_GET_LOYALTY_MENU";
export const getLoyaltyMenuAction = genericAction(ACTION_GET_LOYALTY_MENU);
export const ACTION_SEARCH_LOYALTY_MENU_ITEMS =
  "ACTION_SEARCH_LOYALTY_MENU_ITEMS";

export const ACTION_FILTER_LOYALTY_MENU_ITEMS_BY_CATEGORY =
  "FILTER_LOYALTY_MENU_ITEMS_BY_CATEGORY";
const ACTION_EDIT_LOYALTY_MENU_ITEM = "ACTION_EDIT_LOYALTY_MENU_ITEM";
export const editLoyaltyMenuItemAction = genericAction(
  ACTION_EDIT_LOYALTY_MENU_ITEM
);

const ACTION_GET_LOYALTY_SPECIAL_ITEMS = "ACTION_GET_LOYALTY_SPECIAL_ITEMS";
export const getLoyaltySpecialItemsAction = genericAction(
  ACTION_GET_LOYALTY_SPECIAL_ITEMS
);

const ACTION_DELETE_LOYALTY_SPECIAL_ITEM = "ACTION_DELETE_LOYALTY_SPECIAL_ITEM";
export const deleteLoyaltySpecialItemAction = genericAction(
  ACTION_DELETE_LOYALTY_SPECIAL_ITEM
);

const ACTION_EDIT_LOYALTY_SPECIAL_ITEM = "ACTION_EDIT_LOYALTY_SPECIAL_ITEM";
export const editLoyaltySpecialItemAction = genericAction(
  ACTION_EDIT_LOYALTY_SPECIAL_ITEM
);

export const ACTION_ADD_LOYALTY_SPECIAL_ITEM =
  "ACTION_ADD_LOYALTY_SPECIAL_ITEM";
export const addLoyaltySpecialItemAction = genericAction(
  ACTION_ADD_LOYALTY_SPECIAL_ITEM
);
