import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const getOverallRating = (
  token,
  filters = {}
): AxiosReturn<{ overall: number; total: number }> => {
  return axios.get(Requests.overallRating, {
    headers: {
      token,
    },
    params: filters,
  });
};
