import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { updateNotifierArabic } from "../components/UpdateNotifier/i18n/ar";
import { updateNotifierEnglish } from "../components/UpdateNotifier/i18n/en";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { ...updateNotifierEnglish },
  ar: { ...updateNotifierArabic },
} as any;

i18n.use(LanguageDetector).init({
  resources,

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
