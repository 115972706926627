import { useMemo } from "react";
import {useTranslation} from "./useTranslation";

export interface ICSSModule {
  [className: string]: string;
}

export const useRtlClass = (stylesModule: ICSSModule) => {
  const {i18n} = useTranslation();
  return useMemo(() => {
    const isRtl = i18n.language === 'ar';
    const rtlClass = stylesModule.rtl;
    const hasRtlClass = !!stylesModule.rtl;
    const shouldReturnRtlClass = isRtl && hasRtlClass;
    return shouldReturnRtlClass ? rtlClass : '';
  }, [i18n, stylesModule]);
};

