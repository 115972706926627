import React, { useState } from "react";
// styles
import styles from "./styles.module.sass";
// antd
import { Select } from "antd";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import i18n from "../../../../../../i18n/i18n";
interface IProps {
  typesArr: { id: string; name: string; ar_name?: string; en_name?: string }[];
  onChange: (val: string) => void;
  showSearch?: boolean;
  prefixText?: string;
  includeAllOption?: boolean;
  width?: any;
}

const TypeFilter: React.FC<IProps> = (props) => {
  const { t } = useTranslation("home");
  const [currentSelection, setCurrentSelection] = useState<string>("all");
  const [searchInput, setSearchInput] = useState({ value: "", focused: false });
  const handleSelectChange = (val) => {
    setCurrentSelection(val);
    props.onChange(val);
    setSearchInput({
      value: "",
      focused: false,
    });
  };
  const mapCurrentSelectionValToName = (value) => {
    return [
      ...props?.typesArr,
      ...(props.includeAllOption ? [{ id: "all", name: t(Strings.all) }] : []),
    ].filter((type) => type.id === value)[0]?.name;
  };
  const [open, setOpen] = useState(false);
  const handleSearchChange = (value) => {
    setSearchInput({
      ...searchInput,
      value,
    });
  };

  return (
    <>
      <Select
        onDropdownVisibleChange={() => {
          setOpen(!open);
        }}
        suffixIcon={
          searchInput.focused && open && props.showSearch ? (
            <React.Fragment>
              <Select.Option value={"all"}>{t(Strings.all)}</Select.Option>
              <img
                src={require("../../../../../../assets/search.svg").default}
              />
            </React.Fragment>
          ) : (
            <img
              style={{
                width: "14px",
                height: "14px",
                transform: open ? "rotate(180deg)" : undefined,
              }}
              src={
                require("../../../../../../assets/feather _ chevron-down.svg")
                  .default
              }
            />
          )
        }
        searchValue={searchInput.value}
        onFocus={() => setSearchInput({ ...searchInput, focused: true })}
        onBlur={() => setSearchInput({ ...searchInput, focused: false })}
        onSearch={handleSearchChange}
        showSearch={props.showSearch}
        style={{
          width: props.width ? `${props.width}` : "290px",
          // minWidth: 120,
          border:
            open && searchInput.focused && props.showSearch
              ? "#ffbd7a 1px solid"
              : undefined,
          backgroundColor:
            open && searchInput.focused && props.showSearch
              ? "#fff"
              : undefined,
          maxWidth: "100%",
          margin: "0 1rem",
        }}
        placeholder={t(Strings.search_branch_name)}
        optionFilterProp="children"
        onChange={handleSelectChange}
        value={`${props.prefixText}: ${
          mapCurrentSelectionValToName(currentSelection).length > 10
            ? mapCurrentSelectionValToName(currentSelection).substring(0, 10) +
              "..."
            : mapCurrentSelectionValToName(currentSelection)
        }`}
      >
        {props.includeAllOption && (
          <Select.Option value={"all"}>{t(Strings.all)}</Select.Option>
        )}
        {props.typesArr.map((type) => (
          <Select.Option key={type.id} value={type.id}>
            {!type.ar_name && !type.en_name
              ? type.name
              : `${i18n.language === "en" ? type.en_name : type.ar_name}`}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default TypeFilter;
