import axios from "../axios";
import { posEntitiesURL } from "../requests";

export const getPosEntitiesApi = (token: string) => {
  return axios.get(posEntitiesURL, {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_POS_ENDPOINT,
  });
};
