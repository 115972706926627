import { call, put, select, takeLatest } from "redux-saga/effects";
import { getStoreItemsAction } from "../../constants";
import { ICataloguedItems } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { getStoreItems } from "../../axios/getStoreItems";

function* getStoreItemsSaga() {
  try {
    const selectedToken = yield select(selectToken);
    const storeItemsRes = yield call(getStoreItems, selectedToken);
    const items = storeItemsRes.data as ICataloguedItems[];
    yield put(actions.getStoreItemsSuccess(items || []));
    yield put(actions.getStore());
  } catch (e) {
    yield put(actions.getStoreItemsFailure(e));
  }
}

export function* watchGetStoreItemsSaga() {
  yield takeLatest(getStoreItemsAction.requested, getStoreItemsSaga);
}
