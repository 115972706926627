import * as React from "react";
// import { SelectItem } from "../../GenericComponents/MUltipleSelect/SelectItem";
import MultipleSelect from "../../../GenericComponents/MUltipleSelect";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import styles from "./styles.module.sass";
import { RouteComponentProps } from "react-router";
import { InjectedTranslateProps, translate } from "react-i18next";
import * as Strings from "../../../../../i18n/strings/MultipleSelect";
// import BranchForm from "../../../context/BranchForm";
import { useSelect } from "../../../../../hooks/useSelect";
import { IBranch, PickupStatus } from "../../../../../types/shared";
import { clearPreviousState } from "../../../redux-store/actions/pickupBranchesActions";
// import { GenericButtonWithoutIcon } from "../../../SharedComponent/GenericButtonWithoutIcon";
import { useDispatch } from "react-redux";

const isInitialized = (data) => {
  if (data) {
    const hasKeys = Object.keys(data).length > 0;
    if (hasKeys) {
      let hasBranches = Object.keys(data.pickup_branch_ids).length > 0;
      hasBranches =
        hasBranches || Object.keys(data.delivery_branch_ids).length > 0;
      return hasBranches;
    }
  }
  return false;
};
export const selectedBranchesMap = (branches: IBranch[]) => {
  return branches.reduce(
    (acc, next) => {
      const branchIsEnabled =
        next.pickup_status &&
        next.pickup_status !== PickupStatus.service_disabled;
      const deliveryIsEnabled =
        next.delivery_status &&
        next.delivery_status !== PickupStatus.service_disabled;
      const driveThruIsEnabled = next.drive_thru_status &&
        next.drive_thru_status !== PickupStatus.service_disabled;
      return {
        ...acc,
        pickup_branch_ids: {
          ...acc.pickup_branch_ids,
          [next.id]: branchIsEnabled,
        },
        delivery_branch_ids: {
          ...acc.delivery_branch_ids,
          [next.id]: deliveryIsEnabled,
        },
        drive_thru_branch_ids: {
          ...acc.drive_thru_branch_ids,
          [next.id]: driveThruIsEnabled,
        }
      };
    },
    { pickup_branch_ids: {}, delivery_branch_ids: {}, drive_thru_branch_ids: {} }
  );
};
const SelectedBranches: React.FC<
  InjectedFormProps & RouteComponentProps & InjectedTranslateProps
> = ({ t, change }) => {
  const dispatch = useDispatch();
  const { allBranches: branches, loaded } = useSelect(
    (state) => state.branchesReducer
  );
  const items = React.useMemo(
    () =>
      branches
        .filter((b) => !b.call_center)
        .map((item) => ({
          name: item.name,
          value: item.id,
          pos_vendor: item.pos_vendor,
        })),
    [branches]
  );
  const initialized = React.useRef(false);
  const { status: submitOrderingBranches } = useSelect(
    (state) => state.submitPickupBranchesReducer
  );
  React.useEffect(() => {
    if (loaded && !initialized.current) {
      change("selectedBranchesMap", (oldValue) => {
        if (!isInitialized(oldValue)) {
          return selectedBranchesMap(branches);
        }
        return oldValue;
      });
      initialized.current = true;
    }
  }, [branches, initialized, loaded]);
  React.useEffect(() => {
    if (submitOrderingBranches === "success") {
      dispatch(clearPreviousState());
      change("selectedBranchesMap", {
        pickup_branch_ids: {},
        delivery_branch_ids: {},
        // drive_thru_branch_ids: {}
      });
    }
  }, [submitOrderingBranches]);
  // const { openBranchDrawer } = React.useContext(BranchForm);

  // const handleClickAddBranch = React.useCallback(() => {
  //   openBranchDrawer();
  // }, [openBranchDrawer]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.btnWrapper}>
        <div className={styles.header}>
          <span className={styles.branchesCount}>
            {t(Strings.branches)} ({items.length})
          </span>
          <p>{t(Strings.description)}</p>
        </div>
        {/* <GenericButtonWithoutIcon
          label={`+ ${t(Strings.newBranch)}`}
          onClick={handleClickAddBranch}
          className={styles.btn}
        /> */}
      </div>
      <form className={styles.main}>
        <Field
          items={items}
          name="selectedBranchesMap"
          component={MultipleSelect}
        />
      </form>
    </div>
  );
};

export default translate("MultipleSelect")(
  reduxForm({
    form: "PickupBranchesForm",
    destroyOnUnmount: false,
  })(SelectedBranches as any)
);
