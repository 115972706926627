import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getLoyaltyMenuAction } from "../../../constants";
import * as actions from "../../actions";
import { ICataloguedItems } from "../../../types/wizard-types";
import { mapById } from "../../../hooks/use-cashiers";

type LoyaltyMenuState = {
  categoriesIds: string[];
  shownCategoriesIds: string[];
  categoriesById: Record<string, ICataloguedItems>;
  shownCategoriesById: Record<string, ICataloguedItems>;
  selectedCategoryId: string | undefined;
};

export default withLoadingReducer<LoyaltyMenuState>(
  reducer<LoyaltyMenuState>(
    [
      on(actions.getLoyaltyMenuSuccess, (state, { payload }) => {
        const { categories, shownCategories } = payload.reduce<{
          categories: ICataloguedItems[];
          shownCategories: ICataloguedItems[];
        }>(
          (acc, category) => {
            const items = category.items.map((item) => ({
              ...item,
              category_id: category.id,
            }));

            const itemsNotInLoyalty = items.filter(
              (item) => !item.is_in_loyalty_program
            );

            acc.categories.push({ ...category, items });
            if (itemsNotInLoyalty.length > 0) {
              acc.shownCategories.push({ ...category, items: itemsNotInLoyalty });
            }

            return acc;
          },
          { categories: [], shownCategories: [] }
        );
        return {
          ...state,
          categoriesIds: categories.map((item) => item.id),
          shownCategoriesIds: shownCategories.map((item) => item.id),
          categoriesById: mapById(categories),
          shownCategoriesById: mapById(shownCategories),
        };
      }),
      on(actions.searchLoyaltyMenuItems, (state, { payload }) => {
        const filteredCategoriesById = Object.entries(state.categoriesById)
          .filter(
            ([, value]) =>
              !state.selectedCategoryId || value.id === state.selectedCategoryId
          )
          .reduce((acc, [key, value]) => {
            const filteredItems = value.items.filter(
              (item) =>
                !item.is_in_loyalty_program &&
                item.name.toLowerCase().includes(payload.toLowerCase())
            );

            if (filteredItems.length > 0) {
              acc[key] = {
                ...value,
                items: filteredItems,
              };
            }
            return acc;
          }, {});
        return {
          ...state,
          shownCategoriesById: filteredCategoriesById,
          shownCategoriesIds: Object.keys(filteredCategoriesById),
        };
      }),
      on(actions.filterLoyaltyMenuItemsByCategory, (state, { payload }) => {
        let shownCategoriesIds: string[] = [];
        let shownCategoriesById: Record<string, ICataloguedItems> = {};
        if (payload) {
          const filteredItems = state.categoriesById[payload].items.filter(
            (item) => !item.is_in_loyalty_program
          );
          if (filteredItems.length > 0) {
            shownCategoriesIds = [payload];
            shownCategoriesById = {
              [payload]: {
                ...state.categoriesById[payload],
                items: filteredItems,
              },
            };
          } else {
            shownCategoriesIds = [];
            shownCategoriesById = {};
          }
        } else {
          shownCategoriesById = Object.entries(state.categoriesById).reduce(
            (acc, [key, value]) => {
              const filteredItems = value.items.filter(
                (item) => !item.is_in_loyalty_program
              );

              if (filteredItems.length > 0) {
                acc[key] = {
                  ...value,
                  items: filteredItems,
                };
              }

              return acc;
            },
            {}
          );

          shownCategoriesIds = Object.keys(shownCategoriesById);
        }
        return {
          ...state,
          selectedCategoryId: payload,
          shownCategoriesIds,
          shownCategoriesById,
        };
      }),
      on(actions.editLoyaltyMenuItemSuccess, (state, { payload }) => {
        const updatedShownCategoriesById: Record<string, ICataloguedItems> =
          Object.entries(state.shownCategoriesById).reduce(
            (acc, [key, value]) => {
              const updatedItems =
                !payload.is_in_loyalty_program && key === payload.category_id
                  ? [...value.items, payload]
                  : value.items
                    .map((item) =>
                      item.id === payload.id
                        ? {
                          ...payload,
                        }
                        : item
                    )
                    .filter((item) => !item.is_in_loyalty_program);

              if (updatedItems.length > 0) {
                acc[key] = {
                  ...value,
                  items: updatedItems,
                };
              }

              return acc;
            },
            {}
          );
        const updatedCategoriesById: Record<string, ICataloguedItems> =
          Object.entries(state.categoriesById).reduce((acc, [key, value]) => {
            const updatedItems = value.items.map((item) =>
              item.id === payload.id
                ? {
                  ...item,
                  ...payload,
                }
                : item
            );
            acc[key] = {
              ...value,
              items: updatedItems,
            };
            return acc;
          }, {});

        return {
          ...state,
          shownCategoriesIds: Object.keys(updatedShownCategoriesById),
          shownCategoriesById: updatedShownCategoriesById,
          categoriesById: updatedCategoriesById,
        };
      }),
    ],
    {
      categoriesIds: [],
      shownCategoriesIds: [],
      shownCategoriesById: {},
      categoriesById: {},
      selectedCategoryId: undefined,
    }
  ),
  getLoyaltyMenuAction
);
