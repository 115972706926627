import * as React from "react";
import styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import { BeatLoader } from "react-spinners";
import TickIcon from "../../assets/tick.svg";
import InputErrorIcon from "../../assets/inputError.svg";

interface IProps {
  label: string;
  type: string;
  placeholder: string;
  autoFocus?: boolean;
  viewOnly?: boolean;
  className?: string;
  noValidationMessages?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  validationStyles?: {
    valid: React.CSSProperties;
    invalid: React.CSSProperties;
  };
  validationClassNames?: {
    valid: string;
    invalid: string;
  };
  val?: string;
  change?: (e) => void;
}

export const RenderGenericField: React.FC<WrappedFieldProps & IProps> = (
  props
) => {
  const {
    input,
    viewOnly,
    label,
    type,
    placeholder,
    meta: { touched, error, valid, asyncValidating, invalid, active },
    noValidationMessages,
    className,
    disabled,
    loading,
  } = props;

  const shouldShowLoading = asyncValidating || loading;

  const isInvalid = !shouldShowLoading && touched && invalid;
  const isValid = !shouldShowLoading && touched && valid;
  return (
    <div className={`${styles.inputWrapper} ${className || ""}`}>
      {label && (
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
        </label>
      )}
      <div
        style={{
          ...(active ? { borderColor: "#fdbb11" } : {}),
          ...(isValid ? { borderColor: "#38c172" } : {}),
          ...(isInvalid ? { borderColor: "#db3030" } : {}),
        }}
        className={`${styles.inputAndLoadingWrapper}`}
      >
        <input
          disabled={viewOnly || disabled}
          autoComplete="off"
          placeholder={placeholder}
          className={styles.input}
          id={input.name}
          {...input}
          type={type}
        />
        {isValid && (
          <img
            className={styles.genericInputBadge}
            src={TickIcon}
            alt="tick-icon"
          />
        )}
        {isInvalid && (
          <img
            className={styles.genericInputBadge}
            src={InputErrorIcon}
            alt="input-error-icon"
          />
        )}
        {shouldShowLoading && (
          <BeatLoader loading={true} size={7} color={"#FEBE19"} />
        )}
      </div>
      {!noValidationMessages && touched && error && (
        <p
          style={{
            margin: 0,
            padding: "1rem",
            color: "#db3030",
            fontSize: "1.2rem",
          }}
        >
          *{error}
        </p>
      )}
    </div>
  );
};
