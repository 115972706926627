import React from "react";
import { useGetCurrency } from "../../../../../../Utils";
import styles from "./styles.module.sass";
import GiftPlaceholderImage from "../../../../../../assets/gift-placeholder.svg";
interface IProps {
  image: string;
  name: string;
  price: number;
  redemptionsCount: number;
  rank: number;
  title: string;
}

export const RedeemedGiftCart: React.FC<IProps> = (props) => {
  const { name, title, price, redemptionsCount, rank } = props;

  const currency = useGetCurrency();

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.rank}>{rank}</div>
      <div className={styles.image}>
        <img className={styles.img} src={GiftPlaceholderImage} />
      </div>
      <div className={styles.name}>{name} </div>
      <div className={styles.price}>
        {price} {currency}
      </div>
      <div className={styles.count}>
        {title} : {redemptionsCount}
      </div>
    </div>
  );
};
