import { call, put, select, takeLatest } from "redux-saga/effects";
import { signupAction } from "../../constants";
import {
  signup,
  signupFailure,
  signupSuccess,
} from "../actions/signup-actions";
import { getStoreProfile } from "../../axios/storeProfile";
import { ILoginAction } from "../../types";
import { selectToken } from "../selectors";
import { delay } from "redux-saga/effects";
import { notify } from "react-notify-toast";
import history from "../../helpers/history";
import { translateLeaderboard } from "../../i18n/i18n";
import Strings from "../../i18n/strings/leaderboard";
import { goToLogin } from "../../axios/axios";

function* signupSaga(action: ILoginAction) {
  try {
    const selectedToken = yield select(selectToken);
    const token = action.payload || selectedToken;
    const storeProfileRes = yield call(getStoreProfile, token);
    const storeProfile = storeProfileRes.data.store;
    yield put(signupSuccess({ ...storeProfile, token }));
    yield call(history.push as any, `/`);
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem("using_universal_password");
      localStorage.removeItem("persist:auth");
      goToLogin();
    } else if (e.response.status === 500) {
      notify.show(
        translateLeaderboard(Strings.errorOccurredRetrying),
        "warning"
      );
      yield delay(1000);
      yield put(signup(action.payload));
      yield put(signupFailure(e));
    }
  }
}

export function* watchSignUpSaga() {
  yield takeLatest(signupAction.requested, signupSaga);
}
