import React from "react";
import styles from "./styles.module.sass";
import TodaysProgressCard from "../TodaysProgressCard";
// icons
import DollarIcon from "../../../../../../assets/feather _ dollar-sign.svg";
import UserIcon from "../../../../../../assets/feather _ user.svg";
import GiftIcon from "../../../../../../assets/feather _ gift.svg";
import TitleIcon from "../../../../../../assets/icon_solid_calender.svg";
import { Queries } from "../../../../../../Queries/Manager/Analytics";
import { useGetCurrency } from "../../../../../../Utils";
import { Row, Col } from "antd";
import { useIsVisible } from "react-is-visible";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import FeatherArrowIcon from "../FeatherArrowIcon/index";

const TodaysProgress: React.FC = () => {
  const containerRef = React.useRef(null);
  const isComponentVisible = useIsVisible(containerRef);

  const { t } = useTranslation("home");

  const {
    useGetTodaysTotalCustomers,
    useGetTodaysTotalOrders,
    useGetTodaysTotalRedemptions,
    useGetTodaysTotalSales,
  } = Queries;

  const {
    data: todaysTotalSalesData,
    refetch: refetchTodaysTotalSales,
    status: todaysTotalSalesStatus,
  } = useGetTodaysTotalSales();
  const {
    data: todaysTotalRedemptionsData,
    status: todaysTotalRedemptionsStatus,
    refetch: refetchTodaysTotalRedemptions,
  } = useGetTodaysTotalRedemptions();
  const {
    data: todaysTotalTransactionsData,
    status: todaysTotalTransactionsStatus,
    refetch: refetchTodaysTotalTransactions,
  } = useGetTodaysTotalOrders();
  const {
    data: todaysTotalCustomersData,
    status: todaysTotalCustomersStatus,
    refetch: refetchTodaysTotalCustomers,
  } = useGetTodaysTotalCustomers();

  React.useEffect(() => {
    if (
      todaysTotalRedemptionsStatus === "idle" &&
      isComponentVisible &&
      !todaysTotalRedemptionsData
    ) {
      refetchTodaysTotalRedemptions();
    }
    if (
      todaysTotalTransactionsStatus === "idle" &&
      isComponentVisible &&
      !todaysTotalTransactionsData
    ) {
      refetchTodaysTotalTransactions();
    }
    if (
      todaysTotalSalesStatus === "idle" &&
      isComponentVisible &&
      !todaysTotalSalesData
    ) {
      refetchTodaysTotalSales();
    }
    if (
      todaysTotalCustomersStatus === "idle" &&
      isComponentVisible &&
      !todaysTotalCustomersData
    ) {
      refetchTodaysTotalCustomers();
    }
  }, [isComponentVisible]);
  const defaults = {
    change: 0,
    total: "0",
  };

  type totalValuesType = {
    change: number;
    total: string;
  };

  const todaysTotalSales: totalValuesType =
    todaysTotalSalesData?.data || defaults;
  const todaysTotalRedemptions: totalValuesType =
    todaysTotalRedemptionsData?.data || defaults;
  const todaysTotalTransactions: totalValuesType =
    todaysTotalTransactionsData?.data || defaults;
  const todaysTotalCustomers: totalValuesType =
    todaysTotalCustomersData?.data || defaults;

  const currency = useGetCurrency();

  const generateChangeString = (val) =>
    val == "∞" ? (
      ""
    ) : val < 0 ? (
      <span className={styles.desc} style={{ color: "#38C172" }}>
        <FeatherArrowIcon width={"14"} height={"14"} stroke={"#38C172"} /> {val}
        % {t(Strings.from_yesterday)}
      </span>
    ) : (
      <span className={styles.desc} style={{ color: "#EE5253" }}>
        <div
          style={{
            transform: "rotate(180deg)",
          }}
        >
          <FeatherArrowIcon width={"14"} height={"14"} stroke={"#EE5253"} />{" "}
        </div>
        {val}% {t(Strings.from_yesterday)}
      </span>
    );

  return (
    <div
      className={`${styles.wrapper} ${useRtlClass(styles)}`}
      ref={containerRef}
    >
      {/* div title */}
      <div className={styles.title}>
        <span className={styles.titleIcon}>
          <img src={TitleIcon} />
        </span>
        {t(Strings.todays_progress)}
      </div>
      <Row className={styles.cardsWrapper}>
        <Col span={11}>
          <TodaysProgressCard
            callToAction={{
              route: "/insights/transactions",
              title: t(Strings.analyze_transactions),
            }}
            icon={DollarIcon}
            informationCells={[
              {
                bodyText: `${todaysTotalSales.total} ${currency}`,
                desc: generateChangeString(todaysTotalSales.change),
                title: t(Strings.sales),
              },
              {
                bodyText: todaysTotalTransactions.total,
                desc: generateChangeString(todaysTotalTransactions.change),
                title: t(Strings.transactions),
              },
            ]}
          />
        </Col>
        <Col span={6}>
          <TodaysProgressCard
            callToAction={{
              route: "/insights/customers",
              title: t(Strings.analyze_customers),
            }}
            icon={UserIcon}
            informationCells={[
              {
                bodyText: todaysTotalCustomers.total,
                desc: generateChangeString(todaysTotalCustomers.change),
                title: t(Strings.customers),
              },
            ]}
          />
        </Col>
        <Col span={6}>
          <TodaysProgressCard
            callToAction={{
              route: "/insights/redemptions",
              title: t(Strings.analyze_redemptions),
            }}
            icon={GiftIcon}
            informationCells={[
              {
                bodyText: todaysTotalRedemptions.total,
                desc: generateChangeString(todaysTotalRedemptions.change),
                title: t(Strings.redemptions),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TodaysProgress;
