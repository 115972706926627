import * as React from "react";
import styles from "./styles.module.sass";
import PasswordMask from "react-password-mask";
import { WrappedFieldProps } from "redux-form";
import i18n from "../../i18n/i18n";
import MaskIcon from "../../assets/mask.svg";
import TickIcon from "../../assets/tick.svg";
import InputErrorIcon from "../../assets/inputError.svg";

interface IProps {
  className: string;
  label: string;
  type: string;
  maxLength?: number;
  disabled?: boolean;
}

const eyeIcon = <img style={{ width: "2.5rem" }} src={MaskIcon} alt="" />;
const validIcon = <img style={{ width: "2.5rem" }} src={TickIcon} alt="" />;
const invalidIcon = (
  <img style={{ width: "2.5rem" }} src={InputErrorIcon} alt="" />
);

export class RenderPasswordField extends React.Component<
  WrappedFieldProps & IProps
> {
  public render() {
    const {
      input,
      disabled,
      label,
      className,
      maxLength,
      meta: { dirty, error, valid, invalid, active, touched },
    } = this.props;
    const isInvalid = (dirty || touched) && invalid;
    const isValid = (dirty || touched) && valid;
    let mask: JSX.Element | null = eyeIcon;
    const validationIcon = isInvalid ? invalidIcon : validIcon;
    mask = touched ? validationIcon : mask;
    mask = active ? eyeIcon : mask;
    return (
      <div
        onFocusCapture={input.onFocus}
        className={className ? styles.fullWidthInput : styles.inputWrapper}
      >
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
        </label>
        <PasswordMask
          className={styles.passwordMaskWrapper}
          maxLength={maxLength}
          inputClassName={`${styles.passInput} ${active ? styles.active : ""} ${
            isInvalid ? styles.invalid : ""
          } ${isValid ? styles.valid : ""}`}
          showButtonContent={mask}
          buttonClassName={i18n.language === "ar" ? styles.left : styles.right}
          hideButtonContent={mask}
          disabled={disabled}
          placeholder="......."
          {...input}
          buttonStyles={{ background: "none" }}
          id={input.name}
        />
        {isInvalid && (
          <p
            style={{
              margin: 0,
              padding: "1rem",
              color: "#db3030",
              fontSize: "1.2rem",
            }}
          >
            *{error}
          </p>
        )}
      </div>
    );
  }
}
