import { action, payload } from "ts-action";
import {
  importMenuAction,
  getMenuScrapingStateAction,
  verifyMenuCSVAction,
  uploadMenuCSVAction,
  downloadExcelSheetAction,
} from "../../constants/actions";
import { IMenuScraping } from "../../axios/importMenuApi";
export interface IMenuScrapingState {
  menu_import_status: MenuScrapingState;
}
export enum MenuScrapingState {
  failed,
  success,
  pending,
  null,
}
export const importMenu = action(
  importMenuAction.requested,
  payload<IMenuScraping>()
);
export const importMenuSuccess = action(
  importMenuAction.fulfilled,
  payload<IMenuScraping>()
);
export const importMenuFailure = action(
  importMenuAction.rejected,
  payload<Error>()
);

export const getMenuScrapingState = action(
  getMenuScrapingStateAction.requested
);

export const getMenuScrapingStateSuccess = action(
  getMenuScrapingStateAction.fulfilled,
  payload<IMenuScrapingState>()
);

export const getMenuScrapingStateFailure = action(
  getMenuScrapingStateAction.rejected,
  payload<Error>()
);

// scrapping via csv
// --> verification
export const verifyMenuCSV = action(verifyMenuCSVAction.requested);
export const verifyMenuCSVSuccess = action(verifyMenuCSVAction.fulfilled);
export const verifyMenuCSVFailure = action(
  verifyMenuCSVAction.rejected,
  payload<Error>()
);
// --> upload
export const uploadMenuCSV = action(uploadMenuCSVAction.requested);
export const uploadMenuCSVSuccess = action(uploadMenuCSVAction.fulfilled);
export const uploadMenuCSVFailure = action(
  uploadMenuCSVAction.rejected,
  payload<Error>()
);
// --> download
export const downloadExcelSheet = action(downloadExcelSheetAction.requested);
export const downloadExcelSheetSuccess = action(
  downloadExcelSheetAction.fulfilled,
  payload<string>()
);
export const downloadExcelSheetFailure = action(
  downloadExcelSheetAction.rejected,
  payload<Error>()
);
