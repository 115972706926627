import React, { useEffect, useState } from "react";
import { Modal, DatePicker, Alert, Button } from "antd";
import styles from "./styles.module.sass";
import DownloadIcon from "../../../../../../assets/download-icon.svg";
import CloseIcon from "../../../../../../assets/icon-close.svg";
import moment from "moment";
import { tz } from "moment-timezone";
import { useSelect } from "../../../../../../hooks/useSelect";

interface IProps {
  isVisible: boolean;
  onOk?: () => any;
  onCancel?: () => any;
  onTimeFilterChange?: (
    value: { from: number | null; to: number | null },
    isTimeRangeValid: Boolean
  ) => void;
  initialTimeFilterVal?: { from?: number; to?: number };
}

const ExportCSVModal: React.FC<IProps> = (props) => {
  const {
    isVisible,
    onCancel,
    onOk,
    children,
    onTimeFilterChange,
    initialTimeFilterVal,
  } = props;
  const { store } = useSelect((state) => state.storeReducer);
  const [timeFilterValue, setTimeFilterValue] = useState<{
    from: number | null;
    to: number | null;
  }>({ from: null, to: null });

  const [timeRangeIsValid, setTimeRangeIsValid] = useState<Boolean>(false);

  const handleFromPickerChange = (value) => {
    const from = value
      ? Number(tz(moment(value) as any, "x", store.timezone).startOf("day"))
      : null;
    setTimeFilterValue((prevVal) => ({ ...prevVal, from }));
  };

  const handleToPickerChange = (value) => {
    const to = value
      ? Number(tz(moment(value) as any, "x", store.timezone).endOf("day"))
      : null;
    setTimeFilterValue((prevVal) => ({ ...prevVal, to }));
  };

  // on time filter change
  useEffect(() => {
    const { from, to } = timeFilterValue;

    const isTimeRangeValid =
      typeof to === "number" &&
      typeof from === "number" &&
      from > 0 &&
      to > 0 &&
      to > from;

    setTimeRangeIsValid(isTimeRangeValid);

    onTimeFilterChange && onTimeFilterChange(timeFilterValue, isTimeRangeValid);
  }, [timeFilterValue]);

  useEffect(() => {
    if (!isVisible) {
      setTimeFilterValue({ from: null, to: null });
      setTimeRangeIsValid(false);
    } else if (initialTimeFilterVal) {
      const alignedFrom = Number(
        tz(
          moment(initialTimeFilterVal.from) as any,
          "x",
          store.timezone
        ).startOf("day")
      );

      const alignedTo = Number(
        tz(moment(initialTimeFilterVal.to) as any, "x", store.timezone).endOf(
          "day"
        )
      );

      setTimeFilterValue({
        from: alignedFrom,
        to: alignedTo,
      });
    }
  }, [isVisible]);

  return (
    <Modal
      visible={isVisible}
      onOk={() => {
        setTimeFilterValue({ from: null, to: null });
        setTimeRangeIsValid(false);
        onOk && onOk();
      }}
      footer={null}
      width={552}
      centered={true}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.closeButtonWrapper}>
          <button className={styles.closeButton} onClick={onCancel}>
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ height: "12px", width: "12px" }}
            />
          </button>
        </div>
        <div className={styles.iconWrapper}>
          <img
            src={DownloadIcon}
            alt="download-icon"
            className={styles.downloadIcon}
          />
        </div>
        <h3 className={styles.modalTitle}>Export CSV</h3>
        <p className={styles.modalSubtitle}>
          Please select the time period you want to export
        </p>
        <div className={styles.timeFiltersWrapper}>
          <div className={styles.singleFilterWrapper}>
            <p>From</p>
            <DatePicker
              style={{ borderRadius: 0, width: "240px" }}
              onChange={handleFromPickerChange}
              value={
                timeFilterValue.from
                  ? tz(timeFilterValue.from as any, store.timezone)
                  : undefined
              }
            />
          </div>
          <div className={styles.singleFilterWrapper}>
            <p>To</p>
            <DatePicker
              style={{ borderRadius: 0, width: "240px" }}
              onChange={handleToPickerChange}
              value={
                timeFilterValue.to
                  ? tz(timeFilterValue.to as any, store.timezone)
                  : undefined
              }
            />
          </div>
        </div>
        {!timeRangeIsValid &&
          typeof timeFilterValue.from === "number" &&
          typeof timeFilterValue.to === "number" && (
            <Alert
              className={styles.timeFilterErrMsg}
              message="Time range is not valid"
              type="error"
            />
          )}
        <div className={styles.separator}></div>
        {children}
      </div>
    </Modal>
  );
};

export default ExportCSVModal;
