export const searchPlaceholder = "searchPlaceholder";
export const notExistItem = "notExistItem";
export const branchSelected = "branchSelected";
export const branches = "branches";
export const newBranch = "newBranch";
export const next = "next";
export const description = "description";
export const branchName = "branchName";
export const disableOrdering = "disableOrdering";
export const disableOrderingQ = "disableOrderingQ";
export const disableAll = "disableAll";
export const cancel = "cancel";
export const confirm = "confirm";
