import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IAddStoreItemData, IStoreItem } from "../types/wizard-types";

export const addStoreItem = (
  token,
  data: IAddStoreItemData
): AxiosReturn<IStoreItem> => {
  return axios.post(Requests.storeItems, data, {
    headers: {
      token,
    },
  });
};
