export const getStatus = (status: number) => {
  if (status < 0 || status > 4) {
    throw new TypeError('Statuses are numbers the range from 0 to 4');
  }
  return [
    'Inactive',
    'Active',
    'In Progress',
    'Closed',][status];
};
