import React from "react";
import images from "../../assets/images/images";
import styles from "./styles.module.sass";
import { FileUploadedSuccessfullyProps } from "./types";
import { SettingsSwitch } from "../../helpers/Switch";


export const FileUploadedSuccessfully: React.FC<FileUploadedSuccessfullyProps> = (props) => {
  const {
    fileName,
    onCancel,
    onResetFile,
    onUploadPoints,
    onOptionsChange,
    uploadOptions
  } = props;
  return (
    <div className={styles.container}>
      <img
        className={styles.successfulImageIcon}
        src={images.checkLg}
      />

      <div className={styles.succssfulTitle}>
        <h1 className={styles.fileUploadedTitle} >File uploaded successfully</h1>
        <h2 className={styles.fileMaxUpload}>Max. 1 file</h2>
      </div>

      <div className={styles.filenameContainer}>
        <img
          src={images.uploadFileIcon}
        />
        <h3 className={styles.fileNameStyle}>{fileName}</h3>
      </div>
      <div className={styles.footer}>
        <div className={styles.checkboxesWrapper}>
          <div className={styles.checkboxWrapper}>
            <span className={styles.checkboxLabel}>
              Send transaction massages
            </span>
            <SettingsSwitch
              checked={Boolean(uploadOptions.notifyOnTransaction)}
              onChange={(checked) => {
                onOptionsChange({ ...uploadOptions, notifyOnTransaction: checked ? 1 : 0 })
              }}
              onColor={"#FFF4EA"}
              offColor={"#8E8E93"}
              height={28}
              onHandleColor={"#FFA351"}
              offHandleColor={"#D1D1D6"}
              handleDiameter={24}
              width={49}
              className={
                uploadOptions.notifyOnTransaction
                  ? styles.activeSwitch
                  : styles.inActiveSwitch
              }
            />
          </div>
          <div className={styles.checkboxWrapper}>
            <span className={styles.checkboxLabel}>
              Automatically follow the brand
            </span>
            <SettingsSwitch
              checked={Boolean(uploadOptions.autoFollowBrand)}
              onChange={(checked) => {
                onOptionsChange({ ...uploadOptions, autoFollowBrand: checked ? 1 : 0 })
              }}
              onColor={"#FFF4EA"}
              offColor={"#8E8E93"}
              height={28}
              onHandleColor={"#FFA351"}
              offHandleColor={"#D1D1D6"}
              handleDiameter={24}
              width={49}
              className={
                uploadOptions.autoFollowBrand
                  ? styles.activeSwitch
                  : styles.inActiveSwitch
              }
            />
          </div>
        </div>
        <div className={styles.verifiedContainer}>
          <div className={styles.confirmSendPointsContainer}>
            <h3 className={styles.confirmSendPointsTitle}>Confirm Sending Points?</h3>
          </div>

          <div className={styles.buttonsContainer}>
            <button
              className={styles.colseBtn}
              onClick={() => {
                onCancel();
                onResetFile();
              }}>
              <img
                src={images.crossIcon}
              />
              <h3 className={styles.buttonIcon}>No</h3>
            </button>

            <button
              className={styles.successBtn}
              onClick={() => {
                onUploadPoints();
              }}>
              <img
                src={images.checkUploadIcon}
              />
              <h3 className={styles.buttonIcon}>Yes</h3>
            </button>

          </div>
        </div>
      </div>

    </div>
  )
}