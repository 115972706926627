import c from "./convertingRatio";

export default {
  menu: "القائمة",
  syncOrderingMenuSuccess: "جاري المزامنة...",
  syncOrderingMenuFailure: "عملية المزامنة قيد التشغيل بالفعل",
  addMenuItems: "إضافة منتجات للقائمة",
  addedItemsCount: "عدد المنتجات المضافة",
  addItem: "إضافة منتج",
  syncPosMenu: "مزامنة قائمة الطعام",
  addCategory: "إضافة تصنيف",
  uploadByExcel: "استيراد ملف Excel",
  uploadMenu: "أضف قائمتك:",
  uploadByElMenus: "تحويل من ElMenus",
  uploadByHungerStation: "تحويل من HungerStation",
  uploadManually: "الإضافة يدوياً",
  uploadExternally: "تحويل من مصدر خارجي",
  itemName: "اسم المنتج",
  itemPhoto: "الصورة",
  itemPrice: "السعر",
  itemCategory: "التصنيف",
  itemIntegrationId: "رقم التكامل",
  newCategory: "تصنيف جديد",
  emptyCategories: "لم يتم إضافة أي تصنيفات",
  categoryName: "اسم التصنيف",
  emptyItems: "لم يتم إضافة أي منتجات",
  deleteCategoryTitle: "حذف تصنيف {{name}}",
  youAreAboutToDelete: "أنت على وشك حذف",
  item_0: "{{count}} منتج",
  item_1: "{{count}} منتج",
  item_2: "منتجان",
  item_3: "{{count}} منتجات",
  item_4: "{{count}} منتج",
  item_5: "{{count}} منتج",
  inThisCategory: "في هذا التصنيف",
  somethingWentWrong: c.somethingWentWrong,
  progressAutoSaved: c.progressAutoSaved,
  markComplete: c.markComplete,
  addingMenu: "يتم إضافة منتجاتك",
  waitDontGo: "رجاءأ انتظر قليلاً ولا تقم بإغلاق نافذة المتصفح",
  grabbingData: "جاري معالجة البيانات",
  failed: "فشل التحويل! رجاءاً أعد المحاولة.",
  editItem: "تعديل المنتج",
  delete: "حذف",
  addStoreItemSuccess: 'تم إضافة "{{itemName}}" إلى "{{categoryName}}" بنجاح!',
  addStoreItemFailure: 'فشل إضافة "{{itemName}}"',
  youCanAddGiftListItemsFrom:
    "يمكنك إضافة منتج لقائمة الهدايا من القائمة التي على يسارك",
  itemVisibility: "ظهور المنتج",
};
