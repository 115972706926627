import addItemFormPickup from "./addItemFormPickup";
import categoryPickup from "./categoryPickup";
import completeBranchesPickup from "./completeBranchesPickup";
import itemsPickup from "./itemsPickup";
import navigationPickup from "./navigationPickup";
import menuPickup from "./menuPickup";
import navbarPickup from "./navbarPickup";
import brandCategory from "./brandCategory";
import brandTags from "./brandTags";
import brandPhotoCover from "./brandPhotoCover";
import brandOpeningHours from "./brandOpeningHours";
import progressSettings from "./progressSettings";
import finalStep from "./finalStep";
import menuErrorMessages from "./menuErrorMessages";
import minOrderValue from "./minOrderValue";
import routingMethod from "./routingMethod";
import orderHistory from "./orderHistory";
import paymentMethods from "./paymentMethods";
import webPortal from "./webPortal";
import deliveryMethod from "./deliveryMethod";
export default {
  addItemFormPickup,
  categoryPickup,
  navigationPickup,
  itemsPickup,
  paymentMethods,
  completeBranchesPickup,
  menuPickup,
  navbarPickup,
  orderHistory,
  brandCategory,
  brandTags,
  brandPhotoCover,
  brandOpeningHours,
  progressSettings,
  finalStep,
  menuErrorMessages,
  minOrderValue,
  routingMethod,
  webPortal,
  deliveryMethod,
};
