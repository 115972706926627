import * as React from "react";
import styles from "./styles.module.sass";

interface IProps {
  cover?: string;
  logo?: string;
  backGroundColor?: string;
  brandName: string;
  title: string;
}

export class MobileMock extends React.Component<IProps> {
  public render() {
    const { cover, brandName, logo, backGroundColor, title } = this.props;
    return (
      <div className={styles.wrapper}>
        <p className={styles.text}>{title}</p>
        <div className={styles.mobile}>
          <div className={styles.notch} />
          {cover ? (
            <img src={cover} className={styles.cover} />
          ) : (
            <div className={styles.cover} />
          )}
          <div className={styles.brandWrapper}>
            <img
              className={styles.logo}
              src={logo || require("../../../../assets/brand-logo.svg").default}
            />
            <p className={styles.brandName}>
              {brandName.substring(0, 7)}
              {brandName.length > 7 ? "..." : ""}
            </p>
          </div>
          <div style={{ background: backGroundColor }} className={styles.bg}>
            <img
              className={styles.map}
              src={require("../../../../assets/mock-map.svg").default}
            />
          </div>
        </div>
      </div>
    );
  }
}
