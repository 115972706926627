import React, { useEffect, useState } from "react";
import styles from "./styles.module.sass";

// hooks
import { useSelect } from "../../../../../../hooks/useSelect";
// components
import { LineGraph } from "../../../Home/components/LineGraph";
import { TimeFilter, TypeFilter } from "../../../Home/components/index";
// assets
import InfoIcon from "../../../../../../Components/Pickup/assets/group-2.svg";

// queries
import { useGetFulfillmentRateChart } from "../../../../../../Queries/Manager";
// helpers
import { getHoursLabels } from "../../../../../../helpers/analytics";
import * as configs from "../../../Home/components/LineGraph/configs";
import MultiSelect, {
  mapStringArryToSelectedArray,
} from "../../../../../../@koinz-ui/MultiSelect";
import { ITotalOrders } from "../../../../../../Components/Pickup/lib/types";
import FeatherArrowIcon from "../../../Home/components/FeatherArrowIcon";
import i18n from "../../../../../../i18n/i18n";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";

interface IProps {
  totalOrdersData: ITotalOrders;
}

export enum OrderType {
  pickup = "pickup",
  delivery = "delivery",
}

export enum OrderingSource {
  ordering_portal = "ordering_portal",
  customer_app = "customer_app",
}

//
const FulfillmentRateChart: React.FC<IProps> = (props) => {
  const primaryColor = "#FFA351";
  const {
    store: { created_at, id: store_id },
    token,
  } = useSelect((state) => {
    return {
      ...state.storeReducer,
      ...state.authReducer,
    };
  });

  const { allBranches } = useSelect((state) => state.branchesReducer);

  //
  const { t } = useTranslation("home");
  //
  const [getOrdersChartReqConfigs, setGetOrdersChartRequestConfig] = useState<{
    from: number;
    to: number;
    unit: "hour" | "day";
    branch_ids?: string[];
    order_type?: OrderType;
    ordering_source?: OrderingSource;
  }>({
    from: 0,
    to: 0,
    unit: "day",
  });

  const [lineGraphProps, setLineGraphProps] = useState<any>({
    width: 1080,
    height: 230,
    values: {
      datasets: [],
      labels: [],
    },
  });

  //
  const [onlineOrdersData, setOnlineOrdersData] = useState<any[]>();

  // handling api requests
  const { data, isLoading: onlineOrdersIsLoading } = useGetFulfillmentRateChart(
    token,
    getOrdersChartReqConfigs
  );

  const [typeFilterValue, setTypeFilterValue] = useState<
    "all" | "offline_orders_count"
  >("all");

  useEffect(() => {
    if (data) {
      setOnlineOrdersData(
        data.map((row) => ({
          ...row,
          fulfillment_rate:
            row.completed_orders /
              (row.total_orders - row.canceled_by_customer_orders) >
            0
              ? (row.completed_orders /
                  (row.total_orders - row.canceled_by_customer_orders)) *
                100
              : 0,
        }))
      );
    }
  }, [data]);

  // when data is recieved
  useEffect(() => {
    if (onlineOrdersData && !onlineOrdersIsLoading) {
      const filteredData = (onlineOrdersData || [])
        .filter(
          (element) =>
            !(element.total_orders === 0 && element.date === "1970-01-01")
        )
        .map((element) => {
          return {
            ...element,
            yAxisValue: "fulfillment_rate",
          };
        });

      if (getOrdersChartReqConfigs.unit === "hour") {
        const hoursLabels = getHoursLabels(onlineOrdersData || []);
        setLineGraphProps((prevValue) => ({
          ...prevValue,
          values: {
            ...prevValue.values,
            labels: hoursLabels,
            datasets: [
              {
                data: mapDataWithLabels(filteredData, hoursLabels),
                borderColor: primaryColor,
                borderWidth: 2,
              },
            ],
          },
        }));
      } else if (getOrdersChartReqConfigs.unit === "day") {
        const exactLabels = getExactPointsLabels(onlineOrdersData || []);
        setLineGraphProps((prevValue) => ({
          ...prevValue,
          values: {
            ...prevValue.values,
            labels: exactLabels,
            datasets: [
              {
                data: mapDataWithLabels(filteredData, exactLabels),
                borderColor: primaryColor,
                borderWidth: 2,
              },
            ],
          },
        }));
      }
    }
  }, [
    typeFilterValue,
    onlineOrdersIsLoading,
    onlineOrdersData,
    getOrdersChartReqConfigs,
  ]);

  // mapping data with labels
  const mapDataWithLabels = (data, labels) => {
    return data.map((element, index) => ({ ...element, label: labels[index] }));
  };

  // Setting request configs
  useEffect(() => {
    if (typeof created_at === "number") {
      const now = new Date();
      const nowTimeStamp = now.getTime();
      const difference = nowTimeStamp - created_at;

      let daysFromStoreCreation = 0;

      const dateOfTodayAYearAgo = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );

      const timestampOfADay = 60 * 60 * 24 * 1000;

      daysFromStoreCreation = difference / timestampOfADay;

      // if less than a day => unit = "hour" else "day"
      setGetOrdersChartRequestConfig((prevValue) => ({
        ...prevValue,
        from:
          daysFromStoreCreation <= 28
            ? created_at
            : new Date(now.getTime() - 28 * timestampOfADay).getTime(),
        to: nowTimeStamp,
        unit: daysFromStoreCreation < 1 ? "hour" : "day",
        store_id,
      }));
    }
  }, [created_at, store_id]);

  const getExactPointsLabels = (
    data: {
      date: string;
      [key: string]: number | string;
    }[]
  ) => {
    return data.map((element) => {
      const formattedDate = Date.parse(element.date);
      const monthName = new Intl.DateTimeFormat(i18n.language, {
        month: "short",
      }).format(formattedDate);
      const day = new Intl.DateTimeFormat(i18n.language, {
        day: "2-digit",
      }).format(formattedDate);

      return `${monthName} ${day}`;
    });
  };

  const handleTimeFilterChange = (value: {
    from: number;
    to: number;
    unit: "hour" | "day";
  }) => {
    setGetOrdersChartRequestConfig((prevValue) => ({
      ...prevValue,
      from: value.from,
      to: value.to,
      unit: value.unit,
    }));
  };

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  // branch filter
  const handleBranchFilterMultiSelectChange = (values) => {
    if (values.length === allBranches.length) {
      setGetOrdersChartRequestConfig({
        ...getOrdersChartReqConfigs,
        branch_ids: undefined,
      });
    } else {
      setGetOrdersChartRequestConfig({
        ...getOrdersChartReqConfigs,
        branch_ids: values.map((v) => v.id),
      });
    }
  };

  const hasChangeValueInTotalValueRes =
    typeof props.totalOrdersData?.change !== "undefined";
  return (
    <>
      <div className={styles.numbersAndFiltersContainer}>
        <div className={styles.totalOrdersWrapper}>
          <div className={styles.totalOrdersMain}>
            <span
              className={styles.totalOrdersTitle}
              style={{
                marginRight: hasChangeValueInTotalValueRes ? ".1rem" : ".6rem",
              }}
            >
              {(Number(props.totalOrdersData.total || 0) * 100).toFixed(2)} %
            </span>
            {hasChangeValueInTotalValueRes && (
              <React.Fragment>
                {Number(props.totalOrdersData.change) > 0 ? (
                  <FeatherArrowIcon
                    width={"20"}
                    height={"20"}
                    stroke={"#000000"}
                  />
                ) : (
                  <div style={{ transform: "rotate(180deg)" }}>
                    <FeatherArrowIcon
                      width={"20"}
                      height={"20"}
                      stroke={"#000000"}
                    />
                  </div>
                )}
              </React.Fragment>
            )}

            <img src={InfoIcon} className={styles.infoIcon} />
          </div>
          {hasChangeValueInTotalValueRes ? (
            <span className={styles.totalOrdersSubtitle}>
              {t(Strings.fulfillment_percentage_from_last_30_days, {
                fulfillment_percentage: `${Number(
                  props.totalOrdersData.change
                ).toFixed(2)}%`,
              })}
            </span>
          ) : (
            <span className={styles.totalOrdersSubtitle}>
              {t(Strings.orders)}
            </span>
          )}
        </div>

        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* type filter */}

            <div className={styles.branchMultiSelect}>
              <MultiSelect
                label={t(Strings.branch)}
                canSelectAll={true}
                onApply={handleBranchFilterMultiSelectChange}
                options={branchesOptions}
                selected={
                  getOrdersChartReqConfigs.branch_ids || [].length > 0
                    ? mapStringArryToSelectedArray(
                        getOrdersChartReqConfigs.branch_ids || []
                      )
                    : allBranches.map((b) => ({ id: b.id }))
                }
              />
            </div>

            <TimeFilter
              removeMenuOptionsIds={["today", "yesterday"]}
              valueKey="last-28-days"
              onChange={handleTimeFilterChange}
              showArrow
            />
          </div>
        </div>
      </div>

      <LineGraph
        {...lineGraphProps}
        lineGraphConfigs={configs.fulfillmentRateConfigs}
      />
    </>
  );
};

export default FulfillmentRateChart;
