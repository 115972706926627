import { BounceLoader, ReactSpinners } from "react-spinners";
import React from "react";
import styles from "./styles.module.sass";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const LoadingPageInfo = () => {
  return (
    <div className={styles.loadingWrapper}>
      <DefaultLoader />
    </div>
  );
};

export const DefaultLoader = (props: ReactSpinners.BounceLoaderProps) => {
  const CustomSpinner = (
    <LoadingOutlined style={{ fontSize: 50 }} spin {...props} />
  );
  return <Spin indicator={CustomSpinner} />;
};
