import sl from "../en/socialLinks";
import convertingRatio from "./convertingRatio";

export default {
  newCashier: "New Cashier",
  phoneNumber: "Phone Number",
  name: "Name",
  branch: "Branch",
  noMatch: "No Match",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  noCashiersAreAddedYet: "No cashiers are added yet!",
  thereIsNoMatch: "There is no match!",
  searchForCashiers: "Search For Cashiers..",
  all: "All",
  unassigned: "Unassigned",
  assigned: "Assigned",
  UNASSIGNED: "UNASSIGNED",
  cashierName: "Cashier name",
  assignToBranches: "Assign To Branches",
  password: "Password",
  integrationId: "Integration ID",
  addBranchTitle: "Add New Branch",
  addToBranchTitle: "Add To Branch",
  addCallCenterTitle: "Add to call center",
  addCashier: "Add Cashier",
  viewCashier: "View Cashier",
  editCashier: "Edit Cashier",
  unassignedCashiers: "Unassigned Cashiers",
  assignedCashiers: "Assigned Cashiers",
  submit: sl.submit,
  deleteCashier: "Delete Cashier",
  deleteCashierDescription:
    "Your are about to delete this cashier. confirm to continue",
  confirmDelete: "Confirm",
  cancel: "Cancel",
  callCenterBranch: "Call Center",
  loyaltyTooltipText: "Turn off to disable loyalty tab access for this cashier account",
  loyaltyText: 'Enable Loyalty Tab'
};