import { Modal, ModalBody } from "reactstrap";
import * as React from "react";
import { hot } from "react-hot-loader";
import { translate, InjectedTranslateProps } from "react-i18next";
import styles from "./styles.module.sass";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { translate as useTranslate } from "../../helpers/translate";
import Strings from "../../i18n/Manager/strings/insights"
interface IProps {
  modal: boolean;
  toggle: () => void;
  alertType: "remove" | "block";
  behavior: () => void;
  isLoading: boolean,
}

type AllProps = IProps &
  InjectedTranslateProps;

const Confirmation: React.FC<AllProps> = ({ toggle, modal, alertType, behavior, isLoading }) => {
  const t = useTranslate("insights");
  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  const message = React.useMemo(() => alertType === "remove" ? <p className={styles.message}>
    {t(Strings.removePointsConfirmationMsg)}
    {/* Are you sure you want to <strong>
      remove user’s points?
    </strong> */}
  </p> :
    <p className={styles.message}>
      {t(Strings.blockCustomerconfirmationMsg)}
      {/* Are you sure you want to <strong>block user?
      </strong> (This will remove all of the customer past and future points) */}

    </p>, [alertType]);
  const buttonContent = React.useMemo(() => !isLoading && alertType === "block" ? t(Strings.blockCustomerCta) : t(Strings.removePointsCta), [isLoading, alertType])
  return (
    <Modal isOpen={modal} toggle={toggle}  >
      <ModalBody>
        <div className={styles.container} style={{
          padding: "20px",
          zIndex: 1000000
        }}>
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }} >
            <button data-testid="close-button" className={styles.closeContainer} onClick={() => toggle()}>
              &times;
            </button>
          </div>
          <div className={styles.imageContainer} >
            <img src={require("../../assets/confirm-icon.svg").default} alt="closeButton" style={{ width: "80px", height: "80px" }} />
          </div>
          <div className={styles.contentContainer}>
            <h3 className={styles.header} >
              {t(Strings.action)}
            </h3>
            {message}
          </div>
          <div className={styles.buttonContainer}>
            <button data-testid="cancel-button" className={`${styles.btn} ${styles.cancel}`} onClick={() => toggle()}>
              {t(Strings.cancelCta)}
            </button>
            <button data-testid="confirm-button" onClick={() => {
              behavior();
              toggle();
            }} className={`${styles.btn} ${styles.danger}`}>
              {isLoading && <Spin indicator={CustomSpinner} />}
              {buttonContent}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};


export default hot(module)(
  translate("leaderboard")(Confirmation as any)
) as any;
