import * as React from "react";
import styles from "./styles.module.sass";
import { InjectedTranslateProps } from "react-i18next";
import { hot } from "react-hot-loader";
import { translate } from "react-i18next";
import CashiersTable from "../CashiersTable";
import { ButtonWithIcon } from "../../ButtonWithIcon";
import { useCashiers } from "../../../../hooks/use-cashiers";
import { useMemo } from "react";
import AddEditCashierForm from "../AddEditCashierForm";
import {
  useCashierCRUD,
  useCheckCashierPhone,
  useCashierChangeFormType,
  useCashiersFilters,
  EXISTS_IN_ANOTHER_BRAND,
  CASHIER_ACCOUNT_STATUS,
} from "./cashiers-hooks";
import { ICashier } from "../../../../axios/getCashiers";
import Strings from "../../../../i18n/strings/cashiers";
import StoreData from "../../../../contexts/StoreData";
import { searchCashiers } from "../../../../axios/searchCashiers";
import { trackEvent } from "../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../constants/events-for-tracking";
import { IBranch } from "../../../../types/shared";

const useToggleState = (
  initialValue = false
): [boolean, (forceSelect?: boolean) => void] => {
  const [value, setValue] = React.useState(initialValue);
  return [
    value,
    (newState) => setValue(newState === undefined ? !value : newState),
  ];
};

interface IProps extends InjectedTranslateProps {
  branches: IBranch[];
  selectedBranchId: string;
  handleSelectedBranchId: (id: string) => void;
  getBranches: () => void;
  hasCallCenterBranch: boolean;
  hasPOS: boolean;
}

export const processCashierBranches = (
  cashier: ICashier,
  branchesMap: { [branchId: string]: IBranch }
) => ({
  ...cashier,
  branches: (cashier.branch_ids || []).map((branchId) => branchesMap[branchId]),
});

const Cashiers: React.FunctionComponent<IProps> = ({
  t,
  branches,
  selectedBranchId,
  handleSelectedBranchId,
  getBranches,
  hasCallCenterBranch,
  hasPOS,
}) => {
  const {
    cashiers,
    success,
    failure,
    submissionFailed,
    submissionSucceeded,
    setSubmissionSucceeded,
    createCashier,
    createCallCenterCashier,
    editCashier,
    deleteCashier,
    restoreCashier,
  } = useCashiers(branches);
  const branchesMap = useMemo(() => {
    return branches.reduce((acc, next) => {
      return {
        ...acc,
        [next.id]: next,
      };
    }, {});
  }, [branches]);
  const [addEditCashierOpen, toggleAddEditCashier] = useToggleState();
  const {
    currentForm,
    setFormToAddCashier,
    setFormToEditCashier,
    setFormToViewCashier,
    formType,
    setFormToRestoreCashier,
  } = useCashierCRUD(
    createCashier as any,
    createCallCenterCashier as any,
    editCashier as any,
    restoreCashier as any,
    t,
    getBranches,
    submissionSucceeded,
    addEditCashierOpen
  );
  const [
    cashierAccountStatus,
    setCashierAccountStatus,
  ] = React.useState<CASHIER_ACCOUNT_STATUS>(3);
  const [foundCashier, setFoundCashier] = React.useState<ICashier | null>(null);
  const { cashierData } = useCheckCashierPhone(
    formType,
    cashierAccountStatus,
    foundCashier
  );
  useCashierChangeFormType(
    cashierAccountStatus,
    setFormToEditCashier,
    setFormToRestoreCashier,
    setFormToAddCashier,
    branchesMap,
    cashierData
  );
  const {
    assigned,
    unassigned,
    query,
    filteredCashiers,
    // handleAllClicked,
    // handleAssignedClicked,
    handleQueryChange,
    // handleUnassignedClicked,
  } = useCashiersFilters(cashiers, selectedBranchId, handleSelectedBranchId);

  const openViewCashier = (cashier: ICashier) => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed a cashier or branch"]);
    setFormToViewCashier(processCashierBranches(cashier, branchesMap));
    toggleAddEditCashier(true);
  };
  const openEditCashier = (cashier: ICashier) => {
    setFormToEditCashier(processCashierBranches(cashier, branchesMap));
    toggleAddEditCashier(true);
  };
  const openAddCashier = () => {
    trackEvent(EVENTS_FOR_TRACKING["Adding a branch or a cashier"]);

    // setFormToAddCashier();
    toggleAddEditCashier(true);
  };
  const handleDeleteCashier = (cashier: ICashier) => {
    deleteCashier(cashier);
    getBranches();
  };

  const assignedCashiersTitle = useMemo(() => {
    return assigned
      ? t(Strings.assignedCashiers)
      : t(Strings.unassignedCashiers);
  }, [assigned, unassigned]);
  const unassignedCashiersTitle = useMemo(() => {
    return unassigned
      ? t(Strings.unassignedCashiers)
      : t(Strings.assignedCashiers);
  }, [assigned, unassigned]);

  const handleSubmitCashierForm = React.useCallback(
    (values, ...args) => {
      if (currentForm.onSubmit) {
        currentForm.onSubmit(values, ...args);
      }
    },
    [currentForm, toggleAddEditCashier]
  );
  const dismissCashierForm = () => toggleAddEditCashier(false);

  const { token } = React.useContext(StoreData);
  const asyncValidate = React.useCallback(
    ({ phoneNumber: value }) => {
      if ((formType === "ADD" || formType === "RESTORE") && value) {
        return searchCashiers(token, value).then(
          ({ data: { code, cashier } }) => {
            setFoundCashier(cashier || null);
            setCashierAccountStatus(code);
            if (code === EXISTS_IN_ANOTHER_BRAND) {
              throw {
                phoneNumber:
                  "Cashier already exists in a different brand, They need to be deleted from that brand",
              };
            }
          }
        );
      }
      return Promise.resolve();
    },
    [formType]
  );
  React.useEffect(() => {
    if (!submissionFailed && submissionSucceeded) {
      toggleAddEditCashier();
      setSubmissionSucceeded(false);
    }
  }, [submissionFailed, submissionSucceeded]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.fp}>
          <div className={styles.addedAndLabel}>
            <div className={styles.branchesAdded}>
              {assigned ? assignedCashiersTitle : unassignedCashiersTitle} (
              {filteredCashiers.length})
            </div>
            {/* <div className={styles.labelsWrapper}>
              <div onClick={handleAllClicked}
                className={`${styles.all} ${(!assigned && !unassigned) && styles.active}`}>
                <div className={styles.color}/>
                <p className={styles.label}>{t(Strings.all)}</p>
              </div>
              <div onClick={handleAssignedClicked}
                className={`${styles.assigned} ${assigned && styles.active}`}>
                <div className={styles.color}/>
                <p className={styles.label}>{t(Strings.assigned)}</p>
              </div>
              <div onClick={handleUnassignedClicked}
                className={`${styles.unassigned} ${unassigned && styles.active}`}>
                <div className={styles.color}/>
                <p className={styles.label}>{t(Strings.unassigned)}</p>
              </div>
            </div> */}
          </div>
          <div className={styles.search}>
            <img
              src={require("../../../../assets/search.svg").default}
              className={styles.logo}
            />
            <input
              className={styles.input}
              maxLength={30}
              value={query}
              onChange={handleQueryChange}
              placeholder={t(Strings.searchForCashiers)}
              type="text"
            />
          </div>
        </div>
        <ButtonWithIcon
          onClick={openAddCashier}
          btnStyle={styles.addBranch}
          textStyles={styles.text}
          name={t(Strings.newCashier)}
          icon={"add"}
        />
      </div>
      <CashiersTable
        openAddCashier={openAddCashier}
        selectedBranchId={selectedBranchId}
        onClickViewCashier={openViewCashier}
        onClickEditCashier={openEditCashier}
        onDeleteCashier={handleDeleteCashier}
        filteredCashiers={filteredCashiers}
        cashiers={cashiers}
        loaded={success}
        failed={failure}
      />
      {addEditCashierOpen && (
        <AddEditCashierForm
          hasPOS={hasPOS}
          hasCallCenterBranch={hasCallCenterBranch}
          formType={formType}
          asyncValidate={asyncValidate}
          {...currentForm}
          onSubmit={handleSubmitCashierForm}
          isOpen={addEditCashierOpen}
          dismissCashierForm={dismissCashierForm}
          toggleDrawer={toggleAddEditCashier}
        />
      )}
    </div>
  );
};

export default hot(module)(translate("cashiers")(Cashiers));
