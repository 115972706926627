import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getExtraInfoAction } from "../../constants/actions";
import { IAddress } from "../actions/lib";
import { getExtraInfoSuccess } from "../actions/past-order-actions";

interface IState {
  extraInfo: IAddress;
}

const initialState: IState = {
  extraInfo: {
    delivery_address: {
      apartment: "",
      building: "",
      floor: "",
      id: "",
      more_info: "",
      street: "",
      type: "",
    },
    delivery_zone: {
      city_ar_name: "",
      city_en_name: "",
      district_ar_name: "",
      district_en_name: "",
      sub_district_ar_name: "",
      sub_district_en_name: "",
      sub_district_id: "",
    },
  },
};

export const extraInfoReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getExtraInfoSuccess, (state: {}, { payload }) => ({
        ...state,
        extraInfo: payload,
      })),
    ] as any,
    initialState
  ),
  getExtraInfoAction
);
