import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import {
  getStoreWorkingHoursAction,
  updateStoreWorkingHoursAction,
} from "../../../../constants/actions";
import {
  getStoreWorkingHoursSuccess,
  getStoreWorkingHoursFailure,
  updateStoreWorkingHoursSuccess,
  updateStoreWorkingHoursFailure,
} from "../../../actions/Manager";
import {
  getStoreWorkingHours as getStoreWorkingHoursReq,
  updateStoreWorkingHours as updateStoreWorkingHoursReq,
} from "../../../../axios/Manager";
import { notify } from "react-notify-toast";

function* getStoreWorkingHours() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getStoreWorkingHoursReq, token);

    yield put(getStoreWorkingHoursSuccess(res.data));
  } catch (error) {
    yield put(getStoreWorkingHoursFailure(error));
  }
}

export function* watchGetStoreWorkingHours() {
  yield takeLatest(getStoreWorkingHoursAction.requested, getStoreWorkingHours);
}

function* updateStoreWorkingHours(action: any) {
  try {
    const token = yield select(selectToken);
    const res = yield call(updateStoreWorkingHoursReq, token, action.payload);
    yield put(updateStoreWorkingHoursSuccess(res));
  } catch (error) {
    yield put(updateStoreWorkingHoursFailure(error));
    notify.show("Failed to update store working hours!", "error");
  }
}

export function* watchUpdateStoreWorkingHours() {
  yield takeLatest(
    updateStoreWorkingHoursAction.requested,
    updateStoreWorkingHours
  );
}
