import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export interface IDoughnutProps {
  data?: number[];
  labels?: string[];
  enableTooltip: boolean;
  counts: number[];
  backgroundColor: string[];
  borderColor: string[];
  hoverBackgroundColor: string[];
  hoverBorderColor: string[];
  height: number;
  width: number;
}
interface IProps {
  values: IDoughnutProps;

  doughnutChartConfigs: any;
}
export const DoughnutChart: React.FC<IProps> = ({
  doughnutChartConfigs,
  values: {
    backgroundColor,
    height,
    width,
    counts,
    data,
    labels,
    hoverBorderColor,
    hoverBackgroundColor,
    borderColor,
    enableTooltip,
  },
}) => {
  const koinzDoughnutChartRef = useRef<any>({});

  useEffect(() => {
    const canvas = document.getElementById(
      doughnutChartConfigs.DOUGHNUT_CHART_ID
    ) as HTMLCanvasElement;

    const koinzDoughnutChart = canvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;

    koinzDoughnutChartRef.current = new Chart(koinzDoughnutChart, {
      type: doughnutChartConfigs.CHART_TYPE,
      data: {
        datasets: [
          {
            counts,
            labels,
            data,
            borderColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
            backgroundColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
            hoverBackgroundColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
            hoverBorderColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
          },
        ],
      },
      options: {
        ...doughnutChartConfigs.OPTIONS,
      },
    });
    return () => koinzDoughnutChartRef.current.destroy();
  }, []);
  useEffect(() => {
    if (
      typeof koinzDoughnutChartRef.current !== undefined &&
      data &&
      data.length
    ) {
      data.forEach((element, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].data[index] =
          data[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [data, koinzDoughnutChartRef, doughnutChartConfigs]);
  useEffect(() => {
    if (typeof koinzDoughnutChartRef.current !== undefined && enableTooltip) {
      koinzDoughnutChartRef.current.data.datasets[0].enableTooltip =
        enableTooltip;
      koinzDoughnutChartRef.current.update();
    }
  }, [borderColor, koinzDoughnutChartRef]);
  useEffect(() => {
    if (typeof koinzDoughnutChartRef.current !== undefined && borderColor) {
      borderColor.forEach((border, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].borderColor[index] =
          borderColor[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [borderColor, koinzDoughnutChartRef]);
  useEffect(() => {
    if (
      typeof koinzDoughnutChartRef.current !== undefined &&
      hoverBackgroundColor
    ) {
      hoverBackgroundColor.forEach((border, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].hoverBackgroundColor[
          index
        ] = hoverBackgroundColor[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [hoverBackgroundColor, koinzDoughnutChartRef]);
  useEffect(() => {
    if (
      typeof koinzDoughnutChartRef.current !== undefined &&
      hoverBorderColor
    ) {
      hoverBorderColor.forEach((border, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].hoverBorderColor[index] =
          hoverBorderColor[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [hoverBorderColor, koinzDoughnutChartRef]);
  useEffect(() => {
    if (typeof koinzDoughnutChartRef.current !== undefined && backgroundColor) {
      backgroundColor.forEach((background, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].backgroundColor[index] =
          backgroundColor[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [backgroundColor, koinzDoughnutChartRef]);
  useEffect(() => {
    if (typeof koinzDoughnutChartRef.current !== undefined && counts) {
      counts.forEach((count, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].counts[index] =
          counts[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [counts, koinzDoughnutChartRef, data]);
  useEffect(() => {
    if (typeof koinzDoughnutChartRef.current !== undefined && labels) {
      labels.forEach((label, index) => {
        koinzDoughnutChartRef.current.data.datasets[0].labels[index] =
          labels[index];
      });
      koinzDoughnutChartRef.current.update();
    }
  }, [labels, koinzDoughnutChartRef]);
  return (
    <canvas
      id={doughnutChartConfigs.DOUGHNUT_CHART_ID}
      width={width}
      height={height}
    />
  );
};

export default DoughnutChart;
