import { checkOnStoreWHUpdatingStatusAction } from "../../../../constants/actions";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../withLoadingState";
import { checkOnStoreWHUpdatingStatusSuccess } from "../../../actions";

interface IState {
  updating_branches_working_intervals: boolean;
}
const initialValue: IState = {
  updating_branches_working_intervals: false,
};

export const checkOnStoreWHUpdatingStatusReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(checkOnStoreWHUpdatingStatusSuccess, (state, { payload }) => ({
        ...state,
        updating_branches_working_intervals: !!payload[
          "updating_branches_working_intervals"
        ],
      })),
    ],
    initialValue
  ),
  checkOnStoreWHUpdatingStatusAction
);
