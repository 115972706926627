import { useQuery } from "react-query";
import { getCustomersGraphReq } from "../../../axios/Manager/Analytics";

export const useGetCustomersChartQuery = (token, requestConfigs) => {
  const result = useQuery(["customersGraph", requestConfigs], () => {
    if (!requestConfigs.store_id) return [];
    return getCustomersGraphReq(token, {
      ...requestConfigs,
    }).then((res) => res.data);
  });

  return result;
};
