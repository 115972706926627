import * as actions from "../../constants/actions";
import { payload, action } from "ts-action";
import { IGeographicItem } from "../../../WizardComponents/Branches/AddBranchForm/lib";

export const getDistricts = action(
  actions.getDistrictsAction.requested,
  payload<{ government_code: string }>()
);
export const getDistrictsSuccess = action(
  actions.getDistrictsAction.fulfilled,
  payload<IGeographicItem[]>()
);
export const getDistrictsFailure = action(
  actions.getDistrictsAction.rejected,
  payload<Error>()
);
export const searchForArea = action(
  actions.ACTION_SEARCH_FOR_DISTRICTS,
  payload<string>()
);
