import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { uploadCustomerPointsCSV } from "../../../../axios/uploadCustomerPointsCSV";
import { selectToken } from "../../../../redux-store/selectors";
import { uploadCustomerPointsCSVAction } from "../../../../Components/Pickup/constants/actions"
import {
  uploadCustomerPointsFailure,
  uploadCustomerPointsSuccess,
} from "../actions/customer-actions";

function* uploadCustomerPointsCSVSaga({ payload }: any) {
  try {
    const remoteAuth = JSON.parse(localStorage.getItem("authData") || "{}");
    const { token: remoteToken, storeData: { id } } = remoteAuth;
    const token = !!remoteToken ? remoteToken : yield select(selectToken);
    const res = yield call(uploadCustomerPointsCSV, token, id, payload);
    yield put(uploadCustomerPointsSuccess(res.data));
  } catch (error) {
    yield put(uploadCustomerPointsFailure(error as any));
  }
}

export function* watchUploadCustomerPointsCSVSaga() {
  yield takeLatest(uploadCustomerPointsCSVAction.requested, uploadCustomerPointsCSVSaga);
}
