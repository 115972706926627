import axios from "./axios";
import * as Requests from "./requests";
import { IOrderingMenuCategory } from "../lib/types";

export const editOrderingMenuCategory = (
  token: string,
  category: IOrderingMenuCategory
) => {
  return axios.put(Requests.REORDERING_CATEGORY_API(category.id), category, {
    headers: {
      token,
    },
  });
};
