export default {
  zonesUploading: "اضف مناطق التوصيل",
  downloadZonesTemplate: "تنزيل قالب مناطق التوصيل ",
  uploadExcelZones: " قم برفع ملف Execl الذي يحتوي على مناطق التوصيل",
  note:
    "ملاحظة: كل مناطق التوصيل المضافة حاليا سيتم استبدالها بالمناطق الموجودة في الملف",
  verify: "تأكيد",
  addZones: "اضف المناطق",
  firstStep: "الخطوة الاولي:",
  secondStep: "الخطوة الثانية:",
  cancel: "الغاء",
  uploadZonesByExcel: "ارفع عن طريق Execl",
  downloadExcelZones:
    "قم بتنزيل قالب مناطق التوصيل، ثم قم بإضافة قائمة الطعام به ",
  added: "تم إضافة",
};
