import axios from "../axios";
import { getVouchersURL } from "../requests";

export const getVouchersAPI = (token: string) => {
    return axios.get(getVouchersURL, {
        headers: {
            token,
        },
        baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
    });
};
