import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.sass";
import { Avatar } from "antd";
import { useHistory } from "react-router-dom";
import StoreData from "../../../../contexts/StoreData";
import { userProfileAPI } from "../../../../axios/userProfile";
import { imgBURL } from "../../../..";
import { useDispatch } from "react-redux";
import { getStore } from "../../../../redux-store/actions";
import i18n from "../../../../i18n/i18n";
import UpArrowIcon from "../../../../assets/upArrow.svg";
import { useRtlClass } from "../../../../shopx-shared-components/hooks/useRtlClassName";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../i18n/strings/navigation";
interface UserProfile {
  username: string;
  image: string;
  storeName: string;
}

interface IProps {
  isProfileDropDownOpen: boolean;
  setIsProfileDropDownOpen: (val: any) => void;
}

const ProfileDropDown: React.FC<IProps> = ({
  isProfileDropDownOpen,
  setIsProfileDropDownOpen,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("navigation");
  const [userProfile, setUserProfile] = useState<UserProfile>({
    username: "",
    image: "",
    storeName: "",
  });

  const handleProfileHeaderClick = () => {
    setIsProfileDropDownOpen((prevVal) => !prevVal);
  };

  const { userData, storeData, token } = useContext(StoreData);

  useEffect(() => {
    if (userData && userData.id && storeData) {
      setUserProfile({
        username: userData.name,
        image: `${imgBURL}${storeData.store_image}`,
        storeName:
          i18n.language === "en"
            ? (storeData.en_name as string)
            : (storeData.ar_name as string),
      });
    }
  }, [userData, storeData, i18n.language]);

  useEffect(() => {
    userProfileAPI(token);
    dispatch(getStore());
  }, [token]);

  return (
    <div
      className={`${styles.wrapper} ${useRtlClass(styles)}`}
      id="profile-drop-down-container"
    >
      <button
        className={styles.dropDownHeader}
        onClick={handleProfileHeaderClick}
        role="button"
      >
        <Avatar
          size={40}
          style={
            i18n.language === "ar"
              ? { marginRight: 0, marginLeft: "12px" }
              : { marginRight: "12px" }
          }
          src={userProfile.image}
        />

        <img
          src={UpArrowIcon}
          alt="up-down-arrow-icon"
          style={
            isProfileDropDownOpen
              ? {}
              : {
                  transform: "rotate(180deg)",
                }
          }
        />
      </button>
      {isProfileDropDownOpen && (
        <div className={styles.dropDownList}>
          <div className={styles.avatarContainer}>
            <Avatar
              size={40}
              style={
                i18n.language === "ar"
                  ? { marginRight: 0, marginLeft: "10px" }
                  : { marginRight: "10px", marginBottom: "12px" }
              }
              src={userProfile.image ? userProfile.image : null}
            ></Avatar>
            <div className={styles.storeNameAndUsernameContainer}>
              <p className={styles.username}>{userProfile.username}</p>
              <p className={styles.storeName}>{userProfile.storeName}</p>
            </div>
          </div>
          <div className={styles.separator}></div>
          <button
            className={styles.listItem}
            onClick={() => {
              history.replace("/my-account");
              setIsProfileDropDownOpen(false);
            }}
          >
            {t(Strings.profile_settings)}
          </button>
          <button
            className={styles.listItem}
            onClick={() => {
              setIsProfileDropDownOpen(false);
              localStorage.clear();
              history.go(0);
            }}
          >
            {t(Strings.logout)}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileDropDown;
