import "./styles.module.sass";
import ProgressBar from "./ProgressBar";
import styles from "./styles.module.sass";
/* eslint-disable-next-line */

export interface IPictogramData {
  total_percentage: number;
  average_ticket_size: number;
  label: string;
  males_percentage: number;
}
export interface PictogramProps {
  pictogramData: IPictogramData[];
  width: number;
  gender?: string;
}

export function Pictogram(props: PictogramProps) {
  return (
    <div className={styles.wrapper}>
      {props?.pictogramData?.map(
        (
          { label, total_percentage, average_ticket_size, males_percentage },
          index
        ) => (
          <ProgressBar
            key={index}
            label={label}
            gender={props.gender}
            precentage={total_percentage}
            males_percentage={males_percentage}
            average_ticket_size={average_ticket_size}
            width={props.width}
            marginBottom={
              props?.pictogramData?.length - 1 === index ? "1.2rem" : "2.8rem"
            }
          />
        )
      )}
      <div className={styles.percentageMain}>
        <div className={styles.separator} />
        <div className={styles.percentageWrapper}>
          <span className={styles.percentage}>0%</span>
          <span className={styles.percentage}>20%</span>
          <span className={styles.percentage}>40%</span>
          <span className={styles.percentage}>60%</span>
          <span className={styles.percentage}>80%</span>
          <span className={styles.percentage}>100%</span>
        </div>
      </div>
    </div>
  );
}

export default Pictogram;
