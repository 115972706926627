import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getPromotionsAction } from "../../../constants";
import { IPromocode } from "../../../types";
import { getPromotions, getPromotionsFailure, getPromotionsSuccess } from "../../actions";

export interface IGetPromotionsState {
    data: IPromocode[];
    page: number;
    hasMore: boolean;
}

export default withLoadingReducer<IGetPromotionsState>(reducer<IGetPromotionsState>([
    on(getPromotionsSuccess, (state, { payload }) => ({
        ...state,
        data: [...state.data, ...payload.data],
        hasMore: payload.hasMore,
    })),
    on(getPromotions, (state, { payload }) => ({
        ...state,
        page: payload.page,
        hasMore: true
    })),
    on(getPromotionsFailure, (state) => ({
        ...state,
        page: state.page - 1,
        hasMore: false
    }))
], {
    data: [],
    page: 0,
    hasMore: true
}), getPromotionsAction)