import { DeliveryMethods } from "../lib/types";
import axios from "./axios";
import * as Requests from "./requests";

export interface IEditStoreSettingsRequest {
  pickup_prep_min?: number;
  pickup_prep_max?: number;
  pickup_cover_image?: string;
  delivery_time?: number;
  delivery_fees?: number;
  delivery_method?: DeliveryMethods;
}

export const editStoreSettings = (
  token: string,
  Settings: IEditStoreSettingsRequest
) => {
  return axios.put(Requests.STORE_SETTINGS_API, Settings, {
    headers: {
      token,
    },
  });
};
