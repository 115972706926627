import sl from "./socialLinks";
import convertingRatio from "./convertingRatio";
import brandVidjwels from "./brandVisuals";

export default {
  myProfileHeader: "حسابي",
  myProfileSubHeader: "يمكنك تعديل بياناتك هنا",
  PI: "المعلومات الشخصية",
  profilePicture: "الصورة الشخصية",
  uploadImg: "رفع صورة",
  password: "كلمة المرور",
  changePassword: "تغيير كلمة المرور",
  fullName: "الاسم بالكامل",
  phoneNumber: "رقم الجوال",
  email: "البريد الالكتروني",
  assignToBranches: "الفروع التي تشرف عليها",
  role: "Role",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  save: sl.save,
  oldPassword: "كلمة المرور القديمة",
  newPassword: "كلمة المرور الجديدة",
  cancel: "إلغاء",
  unsupportedFileType: brandVidjwels.unsupportedFileType,
  cropProfilePic: "حفظ الصورة الشخصية",
  passwordChangeSuccess: "تم تغيير كلمة المرور بنجاح!",
  passwordChangeFailure: "كلمة المرور التي أدخلتها خاطئة!",
  profileEditSuccess: "تم تعديل بياناتك بنجاح!",
  overviewEmail: "بريد الاحصائيات الأسبوعي",
  criticalReviewsEmail: "بريد مشاكل العملاء",
  overviewEmailDescription:
    "يقوم هذا البريد الاسبوعي بتحديثك فيما يخص أداء متجرك باستخدام شوبكس",
  emailReportingTitle: "إدارة تقارير البريد الالكتروني",
  criticalReviewsEmailDescription:
    "يعمل هذا البريد كتنبيه حينما يقوم عميل بترك تقييم سيء على إحدى زياراته",
  // confirmChangePasswordTitle: 'Are you sure you want to change your password. confirm to continue',
  confirmChangePasswordTitle:
    "هل أنت متأكد من أنك تريد تغيير كلمة مرورك، قم بالتأكيد للاستمرار",
  confirmChangePasswordDescription: "تغيير كلمة المرور",
  confirm: "تأكيد",
};
