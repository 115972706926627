import axios from "./axios";

export const deleteSuggestedModifiersGroupAPI = (
  token: string,
  mgId: string
) => {
  return axios.delete("/api/manager/suggested-modifiers-groups/" + mgId, {
    headers: {
      token,
    },
  });
};
