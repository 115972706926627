import { on, reducer } from "ts-action";
import { submitPickupBranchesAction } from "../../constants/actions";
import {
  clearPreviousState,
  submitPickupBranches,
  submitPickupBranchesFailure,
  submitPickupBranchesSuccess,
} from "../actions/pickupBranchesActions";
import { withLoadingReducer } from "./withLoadingState";

interface IState {
  status: "idle" | "loading" | "success" | "failure";
}
export const submitPickupBranchesReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(clearPreviousState, (state, {}) => ({
        ...state,
        status: "idle",
      })),
      on(submitPickupBranches, (state, {}) => ({
        ...state,
        status: "loading",
      })),
      on(submitPickupBranchesSuccess, (state, {}) => ({
        ...state,
        status: "success",
      })),
      on(submitPickupBranchesFailure, (state, {}) => ({
        ...state,
        status: "failure",
      })),
    ],
    {
      status: "idle",
    }
  ),
  submitPickupBranchesAction
);
