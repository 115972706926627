import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.sass";
import { Button, Input, Table, Spin, Select, Alert } from "antd";
import { columns, transactionTypeLabel } from "./columns";
import "./style.less";
import { Search, Download } from "react-feather";
import { Queries } from "../../../../../Queries/Manager";
import { useSelect } from "../../../../../hooks/useSelect";
import { LoadingOutlined } from "@ant-design/icons";
import { useIsVisible } from "react-is-visible";
import { useDispatch } from "react-redux";
import { getBranches, getStore } from "../../../../../redux-store/actions";
import ProfileModal from "../../../../../Components/Leaderboard/ProfileModal";
import { getLocalizedCurrency } from "../../../../../Utils";
import MultiSelect, {
  mapStringArryToSelectedArray,
} from "../../../../../@koinz-ui/MultiSelect";
import { RangePicker } from "../../../../../@koinz-ui/RangePicker";
import { useTranslation } from "../../../../../Components/WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
import Strings from "../../../../../i18n/Manager/strings/insights";
import { numberFormatter } from "../../../../../Utils/numbers";
import { TimeFilter } from "../../Home/components";
import i18n from "../../../../../i18n/i18n";
import {
  CSVButton as ExportCSVButton,
  CSVButton,
  ExportCSVModal,
} from "../components";
import { exportCustomerTransactionsCSV } from "../../../../../axios/Manager/Analytics/Insights/transaction";
import StoreData from "../../../../../contexts/StoreData";
import { pusher } from "../../../../../pusher/pusher";
import { trackEvent } from "../../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../../constants/events-for-tracking";
import {
  AmplitudeEvents,
  amplitudeLogEvent,
} from "../../../../../tracking/amplitude";

enum TransactionType {
  delivery = "delivery",
  pickup = "pickup",
  webPortal = "web_portal",
  offline = "offline",
}

export interface Filters {
  page: number;
  size: number;
  filter?: string;
  branch_ids?: any[];
  from?: number;
  to?: number;
  transaction_type?: TransactionType;
  ticket_size_range: number[];
  transaction_types?: TransactionType[];
}

const TransactionInsightsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelect((state) => state.authReducer);
  const { storeData, userData } = useContext(StoreData);

  //
  const listPaginationLoaderRef = useRef(null);
  const isVisible = useIsVisible(listPaginationLoaderRef);
  const { store } = useSelect((state) => state.storeReducer);
  const currency = getLocalizedCurrency(store.country_iso_code);
  // localization
  //
  const [isMore, setIsMore] = useState<boolean>(false);
  const [customerPopup, setCustomerPopup] = useState<boolean>(false);
  const [customerPopupId, setCustomerPopupId] = useState<string | undefined>();
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [searchInputLoading, setSearchInputLoading] = useState<boolean>(false);
  const [transactionData, setTransactionData] = useState<any[] | undefined>(
    undefined
  );
  const { t } = useTranslation("insights");
  const initialFiltersValue = {
    page: 0,
    size: 20,
    branch_ids: [],
    ticket_size_range: [0, 10000],
  };

  const { allBranches } = useSelect((state) => state.branchesReducer);
  const { country_iso_code } = useSelect((state) => state.storeReducer.store);

  const [filters, setFilters] = useState<Filters>(initialFiltersValue);

  // Query
  const { data, status } = Queries.useGetTransactionInsights(token, filters);
  const { data: totalTransactions, status: totalTransactionsStatus } =
    Queries.useGetTransactionInsightsTotal(token, {
      ...filters,
      page: undefined,
      size: undefined,
    });

  useEffect(() => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed transactions insights page"]);
    amplitudeLogEvent(AmplitudeEvents.transactions_insights, {
      user_name: userData?.name,
      user_id: userData?.id,
      user_role: userData?.title || userData?.role,
      store_id: storeData?.id,
      store_name: storeData?.name,
    });
  }, []);

  useEffect(() => {
    if (customerPopupId) setCustomerPopup(true);
    else setCustomerPopup(false);
  }, [customerPopupId]);

  useEffect(() => {
    if (status === "success" && data.length > 0) {
      if (filters.page === 0) {
        setTransactionData(data);
      } else {
        setTransactionData((prev) => (prev && [...prev, ...data]) || data);
      }

      if (data.length < filters.size) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    } else {
      //
      if (filters.page === 0) {
        setTransactionData([]);
      }

      setIsMore(false);
    }
  }, [status, data]);

  useEffect(() => {
    if (status !== "loading") {
      setSearchInputLoading(false);
    }
  }, [status, data, filters.page]);

  // Get store with branches .. for "window refresh"
  useEffect(() => {
    dispatch(getStore());
    dispatch(getBranches());
  }, [dispatch]);

  // When bottom loader is visibile
  useEffect(() => {
    if (isMore && isVisible && status !== "loading") {
      setFilters((filters) => ({
        ...filters,
        page: filters.page + 1,
      }));
    }
  }, [isVisible, status]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: 0 }));
  }, [filters.branch_ids, filters.filter]);

  // Apply text search
  const applySearchFilter = (e) => {
    e.target.blur();
    if (filterSearchText !== filters.filter) {
      setFilters({
        ...filters,
        filter: filterSearchText !== "" ? filterSearchText : undefined,
        page: 0,
      });
      setSearchInputLoading(true);
    }
  };

  // set new filter update (with page reset to first page)
  const setFilterValue = (key, value) => {
    const copyOfFilters = { ...filters };
    copyOfFilters["page"] = 0;
    value = value === "" ? undefined : value;
    copyOfFilters[key] = value;
    setFilters(copyOfFilters);
  };

  // branch filter
  const handleBranchFilterMultiSelectChange = (values) => {
    if (values.length === allBranches.length) {
      setFilterValue("branch_ids", []);
    } else {
      setFilterValue(
        "branch_ids",
        values.map((v) => v.id)
      );
    }
  };
  const orderingTypesOptions = React.useMemo(
    () => [
      {
        id: "pickup",
        title: "Pickup",
      },
      { id: "delivery", title: "Delivery" },
      { id: "offline", title: "Offline" },
    ],
    []
  );
  const handleTypeFilterMultiSelectChange = (values) => {
    if (values.length === orderingTypesOptions.length) {
      setFilterValue("transaction_types", []);
    } else {
      setFilterValue(
        "transaction_types",
        values.map((v) => v.id)
      );
    }
  };

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  const SearchIconJSX = (
    <Search color="#8E8E93" size={18} className={styles.searchInputIcon} />
  );

  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  // export CSV Button
  const DownloadIconJSX = (
    <Download className={styles.exportButtonIcon} size={20} />
  );

  const [downloadButtonStatus, setDownloadButtonStatus] = useState<
    "default" | "loading" | "disabled"
  >("disabled");

  const [socketExportStatus, setSocketExportStatus] = useState<
    "failed" | "default"
  >("default");

  const [isExpCSVModalVisible, setExpCSVModalVisible] = useState(false);
  const [exportModalTimeFilterVal, setExportModalTimeFilterVal] = useState({
    from: 0,
    to: 0,
  });
  const handleDownloadCSV = () => {
    // requesting file for download
    const { ticket_size_range, branch_ids, filter, transaction_types } =
      filters;
    const { from, to } = exportModalTimeFilterVal;

    if (socketExportStatus === "failed") setSocketExportStatus("default");
    setDownloadButtonStatus("loading");

    exportCustomerTransactionsCSV(token, {
      ticket_size_range,
      branch_ids,
      filter,
      from,
      to,
      transaction_types,
    })
      .then((res) => {
        if (res.status === 200) setDownloadButtonStatus("loading");
        else setDownloadButtonStatus("default");
      })
      .catch((e) => {
        setDownloadButtonStatus("default");
        setSocketExportStatus("failed");
      });
  };

  // handling file download
  useEffect(() => {
    const socket = pusher(token, storeData?.id as string);
    const channel = socket.subscribe(`presence-file_export.${storeData?.id}`);

    // binding events
    channel.bind(`file_export_transactions_${token}_success`, (res) => {
      const { file_path } = res;
      if (file_path) {
        const anchorEl = document.createElement("a");
        anchorEl.href = `${process.env.REACT_APP_WAREHOUSE_ENDPOINT}${file_path}`;
        anchorEl.download = "true";
        anchorEl.click();

        setTimeout(() => {
          setExpCSVModalVisible(false);
          setDownloadButtonStatus("disabled");
          setSocketExportStatus("default");
        }, 3000);
      }
    });

    channel.bind(`file_export_transactions_${token}_failed`, (e) => {
      setDownloadButtonStatus("default");
      setSocketExportStatus("failed");
    });
  }, []);

  const handleModalTimeFilterChange = (value, isExportTimeRangeValid) => {
    const { from, to } = value;
    setDownloadButtonStatus(() =>
      isExportTimeRangeValid ? "default" : "disabled"
    );
    if (isExportTimeRangeValid) setExportModalTimeFilterVal({ from, to });
  };

  useEffect(() => {
    if (!isExpCSVModalVisible) {
      setDownloadButtonStatus("disabled");
      setSocketExportStatus("default");
    }
  }, [isExpCSVModalVisible]);

  // default of last 28 days
  const today = new Date();
  const now = new Date().getTime();
  const timestampOfADay = 60 * 60 * 24 * 1000;

  const defaultFrom = new Date(
    today.getTime() - 28 * timestampOfADay
  ).getTime();
  const defaultTo = now;
  const [orderTypeOpen, setOrderTypeOpen] = useState(false);
  return (
    <div className={styles.pageWrapper}>
      {/* download csv modal */}
      <ExportCSVModal
        initialTimeFilterVal={{
          from: filters.from || defaultFrom,
          to: filters.to || defaultTo,
        }}
        isVisible={isExpCSVModalVisible}
        onCancel={() => {
          setExpCSVModalVisible(false);
          setDownloadButtonStatus("disabled");
        }}
        onTimeFilterChange={handleModalTimeFilterChange}
      >
        {socketExportStatus === "failed" && (
          <Alert
            style={{ width: "100%", marginBottom: "10px" }}
            message="File Export Failed"
            type="error"
          />
        )}
        <CSVButton
          onClick={handleDownloadCSV}
          buttonStatus={downloadButtonStatus}
          style={{ width: "504px", height: "48px" }}
        >
          {socketExportStatus === "failed" ? "Try Again" : "Download CSV file"}
        </CSVButton>
      </ExportCSVModal>
      {/* Heading */}

      <div className={styles.heading}>
        <span className={styles.headingText}>{t(Strings.transactions)}</span>
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.tableHead}>
          <div className={styles.tableHeadRowOne}>
            <div className={styles.title}>
              {totalTransactionsStatus === "success" &&
                t(Strings.all_transactions, {
                  transaction_counts: numberFormatter(
                    Number(totalTransactions?.total)
                  ),
                })}
            </div>
            <div className={styles.searchAndActions}>
              <div className={styles.search}>
                <Input
                  className="insights-header-search-input"
                  size="small"
                  placeholder={t(Strings.searchWithOrderCode)}
                  value={filterSearchText}
                  onPressEnter={applySearchFilter}
                  onChange={(e) => setFilterSearchText(e.target.value)}
                  disabled={searchInputLoading}
                  suffix={
                    searchInputLoading && <Spin indicator={CustomSpinner} />
                  }
                  prefix={SearchIconJSX}
                />
              </div>
              <div className={styles.export}>
                <ExportCSVButton
                  onClick={() => setExpCSVModalVisible(true)}
                  buttonStatus={"default"}
                  icon={DownloadIconJSX}
                  style={{ height: "55px" }}
                >
                  {t(Strings.export_csv)}
                </ExportCSVButton>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className={styles.filtersRow}>
          <div className={styles.input}>
            <MultiSelect
              label={t(Strings.type)}
              canSelectAll={true}
              onApply={handleTypeFilterMultiSelectChange}
              options={orderingTypesOptions}
              selected={
                filters.transaction_types &&
                filters.transaction_types.length > 0
                  ? mapStringArryToSelectedArray(
                      filters.transaction_types || []
                    )
                  : orderingTypesOptions.map((b) => ({ id: b.id }))
              }
            />
          </div>
          {/* <div className={`${styles.input}`}>
            <Select
              onDropdownVisibleChange={() => setOrderTypeOpen(!orderTypeOpen)}
              suffixIcon={
                <img
                  style={{
                    width: "14px",
                    height: "14px",
                    transform: orderTypeOpen ? "rotate(180deg)" : undefined,
                  }}
                  src={
                    require("../../../../../assets/feather _ chevron-down.svg")
                      .default
                  }
                />
              }
              value={filters.transaction_type || ""}
              optionLabelProp="label"
              onChange={(val) => {
                setFilterValue("transaction_type", val as TransactionType);
              }}
            >
              <Select.Option
                value={""}
                label={`${t(Strings.type)} :  ${t(Strings.all)}`}
              >
                {t(Strings.all)}
              </Select.Option>
              <Select.Option
                value={TransactionType.pickup}
                label={`${t(Strings.type)} :  ${transactionTypeLabel.pickup}`}
              >
                {transactionTypeLabel.pickup}
              </Select.Option>
              <Select.Option
                value={TransactionType.delivery}
                label={`${t(Strings.type)} :  ${transactionTypeLabel.delivery}`}
              >
                {transactionTypeLabel.delivery}
              </Select.Option>
              <Select.Option
                value={TransactionType.webPortal}
                label={`${t(Strings.type)} :  ${
                  transactionTypeLabel.webPortal
                }`}
              >
                {transactionTypeLabel.webPortal}
              </Select.Option>
              <Select.Option
                value={TransactionType.offline}
                label={`${t(Strings.type)} :  ${transactionTypeLabel.offline}`}
              >
                {transactionTypeLabel.offline}
              </Select.Option>
            </Select>
          </div> */}

          <div className={styles.input}>
            <RangePicker
              country_iso_code={country_iso_code}
              label={`${t(Strings.ticket_size)}  ${
                filters.ticket_size_range[0] === 0 &&
                filters.ticket_size_range[1] === 1000
                  ? t(Strings.all)
                  : `${filters.ticket_size_range[0]} - ${filters.ticket_size_range[1]} `
              }`}
              min={0}
              max={10000}
              from={filters.ticket_size_range[0]}
              to={filters.ticket_size_range[1]}
              setValues={(value) => setFilterValue("ticket_size_range", value)}
              prefix={currency}
            />
          </div>
          <div className={styles.input}>
            <MultiSelect
              label={t(Strings.branch)}
              canSelectAll={true}
              onApply={handleBranchFilterMultiSelectChange}
              options={branchesOptions}
              selected={
                filters.branch_ids && filters.branch_ids.length > 0
                  ? mapStringArryToSelectedArray(filters.branch_ids || [])
                  : allBranches.map((b) => ({ id: b.id }))
              }
            />
          </div>
          <div className={styles.input}>
            <TimeFilter
              onChange={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  page: 0,
                  from: value.from,
                  to: value.to,
                  unit: value.unit,
                }))
              }
            />
          </div>
        </div>
        {/*  */}
        <div className={styles.tableBody} id="customer-list">
          <Table
            columns={columns(currency)}
            rowClassName={"insights-table-row"}
            pagination={false}
            loading={
              status === "loading" && filters.page === 0
                ? { indicator: CustomSpinner }
                : false
            }
            dataSource={transactionData}
            onRow={(row) => ({
              onClick: () => {
                setCustomerPopupId(row.customer_id);
              },
            })}
          />

          {
            <div
              ref={listPaginationLoaderRef}
              className={`${isMore && styles.tableLoader}`}
            >
              {isMore && <Spin indicator={CustomSpinner} />}
            </div>
          }

          {customerPopup && customerPopupId && (
            <ProfileModal
              customer={{ id: customerPopupId }}
              close={() => {
                setCustomerPopupId(undefined);
              }}
              modal={customerPopup}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionInsightsPage;
