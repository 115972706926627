import { call, put, select, takeLatest } from "redux-saga/effects";
import { editStoreItemAction } from "../../constants";
import {
  IEditStoreItemAction,
  IStoreItem,
  OptimisticSuccess,
} from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { editStoreItem } from "../../axios/editStoreItem";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";
import { trackEvent } from "../../tracking/trackEvent";
import { IOptimisticObject } from "../../types/shared";

export const deleteOptimisticProps = <T extends IOptimisticObject>(
  optimistic: T
) => {
  const copyObject = { ...optimistic } as any;
  delete copyObject.state;
  delete copyObject.optimisticId;
  return copyObject;
};

function* editStoreItemSaga(action: IEditStoreItemAction) {
  const { payload } = action;
  try {
    const token = yield select(selectToken);
    const editedStoreItemRes = yield call(editStoreItem, token, {
      ...deleteOptimisticProps(payload),
      price: payload.price ? +(payload.price as number) : undefined,
    } as any);
    const item = editedStoreItemRes.data as IStoreItem;
    yield put(
      actions.editStoreItemSuccess({
        ...payload,
        ...item,
        state: OptimisticSuccess,
      })
    );
    trackEvent(EVENTS_FOR_TRACKING["Edited a menu item"]);
  } catch (e) {
    yield put(actions.editStoreItemFailure(payload));
  }
}

export function* watchEditStoreItemSaga() {
  yield takeLatest(editStoreItemAction.requested, editStoreItemSaga);
}
