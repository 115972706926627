import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IBranch } from "../types/shared";

export const deleteBranch = (token, id): AxiosReturn<IBranch> => {
  return axios.delete(Requests.brancheByID(id), {
    headers: {
      token,
    },
  });
};
