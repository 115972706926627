import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const deleteCategory = (token: string, id: string): AxiosReturn<{}> => {
  return axios.delete(Requests.categoryByID(id), {
    headers: {
      token,
    },
  });
};
