import axios from "../axios";
import { posEntitiesURL } from "../requests";

export const updatePosEntityApi = (
  token: string,
  payload: {
    id: string;
    koinz_main_group_id?: string;
    pos_online_payment_method_id?: string;
    offline_only_menu_group_id?: string;
  }
) => {
  return axios.put(posEntitiesURL, payload, {
    headers: { token },
    baseURL: process.env.REACT_APP_POS_ENDPOINT,
  });
};