import * as React from "react";

export interface IReviewsFiltersContextValues {
  filters: {
    page: number;
    has_issue?: number;
    rate?: number;
    query?: string;
    has_text?: number;
    responded?: number;
    reopened?: number;
    resolved?: number[];
    category_id?: string;
    tag_code?: string;
  };
  generalFilters: {
    start_time?: number;
    end_time?: number;
    branch_id?: string;
  };
  setGeneralFilters: (filters: this["generalFilters"]) => void;
  setFilters: (filters: this["filters"]) => void;
}

export default React.createContext<IReviewsFiltersContextValues>({
  filters: {
    page: 0,
    has_issue: undefined,
    rate: undefined,
    query: undefined,
    has_text: undefined,
    resolved: undefined,
    category_id: undefined,
    tag_code: undefined,
    reopened: undefined,
    responded: undefined,
  },
  generalFilters: {
    start_time: undefined,
    end_time: undefined,
    branch_id: undefined,
  },
  setFilters: (filters) => null,
  setGeneralFilters: (filters) => null,
});
