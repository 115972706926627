import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getCustomerInfoAction } from "../../constants/actions";
import {
  getCustomerInfo,
  getCustomerInfoFailure,
  getCustomerInfoSuccess,
} from "../actions/customer-actions";
import { union } from "ts-action";
import { getCustomerInfoAPI } from "../../axios/getCustomerInfo";

const actionType = union({ getCustomerInfo } as any);
function* getCustomerInfoSaga(action: any) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getCustomerInfoAPI, token, action.payload);
    yield put(
      getCustomerInfoSuccess(
        res.data || {
          customer: {
            image: "",
            store_image: "",
            app_version: "",
            birth_date: 0,
            country_code: "",
            created_at: 0,
            email: "",
            id: "",
            last_active_time: 0,
            name: "",
            password: "",
            phone_number: "",
            shopxId: "",
            update_data_screen_status: 0,
            updated_at: 0,
            area: {
              ArName: "",
              EnName: "",
              code: "",
            },
            country: {
              ArName: "",
              EnName: "",
              ISO_code: "",
              country_code: "",
              last_city_code: 0,
              pickup_status: "",
            },
            government: {
              ArName: "",
              EnName: "",
              address_by_google: "",
              code: "",
              latitude: 0,
              longitude: 0,
              rocket_code: "",
            },
          },
          total_orders: 0,
        }
      )
    );
  } catch (e) {
    yield put(getCustomerInfoFailure(e));
  }
}

export function* watchGetCustomerInfoSaga() {
  yield takeLatest(getCustomerInfoAction.requested, getCustomerInfoSaga);
}
