import convertingRatio from "./convertingRatio";
export default {
  title: "استيراد قائمة المنتجات عن طريق ملف Excel",
  uploading: "جاري الرفع",
  checking: "جاري الفحص",
  error: "خطأ",
  completed: "نجاح",
  uploadExcelFile: "رفع ملف Excel",
  downloadTemplate: "تحميل قالب القائمة",
  emptyFile: "الملف فارغ",
  invalidFileFormat: "صيغة الملف غير صحيحة",
  missingData: "بيانات ناقصة في الصف {{row}} والعامود {{column}}",
  invalidDataType: "خطأ في بيانات الصف {{row}}",
  somethingWentWrong: convertingRatio.somethingWentWrong,
};
