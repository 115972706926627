export default {
  routingMethodHeader: "Routing Method",
  routingMethodContent:
    "Choose your brand available routing method get a better experience",
  standAloneHeader: "Stand-alone",
  standAloneContent: "Each branch operates with a stand-alone Koinz Tablet",
  callCenterHeader: "Call center",
  callCenterContent:
    "Your call center agents will receive and manage the orders",
};
