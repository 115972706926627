import axios from "../axios";
import * as Requests from "../requests";
import { PickupStatus } from "../../../../types/shared";

export const editQRPortalBranchStatusApi = (
  token: string,
  branch_id,
  value: { type: "dine_in" | "take_away"; status: PickupStatus }
) => {
  return axios.put(
    Requests.editQRPortalBranchStatusURL(branch_id),
    { ...value },
    {
      headers: { token },
    }
  );
};
