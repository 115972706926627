import {
  getCashiers,
  getCashiersSuccess,
  getCashiersFailure,
} from "../actions";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { union } from "ts-action";
import { ICashier } from "../../axios/getCashiers";

const CASHIERS = "cashiers";
const LOADING = "loading";
const LOADED = "loaded";
const FAILED = "failed";

export interface ICashiersReducerState {
  [CASHIERS]: ICashier[];
  [LOADING]: boolean;
  [LOADED]: boolean;
  [FAILED]: boolean;
}

const initialState: ICashiersReducerState = Map({
  [CASHIERS]: [],
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
}).toJS();

const allActions = union({
  getCashiers,
  getCashiersSuccess,
  getCashiersFailure,
} as any);

type getCashiersActions = any;

export default (
  state = initialState,
  action: getCashiersActions
): ICashiersReducerState => {
  switch (action.type) {
    case constants.getCashiersAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(CASHIERS, (action as any).payload);
        map.set(LOADING, false);
        map.set(LOADED, true);
        map.set(FAILED, false);
      });
    }
    case constants.getCashiersAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADING, false);
        map.set(LOADED, false);
        map.set(FAILED, true);
      });
    }
    case constants.getCashiersAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADING, true);
        map.set(LOADED, false);
        map.set(FAILED, false);
      });
    }
    default:
      return state;
  }
};
