import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getMinOrderValueAction } from "../../constants/actions";
import {
  getMinValueSuccess,
  getMinValueFailure,
} from "../actions/min-order-value";
import { getMinOrderingValue } from "../../axios/getMinOrderingValue";

function* getMinOrderValueSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getMinOrderingValue, token);

    yield put(getMinValueSuccess(res.data));
  } catch (error) {
    yield put(getMinValueFailure(error));
  }
}

export function* watchGetMinOrderValueSaga() {
  yield takeLatest(getMinOrderValueAction.requested, getMinOrderValueSaga);
}
