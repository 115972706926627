import sl from "./socialLinks";
import convertingRatio from "./convertingRatio";
import actions from "redux-form/lib/actions";


export default {
  myProfileHeader: "myProfileHeader",
  myProfileSubHeader: "myProfileSubHeader",
  PI: "PI",
  profilePicture: "profilePicture",
  uploadImg: "uploadImg",
  title: "title",
  subTitle: "subTitle",
  allUsers: "allUsers",
  addBranchTitle: "addBranchTitle",
  fullName: "fullName",
  email: "email",
  phoneNumber: 'phoneNumber',
  countryCode: 'countryCode',
  assignToBranches: "assignToBranches",
  otherJobTitle: "otherJobTitle",
  jobTitle: "jobTitle",
  searchForUser: "searchForUser",
  addUser: "addUser",
  addUserTT: "addUserTT",
  editUser: "editUser",
  viewUser: "viewUser",
  name: "name",
  role: "role",
  actions: "actions",
  brandSettings: "brandSettings",
  dashboardUsers: "dashboardUsers",
  missingRole: "missingRole",
  pendingInvitations: "pendingInvitations",
  resendInvitation: "resendInvitation",
  lastLogin: "lastLogin",
  pendingUsers: "pendingUsers",
  description1: "description1",
  description2: "description2",
  description3: "description3",
  description4: "description4",
  description5: "description5",
  description6: "description6",
  managePositions: "managePositions",
  title1: "title1",
  title2: "title2",
  title3: "title3",
  title4: "title4",
  title5: "title5",
  title6: "title6",
  title7: "title7",
  all: "all",
  addaCustomerServicesUser: "addaCustomerServicesUser",
  addaBranchMangerUser: "addaBranchMangerUser",
  addaSeniorManagmentUser: "addaSeniorManagmentUser",
  addanOwneracc: "addanOwneracc",
  accessToAssignedTitle: "accessToAssignedTitle",
  unknown: "unknown",
  saveBtn: "saveBtn",
  cancelBtn: "cancelBtn",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  submit: sl.submit,

  deleteUser: "deleteUser",
  deleteUserDescription: "deleteUserDescription",
  confirmDelete: "confirmDelete",
  cancel: "cancel",
  modalDescription: "modalDescription",
  modalPhoneNumberText: "modalPhoneNumberText",
  modalAssignTitle: "modalAssignTitle",
  modalUnassignTitle: "modalUnassignTitle",
  modalPOCTitle: "modalPOCTitle",
  assignUserButtonStaticText: "assignUserButtonStaticText",
  unAssignUserButtonStaticText: "unAssignUserButtonStaticText",
  modalSubTitleStaticText: "modalSubTitleStaticText",
  modalNotReceiveMsg: "modalNotReceiveMsg",
  modaWhatsappMsg: "modaWhatsappMsg"
};