import axios from "../axios";
import * as Requests from "../requests";
export interface IPayload {
  operation_id: string;
  price: number;
  remaining?: any;
}
export const editPointsOperationAPI = async (token, payload: IPayload) =>
  await axios.post(
    Requests.editPointsOperationURL,
    { ...payload },
    {
      headers: {
        token,
      },
      baseURL: process.env.REACT_APP_ENDPOINT,
    }
  );
