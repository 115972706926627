import convertingRatio from "./convertingRatio";
import onBoardingSteps from "./onBoardingSteps";

export default {
  title: "title",
  subtitle: "subtitle",
  note: "note",
  link: "link",
  submit: "submit",
  save: "save",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  next: onBoardingSteps.next,
};
