import React, { MouseEventHandler, useState } from "react";
import styles from "./styles.module.sass";
import { Button, Drawer } from "antd";
import { MoreVert } from "@material-ui/icons";
import MultiSelect, {
  mapStringArryToSelectedArray,
} from "../../../../../../@koinz-ui/MultiSelect";
import { RangePicker } from "../../../../../../@koinz-ui/RangePicker";
import { useSelect } from "../../../../../../hooks/useSelect";
import { Filters, TimeKeys } from "../";
import { AllFiltersDrawerContent } from "./Drawer";
import { SelectedFilters } from "./Selected";
import { useGetCurrency, cleanObject } from "../../../../../../Utils";
import i18n from "../../../../../../i18n/i18n";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/insights";
import "./style.less";
interface IProps {
  filters: Filters;
  setFilterValue: Function;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  resetFilters: MouseEventHandler<HTMLElement>;
  initialValues: Filters;
  setTimeKeys: React.Dispatch<React.SetStateAction<any>>;
  timeKeys: TimeKeys;
  setTimeKeysValue: Function;
}

export const CustomerInsightsFilters: React.FC<IProps> = ({
  filters,
  setFilterValue,
  resetFilters,
  setFilters,
  initialValues,
  setTimeKeys,
  timeKeys,
  setTimeKeysValue,
}) => {
  const { allBranches } = useSelect((state) => state.branchesReducer);
  const { country_iso_code } = useSelect((state) => state.storeReducer.store);

  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const currency = useGetCurrency();
  const { t } = useTranslation("insights");

  // branch filter
  const handleBranchFilterMultiSelectChange = (values) => {
    if (values.length === allBranches.length) {
      setFilterValue("branch_ids", []);
    } else {
      setFilterValue(
        "branch_ids",
        values.map((v) => v.id)
      );
    }
  };

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  return (
    <>
      <div
        className={`${styles.row} ${
          i18n.language.includes("ar") ? styles.ar : styles.en
        }`}
      >
        {/*  */}
        <div style={{ margin: "0 12px" }}>
          <MultiSelect
            label={t(Strings.branch)}
            canSelectAll={true}
            onApply={handleBranchFilterMultiSelectChange}
            options={branchesOptions}
            selected={
              filters.branch_ids && filters.branch_ids.length > 0
                ? mapStringArryToSelectedArray(filters.branch_ids || [])
                : allBranches.map((b) => ({ id: b.id }))
            }
          />
        </div>

        {/*  */}
        <div className={styles.input}>
          <RangePicker
            country_iso_code={country_iso_code}
            label={`${t(Strings.ticket_size)} ${
              filters.average_ticket_size[0] === 0 &&
              filters.average_ticket_size[1] === 1000
                ? t(Strings.all)
                : `${filters.average_ticket_size[0]} - ${filters.average_ticket_size[1]} `
            }`}
            min={0}
            max={10000}
            from={filters.average_ticket_size[0]}
            to={filters.average_ticket_size[1]}
            setValues={(value) => setFilterValue("average_ticket_size", value)}
            prefix={currency}
          />
        </div>
        {/*  */}
        <span
          style={{
            width: "1px",
            background: "#F2F2F7",
            height: "35px",
            marginLeft: "1.5rem",
          }}
        ></span>
        {/* Filters drawer */}
        <Button
          className="ant-black-text-button"
          type="link"
          onClick={() => setFilterVisible(true)}
          icon={<MoreVert style={{ fontSize: 20 }} />}
        >
          {t(Strings.more_filters)}
        </Button>
        <Drawer
          title={<h3>{t(Strings.all_filter)}</h3>}
          width={408}
          placement={i18n.language === "ar" ? "left" : "right"}
          closable={false}
          onClose={() => setFilterVisible(false)}
          visible={filterVisible}
          getContainer={false}
          style={{ position: "fixed" }}
        >
          {/* We use this condition to re render the component everytime we open the drawer, to replace the filters state with current filters values */}
          {filterVisible && (
            <AllFiltersDrawerContent
              closeDrawer={() => setFilterVisible(false)}
              setFilters={setFilters}
              filters={filters}
              timeKeys={timeKeys}
              setTimeKeys={setTimeKeys}
            />
          )}
        </Drawer>
      </div>

      {/*  */}
      {/* we create a new objects to compare with ( using fixed page number and page size ) */}
      {JSON.stringify({
        ...cleanObject(filters),
        page: 0,
        size: 20,
        filter: "",
        include_non_aged: 1,
      }) !==
        JSON.stringify({
          ...cleanObject(initialValues),
          page: 0,
          size: 20,
          filter: "",
          include_non_aged: 1,
        }) && (
        <SelectedFilters
          filters={filters}
          resetFilters={resetFilters}
          setFilterValue={setFilterValue}
        />
      )}
    </>
  );
};
