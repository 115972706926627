import * as Routings from "../../api-routes/Manager/Analytics/index";
import axios from "../../axios";
export interface IParams {
  from?: number;
  to?: number;
}
export const getBranchesPerformance = (token, params?: IParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${Routings.branchesPerformanceURL}`,
    {
      headers: { token },
      params: {
        ...params,
      },
    }
  );
};
