import { call, put, select, takeLatest } from "redux-saga/effects";
import { reorderBannerCardAction } from "../../constants";
import { selectToken } from "../selectors";
import { reorderBannerCardApi } from "../../axios/banner-cards";
import {
  reorderBannerCardFailure,
  reorderBannerCardSuccess,
  reorderBannerCard,
  resetBannerCardsState,
} from "../actions/brand-visuals-actions";
import { union } from "ts-action";
import { notify } from "react-notify-toast";
import strings from "../../i18n/strings/brandVisuals";
import { translate } from "../../helpers/translate";
const actionTypes = union({ reorderBannerCard });
function* reorderBannerCardSaga({ payload }: typeof actionTypes) {
  const t = translate("brandVisuals");
  try {
    const token = yield select(selectToken);
    const res = yield call(reorderBannerCardApi, token, {
      banner_card_id: payload.banner_card_id,
      new_order: payload.new_order,
    });
    yield put(reorderBannerCardSuccess(res.data));
    notify.show(t(strings.bannerCardReorderedSuccessfully), "success");
    yield put(resetBannerCardsState());
  } catch (error) {
    notify.show(t(strings.bannerCardReorderedFailed), "error");
    yield put(
      reorderBannerCardFailure({
        error: error as Error,
        order: payload.old_order,
        id: payload.banner_card_id,
      })
    );
  }
}

export default function* watchReorderBannerCardSaga() {
  yield takeLatest(reorderBannerCardAction.requested, reorderBannerCardSaga);
}
