import axios from "axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const uploadCustomerPointsCSV = (token: string, store_id: string, data: FormData): AxiosReturn<{}> => {
  return axios.post(Requests.uplaodCutomerPointsCSV,
    data,
    {
      headers: {
        "Content-Type": 'multipart/form-data',
        token,
      },
      params: {
        store_id
      },
      baseURL: process.env.REACT_APP_ENDPOINT
    }
  );
};
