import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const confirmPassword = (password: string, token): AxiosReturn<{}> => {
  return axios.post(
    Requests.confirmPassword,
    { password },
    {
      baseURL: process.env.REACT_APP_LUMEN_ENDPOINT,
      headers: {
        token,
      },
    }
  );
};
