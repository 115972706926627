import * as React from "react";
import styles from  "./styles.module.sass";
import { hot } from "react-hot-loader";
import { activityType, IActivityUser } from "../../hooks/types";
import { imagesURL } from "../../../../Leaderboard/Card";
import { useLocalizedMoment, useToggleState } from "../../../../../Utils";
import { useRtlClass } from "../../../../../lib";
import { Tooltip } from "reactstrap";
import { Placement } from "bootstrap";

interface IActivityMap {
  image: string;
  type: activityType;
  text: string;
  message?: string;
  created_at: number;
  user: IActivityUser;
  afterText?: string;
}

interface IProps {
  activity: IActivityMap;
}

interface ITextOverflowToolTipProps {
  maxLength: number;
  text: string;
  target: string;
  placement?: Placement;
}

export const TextOverflowToolTip: React.FC<ITextOverflowToolTipProps> = ({
  maxLength,
  text,
  target,
  placement = "top",
}) => {
  const [tooltipOpen, toggleToolTip] = useToggleState();
  return (
    <>
      {(text || "").length > maxLength && (
        <Tooltip
          style={{ fontSize: "1.4rem" }}
          placement={placement as Placement}
          target={target}
          toggle={toggleToolTip}
          isOpen={tooltipOpen}
        >
          {text}
        </Tooltip>
      )}
    </>
  );
};

export const useTruncationFull = (str: string, maxLength = 12) => {
  return React.useMemo(() => {
    const truncatedName = str.slice(0, maxLength);
    return truncatedName.length < str.length
      ? `${truncatedName}...`
      : truncatedName;
  }, [str]);
};

const LatestActivity: React.FC<IProps> = ({ activity }) => {
  const rtlClass = useRtlClass(styles);
  return (
    <>
      <div className={`${styles.wrapper} ${rtlClass}`}>
        <img src={activity.image} className={styles.dot} />
        <div className={styles.info}>
          <div className={styles.userWrapper}>
            <div className={styles.user}>
              <img
                src={imagesURL + activity.user.image}
                className={styles.userImage}
              />
              <div className={styles.textWrapper}>
                <b>{activity.user.name}</b>
                {activity.text}
                <b id={`tt${activity.created_at}`}>
                  {useTruncationFull(activity.afterText || "", 15)}
                </b>
                <TextOverflowToolTip
                  maxLength={15}
                  target={`#tt${activity.created_at}`}
                  text={activity.afterText || ""}
                />
              </div>
            </div>
            <div className={styles.date}>
              {useLocalizedMoment(activity.created_at, "x").fromNow()}
            </div>
          </div>
          {activity.message ? (
            <p className={styles.commentText}>{activity.message}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default hot(module)(LatestActivity);
