import * as React from "react";
import Icon from "@material-ui/core/Icon/Icon";
import { ClipLoader } from "react-spinners";

interface IProps {
  btnStyle: string;
  textStyles?: string;
  iconWrapperStyles?: string;
  name?: string;
  icon: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick: React.EventHandler<React.MouseEvent>;
}

export class ButtonWithIcon extends React.Component<IProps> {
  public render() {
    const {
      btnStyle,
      icon,
      name,
      textStyles,
      onClick,
      iconWrapperStyles,
      isLoading,
      disabled,
    } = this.props;
    return (
      <button
        onClick={onClick}
        disabled={disabled || isLoading}
        className={btnStyle}
      >
        {isLoading ? (
          <ClipLoader
            sizeUnit={"px"}
            size={20}
            color={"white"}
            loading={true}
          />
        ) : (
          <>
            {iconWrapperStyles ? (
              <div className={iconWrapperStyles}>
                <Icon>{icon}</Icon>
              </div>
            ) : (
              <Icon>{icon}</Icon>
            )}
            <p className={textStyles}>{name}</p>
          </>
        )}
      </button>
    );
  }
}
