import axios from "../../../axios/axios";
import { CHANGE_ITEM_CATEGORY } from "./requests";

export const changeItemCategoryAPI = (
  token: string,
  item_id: string,
  category_id: string
) => {
  return axios.put(
    CHANGE_ITEM_CATEGORY(item_id),
    { category_id },
    {
      headers: { token },
      baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
    }
  );
};
