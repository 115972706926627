export default {
  search: "البحث عن اسم المنتج ووصف المنتج",
  noItemAdded: '"لم تتم إضافة أي منتج"',
  noItemAddedDescription:
    ' "لم تتم إضافةأي منتجات" - أضف تصنيف واحد على الأقل لتتمكن من إضافة منتجات',
  addNewItem: "اضافة منتج جديد",
  deleteItem: "حذف المنتج",
  editItem: "تعديل المنتج",
  duplicateItem: "تكرار المنتج",
  delete: "حذف",
  cancel: "الغاء",
  item: "منتج",
  itemAvailableInBranches: "هذا الصنف متاح في {{branches_count}} فروع:",
  itemNotAvailableInBranches: "هذا الصنف غير متاح في {{branches_count}} فروع:",
  itemAvailableInBranch: "هذا الصنف متاح في فرع:",
  itemNotAvailableInBranch: "هذا الصنف غير متاح في فرع:",
  moreBranches: "+{{more_branches}} فروع اخري",
  availableIn: "متاح في: {{branches}}",
  unavailableIn: "غير متاح في: {{branches}}",
  confirm: "تأكيد",
  duplicateTitle: "تكرار ({{item_name}})",
  duplicateConfirm: "هل تريد تكرار ({{item_name}})",
};
