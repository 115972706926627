Object.defineProperty(exports, "__esModule", {
  value: true
});

const disabledChipTheme = {
  chip: {
    padding: ".3rem 1.5rem",
    background: "#B1B1B1",
    color: "white",
    borderRadius: 40,
    margin: "2.5px",
    display: 'flex',
    cursor: 'default'
  },
  chipSelected: {
    background: '#888'
  },
  chipRemove: {
    fontWeight: "bold",
    cursor: "pointer",
    ':hover': {
      color: 'black'
    }
  }
};
exports.default = disabledChipTheme;
