import { action, payload } from "ts-action";
import { getStatisticsAction } from "../../constants/actions";
import { IStatistics } from "./lib";

export interface IStatisticsFilter {
  start_time?: number;
  end_time?: number;
  branch_id?: string;
  order_type?: string;
}
export const getStatistics = action(
  getStatisticsAction.requested,
  payload<IStatisticsFilter>()
);

export const getStatisticsSuccess = action(
  getStatisticsAction.fulfilled,
  payload<IStatistics>()
);
export const getStatisticsFailure = action(
  getStatisticsAction.rejected,
  payload<Error>()
);
