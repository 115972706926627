import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.sass";

// hooks
import { useSelect } from "../../../../../../hooks/useSelect";
// components
import { LineGraph } from "../LineGraph";
import { TimeFilter, TypeFilter } from "../index";
// assets
import InfoIcon from "../../../../../../Components/Pickup/assets/group-2.svg";

// queries
import { useGetOrdersChartQuery } from "../../../../../../Queries/Manager";
// helpers
import { getHoursLabels } from "../../../../../../helpers/analytics";
import * as configs from "../LineGraph/configs";
import MultiSelect, {
  mapStringArryToSelectedArray,
} from "../../../../../../@koinz-ui/MultiSelect";
import { ITotalOrders } from "../../../../../../Components/Pickup/lib/types";
import FeatherArrowIcon from "../FeatherArrowIcon";
import i18n from "../../../../../../i18n/i18n";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { LineGraphDataset } from "../LineGraph/types";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import {
  getOnlineOrdersGraphReq,
  getOrdersGraphReq,
} from "../../../../../../axios/Manager/Analytics";
import { useQueries } from "react-query";
import { IBranch } from "../../../../../../types/shared";

interface IProps {
  totalOrdersData: ITotalOrders;
}
export enum OrderType {
  pickup = "pickup",
  delivery = "delivery",
}
export enum OrderingSource {
  ordering_portal = "ordering_portal",
  customer_app = "customer_app",
  qr_portal = "qr_portal"
}

const OrdersChart: React.FC<IProps> = (props) => {
  const {
    store: { created_at, id: store_id },
    token,
  } = useSelect((state) => {
    return {
      ...state.storeReducer,
      ...state.authReducer,
    };
  });

  const { t } = useTranslation("home");

  const { allBranches } = useSelect((state) => state.branchesReducer);

  const [selectedBranches, setSelectedBranches] = useState([]);
  const [branchesData, setBranchesData] = useState<any>([]);
  const [allSelectedBranchesLoaded, setAllSelectedBranchesLoaded] =
    useState<boolean>(false);

  const [branchesFilterIsApplied, setBranchesFilterIsApplied] =
    useState<boolean>(false);

  const [getOrdersChartReqConfigs, setGetOrdersChartRequestConfig] = useState<{
    from: number;
    to: number;
    unit: "hour" | "day";
    branch_ids?: string[];
    order_type?: OrderType;
    ordering_source?: OrderingSource;
  }>({
    from: 0,
    to: 0,
    unit: "day",
  });

  const graphColors = ["#38C172", "#E251FF", "#5151FF", "#503E9D", "#FFA351"];
  const primaryColor = "#FFA351";

  const [lineGraphProps, setLineGraphProps] = useState<{
    width: number;
    height: number;
    values: {
      datasets: LineGraphDataset[];
      labels: string[];
    };
  }>({
    width: 1080,
    height: 230,
    values: {
      datasets: [],
      labels: [],
    },
  });

  const isOnlineOrder = useDeepCompareMemo(
    () =>
      Boolean(
        getOrdersChartReqConfigs.order_type ||
          getOrdersChartReqConfigs.ordering_source
      ),

    [getOrdersChartReqConfigs]
  );
  // handling api requests
  const { data: singleLineGraphData, isLoading } = useGetOrdersChartQuery(
    token,
    getOrdersChartReqConfigs,
    isOnlineOrder
  );

  const [typeFilterValue, setTypeFilterValue] = useState<
    "all" | "offline_orders_count"
  >("all");

  // when accumulative data is recieved as a single line graph
  useEffect(() => {
    if (singleLineGraphData && !isLoading && !branchesFilterIsApplied) {
      const processedData = filterAndAdjustDataPoints(singleLineGraphData);

      if (getOrdersChartReqConfigs.unit === "hour") {
        const hoursLabels = getHoursLabels(processedData);

        setLineGraphProps((prevValue: any) => ({
          ...prevValue,
          values: {
            ...prevValue.values,
            labels: hoursLabels,
            datasets: [
              {
                data: mapDataWithLabels(processedData, hoursLabels),
                borderColor: primaryColor,
                borderWidth: 2,
              },
            ],
          },
        }));
      } else if (getOrdersChartReqConfigs.unit === "day") {
        const exactLabels = getExactPointsLabels(processedData);
        setLineGraphProps((prevValue: any) => ({
          ...prevValue,
          values: {
            ...prevValue.values,
            labels: exactLabels,
            datasets: [
              {
                data: mapDataWithLabels(processedData, exactLabels),
                borderColor: primaryColor,
                borderWidth: 2,
              },
            ],
          },
        }));
      }
    }
  }, [
    singleLineGraphData,
    isLoading,
    typeFilterValue,
    getOrdersChartReqConfigs,
    branchesFilterIsApplied,
  ]);

  // branches comparison
  useDeepCompareEffect(() => {
    // if branches selected
    if (
      branchesData.length > 0 &&
      selectedBranches.length < 6 &&
      allSelectedBranchesLoaded &&
      branchesFilterIsApplied
    ) {
      // [[], [], []]

      const processedData = branchesData.map((singleBranchData) =>
        filterAndAdjustDataPoints(singleBranchData)
      );

      if (getOrdersChartReqConfigs.unit === "hour") {
        const hoursLabels = getHoursLabels(processedData[0]);
        const datasets = processedData.map(
          (singleBranchData: any[], index) => ({
            data: mapDataWithLabels(singleBranchData, hoursLabels),
            borderColor: graphColors[index],
            borderWidth: 2,
          })
        );

        setLineGraphProps((prevValue) => ({
          ...prevValue,
          values: {
            labels: hoursLabels,
            datasets,
          },
        }));
      } else if (getOrdersChartReqConfigs.unit === "day") {
        const exactLabels = getExactPointsLabels(processedData[0]);
        const datasets = processedData.map(
          (singleBranchData: any[], index) => ({
            data: mapDataWithLabels(singleBranchData, exactLabels),
            borderColor: graphColors[index],
            borderWidth: 2,
          })
        );

        setLineGraphProps((prevValue) => ({
          ...prevValue,
          values: {
            labels: exactLabels,
            datasets,
          },
        }));
      }
    }
  }, [
    branchesData,
    selectedBranches,
    allSelectedBranchesLoaded,
    branchesFilterIsApplied,
    getOrdersChartReqConfigs,
    typeFilterValue,
  ]);

  const filterAndAdjustDataPoints = (data) => {
    return (data || [])
      .filter((element) =>
        isOnlineOrder
          ? !(element.total_orders === 0 && element.date === "1970-01-01")
          : !(
              element.online_orders_count === 0 &&
              element.offline_orders_count === 0 &&
              element.total_orders === 0 &&
              element.date === "1970-01-01"
            )
      )
      .map((element) => {
        return {
          yAxisValue:
            typeFilterValue === "all" || isOnlineOrder
              ? isOnlineOrder
                ? element.completed_orders
                : element.total_orders
              : element[typeFilterValue],
          ...element,
        };
      });
  };

  // mapping data with labels
  const mapDataWithLabels = (data, labels) => {
    return data.map((element, index) => ({ ...element, label: labels[index] }));
  };

  // Setting request configs
  useEffect(() => {
    if (typeof created_at === "number") {
      const now = new Date();
      const nowTimeStamp = now.getTime();
      const difference = nowTimeStamp - created_at;

      let daysFromStoreCreation = 0;

      const dateOfTodayAYearAgo = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );

      const oneYearAgoTimestamp = dateOfTodayAYearAgo.getTime();

      const timestampOfADay = 60 * 60 * 24 * 1000;

      daysFromStoreCreation = difference / timestampOfADay;

      // if less than a day => unit = "hour" else "day"
      setGetOrdersChartRequestConfig((prevValue) => ({
        ...prevValue,
        from:
          daysFromStoreCreation <= 28
            ? created_at
            : new Date(now.getTime() - 28 * timestampOfADay).getTime(),
        to: nowTimeStamp,
        unit: daysFromStoreCreation < 1 ? "hour" : "day",
        store_id,
      }));
    }
  }, [created_at, store_id]);

  const getExactPointsLabels = (
    data: {
      date: string;
      [key: string]: number | string;
    }[]
  ) => {
    return data.map((element) => {
      const formattedDate = Date.parse(element.date);
      const monthName = new Intl.DateTimeFormat(i18n.language, {
        month: "short",
      }).format(formattedDate);
      const day = new Intl.DateTimeFormat(i18n.language, {
        day: "2-digit",
      }).format(formattedDate);

      return `${monthName} ${day}`;
    });
  };

  const handleTimeFilterChange = (value: {
    from: number;
    to: number;
    unit: "hour" | "day";
  }) => {
    setGetOrdersChartRequestConfig((prevValue) => ({
      ...prevValue,
      from: value.from,
      to: value.to,
      unit: value.unit,
    }));
  };

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  // on apply filter branches
  const handleBranchFilterMultiSelectChange = (values) => {
    setSelectedBranches(values);
    // if select all
    if (values.length === branchesOptions.length) {
      setBranchesFilterIsApplied(false);
    } else {
      setBranchesFilterIsApplied(true);
    }
  };

  const branchesQueries = useQueries(
    selectedBranches.map((branch: any) => {
      const isBranchesFilterValid =
        branchesData.length > 0 && selectedBranches.length <= 5;

      return {
        queryKey: [
          "branch-data",
          "orders-chart",
          branch.id,
          getOrdersChartReqConfigs,
        ],
        queryFn: () =>
          isOnlineOrder
            ? getOnlineOrdersGraphReq(token, {
                ...getOrdersChartReqConfigs,
                branch_ids: [branch.id],
              }).then((res) => res.data)
            : getOrdersGraphReq(token, {
                ...getOrdersChartReqConfigs,
                branch_ids: [branch.id],
              }).then((res) => res.data),
        enabled: isBranchesFilterValid,
        retry: 3,
      };
    })
  );

  useDeepCompareEffect(() => {
    const isBranchesLoaded =
      branchesQueries.map((result) => result.isLoading).filter(Boolean)
        .length === 0;

    if (isBranchesLoaded) {
      setBranchesData([...branchesQueries.map((result) => result.data)]);
      setAllSelectedBranchesLoaded(isBranchesLoaded);
    }
  }, [branchesQueries.map((result) => result.data)]);

  // types filter handlers
  const handleTypeFilterChange = (value) => {
    if (value in OrderType) {
      setGetOrdersChartRequestConfig((prevState) => ({
        ...prevState,
        order_type: value,
        ordering_source: undefined,
      }));
    } else if (value === OrderingSource.ordering_portal || value === OrderingSource.qr_portal) {
      setGetOrdersChartRequestConfig((prevState) => ({
        ...prevState,
        order_type: undefined,
        ordering_source: value,
      }));
    } else {
      setGetOrdersChartRequestConfig((prevState) => ({
        ...prevState,
        order_type: undefined,
        ordering_source: undefined,
      }));
      setTypeFilterValue(value);
    }
  };

  const typeFilterOptions = [
    { name: t(Strings.all), id: "all" },
    {
      name: t(Strings.pickup_orders),
      id: "pickup",
    },
    {
      name: t(Strings.delivery_orders),
      id: "delivery",
    },
    {
      name: t(Strings.web_portal_orders),
      id: "ordering_portal",
    },
    {
      name: "QR portal",
      id: "qr_portal"
    },
    {
      name: t(Strings.offline_visits),
      id: "offline_orders_count",
    },
  ];
  const hasChangeValueInTotalValueRes = useMemo(
    () => typeof props.totalOrdersData?.change !== "undefined",
    [props.totalOrdersData?.change]
  );

  return (
    <>
      <div className={styles.numbersAndFiltersContainer}>
        <div className={styles.totalOrdersWrapper}>
          {/* <div className={styles.totalOrdersMain}>
            <span
              className={styles.totalOrdersTitle}
              style={{
                marginRight: hasChangeValueInTotalValueRes ? ".1rem" : ".6rem",
              }}
            >
              {Number(props.totalOrdersData?.total || 0)}
            </span>
            {hasChangeValueInTotalValueRes && (
              <React.Fragment>
                {Number(props.totalOrdersData?.change) > 0 ? (
                  <FeatherArrowIcon
                    width={"20"}
                    height={"20"}
                    stroke={"#000000"}
                  />
                ) : (
                  <div style={{ transform: "rotate(180deg)" }}>
                    <FeatherArrowIcon
                      width={"20"}
                      height={"20"}
                      stroke={"#000000"}
                    />
                  </div>
                )}
              </React.Fragment>
            )}

            <img src={InfoIcon} className={styles.infoIcon} />
          </div>
          {hasChangeValueInTotalValueRes ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  transform:
                    Number(props.totalOrdersData?.change) > 0
                      ? undefined
                      : "rotate(180deg)",
                }}
              >
                <FeatherArrowIcon
                  width={"14"}
                  height={"14"}
                  stroke={
                    Number(props.totalOrdersData?.change) > 0
                      ? "#38C172"
                      : "#EE5253"
                  }
                />
              </div>

              <span
                className={styles.totalOrdersSubtitle}
                style={{
                  color:
                    Number(props.totalOrdersData?.change) > 0
                      ? "#38C172"
                      : "#EE5253",
                }}
              >
                {t(Strings.order_percentage_from_last_30_days, {
                  order_percentage: `${props.totalOrdersData?.change}%`,
                })}
              </span>
            </div>
          ) : (
            <span className={styles.totalOrdersSubtitle}>
              {t(Strings.orders)}
            </span>
          )} */}
        </div>

        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* type filter */}

            <TypeFilter
              onChange={handleTypeFilterChange}
              typesArr={typeFilterOptions}
              showSearch={false}
              prefixText={t(Strings.order_type)}
              includeAllOption={false}
            />

            {/* branch filter */}
            <div className={styles.branchMultiSelect}>
              <MultiSelect
                label={t(Strings.branch)}
                canSelectAll={true}
                onApply={handleBranchFilterMultiSelectChange}
                options={branchesOptions}
                selected={
                  selectedBranches.length > 0
                    ? selectedBranches
                    : branchesOptions
                }
                defaultSelected={branchesOptions}
                maxSelectionsNo={5}
              />
            </div>

            <TimeFilter
              valueKey="last-28-days"
              onChange={handleTimeFilterChange}
            />
          </div>
        </div>
      </div>

      <LineGraph
        {...lineGraphProps}
        lineGraphConfigs={configs.ordersGraphConfigs}
      />

      {selectedBranches &&
      selectedBranches.length < 6 &&
      selectedBranches.length > 0 &&
      branchesFilterIsApplied ? (
        <div className={styles.legendsContainer}>
          {selectedBranches.map((element: IBranch, index) => {
            return (
              <div className={styles.legendWrapepr} key={element.id}>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: graphColors[index],
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                ></span>
                <span>
                  {i18n.language.includes("ar")
                    ? element.ar_name
                    : element.en_name}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default OrdersChart;
