export default {
  menu: "استيراد القائمة",
  giftList: "اختيار قائمة الهدايا",
  convertingRatio: "حدد نسبة تحويل الفواتير إلى نقاط",
  finished: "تهانينا!",
  nameBio: "الاسم ووصف المتجر",
  brandVisuals: "الصور والألوان",
  socialLinks: "التواصل الاجتماعي",
  branches: "ادارة الفروع",
  errorRetrying: "حدثت مشكلة ما، جاري إعادة المحاولة...",
  unauthorized: "ممنوع الدخول",
};
