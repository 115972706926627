import { IFormState, IRootReducerState } from "./rootReducer";
import { ICataloguedItems } from "../types/wizard-types";

export const selectForm = (state: IRootReducerState): IFormState =>
  state.form as any;
export const selectToken = (state: IRootReducerState): string =>
  state.authReducer.token;
export const selectAddMore = (state: IRootReducerState): boolean =>
  state.addMoreReducer.addMore;
export const selectCats = (state: IRootReducerState): ICataloguedItems[] =>
  state.menuReducer.categories;
export const selectMenus = (state: IRootReducerState): string[] =>
  state.orderingMenusReducer.menus;
