import { call, put, select, takeLatest } from "redux-saga/effects";
import { blockFraudlentCustomer, removeFraudlentCustomerPoints } from "../../constants";
import { selectToken } from "../selectors";
import { createBlockedUser as apiCall } from "../../axios/createBlockedUser";
import { blockFraudlentCustomerSuccess, blockFraudlentCustomerFailure } from "../actions/edit-fraud-list-actions";

function* blockFraudCustomerSaga(action) {
  try {
    const token = yield select(selectToken);
    const payload = action.payload;
    const response = yield call(apiCall, token, { phone_number: payload.phone_number });
    yield put(
      blockFraudlentCustomerSuccess({ phone_number: payload.phone_number, state: "blocked" })
    );
  } catch (e: unknown) {
    yield put(blockFraudlentCustomerFailure(e as Error));
  }
}

export function* watchBlockFraudCustomerSaga() {
  yield takeLatest(blockFraudlentCustomer.requested, blockFraudCustomerSaga);
}
