import axios from "../../axios";
import * as routes from "../../api-routes/Manager/Analytics";

export const getCustomersSegmentOverview = async (token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getCustomersSegmentOverview}`,
    {
      headers: {
        token,
      },
    }
  );
  return data;
};
export const getCustomersSegmentChart = async (token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getCustomersSegmentChart}`,
    {
      headers: {
        token,
      },
    }
  );
  return data;
};

export const getCustomersSegmentGenderChartApi = async (token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getCustomersSegmentGenderChartURL}`,
    {
      headers: {
        token,
      },
    }
  );
  return data;
};
