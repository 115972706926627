import { useContext } from "react";
import { useQuery } from "react-query";
import {
  getCustomersSegmentOverview,
  getCustomersSegmentChart,
  getCustomersSegmentGenderChartApi,
} from "../../../axios/Manager/Analytics";
import StoreData from "../../../contexts/StoreData";
export interface IGender {
  count: number;
  percentage: number;
  average_ticket_size: number;
}
export interface IGenderSegments {
  male: IGender;
  female: IGender;
}
export const useGetCustomersSegmentOverview = () => {
  const { token } = useContext(StoreData);
  return useQuery(
    "customers-segment-overview",
    async () => await getCustomersSegmentOverview(token)
  );
};

export const useGetCustomersSegmentChart = () => {
  const { token } = useContext(StoreData);
  return useQuery(
    "customers-segment-chart",
    async () => await getCustomersSegmentChart(token)
  );
};

export const useGetCustomersSegmentGenderChart = () => {
  const { token } = useContext(StoreData);
  return useQuery<IGenderSegments, Error>(
    "customers-segment-gender-chart",
    async () => await getCustomersSegmentGenderChartApi(token)
  );
};
