import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { deleteOrderingMenuApi } from "../../axios/deleteOrderingMenuApi";
import { deleteOrderingMenuAction } from "../../constants/actions";
import {
  deleteOrderingMenuFailure,
  deleteOrderingMenuSuccess,
} from "../actions";

function* deleteOrderingMenuSaga() {
  try {
    const token = yield select(selectToken);
    yield call(deleteOrderingMenuApi, token);
    yield put(deleteOrderingMenuSuccess());
  } catch (error) {
    yield put(deleteOrderingMenuFailure(error as Error));
  }
}

export function* watchDeleteOrderingMenuSaga() {
  yield takeLatest(deleteOrderingMenuAction.requested, deleteOrderingMenuSaga);
}
