import {
  ILoginAction,
  ILoginFailureAction,
  ILoginSuccessAction,
} from "../../types";
import { loginAction } from "../../constants";

export const login = (token): ILoginAction => ({
  type: loginAction.requested,
  payload: token,
});
export const loginSuccess = (data): ILoginSuccessAction => ({
  type: loginAction.fulfilled,
  payload: data,
});
export const loginFailure = (error): ILoginFailureAction => ({
  type: loginAction.rejected,
  payload: error,
});
