import React from "react";
import { SettingsIcon } from "../../icons";
import { SubMenu } from "../index";
import { Link } from "react-router-dom";
import styles from "../../styles.module.sass";
import Strings from "../../../../i18n/strings/navigation";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import { useSelect } from "../../../../hooks/useSelect";
interface IProps {
  activeNavigate: (values) => boolean;
  collapsed: boolean;
  canAccessBranches: Boolean;
  hasFullAccess: boolean;
}
const CollapseOrders: React.FC<IProps> = ({
  activeNavigate,
  collapsed,
  canAccessBranches,
  hasFullAccess,
}) => {
  const { t } = useTranslation("navigation");
  const { entitiesIds: posEntitiesIds } = useSelect(state => state.getPosEntitiesReducer)
  return (
    <SubMenu
      active={activeNavigate("/settings")}
      key={"branches_cashiers"}
      title={t(Strings.settings)}
      collapsed={collapsed}
      clicked={activeNavigate("/settings")}
      icon={<SettingsIcon clicked={activeNavigate("/settings")} />}
    >
      {canAccessBranches && (
        <Link
          to={"/settings/branches_cashiers"}
          className={
            activeNavigate("branches_cashiers")
              ? styles.activeCollapseItem
              : styles.collapseItem
          }
        >
          {t(Strings.branchesAndCashiers)}
        </Link>
      )}

      {hasFullAccess && (
        <React.Fragment>
          {!!posEntitiesIds.length && <Link
            to={"/settings/pos"}
            className={
              activeNavigate("pos")
                ? styles.activeCollapseItem
                : styles.collapseItem
            }
          >
            {t(Strings.posSettings)}
          </Link>}
          <Link
            to={"/settings/users"}
            className={
              activeNavigate("users")
                ? styles.activeCollapseItem
                : styles.collapseItem
            }
          >
            {t(Strings.dashboardUsers)}
          </Link>
          <Link
            to={"/settings/advanced"}
            className={
              activeNavigate("advanced")
                ? styles.activeCollapseItem
                : styles.collapseItem
            }
          >
            {t(Strings.advancedSettings)}
          </Link>
        </React.Fragment>
      )}
    </SubMenu>
  );
};

export default CollapseOrders;
