export default {
  name: "Name",
  phone: "Phone",
  email: "Email",
  role: "Role",
  password: "Password",
  signUpTitle: "Sign Up",
  signUpSubTitle: "By clicking sign up your are agree to our",
  signUpSubTitle2: "terms & condition",
  haveAnAccount: "have an account?",
  logInHere: "Login here",
  submit: "Sign up",
};
