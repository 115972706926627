export default {
  paymentTitle: "Setup customers payment methods",
  paymentContent:
    "Choose the payment method that you want to enable to your customers",
  cashOnly: "Cash only",
  cashOnlyContent: "Customers will pay only in cash",
  cashAndCard: "Cash & Card",
  cashAndCardContent: "Customers will pay in cash or by card",
  cardOnly: "Card only",
  cardOnlyContent: "Customers will pay only by card",
};
