import axios from "../../../axios/axios";
import * as Requests from "./requests";
import { AxiosPromise, AxiosResponse } from "axios";
import { simpleCacheControl } from "../lib/tempCache";
const HOUR = 60 * 60 * 1000;

export const handleSimpleCaching = <
  Fn extends (...args) => AxiosPromise<P>,
  P = any
>(
  fn: Fn,
  key: string
) => (...args: Parameters<Fn>) => {
  const cacheKey = key + args.join("");
  const latestCachedVersion = simpleCacheControl.getCacheContent(cacheKey);
  if (latestCachedVersion) {
    const diff = new Date().getTime() - latestCachedVersion.lastUpdated;
    if (diff < HOUR) {
      return Promise.resolve(latestCachedVersion);
    }
  }
  return fn(...args).then<AxiosResponse<P>, AxiosResponse<P>>((res) => {
    simpleCacheControl.updateCache(cacheKey, res.data);
    return res;
  });
};

export const getZonesAPI = handleSimpleCaching(
  (token: string, district_id: string) => {
    return axios.get(Requests.getZonesAPI, {
      baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
      headers: {
        token,
      },
      params: {
        district_id,
      },
    });
  },
  Requests.getZonesAPI
);
