import {
  welcome,
  completeSteps,
  chooseLanguage,
  next,
  terms,
  progressAutoSave,
  estimatedTime,
  termsBtn,
} from "../strings/chooseLanguage";

export default {
  [welcome]: "Welcome aboard!",
  [completeSteps]: "Please complete the following steps to start your brand",
  [chooseLanguage]: "Please choose your language below",
  [next]: "Next",
  [terms]: "By clicking next you agreed on the ",
  [termsBtn]: "Terms & Conditions",
  [progressAutoSave]: "Your progress will be auto saved",
  [estimatedTime]: "Estimated time to complete: 1 day",
};
