import axios from "./axios";
import * as Requests from "./requests";

export const reOrderItem = (token, order_id: string, order) => {
  return axios.put(
    Requests.REORDERING_ITEM_API(order_id),
    { order },
    {
      headers: {
        token,
      },
    }
  );
};
