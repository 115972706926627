import moment from "moment";
import { CustomerBox } from "../CustomerBox";
import { translate } from "../../../../../helpers/translate";
import Strings from "../../../../../i18n/Manager/strings/insights";
import i18n from "../../../../../i18n/i18n";

const t = translate("insights");
export const columns = (currency) => [
  {
    title: t(Strings.transaction_time),
    dataIndex: "",
    key: "",
    render: (obj) => (
      <span>
        {moment(obj.created_at)
          .locale(i18n.language.includes("ar") ? "ar" : "en")
          .format("DD MMM YYYY - hh:mm a")}
      </span>
    ),
  },
  {
    title: t(Strings.gift),
    dataIndex: "",
    key: "",
    render: (obj) => (
      <div>
        {obj.item_name} <br />{" "}
        <span style={{ color: "#8E8E93" }}>
          {" "}
          {obj.item_price} {currency}{" "}
        </span>
      </div>
    ),
  },
  {
    title: t(Strings.points),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => <span>{obj.points || 0}</span>,
  },
  {
    title: t(Strings.customer),
    dataIndex: "",
    key: "",
    render: (obj) => <CustomerBox name={obj.customer_name} />,
    width: 320,
  },
  {
    title: t(Strings.branch),
    render: (obj) => (
      <span>
        {i18n.language.includes("ar") && obj.branch_ar_name
          ? obj.branch_ar_name
          : obj.branch_en_name}
      </span>
    ),
    key: i18n.language.includes("en") ? "branch_en_name" : "branch_ar_name",
    sorter: false,
    ellipsis: {
      showTitle: false,
    },
  },
];
