import React from "react";
interface IProps {
  style: React.CSSProperties;
  pathStyle: React.CSSProperties;
}
export const Chevron: React.FC<IProps> = ({ style, pathStyle }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      style={style}
      viewBox="0 0 16 16"
    >
      <path
        style={pathStyle}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M6.667 12l4-4-4-4"
      />
    </svg>
  );
};

export default Chevron;
