import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getRecommendedGiftAction } from "../../../constants";
import { IRecommendedGift } from "../../../types/wizard-types";
import { getRecommendedGiftSuccess } from "../../actions";

interface IState {
  recommended_gift: IRecommendedGift | undefined;
}
export const getRecommendedGiftReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getRecommendedGiftSuccess, (state, { payload }) => ({
        ...state,
        recommended_gift: payload,
      })),
    ],
    {
      recommended_gift: undefined,
    }
  ),
  getRecommendedGiftAction
);
