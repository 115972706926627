import {
  IAddStoreItemFailureAction,
  IAddStoreItemSuccessAction,
  ICUDItemsAction,
  IDeleteStoreItemAction,
  IDeleteStoreItemFailureAction,
  IEditStoreItemAction,
  IEditStoreItemFailureAction,
  IEditStoreItemSuccessAction,
  IStoreItem,
  OptimisticLoading,
} from "../../types/wizard-types";
import * as constants from "../../constants";
import { orderByIndex, sortByOrder, updateItemOrdering } from "./menuReducer";

type IItemsReducerState = IStoreItem[];

export const itemsReducer = (
  state: IItemsReducerState,
  action: ICUDItemsAction
) => {
  switch (action.type) {
    case constants.addStoreItemAction.requested: {
      return [...state, action.payload];
    }
    case constants.addStoreItemAction.rejected: {
      const { payload } = action as IAddStoreItemFailureAction;
      return state.filter((item) => item.optimisticId !== payload.optimisticId);
    }
    case constants.addStoreItemAction.fulfilled: {
      const { payload } = action as IAddStoreItemSuccessAction;
      return state.map((item) =>
        item.optimisticId === payload.optimisticId ? payload : item
      );
    }
    case constants.deleteStoreItemAction.fulfilled: {
      return state.map(orderByIndex);
    }
    case constants.deleteStoreItemAction.requested: {
      const { payload } = action as IDeleteStoreItemAction;
      return state.filter((i) => i.id !== payload.id).sort(sortByOrder);
    }
    case constants.deleteStoreItemAction.rejected: {
      const { payload } = action as IDeleteStoreItemFailureAction;
      return [...state, payload].sort(sortByOrder);
    }
    case constants.editStoreItemAction.rejected: {
      const { payload } = action as IEditStoreItemFailureAction;
      const failedItem = state.find((i) => i.id === payload.id) as IStoreItem;
      return failedItem.fallBack;
    }
    case constants.editStoreItemAction.requested: {
      const { payload } = action as IEditStoreItemAction;
      const itemOldData = state.find((c) => c.id === payload.id) as IStoreItem;
      if (payload.order !== undefined && itemOldData.order !== payload.order) {
        return updateItemOrdering(
          state,
          {
            ...itemOldData,
            ...payload,
          },
          itemOldData
        );
      } else {
        return state.map((c) =>
          c.id === payload.id
            ? {
                ...itemOldData,
                ...payload,
                fallBack: state,
                state: OptimisticLoading,
              }
            : c
        );
      }
    }
    case constants.editStoreItemAction.fulfilled: {
      const { payload } = action as IEditStoreItemSuccessAction;
      return state.map((i) => (i.id === payload.id ? { ...i, ...payload } : i));
    }
    default:
      return state;
  }
};
