import axios from "./axios";
import * as Requests from "./requests";

export const deleteOrderingMenuCategory = (token: string, id: string) => {
  return axios.delete(Requests.REORDERING_CATEGORY_API(id), {
    headers: {
      token,
    },
  });
};
