import convertingRatio from "./convertingRatio";
import sl from "./socialLinks";

export default {
  newCashier: "newCashier",
  phoneNumber: "phoneNumber",
  name: "name",
  branch: "branch",
  thereIsNoMatch: "thereIsNoMatch",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  noCashiersAreAddedYet: "noCashiersAreAddedYet",
  searchForCashiers: "searchForCashiers",
  all: "all",
  unassigned: "unassigned",
  assigned: "assigned",
  UNASSIGNED: "UNASSIGNED",
  cashierName: "cashierName",
  assignToBranches: "assignToBranches",
  password: "password",
  integrationId: "integrationId",
  addBranchTitle: "addBranchTitle",
  addCallCenterTitle: "addCallCenterTitle",
  addToBranchTitle: "addToBranchTitle",
  brandSettings: "brandSettings",
  unassignedCashiers: "unassignedCashiers",
  addCashier: "addCashier",
  editCashier: "editCashier",
  viewCashier: "viewCashier",
  assignedCashiers: "assignedCashiers",
  plus: "plus",
  submit: sl.submit,
  addCashierButtonTitle: "addCashierButtonTitle",
  deleteCashier: "deleteCashier",
  deleteCashierDescription: "deleteCashierDescription",
  confirmDelete: "confirmDelete",
  cancel: "cancel",
  callCenterBranch: "callCenterBranch",
  loyaltyTooltipText: "loyaltyTooltipText",
  loyaltyText: 'loyaltyText'
};
