import { action, payload } from "ts-action";
import { orderTrackingAction } from "../../constants/actions";
import { IOrderTracking } from "./lib";

export const orderTracking = action(
  orderTrackingAction.requested,
  payload<string>()
);
export const orderTrackingSuccess = action(
  orderTrackingAction.fulfilled,
  payload<IOrderTracking>()
);
export const orderTrackingFailure = action(
  orderTrackingAction.rejected,
  payload<Error>()
);
