import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en";
import ar from "./ar";

i18n.use(LanguageDetector).init({
  resources: {
    en,
    ar,
  },
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
});

export const translateLeaderboard = (key, options?) =>
  i18n.t(key, { ns: "leaderboard", ...options });

export default i18n;
