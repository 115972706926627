import { action, payload } from "ts-action";
import {
  getPastOrderAction,
  getOrderDetailsAction,
  orderAction,
  getBranchInfoAction,
  getStoreInfoAction,
  getExtraInfoAction,
} from "../../constants/actions";
import {
  IOrders,
  IFilter,
  IOrderDetails,
  ISpecificOrder,
  IStoreAppInfo,
  IAddress,
} from "./lib";
import { IStore } from "../../../../types";

interface IOrder {
  page: number;
  per_page: number;
  filters?: IFilter;
}
export const getPastOrders = action(
  getPastOrderAction.requested,
  payload<IOrder>()
);
export const getPastOrderSuccess = action(
  getPastOrderAction.fulfilled,
  payload<IOrders>()
);
export const getPastOrderFailure = action(
  getPastOrderAction.rejected,
  payload<Error>()
);

export const getOrderDetails = action(
  getOrderDetailsAction.requested,
  payload<string>()
);
export const getOrderDetailsSuccess = action(
  getOrderDetailsAction.fulfilled,
  payload<IOrderDetails>()
);
export const getOrderDetailsFailure = action(
  getOrderDetailsAction.rejected,
  payload<Error>()
);

export const getOrder = action(orderAction.requested, payload<string>());
export const getOrderSuccess = action(
  orderAction.fulfilled,
  payload<ISpecificOrder>()
);
export const getOrderFailure = action(orderAction.rejected, payload<Error>());

export const getBranchInfo = action(
  getBranchInfoAction.requested,
  payload<string>()
);
export const getBranchInfoSuccess = action(
  getBranchInfoAction.fulfilled,
  payload<IStore>()
);
export const getBranchInfoFailure = action(
  getBranchInfoAction.rejected,
  payload<Error>()
);

export const getStoreInfo = action(
  getStoreInfoAction.requested,
  payload<string>()
);
export const getStoreInfoSuccess = action(
  getStoreInfoAction.fulfilled,
  payload<IStoreAppInfo>()
);
export const getStoreFailure = action(
  getStoreInfoAction.rejected,
  payload<Error>()
);

export const getExtraInfo = action(
  getExtraInfoAction.requested,
  payload<string>()
);

export const getExtraInfoSuccess = action(
  getExtraInfoAction.fulfilled,
  payload<IAddress>()
);
export const getExtraInfoFailure = action(
  getExtraInfoAction.rejected,
  payload<Error>()
);
