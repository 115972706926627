import React, { useEffect, useMemo, useState } from "react";
import { Modal, DatePicker, Alert, Button } from "antd";
import styles from "./styles.module.sass";
import DownloadIcon from "../../../../../../assets/download-icon.svg";
import WarningIcon from "../../../../../../assets/warning-icon-file-upload.svg";
import images from "../../../../../../assets/images/images";
import CloseIcon from "../../../../../../assets/icon-close.svg";
import moment from "moment";
import { tz } from "moment-timezone";
import { useSelect } from "../../../../../../hooks/useSelect";
import { FileUploader } from "react-drag-drop-files";
import { ForumRounded } from "@material-ui/icons";

interface IProps {
  isVisible: boolean;
  onOk?: () => any;
  onCancel?: () => any;
  uploadFile?: (file: any) => void;
  ExtraBody?: React.ComponentType<{ resetFile: () => void; }>;
  showFileUploader?: boolean;
}

interface ClosableModalProps {
  onOk?: () => void;
  onCancel?: () => void;
  isVisible: boolean;
}

const ClosableModal: React.FC<ClosableModalProps> = (props) => {
  const {
    children,
    isVisible,
    onOk,
    onCancel,
  } = props;
  return (
    <Modal
      visible={isVisible}
      onOk={() => {
        onOk && onOk();
      }}
      footer={null}
      width={552}
      centered={true}
      closable={false}
      destroyOnClose={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.closeButtonWrapper}>
          <button className={styles.closeButton} onClick={() => {
            onCancel && onCancel()
          }}>
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ height: "12px", width: "12px" }}
            />
          </button>
        </div>
      </div>
      {children}
    </Modal>
  )
}

const GenericExportCSVModal: React.FC<IProps> = (props) => {
  const {
    isVisible,
    onCancel,
    onOk,
    children,
    uploadFile,
    ExtraBody,
    showFileUploader = true,
  } = props;
  const fileTypes = ["CSV"];
  const { uploadBulkCustomerPoints: { status } } = useSelect(state => state.getCustomerInfoReducer)
  const [file, setFile] = useState<any>(null);
  const handleChange = (value: any) => {
    setFile(value)
  }
  const isValidFileType = useMemo(() => {

    const type = file?.type ? file?.type.split("/").reverse()[0].toUpperCase() : "unknown"
    return file && !fileTypes.includes(type)
  }, [file])
  useEffect(() => {

    if (file) {
      const formPayload = new FormData();
      formPayload.append("data", file);
      uploadFile && uploadFile(formPayload);
    }
  }, [file])

  const fileInfoCard = useMemo(() => {
    return (
      file?.name && status !== "error" && (
        <div className={styles.okButtonContainer}>
          <button
            className={styles.okButtonStyles}
            onClick={() => {
              onCancel && onCancel();
              setFile(null);
            }}
          >
            <h2 className={styles.okButtonText}>Ok</h2>
          </button>
        </div>

      )
    );
  }, [file, status]);

  if (!showFileUploader) {
    return (
      <ClosableModal
        onOk={onOk}
        isVisible={isVisible}
        onCancel={() => {
          onCancel && onCancel();
          setFile(null);
        }}
      >
        {!!ExtraBody && <ExtraBody resetFile={() => setFile(null)} />}
      </ClosableModal>
    );
  }

  return (
    <ClosableModal
      onOk={onOk}
      isVisible={isVisible}
      onCancel={() => {
        onCancel && onCancel();
        setFile(null);
      }}
    >
      <FileUploader
        fileOrFiles={file} handleChange={handleChange} name="file" types={fileTypes}>
        {
          !isValidFileType ?
            <React.Fragment>
              {
                status === "idle" || !file ?
                  <div className={styles.wrapper}>
                    <div className={styles.iconWrapper}>
                      <img
                        src={DownloadIcon}
                        alt="download-icon"
                        className={styles.downloadIcon}
                      />
                    </div>
                    <h3 className={styles.modalTitle}>Add CSV</h3>
                    <p className={styles.modalSubtitle}>
                      Drag and Drop file
                    </p>
                  </div> : (
                    <div className={styles.wrapper}>
                      <div className={styles.csvWrapper}>
                        <div className={styles.csvContainer}>
                          {status === "loading" ? (
                            <img src={images.refreshLGIcon.default} />
                          ) : status === "success" ? (
                            <img src={images.successfullySendIcon} />
                          ) : status === "error" ? <img
                            src={images.uploadError}
                            alt="warning-icon"
                          /> : <></>}
                        </div>
                        <div className={styles.uploadTitleContainer}>
                          {status === "loading" ? (
                            <p className={styles.header} style={{ marginBottom: "0px", textAlign: "center", width: "100%" }}>
                              We&apos;re Processing file data...
                            </p>
                          ) : status === "success" ? (
                            <>
                              <h1 style={{ marginTop: 16, marginBottom: 16, fontWeight: 700, color: '#1C1C1C' }}>
                                Awesome!
                              </h1>
                              <h2 style={{ color: '#65656C', fontSize: 20 }}>Points have been sent to the users successfully</h2>
                            </>

                          ) : status === "error" ?

                            <>
                              <p className={styles.header} style={{ margin: "10px 0px 0px" }}>
                                Error
                              </p>
                              <p style={{ margin: "10px 0px 0px" }}>
                                Wrong file type or does not follow the template
                              </p>
                            </>
                            : <></>}
                          {status === "loading" ? (
                            <p className={styles.files_count}>
                              Don&apos;t close this window until processing finishes
                            </p>
                          ) : <></>}
                        </div>
                      </div>
                    </div>
                  )
              }


            </React.Fragment>
            : <div className={styles.wrapper}>
              <div className={styles.iconWrapper}>
                <img
                  src={WarningIcon}
                  alt="warning-icon"
                />
              </div>
              <h3 className={styles.modalTitle}>Error</h3>
              <p className={styles.modalSubtitle}>
                Wrong file type or does not follow the template</p>
            </div>
        }

      </FileUploader>
      {fileInfoCard}

      {
        ["error", "idle"].includes(status) && <div className={styles.separator}></div>
      }


      {file ? ["error"].includes(status) ? <div className={styles.buttonContainer}>
        <button onClick={() => {
          setFile(null);
          onCancel && onCancel();

        }} type="button" className={styles.cancel}>
          Cancel
        </button>
        <button onClick={() => {
          setFile(null);
        }} type="button" className={styles.reset}>
          Reset
        </button>

      </div> : <></> : children}
      {!!ExtraBody && <ExtraBody resetFile={() => setFile(null)} />}
    </ClosableModal>
  );
};

export default GenericExportCSVModal;
