import axios from "./axios";
import * as Requests from "./requests";
import { OrderingPortalStatus } from "./getOrderingWebPortalStatus";

export const editOrderingPortalApi = (
  token: string,
  web_portal_status: OrderingPortalStatus
) => {
  return axios.put(
    Requests.orderingWebPortalStatus,
    { web_portal_status },
    {
      headers: { token },
    }
  );
};
