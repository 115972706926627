import axios from "../../../axios";
import * as routes from "../../../api-routes/Manager/Analytics";
import { exportCustomerTransactionsCSVParams } from "./types";

export const getTransactionInsightsAPI = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTransactionInsights}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};

export const getTransactionInsightsTotalAPI = (token, queryParams) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getTransactionInsightsTotal}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};

export const exportCustomerTransactionsCSV = (
  token,
  queryParams: exportCustomerTransactionsCSVParams
) => {
  return axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.exportCustomerTransactionsCSV}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
};
