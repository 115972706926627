import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getSuggestedModifiersGroupsAction } from "../../constants/actions";
import {
  getSuggestedModifiersGroupsSuccess,
  getSuggestedModifiersGroupsFailure,
} from "../actions/modifierGroupsAction";
import { getSuggestedModifierGroups } from "../../axios/getSuggestedModifiersGroups";

function* getSuggestedModifiersGroupsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getSuggestedModifierGroups, token);

    yield put(getSuggestedModifiersGroupsSuccess(res.data.modifiers_groups));
  } catch (error) {
    yield put(getSuggestedModifiersGroupsFailure(error));
  }
}

export function* watchGetSuggestedModifiersGroupsSaga() {
  yield takeLatest(
    getSuggestedModifiersGroupsAction.requested,
    getSuggestedModifiersGroupsSaga
  );
}
