export default {
  posSendCustomGift: "هذا الاختيار غير متاح لهذه العلامه التجارية",
  orderId: "كود الطلب: {{order_id}}",
  transaction_id: "كود المعاملة: {{transaction_id}}",
  allTime: "الوقت الكلي",
  today: "اليوم",
  thisWeek: "هذا الأسبوع",
  thisMonth: "هذا الشهر",
  allBranches: "كل الفروع",
  searchReviewsPlaceholder: "البحث عن تقييم...",
  issue: "شكوى",
  solved: "تم الحل",
  unsolved: "لم تحل",
  resolved: "تم الحل",
  reopened: "تم اعادة الفتح",
  noResponse: "لم يتم الرد",
  reviewTabTitle: "التقييمات",
  reviewsStatisticsTabTitle: "التحليلات",
  reviewsStatisticsSubTitle: "نقوم حالياً بمعالجة بياناتكم",
  reviewsStatisticsContent: `سيتم تنبيهكم عندما تكون جاهزة!`,
  noIssuesCardTitle: "كل شيء على ما يرام 👌",
  noIssuesCardSubTitle: "كل عملائك سعداء الآن",
  issuesCardTitle: "عملاء غير راضيين",
  issuesCardSubTitle: "عرض الشكاوى",
  compensationQuestion: "هل تريد إرسال",
  to: "إلى",
  asACompensation: "كتعويض عن الشكوى",
  points: "نقاط",
  points_1: "نقطة واحدة",
  points_2: "نقطتان",
  points_3: "{{count}} نقاط",
  points_4: "{{count}} نقطة",
  points_5: "{{count}} نقطة",
  cancelCompensation: "إلغاء",
  sendCompensation: "إرسال النقاط",
  emoji1: "سيء جداً",
  emoji2: "سيء",
  emoji3: "معقول",
  emoji4: "جيد",
  emoji5: "رائع",
  overallRatingTitle: "مجموع التقييمات",
  visitsCount: "{{count}} زيارة",
  visitsCount_1: "زيارة واحدة",
  visitsCount_2: "زياراتان",
  visitsCount_3: "{{count}} زيارات",
  visitsCount_4: "{{count}} زيارة",
  expandReview: " اقرأ المزيد ",
  collapseReview: " أقل ",
  solveIssue: "حل الشكوى",
  viewIssueDetails: "عرض الشكوى",
  viewProfile: "عرض صفحة العميل",
  nextIssue: "الشكوى التالية",
  previousIssue: "الشكوى السابقة",
  nextIssueArrow: "arrow_back",
  previousIssueArrow: "arrow_forward",
  issuesRemaining: "تبقى {{count}} شكوى",
  issuesRemaining_1: "شكوى واحدة فقط متبقية",
  issuesRemaining_2: "تبقى شكوتان فقط",
  issuesRemaining_3: "تبقى {{count}} شكاوى",
  issuesRemaining_4: "تبقى {{count}} شكوى",
  userCaringPart1: "الاتصال",
  userCaringPart2: "بالعميل",
  callCustomerPart1:
    "قم بالاتصال  ب{{name}}، لتعرف المزيد عن مشكلتهم واكتب ما حدث بالأسفل",
  callCustomerPart2: "",
  customerResponseQuestion: "هل قام العميل بالرد على اتصالك؟",
  yes: "نعم",
  no: "لا",
  writeNoteTitle: "اكتب ملاحظاتك",
  writeNotePlaceholder: "كيف قمت بحل شكوى العميل؟",
  writeNoteSubmit: "إضافة الملاحظة",
  markResolved: "اضغط هنا إذا تم الحل",
  notesTitle: "آخر الملاحظات",
  compensationsTitle: "سجل التعويضات",
  userCompensationPart1: "قم بتعويض",
  userCompensationPart2: "العميل",
  optional: "اختياري",
  userCompensationSubtitle:
    "🎁 هل تريد أن ترسل نقاط مجانية إلى {{customerName}}؟",
  nextGiftTitle: "هدية العميل التالية",
  giftPrice: "سعر الهدية",
  openCompensationModal: "أرسل",
  endOfReviews: "لا توجد تقيمات.",
  noteDateTitle: "بتاريخ",
  filteredReviewsCount: "عدد نتائج التقييمات",
  filteredIssuesCount: "عدد نتائج الشكاوى",
  unFilteredReviewsCount: "عدد التقييمات",
  unFilteredIssuesCount: "عدد الشكاوى",
  allReviewsTabTitle: "جميع التقييمات",
  categoryFilterTitle: "الوصف الأساسي",
  successCompensationAlertPriceToPoints: "{{price}} أو {{points}} نقاط إلى",
  successCompensationAlertPriceToPoints_1: "{{price}} أو نقطة واحدة إلى",
  successCompensationAlertPriceToPoints_2: "{{price}} أو نقطتين إلى",
  successCompensationAlertPriceToPoints_3: "{{price}} أو {{points}} نقاط إلى",
  successCompensationAlertPriceToPoints_4: "{{price}} أو {{points}} نقطة إلى",
  successCompensationAlertWasSentTo: "تم ارسال",
  pointsBefore: "نقطة قبل",
  pointsAfter: "نقطة بعد",
  compensationWasSent: "تم إرسال",
  markNoResponse: "حفظ حالة عدم الرد؟",
  noteArrow: "arrow_left",
  markUnResolved: "فتح الشكوى مجدداً؟",
  showTagsFilters: "عرض التصفية بالعلامات",
  hideTagsFilters: "إخفاء التصفية بالعلامات",
  badTagsTitle: "سلبية",
  goodTagsTitle: "إيجابية",
  allCategoriesFilter: "كل العلامات",
  solveIssueFeedbackAlertCustomerName: "{{customer}}",
  solveIssueFeedbackAlertMessage: "تم حل شكوى",
  emptyNotesTitle: "لم يتم إضافة أي ملاحظة بعد",
  emptyNotesAddNote: "إضافة ملاحظة",
  emptyCompensations: "لم يتم إرسال أي نقاط تعويضية بعد",
  noteAddedFeedbackAlertMessage: "تم إضافة الملاحظة بنجاح",
  markUnResolvedSuccessAlert: "تم تغيير حالة الشكوى",
  markNoResponseSuccessAlert:
    "تم حفظ تعديلاتك، قم بالعودة مجدداً للاطمئنان على شكوى {{customer}}",
  allIssues: "كل الشكاوى",
  customerCurrentPoints: "نقاط العميل الحالية",
  errorCompensationBlockedUser:
    "العميل الذي تحاول تعويضه قد تم حظره من هذا المتجر",
  issueStatusTitle: "حالة الشكوى:",
  compensationsCount: "تم تعويض العميل {{count}} مرة",
  compensationsCount_1: "تم تعويض العميل مرة واحدة",
  compensationsCount_2: "تم تعويض العميل مرتين",
  compensationsCount_3: "تم تعويض العميل {{count}} مرات",
  compensationsCount_4: "تم تعويض العميل {{count}} مرة",
  notesCount: "تم إضافة {{count}} ملاحظة",
  notesCount_1: "تم إضافة ملاحظة واحدة",
  notesCount_2: "تم إضافة ملاحظتين",
  notesCount_3: "تم إضافة {{count}} ملاحظات",
  notesCount_4: "تم إضافة {{count}} ملاحظة",
  customerReceivedAllTimePart1: "لقد استلم هذا العميل",
  customerReceivedAllTimePart2: "{{count}} هدية",
  customerReceivedAllTimePart2_1: "هدية واحدة",
  customerReceivedAllTimePart2_2: "هديتين",
  customerReceivedAllTimePart2_3: "{{count}} هدايا",
  customerReceivedAllTimePart2_4: "{{count}} هدية",
  customerReceivedAllTimePart3: "في كل",
  customerReceivedAllTimePart4: "الشكاوى السابقة",
  userActivityNote: "أضاف ملاحظة",
  userActivityCompensate: "قام بتعويض العميل بـ",
  userActivityChangeStatus: "غير حالة الشكوى إلى",
  compensationPanelTitle: "أرسل تعويضاً",
  compensationPanelSubTitlePart1: "يمكنك الاختيار بين ارسال نقاط",
  compensationPanelSubTitlePart2: "أو منتج أو هدية مخصصة.",
  recentCompensationsTitle: "آخر التعويضات",
  sendShopXPointsCompensationTitle: "إرسال نقاط Koinz",
  sendShopXPointsCompensationSubTitlePart1: "قم بإرسال قيمة محددة من",
  sendShopXPointsCompensationSubTitlePart2: "النقاط لعملائك",
  sendCustomGiftCompensationTitle: "إرسال هدية مخصصة",
  sendCustomGiftCompensationSubTitlePart1:
    "،قم بإرسال منتج من القائمة، أو خصماً",
  sendCustomGiftCompensationSubTitlePart2: "أو قسيمة شراء",
  sendSelectedMenuItemButtonText: "إرسال الهدية المختارة",
  itemIsSelected: "مختارة!",
  pointsCompensationPlaceholder: "أدخل قيمة النقاط",
  pointsCompensationValue: "قيمة هذه النقاط = ",
  failedToLoadActivities: "فشل تحميل سجل النشاطات، برجاء المحاولة مرةَ أخرى.",
  emptyActivityLog:
    "لم يتم القيام بأي نشاطات على هذه الشكوى، قم بالتواصل، وأضف ملاحظاتك.",
  activityLogTitle: "آخر النشاطات",
  // Gift Popup
  selectedGiftTitle: "الهدية المختارة",
  messageInArabic: "الرسالة باللغة العربية",
  messageInEnglish: "الرسالة باللغة الانجليزية",
  messageTitle: "اكتب رسالة مع الهدية",
  messageSubTitle: "*سيتم إرسال هذه الرسالة مع الهدية كخطاب في تطبيق Koinz.",
  compensationOptionsTitle: "إرسال تعويض جديد",

  somethingWentWrong: "حدث خطأٌ ما، رجاءاً قم باعادة المحاولة.",
  customGiftImageTitle: "صورة الهدية",
  customGiftNameTitle: "اسم الهدية",

  sendGiftButtonTitle: "أرسل الهدية",

  sendVoucherTabTitle: "قسيمة أو خصم",
  voucherNamePlaceholder: "مثال: خصم 50% على وجبة...",
  next: "التالي",
  sendMenuItemTabTitle: "منتج من القائمة",

  sendMessage: "اكتب رسالة",
  messagePlaceholder: "اكتب رسالتك هنا",

  expandActivities: "رؤية المزيد ({{count}})",

  messageField: "حقل الرسالة",

  compensationSuccessModalTitle: "تم ارسال التعويض بنجاح!",
  compensationSuccessModalSubTitle:
    "سيظهر التعويض في صندوق الهدايا الخاص بعميلك.",
  okay: "انهاء",
  unlockedUserStaticText: "استخدمت كل الهدايا",
  "Contact through WhatsApp": "اتصل من خلال الواتساب"
};
