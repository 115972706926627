import { Moment } from "moment";
import moment from "moment";
import { LAUNCH_DATE } from "../constants";
import { localizedMoment } from "../Utils";

export enum LeaderBoardStatuses {
  BeforeLaunch = 0,
  Missed = 1,
  Completed = 2,
  CurrentRunning = 3,
  NextAvailable = 4,
  NextBooked = 5,
  NextUnAvailable = 6,
}

const getLeaderBoardStatus = (
  competitionStartDate: Moment,
  id?: string
): LeaderBoardStatuses => {
  const isBeforeLaunch = competitionStartDate.isBefore(LAUNCH_DATE);
  if (isBeforeLaunch) {
    return LeaderBoardStatuses.BeforeLaunch;
  }
  const today = moment();
  // const currentMonthIsLast = moment().month() === 11;
  const isInSystem = Boolean(id);
  const dayInMiddleOfMonth = moment(competitionStartDate)
    .startOf("month")
    .add(Math.floor(competitionStartDate.daysInMonth() / 2), "day");
  const competitionEndDate = moment(competitionStartDate).endOf("month");
  if (!isInSystem) {
    const isMissed = today.isAfter(dayInMiddleOfMonth);
    if (isMissed) {
      return LeaderBoardStatuses.Missed;
    }

    // const leaderBoardIsFirstMonth = dayInMiddleOfMonth.month() === 0;
    // is today before the day in the middle of the leader-board's month?
    const diff = today.isBefore(dayInMiddleOfMonth);
    // is competitionStartDate in the radius between this month and the month after it
    // it must not be after the next month
    const isAfterNextMonth = competitionStartDate.isAfter(
      moment().startOf("month").add(1, "month")
    );

    const isNextAvailable = diff && !isAfterNextMonth;
    // ||
    // (currentMonthIsLast && leaderBoardIsFirstMonth);
    if (isNextAvailable) {
      return LeaderBoardStatuses.NextAvailable;
    } else {
      return LeaderBoardStatuses.NextUnAvailable;
    }
  } else {
    const hasCompleted = today.isAfter(competitionEndDate);
    if (hasCompleted) {
      return LeaderBoardStatuses.Completed;
    }
    const isRunning = today.isBetween(competitionStartDate, competitionEndDate);
    if (isRunning) {
      return LeaderBoardStatuses.CurrentRunning;
    }
    const isNextBooked = today.isBefore(competitionStartDate);
    if (isNextBooked) {
      return LeaderBoardStatuses.NextBooked;
    }
  }
  return LeaderBoardStatuses.BeforeLaunch;
};

interface ILeaderBoardResponse {
  start_time: string;
  end_time?: string;
  id?: string;
}

export class LeaderBoardItem {
  public date: Moment;
  public status: LeaderBoardStatuses;
  public name: string;
  public id: string | undefined;

  constructor({ start_time, id }: ILeaderBoardResponse) {
    this.date = moment(start_time, "MM YYYY");
    this.status = getLeaderBoardStatus(this.date, id);
    this.name = localizedMoment(this.date).format("MMM");
    this.id = id;
  }
}
