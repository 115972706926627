import axios, { AxiosError, AxiosResponse } from "axios";
import * as Requests from "./requests";
import { notify } from "react-notify-toast";
import { translateLeaderboard } from "../i18n/i18n";
import Strings from "../i18n/strings/leaderboard";

export const escapeQuotes = (str: string): string =>
  str.replace(/(")|(')/g, `\\$&`);
export const escapeBackslashes = (str: string): string =>
  str.replace(/\\\'/g, `'$&`);
const processNestedProp = (value) => {
  if (typeof value === "string") {
    return escapeBackslashes(value) || escapeQuotes(value);
  }
  if (value instanceof Object) {
    return escapeAllStrings(value);
  }
  return value;
};
export const escapeAllStrings = (obj: { [x: string]: any }) => {
  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key]: processNestedProp(obj[key]),
    };
  }, {});
};

export const goToLogin = () => {
  const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
  const storeId = localStorage.getItem("storeId");
  if (isKoinzAdminRemote) {
    window.location.href = `${process.env.REACT_APP_ADMIN_APP_URL}/${storeId}/statistics`;
  } else {
    window.location.href = "/";
  }
};

export const handleSuccess = (
  res: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => res;
export const handleError = (error: AxiosError) => {
  const res = error.response as AxiosResponse;
  if (
    res &&
    res.status === 401 &&
    !res.config.url?.includes(Requests.confirmPassword) &&
    !res.config.url?.includes(Requests.login)
  ) {
    localStorage.removeItem("persist:auth");
    localStorage.removeItem("authData");
    localStorage.removeItem("isRemoteAuthentication");
    localStorage.removeItem("isKoinzAdminRemote");
    localStorage.removeItem("storeId");
    localStorage.removeItem("using_universal_password");
    localStorage.removeItem("token");
    goToLogin();
  } else if (!res || !res.status) {
    notify.show(translateLeaderboard(Strings.errorInternetConnection), "error");
  }
  throw error;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(handleSuccess, handleError);
// koinz admin can open the dashboard, so we inject the store_id
instance.interceptors.request.use((req) => {
  const isKoinzAdminRemote = localStorage.getItem("isKoinzAdminRemote");
  const token = localStorage.getItem("token");

  if (!req.headers.token) {
    req.headers.token = token;
  }

  if (isKoinzAdminRemote) {
    const storeId = localStorage.getItem("storeId");
    if (req.method === "GET" || req.method === "get") {
      if (req.url?.includes("?")) {
        req.url = `${req.url}&store_id=${storeId}`;
      } else {
        req.url = `${req.url}?store_id=${storeId}`;
      }
    } else {
      req.data = {
        ...req.data,
        store_id: storeId,
      };
    }
  }

  return {
    ...req,
    data: req.data && escapeAllStrings(req.data),
  };
});
export default instance;
//
//
// const fromPromise = (fn) => {
//   return function promisifier<T>(...args): AxiosReturn<T> {
//     const promise: AxiosPromise<T> = fn(...args);
//     const observable = new Observable<AxiosResponse<T>>(observer => {
//       promise
//         .then(res => {
//           observer.next(res);
//           return res;
//         })
//         .catch(err => {
//           observer.error(err);
//           return err;
//         });
//     });
//     return {
//       then: (cb) => promise.then(cb),
//       catch: (cb) => promise.catch(cb),
//       finally: (cb) => promise.finally(cb),
//       [Symbol.toStringTag]: promise[Symbol.toStringTag],
//       observable,
//     }
//   };
// };
//
// const put = fromPromise(instance.put);
// const get = fromPromise(instance.get);
// const post = fromPromise(instance.post);
// const delete1 = fromPromise(instance.delete);

// export default {
//   put,
//   get,
//   post,
//   delete: delete1,
// };
