import React from "react"
import styles from  './styles.module.sass'
import { GenericButton } from "../GenericButton"
import { uDIC } from "../../lib/useDefaultAndInsertedClassName"

export const FilledButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <GenericButton {...props} className={uDIC(styles.filledButtonDefaults, props.className)} />
  )
}
