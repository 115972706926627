export default {
  enterPersonalInfo: "قم بإدخال بياناتك الشخصية",
  namePlaceholder: "الاسم",
  jobPlaceholder: "المسمى الوظيفي",
  passwordPlaceholder: "كلمة المرور",
  signUp: "التسجيل",
  registerSuccess: "تم التسجيل بنجاح",
  owner: "المالك",
  it_manager: "مدير تكنولوجيا المعلومات",
  marketing_manager: "مدير التسويق",
  operations_manager: "مدير التشغيل",
  other: "آخر",
};
