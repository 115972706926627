import { monthsNames } from "../../Screens/Manager/Analytics/Home/consts";
import moment from "moment";

export const getLabelsOfPastNWeeks = (noOfWeeks: number) => {
  const labels: string[] = [];

  for (let index = 0; index < noOfWeeks; index++) {
    const date = new Date();
    date.setDate(date.getDate() - index * 7);

    const noOfTheDay = date.getDate();
    const nameOfTheMnth = date.toLocaleDateString(undefined, {
      month: "short",
    });

    labels.unshift(`${nameOfTheMnth} ${noOfTheDay}`);
  }

  return labels;
};

export const getMonthsNamesAfter = (
  currentMonthIndex: number,
  noOfMnthsAfter: number,
  namesStrType: "upper" | "lower" | "normal"
): string[] => {
  const selectedMnthsArr = [...monthsNames[namesStrType]];

  const rightSection = selectedMnthsArr.slice(
    currentMonthIndex,
    noOfMnthsAfter > selectedMnthsArr.length - currentMonthIndex - 1
      ? selectedMnthsArr.length
      : noOfMnthsAfter
  );

  const leftSection = selectedMnthsArr.slice(
    0,
    noOfMnthsAfter - rightSection.length
  );

  return [...rightSection, ...leftSection];
};

export const getLabelsOfPastNDays = (noOfDays: number, noOfLabels: number) => {
  const labels: string[] = [];

  // return 12 labels of the past N days
  const ratio =
    Math.round(noOfDays / noOfLabels) < 1
      ? 1
      : Math.round(noOfDays / noOfLabels);

  for (let index = 0; index < noOfLabels; index++) {
    const now = new Date();
    now.setDate(now.getDate() - index * ratio);
    labels.unshift(
      `${now.toLocaleDateString(undefined, {
        month: "short",
      })} ${now.getDate()}`
    );
  }

  return labels;
};

export const getHoursLabels = (
  data: {
    date: string;
    new_customers_count: number;
    total_customers_count: number;
  }[]
) => {
  return data.map((element) => {
    const date = new Date(element.date);
    const hourLabel = moment(date.getTime()).format("hh:mm");
    return hourLabel;
  });
};

export const getExactPointsLabels = (
  data: {
    date: string;
    new_customers_count: number;
    total_customers_count: number;
  }[]
) => {
  return data.map((element) => {
    const formattedDate = Date.parse(element.date);
    const monthName = new Intl.DateTimeFormat("en", {
      month: "short",
    }).format(formattedDate);
    const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      formattedDate
    );

    return `${monthName} ${day}`;
  });
};

export const getMonthsLabelsInRange = (startDate: string, endDate: string) => {
  const startMonthIndex = new Date(startDate).getMonth();
  const endMonthIndex = new Date(endDate).getMonth();

  const noOfMonthsInRange = Math.abs(startMonthIndex - endMonthIndex);

  const monthsLabels: string[] = [];

  for (let i = 0; i <= noOfMonthsInRange; i++) {
    monthsLabels.push(monthsNames.normal[(startMonthIndex + i) % 12]);
  }

  return monthsLabels;
};

export const getYearsLabelsInRange = (startDate: string, endDate: string) => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();

  const noOfYearsInRange = Math.abs(startYear - endYear);

  const yearsLabels: string[] = [];

  for (let i = 0; i <= noOfYearsInRange; i++) {
    yearsLabels.push(`${startYear + i}`);
  }

  return yearsLabels;
};
