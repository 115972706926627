import * as actions from "../../constants/actions";
import { payload, action } from "ts-action";
import { IGeographicItem } from "../../../WizardComponents/Branches/AddBranchForm/lib";
import { IDataViolation } from "./lib";
export const getZones = action(
  actions.getZonesAction.requested,
  payload<{ area_id: string }>()
);
export const getZonesSuccess = action(
  actions.getZonesAction.fulfilled,
  payload<IGeographicItem[]>()
);
export const getZonesFailure = action(
  actions.getZonesAction.rejected,
  payload<Error>()
);
export const searchForZones = action(
  actions.ACTION_SEARCH_FOR_ZONES,
  payload<string>()
);

// verify zone csv actions
export const verifyZoneCSV = action(
  actions.verifyZoneCSVAction.requested,
  payload<{
    excel_sheet: FormData;
    fileName: string;
  }>()
);
export const verifyZoneCSVSuccess = action(
  actions.verifyMenuCSVAction.fulfilled,
  payload<{ zone_select: FormData; submitting: boolean }>()
);
export const verifyZoneCSVFailure = action(
  actions.verifyMenuCSVAction.rejected,
  payload<{ data_violations: IDataViolation[]; submitting: boolean }>()
);

// upload zone csv actions
export const uploadZoneCSV = action(
  actions.uploadZoneCSVAction.requested,
  payload<FormData>()
);
export const uploadZoneCSVSuccess = action(
  actions.uploadZoneCSVAction.fulfilled,
  payload<FormData>()
);
export const uploadZoneCSVFailure = action(
  actions.uploadZoneCSVAction.rejected,
  payload<Error>()
);
