import { reducer, on } from "ts-action";
import {
  withLoadingReducer,
  ILoadingState,
  LoadingStatus,
} from "./withLoadingState";
import { getSuggestedModifiersGroupsAction } from "../../constants/actions";
import {
  getSuggestedModifiersGroupsSuccess,
  createOrderingSuggestedModifiersGroup,
  createOrderingSuggestedModifiersGroupSuccess,
  deleteOrderingSuggestedModifiersGroup,
  editOrderingSuggestedModifiersGroup,
  editOrderingSuggestedModifiersGroupSuccess,
  deleteOrderingSuggestedModifiersGroupSuccess,
} from "../actions/modifierGroupsAction";
import { IModifiersGroup } from "../../lib";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { clearPrevState } from "../../../../redux-store/actions";

interface IState {
  modifierGroup: string[];
  loading: LoadingStatus;
  modifierGroupById: { [x: string]: IModifiersGroup };
}
const initialState: IState = {
  modifierGroup: [],
  modifierGroupById: {},
  loading: LoadingStatus.loading,
};

export const suggestedModifierGroupsReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(clearPrevState, (state: IState, {}) => ({
        ...state,
        loading: LoadingStatus.loading,
      })),
      on(getSuggestedModifiersGroupsSuccess, (state: IState, { payload }) => ({
        ...state,
        modifierGroup: payload.map((m) => m.id),
        modifierGroupById: payload.reduce(
          (acc, modifier) => ({
            ...acc,
            [modifier.id]: modifier,
          }),
          {}
        ),
      })),
      on(
        createOrderingSuggestedModifiersGroup,
        (
          state: IState,
          {
            payload,
            meta: {
              optimistic: { id },
            },
          }
        ) => {
          return {
            ...state,
            modifierGroup: [...state.modifierGroup, id.toString()],
            modifierGroupById: {
              ...state.modifierGroupById,
              [id]: payload,
            },
            loading: LoadingStatus.loading,
          };
        }
      ),
      on(
        createOrderingSuggestedModifiersGroupSuccess,
        (state: IState, { payload, meta: { optimistic: opt } }) => {
          return {
            ...state,
            modifierGroup: state.modifierGroup.map((id) =>
              id === String(opt.id) ? payload.id : id
            ),
            modifierGroupById: {
              ...state.modifierGroupById,
              [payload.id]: payload,
            },
            loading: LoadingStatus.success,
          };
        }
      ),
      on(deleteOrderingSuggestedModifiersGroupSuccess, (state: IState, {}) => ({
        ...state,
        loading: LoadingStatus.success,
      })),
      on(
        deleteOrderingSuggestedModifiersGroup,
        (state: IState, { payload }) => {
          return {
            ...state,
            modifierGroup: state.modifierGroup.filter((mod) => mod !== payload),
          };
        }
      ),
      on(editOrderingSuggestedModifiersGroupSuccess, (state: IState, {}) => ({
        ...state,
        loading: LoadingStatus.success,
      })),
      on(editOrderingSuggestedModifiersGroup, (state: IState, { payload }) => {
        return {
          ...state,
          modifierGroupById: {
            ...state.modifierGroupById,
            [payload.id]: payload,
          },
          loading: LoadingStatus.loading,
        };
      }),
    ] as any,
    initialState
  ),
  getSuggestedModifiersGroupsAction
);
