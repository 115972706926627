import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.sass";
import GlobeIcon from "../../../../assets/feather _ globe.svg";
import UpDownArrowIcon from "../../../../assets/feather _ chevron-double.svg";
import i18n from "../../../../i18n/i18n";
import { useRtlClass } from "../../../../shopx-shared-components/hooks/useRtlClassName";
import { useHistory } from "react-router";

interface IProps {
  isLanguageDropDownOpen: boolean;
  setIsLanguageDropDownOpen: (val: any) => void;
}

const LanguageDropDown: React.FC<IProps> = ({
  isLanguageDropDownOpen,
  setIsLanguageDropDownOpen,
}) => {
  const history = useHistory();

  const handleHeaderClick = () =>
    setIsLanguageDropDownOpen((prevValue) => !prevValue);
  const languages = {
    ar: "العربية",
    en: "English",
    "en-US": "English",
  };
  const onChangeLanguage = (lng) => {
    setSelectedLang(lng);
    setIsLanguageDropDownOpen(false);
    i18n.changeLanguage(lng);
    history.go(0);
  };

  const [selectedLang, setSelectedLang] = useState(i18n.language);

  return (
    <div
      className={`${styles.wrapper} ${useRtlClass(styles)}`}
      id="drop-down-container"
    >
      <button
        className={`${styles.dropDownHeader} ${
          isLanguageDropDownOpen ? styles.dropDownHeaderFocus : ""
        }`}
        onClick={handleHeaderClick}
        role="button"
      >
        <img className={styles.globeIcon} src={GlobeIcon} alt="world-icon" />
        {languages[selectedLang]}
        <img
          className={styles.upDownArrowIcon}
          src={UpDownArrowIcon}
          alt="up-down-arrow-icon"
        />
      </button>
      {isLanguageDropDownOpen && (
        <div className={styles.dropDownList}>
          <button
            className={styles.listItem}
            value="ar"
            onClick={() => onChangeLanguage("ar")}
          >
            العربية
          </button>
          <button
            className={styles.listItem}
            value="en"
            onClick={() => onChangeLanguage("en")}
          >
            English
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageDropDown;
