import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { setMinOrderValueAction } from "../../constants/actions";
import {
  setMinValueSuccess,
  setMinValueFailure,
} from "../actions/min-order-value";
import { setMinOrderingValue } from "../../axios/setMinOrderingValue";

function* setMinOrderValueSaga(action) {
  try {
    const token = yield select(selectToken);
    const res = yield call(setMinOrderingValue, token, action.payload);

    yield put(setMinValueSuccess(res.data));
  } catch (error) {
    yield put(setMinValueFailure(error));
  }
}

export function* watchSetMinOrderValueSaga() {
  yield takeLatest(setMinOrderValueAction.requested, setMinOrderValueSaga);
}
