import * as React from "react";
import styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import "react-datetime/css/react-datetime.css";
import Dropzone from "react-dropzone";
import { translate, InjectedTranslateProps } from "react-i18next";
import AvatarEditor from "react-avatar-editor";
import { convertDataURLToFile } from "../../Utils";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Modal from "react-responsive-modal";
import Strings from "../../i18n/strings/formElements";
import { useRtlClass } from "../../lib";
import CropIcon from "../../assets/icon-crop.svg";
import ImagePlaceholder from "../../assets/image-placeholder.svg";

interface IRenderPhotoPickerProps extends InjectedTranslateProps {
  onPick: () => any;
  label: string;
  isCroppable: boolean;
  description: string;
  buttonText: string;
  cropTitle?: string;
  unsupportedFileTypeText: string;
  customLabel: boolean;
  className?: string;
  focusBtn: boolean;
  placeHolderImage?: string;
}

interface IState {
  isOpen: boolean;
  scale: number;
  files: File[];
}

const RenderPhotoPickerClass: React.FC<
  WrappedFieldProps & IRenderPhotoPickerProps
> = (props) => {
  const editorRef = React.useRef<null | any>(null);
  const {
    buttonText,
    cropTitle,
    customLabel,
    t,
    placeHolderImage,
    focusBtn,
    input,
    isCroppable,
    label,
    meta: { error, touched, warning },
    onPick,
    unsupportedFileTypeText,
    description,
    className,
  } = props;

  const [state, setState] = React.useState<IState>({
    isOpen: false,
    scale: 1,
    files: [],
  });

  const handleCancel = () => setState({ ...state, isOpen: false, files: [] });

  const handleChange = (data) => {
    if (isCroppable) {
      setState({ ...state, files: data, isOpen: true });
    } else {
      input.onChange(data);
      input.onBlur(data);
      onPick();
    }
  };
  const onClickSave = async () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current
        .getImageScaledToCanvas()
        .toDataURL();
      const canvasFile = await convertDataURLToFile(canvasScaled);
      handleCancel();
      input.onChange(canvasFile);
      input.onBlur(canvasFile);
      onPick();
    }
  };
  const onSliderChange = (scale: number) => {
    setState({ ...state, scale });
  };

  const baseStyle = {
    width: "8.5rem",
    height: "8.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    overflow: "hidden",
  };
  const activeStyle = {
    borderStyle: "solid",
    borderColor: "#6c6",
    backgroundColor: "#eee",
  };
  const rejectStyle = {
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee",
  };
  const thumb = {
    width: "8.5rem",
    height: "8.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const img = {
    display: "block",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  };
  const rtlClass = useRtlClass(styles);

  const thumbs = (input.value || []).map((file) => (
    <div style={thumb} key={file.name}>
      <img src={URL.createObjectURL(file)} style={img as any} alt="" />
    </div>
  ));
  return (
    <div className={styles.photoUploaderWrapper}>
      {isCroppable && (
        <Modal
          open={state.isOpen}
          onClose={handleCancel}
          showCloseIcon={true}
          classNames={{
            modal: styles.modal,
            overlay: styles.overlay,
          }}
          animationDuration={1000}
        >
          <p className={styles.cropHead}>{cropTitle || "Crop Brand Logo"}</p>
          <div
            style={{
              background: "#f6f6f6",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AvatarEditor
              ref={editorRef}
              image={state.files[0]}
              width={500}
              height={500}
              borderRadius={250}
              color={[255, 255, 255, 0.6]}
              scale={state.scale}
            />
          </div>
          <div className={styles.sliderWrapper}>
            <img className={styles.minScale} src={CropIcon} alt="" />
            <Slider
              className={styles.slider}
              railStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                height: "1rem",
                width: "100%",
                boxShadow: "inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)",
                borderRadius: "28px",
              }}
              handleStyle={{
                height: "2.6rem",
                width: "2.6rem",
                outline: "0",
                borderRadius: "50%",
                marginTop: "-.8rem",
                marginLeft: "-1.5rem",
                boxShadow:
                  "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.5)",
              }}
              value={state.scale}
              onChange={onSliderChange}
              min={1}
              step={0.1}
              max={2}
              trackStyle={{
                backgroundColor: "transparent",
              }}
              dotStyle={{
                opacity: 0,
                fontSize: "10rem",
              }}
              activeDotStyle={{
                opacity: 0,
              }}
            />
            <img className={styles.maxScale} src={CropIcon} alt="" />
          </div>
          <div className={`${styles.btnWrapper} ${rtlClass}`}>
            <button className={styles.btnCancel} onClick={handleCancel}>
              {t(Strings.cancel)}
            </button>
            <button className={styles.btnSave} onClick={onClickSave}>
              {t(Strings.cropSave)}
            </button>
          </div>
        </Modal>
      )}
      <label
        style={
          customLabel
            ? { fontWeight: 500, fontSize: "2.4rem", color: "black" }
            : {}
        }
        className={styles.label}
      >
        {label}
      </label>
      <div className={styles.uploadPart}>
        <Dropzone
          onClick={input.onBlur}
          multiple={false}
          accept="image/*"
          onDrop={handleChange}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            let styling: any = { ...baseStyle };
            styling = isDragActive ? { ...styles, ...activeStyle } : styling;
            styling = isDragReject ? { ...styles, ...rejectStyle } : styling;
            return (
              <>
                <div {...getRootProps()} style={styling}>
                  <input {...getInputProps()} />
                  {(input.value || []).length ? (
                    <>{thumbs}</>
                  ) : (
                    <div>
                      <img
                        src={
                          placeHolderImage ? placeHolderImage : ImagePlaceholder
                        }
                        alt=""
                        className={styles.img}
                      />
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      {unsupportedFileTypeText || "Unsupported file type..."}
                    </div>
                  )}
                </div>
                <div className={styles.uploadWrapper}>
                  <span className={styles.max}>
                    {description || "Image should be at least 4KB"}
                  </span>
                  <div
                    {...getRootProps()}
                    className={`${
                      focusBtn ? styles.uploadFBtn : styles.uploadBtn
                    } ${className || ""}`}
                  >
                    {buttonText || t(Strings.uploadImageButtonText)}
                  </div>
                  {touched &&
                    ((error && (
                      <span className={styles.formError}>{error}</span>
                    )) ||
                      (warning && <span>{warning}</span>))}
                </div>
              </>
            );
          }}
        </Dropzone>
      </div>
    </div>
  );
};

export const RenderPhotoPicker = translate("formElements")(
  RenderPhotoPickerClass
);
