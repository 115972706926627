// line graphs
export const getCustomersChart =
  "/api/manager/analytics/store/chart/customers-transactions";
export const getOrdersChart = "/api/manager/analytics/store/chart/orders";
export const getRedemptionsChart =
  "/api/manager/analytics/store/chart/redemptions";
export const getSalesChart = "/api/manager/analytics/store/chart/sales";
export const getOnlineOrdersChart =
  "/api/manager/analytics/store/chart/online-orders";
// customer segments
export const getCustomersSegmentOverview =
  "/api/manager/analytics/store/customers-segment-overview";
export const getCustomersSegmentChart =
  "/api/manager/analytics/store/customers-segment-age-chart";
export const getCustomersSegmentGenderChartURL =
  "/api/manager/analytics/store/customers-segment-gender-chart";
export const getTotalOrdersValue = (type: string) =>
  `/api/manager/analytics/store/chart/total-value/${type}`;
export const getTodaysTotalValue = (type) =>
  `/api/manager/analytics/store/chart/today-total-value/${type}`;
export const getLiveUpdatesHistory =
  "/api/manager/analytics/store/live-updates";
// customers visits
export const getCustomersVisitsOverview =
  "/api/manager/analytics/store/chart/new-returning-customers-overview";
export const getCustomersVisitsChart =
  "/api/manager/analytics/store/chart/new-returning-customers";
export const getCustomerInsights = "/api/manager/analytics/store/customers";
export const getRedemptionInsights = "/api/manager/analytics/store/redemptions";
export const getRedemptionInsightsTotal =
  "/api/manager/analytics/store/redemptions/total";
export const getTransactionInsights =
  "/api/manager/analytics/store/transactions";
export const getTransactionInsightsTotal =
  "/api/manager/analytics/store/transactions/total";
export const getBranchInsights = "/api/manager/analytics/store/branches";
export const getBranchInsightsTotal =
  "/api/manager/analytics/store/branches/total";
export const getTopRedeemedGifts =
  "/api/manager/analytics/store/top-redeemed-gifts";
export const getTotalCustomerInsights =
  "/api/manager/analytics/store/customers/total";

export const branchesPerformanceURL = "/api/manager/analytics/branch/top-lowest-branches";
// export csv
export const exportCustomerTransactionsCSV =
  "/api/manager/analytics/store/transactions/csv";
export const exportCustomerRedemptionsCSV =
  "/api/manager/analytics/store/redemptions/csv";
// customer receipts
export const getCustomerReceipts = "/api/manager/customers";
