import * as React from "react";
import styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import "react-datetime/css/react-datetime.css";
import Dropzone from "react-dropzone";
import { translate } from "react-i18next";
import CoverPlaceholderImg from "../../assets/cover-placeholder.svg";

interface IRenderCoverPhotoPickerProps {
  onPick: () => any;
  label: string;
  description: string;
  buttonText: string;
  customLabel: boolean;
  unsupportedFileTypeText: string;
}

class RenderCoverPhotoPickerClass extends React.Component<
  WrappedFieldProps & IRenderCoverPhotoPickerProps
> {
  public render() {
    const baseStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    };
    const activeStyle = {
      borderStyle: "solid",
      borderColor: "#6c6",
      backgroundColor: "#eee",
    };
    const rejectStyle = {
      borderStyle: "solid",
      borderColor: "#c66",
      backgroundColor: "#eee",
    };
    const thumb = {
      width: "50rem",
      height: "14rem",
    };

    const img = {
      display: "block",
      objectFit: "cover",
      width: "100%",
      height: "100%",
    };

    const {
      input,
      meta: { touched, warning, error },
      label,
      buttonText,
      onPick,
      unsupportedFileTypeText,
      description,
    } = this.props;
    const thumbs = (input.value || []).map((file) => (
      <div style={thumb} key={file.name}>
        <img src={URL.createObjectURL(file)} style={img as any} alt="" />
      </div>
    ));
    const handleChange = (data) => {
      input.onChange(data);
      input.onBlur(data);
      onPick();
    };
    return (
      <div className={styles.photoUploaderWrapper}>
        <label className={styles.label}>{label}</label>
        <span className={styles.max}>
          {description || "Image should be at least 4KB"}
        </span>

        <div className={styles.uploadCoverPart}>
          <Dropzone
            onClick={input.onBlur}
            multiple={false}
            accept="image/*"
            onDrop={handleChange}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject,
            }) => {
              let styling: any = { ...baseStyle };
              styling = isDragActive ? { ...styles, ...activeStyle } : styling;
              styling = isDragReject ? { ...styles, ...rejectStyle } : styling;

              return (
                <>
                  <div {...getRootProps()} style={styling}>
                    <input {...getInputProps()} />
                    {(input.value || []).length ? (
                      <>{thumbs}</>
                    ) : (
                      <div>
                        <img
                          src={CoverPlaceholderImg}
                          className={styles.img}
                          alt="cover-placeholder"
                        />
                      </div>
                    )}
                    {isDragReject && (
                      <div>
                        {unsupportedFileTypeText || "Unsupported file type..."}
                      </div>
                    )}
                  </div>
                  <div className={styles.uploadWrapper}>
                    {/* <div style={{display: 'flex', alignItems: 'center'}}> */}
                    {touched &&
                      ((error && (
                        <span
                          style={{ margin: "0 1rem" }}
                          className={styles.formError}
                        >
                          {error}
                        </span>
                      )) ||
                        (warning && <span>{warning}</span>))}
                    <div {...getRootProps()} className={styles.uploadButton}>
                      {buttonText || "Upload Image"}
                    </div>
                    {/* </div> */}
                  </div>
                </>
              );
            }}
          </Dropzone>
        </div>
      </div>
    );
  }
}

export const RenderCoverPhotoPicker = translate("formElements")(
  RenderCoverPhotoPickerClass
);
