import axios from "../axios";
import * as Requests from "../requests";

export enum OrderingQRPortalStatus {
  published = "published",
  unpublished = "unpublished",
  service_disabled = "service_disabled",
}
export interface IOrderingQRPortal {
  status: OrderingQRPortalStatus;
}
export const getOrderingQRPortalApi = (token: string) => {
  return axios.get<IOrderingQRPortal>(Requests.orderingQrPortalStatusURL, {
    headers: { token },
  });
};
