import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getBranchInfoAction } from "../../constants/actions";
import {
  getBranchInfo,
  getBranchInfoFailure,
  getBranchInfoSuccess,
} from "../actions/past-order-actions";
import { union } from "ts-action";
import { getBranchInfoAPI } from "../../axios/getBranchInfo";

const actionType = union({ getBranchInfo } as any);
function* getBranchInfoSaga(action: any) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getBranchInfoAPI, token, action.payload);
    yield put(getBranchInfoSuccess(res.data));
  } catch (e) {
    yield put(getBranchInfoFailure(e));
  }
}

export function* watchGetBranchInfoSaga() {
  yield takeLatest(getBranchInfoAction.requested, getBranchInfoSaga);
}
