import { union } from "ts-action";
import { put, takeLatest, select } from "redux-saga/effects";
import { defaultRetry } from "../../../WizardComponents/Branches/AddBranchForm/redux/getSingleBranchSaga";
import {
  setPaymentMethod,
  setPaymentMethodFailure,
  setPaymentMethodSuccess,
} from "../actions/min-order-value";
import { selectToken } from "../../../../redux-store/selectors";
import { setPaymentMethodApi } from "../../axios/setPaymentMethod";
import { setPaymentMethodAction } from "../../constants/actions";

const actionType = union({ setPaymentMethod });

function* setPaymentMethodSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    yield defaultRetry(setPaymentMethodApi, token, payload.payment_methods, payload?.pos_online_payment_method_id);
    yield put(setPaymentMethodSuccess(payload.payment_methods));
  } catch (e) {
    yield put(setPaymentMethodFailure(e as Error));
  }
}

export function* watchSetPaymentMethodSaga() {
  yield takeLatest(setPaymentMethodAction.requested, setPaymentMethodSaga);
}
