import moment from "moment";
import { CustomerBox } from "../CustomerBox";
import { translate } from "../../../../../helpers/translate";
import Strings from "../../../../../i18n/Manager/strings/insights";
import i18n from "../../../../../i18n/i18n";

const t = translate("insights");
export const columns = [
  {
    title: t(Strings.customer),
    dataIndex: "",
    key: "",
    render: (obj) => (
      <CustomerBox
        name={obj.customer_name}
        image={obj.customer_image}
        subTitle={`${t(Strings.lastVisit)}: ${moment(obj.last_visit_timestamp)
          .locale(i18n.language.includes("ar") ? "ar" : "en")
          .format("DD MMMM YYYY - h : mm A")}`}
      />
    ),
    width: 320,
  },
  {
    title: t(Strings.online_orders),
    dataIndex: "",
    key: "online_orders_count",
    sorter: true,
    render: (obj) => <span>{obj.online_orders_count || 0}</span>,
  },
  {
    title: t(Strings.redemptions),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => <span>{obj.redemptions_count || 0}</span>,
  },
  {
    title: t(Strings.visits),
    dataIndex: "",
    key: "points_operation_count",
    sorter: true,
    render: (obj) => <span>{obj.points_operation_count || 0}</span>,
  },
  {
    title: t(Strings.current_points),
    dataIndex: "",
    key: "current_points",
    sorter: true,
    render: (obj) => <span>{obj.current_points || 0}</span>,
  },
  {
    title: t(Strings.life_time_points),
    dataIndex: "",
    key: "life_time_points",
    sorter: true,
    render: (obj) => <span>{obj.life_time_points || 0}</span>,
  },
];
