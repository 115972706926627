import reviews from "./reviews";
export default {
  competitionMissedTitle: "يبدو أنه قد فاتك شهر {{month}}",
  competitionNotStartedTitle: "مسابقة {{currentMonth}} لم تبدأ بعد",
  competitionNotStartedSubTitle: "رجاءاً قم بالانتظار حتى بداية الشهر",
  editGifts: "تعديل الجوائز",
  deleteBooking: "إلغاء الحجز",
  password: "كلمة المرور",
  deleteCompetitionModalTitle: "حذف المسابقة",
  enterPasswordToDeletePart1: "اكتب كلمة المرور لكي يتم حدف",
  enterPasswordToDeletePart2: "مسابقة {{nextMonth}}",
  delete: "حدف",
  receiptCode: "رقم الفاتوره",
  createCompetitionTitle: "حجز مسابقة شهر {{nextMonth}}",
  createCompetitionSubTitle: "جوائز المسابقة",

  extendGifts: "إضافة جوائز للمركزين #4, #5",
  removeGiftExtension: "إزالة جوائز المركزين #4, #5",

  giftFormTitle: "المركز #{{rank}}",
  giftTitlePlaceholder: "اسم الجائزة",
  giftDescriptionPlaceholder: "قم بوصف الجائزة",

  somethingWentWrong: "حدث خطأ ما!",

  editCompetitionTitle: "تعديل مسابقة شهر {{nextMonth}}",
  editCompetitionSubTitle: "الجوائز",

  tutorialFirstStepTitle: "الخط الزمني",
  tutorialFirstStepSubTitle:
    "يمكنك مشاهدة نتائج الشهر المنقضي، وادارة المسابقة الحالية، وأيضاً حجز مسابقة الشهر القادم",
  tutorialSecondStepTitle: "الشهور",
  tutorialSecondStepSubTitle: "يمكنك الضغط على أي من الشهور لعرض بياناته",
  tutorialThirdStepTitle: "جوائز المسابقة",
  tutorialThirdStepSubTitle: "هنا يمكنك استعراض جوائز المسابقة الحالية",
  tutorialFourthStepTitle: "حجز الشهر القادم",
  tutorialFourthStepSubTitle: "قم بحجز مسابقة للشهر القادم",
  tutorialFifthStepTitle: "العملاء المشاركون",
  tutorialFifthStepSubTitle: "يمكنك استعراض العملاء المشاركين في المسابقة",
  tutorialSixthStepTitle: "تصفية العملاء",
  tutorialSixthStepSubTitle:
    "يمكنك تصفية العملاء المشاركين في المسابقة، عن طريق خيارات العملاء الذين قاموا بتحميل التطبيق والآخرين الذين لم يقوموا بتحميله",
  tutorialTitle: "مسابقتك",
  tutorialSubTitle:
    "قم بإشراك عملائك في مسابقة وقم بمكافئة عملائك الأكثر ولاءاً بأفضل الجوائز",
  tutorialStart: "أخبرني كيف؟",
  tutorialTempGiftName: "اسم الجائزة",
  tutorialTempGiftsListTitle: "الجوائز",

  "1st": "الأول",
  "2nd": "الثاني",
  "3rd": "الثالث",
  "4th": "الرابع",
  "5th": "الخامس",
  giftRanking: "",
  nextMonthBooked: "تم حجز {{nextMonthName}}",
  bookNextMonth: "قم بحجز {{nextMonthName}}",

  restartTutorialButtonText: "كيف يعمل النظام",

  noCompetitionTitle: "لم يتم حجز أي مسابقة بعد",
  noCompetitionSubTitle: "قم بالحجز الآن!",

  customerReceiptPoints: "النقاط",
  customerReceiptRemainingPoints: "متبقي",
  compensation: "تعويض",
  invoice: "فاتورة",
  branch: "فرع",
  cashierName: "اسم الكاشير",
  male: "ذكر",
  female: "أنثى",
  yearsOld: " ،{{count}} سنة",
  yearsOld_1: " ،سنة",
  yearsOld_2: " ،سنتان",
  yearsOld_3: "، {{count}} سنين",
  yearsOld_4: "، {{count}} سنة",
  yearsOld_5: "، {{count}} سنة",
  totalInvoicesPart1: "مجموع",
  totalInvoicesPart2: "الفواتير",
  lifeTimePointsPart1: "جميع النقاط",
  lifeTimePointsPart2: "المجمعة",
  storeVisitsPart1: "زيارات",
  storeVisitsPart2: "العميل",
  receivedPointsPart1: "النقاط",
  receivedPointsPart2: "الحالية",
  overallRatingPart1: "التقييم",
  overallRatingPart2: "الكلي",
  pointsOperationsTitle: "عمليات الشراء",
  redemptionsTitle: "عمليات تبديل الجوائز",
  ratingsTitle: "التقييمات",
  noPointsOperations: "لم يقم هذا المستخدم بأية عملية شراء",
  noRedemptions: "لم يقم هذا العميل بتبديل أي هدية",
  noRatings: "لم يقم هذا العميل بتقييم أي من زياراته",
  errorLoadingPointsOperations: "خطأ في تحميل عمليات الشراء",
  errorLoadingRedemptions: "خطأ في تحميل هدايا العميل السابقة",
  errorLoadingRatings: "خطأ في تحميل تقييمات العميل",
  photoOf: "صورة",

  emoji1: reviews.emoji1,
  emoji2: reviews.emoji2,
  emoji3: reviews.emoji3,
  emoji4: reviews.emoji4,
  emoji5: reviews.emoji5,
  readMore: "اقرأ المزيد...",
  next: "التالي",
  last: "انهاء",
  gifts: "الجوائز",
  allUsers: "عدد المشاركين",
  all: "الجميع",
  withTheApp: "من معهم التطبيق",
  withoutTheApp: "بدون التطبيق",
  filters: "التصفية",
  pointsWithCount: "{{count}} نقطة",
  pointsWithCount_1: "نقطة واحدة",
  pointsWithCount_2: "نقطتان",
  pointsWithCount_3: "{{count}} نقاط",
  pointsWithCount_4: "{{count}} نقطة",
  noUsers: "لا يوجد أي مشتركين في هذه المسابقة",
  createCompetition: "حجز",
  editCompetition: "تعديل",
  errorInternetConnection: "رجاءاً قم بالتحقق من اتصالك بالانترنت",
  errorOccurredRetrying: "حدث خطأ ما، جاري اعادة المحاولة...",
  errorEnterSpecifiedNumberOfGifts: "رجاءاً قم باختيار 3 أو 5 جوائز",
  errorLoadingCompetition: "فشل في تحميل بيانات المسابقة، رجاءاً حاول مجدداً",
  errorWrongPassword: "كلمة المرور غير صحيحة",

  successCompetitionCreated: "تم حجز المسابقة بنجاح",
  successCompetitionDeleted: "تم حذف المسابقة",
  successCompetitionEdited: "تم تعديل المسابقة",
  successStoreEditsSaved: "تم حفظ تعديلاتك",
  competitorName: "اسم المتسابق",

  totalInvoicesTableHead: "مجموع الفواتير",

  tableHeadUserPhoneNumber: "رقم الهاتف",
  tableHeadUserName: "الاسم",
  tableHeadUserPoints: "النقاط",
  tableHeadUserMoneySpent: "النقود المدفوعة",
  tableHeadUserEmail: "البريد الالكتروني",

  statsTotalInvoices: "صافي ربح الشهر",
  statsTotalVisits: "عدد الزيارات",

  firstCountUsers: "أول {{count}} عميل",
  firstCountUsers_1: "أول عميل",
  firstCountUsers_2: "أول عميلان",
  firstCountUsers_3: "أول {{count}} عملاء",
  firstCountUsers_4: "أول {{count}} عميل",
  statsAllUsers: "جميع العملاء",
};
