import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  getMinValueSuccess,
  setMinValueSuccess,
} from "../actions/min-order-value";
import { getMinOrderValueAction } from "../../constants/actions";
import { IMinOrderValue } from "../../lib/types";

interface IState {
  minOrderValue: IMinOrderValue;
}

const initialState: IState = {
  minOrderValue: {
    minimum_order_value: undefined,
    minimum_order_value_enabled: 0,
  },
};

export const minOrderValueReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getMinValueSuccess, (state: IState, { payload }) => ({
        ...state,
        minOrderValue: payload,
      })),
      on(setMinValueSuccess, (state: IState, { payload }) => ({
        ...state,
        minOrderValue: payload,
      })),
    ] as any,
    initialState
  ),
  getMinOrderValueAction
);
