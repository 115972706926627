import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.sass";
import { Input, Table, Spin } from "antd";
import { columns } from "./columns";
import "./style.less";
import { Search } from "react-feather";
import { useSelect } from "../../../../../hooks/useSelect";
import { LoadingOutlined } from "@ant-design/icons";
import { useIsVisible } from "react-is-visible";
import { useDispatch } from "react-redux";
import { getBranches, getStore } from "../../../../../redux-store/actions";
import Strings from "../../../../../i18n/Manager/strings/insights";
import { useTranslation } from "../../../../../shopx-shared-components/hooks/useTranslation";
import { trackEvent } from "../../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../../constants/events-for-tracking";
import {
  AmplitudeEvents,
  amplitudeLogEvent,
} from "../../../../../tracking/amplitude";
import StoreData from "../../../../../contexts/StoreData";
import { getFraudlentCustomerListRequested } from "../../../../../redux-store/actions/get-fraud-center-actions";
import ProfileModal from "../../../../../Components/Leaderboard/ProfileModal";



export enum CustomerType {
  appUser = "app_user",
  nonAppUser = "unregistered_user",
}

export enum Gender {
  male = "male",
  female = "female",
}

export interface TimeKeys {
  last_visit_timestamp?: string;
}

export interface Filters {
  page: number;
  size: number;
  store_id?: string,
  phone_number?: string;
}

const FraudCenter: React.FC = () => {


  const dispatch = useDispatch();

  const { data: fraudList, status: fraudListStatus, hasMore } = useSelect((state) => state.fraudlentCustomersReducer);

  const listPaginationLoaderRef = useRef(null);
  const isVisible = useIsVisible(listPaginationLoaderRef);
  const [customerPopup, setCustomerPopup] = useState<boolean>(false);
  const [customerPopupId, setCustomerPopupId] = useState<string | undefined>();
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [searchInputLoading, setSearchInputLoading] = useState<boolean>(false);
  const searchError = useMemo(() => {
    let pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    return !!!pattern.test(filterSearchText);
  }, [filterSearchText])
  const { storeData, userData } = useContext(StoreData);
  const [customersData, setCustomersData] = useState<any[] | undefined>(
    undefined
  );
  // localization

  const { t } = useTranslation("insights");
  const initialFiltersValue = {
    page: 0,
    size: 20,
    store_id: storeData?.id,
    phone_number: "",

  };

  const [filters, setFilters] = useState<Filters>(initialFiltersValue);
  const handleSearchChanges = (search: string) => {
    setFilterSearchText(search)
  }
  useEffect(() => {
    // if (hasMore) {
    dispatch(getFraudlentCustomerListRequested(filters));
    // }
  }, [filters])

  useEffect(() => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed customers insights page"]);
    amplitudeLogEvent(AmplitudeEvents.customers_insights, {
      user_name: userData?.name,
      user_id: userData?.id,
      user_role: userData?.title || userData?.role,
      store_id: storeData?.id,
      store_name: storeData?.name,
    });
  }, []);

  useEffect(() => {
    if (customerPopupId) setCustomerPopup(true);
    else setCustomerPopup(false);
  }, [customerPopupId]);

  useEffect(() => {
    if (fraudListStatus === "success" && fraudList.length > 0) {
      if (filters.page === 0) {
        setCustomersData(fraudList.map(item => ({
          ...item,
        })));
      } else {
        setCustomersData(fraudList);
      }

    } else {
      //
      if (filters.page === 0) {
        setCustomersData(fraudList);
      }
    }
  }, [fraudListStatus, fraudList]);

  useEffect(() => {
    if (fraudListStatus !== "loading") {
      setSearchInputLoading(false);
    }
  }, [fraudListStatus]);
  // Get store with branches .. for "window refresh"
  useEffect(() => {
    dispatch(getStore());
    dispatch(getBranches());
  }, [dispatch]);

  // When bottom loader is visibile
  useEffect(() => {
    if (hasMore && isVisible && fraudListStatus !== "loading") {
      setFilters((filters) => ({
        ...filters,
        page: filters.page + 1,
      }));
    }
  }, [isVisible, fraudListStatus, hasMore]);

  // Apply text search
  const applySearchFilter = (e) => {
    if (!searchError || !filterSearchText) {
      e.target.blur();
      setFilters({
        ...filters,
        page: 0,
        phone_number: filterSearchText,
      });
    };
  }

  const SearchIconJSX = (
    <Search color="#8E8E93" size={18} className={styles.searchInputIcon} />
  );

  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  const handleTableChange = (pagination, filter, sorter) => {
    const { order, column } = sorter;

    const sort_by = column ? column.key : undefined;

    const sort_order =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined;

    setFilters((prevState) => ({
      ...prevState,
      sort_order,
      sort_by,
    }));
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.heading}>
        <span className={styles.headingText}>{t(Strings.customers)}</span>
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.tableHead}>
          <div className={styles.tableHeadRowOne}>
            <div className={styles.title}></div>
            <div className={styles.searchAndActions}>
              <div className={!!searchError && !!filterSearchText ? `${styles.search} ${styles.hasError}` : styles.search}>
                <Input
                  className={"insights-header-search-input"}
                  size="small"
                  placeholder={t(Strings.search)}
                  value={filterSearchText}
                  onPressEnter={applySearchFilter}
                  onChange={(e) => handleSearchChanges(e.target.value)}
                  disabled={searchInputLoading}
                  suffix={
                    searchInputLoading && <Spin indicator={CustomSpinner} />
                  }
                  prefix={SearchIconJSX}
                />
                {
                  !!searchError && !!filterSearchText && <p style={{ color: "red", fontSize: "12px", padding: "5px 0" }}>
                    {t(Strings.invalid_phone_number)}
                  </p>
                }
              </div>

            </div>

          </div>

        </div>
        <div className={styles.tableBody} id="customer-list">
          <Table
            columns={columns}
            rowClassName={"insights-table-row"}
            pagination={false}
            loading={
              fraudListStatus === "loading" && filters.page === 0
                ? { indicator: CustomSpinner }
                : false
            }
            dataSource={customersData?.map((item) => ({
              ...item, onClick: (id: string) => {
                setCustomerPopupId(id);
              },
            }))}
            onChange={handleTableChange}
          // onRow={(row) => ({
          //   onClick: () => {
          //     setCustomerPopupId(row.customer_id);
          //   },
          // })}
          />

          <div
            ref={listPaginationLoaderRef}
            className={`${hasMore && styles.tableLoader}`}
          >
            {hasMore && <Spin indicator={CustomSpinner} />}
          </div>

        </div>
      </div>
      {customerPopup && customerPopupId && (
        <ProfileModal
          customer={{ id: customerPopupId }}
          close={() => {
            setCustomerPopupId(undefined);
          }}
          modal={customerPopup}
        />
      )}
    </div>
  );
};

export default FraudCenter;
