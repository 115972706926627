import { call, put, select, takeLatest } from "redux-saga/effects";
import { getBannerCardsAction } from "../../constants";
import { selectToken } from "../selectors";
import { getBannerCardsApi } from "../../axios/banner-cards";
import {
  getBannerCardsFailure,
  getBannerCardsSuccess,
  resetBannerCardsState,
} from "../actions/brand-visuals-actions";

function* getBannerCardsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getBannerCardsApi, token);
    yield put(getBannerCardsSuccess(res.data));
    yield put(resetBannerCardsState());
  } catch (error) {
    yield put(getBannerCardsFailure(error as Error));
  }
}
export default function* watchGetBannerCardsSaga() {
  yield takeLatest(getBannerCardsAction.requested, getBannerCardsSaga);
}
