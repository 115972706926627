import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const createCompetition = (
  data: any,
  token
): AxiosReturn<{ id: string }> => {
  return axios.post(Requests.leaderBoards, data, {
    baseURL: process.env.REACT_APP_LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
