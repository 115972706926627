import switchLanguage from "./switchLanguage";
import reviews from "./reviews";
import navigation from "./navigation";
import chooseLanguage from "./chooseLanguage";
import giftList from "./giftList";
import registrationForm from "./registrationForm";
import onBoardingSteps from "./onBoardingSteps";
import whyUse from "./whyUse";
import menu from "./menu";
import stepper from "./stepper";
import addItemForm from "./addItemForm";
import addFromMenu from "./addFromMenu";
import validators from "./validators";
import convertingRatio from "./convertingRatio";
import importMenuThirdParty from "./importMenuThirdParty";
import importExcelFile from "./importExcelFile";
import finished from "./finished";
import NotFound from "./NotFound";
import nameBio from "./nameBio";
import brandVisuals from "./brandVisuals";
import socialLinks from "./socialLinks";
import branches from "./branches";
import leaderboard from "./leaderboard";
import formElements from "./formElements";
import translations from "./translations";
import users from "./users";
import cashiers from "./cashiers";
import signup from "./signup";
import myAccount from "./myAccount";
import advancedSettings from "./advancedSettings";
import blockedUsers from "./blockedUsers";
import MultipleSelect from "./MultipleSelect";
import zonesUploading from "./zonesUploading";
import pickupTranslation from "../../Components/Pickup/i18n/en/index";
import managerTranslations from "../Manager/en";
import updateNotifier from "./updateNotifier";
import giftingPoints from "./giftingPoints";
export default {
  MultipleSelect,
  myAccount,
  zonesUploading,
  switchLanguage,
  reviews,
  navigation,
  chooseLanguage,
  giftList,
  registrationForm,
  onBoardingSteps,
  whyUse,
  menu,
  stepper,
  addItemForm,
  addFromMenu,
  validators,
  convertingRatio,
  importExcelFile,
  importMenuThirdParty,
  finished,
  NotFound,
  nameBio,
  brandVisuals,
  socialLinks,
  branches,
  leaderboard,
  formElements,
  translations,
  users,
  cashiers,
  signup,
  advancedSettings,
  blockedUsers,
  ...pickupTranslation,
  ...managerTranslations,
  updateNotifier,
  giftingPoints
};
