import axios from "./axios";
import * as Requests from "./requests";

export interface ICreateMenu {
  name: string;
  vat: string;
}

export const createOrderingMenu = (token: string, menuInfo: ICreateMenu) => {
  return axios.post<{ id: string }>(Requests.ORDERING_MENU_API, menuInfo, {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
