import axios from "./axios";
import * as Requests from "./requests";
import { ICreateOrderingItem } from "../lib";

export const editOrderingMenuItem = (
  token: string,
  data: ICreateOrderingItem & {
    branch_has_stop_sync: boolean;
    branch_stop_sync_duration: number;
  },
  id: string
) => {
  return axios.put(Requests.EDIT_ITEM_API(id), data, {
    headers: {
      token,
    },
  });
};
