import React, { useEffect, useState } from "react";
import styles from "./styles.module.sass";

// hooks
import { useSelect } from "../../../../../../hooks/useSelect";
// components
import { LineGraph } from "../LineGraph";
import { TimeFilter, TypeFilter } from "../index";
import InfoIcon from "../../../../../../Components/Pickup/assets/group-2.svg";
// queries
import { useGetCustomersChartQuery } from "../../../../../../Queries/Manager";
// helpers
import { getHoursLabels } from "../../../../../../helpers/analytics";

import * as configs from "../LineGraph/configs";
import { ITotalOrders } from "../../../../../../Components/Pickup/lib/types";
import FeatherArrowIcon from "../FeatherArrowIcon";
import i18n from "../../../../../../i18n/i18n";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import MultiSelect from "../../../../../../@koinz-ui/MultiSelect";
import { useQueries } from "react-query";
import { getCustomersGraphReq } from "../../../../../../axios/Manager/Analytics";
import { useDeepCompareEffect } from "use-deep-compare";
import { LineGraphDataset } from "../LineGraph/types";
import { IBranch } from "../../../../../../types/shared";

interface IProps {
  totalOrdersData: ITotalOrders;
}

const CustomersChart: React.FC<IProps> = (props) => {
  const {
    store: { created_at, id: store_id },
    token,
  } = useSelect((state) => {
    return {
      ...state.storeReducer,
      ...state.authReducer,
    };
  });

  const [selectedBranches, setSelectedBranches] = useState([]);
  const { t } = useTranslation("home");
  const { allBranches } = useSelect((state) => state.branchesReducer);
  // data points of each branch
  const [branchesData, setBranchesData] = useState<any>([]);
  const [allSelectedBranchesLoaded, setAllSelectedBranchesLoaded] =
    useState<boolean>(false);

  const [branchesFilterIsApplied, setBranchesFilterIsApplied] =
    useState<boolean>(false);

  const [getCustomerChartReqConfigs, setGetCustomerChartRequestConfig] =
    useState<{
      store_id: string;
      from: number;
      to: number;
      unit: "hour" | "day";
      branch_ids?: string[];
      customer_type?: string;
    }>({
      store_id: "",
      from: 0,
      to: 0,
      unit: "day",
    });

  const graphColors = ["#38C172", "#E251FF", "#5151FF", "#503E9D", "#FFA351"];
  const primaryColor = "#FFA351";

  const [lineGraphProps, setLineGraphProps] = useState<{
    width: number;
    height: number;
    values: {
      datasets: LineGraphDataset[];
      labels: string[];
    };
  }>({
    width: 1080,
    height: 230,
    values: {
      datasets: [],
      labels: [],
    },
  });

  // handling api requests
  const {
    data: singleLineGraphData,
    isLoading,
    error,
  } = useGetCustomersChartQuery(token, getCustomerChartReqConfigs);

  // when accumulative data is recieved as a single line graph
  useEffect(() => {
    if (singleLineGraphData && !isLoading && !branchesFilterIsApplied) {
      // [ , , ]
      const filteredData = filterDataPoints(singleLineGraphData);

      if (getCustomerChartReqConfigs.unit === "hour") {
        const hoursLabels = getHoursLabels(singleLineGraphData);
        setLineGraphProps((prevValue: any) => ({
          ...prevValue,
          values: {
            ...prevValue.values,
            labels: hoursLabels,
            datasets: [
              {
                data: mapDataWithLabels(filteredData, hoursLabels),
                borderColor: primaryColor,
                borderWidth: 2,
              },
            ],
          },
        }));
      } else if (getCustomerChartReqConfigs.unit === "day") {
        const exactLabels = getExactPointsLabels(singleLineGraphData);
        setLineGraphProps((prevValue: any) => ({
          ...prevValue,
          values: {
            ...prevValue.values,
            labels: exactLabels,
            datasets: [
              {
                data: mapDataWithLabels(filteredData, exactLabels),
                borderColor: primaryColor,
                borderWidth: 2,
              },
            ],
          },
        }));
      }
    }
  }, [singleLineGraphData, isLoading, branchesFilterIsApplied]);

  // branches comparison
  useDeepCompareEffect(() => {
    // if branches selected
    if (
      branchesData.length > 0 &&
      selectedBranches.length < 6 &&
      allSelectedBranchesLoaded &&
      branchesFilterIsApplied
    ) {
      // [[], [], []]
      const filteredData = branchesData.map((singleBranchData) =>
        filterDataPoints(singleBranchData)
      );

      if (getCustomerChartReqConfigs.unit === "hour") {
        const hoursLabels = getHoursLabels(filteredData[0]);
        const datasets = filteredData.map((singleBranchData: any[], index) => ({
          data: mapDataWithLabels(singleBranchData, hoursLabels),
          borderColor: graphColors[index],
          borderWidth: 2,
        }));

        setLineGraphProps((prevValue) => ({
          ...prevValue,
          values: {
            labels: hoursLabels,
            datasets,
          },
        }));
      } else if (getCustomerChartReqConfigs.unit === "day") {
        const exactLabels = getExactPointsLabels(filteredData[0]);
        const datasets = filteredData.map((singleBranchData: any[], index) => ({
          data: mapDataWithLabels(singleBranchData, exactLabels),
          borderColor: graphColors[index],
          borderWidth: 2,
        }));

        setLineGraphProps((prevValue) => ({
          ...prevValue,
          values: {
            labels: exactLabels,
            datasets,
          },
        }));
      }
    }
  }, [
    branchesData,
    selectedBranches,
    allSelectedBranchesLoaded,
    branchesFilterIsApplied,
    getCustomerChartReqConfigs,
  ]);

  const filterDataPoints = (data) =>
    (data &&
      data.filter(
        (element) =>
          !(
            element.new_customers_count === 0 &&
            element.total_customers_count === 0 &&
            element.date === "1970-01-01"
          )
      )) ||
    [];

  // mapping data with labels
  const mapDataWithLabels = (data, labels) => {
    return data.map((element, index) => ({ ...element, label: labels[index] }));
  };

  // Setting request configs
  useEffect(() => {
    if (typeof created_at === "number") {
      const now = new Date();
      const nowTimeStamp = now.getTime();
      const difference = nowTimeStamp - created_at;

      let daysFromStoreCreation = 0;

      const dateOfTodayAYearAgo = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      );

      const timestampOfADay = 60 * 60 * 24 * 1000;

      daysFromStoreCreation = difference / timestampOfADay;

      // if less than a day => unit = "hour" else "day"
      setGetCustomerChartRequestConfig((prevValue) => ({
        ...prevValue,
        from:
          daysFromStoreCreation <= 28
            ? created_at
            : new Date(now.getTime() - 28 * timestampOfADay).getTime(),
        to: nowTimeStamp,
        unit: daysFromStoreCreation < 1 ? "hour" : "day",
        store_id,
      }));
    }
  }, [created_at, store_id]);

  const getExactPointsLabels = (
    data: {
      date: string;
      [key: string]: number | string;
    }[]
  ) => {
    return (
      (data &&
        data.map((element) => {
          const formattedDate = Date.parse(element.date);
          const monthName = new Intl.DateTimeFormat(i18n.language, {
            month: "short",
          }).format(formattedDate);
          const day = new Intl.DateTimeFormat(i18n.language, {
            day: "2-digit",
          }).format(formattedDate);

          return `${monthName} ${day}`;
        })) ||
      []
    );
  };

  const handleTimeFilterChange = (value: {
    from: number;
    to: number;
    unit: "hour" | "day";
  }) => {
    setGetCustomerChartRequestConfig((prevValue) => ({
      ...prevValue,
      from: value.from,
      to: value.to,
      unit: value.unit,
    }));
  };

  // types filter handlers
  const handleTypeFilterChange = (value) => {
    if (value === "all") {
      let copyOfCustomerChartReqConfigs = { ...getCustomerChartReqConfigs };
      delete copyOfCustomerChartReqConfigs["customer_type"];
      setGetCustomerChartRequestConfig(copyOfCustomerChartReqConfigs);
    } else {
      setGetCustomerChartRequestConfig({
        ...getCustomerChartReqConfigs,
        customer_type: value,
      });
    }
  };

  const typeFilterOptions = [
    { name: t(Strings.all_customers), id: "all" },
    {
      name: t(Strings.app_customers),
      id: "app_user",
    },
    {
      name: t(Strings.non_app_customers),
      id: "unregistered_user",
    },
  ];

  const branchesOptions = React.useMemo(
    () =>
      allBranches.map((branch) => ({
        ...branch,
        title: i18n.language === "ar" ? branch.ar_name : branch.en_name,
      })),
    [allBranches]
  );

  // on apply filter branches
  const handleBranchFilterMultiSelectChange = (values) => {
    setSelectedBranches(values);
    // if select all
    if (values.length === branchesOptions.length) {
      setBranchesFilterIsApplied(false);
    } else {
      setBranchesFilterIsApplied(true);
    }
  };

  const branchesQueries = useQueries(
    selectedBranches.map((branch: any) => {
      return {
        queryKey: [
          "branch-data",
          "customers-chart",
          branch.id,
          getCustomerChartReqConfigs,
        ],
        queryFn: () =>
          getCustomersGraphReq(token, {
            ...getCustomerChartReqConfigs,
            branch_ids: [branch.id],
          }).then((res) => res.data),
        enabled: branchesData.length > 0 && selectedBranches.length <= 5,
        retry: 3,
      };
    })
  );

  useDeepCompareEffect(() => {
    const isBranchesLoaded =
      branchesQueries.map((result) => result.isLoading).filter(Boolean)
        .length === 0;
    if (isBranchesLoaded) {
      setBranchesData([...branchesQueries.map((result) => result.data)]);
      setAllSelectedBranchesLoaded(isBranchesLoaded);
    }
  }, [branchesQueries.map((result) => result.data)]);

  const hasChangeValueInTotalValueRes =
    typeof props.totalOrdersData?.change !== "undefined";

  return (
    <>
      <div className={styles.numbersAndFiltersContainer}>
        <div
          style={{
            marginBottom: "36px",
            padding: "0 .5rem",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontWeight: "bold",
                marginRight: hasChangeValueInTotalValueRes ? ".1rem" : ".6rem",
              }}
            >
              {Number(props.totalOrdersData?.total || 0)}
            </span>
            {hasChangeValueInTotalValueRes && (
              <React.Fragment>
                {Number(props.totalOrdersData.change) > 0 ? (
                  <FeatherArrowIcon
                    width={"20"}
                    height={"20"}
                    stroke={"#000000"}
                  />
                ) : (
                  <div style={{ transform: "rotate(180deg)" }}>
                    <FeatherArrowIcon
                      width={"20"}
                      height={"20"}
                      stroke={"#000000"}
                    />
                  </div>
                )}
              </React.Fragment>
            )}

            <img
              style={{
                width: "2rem",
                height: "2rem",
                transform: "rotate(180deg)",
                cursor: "pointer",
                margin: "0 4px",
              }}
              src={InfoIcon}
            />
          </div>
          {hasChangeValueInTotalValueRes ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  transform:
                    Number(props.totalOrdersData?.change) > 0
                      ? undefined
                      : "rotate(180deg)",
                }}
              >
                <FeatherArrowIcon
                  width={"14"}
                  height={"14"}
                  stroke={
                    Number(props.totalOrdersData?.change) > 0
                      ? "#38C172"
                      : "#EE5253"
                  }
                />
              </div>

              <span
                style={{
                  color:
                    Number(props.totalOrdersData?.change) > 0
                      ? "#38C172"
                      : "#EE5253",
                  fontSize: 14,
                }}
              >
                {t(Strings.customer_percentage_from_last_30_days, {
                  customer_percentage: `${props.totalOrdersData.change}%`,
                })}
              </span>
            </div>
          ) : (
            <span style={{ color: "#8e8e93", fontSize: 12 }}>
              {t(Strings.customers)}
            </span>
          )} */}
        </div>

        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* type filter */}
            <TypeFilter
              onChange={handleTypeFilterChange}
              typesArr={typeFilterOptions}
              showSearch={false}
              prefixText={t(Strings.customer_type)}
              includeAllOption={false}
            />

            {/* branch filter */}
            <div className={styles.branchFilterWrapper}>
              <MultiSelect
                label={t(Strings.branch)}
                canSelectAll={true}
                onApply={handleBranchFilterMultiSelectChange}
                options={branchesOptions}
                selected={
                  selectedBranches.length > 0
                    ? selectedBranches
                    : branchesOptions
                }
                defaultSelected={branchesOptions}
                maxSelectionsNo={5}
              />
            </div>

            {/* time filter */}
            <TimeFilter
              valueKey="last-28-days"
              onChange={handleTimeFilterChange}
            />
          </div>
        </div>
      </div>

      <LineGraph
        {...lineGraphProps}
        lineGraphConfigs={configs.customersGraphConfigs}
      />

      {selectedBranches &&
      selectedBranches.length < 6 &&
      selectedBranches.length > 0 &&
      branchesFilterIsApplied ? (
        <div className={styles.legendsContainer}>
          {selectedBranches.map((element: IBranch, index) => {
            return (
              <div className={styles.legendWrapepr} key={element.id}>
                <span
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: graphColors[index],
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                ></span>
                <span>
                  {i18n.language.includes("ar")
                    ? element.ar_name
                    : element.en_name}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default CustomersChart;
