import { action, payload } from "ts-action";
import {
  addBannerCardAction,
  deleteBannerCardAction,
  getBannerCardsAction,
  reorderBannerCardAction,
  resetBannerCardsStateAction,
} from "../../constants";

export type BannerCard = {
  id: string;
  order: number;
  image: string;
  created_at: number;
  updated_at: number;
};

export const getBannerCards = action(getBannerCardsAction.requested);
export const getBannerCardsSuccess = action(
  getBannerCardsAction.fulfilled,
  payload<BannerCard[]>()
);
export const getBannerCardsFailure = action(
  getBannerCardsAction.rejected,
  payload<Error>()
);

export const addBannerCard = action(
  addBannerCardAction.requested,
  payload<{
    order: number;
    image: string;
  }>()
);

export const addBannerCardSuccess = action(
  addBannerCardAction.fulfilled,
  payload<BannerCard>()
);
export const addBannerCardFailure = action(
  addBannerCardAction.rejected,
  payload<Error>()
);

export const deleteBannerCard = action(
  deleteBannerCardAction.requested,
  payload<string>()
);
export const deleteBannerCardSuccess = action(
  deleteBannerCardAction.fulfilled,
  payload<{ id: string }>()
);
export const deleteBannerCardFailure = action(
  deleteBannerCardAction.rejected,
  payload<Error>()
);

export const reorderBannerCard = action(
  reorderBannerCardAction.requested,
  payload<{ new_order: number; banner_card_id: string; old_order: number }>()
);

export const reorderBannerCardSuccess = action(
  reorderBannerCardAction.fulfilled,
  payload<BannerCard>()
);
export const reorderBannerCardFailure = action(
  reorderBannerCardAction.rejected,
  payload<{ order: number; id: string; error: Error }>()
);

export const resetBannerCardsState = action(resetBannerCardsStateAction);

export const selectBannerCard = action(
  "ACTION_SELECT_BANNER_CARD",
  payload<{ banner_card_id: string; order: number }>()
);

export const setBannerCardModalVisibility = action(
  "ACTION_SET_BANNER_CARD_MODAL_VISIBILITY",
  payload<boolean>()
);
