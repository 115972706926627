import axios from "./axios";
import * as Requests from "./requests";
import { IStorePickup } from "../lib/types";

export const getStorePickup = (token) => {
  return axios.get<IStorePickup>(Requests.STORE_PICKUP_SETTINGS, {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
