import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IAddSpecialItemData, IStoreItem } from "../types/wizard-types";

export const addSpecialItem = (
  token,
  data: IAddSpecialItemData
): AxiosReturn<IStoreItem> => {
  return axios.post(Requests.specialItems, data, {
    headers: {
      token,
    },
  });
};
