import { getOrderingQRPortalStatusAction } from "./../../../constants/actions";
import { action, payload } from "ts-action";
import { OrderingQRPortalStatus } from "../../../axios/Portal/getOrderingQRPortalApi";

export const getOrderingQRPortalStatus = action(
  getOrderingQRPortalStatusAction.requested
);
export const getOrderingQRPortalStatusSuccess = action(
  getOrderingQRPortalStatusAction.fulfilled,
  payload<{ status: OrderingQRPortalStatus }>()
);

export const getOrderingQRPortalStatusFailure = action(
  getOrderingQRPortalStatusAction.rejected,
  payload<Error>()
);
