import { call, put, select, takeLatest } from "redux-saga/effects";
import { getGiftListAction } from "../../constants";
import { ICataloguedItems, IStoreItem } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { getSpecialItems } from "../../axios/editSpecialItems";
import { getStoreItems } from "../../axios/getStoreItems";

function* getGiftListSaga() {
  try {
    const selectedToken = yield select(selectToken);
    const specialItemsRes = yield call(getSpecialItems, selectedToken);
    const specialItems = (specialItemsRes.data.items as IStoreItem[]) || [];
    const storeItemsRes = yield call(getStoreItems, selectedToken);
    const items = storeItemsRes.data as ICataloguedItems[];
    yield put(actions.getGiftListSuccess(specialItems, items || []));
    yield put(actions.getStore());
  } catch (e) {
    yield put(actions.getGiftListFailure(e));
  }
}

export function* watchGetSpecialItemsSaga() {
  yield takeLatest(getGiftListAction.requested, getGiftListSaga);
}
