import { call, put, select, takeLatest } from "redux-saga/effects";
import { getPosEntitiesFailure, getPosEntitiesSuccess } from "../../actions";
import { selectToken } from "../../selectors";
import { getPosEntitiesApi } from "../../../axios/pos";
import { getPosEntitiesAction } from "../../../constants";

function* getPosEntitiesSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getPosEntitiesApi, token);
    yield put(getPosEntitiesSuccess(res.data.pos));
  } catch (error) {
    yield put(getPosEntitiesFailure(error as Error));
  }
}

export function* watchGetPosEntitiesSaga() {
  yield takeLatest(getPosEntitiesAction.requested, getPosEntitiesSaga);
}
