import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getStatisticsAction } from "../../constants/actions";
import { IStatistics } from "../actions/lib";
import { getStatisticsSuccess } from "../actions/statistics-actions";

interface IState {
  statistics: IStatistics;
}

const initialState: IState = {
  statistics: {
    chart_data: [],
    net_profit: 0,
    total_orders: 0,
  },
};

export const statisticsReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getStatisticsSuccess, (state: IState, { payload }) => ({
        ...state,
        statistics: payload,
      })),
    ] as any,
    initialState
  ),
  getStatisticsAction
);
