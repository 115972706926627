export default {
  changeItemCategory: "changeItemCategory",
  createCategory: "createCategory",
  createItem: "createItem",
  createModifierGroup: "createModifierGroup",
  deleteCategory: "deleteCategory",
  deleteItem: "deleteItem",
  deleteSuggestedModifierGroup: "deleteSuggestedModifierGroup",
  editCategory: "editCategory",
  editItem: "editItem",
  editModifierGroup: "editModifierGroup",
  reorderItem: "reorderItem",
  reorderCategory: "reorderCategory",
};
