import axios from "./axios";
import * as Requests from "./requests";
import { ICashier } from "./getCashiers";

export interface IPointsOperation {
  created_at: any;
  seen: number;
  remaining: number;
  points: number;
  is_compensation: number;
  expires_at: any;
  expired: number;
  call_center: number;
  phoneNumber: string;
  updated_at: any;
  price: number;
  countryCode: string;
  service: string;
  phoneCountryISO: string;
  receipt_code: string;
  cashier_id: string;
  cashier: ICashier;
  id: string;
  customer: string;
  branch_name: string;
}

export interface IReview {
  updated_at: number;
  points_operation_id: string;
  rate: number;
  created_at: number;
  text: string;
  id: string;
  resolution_time: number;
  resolution: string;
  version: string;
  resolved: number;
  branch_id: string;
}

export interface IRedemption {
  created_at: any;
  id: string;
  updated_at: any;
  points: number;
  name: string;
  branch_name: string;
  image: string;
}

export interface IGetUserProfileRes {
  image: string;
  gender: number;
  app_version: string;
  birth_date: number;
  sign_up_time: number;
  shopxId: string;
  created_at: number;
  country_code: string;
  last_active_time: number;
  updated_at: number;
  district_code: string;
  name: string;
  phone_number: string;
  id: string;
  email: string;
  age: number;
  points: number;
  visits: number;
  points_operations: IPointsOperation[];
  page_points_operations: number;
  total_points: number;
  total_invoices: number;
  reviews: IReview[];
  average_rating: number;
  redemptions: IRedemption[];
}

export const getUserProfile = (token: string, customer_id: string) => {
  return axios.get<IGetUserProfileRes>(Requests.getUserProfile, {
    headers: {
      token,
    },
    params: {
      customer_id,
      page_points_operations: 0,
      page_redemptions: 0,
      page_reviews: 0,
    },
  });
};

interface IGetNextPointsOperationsPageFilters {
  customer_id: string;
  page_points_operations: number;
}

export const getNextPointsOperationsPage = (
  token: string,
  filters: IGetNextPointsOperationsPageFilters
) => {
  return axios.get<IGetUserProfileRes>(Requests.getUserProfile, {
    headers: {
      token,
    },
    params: filters,
  });
};

interface IGetNextRedemptionsPageFilters {
  customer_id: string;
  page_redemptions: number;
}

export const getNextRedemptionsPage = (
  token: string,
  filters: IGetNextRedemptionsPageFilters
) => {
  return axios.get<IGetUserProfileRes>(Requests.getUserProfile, {
    headers: {
      token,
    },
    params: filters,
  });
};

interface IGetNextReviewsPageFilters {
  customer_id: string;
  page_reviews: number;
}

export const getNextReviewsPage = (
  token: string,
  filters: IGetNextReviewsPageFilters
) => {
  return axios.get<IGetUserProfileRes>(Requests.getUserProfile, {
    headers: {
      token,
    },
    params: filters,
  });
};
