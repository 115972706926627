import {
  ICompetitionCreate,
  ICreateCompetitionAction,
  ICreateCompetitionFailureAction,
  ICreateCompetitionSuccessAction,
} from "../../types";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";

interface ICompetitionCreated extends ICompetitionCreate {
  id: string;
}

export interface ICreateCompetitionReducerState {
  createdCompetition: ICompetitionCreated;
  creating: boolean;
  created: boolean;
  failed: boolean;
}

const CREATED_COMPETITION = "createdCompetition";
const CREATING = "creating";
const CREATED = "created";
const FAILED = "failed";

const initialState: ICreateCompetitionReducerState = Map({
  [CREATED_COMPETITION]: {},
  [CREATED]: false,
  [CREATING]: false,
  [FAILED]: false,
}).toJS();

type createCompetitionActions =
  | ICreateCompetitionAction
  | ICreateCompetitionFailureAction
  | ICreateCompetitionSuccessAction;

export default (
  state = initialState,
  action: createCompetitionActions
): ICreateCompetitionReducerState => {
  switch (action.type) {
    case constants.createCompetitionAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(
          CREATED_COMPETITION,
          (action as ICreateCompetitionSuccessAction).payload
        );
        map.set(CREATING, false);
        map.set(CREATED, true);
        map.set(FAILED, false);
      });
    }
    case constants.createCompetitionAction.requested: {
      return mutateState(state, (map) => {
        map.set(CREATED_COMPETITION, {});
        map.set(CREATING, true);
        map.set(CREATED, false);
        map.set(FAILED, false);
      });
    }
    case constants.createCompetitionAction.rejected: {
      return mutateState(state, (map) => {
        map.set(CREATED_COMPETITION, {});
        map.set(CREATING, false);
        map.set(CREATED, false);
        map.set(FAILED, true);
      });
    }
    default:
      return state;
  }
};
