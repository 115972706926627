export default {
  openingHour: "ساعات العمل",
  preparationTime: "وقت التحضير",
  delivery: "وقت وتكلفة الدليفرى",
  brandCat: "تصنيف المتجر",
  brandTags: "أوسمة المتجر",
  coverPhoto: "صورة الواجهة",
  friday: "الجمعة",
  saturday: "السبت",

  sunday: "الاحد",
  monday: "الاثنين",
  wednesday: "الاربعاء",
  tuesday: "الثلاثاء",
  posTitle: "يتم إرسال الطلبات للفروع عن طريق نظام الـ POS مباشرة",
  thursday: "الخميس",
  AM: "ص",
  PM: "م",
  selectTimeZoneLabel: "اختر منطقتك الزمنية",
  setUpPrepTimeTitle: "تنصيب وقت التحضير",
  setUpPrepTimeSubTitle: "ما هو متوسط وقت تحضير الطلبات في متجرك؟",
  from: "من",
  to: "إلى",
  minutes: "دقيقة",
  minimumOrderValue: "أقل قيمة للطلب",
  setUpDeliveryTitle: "تنصيب وقت وتكليف خدمة التطلبات",
  setUpDeliverySubTitle: "ما هو متوسط وقت الطلب",
  time: "الوقت",
  cost: "التكلفة",
  description:
    "يجب ان تكون يكون حجم الصور لا يتعدى 5MB تكون من صيغة ال JPG , PNG ",
  uploadPhoto: "رفع الصورة",
  done: "الأنتهاء",
  unsupported: "نوع صورة غير مدعم",
  routingMethod: "طريقة التوجية",
  orderTracking: "تتبع الطلب",
  deliveryMethod: "طريقة التوصيل",
  paymentMethodTitle: "طريقة الدفع",
  branchesWorkingHours: "مواعيد عمل الفروع",
  pointOfSale: "نقاط البيع",
  pleaseEnterGroupId: "رجاءًا  قم بإدخال رقم المجموعة",
  pleaseEnterPaymentMethodId: "رجاءًا رقم بإدخال رقم طريقة الدفع",
  groupId: "رقم المجموعة",
  paymentMethodId: "رقم طريقة الدفع",
  save: "حفظ",
  cancel: "إلغاء",
  discard: "تجاهل",
  posWarningModalText:
    "لديك تغييرات غير محفوظة في هذا الحساب. يرجى اختيار إجراء",
  posSettingsUpdatedSuccessfully: "تم تحديث إعدادات POS بنجاح",
  somethingWentWrong: "حدث خطأٌ ما!",
  posSettings: "إعدادات نقاط البيع",
  posSettingsDescription: "إعدادات تكامل نقاط البيع.",
  posEntitiesSettings: "إعدادات كيانات نقاط البيع",
  posEntitiesSettingsDescription:
    "اختر نقطة البيع، قم بتعيين إعداداته، ثم احفظ. كرر لكل كيان نقاط بيع.",
  selectPOSEntity: "اختر نقطة البيع",
  menuGroupID: "معرف مجموعة القائمة",
  onlinePaymentMethodID: "معرف طريقة الدفع عبر الإنترنت",
  whatIsThisAndWhyDoINeedIt: "ما هذا ولماذا احتاجه؟",
  offlineOnlyMenuGroupIdHint_1:
    "هذه مجموعة قائمة إضافية تجمع جميع عناصر القائمة المتاحة لعمليات إضافة النقاط داخل الفرع. يجب أن تكون هذه المجموعة من القوائم حصرية لكل مطعم",
  offlineOnlyMenuGroupIdHint_2:
    " قد تحتاج إلى هذا الإعداد إذا كان حساب نقاط البيع هذا مشتركًا مع علامات تجارية أخرى لـ كوينز وإذا كان هذا المطعم يستخدم قوائم مختلفة داخل الفرع بالإنترنت مقارنة بقائمة تطبيق كوينز. يضمن تعيين هذا المعرف تعيين النقاط  المستلمة بشكل صحيح إلى العلامة التجارية المناسبة لـ كوينز.",
  offlineOnlyMenuGroupId: "معرف مجموعة القائمة المخصصة للعمليات داخل الفرع",
  offlineOnlyMenuGroupIdPlaceholder: "رجاءًا قم بإدخال معرف مجموعة القائمة المخصصة للعمليات داخل الفرع",
  optional: "(اختياري)",
};