import * as React from "react";
import styles from  "./styles.module.sass";
import { hot } from "react-hot-loader";
import { RouteComponentProps, withRouter } from "react-router";
import Aux from "../Leaderboard/AuxComponent";

class NotFound extends React.Component<RouteComponentProps> {
  constructor(props) {
    super(props);
  }
  public reternToHome = () => {
    this.props.history.push(`/`);
  };

  public render() {
    return (
      <Aux>
        <h1 className={styles.forOFor}>404</h1>
        <h1 className={styles.text}>Not Found</h1>
        <button onClick={this.reternToHome} className={styles.btn}>
          Back to home
        </button>
      </Aux>
    );
  }
}

export default hot(module)(withRouter(NotFound));
