import { ICreateCashier } from "../../../../axios/createCashier";
import { IEditCashierData } from "../../../../axios/editCashier";
import { ICashier } from "../../../../axios/getCashiers";
import { useMemo, useState, useEffect } from "react";
import { useProgressState } from "../../../../Utils";
// import { notify } from "react-notify-toast";
import { processCashierBranches } from "./index";
import Strings from "../../../../i18n/strings/cashiers";
import { TranslationFunction } from "react-i18next";
import { ICreateCallCenterCashier } from "../../../../axios/addCallCenterCashier";
import { IBranch } from "../../../../types/shared";

export interface IGenericCUDForm<FormValues = any> {
  titleText: string;
  buttonText?: string;
  onSubmit?: (values: FormValues, ...args: any[]) => Promise<any>;
  readOnly?: boolean;
  initialValues?: FormValues;
}

export interface ICashierCUDForm {
  [x: string]: IGenericCUDForm<
    ICreateCashier | ICreateCallCenterCashier | IEditCashierData | ICashier
  >;
}

const VIEW = "VIEW";
type VIEW = typeof VIEW;
const EDIT = "EDIT";
type EDIT = typeof EDIT;
const ADD = "ADD";
type ADD = typeof ADD;
const RESTORE = "RESTORE";
type RESTORE = typeof RESTORE;

export type FormTypes = VIEW | EDIT | ADD | RESTORE;

export const DELETED_IN_BRAND = 0;
type DELETED_IN_BRAND = typeof DELETED_IN_BRAND;
export const EXISTS_IN_BRAND = 1;
type EXISTS_IN_BRAND = typeof EXISTS_IN_BRAND;
export const EXISTS_IN_ANOTHER_BRAND = 2;
type EXISTS_IN_ANOTHER_BRAND = typeof EXISTS_IN_ANOTHER_BRAND;
export const CAN_ADD_ANEW = 3;
type CAN_ADD_ANEW = typeof CAN_ADD_ANEW;

export type CASHIER_ACCOUNT_STATUS =
  | DELETED_IN_BRAND
  | EXISTS_IN_BRAND
  | EXISTS_IN_ANOTHER_BRAND
  | CAN_ADD_ANEW;

export const useCheckCashierPhone = (
  currentFormType: FormTypes,
  code: CASHIER_ACCOUNT_STATUS,
  cashier: ICashier | null
) => {
  const [cashierData, setCashierData] = useState<ICashier | undefined>();
  useEffect(() => {
    if (currentFormType === ADD || currentFormType === RESTORE) {
      if ((code === DELETED_IN_BRAND || code === EXISTS_IN_BRAND) && cashier) {
        setCashierData(cashier);
      } else {
        setCashierData(undefined);
      }
    }
  });
  return {
    cashierData,
  };
};

type cashierPromisingFn = (
  cashier: ICreateCashier | IEditCashierData
) => Promise<any>;
export const useCashierCRUD = (
  createCashier: cashierPromisingFn,
  addCallCenterCashier: cashierPromisingFn,
  editCashier: cashierPromisingFn,
  restoreCashier: cashierPromisingFn,
  t: TranslationFunction,
  getBranches: () => void,
  submissionSucceeded: boolean,
  addEditCashierOpen: boolean
) => {
  const [formType, setFormType] = useState<FormTypes>(ADD);

  const [selectedCashier, selectCashier] = useState<ICashier | undefined>();

  const { loading, setFailure, setLoading, setSuccess } = useProgressState();

  const cashierFormsMap: ICashierCUDForm = useMemo(
    () => ({
      [ADD]: {
        submittingData: loading,
        titleText: t(Strings.addCashier),
        onSubmit: async (values, isCallCenter: boolean) => {
          setLoading();
          const addFn = isCallCenter ? addCallCenterCashier : createCashier;
          await addFn(values as ICreateCashier)
            .catch(setFailure)
            .then(() => {
              setSuccess();
              getBranches();
            });
          selectCashier(undefined);
        },
        buttonText: t(Strings.addCashier),
      },
      [EDIT]: {
        submittingData: loading,
        titleText: t(Strings.editCashier),
        buttonText: t(Strings.editCashier),
        onSubmit: async (values) => {
          setLoading();
          await editCashier(values as IEditCashierData)
            .catch(setFailure)
            .then(() => {
              setSuccess();
              if (submissionSucceeded) {
                getBranches();
                selectCashier(undefined);
              }
            });
        },
        initialValues: selectedCashier,
      },
      [VIEW]: {
        readOnly: true,
        titleText: t(Strings.viewCashier),
        initialValues: selectedCashier,
      },
      [RESTORE]: {
        submittingData: loading,
        titleText: "Restore Cashier",
        buttonText: "Restore Cashier",
        onSubmit: async (values) => {
          setLoading();
          await restoreCashier(values as IEditCashierData)
            .catch(setFailure)
            .then(() => {
              setSuccess();
              getBranches();
            });
          selectCashier(undefined);
        },
        initialValues: selectedCashier,
      },
    }),
    [
      selectedCashier,
      createCashier,
      editCashier,
      restoreCashier,
      loading,
      setFailure,
      setLoading,
      setSuccess,
      getBranches,
    ]
  );

  const setFormToEditCashier = (cashier: ICashier) => {
    setFormType(EDIT);
    selectCashier({ ...cashier, password: undefined });
  };
  const setFormToViewCashier = (cashier: ICashier) => {
    setFormType(VIEW);
    selectCashier({ ...cashier, password: undefined });
  };
  const setFormToRestoreCashier = (cashier: ICashier) => {
    setFormType(RESTORE);
    selectCashier({ ...cashier, password: undefined });
  };
  const setFormToAddCashier = () => {
    setFormType(ADD);
    selectCashier(undefined);
  };
  useEffect(() => {
    if (!addEditCashierOpen) {
      setFormType(ADD);
      selectCashier(undefined);
    }
  }, [addEditCashierOpen]);
  return {
    formType,
    currentForm: cashierFormsMap[formType],
    setFormToAddCashier,
    setFormToEditCashier,
    setFormToViewCashier,
    setFormToRestoreCashier,
  };
};

export const useFilterCashiers = (
  cashiers: ICashier[],
  cashiersFilters: { query: string; unassigned: boolean; assigned: boolean },
  selectedBranchId: string
) => {
  const { query, unassigned, assigned } = cashiersFilters;
  const LCQuery = query.toLowerCase().trim();
  return useMemo(
    () =>
      cashiers.filter((c) => {
        let shouldFilter = true;
        if (selectedBranchId) {
          shouldFilter =
            shouldFilter && c.branch_ids.some((b) => b === selectedBranchId);
        }
        const hasBranches = !!c.branch_ids && !!c.branch_ids.length;
        shouldFilter = assigned ? shouldFilter && hasBranches : shouldFilter;
        shouldFilter = unassigned ? shouldFilter && !hasBranches : shouldFilter;
        shouldFilter =
          shouldFilter &&
          (c.name.toLowerCase().includes(LCQuery) ||
            (c.phoneNumber || "").toLowerCase().includes(LCQuery));
        return shouldFilter;
      }),
    [cashiers, cashiersFilters, selectedBranchId]
  );
};

type cashierTakingFn = (cashier: ICashier) => void;
type noArgsForMeThanks = () => void;

export const useCashierChangeFormType = (
  cashierAccountStatus: CASHIER_ACCOUNT_STATUS,
  setFormToEditCashier: cashierTakingFn,
  setFormToRestoreCashier: cashierTakingFn,
  setFormToAddCashier: noArgsForMeThanks,
  branchesMap: { [branchId: string]: IBranch },
  cashier?: ICashier
) => {
  useEffect(() => {
    switch (cashierAccountStatus) {
      case DELETED_IN_BRAND:
        return (
          cashier &&
          setFormToRestoreCashier(
            processCashierBranches(cashier as ICashier, branchesMap)
          )
        );
      case EXISTS_IN_BRAND:
        return (
          cashier &&
          setFormToEditCashier(
            processCashierBranches(cashier as ICashier, branchesMap)
          )
        );
      case CAN_ADD_ANEW:
        return setFormToAddCashier();
      // case EXISTS_IN_ANOTHER_BRAND: {
      //   notify.show('Cashier already exists in a different brand, They need to be deleted from that brand', 'error');
      // }
    }
  }, [cashierAccountStatus, cashier]);
};

export function useUpdateState<S>(initialState): [S, (a) => void] {
  const [state, setState] = useState<S>(initialState);
  return [state, (a) => setState({ ...state, ...a })];
}

interface ICashiersFilters {
  assigned: boolean;
  unassigned: boolean;
  query: string;
}

export const useCashiersFilters = (
  cashiers: ICashier[],
  selectedBranchId: string,
  handleSelectedBranchId: (value: string) => void
) => {
  const [
    cashiersFilters,
    setCashiersFilters,
  ] = useUpdateState<ICashiersFilters>({
    assigned: false,
    unassigned: false,
    query: "",
  });
  const { assigned, query, unassigned } = cashiersFilters;
  useEffect(() => {
    setCashiersFilters({ assigned: false, unassigned: false, query: "" });
  }, [selectedBranchId]);
  useEffect(() => {
    handleSelectedBranchId("");
  }, [assigned, query, unassigned]);
  const filteredCashiers = useFilterCashiers(
    cashiers,
    cashiersFilters,
    selectedBranchId
  );

  useEffect(() => {
    if (!query) {
      setCashiersFilters({ query: "" });
    }
  }, [query]);
  const handleAllClicked = () =>
    setCashiersFilters({ assigned: false, unassigned: false, query: "" });
  const handleAssignedClicked = () =>
    setCashiersFilters({ assigned: true, unassigned: false, query: "" });
  const handleUnassignedClicked = () =>
    setCashiersFilters({ assigned: false, unassigned: true, query: "" });
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCashiersFilters({ query: e.target.value });

  return {
    filteredCashiers,
    handleAllClicked,
    handleAssignedClicked,
    handleUnassignedClicked,
    handleQueryChange,
    assigned,
    query,
    unassigned,
  };
};
