import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { TimeFilter } from "..";
import { BranchesPerformanceTable } from "./components";
import styles from "./styles.module.sass";
import { useIsVisible } from "react-is-visible";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../Components/WizardComponents/Branches/AddBranchForm/AddZones/RenderZonesFieldArray/useTranslation";
const BranchesPerformance: React.FC = () => {
  const today = new Date();
  const { t } = useTranslation("home");
  const branchesPerformanceRef = useRef<any>();
  const branchPerformanceVisible = useIsVisible(branchesPerformanceRef);
  const [requestConfigs, setRequestConfigs] = useState<{
    to: number;
    from: number;
  }>({
    to: new Date().getTime(),
    from: new Date(today.getTime() - 28 * 60 * 60 * 24 * 1000).getTime(),
  });

  const handleTimeFilterChange = (value) => {
    setRequestConfigs(value);
  };
  return (
    <div
      ref={branchesPerformanceRef}
      className={`${styles.main} ${useRtlClass(styles)}`}
    >
      <div className={styles.headerWrapper}>
        <span className={styles.headerTitle}>
          {t(Strings.branches_performance)}
        </span>
        <TimeFilter showArrow={true} onChange={handleTimeFilterChange} />
      </div>
      <BranchesPerformanceTable
        branchPerformanceVisible={branchPerformanceVisible}
        {...requestConfigs}
      />
      <NavLink className={styles.analyzeBranches} to={"/insights/branches"}>
        {t(Strings.analyze_branches)}
        <img
          className={`${styles.arrowIcon}`}
          src={
            require("../../../../../../assets/feather _orange- chevron-right.svg")
              .default
          }
        />
      </NavLink>
    </div>
  );
};

export default BranchesPerformance;
