import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IBranch } from "../types/shared";

export const editBranch = (token, data): AxiosReturn<IBranch> => {
  return axios.put(Requests.brancheByID(data.id), data, {
    headers: {
      token,
    },
  });
};
