export default {
  pickupBranches: "فروع ال Ordering",
  menu: "قائمة الطعام",
  brandSettings: "اعدادات المتجر",
  step: "1.1 خطوة",
  setupPickup: "تنصيب خدمة Ordering",
  pickupBranchesDescription: " قم بأختيار الفروع التى تريد تنصيب البيكب فيها",
  brandSettingsDescription: "قم بتنصيب تصنيفات ومنتجات قائمتك",
  menuDescription: "قم بتنصيب اعدادات الفرع",
  //
};
