import { translate } from "../../../../../../helpers/translate";
import Strings from "../../../../../../i18n/Manager/strings/home";
const BAR_CHART_ID = "customer-visits-bar-chart";
const CHART_TYPE = "bar";
const toolTipWrapperTableStyles =
  "position: relative; left: 10px; top: -10px; width: 156px; height: 52px; background-color: #FFFFFF; color: #8E8E93; border: 1px solid #E5E5EA; box-sizing: border-box; box-shadow: 0px 2px 4px rgba(142, 142, 147, 0.12); font-size: 12px; font-weight: bold; padding: 8px;";
const t = translate("home");
const TOOLTIP = {
  enabled: false,
  external: (context) => {
    const { tooltip } = context;
    const indexedDataset =
      tooltip && Array.isArray(tooltip.dataPoints)
        ? tooltip.dataPoints[0]
        : null;

    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = `<table style="${toolTipWrapperTableStyles}"></table>`;
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip (when hover leaves)
    let tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    // Set Text
    if (tooltipModel.body && tooltip && Array.isArray(tooltip.dataPoints)) {
      let innerHtml = `<thead style="width:308px;">`;
      innerHtml += "</thead><tbody>";

      innerHtml += `<tr><td style="color: black; width: 100%; text-align: "center"">
      <span style="font-size: 12px; margin: 0 8px; font-weight: bold; color: #8E8E93; font-size: 12px; padding-top: 8px;">${
        tooltip?.dataPoints[0]?.dataset?.data[tooltip?.dataPoints[0]?.dataIndex]
          .date
      }</span>
      <div style="display: flex; flex-direction: row; max-width:308px; width: 308px padding: 24px;">
      <span style="width: 254px; font-size: 12px; margin:0 8px;">
        ${t(Strings.returning, {
          percentage:
            tooltip?.dataPoints[0]?.dataset?.data[
              tooltip?.dataPoints[0]?.dataIndex
            ].value,
        })}</span>
            <span style="width: 254px; font-size: 12px; margin:0 8px; margin-bottom: 8px;"> ${t(
              Strings.new,
              {
                percentage:
                  tooltip?.dataPoints[1]?.dataset?.data[
                    tooltip?.dataPoints[1]?.dataIndex
                  ].value,
              }
            )}</span>
 
      </div>
      
      </td>
       </tr>`;

      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      if (tableRoot) tableRoot.innerHTML = innerHtml;
    }

    let position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";
    // tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + "px " + tooltipModel.padding + "px";
    tooltipEl.style.pointerEvents = "none";
  },
};

const SCALES = {
  x: {
    stacked: true,
    barThickness: 34,
    grid: {
      display: false,
    },
    ticks: {
      autoSkip: true,
      maxTicksLimit: 12,
    },
  },
  y: {
    stacked: true,
    grid: {
      display: true,
      color: "#F2F2F7",
    },
    ticks: {
      callback: function (value, index, values) {
        return value >= 1000 ? `${value / 1000}k` : value;
      },
      stepSize: 1,
      precision: 10,
      crossAlign: "near",
      autoSkip: true,
      maxTicksLimit: 8,
    },
    min: 0,
  },
};

const PARSING = {
  xAxisKey: "label",
  yAxisKey: "value",
};

const OPTIONS = {
  parsing: PARSING,
  responsive: true,
  plugins: {
    tooltip: TOOLTIP,
    legend: {
      display: false,
    },
  },
  scales: SCALES,
};

export { BAR_CHART_ID, CHART_TYPE, OPTIONS };
