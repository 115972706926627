import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ICashier } from "./getCashiers";

export interface ICreateCallCenterCashier {
  name: string;
  phoneNumber: string;
  password: string;
}

export const addCallCenterCashier = (
  token: string,
  cashier: ICreateCallCenterCashier
): AxiosReturn<{ cashier: ICashier }> => {
  return axios.post(Requests.callCenterCashier, cashier, {
    headers: {
      token,
    },
  });
};
