import React from "react";
interface IProps {
  clicked: boolean;
}
const OrdersIcon = ({ clicked }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={clicked ? "#FFA351" : "#AEAEB2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 1.667L2.5 5v11.666c0 .442.176.866.488 1.179.313.313.737.488 1.179.488h11.666c.442 0 .866-.175 1.179-.488.312-.313.488-.736.488-1.178V5L15 1.667H5zM2.5 5h15"
      />
      <path
        stroke={clicked ? "#FFA351" : "#AEAEB2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 8.334c0 .884-.351 1.731-.976 2.357-.625.625-1.473.976-2.357.976-.884 0-1.732-.351-2.357-.976-.625-.626-.976-1.473-.976-2.357"
      />
    </svg>
  );
};

export default OrdersIcon;
