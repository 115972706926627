import React, { useState } from "react";
import styles from "./styles.module.sass";
import { Collapse } from "antd";
import SubMenuTitle from "../SubMenuTitle/index";
import "./styles.less";
import i18n from "../../../../i18n/i18n";
import { Chevron } from "./chevron";
interface IProps {
  title: string;
  collapsed: boolean;
  icon: React.ReactNode;
  key: string;
  notification?: boolean;
  clicked: boolean;
  active: boolean;
}
const SubMenu: React.FC<IProps> = ({
  children,
  title,
  icon,
  active,
  collapsed,
  clicked,
  key,
}) => {
  const [activeKey, setActiveKey] = useState();
  const collapseCallBack = (current_key) => {
    setActiveKey(current_key);
  };
  return (
    <div
      className={
        key === "settings" ? styles.settingsAnchorTag : styles.anchorTag
      }
    >
      {collapsed ? (
        <Collapse
          className={
            i18n.language === "ar"
              ? "side-bar-collapse-rtl"
              : "side-bar-collapse"
          }
          activeKey={activeKey}
          onChange={collapseCallBack}
          bordered={false}
          expandIcon={({ isActive }) => (
            <div
              style={{
                display: "flex !important",
                alignSelf: "center !important",
              }}
            >
              <Chevron
                pathStyle={{
                  stroke: active ? "#ffa351" : "#8E8E93",
                }}
                style={{
                  width: "16px",
                  height: "16px",
                  transform: isActive
                    ? "rotate(90deg)"
                    : i18n.language === "ar"
                    ? "rotate(180deg)"
                    : "",
                }}
              />
              {/* </svg> */}
            </div>
          )}
        >
          <Collapse.Panel
            // showArrow={false}

            className={
              clicked
                ? i18n.language === "ar"
                  ? "active-rtl-ant-collapse-header"
                  : "active-ant-collapse-header"
                : i18n.language === "ar"
                ? "rtl-sidebar-ant-collapse-header"
                : "sidebar-ant-collapse-header"
            }
            header={
              <SubMenuTitle
                clicked={clicked}
                title={title}
                icon={icon}
                collapsed={collapsed}
              />
            }
            key={key}
          >
            {collapsed ? <React.Fragment>{children}</React.Fragment> : null}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <SubMenuTitle
          clicked={clicked}
          title={title}
          icon={icon}
          collapsed={collapsed}
        />
      )}
    </div>
  );
};

export default SubMenu;
