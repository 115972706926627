import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IUser } from "../Components/BrandSettings/DashboardUsers/users-hooks";

export const userProfileAPI = (token): AxiosReturn<IUser> => {
  return axios.get(Requests.userProfile, {
    headers: {
      token,
    },
  });
};
