import * as React from "react";

import styles from "./App.module.sass";
import Notifications from "react-notify-toast";

import { HashRouter, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import { UpdateNotifier } from "./shopx-shared-components";

import LoadingBar from "react-redux-loading-bar";
// import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import { Route } from "react-router";
import AuthenticatedRoutes from "./Components/AuthenticatedRoutes";
import StoreData from "./contexts/StoreData";
import { useContext, useEffect, useState } from "react";
import { IStore } from "./types";
import { PrivateRoute } from "./Components/PrivateRoute";
import BreadCrumbs, { ICrumbData } from "./contexts/BreadCrumbs";

import isEqual from "lodash/isEqual";
import { getCashiers } from "./redux-store/actions";
import { connect, useDispatch } from "react-redux";

import { translate } from "react-i18next";
import { IUser } from "./Components/BrandSettings/DashboardUsers/users-hooks";
import { useGetCriticalReviewsNumber } from "./hooks/get-critical-reviews-number";
import { loadableWithBounce } from "./Components/LoadableRoute";
import { CriticalReviewsNumber, useRtlClass } from "./lib";
import "./App.less";
import { authenticateSocket } from "./pusher/authenticateSocket";
import { useCheckNetwork } from "./hooks/useCheckNetwork";
import { MuiThemeProvider } from "@material-ui/core";
import { MaterialUITheme } from "./theme.material";
import Sidebar from "./Components/Sidebar/index";
import { ConfigProvider } from "antd";
import i18n from "./i18n/i18n";
import { useWindowSize } from "./hooks/useWindowSize";
import arLocale from "antd/lib/locale/ar_EG";
import enLocale from "antd/lib/locale/en_US";
import { getStoreSettings } from "./Components/Pickup/redux-store/actions/StoreSettings";
import { pusher } from "./pusher/pusher";
import { getOrderingQRPortalStatus } from "./Components/Pickup/redux-store/actions/Portal";

const SignUp = loadableWithBounce(() => import("./Components/SignUp"));
const LoginFormPage = loadableWithBounce(
  () => import("./Components/Login/login")
);
const DashboardPreview = loadableWithBounce(
  () => import("./Components/Login/PreviewMode/authentication")
);

interface IState {
  storeData: IStore | null;
  isLoggedIn: boolean;
  token: string;
  signUpComplete: boolean;
}

interface IProps {
  getCashiers: () => void;
}

const AuthenticatedWrapper = (props: IProps) => {
  useEffect(() => {
    props.getCashiers();
  }, []);
  const { criticalNumbers, update } = useGetCriticalReviewsNumber(false);
  const [crumbs, setCrumbs] = useState<ICrumbData[]>([]);
  const { signUpComplete } = useContext(StoreData);

  const updateCrumbs = React.useCallback(
    (data) => {
      if (!isEqual(data, crumbs)) {
        setCrumbs(data);
      }
    },
    [setCrumbs, crumbs]
  );
  const windowSize = useWindowSize();

  return signUpComplete ? (
    <div className={styles.mainSection}>
      <CriticalReviewsNumber.Provider value={{ criticalNumbers, update }}>
        <Sidebar />
        {windowSize.width > 1152 && <div className={styles.navBar} />}
        <div className={styles.secondarySection}>
          <BreadCrumbs.Provider value={{ crumbs, setCrumbs: updateCrumbs }}>
            <Header />
            <AuthenticatedRoutes />
            <UpdateNotifier appName={"Koinz Dashboard"} />
          </BreadCrumbs.Provider>
        </div>
      </CriticalReviewsNumber.Provider>
    </div>
  ) : (
    <Route component={SignUp} />
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCashiers: () => dispatch(getCashiers()),
});

const ConnectedAuthRoutes = connect(
  null,
  mapDispatchToProps
)(AuthenticatedWrapper);

const App: React.FC = () => {
  const [state, setState] = useState<IState>({
    storeData: null,
    token: "",
    isLoggedIn: false,
    signUpComplete: false,
  });
  const rtlClass = useRtlClass(styles);
  const setStoreData = (
    store: IStore,
    token: string,
    signUpComplete: boolean
  ) => {
    setState({
      storeData: store,
      isLoggedIn: true,
      token,
      signUpComplete,
    });
  };
  const [userData, setUserData] = useState<IUser | null>(null);
  const { token, isLoggedIn, storeData } = state;
  const isOnline = useCheckNetwork();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getStoreSettings());
      dispatch(getOrderingQRPortalStatus());
    }
  }, [token]);
  useEffect(() => {
    authenticateSocket(
      token,
      storeData?.id as string,
      isLoggedIn,
      `presence-live_updates`,
      isOnline
    );
  }, [token, isLoggedIn, storeData, isOnline]);

  useEffect(() => {
    const socket = pusher(token, storeData?.id as string);

    return () => {
      socket.unsubscribe(`presence-file_export.${storeData?.id}`);
    };
  }, [storeData]);

  const selectedLocale = i18n.language.includes("ar") ? arLocale : enLocale;

  return (
    <MuiThemeProvider theme={MaterialUITheme}>
      <ConfigProvider
        locale={selectedLocale}
        direction={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <HashRouter>
          <div className={`${styles.App} ${rtlClass}`}>
            <LoadingBar style={{ backgroundColor: "yellow" }} />
            <div style={{ fontSize: "2rem" }}>
              <Notifications
                options={{ zIndex: 2000, wrapperId: "notificationsWrapper" }}
              />
            </div>
            <StoreData.Provider
              value={{ ...state, setStoreData, userData, setUserData }}
            >
              <LoadingBar style={{ backgroundColor: "yellow" }} />

              <Switch>
                <Route
                  exact={true}
                  path="/(login|signup)"
                  component={LoginFormPage}
                />
                <Route
                  exact={true}
                  path="/preview/auth"
                  component={DashboardPreview}
                />

                <Route
                  exact={true}
                  path="/auth/login"
                  component={LoginFormPage}
                />
                <PrivateRoute component={ConnectedAuthRoutes} />
              </Switch>
            </StoreData.Provider>
          </div>
        </HashRouter>
      </ConfigProvider>
    </MuiThemeProvider>
  );
};

export default hot(module)(translate("")(App));
