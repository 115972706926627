export default {
  categoryTitle: "Categories",
  // Add Category
  addCategory: "Add Category",
  createNewCategory: "Add New Category",
  CancelButton: "Cancel",
  submitButton: "Add",
  arabicCategoryNamePlaceholder: "مثال : الافطار",
  englishCategoryNamePlaceholder: "Ex: Breakfast",
  notificationsAddedToCategories: "Added to categories",
  notificationsFailedToCategories: "Not added to categories",
  labelEnglishCategoryName: "Name in English",
  labelArabicCategoryName: "Name in Arabic",
  labelCategoryIntegrationID: "Integration Id",
  categoryIntegrationIDPlaceholder: "Integration Id",
  isBundle: "is an offer ?",
  categoryUpdated: "Category updated successfully !",
  // Edit Category
  editCategoryTitle: "Edit Category",
  editCategory: "Edit Category",
  editCategoryCancelButton: "Cancel",
  editCategorySubmitButton: "Save Edits",
  editCategoryArabicCategoryNamePlaceholder: "الافطار",
  editCategoryenglishCategoryNamePlaceholder: "Breakfast",
  editCategoryLabelEnglishCategoryName: "Name in English",
  editCategoryLabelArabicCategoryName: "Name in Arabic",

  // Delete Category
  delete: "delete",
  category: "category",
  warning: "Warning you’re about to delete",
  items: "items",
  errorCat: "This process cannot be undone",
  deleteCategorySubmitButton: "Delete Category",
  deleteCategoryCancelButton: "Cancel",
};
