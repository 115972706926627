const DOUGHNUT_CHART_ID = "gender-segment-doughnut-chart";
const CHART_TYPE = "doughnut";
const BORDER_COLOR = ["#ffd1a8", "#ffa351"];
const BACKGROUND_COLOR = ["#ffd1a8", "#ffa351"];
const HOVER_COLOR = ["#ffd1a8", "#ffa351"];
const PLACEHOLDER_COLOR = ["#F2F2F7", "#F2F2F7"];

const toolTipWrapperTableStyles =
  "position: relative; left: 10px; top: -10px; width: 100%; height: 52px; background-color: #FFFFFF; color: #8E8E93; border: 1px solid #E5E5EA; box-sizing: border-box; box-shadow: 0px 2px 4px rgba(142, 142, 147, 0.12); font-size: 12px; padding: 8px; ";

const TOOLTIP = {
  enabled: false,
  external: (context) => {
    const { tooltip } = context;
    const indexedDataset =
      tooltip && Array.isArray(tooltip.dataPoints)
        ? tooltip.dataPoints[0]
        : null;

    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = `<table style="${toolTipWrapperTableStyles}"></table>`;
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip (when hover leaves)
    let tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");

    // Set Text
    if (tooltipModel.body && indexedDataset) {
      let innerHtml = `<thead>`;
      innerHtml += "</thead><tbody>";

      innerHtml += `<tr><td style="color: black; width: 100%; text-align: "center"">
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; padding-left: 8px; padding-right: 8px;" >
      <div style="display: flex; flex-direction: row; max-width:100%; width: 100% padding: 24px;">
      <span style="font-size: 12px; margin-right: 4px;">${
        indexedDataset.dataset?.labels[indexedDataset.dataIndex]
      }:</span><span style="font-size: 12px">${
        indexedDataset.dataset?.counts[indexedDataset.dataIndex]
      } (${indexedDataset.dataset?.data[indexedDataset.dataIndex]}%) </span>
       </div>
      
       </div>
      </td>
       </tr>`;

      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      if (tableRoot) tableRoot.innerHTML = innerHtml;
      let position = context.chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = indexedDataset.dataset.enableTooltip
        ? "1"
        : "0";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left =
        position.left + window.pageXOffset + tooltipModel.caretX + "px";
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + "px";
      // tooltipEl.style.font = bodyFont.string;
      tooltipEl.style.padding =
        tooltipModel.padding + "px " + tooltipModel.padding + "px";
      tooltipEl.style.pointerEvents = "none";
    }
  },
};

const OPTIONS = {
  responsive: true,
  plugins: {
    tooltip: TOOLTIP,
    legend: {
      display: false,
    },
  },
};

export {
  DOUGHNUT_CHART_ID,
  CHART_TYPE,
  BORDER_COLOR,
  HOVER_COLOR,
  PLACEHOLDER_COLOR,
  OPTIONS,
  BACKGROUND_COLOR,
};
