export default {
  search: "Search for item name, item description",
  noItemAdded: "“No item added”",
  noItemAddedDescription:
    " “No item added” - add one category at least to be able to add items",
  addNewItem: "Add new item",
  deleteItem: "Delete Item",
  editItem: "Edit Item",
  duplicateItem: "Duplicate Item",
  delete: "Delete",
  item: "Item",
  cancel: "Cancel",
  itemAvailableInBranches:
    "This item available in {{branches_count}} branches:",
  itemNotAvailableInBranches:
    "This item not available in {{branches_count}} branches:",
  itemAvailableInBranch: "This item available in branch:",
  itemNotAvailableInBranch: "This item not available in branch:",
  moreBranches: "+{{more_branches}} more branches",
  availableIn: "Available in: {{branches}}",
  unavailableIn: "Unavailable in: {{branches}}",
  confirm: "Confirm",
  duplicateTitle: "Duplicate  ({{item_name}}) item ",
  duplicateConfirm: " Do you want to duplicate ({{item_name}})",
};
