import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IIdentifiedObject, IStoreItem } from "../types/wizard-types";
import { IOptimisticObject } from "../types/shared";

export interface IEditLoyaltyItemData
  extends IIdentifiedObject,
    IOptimisticObject {
  is_in_loyalty_program: number;
  name: string;
}

interface IEditLoyaltyItemRequestData extends IIdentifiedObject {
  is_in_loyalty_program: number;
  name?: string;
}

export const editLoyaltyItem = (
  token,
  data: IEditLoyaltyItemRequestData
): AxiosReturn<IStoreItem> => {
  return axios.put(Requests.storeItem(data.id), data, {
    headers: {
      token,
    },
  });
};
