import { call, takeLatest, put, select } from "redux-saga/effects";
import {
  getMenuScrapingStateFailure,
  getMenuScrapingStateSuccess,
} from "../actions/menu-scraping-actions";
import { selectToken } from "../../../../redux-store/selectors";
import { getMenuScrapingStateApi } from "../../axios/getMenuScrapingState";
import { getMenuScrapingStateAction } from "../../constants/actions";

function* getMenuScrapingStateSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getMenuScrapingStateApi, token);
    yield put(getMenuScrapingStateSuccess(res.data));
  } catch (e) {
    yield put(getMenuScrapingStateFailure(e));
  }
}

export function* watchMenuScrapingStateSaga() {
  yield takeLatest(
    getMenuScrapingStateAction.requested,
    getMenuScrapingStateSaga
  );
}
