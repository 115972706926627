import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { orderAction } from "../../constants/actions";
import { ISpecificOrder } from "../actions/lib";
import { getOrderSuccess } from "../actions/past-order-actions";

interface IState {
  order: ISpecificOrder;
}

const initialState: IState = {
  order: {
    branch_id: "",
    created_at: 0,
    estimated_customer_arrival_interval: 0,
    id: "",
    name: "",
    order_code: "",
    status: "",
    store_id: "",
    total_invoices: 0,
    updated_at: 0,
    delivery_fees: 0,
    delivery_time: 0,
    country_code: 0,
    delivery_zones: [],
  },
};

export const orderReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getOrderSuccess, (state: IState, { payload }) => ({
        ...state,
        order: payload,
      })),
    ] as any,
    initialState
  ),
  orderAction
);
