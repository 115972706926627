export default {
  accepted: "Preparing",
  got_picked_up: "Got picked up",
  ready_for_pickup: "Ready for pickup",
  received: "Received by branch",
  rejected: "Rejected",
  seen: "Seen by branch",
  today: "Today",
  _30days: "30 days",
  sent: "Sent to branch",
  orders: "Orders",
  endMessage: " You have seen it all",
  canceled_by_agent: "Canceled",
  canceled_by_customer: "Canceled by customer",
  canceled_by_cashier: "Canceled",
  rejected_for_payment_failure: "Rejected for payment failure",
  order_received: "Received",
  customer_no_show: "No show",
  scheduled: "Scheduled",
  redemption: "Gift redemption code:",
  custom: "Custom",
  pickDate: "Pick a date ...",
  delivered: "Delivered",
  out_for_delivery: "Out for delivery",
  netProfit: "Total sales",
  time: "Time",
  clientName: "Customer Name",
  orderId: "Order ID",
  orderType: "Order Type",
  brand: "Brand",
  branch: "Branch",
  all: "All",
  orderTypeSelected: "Order Type:",
  branches: "Branches:",
  timeSelected: "Start from:",
  price: "Price",
  orderState: "Order state",
  pastOrders: "Past orders",
  name: "Name",
  yesterday: "Yesterday",
  _3Days: "3 days",
  _7Days: "7 days",
  _20Days: "20 days",
  pickup: "Pickup",
  delivery: "Delivery",
  drive_thru: "Drive thru",
  orderDetails: "Order Details",
  orderTracking: "Order Tracking",
  rejectionReason: "Rejection Reason",
  paymentMethod: "Payment Method",
  noCodeApplied: "No code applied",
  promoCode: "Promo Code",
  order: "Order {{order}}",
  totalInvoice: "Total Invoice",
  orderHistory: "Order History",
  cash: "Cash",
  empty: "No orders have been found",
  visa: "VISA",
  placeholder: "Search by order id, phone number",
  orderHistoryTitle: "See your ordering service results",
  brandInformation: "Brand Information",
  branchAddress: "Branch Address",
  branchAppVersion: "Branch app version",
  branchLocationOnMap: "Branch location on map",
  deliveryInformation: "Delivery Information",
  deliveryCost: "Delivery Cost",
  deliveryTime: "Delivery time",
  deliveryZones: "Delivery zones",
  min: "min",
  seeMoreTrans: " See {{seeMore}} more zones",
  cancel: "Cancel",
  userInformation: "User Information",
  totalOrders: "Total orders: {{totalOrders}}",
  totalOrdersForStatistics: "Total number of orders",
  customerAddress: "Customer Address",
  sales: "Analytics",
  country: "Country",
  city: "City",
  district: "District",
  zoneAndStreet: "Zone/Street",
  buildingNo: "Building no.",
  floorNo: "Floor no.",
  apartmentNo: "Apartment no.",
  comment: "Comment",
  deliverFees: "Deliver Fees",
  canceled_by_courier: "Canceled",
  online: "Online",
};
