import axios from "../axios";
import { AxiosReturn } from "../types";
import * as Requests from "../requests";
import { IStoreItem } from "../../types/wizard-types";
import { EditLoyaltyMenuItemPayload } from "../../redux-store/actions";

export const editLoyaltySpecialItemApi = (
  token,
  data: EditLoyaltyMenuItemPayload & { state: number }
): AxiosReturn<IStoreItem> => {
  return axios.put(Requests.specialItemsByID(data.id), data, {
    headers: {
      token,
    },
  });
};
