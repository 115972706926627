import { useQuery } from "react-query";
import {
  getCustomersVisitsChartReq,
  getCustomersVisitsOverviewReq,
} from "../../../axios/Manager/Analytics";

// overview
export const useGetCustomersVisitsOverview = (token, requestConfigs) => {
  const result = useQuery(["customersVisitsOverview", requestConfigs], () => {
    return getCustomersVisitsOverviewReq(token, {
      ...requestConfigs,
    }).then((res) => res.data);
  });

  return result;
};

// chart
export const useGetCustomersVisitsChart = (token, requestConfigs) => {
  const result = useQuery(["customersVisitsChart", requestConfigs], () => {
    if (!requestConfigs.store_id) return [];
    return getCustomersVisitsChartReq(token, {
      ...requestConfigs,
    }).then((res) => res.data);
  });

  return result;
};
