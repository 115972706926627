import { Divider, Drawer } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./styles.less";
import styles from "./styles.module.sass";
import LargeLogo from "../../assets/logo_on-dark.svg";
import SmallLogo from "../../assets/icons_on-dark-small.svg";
import {
  HomeIcon,
  AdsManagerIcon,
  FeedbackIcon,
  LeaderboardIcon,
  CustomersIcon,
} from "./icons";
import {
  CollapseSettings,
  CollapseInsights,
  CollapseBrandListings,
} from "./components";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CollapseOrders from "./components/CollapseOrders/index";
import NotificationIcon from "../../assets/notification.svg";
import { CriticalReviewsNumber, useRtlClass } from "../../lib";
import StoreData from "../../contexts/StoreData";
import { useRecoilState } from "recoil";
import { collapseAtom } from "../../Components/atoms";
import { hamburgerAtom } from "../../Components/atoms/index";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useTranslation } from "../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../i18n/strings/navigation";
import i18n from "../../i18n/i18n";
import { useUserAccess } from "../AuthenticatedRoutes";

const Sidebar: React.FC = () => {
  const {
    hasAccessToLeaderboard,
    hasFullAccess,
    hasAccessToBranches,
    hasPickUpEnabled,
  } = useUserAccess();
  const [collapsed, setCollapsed] = useRecoilState(collapseAtom);
  const [showSidebarIcon, setShowSidebarIcon] = useState(false);
  const [visible, setVisible] = useRecoilState(hamburgerAtom);
  const { criticalNumbers } = useContext(CriticalReviewsNumber);
  const { token, storeData } = useContext(StoreData);
  const { t } = useTranslation("navigation");
  const location = useLocation();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (visible) {
      setCollapsed(true);
      setShowSidebarIcon(true);
    }
  }, [visible]);

  const handleMouseOver = () => {
    if (windowSize.width > 1152) {
      setCollapsed(true);
      setShowSidebarIcon(true);
    }
  };

  const handleMouseLeave = () => {
    if (windowSize.width > 1152) {
      setCollapsed(false);
      setShowSidebarIcon(false);
    }
  };

  const activeNavigate = (key) => {
    return location.pathname.includes(key);
  };

  const rtl = useRtlClass(styles);

  const sidebarContent = (
    <div
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className={
        collapsed && showSidebarIcon ? styles.navbarWrapper : styles.navbar
      }
    >
      <NavLink to={"/home"} className={styles.logoWrapper}>
        <div className={styles.largeLogoWrapper}>
          {collapsed && showSidebarIcon ? (
            <img className={styles.largeIcon} src={LargeLogo} />
          ) : (
            <img src={SmallLogo} />
          )}
        </div>
      </NavLink>
      <Divider className={"sidebar-divider"} />
      <div style={{ marginBottom: "23.5px" }} />
      <div className={styles.navbarMain}>
        <NavLink
          to={"/home"}
          className={`${
            activeNavigate("home")
              ? collapsed && showSidebarIcon
                ? styles.activeNavLink
                : styles.activeNavLinkCollapsed
              : collapsed && showSidebarIcon
              ? styles.navLinkCollapsed
              : styles.navLink
          } ${rtl}`}
        >
          <HomeIcon clicked={activeNavigate("home")} />
          {collapsed && showSidebarIcon ? (
            <span className={styles.navTitle}>{t(Strings.home)}</span>
          ) : null}
        </NavLink>
        <CollapseInsights
          collapsed={collapsed && showSidebarIcon}
          activeNavigate={(value) => activeNavigate(value)}
        />
        {hasPickUpEnabled && (
          <CollapseOrders
            collapsed={collapsed && showSidebarIcon}
            activeNavigate={(value) => activeNavigate(value)}
          />
        )}
        <NavLink
          to={"/reviews"}
          className={`${
            activeNavigate("reviews")
              ? collapsed && showSidebarIcon
                ? styles.activeNavLink
                : styles.activeNavLinkCollapsed
              : collapsed && showSidebarIcon
              ? styles.navLinkCollapsed
              : styles.navLink
          } ${rtl}`}
        >
          <FeedbackIcon clicked={activeNavigate("reviews")} />
          {collapsed ? (
            <div className={styles.feedbackWrapper}>
              <span className={styles.navTitle}>{t(Strings.feedback)}</span>
              {!!criticalNumbers ? (
                <img src={NotificationIcon} className={styles.notification} />
              ) : null}
            </div>
          ) : null}
        </NavLink>

        {hasFullAccess && (
          <CollapseBrandListings
            collapsed={collapsed && showSidebarIcon}
            activeNavigate={(value) => activeNavigate(value)}
          />
        )}

        <a
          target={"_blank"}
          href={`
            ${process.env.REACT_APP_KOINZ_REACH_URL}${
            JSON.parse(localStorage.getItem("isKoinzAdminRemote") as any)
              ? `/#/login?token=${token}&store_id=${storeData?.id}&is_remote_authentication=true`
              : `/#/login?token=${token}`
          }
        `}
          className={`${
            activeNavigate("reach")
              ? collapsed && showSidebarIcon
                ? styles.activeNavLink
                : styles.activeNavLinkCollapsed
              : collapsed && showSidebarIcon
              ? styles.navLinkCollapsed
              : styles.navLink
          } ${rtl}`}
        >
          <AdsManagerIcon clicked={activeNavigate("reach")} />
          {collapsed && showSidebarIcon ? (
            <span className={styles.navTitle}>{t(Strings.adsManager)}</span>
          ) : null}
        </a>
        {hasAccessToLeaderboard && (
          <NavLink
            to={"/leaderboard"}
            className={`${
              activeNavigate("leaderboard")
                ? collapsed && showSidebarIcon
                  ? styles.activeNavLink
                  : styles.activeNavLinkCollapsed
                : collapsed && showSidebarIcon
                ? styles.navLinkCollapsed
                : styles.navLink
            } ${rtl}`}
          >
            <LeaderboardIcon clicked={activeNavigate("leaderboard")} />
            {collapsed && showSidebarIcon ? (
              <span className={styles.navTitle}>{t(Strings.leaderBoard)}</span>
            ) : null}
          </NavLink>
        )}
        <div style={{ marginBottom: "18.5px" }} />
        {hasAccessToBranches && (
          <React.Fragment>
            <Divider className={"sidebar-divider"} />
            <div style={{ marginTop: "18.5px" }} />
            <CollapseSettings
              canAccessBranches={hasAccessToBranches}
              collapsed={collapsed && showSidebarIcon}
              hasFullAccess={hasFullAccess}
              activeNavigate={(value) => activeNavigate(value)}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );

  return windowSize.width <= 1152 && visible ? (
    <Drawer
      className={"sidebar-drawer"}
      style={{
        backgroundColor: "transparent !important",
      }}
      width={"auto"}
      visible={visible}
      onClose={() => setVisible(false)}
      placement={i18n.language === "ar" ? "right" : "left"}
    >
      {sidebarContent}
    </Drawer>
  ) : windowSize.width > 1152 ? (
    <>{sidebarContent}</>
  ) : (
    <div />
  );
};

export default Sidebar;
