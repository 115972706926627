export default {
  required: "required",
  atLeastOneField: "atLeastOneField",
  isEmpty: "isEmpty",
  maxLength: "maxLength",
  minLength: "minLength",
  number: "number",
  minValue: "minValue",
  maxValue: "maxValue",
  image: "image",
  url: "url",
  mustBeNumber: "mustBeNumber",
  mustBeInteger: "mustBeInteger",
  range: "range",
  location: "location",
  vat: "vat",
};
