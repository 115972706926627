export default {
  toUnlockThisGift: "To unlock this gift",
  customerWillPay: "Customer will pay",
  somethingWentWrong: "Something went wrong!",
  cashToPointsRateTitle: "Cash-to-Points Rate",
  cashToPointsRateSubtitle:
    "How much of what your customers pay will turn into points.",
  ifYourRateIs: "If your rate is:",
  notRecommended: "Not recommended",
  recommended: "Recommended",
  markComplete: "Mark as complete",
  progressAutoSaved: "Your progress will be autosaved",
};
