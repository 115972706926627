import axios from "../axios";
import * as Requests from "../requests";
export const deletePointsOperationAPI = async (token, operation_id) =>
  await axios.post(
    Requests.deletePointsOperationURL,
    { operation_id },
    {
      headers: { token },
      baseURL: process.env.REACT_APP_ENDPOINT,
    }
  );
