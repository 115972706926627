import { useQuery } from "react-query";
import { getCriticalNumbers } from "../../../axios/getCriticalNumbers";
import { getEmojies } from "../../../axios/getEmojies";
import { getOverallRating } from "../../../axios/getOverallRating";
import { getReviewsTotalNumber } from "../../../axios/getReviewsTotalNumber";
import { getLiveUpdatesHistory } from "../../../axios/Manager/Analytics";

export const useGetRecentReviews = (token, requestConfigs) => {
  const result = useQuery(
    ["recent-reviews", requestConfigs],
    () => {
      return getLiveUpdatesHistory(token, {
        ...requestConfigs,
        filter: ["reviews"],
      }).then((res) => res.data);
    },
    {
      cacheTime: 0,
    }
  );

  return result;
};

export const useGetOverallRating = (token) => {
  const result = useQuery("overall-rating", () => {
    return getOverallRating(token).then((res) => res.data);
  });

  return result;
};

export const useGetCriticalReviewsNumbers = (token) => {
  const result = useQuery("critical-number", () => {
    return getCriticalNumbers(token).then((res) => res.data);
  });

  return result;
};

export const useGetRatesNumbers = (token) => {
  const result = useQuery("rates-number", () => {
    return getEmojies(token).then((res) => res.data);
  });

  return result;
};

export const useGetTotalReviewsNumbers = (token) => {
  const result = useQuery("total-number", () => {
    return getReviewsTotalNumber(token).then((res) => res.data);
  });

  return result;
};
