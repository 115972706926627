import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { getExtraInfoAction } from "../../constants/actions";
import {
  getExtraInfo,
  getExtraInfoFailure,
  getExtraInfoSuccess,
} from "../actions/past-order-actions";
import { getExtraInfoAPI } from "../../axios/getExtraInfoAPI";

const actionType = union({ getExtraInfo } as any);
function* extraInfoSaga(action: any) {
  const token = yield select(selectToken);
  try {
    const res = yield call(getExtraInfoAPI, token, action.payload);
    yield put(getExtraInfoSuccess(res.data));
  } catch (e) {
    yield put(getExtraInfoFailure(e));
  }
}

export function* watchExtraInfoSaga() {
  yield takeLatest(getExtraInfoAction.requested, extraInfoSaga);
}
