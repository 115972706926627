export default {
  onBoardingStepsTitle: "onBoardingStepsTitle",
  subtitlePart1: "subtitlePart1",
  subtitlePart2: "subtitlePart2",
  dataAnalyticsTitle: "dataAnalyticsTitle",
  feedbackToolTitle: "feedbackToolTitle",
  loyaltyProgramTitle: "loyaltyProgramTitle",
  dataAnalyticsDescriptionPart1: "dataAnalyticsDescriptionPart1",
  dataAnalyticsDescriptionPart2: "dataAnalyticsDescriptionPart2",
  feedbackToolDescriptionPart1: "feedbackToolDescriptionPart1",
  feedbackToolDescriptionPart2: "feedbackToolDescriptionPart2",
  feedbackToolDescriptionPart3: "feedbackToolDescriptionPart3",
  loyaltyProgramDescriptionPart1: "loyaltyProgramDescriptionPart1",
  loyaltyProgramDescriptionPart2: "loyaltyProgramDescriptionPart2",
  next: "next",
  back: "back",
};
