import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getStoreViralitySettingsApi } from "../../../axios";
import { getStoreViralitySettingsAction } from "../../../constants";
import { IStoreViralitySettings } from "../../../types/wizard-types";
import {
  getStoreViralitySettingsFailure,
  getStoreViralitySettingsSuccess,
} from "../../actions";
import { selectToken } from "../../selectors";

function* getStoreViralitySettingsSaga() {
  try {
    const token = yield select(selectToken);
    const res: AxiosResponse<IStoreViralitySettings> = yield call(
      getStoreViralitySettingsApi,
      token
    );
    yield put(getStoreViralitySettingsSuccess(res.data));
  } catch (error) {
    yield put(getStoreViralitySettingsFailure(error as Error));
  }
}

export function* watchGetStoreViralitySettingsSaga() {
  yield takeLatest(
    getStoreViralitySettingsAction.requested,
    getStoreViralitySettingsSaga
  );
}
