export default {
  extraPointsExtraOrders: "نقاط اضافية تعني المزيد من الطلبات الاضافية!",
  extraPointsExtraOrdersDescription:
    "فعّل خاصية مشاركة النقاط الآن! اسمح للمستخدمين بإرسال نقاط إضافية إلى أصدقائهم للحصول على مستخدمين جدد والمزيد من الطلبات!",
  pointsType: "نوع النقاط",
  points: "عدد نقاط ثابت",
  percentage: "نسبة من النقاط",
  pointsAmount: "قيمة النقاط",
  save: "حفظ",
  recommendedGiftPoints: "عدد النقاط المقترحة كهدية",
  getNewCustomersBySendingX:
    "احصل على عملاء جدد عن طريق إرسال {{points}} نقاط تساوي قيمة الهدية الأولى.",
  pointsTile: "{{points}} نِقاط",
  twoPoints: "نقطتان",
  beforeTenPoints: "{{points}} نقطة",
  point: "{{points}} نقطة",
  allowWelcomingPointsDescription: "عزّز رضا العملاء وولاءهم من خلال تفعيل ميزة 'النقاط الترحيبية'! تأكد من أن كل مستخدم جديد يحصل على أول هدية له بعد أول عملية شراء، مما يخلق تجربة لا تُنسى تشجعه على العودة مرات عديدة والانخراط بشكل أعمق مع علامتك التجارية. فعّل هذه الميزة لبناء عملاء مخلصين من اليوم الأول!",
  allowWelcomingPoints: 'السماح بنقاط الترحيب'
};
