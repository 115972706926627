import axios from "./axios";
import * as Requests from "./requests";

export const deleteOrderingMenuItem = (token: string, id: string) => {
  return axios.delete(Requests.DELETE_ITEM_API(id), {
    headers: {
      token,
    },
  });
};
