import obs from "./onBoardingSteps";
import crs from "./convertingRatio";

export default {
  title: "الاسم ووصف المتجر",
  subtitle: "الآن، أخبرنا بالمزيد عن متجرك:",
  brandNameTitle: "اسم متجرك",
  brandNamePlaceholder: "اسم المتجر",
  arBrandNameTitle: "اسم متجرك باللغة العربية",
  arBrandNamePlaceholder: "اسم متجرك باللغة العربية",
  brandBioTitle: "نبذة عن المتجر",
  brandBioPlaceholder:
    "يمكنك هنا التحدث عن تاريخ المتجر، نوع المنتجات التي تقدمها، وما يمكنك أن تقدمه لعملائك…",
  optional: "اختياري",
  needHelp: "تحتاج المساعدة؟",
  next: obs.next,
  progressAutoSaved: crs.progressAutoSaved,
};
