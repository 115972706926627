export default {
  searchPlaceholder: "Search by branch name",
  notExistItem: "Item doesn’t exist",
  branchSelected: "Selected branches",
  branches: "Branches",
  newBranch: "Add new branch",
  next: "Next",
  description: "Select branches that you want to enable pickup in",
  branchName: "Branch name",
  disableAll: "Disable all",
  disableOrderingQ:
    "Are you sure you want to disable ordering in your restaurant ?",
  disableOrdering: "Disable ordering",
  confirm: "Confirm",
  cancel: "Cancel",
};
