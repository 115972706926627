/* eslint-disable no-restricted-globals */
import { useState, useEffect, useMemo, useCallback } from "react";

const versionLink =
  (process.env.REACT_APP_ADMIN_ENDPOINT as string) +
  `/api/admin/app-version/${process.env.REACt_APP_APP_NAME}`;
interface IAppVersionData {
  app_name: string;
  created_at: number;
  id: string;
  updated_at: number;
  app_version: string;
}

const getAppVersion = (): Promise<IAppVersionData> => {
  return fetch(versionLink).then((res) => res.json());
};

export const useAppVersion = () => {
  const localVersion = useMemo(
    () => localStorage.getItem("app-version-" + process.env.REACt_APP_APP_NAME),
    []
  );
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [latestVersion, setLatestVersion] = useState(localVersion || "");

  useEffect(() => {
    getAppVersion()
      .then((data) => {
        setShouldUpdate(localVersion !== data.app_version);
        setLatestVersion(data.app_version);
      })
      .catch((e) => console.error(e));
  });

  const update = useCallback(() => {
    if (shouldUpdate) {
      localStorage.setItem(
        "app-version-" + process.env.REACt_APP_APP_NAME,
        latestVersion
      );
      setTimeout(() => {
        location.reload(true);
      });
    }
  }, [latestVersion, shouldUpdate]);

  return {
    shouldUpdate,
    update,
  };
};
