import { FC, Fragment, memo, useMemo, useRef, useState } from "react";
import styles from "./styles.module.sass";
import PlusIcon from "../../../../assets/ic_plus.svg";
import AddBannerCardModal from "../AddBannerCardModal";
import { EditIcon } from "../../../../assets/white-label";
import {
  BannerCard,
  selectBannerCard,
  setBannerCardModalVisibility,
} from "../../../../redux-store/actions/brand-visuals-actions";
import { useDispatch } from "react-redux";
import { useSelect } from "../../../../hooks/useSelect";
import { IDnDInjectedProps } from "../../../../Components/Pickup/SetupPickup/Menus/MenuPage/MenuItems/Items";
import { useRtlClass } from "../../../../lib";
import strings from "../../../../i18n/strings/brandVisuals";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
type DraggableBannerCardProps = {
  bannerCard: BannerCard;
} & IDnDInjectedProps;
const DraggableCard: FC<DraggableBannerCardProps> = memo(
  ({
    bannerCard,
    dndIntegration: {
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
    },
  }) => {
    const rtl = useRtlClass(styles);
    const { t } = useTranslation("brandVisuals");
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    connectDragPreview(ref.current);
    const { selectedBannerCard } = useSelect(
      (state) => state.bannerCardsReducer
    );
    const dispatch = useDispatch();

    const handleBannerCardSelect = (order: number, id: string) => {
      dispatch(selectBannerCard({ banner_card_id: id, order }));
    };
    const handleOpenAddBannerPopup = (order, id) => {
      dispatch(setBannerCardModalVisibility(true));
      setVisible(true);
      dispatch(selectBannerCard({ banner_card_id: id, order }));
    };
    const handleCloseAddBannerPopup = () => {
      dispatch(setBannerCardModalVisibility(false));
      setVisible(false);
    };

    const isSelectedBannerCard = useMemo(() => {
      return selectedBannerCard?.id && selectedBannerCard?.id === bannerCard.id;
    }, [selectedBannerCard?.id, bannerCard.id]);

    return (
      <Fragment>
        <AddBannerCardModal
          onClose={handleCloseAddBannerPopup}
          visible={visible}
          bannerCard={bannerCard}
        />

        {connectDropTarget(
          <div
            onClick={() =>
              bannerCard.image && bannerCard.id
                ? handleBannerCardSelect(bannerCard.order, bannerCard.id)
                : handleOpenAddBannerPopup(bannerCard.order, bannerCard.id)
            }
            key={bannerCard.order}
            className={`${styles.cardWrapper} ${rtl}`}
          >
            {connectDragSource(
              <div
                ref={ref}
                className={
                  isSelectedBannerCard ? styles.selectCard : styles.card
                }
                style={{
                  opacity: isDragging ? 0.2 : 1,
                  outline: bannerCard.image ? "none" : "",
                }}
              >
                <img
                  src={bannerCard.image ? bannerCard.image : PlusIcon}
                  alt="Add banner image"
                  className={bannerCard.image ? styles.image : styles.plusIcon}
                />
              </div>
            )}

            {bannerCard.image && (
              <button
                onClick={() =>
                  handleOpenAddBannerPopup(bannerCard.order, bannerCard.id)
                }
                className={styles.editBtn}
              >
                <img className={styles.editIcon} src={EditIcon} />
                {t(strings.edit)}
              </button>
            )}
          </div>
        )}
      </Fragment>
    );
  }
);

export default DraggableCard;
