import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";
import StoreData from "../../../../../../contexts/StoreData";
import { Queries } from "../../../../../../Queries/Manager";
import { numberFormatter } from "../../../../../../Utils/numbers";
import { ReviewCard } from "./components/ReviewCard";
import { pusher } from "../../../../../../pusher/pusher";
// icons
import GraySadIcon from "../../../../../../assets/gray-sad.svg";
import GrayStarIcon from "../../../../../../assets/gray-star.svg";
import BadIcon from "../../../../../../assets/reviews/bad.svg";
import TerribleIcon from "../../../../../../assets/reviews/terrible.svg";
import OkayIcon from "../../../../../../assets/reviews/okay.svg";
import GreatIcon from "../../../../../../assets/reviews/great.svg";
import LovelyIcon from "../../../../../../assets/reviews/lovely.svg";
// style
import styles from "./styles.module.sass";
//
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useRtlClass } from "../../../../../../lib";
import { useIsVisible } from "react-is-visible";
import { useHistory } from "react-router";

const Reviews: React.FC = () => {
  //
  const { t } = useTranslation("home");
  const { token, storeData } = useContext(StoreData);
  const reviewsDiv: any = useRef<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [newRecords, setNewRecords] = useState<any[]>([]);
  const [filters, setFilters] = useState<{ page: number; size: number }>({
    page: 1,
    size: 20,
  });
  const [receivedTransaction, setReceivedTransaction] =
    useState<boolean>(false);
  const [recentReviews, setRecentReviews] = useState<any[]>([]);
  const reviewsRef = useRef(null);
  const reviewsVisible = useIsVisible(reviewsRef);
  // Queries
  const { data, status, refetch } = Queries.useGetRecentReviews(token, filters);
  const {
    data: overallRating,
    status: overallRatingStatus,
    refetch: overallRatingRefetch,
  } = Queries.useGetOverallRating(token);
  const {
    data: criticalNumbers,
    status: criticalNumbersStatus,
    refetch: criticalNumbersRefetch,
  } = Queries.useGetCriticalReviewsNumbers(token);
  const {
    data: ratesNumbers,
    status: ratesNumbersStatus,
    refetch: ratesNumbersRefetch,
  } = Queries.useGetRatesNumbers(token);
  const {
    data: totalRatings,
    status: totalRatingsStatus,
    refetch: totalRatingsRefetch,
  } = Queries.useGetTotalReviewsNumbers(token);
  useEffect(() => {
    if (!data && status === "idle" && reviewsVisible) {
      refetch();
    }
    if (!overallRating && overallRatingStatus === "idle" && reviewsVisible) {
      overallRatingRefetch();
    }
    if (!totalRatings && totalRatingsStatus === "idle" && reviewsVisible) {
      totalRatingsRefetch();
    }
    if (!ratesNumbers && ratesNumbersStatus === "idle" && reviewsVisible) {
      ratesNumbersRefetch();
    }
    if (
      !criticalNumbers &&
      criticalNumbersStatus === "idle" &&
      reviewsVisible
    ) {
      criticalNumbersRefetch();
    }
  }, [reviewsVisible]);
  useEffect(() => {
    const socket = pusher(token, storeData?.id as string);
    const channel = socket.subscribe(`presence-live_updates.${storeData?.id}`);

    channel.bind("new_review", (res) => {
      setReceivedTransaction(true);
      setRecentReviews((prev) => [res, ...prev]);
    });

    return () => {
      socket.unsubscribe(`presence-live_updates.${storeData?.id}`);
    };
  }, []);

  useEffect(() => {
    if (status === "success") {
      // pagination controls
      if (data.length < filters.size) {
        setHasMore(false);
      }

      setRecentReviews((prev) => [...prev, ...data]);
    }
  }, [status, data]);

  const loadMore = () => {
    setTimeout(() => {
      setFilters((f) => ({ ...filters, page: f.page + 1 }));
    }, 1000);
  };

  const scrollToTop = () => {
    reviewsDiv.current.scrollIntoView();
    setReceivedTransaction(false);
  };

  let loaderIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
  const { push } = useHistory();
  const solveIssues = () => {
    push("/reviews/issues");
  };
  return (
    <div
      className={`${styles.wrapper} ${useRtlClass(styles)}`}
      ref={reviewsRef}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <span className={styles.heading}>{t(Strings.customer_feedback)}</span>
          <span className={styles.totalRatings}>
            {numberFormatter(totalRatings?.total_number)} {t(Strings.reviews)}
          </span>
        </div>
        <div className={styles.numbers}>
          <div className={`${styles.number} ${styles.firstNumber}`}>
            <img src={GrayStarIcon} style={{ width: 56, height: 56 }} />
            <span className={styles.key}>{t(Strings.overall_rating)}</span>
            <span className={styles.value}>
              {overallRating?.overall.toFixed(1)} / 5
            </span>
          </div>
          <div className={styles.number}>
            <img src={GraySadIcon} style={{ width: 56, height: 56 }} />
            <span className={styles.key}>{t(Strings.unhappy_customers)}</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span className={styles.value}>
                {criticalNumbers?.critical_reviews_number || 0}
              </span>
              <div onClick={solveIssues} className={styles.solveWrapper}>
                <span className={styles.solveText}>
                  {t(Strings.solveIssue)}
                </span>
                <img
                  src={
                    require("../../../../../../assets/feather _orange- chevron-right.svg")
                      .default
                  }
                  className={styles.solveArrowIcon}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.feedbackOverview}>
        {/* rates fixed */}
        {/* Lovely */}
        <div className={styles.rateInsights}>
          <div className={styles.emoji}>
            <Tooltip placement="bottom" title={"Lovely"}>
              <img src={LovelyIcon} className={styles.image} />
            </Tooltip>
          </div>
          {ratesNumbers?.reviews_rates_number && (
            <div className={styles.text}>
              <span className={styles.number}>
                {ratesNumbers?.reviews_rates_number["5"]}
              </span>
              <span className={styles.percentage}>
                {(
                  (Number(ratesNumbers?.reviews_rates_number["5"]) /
                    Number(totalRatings?.total_number)) *
                  100
                ).toFixed(1) || ""}{" "}
                %
              </span>
            </div>
          )}
        </div>
        {/* Great */}
        <div className={styles.rateInsights}>
          <div className={styles.emoji}>
            <Tooltip placement="bottom" title={"Great"}>
              <img src={GreatIcon} className={styles.image} />
            </Tooltip>
          </div>
          {ratesNumbers?.reviews_rates_number && (
            <div className={styles.text}>
              <span className={styles.number}>
                {ratesNumbers?.reviews_rates_number["4"]}
              </span>
              <span className={styles.percentage}>
                {(
                  (Number(ratesNumbers?.reviews_rates_number["4"]) /
                    Number(totalRatings?.total_number)) *
                  100
                ).toFixed(1) || ""}{" "}
                %
              </span>
            </div>
          )}
        </div>
        {/* Okay */}
        <div className={styles.rateInsights}>
          <div className={styles.emoji}>
            <Tooltip placement="bottom" title={"Ok"}>
              <img src={OkayIcon} className={styles.image} />
            </Tooltip>
          </div>
          {ratesNumbers?.reviews_rates_number && (
            <div className={styles.text}>
              <span className={styles.number}>
                {ratesNumbers?.reviews_rates_number["3"]}
              </span>
              <span className={styles.percentage}>
                {(
                  (Number(ratesNumbers?.reviews_rates_number["3"]) /
                    Number(totalRatings?.total_number)) *
                  100
                ).toFixed(1) || ""}{" "}
                %
              </span>
            </div>
          )}
        </div>
        {/* Bad */}
        <div className={styles.rateInsights}>
          <div className={styles.emoji}>
            <Tooltip placement="bottom" title={"Bad"}>
              <img src={BadIcon} className={styles.image} />
            </Tooltip>
          </div>
          {ratesNumbers?.reviews_rates_number && (
            <div className={styles.text}>
              <span className={styles.number}>
                {ratesNumbers?.reviews_rates_number["2"]}
              </span>
              <span className={styles.percentage}>
                {(
                  (Number(ratesNumbers?.reviews_rates_number["2"]) /
                    Number(totalRatings?.total_number)) *
                  100
                ).toFixed(1) || ""}{" "}
                %
              </span>
            </div>
          )}
        </div>
        {/* Terrible */}
        <div className={styles.rateInsights}>
          <div className={styles.emoji}>
            <Tooltip placement="bottom" title={"Terrible"}>
              <img src={TerribleIcon} className={styles.image} />
            </Tooltip>
          </div>
          {ratesNumbers?.reviews_rates_number && (
            <div className={styles.text}>
              <span className={styles.number}>
                {ratesNumbers?.reviews_rates_number["1"]}
              </span>
              <span className={styles.percentage}>
                {(
                  (Number(ratesNumbers?.reviews_rates_number["1"]) /
                    Number(totalRatings?.total_number)) *
                  100
                ).toFixed(1) || ""}{" "}
                %
              </span>
            </div>
          )}
        </div>
        {/*  */}
      </div>
      <div className={styles.recentReviews}>
        <span className={styles.title}>{t(Strings.recent_reviews)}</span>

        <div className={styles.scroll} id={"scrollbarDivForReviews"}>
          <InfiniteScroll
            dataLength={recentReviews.length}
            next={loadMore}
            scrollableTarget={"scrollbarDivForReviews"}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "24px",
                }}
              >
                <Spin indicator={loaderIcon} />
              </div>
            }
          >
            {recentReviews.map((review, i) => (
              <div
                className={`${styles.card}`}
                ref={i === 0 ? reviewsDiv : null}
                key={i}
              >
                <ReviewCard
                  branch={review.data.branch}
                  customer={review.data.customer}
                  data={review.data.review}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={styles.bottomBar}>
        <NavLink className={styles.analyzeCustomers} to={"/reviews"}>
          {t(Strings.analyze_feedback)}
          <img
            className={styles.arrowIcon}
            src={
              require("../../../../../../assets/feather _orange- chevron-right.svg")
                .default
            }
          />
        </NavLink>
      </div>
    </div>
  );
};

export default Reviews;
