import React, { useMemo, useState } from "react";
import {
  Menu,
  Dropdown,
  Button,
  DatePicker,
  Space,
  Divider,
  Popover,
  Alert,
} from "antd";
import ArrowIcon from "../../../../../../assets/down-arrow.svg";
import moment from "moment";
// styles
import styles from "./styles.module.sass";
// hooks
import { useSelect } from "../../../../../../hooks/useSelect";
import Arrow from "../../../../../../Components/Pickup/assets/feather _ chevron-down.svg";
import { translate } from "../../../../../../helpers/translate";
import Strings from "../../../../../../i18n/Manager/strings/home";
import "./styles.less";
export interface ITimeFilterProps {
  onChange: (
    value: { from: number; to: number; unit: "day" | "hour" },
    filterUnit: "month" | "year"
  ) => void;
  showArrow?: boolean;
}

export interface IFilter {
  label: string;
  hasDividerBefore: boolean;
  isSeeMoreItem: boolean;
  id: string;
  sort: number;
}

const t = translate("home");

const menuOptions: IFilter[] = [
  {
    label: t(Strings.last_date, {
      unit: t(Strings.months),
      count: 6,
    }),
    hasDividerBefore: false,
    id: "last-6-months",
    isSeeMoreItem: true,
    sort: 0,
  },
];

const yearFilterOption = {
  label: t(Strings.years_range),
  hasDividerBefore: true,
  id: "years",
  isSeeMoreItem: false,
  sort: NaN,
};

const YearsandMonthsTimeFilter: React.FC<ITimeFilterProps> = (props) => {
  const { RangePicker } = DatePicker;

  const [state, setState] = useState({
    isYearsRangeValid: true,
    yearsRangeRawVal: null,
    selectedFilter: menuOptions[0],
    seeMoreClicked: false,
    isDropDownVisible: false,
    isYearsFilterActive: false,
  });

  const [yearsRangeReqConfigs, setYearsRangeReqConfigs] = useState<{
    from: number;
    to: number;
    unit: "hour" | "day";
  }>({
    from: 0,
    to: 0,
    unit: "hour",
  });

  const handleOptionSelect = (e) => {
    // get selected item key
    const { key } = e;
    if (key === "years") {
      setState((prevValue) => ({
        ...prevValue,
        isYearsFilterActive: true,
        isDropDownVisible: false,
        yearsRangeRawVal: null,
        seeMoreClicked: false,
        selectedFilter: yearFilterOption,
      }));
      return;
    }

    const selectedItemObj = [...menuOptions, yearFilterOption].find(
      (item) => item.id === key
    );

    if (
      selectedItemObj &&
      !["years", "more-preset-ranges"].includes(selectedItemObj.id)
    ) {
      const processedVal = mapSelectedFilterToTimestamp(selectedItemObj);
      // pass processed value up to parent
      props.onChange(processedVal, "month");

      setState((prevValue) => ({
        ...prevValue,
        selectedFilter: selectedItemObj,
        isDropDownVisible: false,
        isYearsFilterActive: false,
        yearsRangeRawVal: null,
        seeMoreClicked: false,
      }));
      return;
    }
  };

  const {
    store: { created_at },
  } = useSelect((state) => {
    return {
      ...state.storeReducer,
      ...state.authReducer,
    };
  });

  const mapSelectedFilterToTimestamp = (filter: IFilter) => {
    let from = 0,
      to = 0,
      unit: "hour" | "day" = "hour";

    const today = new Date();

    const timestampOfADay = 60 * 60 * 24 * 1000;

    const now = new Date().getTime();
    const difference = now - created_at;

    const daysFromStoreCreation = difference / timestampOfADay;

    switch (filter.id) {
      case "last-6-months":
        from =
          daysFromStoreCreation <= 180
            ? created_at
            : new Date(today.getTime() - 180 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      default:
        break;
    }

    return {
      from,
      to,
      unit,
    };
  };

  const menuItems = useMemo(() => {
    return [...menuOptions].map((item) => {
      return (
        <>
          {item.hasDividerBefore && <Menu.Divider />}
          <Menu.Item key={item.id}>
            {item.label}{" "}
            {item.id === "more-preset-ranges" && (
              <img
                src={ArrowIcon}
                alt=""
                style={{
                  marginLeft: "100px",
                  transform: state.seeMoreClicked ? "rotate(-90deg)" : "",
                }}
              />
            )}
          </Menu.Item>{" "}
        </>
      );
    });
  }, [state.seeMoreClicked]);

  const menu = (
    <Menu style={{ width: "266px" }} onClick={handleOptionSelect}>
      {menuItems}
    </Menu>
  );

  const handleRangePickerChange = (value: any) => {
    setState((prevValue) => ({
      ...prevValue,
      yearsRangeRawVal: value,
    }));
    if (value) {
      const from = alignToHeadOfYear(moment(value[0]).unix() * 1000);
      const to = alignToTailOfYear(moment(value[1]).unix() * 1000);

      const yearTimestampValue = 1000 * 60 * 60 * 24 * 365;

      //    max valid  time range is 5 years
      const isRangeValid = Math.abs(from - to) <= yearTimestampValue * 5.1;

      setState((prevValue) => ({
        ...prevValue,
        isYearsRangeValid: isRangeValid,
      }));

      // a day should be subtracted from the 'from' value
      // so the response will include the whole start day
      setYearsRangeReqConfigs({
        from: from,
        to,
        unit: "day",
      });
    }
  };

  const alignToHeadOfYear = (timestamp: number) => {
    const date = new Date(new Date(timestamp).getFullYear(), 0, 1);

    return date.getTime();
  };

  const alignToTailOfYear = (timestamp: number) => {
    const date = new Date(new Date(timestamp).getFullYear(), 11, 31);

    return date.getTime();
  };

  const handleYearsFilterApply = () => {
    const { from, to, unit } = yearsRangeReqConfigs;
    props.onChange(
      {
        from,
        to,
        unit,
      },
      "year"
    );

    const modifiedFilterLabel = getModifiedFilterLabel(from, to);
    setState((prevValue) => ({
      ...prevValue,
      isYearsFilterActive: false,
      selectedFilter: {
        ...prevValue.selectedFilter,
        label: modifiedFilterLabel,
      },
    }));
  };

  const getModifiedFilterLabel = (from: number, to: number) => {
    return `${moment(from).format("YYYY")} - ${moment(to).format("YYYY")}`;
  };

  const yearsRangeWrapper = (
    <Space direction="vertical" size={2}>
      <RangePicker
        picker="year"
        onChange={handleRangePickerChange}
        value={state.yearsRangeRawVal}
      />
      {!state.isYearsRangeValid && (
        <Alert
          message="Range is not valid (Larger than 5 years)"
          type="error"
          showIcon
          className={styles.rangeErrorAlert}
        />
      )}
      <Divider className={styles.customRangeDivider} />
      <span className={styles.filterButtonsContainer}>
        {state.yearsRangeRawVal ? (
          <Button
            onClick={() => {
              setState((prevValue) => ({
                ...prevValue,
                isYearsRangeValid: true,
                yearsRangeRawVal: null,
              }));
            }}
          >
            Reset
          </Button>
        ) : (
          <Button
            onClick={() => {
              setState((prevValue) => ({
                ...prevValue,
                isYearsRangeValid: true,
                yearsRangeRawVal: null,
                isYearsFilterActive: false,
                isDropDownVisible: true,
              }));
            }}
          >
            {t(Strings.cancel)}
          </Button>
        )}

        <Button
          disabled={
            !state.isYearsRangeValid ||
            yearsRangeReqConfigs.from === 0 ||
            yearsRangeReqConfigs.to === 0
          }
          onClick={handleYearsFilterApply}
        >
          {t(Strings.apply)}
        </Button>
      </span>
    </Space>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomCenter"
      visible={!state.isYearsFilterActive && state.isDropDownVisible}
    >
      <Popover
        placement="bottom"
        content={yearsRangeWrapper}
        visible={state.isYearsFilterActive}
        trigger={["click"]}
      >
        <Button
          onClick={() => {
            setState((prevValue) => ({
              ...prevValue,
              isDropDownVisible: !prevValue.isDropDownVisible,
            }));
          }}
          className={"y-m-time-filter-btn"}
        >
          {t(Strings.time, {
            time: state.selectedFilter.label,
          })}

          {props.showArrow && (
            <img
              style={{ width: "1.2rem", height: "1.2rem", marginLeft: ".4rem" }}
              src={Arrow}
            />
          )}
        </Button>
      </Popover>
    </Dropdown>
  );
};

export default YearsandMonthsTimeFilter;
