import axios from "./axios";
import * as Requests from "./requests";
import { IGetSingleBranch } from "../types/shared";

export const getBranchById = (token, id) => {
  return axios.get<IGetSingleBranch>(Requests.brancheByID(id), {
    headers: {
      token,
    },
  });
};
