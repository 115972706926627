import React, { useContext, useEffect } from "react";
import styles from "./styles.module.sass";
// hooks
import { useDispatch } from "react-redux";
// antd
import { Row, Col } from "antd";
// components
import {
  WelcomeMessage,
  CustomersSegment,
  CustomersVisits,
  OverallProgress,
  HomeNavigationCards,
  BranchesPerformance,
  TodaysProgress,
} from "./components";
// actions
import { getStore, getBranches } from "../../../../redux-store/actions";
import { LiveUpdates } from "./components/LiveUpdates";
import Strings from "../../../../i18n/Manager/strings/home";
import "./styles.less";
import { useSelect } from "../../../../hooks/useSelect";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import i18n from "../../../../i18n/i18n";
import RocketTitleIcon from "../../../../assets/reviews/rocket-title.svg";
import Reviews from "./components/Reviews";
import { trackEvent } from "../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../constants/events-for-tracking";
import {
  AmplitudeEvents,
  amplitudeLogEvent,
} from "../../../../tracking/amplitude";
import StoreData from "../../../../contexts/StoreData";

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const store = useSelect((state) => state.storeReducer.store);
  const { storeData, userData } = useContext(StoreData);
  const { t } = useTranslation("home");
  useEffect(() => {
    dispatch(getStore());
    dispatch(getBranches());
  }, [dispatch]);

  useEffect(() => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed home page"]);
    amplitudeLogEvent(AmplitudeEvents.home_page, {
      user_name: userData?.name,
      user_id: userData?.id,
      user_role: userData?.title || userData?.role,
      store_id: storeData?.id,
      store_name: storeData?.name,
    });
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <Row>
        <Col span={24}>
          <WelcomeMessage />
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
          <TodaysProgress />
        </Col>
      </Row> */}
      <Row>
        <Col span={24} className={styles.overAllProgressWrapper}>
          <OverallProgress />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HomeNavigationCards />
        </Col>
      </Row>
      <Row justify={"space-between"} className={styles.performanceWrapper}>
        <Col span={24}>
          <div className={styles.performanceTitle}>
            <img src={RocketTitleIcon} className={styles.icon} />
            <span className={styles.text}>
              {t(Strings.store_performance, {
                store_name:
                  i18n.language === "ar" ? store.ar_name : store.en_name,
              })}
            </span>
          </div>
        </Col>
        <Col span={11}>
          <Reviews />
        </Col>
        <Col className={styles.performanceTable} span={13}>
          <BranchesPerformance />
        </Col>
      </Row>
      <Row>
        <Col span={24} className={styles.factsWrapper}>
          <div className={styles.factsContainer}>
            <div className={styles.factsIconWrapper}>
              <img
                src={
                  require("../../../../assets/reviews/icon_solid_search.svg")
                    .default
                }
                className={styles.factsIcon}
              />
            </div>
            <span className={styles.factsLabel}>
              {" "}
              {t(
                Strings.quick_facts_about,
                i18n.language === "ar"
                  ? { ar_store_name: store.ar_name }
                  : {
                      en_store_name: store.en_name,
                    }
              )}
            </span>
          </div>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col span={14}>
          <CustomersVisits />
          <CustomersSegment />
        </Col>
        <Col span={9}>
          <Row justify="center">
            <LiveUpdates />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
