import obs from "./onBoardingSteps";
import crs from "./convertingRatio";

export default {
  title: "Brand Info",
  subtitle: "Now, Let us know about your brand:",
  brandNameTitle: "Brand name",
  brandNamePlaceholder: "Brand name",
  arBrandNameTitle: "Brand name in Arabic",
  arBrandNamePlaceholder: "Brand name in Arabic",
  brandBioTitle: "Brand Bio",
  brandBioPlaceholder: "Tell us what is your brand about…",
  optional: "optional",
  needHelp: "Need Help?",
  next: obs.next,
  save: "Save",
  progressAutoSaved: crs.progressAutoSaved,
};
