import c from "./convertingRatio";

export default {
  menu: "menu",
  addMenuItems: "addMenuItems",
  addedItemsCount: "addedItemsCount",
  addItem: "addItem",
  syncPosMenu: "syncPosMenu",
  addCategory: "addCategory",
  markComplete: "markComplete",
  progressAutoSaved: "progressAutoSaved",
  uploadByExcel: "uploadByExcel",
  uploadMenu: "uploadMenu",
  uploadByElMenus: "uploadByElMenus",
  uploadByHungerStation: "uploadByHungerStation",
  uploadManually: "uploadManually",
  uploadExternally: "uploadExternally",
  itemName: "itemName",
  itemPhoto: "itemPhoto",
  itemPrice: "itemPrice",
  itemCategory: "itemCategory",
  itemIntegrationId: "itemIntegrationId",
  categoryIntegrationId: "categoryIntegrationId",
  newCategory: "newCategory",
  emptyCategories: "emptyCategories",
  categoryName: "categoryName",
  deleteCategoryTitle: "deleteCategoryTitle",
  youAreAboutToDelete: "youAreAboutToDelete",
  item: "item",
  inThisCategory: "inThisCategory",
  emptyItems: "emptyItems",
  somethingWentWrong: c.somethingWentWrong,
  addingMenu: "addingMenu",
  waitDontGo: "waitDontGo",
  grabbingData: "grabbingData",
  failed: "failed",
  editItem: "editItem",
  delete: "delete",
  addStoreItemSuccess: "addStoreItemSuccess",
  addStoreItemFailure: "addStoreItemFailure",
  youCanAddGiftListItemsFrom: "youCanAddGiftListItemsFrom",
  itemVisibility: "itemVisibility",
  syncOrderingMenuSuccess: "syncOrderingMenuSuccess",
  syncOrderingMenuFailure: "syncOrderingMenuFailure",
};
