import reviews from "./reviews";

export default {
  competitionMissedTitle: "competitionMissedTitle",
  competitionNotStartedTitle: "competitionNotStartedTitle",
  competitionNotStartedSubTitle: "competitionNotStartedSubTitle",
  editGifts: "editGifts",
  deleteBooking: "deleteBooking",
  password: "password",
  deleteCompetitionModalTitle: "deleteCompetitionModalTitle",
  enterPasswordToDeletePart1: "enterPasswordToDeletePart1",
  enterPasswordToDeletePart2: "enterPasswordToDeletePart2",
  delete: "delete",
  receiptCode: "receiptCode",

  createCompetitionTitle: "createCompetitionTitle",
  createCompetitionSubTitle: "createCompetitionSubTitle",

  extendGifts: "extendGifts",
  removeGiftExtension: "removeGiftExtension",

  giftFormTitle: "giftFormTitle",
  giftTitlePlaceholder: "giftTitlePlaceholder",
  giftDescriptionPlaceholder: "giftDescriptionPlaceholder",

  somethingWentWrong: "somethingWentWrong",

  editCompetitionTitle: "editCompetitionTitle",
  editCompetitionSubTitle: "editCompetitionSubTitle",

  tutorialFirstStepTitle: "tutorialFirstStepTitle",
  tutorialFirstStepSubTitle: "tutorialFirstStepSubTitle",
  tutorialSecondStepTitle: "tutorialSecondStepTitle",
  tutorialSecondStepSubTitle: "tutorialSecondStepSubTitle",
  tutorialThirdStepTitle: "tutorialThirdStepTitle",
  tutorialThirdStepSubTitle: "tutorialThirdStepSubTitle",
  tutorialFourthStepTitle: "tutorialFourthStepTitle",
  tutorialFourthStepSubTitle: "tutorialFourthStepSubTitle",
  tutorialFifthStepTitle: "tutorialFifthStepTitle",
  tutorialFifthStepSubTitle: "tutorialFifthStepSubTitle",
  tutorialSixthStepTitle: "tutorialSixthStepTitle",
  tutorialSixthStepSubTitle: "tutorialSixthStepSubTitle",
  tutorialTitle: "tutorialTitle",
  tutorialSubTitle: "tutorialSubTitle",
  tutorialStart: "tutorialStart",
  tutorialTempGiftName: "tutorialTempGiftName",
  tutorialTempGiftsListTitle: "tutorialTempGiftsListTitle",

  "1st": "1st",
  "2nd": "2nd",
  "3rd": "3rd",
  "4th": "4th",
  "5th": "5th",
  giftRanking: "giftRanking",
  nextMonthBooked: "nextMonthBooked",
  bookNextMonth: "bookNextMonth",

  restartTutorialButtonText: "restartTutorialButtonText",

  noCompetitionTitle: "noCompetitionTitle",
  noCompetitionSubTitle: "noCompetitionSubTitle",

  // Todo: move these to a new file for the profile component
  customerReceiptPoints: "customerReceiptPoints",
  customerReceiptRemainingPoints: "customerReceiptRemainingPoints",
  compensation: "compensation",
  invoice: "invoice",
  branch: "branch",
  cashierName: "cashierName",
  readMore: "readMore",
  emoji1: reviews.emoji1,
  emoji2: reviews.emoji2,
  emoji3: reviews.emoji3,
  emoji4: reviews.emoji4,
  emoji5: reviews.emoji5,
  male: "male",
  female: "female",
  yearsOld: "yearsOld",
  totalInvoicesPart1: "totalInvoicesPart1",
  totalInvoicesPart2: "totalInvoicesPart2",
  lifeTimePointsPart1: "lifeTimePointsPart1",
  lifeTimePointsPart2: "lifeTimePointsPart2",
  storeVisitsPart1: "storeVisitsPart1",
  storeVisitsPart2: "storeVisitsPart2",
  receivedPointsPart1: "receivedPointsPart1",
  receivedPointsPart2: "receivedPointsPart2",
  overallRatingPart1: "overallRatingPart1",
  overallRatingPart2: "overallRatingPart2",
  pointsOperationsTitle: "pointsOperationsTitle",
  redemptionsTitle: "redemptionsTitle",
  ratingsTitle: "ratingsTitle",
  noPointsOperations: "noPointsOperations",
  noRedemptions: "noRedemptions",
  noRatings: "noRatings",
  errorLoadingPointsOperations: "errorLoadingPointsOperations",
  errorLoadingRedemptions: "errorLoadingRedemptions",
  errorLoadingRatings: "errorLoadingRatings",
  photoOf: "photoOf",

  next: "next",
  last: "last",
  gifts: "gifts",
  allUsers: "allUsers",
  withTheApp: "withTheApp",
  withoutTheApp: "withoutTheApp",
  all: "all",
  filters: "filters",
  pointsWithCount: "pointsWithCount",
  noUsers: "noUsers",
  createCompetition: "createCompetition",
  editCompetition: "editCompetition",

  errorInternetConnection: "errorInternetConnection",
  errorOccurredRetrying: "errorOccurredRetrying",
  errorEnterSpecifiedNumberOfGifts: "errorEnterSpecifiedNumberOfGifts",
  errorLoadingCompetition: "errorLoadingCompetition",
  errorWrongPassword: "errorWrongPassword",

  successCompetitionCreated: "successCompetitionCreated",
  successCompetitionDeleted: "successCompetitionDeleted",
  successCompetitionEdited: "successCompetitionEdited",
  successStoreEditsSaved: "successStoreEditsSaved",

  competitorName: "competitorName",

  totalInvoicesTableHead: "totalInvoicesTableHead",

  tableHeadUserPhoneNumber: "tableHeadUserPhoneNumber",
  tableHeadUserName: "tableHeadUserName",
  tableHeadUserPoints: "tableHeadUserPoints",
  tableHeadUserMoneySpent: "tableHeadUserMoneySpent",
  tableHeadUserEmail: "tableHeadUserEmail",

  statsTotalInvoices: "statsTotalInvoices",
  statsTotalVisits: "statsTotalVisits",

  firstCountUsers: "firstCountUsers",
  statsAllUsers: "statsAllUsers",
};
