import React from "react";

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="61"
    viewBox="0 0 48 61"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#67E5DD"
        d="M16 44h15v10.031C30.246 57.445 27.174 60 23.5 60c-3.675 0-6.746-2.555-7.5-5.969V44z"
      />
      <path
        fill="#FDCA44"
        d="M24 9c8.284 0 15 6.75 15 15.077 0 10.442-7.624 13.838-7.624 18.3 0 .574-.024 1.114-.071 1.623h-14.61c-.047-.51-.071-1.05-.071-1.623 0-4.462-7.624-7.858-7.624-18.3C9 15.75 15.715 9 24 9z"
      />
      <path
        fill="#000"
        d="M23.704 8C16.66 8 10.44 12.432 8.23 19.029a1.181 1.181 0 0 0 .763 1.496 1.203 1.203 0 0 0 1.516-.753c1.885-5.624 7.188-9.403 13.195-9.403 7.662 0 13.895 6.15 13.895 13.71 0 5.843-2.652 9.334-4.783 12.14-1.55 2.04-2.89 3.802-2.89 5.94 0 .14-.001.28-.005.417h-5.016v-2.72c0-.655-.538-1.185-1.2-1.185-.664 0-1.201.53-1.201 1.184v2.721h-5.017a17.62 17.62 0 0 1-.005-.418c0-2.138-1.34-3.9-2.889-5.94-1.57-2.066-3.349-4.408-4.23-7.736a1.202 1.202 0 0 0-1.466-.846 1.183 1.183 0 0 0-.858 1.446c1.002 3.777 3.015 6.427 4.633 8.557 1.346 1.771 2.409 3.171 2.409 4.52 0 .566.024 1.12.071 1.648v10.02c0 .032.003.063.006.094v.023a1.167 1.167 0 0 0 .021.13 8.854 8.854 0 0 0 3.006 4.94A8.69 8.69 0 0 0 23.705 61a8.69 8.69 0 0 0 5.518-1.986 8.857 8.857 0 0 0 3.006-4.94v-.002c.01-.042.016-.085.021-.129l.001-.022c.002-.032.005-.063.005-.095v-10.02c.047-.527.072-1.08.072-1.648 0-1.348 1.063-2.748 2.409-4.52C36.957 34.716 40 30.713 40 24.08 40 15.213 32.69 8 23.704 8zm-6.15 41.978h12.301v2.664H17.554v-2.664zm12.301-2.369H17.554v-2.663h12.301v2.663zm-6.15 11.022c-2.442 0-4.643-1.457-5.686-3.62h11.37c-1.043 2.163-3.244 3.62-5.685 3.62zM24 6c.552 0 1-.576 1-1.286V1.286C25 .576 24.552 0 24 0s-1 .576-1 1.286v3.428C23 5.424 23.448 6 24 6zM4.714 23H1.286C.576 23 0 23.448 0 24s.576 1 1.286 1h3.428C5.424 25 6 24.552 6 24s-.576-1-1.286-1z"
      />
      <path
        fill="#000"
        d="M29 26.133c0-.626-.538-1.133-1.201-1.133H19.2c-.663 0-1.201.507-1.201 1.133 0 .625.538 1.132 1.201 1.132H22.3v2.602c0 .626.537 1.133 1.201 1.133s1.201-.507 1.201-1.133v-2.602H27.8c.663 0 1.201-.507 1.201-1.132zM8.803 10.623a1.283 1.283 0 0 0 1.82 0 1.287 1.287 0 0 0 0-1.82L8.197 6.376a1.287 1.287 0 1 0-1.82 1.82l2.426 2.426zM8.803 37.377l-2.426 2.425a1.288 1.288 0 0 0 1.82 1.82l2.426-2.424a1.287 1.287 0 1 0-1.82-1.82zM38.287 11c.33 0 .66-.126.91-.377l2.426-2.425a1.287 1.287 0 0 0-1.82-1.821l-2.426 2.425a1.288 1.288 0 0 0 .91 2.198zM39.758 37.377c-.402-.503-1.054-.503-1.456 0-.403.503-.403 1.318 0 1.82l1.94 2.426c.201.251.465.377.728.377.264 0 .527-.126.728-.377.403-.503.403-1.318 0-1.82l-1.94-2.426zM46.714 23h-3.428c-.71 0-1.286.448-1.286 1s.576 1 1.286 1h3.428c.71 0 1.286-.448 1.286-1s-.576-1-1.286-1zM24 34c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM9 25c.55 0 1-.45 1-1s-.45-1-1-1c-.551 0-1 .45-1 1s.449 1 1 1z"
      />
    </g>
  </svg>
);
