import convertingRatio from "./convertingRatio";
import onBoardingSteps from "./onBoardingSteps";

export default {
  title: "Your Brand Social Links",
  subtitle: "Provide us with your social media links",
  note: "It's okay if you don't have one",
  link: "Link",
  submit: "Submit",
  save: "Save",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  next: onBoardingSteps.next,
};
