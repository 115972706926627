import { on, reducer } from "ts-action";
import {
  LoadingStatus,
  withLoadingReducer,
} from "../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getPosEntitiesAction } from "../../constants";
import { IPosEntity } from "../../types";
import {
  getPosEntitiesSuccess,
  updatePosEntity,
  updatePosEntityFailure,
  updatePosEntityReset,
  updatePosEntitySuccess,
} from "../actions";

interface IPosEntitiesState {
  status: LoadingStatus | undefined;
  entitiesIds: string[];
  entitiesById: {
    [key: string]: IPosEntity;
  };
  entitiesByVendor: {
    [key: string]: IPosEntity;
  }
  entitiesOptions: Array<{ value: string; label: string }>;
}
export default withLoadingReducer<IPosEntitiesState>(
  reducer<IPosEntitiesState>(
    [
      on(getPosEntitiesSuccess, (state, { payload }) => ({
        ...state,
        entitiesIds: payload.map((entity) => entity.id),
        entitiesById: payload.reduce(
          (acc, entity) => ({
            ...acc,
            [entity.id]: entity,
          }),
          {}
        ),
        entitiesByVendor: payload.reduce(
          (acc, entity) => ({
            ...acc,
            [entity.pos_vendor]: entity,
          }),
          {}
        ),
        entitiesOptions: payload.map((entity) => ({
          ...entity,
          value: entity.id,
          label: entity.name,
        })),
      })),
      on(updatePosEntity, (state, { payload }) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(updatePosEntitySuccess, (state, { payload }) => ({
        ...state,
        status: LoadingStatus.success,
        entitiesById: { ...state.entitiesById, [payload.id]: payload },
      })),
      on(updatePosEntityFailure, (state, { payload }) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(updatePosEntityReset, (state, { payload }) => ({
        ...state,
        status: undefined,
      })),
    ],
    {
      entitiesIds: [],
      entitiesById: {},
      entitiesByVendor: {},
      entitiesOptions: [],
      status: undefined,
    }
  ),
  getPosEntitiesAction
);
