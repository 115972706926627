import * as constants from "../../constants/actions";
import { action, payload } from "ts-action";
import {
  IStorePickup,
  IBrandCategories,
  IBrandTags,
  IInterval,
} from "../../lib/types";
import { IEditStoreSettingsRequest } from "../../axios/editStoreSettings";
import { optAction, optReaction } from "./lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";
import { genericAction } from "../../../../constants";

export const getStoreSettings = action(
  constants.getStoreSettingsAction.requested
);
export const getStoreSettingsSuccess = action(
  constants.getStoreSettingsAction.fulfilled,
  payload<IStorePickup>()
);
export const getStoreSettingsFailure = action(
  constants.getStoreSettingsAction.rejected,
  payload<Error>()
);

export interface IEditWorkingHours {
  intervals: IInterval[];
}

export const editStoreSettings = action(
  constants.editStoreSettingsAction.requested,
  payload<IEditStoreSettingsRequest>()
);
export const editStoreSettingsSuccess = action(
  constants.editStoreSettingsAction.fulfilled,
  payload<IEditStoreSettingsRequest>()
);
export const editStoreSettingsFailure = action(
  constants.editStoreSettingsAction.rejected,
  payload<Error>()
);

export const getBrandCategory = optAction(
  constants.getBrandCategoryAction.requested
);
export const getBrandCategorySuccess = optReaction(
  constants.getBrandCategoryAction.fulfilled,
  payload<IBrandCategories>(),
  COMMIT
);
export const getBrandCategoryFailure = optReaction(
  constants.getBrandCategoryAction.rejected,
  payload<Error>(),
  REVERT
);

export const getBrandTags = optAction(constants.getBrandTagsAction.requested);
export const getBrandTagsSuccess = optReaction(
  constants.getBrandTagsAction.fulfilled,
  payload<IBrandTags[]>(),
  COMMIT
);
export const getBrandTagsFailure = optReaction(
  constants.getBrandTagsAction.rejected,
  payload<Error>(),
  REVERT
);
export const getAllBrandCategories = optAction(
  constants.getAllBrandCategoriesAction.requested
);
export const getAllBrandCategoriesSuccess = optReaction(
  constants.getAllBrandCategoriesAction.fulfilled,
  payload<IBrandCategories[]>(),
  COMMIT
);
export const getAllBrandCategoriesFailure = optReaction(
  constants.getAllBrandCategoriesAction.rejected,
  payload<Error>(),
  REVERT
);

export const getAllBrandTags = optAction(
  constants.getAllBrandTagsAction.requested
);
export const getAllBrandTagsSuccess = optReaction(
  constants.getAllBrandTagsAction.fulfilled,
  payload<IBrandTags[]>(),
  COMMIT
);
export const getAllBrandTagsFailure = optReaction(
  constants.getAllBrandTagsAction.rejected,
  payload<Error>(),
  REVERT
);
const submitOrderingSettingsAction = genericAction(
  "ACTION_SUBMIT_ORDERING_SETTINGS"
);

export const submitOrderingSettings = action(
  submitOrderingSettingsAction.requested
);
export const submitOrderingSettingsSuccess = action(
  submitOrderingSettingsAction.fulfilled
);
export const submitOrderingSettingsFailure = action(
  submitOrderingSettingsAction.rejected
);
