import * as constants from "../constants";
import {
  createOrderingMenuAction,
  getOrderingItemsAction,
  createOrderingCategoryAction,
} from "../Components/Pickup/constants/actions";
import { DeliveryMethods } from "../Components/Pickup/lib/types";
import { SyncAvailability } from "../axios/types";

export * from "./Manager";

export interface IListenToNetworkChangeAction {
  type: string;
}

export enum PosOrderingEnabled {
  service_disabled,
  service_enabled,
}
export enum POSSyncStatus {
  running = "running",
  done = "done",
  failed = "failed",
  inactive = "inactive",
}
export enum OrderingPortalStatus {
  service_enabled = "service_enabled",
  service_disabled = "service_disabled",
}
export interface IStore {
  menu_sync_availability?: SyncAvailability;
  facebook_link: string;
  noMenuSync: 1 | 0;
  pos_sync_status: POSSyncStatus;
  admin_web_portal_status: OrderingPortalStatus;
  api_key?: string;
  bio: string;
  hide_items: number;
  background_color: string;
  pos_loyalty_enabled: 1 | 0;
  pos_ordering_enabled: 1 | 0;
  noSync: 1 | 0;
  instagram_link: string;
  location: string;
  cover_image: string;
  store_image: string;
  token: string;
  allow_receipt_code: number;
  translated_name?: string;
  ar_name?: string;
  en_name?: string;
  snapchat_link: string;
  twitter_link: string;
  created_at: number;
  converting_ratio: number;
  country_code: string;
  country_iso_code: string;
  updated_at: number;
  expiration_period: number;
  name: string;
  id: string;
  status: number;
  pos: string;
  allow_reach?: 1;
  allow_leaderboard?: 1;
  timezone: string;
  pickup_status?: "service_enabled" | "service_disabled";
  delivery_status?: "service_enabled" | "service_disabled";
  drive_thru_status?: "service_enabled" | "service_disabled";
  delivery_management_status?: "enabled" | "disabled";
  delivery_method?: DeliveryMethods;
  qr_preview_id?: string;
  koinz_main_group_id?: string;
  has_white_label: 0 | 1;
  welcoming_gift_status: "enabled" | "disabled";

}

export interface IGetItem {
  type: typeof getOrderingItemsAction.requested;
}

export interface ISignupAction {
  type: typeof constants.signupAction;
  payload: string;
}

export interface ISignupSuccessAction {
  type: typeof constants.signupAction.requested;
  payload: IStore;
}

export interface ISignFailureAction {
  type: typeof constants.signupAction.fulfilled;
  payload: Error;
}
export type ISignUpAction =
  | ISignupAction
  | ISignupSuccessAction
  | ISignFailureAction;

export interface ILoginAction {
  type: typeof constants.loginAction.requested;
  payload: string;
}

export interface ILoginSuccessAction {
  type: typeof constants.loginAction.fulfilled;
  payload: IStore;
}

export interface ILoginFailureAction {
  type: typeof constants.loginAction.fulfilled;
  payload: Error;
}

export type IAuthAction =
  | ILoginAction
  | ILoginSuccessAction
  | ILoginFailureAction;

export interface ICountry {
  EnName: string;
  ArName: string;
  country_code: string;
  ISO_code: string;
  icon: string;
}

export interface IGetCountriesAction {
  type: typeof constants.getCountriesAction.requested;
  payload: { search?: string; page?: number };
}

export interface IGetCountriesSuccessAction {
  type: typeof constants.getCountriesAction.fulfilled;
  payload: ICountry[];
}

export interface IGetCountriesFailureAction {
  type: typeof constants.getCountriesAction.rejected;
  payload: Error;
}

export interface ICountriesReducerState {
  countries: ICountry[];
}

export interface ILogoutAction {
  type: typeof constants.logoutAction.requested;
}

export interface ILogoutSuccessAction {
  type: typeof constants.logoutAction.fulfilled;
}

export interface ILogoutFailureAction {
  type: typeof constants.logoutAction.rejected;
  payload: Error;
}

export type appLanguages = "ar" | "en";

export interface IGift {
  gift_name: string;
  gift_rank: number;
  gift_description: string;
  gift_photo: File | string;
}

export interface ICompetitionCreate {
  addMore?: boolean;
  id?: string;
  start_time: string;
  end_time: string;
  gifts: IGift[];
}

export interface ICompetitionEdit {
  id: string;
  gifts?: IGift[];
}

export interface ICreateCompetitionAction {
  type: typeof constants.createCompetitionAction.requested;
  payload: ICompetitionCreate;
}

export interface ICreateCompetitionSuccessAction {
  type: typeof constants.createCompetitionAction.fulfilled;
  payload: string;
}

export interface ICreateCompetitionFailureAction {
  type: typeof constants.createCompetitionAction.rejected;
  payload: Error;
}

export interface IEditCompetitionAction {
  type: typeof constants.editCompetitionAction.requested;
  payload: ICompetitionEdit;
}

export interface IEditCompetitionSuccessAction {
  type: typeof constants.editCompetitionAction.fulfilled;
}

export interface IEditCompetitionFailureAction {
  type: typeof constants.editCompetitionAction.rejected;
  payload: Error;
}

export interface ISetMoreAction {
  type: typeof constants.ACTION_SET_MORE;
  payload: boolean;
}

export interface IDeleteCompetitionAction {
  type: typeof constants.deleteCompetitionAction.requested;
  payload: string;
}

export interface IDeleteCompetitionSuccessAction {
  type: typeof constants.deleteCompetitionAction.fulfilled;
  payload: string;
}

export interface IDeleteCompetitionFailureAction {
  type: typeof constants.deleteCompetitionAction.rejected;
  payload: Error;
}

export interface INextLeaderboardAction {
  type: typeof constants.nextLeaderboardAction.requested;
}

export interface INextLeaderboardSuccessAction {
  type: typeof constants.nextLeaderboardAction.fulfilled;
  payload: boolean;
}

export interface INextLeaderboardFailureAction {
  type: typeof constants.nextLeaderboardAction.rejected;
  payload: Error;
}

export interface ICompleteTutorialAction {
  type: typeof constants.ACTION_COMPLETED_TUTORIAL;
}

export interface IResetTutorialAction {
  type: typeof constants.ACTION_RESET_TUTORIAL;
}
export interface IGetStoreAction {
  type: typeof constants.getStoreAction.requested;
}

export interface IGetStoreSuccessAction {
  type: typeof constants.getStoreAction.fulfilled;
  payload: IStore;
}

export interface IGetStoreFailureAction {
  type: typeof constants.getStoreAction.rejected;
  payload: Error;
}

export interface IFormChangedData {
  key: string;
  oldValue?: any;
  newValue: any;
}

export interface IEditStoreAction {
  type: typeof constants.editStoreAction.requested;
  payload: { changedData: IFormChangedData[] };
}

export interface IChangeStoreStateAction {
  type: typeof constants.ACTION_CHANGE_STORE_STATE;
  payload: any;
}

export interface IEditStoreSuccessAction {
  type: typeof constants.editStoreAction.fulfilled;
}

export interface IEditStoreFailureAction {
  type: typeof constants.editStoreAction.rejected;
  payload: Error;
}

export interface IResetEditFailed {
  type: typeof constants.ACTION_RESET_EDIT_FAILED;
}

export interface ICreateMenu {
  name: string;
  vat: string;
  id: string;
}

export interface ICreateMenuAction {
  type: typeof createOrderingMenuAction.requested;
  payload: ICreateMenu;
}

export interface ICreateMenuSuccessAction {
  type: typeof createOrderingMenuAction.fulfilled;
  payload: ICreateMenu;
}

export interface ICreateMenuFailureAction {
  type: typeof createOrderingMenuAction.rejected;
  payload: Error;
}

export interface ICategories {
  categories: ICreateCategory[];
}
export interface ICreateCategory {
  menu_id: string;
  ar_name?: string;
  en_name?: string;
  order: number;
}

export interface ICreateCategoryAction {
  type: typeof createOrderingCategoryAction.requested;
  payload: ICreateCategory;
}

export interface ICreateCategorySuccessAction {
  type: typeof createOrderingCategoryAction.fulfilled;
  payload: ICreateCategory;
}

export interface ICreateCategoryFailureAction {
  type: typeof createOrderingCategoryAction.rejected;
  payload: Error;
}

export enum PocOfContact {
  ASSIGN = "assign",
  UNASSIGN = "unassign",
}

export interface IPosEntity {
  id: string;
  store_id: string;
  name: string;
  pos_vendor: string;
  updated_at: number;
  created_at: number;
  api_key: string;
  koinz_main_group_id?: string;
  pos_online_payment_method_id?: string;
  offline_only_menu_group_id?: string;
  isShared: 1 | 0;
}

export enum PosVendor {
  foodics = "foodics",
  feedus = "feedus",
}
export enum PromoMerchantVisibility {
  invisible = "invisible",
  visible = "visible",
  masked = "masked",
}
export enum AllowedPaymentMethod {
  all = "all",
  online = "online",
}

export interface IStorePromocode {
  name: string;
  id: string;
  store_fund_percentage?: any;
  country_iso_code: string;
  max_discount_value?: any;
  masked_value?: any;
  masked_promo_code?: string;
  min_threshold?: any;
  max_cashback_value?: any;
  expires_after?: any;
}
export interface IPromocode {
  category: "points_multiplier" | "discount";
  end_time: number;
  discount_value: number;
  created_at: number;
  promo_code: string;
  type: string;
  title: string;
  max_discount_value: number;
  cashback_value?: number;
  max_cashback_value?: number;
  max_usage_times: number;
  min_threshold: number;
  start_time: number;
  updated_at: number;
  user_id: string;
  multiplier_value: string;
  id: string;
  desc: string;
  ar_title: string;
  ar_desc: string;
  stores_fund_percentage: any;
  expired_at: string;
  label: string;
  expired_by_admin_at?: number;
  stores: IStorePromocode[];
  ordering_type: string;
  targeting_method: string;
  suggested: number;
  customers_file_path: string;
  promo_merchant_visibility?: PromoMerchantVisibility;
  allowed_payment_method?: AllowedPaymentMethod;
  is_compensational: boolean;
  customers_file_name: string;
  customers_file_names: string[];
  customers_count: number;
  rank: number;
  is_exclusive: 0 | 1;
}