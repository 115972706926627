import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/categoryActions";
import { deleteOrderingMenuCategory } from "../../axios/deleteOrderingMenuCategory";
import { selectToken } from "../../../../redux-store/selectors";
import { deleteOrderingMenuCategoryAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import { customNotify } from "../../SharedComponent/Notification";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";

function* deleteMenuCategorySaga(action) {
  try {
    const token = yield select(selectToken);
    return yield call(deleteOrderingMenuCategory, token, action.payload);
  } catch (e) {
    customNotify.error(translateMenuError(menuErrorMessages.deleteCategory));
    throw e;
  }
}

export function* watchDeleteMenuCategorySaga() {
  yield takeLatest(
    deleteOrderingMenuCategoryAction.requested,
    handleOptimism(deleteMenuCategorySaga, {
      success: actions.deleteOrderingCategorySuccess as any,
      failure: actions.deleteOrderingCategorySuccess as any,
    })
  );
}
