import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  getZones,
  getZonesSuccess,
  searchForZones,
} from "../actions/get-zones-actions";
import { getZonesAction } from "../../constants/actions";
import { IGeographicItem } from "../../../WizardComponents/Branches/AddBranchForm/lib";

interface IState {
  zones: string[];
  zonesById: { [x: string]: IGeographicItem };
  shownZones: string[];
}

const initialState: IState = {
  zones: [],
  zonesById: {},
  shownZones: [],
};

export const getZonesReducer = withLoadingReducer(
  reducer<IState>(
    [
      on(getZonesSuccess, (state: IState, { payload }) => ({
        ...state,
        zones: payload.map((zone) => zone.id),
        zonesById: payload.reduce(
          (acc, zone) => ({
            ...acc,
            [zone.id]: zone,
          }),
          {}
        ),
        shownZones: payload.map((zone) => zone.id),
      })),
      on(getZones, () => ({
        zones: [],
        zonesById: {},
        shownZones: [],
      })),
      on(searchForZones, (state: IState, action) => {
        const { zones, zonesById } = state;
        const term = action.payload.trim().toLowerCase();
        if (!term) {
          return { ...state, shownZones: zones };
        }
        const searchedZones = zones.filter((c) => {
          const zone = zonesById[c];
          const formattedEnZoneName = zone.EnName.toLowerCase();
          const enNameMatches = formattedEnZoneName.includes(term);
          const arNameMatches = zone.ArName.includes(term);
          return enNameMatches || arNameMatches;
        });

        return { ...state, shownZones: searchedZones };
      }),
    ] as any,
    initialState
  ),
  getZonesAction
);
