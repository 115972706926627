import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const addCallCenter = (token): AxiosReturn<{}> => {
  return axios.post(
    Requests.callCenter,
    {},
    {
      headers: {
        token,
      },
    }
  );
};
