export default {
  title: "إضافة منتج جديد",
  imagePlaceholder: "صورة المنتج",
  namePlaceholder: "اسم المنتج",
  categoryPlaceholder: "تصنيف المنتج",
  pricePlaceholder: "سعر المنتج",
  integrationIdPlaceholder: "رقم التكامل",
  addItem: "إضافة المنتج",
  uploadImageButtonText: "رفع صورة",
  uploadImageDescription: "يجب ألا تقل عن 4 كيلوبايت ولا تزيد على 1 ميجابايت",
  unsupportedImageType: "نوع الملف غير مدعوم...",
  voucherCapping: "آعلي قيمة للخصم",
  thisIsVoucher: "قسيمة شراء",
  selectVoucher: "أختر قسيمة الشراء",
  addVoucher: "أضف قسيمة الشراء",
  selectVoucherDescription: "الحد الأدنى للطلب {{min_threshold}} {{currency}}, الخصم {{max_discount_value}} {{currency}}",
  selectVoucherError: "لا يمكنك إضافة قسيمة دون قيمة قصوى"
};
