import { call, put, select, takeLatest } from "redux-saga/effects";
import { addSpecialItemAction } from "../../constants";
import {
  IAddSpecialItemAction,
  IStoreItem,
  OptimisticSuccess,
} from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { addSpecialItem } from "../../axios/addSpecialItem";
import { reset } from "redux-form";
import { customNotify } from "../../helpers/customNotification/customNotification";
import i18n from "../../i18n/i18n";
import { TranslationOptions } from "i18next";
import Strings from "../../i18n/strings/giftList";

const t = (key: string, options: TranslationOptions) =>
  i18n.t(key, { ns: "giftList", ...options });

function* addSpecialItemSaga({ payload }: IAddSpecialItemAction) {
  try {
    const token = yield select(selectToken);
    const { price, name, image, optimisticId } = payload;
    yield put(reset("addSpecialItemForm"));
    console.log({ payload: payload.is_voucher ? "voucher" : "loyalty" });
    const addLoyaltyItemRes = yield call(addSpecialItem, token, {
      price: +price,
      name,
      image,
      ...(payload.is_voucher ? { is_voucher: 1, promo_id: payload.promo_id, promo_code: payload.promo_code } : {}),
    });
    const item = addLoyaltyItemRes.data as IStoreItem;
    yield put(
      actions.addSpecialItemSuccess({
        ...item,
        optimisticId,
        state: OptimisticSuccess,
      })
    );
    customNotify.success(t(Strings.addSpecialItemSuccess, { name }));
  } catch (e) {
    customNotify.error(
      t(Strings.addSpecialItemFailure, { name: payload.name })
    );
    yield put(actions.addSpecialItemFailure(payload));
  }
}

export function* watchAddSpecialItemSaga() {
  yield takeLatest(addSpecialItemAction.requested, addSpecialItemSaga);
}
