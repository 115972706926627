Object.defineProperty(exports, "__esModule", {
  value: true
});

const chipTheme = {
  chip: {
    padding: ".3rem 1.5rem",
    background: "#FEBE19",
    display: 'flex',
    color: "white",
    borderRadius: 40,
    margin: "2.5px",
    cursor: 'default'
  },
  chipSelected: {
    background: '#888'
  },
  chipRemove: {
    fontWeight: "bold",
    cursor: "pointer",
    ':hover': {
      color: 'black'
    }
  }
};
exports.default = chipTheme;
