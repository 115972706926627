import { call, put, select, takeLatest } from "redux-saga/effects";
import { addStoreItemAction } from "../../constants";
import {
  IAddStoreItemAction,
  ICataloguedItems,
  IStoreItem,
  OptimisticSuccess,
} from "../../types/wizard-types";
import { selectCats, selectToken } from "../selectors";
import * as actions from "../actions";
import { addStoreItem } from "../../axios/addStoreItem";
import { customNotify } from "../../helpers/customNotification/customNotification";
import { delay } from "redux-saga/effects";
import i18n from "../../i18n/i18n";
import { TranslationOptions } from "i18next";
import Strings from "../../i18n/strings/menu";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";
import { trackEvent } from "../../tracking/trackEvent";

const t = (key: string, options: TranslationOptions) =>
  i18n.t(key, { ns: "menu", ...options });

function* addStoreItemSaga({ payload }: IAddStoreItemAction) {
  try {
    const token = yield select(selectToken);
    const cats: ICataloguedItems[] = yield select(selectCats);
    const {
      category_id,
      price,
      name,
      image,
      integration_id,
      order,
      optimisticId,
    } = payload;
    const selectedCat: ICataloguedItems = cats.filter(
      (c) => c.id === category_id
    )[0];
    const addedStoreItem = yield call(addStoreItem, token, {
      category_id,
      price: +price,
      name,
      image,
      integration_id,
      order,
    });
    const item = addedStoreItem.data as IStoreItem;
    yield put(
      actions.addStoreItemSuccess({
        ...item,
        category_id,
        optimisticId,
        state: OptimisticSuccess,
      })
    );
    trackEvent(EVENTS_FOR_TRACKING["Added a menu item"]);
    customNotify.success(
      t(Strings.addStoreItemSuccess, {
        categoryName: selectedCat.name,
        itemName: name,
      })
    );
  } catch (e) {
    yield delay(1000);
    customNotify.error(
      t(Strings.addStoreItemFailure, { itemName: payload.name })
    );
    yield put(actions.addStoreItemFailure(payload));
  }
}

export function* watchAddStoreItemSaga() {
  yield takeLatest(addStoreItemAction.requested, addStoreItemSaga);
}
