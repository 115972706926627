import React, { useMemo, useState } from "react";
import {
  Menu,
  Dropdown,
  Button,
  DatePicker,
  Space,
  Divider,
  Popover,
  Alert,
} from "antd";
import ArrowIcon from "../../../../../../assets/down-arrow.svg";
import moment from "moment";

import styles from "./styles.module.sass";
// hooks
import { useSelect } from "../../../../../../hooks/useSelect";
import Arrow from "../../../../../../Components/Pickup/assets/feather _ chevron-down.svg";
import { useEffect } from "react";
// localization
import { translate } from "../../../../../../helpers/translate";
import Strings from "../../../../../../i18n/Manager/strings/home";
import i18n from "../../../../../../i18n/i18n";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
export interface ITimeFilterProps {
  onChange: (
    value: { from: number; to: number; unit: "day" | "hour" },
    key?: string
  ) => void;
  showArrow?: boolean;
  width?: string;
  withoutLabel?: boolean;
  valueKey?: string;
  removeMenuOptionsIds?: string[];
  value?: { from: number; to: number; unit?: "day" | "hour" };
  placement?:
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight"
  | "topLeft"
  | "topCenter"
  | "topRight";
}

export interface IFilter {
  label: string;
  hasDividerBefore: boolean;
  isSeeMoreItem: boolean;
  id: string;
  sort: number;
}
const t = translate("home");
const menuOptionList: IFilter[] = [
  {
    label: t(Strings.all_time),
    hasDividerBefore: false,
    id: "all-time",
    isSeeMoreItem: false,
    sort: 0,
  },
  {
    label: t(Strings.today),
    hasDividerBefore: false,
    id: "today",
    isSeeMoreItem: false,
    sort: 1,
  },
  {
    label: t(Strings.yesterday),
    hasDividerBefore: false,
    id: "yesterday",
    isSeeMoreItem: false,
    sort: 2,
  },
  {
    label: t(Strings.last_date, {
      count: 7,
      unit: t(Strings.days),
    }),
    hasDividerBefore: false,
    id: "last-7-days",
    isSeeMoreItem: false,
    sort: 3,
  },
  {
    label: t(Strings.last_date, {
      count: 28,
      unit: t(Strings.days),
    }),
    hasDividerBefore: false,
    id: "last-28-days",
    isSeeMoreItem: false,
    sort: 5,
  },
  {
    label: t(Strings.more_preset_ranges),
    hasDividerBefore: false,
    id: "more-preset-ranges",
    isSeeMoreItem: false,
    sort: NaN,
  },
];

const seeMoreMenuOptions: IFilter[] = [
  {
    label: t(Strings.last_date, {
      count: 14,
      unit: t(Strings.days),
    }),
    hasDividerBefore: true,
    id: "last-14-days",
    isSeeMoreItem: true,
    sort: 4,
  },
  {
    label: t(Strings.last_date, {
      count: 30,
      unit: t(Strings.days),
    }),
    hasDividerBefore: false,
    id: "last-30-days",
    isSeeMoreItem: true,
    sort: 6,
  },
  {
    label: t(Strings.last_date, {
      count: 60,
      unit: t(Strings.days),
    }),
    hasDividerBefore: false,
    id: "last-60-days",
    isSeeMoreItem: true,
    sort: 7,
  },
  {
    label: t(Strings.last_date, {
      count: 90,
      unit: t(Strings.days),
    }),
    hasDividerBefore: false,
    id: "last-90-days",
    isSeeMoreItem: true,
    sort: 8,
  },
  {
    label: t(Strings.last_date, {
      count: 12,
      unit: t(Strings.months),
    }),
    hasDividerBefore: false,
    id: "last-12-months",
    isSeeMoreItem: true,
    sort: 9,
  },
  {
    label: t(Strings.this_year),
    hasDividerBefore: false,
    id: "this-year",
    isSeeMoreItem: true,
    sort: 10,
  },
  {
    label: t(Strings.last_year),
    hasDividerBefore: false,
    id: "last-year",
    isSeeMoreItem: true,
    sort: 11,
  },
];

const customFilterOption = {
  label: t(Strings.custom_time),
  hasDividerBefore: true,
  id: "custom",
  isSeeMoreItem: false,
  sort: NaN,
};

const TimeFilter: React.FC<ITimeFilterProps> = (props) => {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation("home");
  const [menuOptions, setMenuOptions] = useState<IFilter[]>(menuOptionList);
  const [state, setState] = useState({
    isCustomRangeValid: true,
    customRangeRawVal: null,
    selectedFilter: menuOptions[4],
    seeMoreClicked: false,
    isDropDownVisible: false,
    isCustomFilterActive: false,
  });

  const [customRangeReqConfigs, setCustomRangeReqConfigs] = useState<{
    from: number;
    to: number;
    unit: "hour" | "day";
  }>({
    from: 0,
    to: 0,
    unit: "hour",
  });

  useEffect(() => {
    if (
      props.removeMenuOptionsIds &&
      props.removeMenuOptionsIds.length > 0 &&
      menuOptionList.length === menuOptions.length
    ) {
      const newMenuOptionList = menuOptionList.filter(
        (option) => !props.removeMenuOptionsIds?.includes(option.id)
      );
      setMenuOptions(newMenuOptionList);
    }
  }, [props.removeMenuOptionsIds]);

  useEffect(() => {
    if (props.valueKey) {
      if (props.valueKey !== "custom") {
        const selectedItemObj = [
          ...menuOptions,
          ...seeMoreMenuOptions,
          customFilterOption,
        ].find((item) => item.id === props.valueKey);

        if (selectedItemObj) {
          setState((prevValue) => ({
            ...prevValue,
            selectedFilter: selectedItemObj,
            isDropDownVisible: false,
            isCustomFilterActive: false,
            customRangeRawVal: null,
            seeMoreClicked: false,
          }));
        }
      } else {
        if (props.value) {
          const modifiedFilterLabel = getModifiedFilterLabel(
            props.value.from,
            props.value.to
          );
          setState((prevValue) => ({
            ...prevValue,
            isCustomFilterActive: false,
            selectedFilter: {
              ...prevValue.selectedFilter,
              label: modifiedFilterLabel,
            },
          }));
        }
      }
    }
  }, [props.value]);

  const handleOptionSelect = (e) => {
    // get selected item key
    const { key } = e;
    if (key === "custom") {
      setState((prevValue) => ({
        ...prevValue,
        isCustomFilterActive: true,
        isDropDownVisible: false,
        customRangeRawVal: null,
        seeMoreClicked: false,
        selectedFilter: customFilterOption,
      }));
      return;
    }

    if (key === "more-preset-ranges") {
      setState((prevValue) => ({
        ...prevValue,
        seeMoreClicked: !state.seeMoreClicked,
      }));
      return;
    }

    const selectedItemObj = [
      ...menuOptions,
      ...seeMoreMenuOptions,
      customFilterOption,
    ].find((item) => item.id === key);

    if (
      selectedItemObj &&
      !["custom", "more-preset-ranges"].includes(selectedItemObj.id)
    ) {
      const processedVal = mapSelectedFilterToTimestamp(selectedItemObj);
      // pass processed value up to parent
      props.onChange(processedVal, selectedItemObj.id);

      setState((prevValue) => ({
        ...prevValue,
        selectedFilter: selectedItemObj,
        isDropDownVisible: false,
        isCustomFilterActive: false,
        customRangeRawVal: null,
        seeMoreClicked: false,
      }));
      return;
    }
  };

  const {
    store: { created_at },
  } = useSelect((state) => {
    return {
      ...state.storeReducer,
      ...state.authReducer,
    };
  });

  const alignToNextHour = (
    currentHour: number,
    currentHourMinutes: number
  ): { hour: number; minutes: number } => {
    // if clock is sharp
    if (currentHourMinutes === 0) {
      return {
        hour: currentHour,
        minutes: currentHourMinutes,
      };
    }
    // if clock is 11 p.m
    if (currentHour === 23) {
      return {
        hour: 23,
        minutes: 59,
      };
    }

    // otherwise align to the next hour sharp
    return {
      hour: currentHour + 1,
      minutes: 0,
    };
  };

  const mapSelectedFilterToTimestamp = (filter: IFilter) => {
    let from = 0,
      to = 0,
      unit: "hour" | "day" = "hour";

    const today = new Date();

    const startOfYesterday = new Date(today);
    startOfYesterday.setDate(startOfYesterday.getDate() - 1);
    startOfYesterday.setHours(0, 0, 0);

    const endOfYesterday = new Date(today);
    endOfYesterday.setDate(endOfYesterday.getDate() - 1);
    endOfYesterday.setHours(23, 59, 59);

    const timestampOfADay = 60 * 60 * 24 * 1000;

    const now = new Date().getTime();
    const difference = now - created_at;

    const daysFromStoreCreation = difference / timestampOfADay;

    const minutesOfCurrentHour = new Date().getMinutes();
    const currentHour = new Date().getHours();
    const nextHour = alignToNextHour(currentHour, minutesOfCurrentHour);

    switch (filter.id) {
      case "all-time":
        from =
          daysFromStoreCreation <= 365
            ? created_at
            : new Date(today.getTime() - 365 * timestampOfADay).getTime();
        to = now;
        unit = daysFromStoreCreation < 1 ? "hour" : "day";
        break;

      case "today":
        from = new Date().setHours(0, 0, 0, 0);
        to = new Date().setHours(nextHour.hour, nextHour.minutes, 0, 0);
        unit = "hour";
        break;

      case "yesterday":
        from = startOfYesterday.getTime();
        to = endOfYesterday.getTime();
        unit = "hour";
        break;

      case "last-7-days":
        from = new Date(today.getTime() - 7 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "last-14-days":
        from = new Date(today.getTime() - 14 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "last-28-days":
        from = new Date(today.getTime() - 28 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "last-30-days":
        from = new Date(today.getTime() - 30 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "last-60-days":
        from = new Date(today.getTime() - 60 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "last-90-days":
        from = new Date(today.getTime() - 90 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "last-12-months":
        from = new Date(today.getTime() - 365 * timestampOfADay).getTime();
        to = now;
        unit = "day";
        break;

      case "this-year":
        // begining of the year
        from = new Date(today.getFullYear(), 0, 1).getTime();
        to = now;
        unit = "day";
        break;
      case "last-year":
        // begining and end of the last year
        from = new Date(today.getFullYear() - 1, 0, 1).getTime();
        to = new Date(today.getFullYear() - 1, 11, 31).getTime();
        unit = "day";
        break;

      default:
        break;
    }

    return {
      from,
      to,
      unit,
    };
  };

  const menuItems = useMemo(() => {
    return [
      ...menuOptions,
      ...(state.seeMoreClicked ? seeMoreMenuOptions : []),
      customFilterOption,
    ].map((item) => {
      return (
        <>
          {item.hasDividerBefore && <Menu.Divider />}
          <Menu.Item key={item.id}>
            {item.label}{" "}
            {item.id === "more-preset-ranges" && (
              <img
                src={ArrowIcon}
                alt=""
                style={{
                  marginLeft: i18n.language === "ar" ? "0" : "100px",
                  marginRight: i18n.language === "ar" ? "100px" : "0",
                  transform: state.seeMoreClicked
                    ? i18n.language === "ar"
                      ? "rotate(0deg)"
                      : "rotate(-90deg)"
                    : i18n.language === "ar"
                      ? "rotate(90deg)"
                      : "",
                }}
              />
            )}
          </Menu.Item>{" "}
        </>
      );
    });
  }, [state.seeMoreClicked, menuOptions]);

  const menu = (
    <Menu style={{ width: "266px" }} onClick={handleOptionSelect}>
      {menuItems}
    </Menu>
  );

  const handleRangePickerChange = (value: any) => {
    setState((prevValue) => ({
      ...prevValue,
      customRangeRawVal: value,
    }));
    if (value) {
      const timestampOfADay = 60 * 60 * 24 * 1000;

      const from = moment(value[0]).startOf("day").unix() * 1000;
      const to = moment(value[1]).unix() * 1000;

      const yearTimestampValue = 1000 * 60 * 60 * 24 * 365;

      const isRangeValid = Math.abs(from - to) <= yearTimestampValue;

      setState((prevValue) => ({
        ...prevValue,
        isCustomRangeValid: isRangeValid,
      }));

      setCustomRangeReqConfigs({
        from: from,
        to,
        unit: Math.abs(from - to) < timestampOfADay ? "hour" : "day",
      });
    }
  };

  const handleCustomFilterApply = () => {
    const { from, to, unit } = customRangeReqConfigs;
    props.onChange(
      {
        from,
        to,
        unit,
      },
      "custom"
    );

    const modifiedFilterLabel = getModifiedFilterLabel(from, to);

    setState((prevValue) => ({
      ...prevValue,
      isCustomFilterActive: false,
      selectedFilter: {
        ...prevValue.selectedFilter,
        label: modifiedFilterLabel,
      },
    }));
  };

  const getModifiedFilterLabel = (from: number, to: number) => {
    return `${moment(from)
      .locale(i18n.language)
      .format("YYYY-MM-DD")} - ${moment(to)
        .locale(i18n.language)
        .format("YYYY-MM-DD")}`;
  };

  const customRangeWrapper = (
    <Space direction="vertical" size={2}>
      <span className={styles.customFilterTitle}>{t(Strings.custom_time)}</span>
      <RangePicker
        onChange={handleRangePickerChange}
        value={state.customRangeRawVal}
      />
      {!state.isCustomRangeValid && (
        <Alert
          message={t(Strings.time_range_more_than_a_year_error)}
          type="error"
          showIcon
          className={styles.rangeErrorAlert}
        />
      )}
      <Divider className={styles.customRangeDivider} />
      <span className={styles.filterButtonsContainer}>
        {state.customRangeRawVal ? (
          <Button
            onClick={() => {
              setState((prevValue) => ({
                ...prevValue,
                isCustomRangeValid: true,
                customRangeRawVal: null,
              }));
            }}
          >
            {t(Strings.reset)}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setState((prevValue) => ({
                ...prevValue,
                isCustomRangeValid: true,
                customRangeRawVal: null,
                isCustomFilterActive: false,
                isDropDownVisible: true,
              }));
            }}
          >
            {t(Strings.cancel)}
          </Button>
        )}

        <Button
          disabled={
            !state.isCustomRangeValid ||
            customRangeReqConfigs.from === 0 ||
            customRangeReqConfigs.to === 0
          }
          onClick={handleCustomFilterApply}
        >
          {t(Strings.apply)}
        </Button>
      </span>
    </Space>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement={props.placement ?? "bottomCenter"}
      visible={!state.isCustomFilterActive && state.isDropDownVisible}
    >
      <Popover
        placement="bottom"
        content={customRangeWrapper}
        visible={state.isCustomFilterActive}
        // onVisibleChange={handlePopoverVisibilityChange}
        trigger={["click"]}
      >
        <Button
          style={
            props.width
              ? {
                width: props.width,
                justifyContent: "flex-start",
              }
              : {}
          }
          onClick={() => {
            setState((prevValue) => ({
              ...prevValue,
              isDropDownVisible: !prevValue.isDropDownVisible,
            }));
          }}
        >
          {!props.withoutLabel && t(Strings.time)} {state.selectedFilter.label}
          {props.showArrow && (
            <img
              style={{
                width: "1.2rem",
                height: "1.2rem",
                marginLeft: ".4rem",
                transform: state.isDropDownVisible
                  ? "rotate(180deg)"
                  : undefined,
              }}
              src={Arrow}
            />
          )}
        </Button>
      </Popover>
    </Dropdown>
  );
};

export default TimeFilter;
