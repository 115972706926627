import axios from "../axios";
import * as Requests from "../requests";
import { OrderingPortalStatus } from "../getOrderingWebPortalStatus";

export const editOrderingQRPortalApi = (
  token: string,
  status: OrderingPortalStatus
) => {
  return axios.put(
    Requests.orderingQrPortalStatusURL,
    { status },
    {
      headers: { token },
    }
  );
};
