import convertingRatio from "./convertingRatio";
import onBoardingSteps from "./onBoardingSteps";
export default {
  title: "title",
  logoLabel: "logoLabel",
  logoDescription: "logoDescription",
  logoUploadButtonText: "logoUploadButtonText",
  coverLabel: "coverLabel",
  coverDescription: "coverDescription",
  coverUploadButtonText: "coverUploadButtonText",
  unsupportedFileType: "unsupportedFileType",
  brandColorLabel: "brandColorLabel",
  brandColorNote: "brandColorNote",
  mockUpTitle: "mockUpTitle",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  next: onBoardingSteps.next,
  delete: "delete",
  changeBanner: "changeBanner",
  uploadBanner: "uploadBanner",
  addBanner: "addBanner",
  edit: "edit",
  dragAndDropImageOrBrowse: "dragAndDropImageOrBrowse",
  bannerShouldBe: "bannerShouldBe",
  bannerCards: "bannerCards",
  recommendedSize: "recommendedSize",
  bannerCardReorderedFailed: "bannerCardReorderedFailed",
  bannerCardReorderedSuccessfully: "bannerCardReorderedSuccessfully",
  bannerCardAddedSuccessfully: "bannerCardAddedSuccessfully",
  bannerCardAddedFailed: "bannerCardAddedFailed",
  bannerCardDeletedSuccessfully: "bannerCardDeletedSuccessfully",
  bannerCardDeletedFailed: "bannerCardDeletedFailed"
};
