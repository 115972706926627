export default {
  extraPointsExtraOrders: "Extra points, extra orders",
  extraPointsExtraOrdersDescription:
    "Go social now! Allow users to send extra points to their friends, and get new users and more orders!",
  pointsType: "Points Type",
  points: "Points",
  percentage: "Percentage",
  pointsAmount: "Points Amount",
  save: "Save",
  recommendedGiftPoints: "Recommended gift points",
  getNewCustomersBySendingX:
    "Get new customers by sending {{points}} points worth your first gift.",
  pointsTile: "{{points}} Points",
  twoPoints: "{{points}} Points",
  beforeTenPoints: "{{points}} Points",
  point: "{{points}} Point",
  allowWelcomingPoints: 'Allow welcoming points',
  allowWelcomingPointsDescription: "Boost customer satisfaction and loyalty by enabling the 'Welcoming points' feature! Ensure every new user unlocks their first gift after their first purchase, creating a memorable experience that encourages repeat visits and deeper brand engagement. Turn this on to start building loyal customers from day one!"
}