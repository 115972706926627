import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import { orderAction } from "../../constants/actions";
import {
  getOrder,
  getOrderFailure,
  getOrderSuccess,
} from "../actions/past-order-actions";
import { getOrderAPI } from "../../axios/orderAPI";

const actionType = union({ getOrder } as any);
function* getOrderSaga(action: any) {
  const token = yield select(selectToken);
  try {
    const res = yield call(getOrderAPI, token, action.payload);
    yield put(getOrderSuccess(res.data));
  } catch (e) {
    yield put(getOrderFailure(e));
  }
}

export function* watchGetOrderSaga() {
  yield takeLatest(orderAction.requested, getOrderSaga);
}
