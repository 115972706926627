import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ICategory } from "../types/wizard-types";

export const addCategory = (
  token: string,
  name: string
): AxiosReturn<{ category: ICategory }> => {
  return axios.post(
    Requests.categories,
    { name },
    {
      headers: {
        token,
      },
    }
  );
};
