import * as constants from "../../constants";
import {
  ICreateCompetitionAction,
  ICreateCompetitionFailureAction,
  ICreateCompetitionSuccessAction,
  ICompetitionCreate,
} from "../../types";

export const createCompetition = (
  data: ICompetitionCreate
): ICreateCompetitionAction => ({
  type: constants.createCompetitionAction.requested,
  payload: data,
});

export const createCompetitionSuccess = (
  id: string
): ICreateCompetitionSuccessAction => ({
  type: constants.createCompetitionAction.fulfilled,
  payload: id,
});

export const createCompetitionFailure = (
  error: Error
): ICreateCompetitionFailureAction => ({
  type: constants.createCompetitionAction.rejected,
  payload: error,
});
