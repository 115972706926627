import axios from "./axios";
import * as Requests from "./requests";
import { IWebPortalFilters } from "./getWebPortalHistory";
export const getWebPortalStatisticsApi = (
  token: string,
  filters?: IWebPortalFilters
) => {
  return axios.get(Requests.getWebPortalStatisticsURL, {
    params: {
      ...filters,
    },
    headers: { token },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
