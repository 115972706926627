let simpleCache: { [x: string]: { data: any; lastUpdated: number } } = {};

const updateCache = (key: string, data: any) => {
  simpleCache = {
    ...simpleCache,
    [key]: {
      data,
      lastUpdated: new Date().getTime(),
    },
  };
};

const getCacheContent = (key: string) => simpleCache[key];

export const simpleCacheControl = {
  updateCache,
  getCacheContent,
};
