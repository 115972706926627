import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getVouchersAction } from "../../../constants";
import { IPromocode } from "../../../types";
import { getVouchers, getVouchersFailure, getVouchersSuccess } from "../../actions";

export interface IGetVouchersState {
    vouchers: IPromocode[];
}

export default withLoadingReducer<IGetVouchersState>(reducer<IGetVouchersState>([
    on(getVouchersSuccess, (state, { payload }) => ({
        ...state,
        vouchers: payload,
    })),
    on(getVouchers, (state) => ({
        ...state,
        vouchers: []
    })),
    on(getVouchersFailure, (state) => ({
        ...state,
        vouchers: []
    }))
], {
    vouchers: [],
}), getVouchersAction)