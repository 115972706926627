import {
  IEditCompetitionAction,
  IEditCompetitionFailureAction,
  IEditCompetitionSuccessAction,
} from "../../types";
import { Map } from "immutable";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";

export interface IEditCompetitionReducerState {
  editing: boolean;
  edited: boolean;
  failed: boolean;
}

const EDITING = "editing";
const EDITED = "edited";
const FAILED = "failed";

const initialState: IEditCompetitionReducerState = Map({
  [EDITED]: false,
  [EDITING]: false,
  [FAILED]: false,
}).toJS();

type editCompetitionActions =
  | IEditCompetitionAction
  | IEditCompetitionFailureAction
  | IEditCompetitionSuccessAction;

export default (
  state = initialState,
  action: editCompetitionActions
): IEditCompetitionReducerState => {
  switch (action.type) {
    case constants.editCompetitionAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(EDITING, false);
        map.set(EDITED, true);
        map.set(FAILED, false);
      });
    }
    case constants.editCompetitionAction.requested: {
      return mutateState(state, (map) => {
        map.set(EDITING, true);
        map.set(EDITED, false);
        map.set(FAILED, false);
      });
    }
    case constants.editCompetitionAction.rejected: {
      return mutateState(state, (map) => {
        map.set(EDITING, false);
        map.set(EDITED, false);
        map.set(FAILED, true);
      });
    }
    default:
      return state;
  }
};
