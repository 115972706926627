export const getUniqueItems = <T extends { id: unknown }>(items: T[]): T[] => {
  const ids = new Set();
  const uniqueItemsArray: T[] = [];

  for (const item of items) {
    if (!ids.has(item.id)) {
      ids.add(item.id);
      uniqueItemsArray.push(item);
    }
  }
  return uniqueItemsArray;
};
