import React, { useContext } from "react";
import { SubMenu } from "../index";
import { NavLink as Link } from "react-router-dom";
import styles from "../../styles.module.sass";
import { OrdersIcon } from "../../icons";
import Strings from "../../../../i18n/strings/navigation";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import { useOrderingPortalToggle } from "../../../OrderingPortal/OrderingPortalHeader/useOrderPortalToggle";
import StoreData from "../../../../contexts/StoreData";
import { OrderingPortalStatus } from "../../../Pickup/axios/getOrderingWebPortalStatus";
import { useSelect } from "../../../../hooks/useSelect";
import { IRootReducerState } from "../../../../redux-store/rootReducer";
interface IProps {
  activeNavigate: (values) => boolean;
  collapsed: boolean;
}
const CollapseOrders: React.FC<IProps> = ({
  activeNavigate,

  collapsed,
}) => {
  const { t } = useTranslation("navigation");
  const { token } = useContext(StoreData);
  const { orderingPortalStatus } = useOrderingPortalToggle(token);
  const { service_disabled } = OrderingPortalStatus;
  const storeSettings = useSelect(
    (state) => state.storeSettingsReduces.General
  );
  const { qr_portal_status } = useSelect((state: IRootReducerState) => ({
    qr_portal_status: state.getOrderingQRPortalStatusReducer.status,
  }));
  return (
    <SubMenu
      active={activeNavigate("/pickup")}
      key={"orders"}
      title={t(Strings.orders)}
      icon={<OrdersIcon clicked={activeNavigate("/pickup")} />}
      clicked={activeNavigate("/pickup")}
      collapsed={collapsed}
    >
      <Link
        to={"/pickup/setup/pickup-branches/select-branches"}
        className={
          activeNavigate("/pickup/setup/pickup-branches/select-branches") ||
          activeNavigate("/pickup/setup/pickup-branches/complete-branches")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.pickupBranches)}
      </Link>
      <Link
        to={"/pickup/setup/menu"}
        className={
          activeNavigate("/pickup/setup/menu")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.menu)}
      </Link>
      <Link
        to={"/pickup/setup/settings"}
        className={
          activeNavigate("/pickup/setup/settings")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.brandSettings)}
      </Link>
      {orderingPortalStatus !== service_disabled && (
        <Link
          to={"/pickup/setup/ordering-portal"}
          className={
            activeNavigate("/pickup/setup/ordering-portal")
              ? styles.activeCollapseItem
              : styles.collapseItem
          }
        >
          {t(Strings.orderingPortal)}
        </Link>
      )}
      {qr_portal_status !== service_disabled && (
        <Link
          to={"/pickup/setup/ordering-qr-portal"}
          className={
            activeNavigate("/pickup/setup/ordering-qr-portal")
              ? styles.activeCollapseItem
              : styles.collapseItem
          }
        >
          {t(Strings.qrOrdering)}
        </Link>
      )}
      {(storeSettings.delivery_settings_completed ||
        storeSettings.pickup_settings_completed) && (
        <Link
          to={"/pickup/setup/order-history"}
          className={
            activeNavigate("/pickup/setup/order-history")
              ? styles.activeCollapseItem
              : styles.collapseItem
          }
        >
          {t(Strings.orderHistory)}
        </Link>
      )}
    </SubMenu>
  );
};

export default CollapseOrders;
