import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import {
  ICategorizedItem,
  IIdentifiedObject,
  IStoreItem,
} from "../types/wizard-types";
import { IOptimisticObject } from "../types/shared";

export interface IEditStoreItemData
  extends ICategorizedItem,
    IIdentifiedObject,
    IOptimisticObject {
  order?: number;
  name?: string;
  price?: number;
  image?: string;
  integration_id?: string;
}

export const editStoreItem = (
  token,
  data: IEditStoreItemData
): AxiosReturn<IStoreItem> => {
  return axios.put(Requests.storeItem(data.id), data, {
    headers: {
      token,
    },
  });
};
