import { useQuery } from "react-query";
import { getLiveUpdatesHistory } from "../../../axios/Manager/Analytics";

export const useGetLiveUpdates = (token, requestConfigs) => {
  const result = useQuery(
    ["liveUpdates", requestConfigs],
    () => {
      return getLiveUpdatesHistory(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      cacheTime: 0,
    }
  );

  return result;
};
