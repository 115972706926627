export default {
  invalid_phone_number: "invalid_phone_number",
  payment_method: "payment_method",
  transaction_time: "transaction_time",
  branch: "branch",
  type: "type",
  customer: "customer",
  fulfillment_rate: "fulfillment_rate",
  price: "price",
  points: "points",
  export_csv: "export_csv",
  rate: "rate",
  status: "status",
  search: "search",
  searchWithOrderCode: "searchWithOrderCode",
  time_to_accept: "time_to_accept",
  searchBranchName: "searchBranchName",
  acceptance_rate: "acceptance_rate",
  all: "all",
  on_track: "on_track",
  off_track: "off_track",
  general: "general",
  customerSegment: "customerSegment",
  appCustomer: "appCustomer",
  nonAppCustomer: "nonAppCustomer",
  male: "male",
  female: "female",
  usersWithUnregisteredAge: "usersWithUnregisteredAge",
  moreAdvanced: "moreAdvanced",
  onePlusRedemption: "onePlusRedemption",
  noRedemption: "noRedemption",
  applyFilters: "applyFilters",
  all_filter: "all_filter",
  selectedFilters: "selectedFilters",
  branchesSelected: "branchesSelected",
  customerType: "customerType",
  customerAge: "customerAge",
  customerGender: "customerGender",
  totalVisits: "totalVisits",
  totalPoints: "totalPoints",
  lastVisit: "lastVisit",
  resetAll: "resetAll",
  all_transactions: "all_transactions",
  all_redemptions: "all_redemptions",
  all_customers: "all_customers",
  all_branches: "all_branches",
  transactions: "transactions",
  customers: "customers",
  branches: "branches",
  redemptions: "redemptions",
  gift: "gift",
  online_orders: "online_orders",
  visits: "visits",
  current_points: "current_points",
  life_time_points: "life_time_points",
  ticket_size: "ticket_size",
  branch_dropdown_title: "branch_dropdown_title",
  more_filters: "more_filters",
  average_ticket_size: "average_ticket_size",
  branch_name: "branch_name",
  delivery: "delivery",
  pickup: "pickup",
  web_portal: "web_portal",
  offline: "offline",
  online: "online",
  sortBy: "sortBy",
  asc: "asc",
  desc: "desc",
  dine_in: "dine_in",
  takeaway: "takeaway",
  // fraud list  strings
  action: "action",
  reason: "reason",
  fraudRank: "fraudRank",
  markAsReviewed: "markAsReviewed",
  currentPoints: "currentPoints",
  numOfTransactions: "numOfTransactions",
  reviewed: "reviewed",
  pointsRemoved: "pointsRemoved",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  SUSPECT: "SUSPECT",

  blocked: "blocked",
  removePointsConfirmationMsg: "removePointsConfirmationMsg",

  cancelCta: "cancelCta",
  removePointsCta: "removePointsCta",
  blockCustomerCta: "blockCustomerCta",
  blockCustomerconfirmationMsg: "blockCustomerconfirmationMsg",
  unreviewed: "unreviewed",
  phoneNumberPlaceholder: "phoneNumberPlaceholder",
  //=> watch tower
  wallet: "wallet",
  compensations: "compensations",
  recentVisits: "recentVisits",
  promoCodeChecker: "promoCodeChecker",
  actionCenter: "actionCenter",
  blockCustomer: "blockCustomer",
  blockedBy: "blockedby",














};
