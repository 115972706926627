import React, { useMemo, useRef, useState } from "react";
import styles from "./styles.module.sass";
import Avatar from "../../../../../../assets/icons_info_gender_male.svg";
import Female from "../../../../../../assets/female.svg";
import { Queries } from "../../../../../../Queries/Manager";
import Pictogram, { IPictogramData } from "../Pictogram";
import { Collapse, Skeleton } from "antd";
import Placeholder from "../../../../../../assets/group-2.svg";
import { useEffect } from "react";
import { GenderSegments } from "../index";
import ArrowIcon from "../ArrowIcon";
import {
  DownOutlined as DownIcon,
  UpOutlined as UpIcon,
} from "@ant-design/icons";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import { useIsVisible } from "react-is-visible";
import "./styles.less";
import { Link } from "react-router-dom";
interface ICustomerSegment {
  age_group: string;
  majority_gender: string;
}
function CustomersSegment() {
  const customersSegmentRef = useRef<any>(null);
  const visible = useIsVisible(customersSegmentRef);

  const { data, status, refetch } = Queries.useGetCustomersSegmentOverview();
  const { Panel } = Collapse;
  const {
    data: customersSegmentChart,
    status: getCustomersSegmentStatus,
    refetch: customersSegmentRefetch,
  } = Queries.useGetCustomersSegmentChart();

  const { t } = useTranslation("home");
  useEffect(() => {
    if (
      visible &&
      !customersSegmentChart &&
      getCustomersSegmentStatus === "idle"
    ) {
      customersSegmentRefetch();
    }
    if (visible && !data && status === "idle") {
      refetch();
    }
  }, [visible]);
  const customersSegmentPlaceholder = {
    "18-24": {
      total_percentage: 0,
      average_ticket_size: 0,
      males_percentage: 0,
    },
    "25-34": {
      total_percentage: 0,
      average_ticket_size: 0,
      males_percentage: 0,
    },
    "35-44": {
      total_percentage: 0,
      average_ticket_size: 0,
      males_percentage: 0,
    },
    "45-54": {
      total_percentage: 0,
      average_ticket_size: 0,
      males_percentage: 0,
    },
    "55-64": {
      total_percentage: 0,
      average_ticket_size: 0,
      males_percentage: 0,
    },
    "65+": {
      total_percentage: 0,
      average_ticket_size: 0,
      males_percentage: 0,
    },
  };
  const [state, setState] = useState(customersSegmentPlaceholder);

  useEffect(() => {
    if (getCustomersSegmentStatus === "success") {
      setState(customersSegmentChart);
    }
  }, [getCustomersSegmentStatus]);
  const [expand, setExpand] = useState(true);
  const customersSegmentData = useMemo((): IPictogramData[] => {
    return Object.keys(state).map((i: any) => ({
      label: i,
      total_percentage: state[i]?.total_percentage,
      average_ticket_size: state[i]?.average_ticket_size,
      males_percentage: state[i]?.males_percentage,
    }));
  }, [state]);

  return (
    <div
      ref={customersSegmentRef}
      className={`${styles.main} ${useRtlClass(styles)}`}
    >
      <Collapse defaultActiveKey={[]} style={{ border: "none" }}>
        <Panel
          showArrow={false}
          key={"1"}
          header={
            <div
              onClick={() => setExpand(!expand)}
              className={styles.wrapper}
              style={{ borderRadius: expand ? "0.4rem 0.4rem 0 0" : "0.4rem" }}
            >
              <div className={styles.headWrapper}>
                <div className={styles.titleWrapper}>
                  <span className={styles.title}>
                    {t(Strings.customer_segment)}
                  </span>
                  <span className={styles.subtitle}>
                    {t(Strings.most_of_your_customers_are)}{" "}
                  </span>
                </div>
                <button className={styles.seeMoreButton}>
                  {expand ? t(Strings.see_more) : t(Strings.less)}
                  <span>
                    {expand ? (
                      <img
                        src={
                          require("../../../../../../assets/feather _ chevron-down.svg")
                            .default
                        }
                        width={16}
                        height={16}
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <img
                        src={
                          require("../../../../../../assets/feather _ chevron-down.svg")
                            .default
                        }
                        width={16}
                        height={16}
                      />
                    )}
                  </span>
                </button>
              </div>
              {status === "loading" ? (
                <Skeleton
                  className={"customers-segment-skeleton"}
                  avatar
                  active
                  paragraph={{
                    rows: 0,
                  }}
                />
              ) : (
                <React.Fragment>
                  {data?.majority_gender && data?.age_group ? (
                    <div className={styles.customerWrapper}>
                      <div className={styles.avatarWrapper}>
                        <img
                          src={
                            data?.majority_gender === "males" ? Avatar : Female
                          }
                        />
                      </div>
                      <span className={styles.malesFrom}>
                        {data?.majority_gender === "males"
                          ? t(Strings.males)
                          : t(Strings.females)}{" "}
                        {t(Strings.age_group, { age_group: data?.age_group })}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.placeholderWrapper}>
                      <img
                        className={styles.placeholderIcon}
                        src={Placeholder}
                      />
                      <span className={styles.placeholderTitle}>
                        {t(Strings.your_data_will_appear_here)}
                      </span>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          }
        >
          <div className={styles.collapseWrapper}>
            <Pictogram
              gender={data?.majority_gender}
              width={550}
              pictogramData={customersSegmentData}
            />
          </div>
          <GenderSegments />
          <Link to={"insights/customers"} className={styles.analyzeCustomers}>
            <div className={styles.analyzeCustomersWrapper}>
              <span className={styles.analyzeCustomersTitle}>
                {" "}
                {t(Strings.analyze_customers)}
              </span>
              <div className={styles.iconWrapper}>
                <ArrowIcon
                  height={"1.6rem"}
                  width={"1.6rem"}
                  fill={"#ffa351"}
                />
              </div>
            </div>
          </Link>
        </Panel>
      </Collapse>
    </div>
  );
}

export default CustomersSegment;
