import memoize from 'lodash/memoize';

export const numberFormatter = memoize((num: number, digits: number) => {
  if (num === 0) {
    return 0;
  }
  const numberFormats = [
    {value: 1, symbol: ""},
    {value: 1E3, symbol: "k"},
    {value: 1E6, symbol: "M"},
    {value: 1E9, symbol: "B"},
    {value: 1E12, symbol: "T"},
    {value: 1E15, symbol: "P"},
    {value: 1E18, symbol: "E"},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = numberFormats.length - 1; i > 0; i--) {
    if (num >= numberFormats[i].value) {
      break;
    }
  }
  return (num / numberFormats[i].value).toFixed(digits).replace(rx, "$1") + numberFormats[i].symbol;
});
