import { takeLatest, put, select, call } from "redux-saga/effects";
import { getOrderingMenusAction } from "../../constants/actions";
import { getMenusFailure, getMenusSuccess } from "../actions/menuActions";
import { selectToken } from "../../../../redux-store/selectors";
import { getOrderingMenus } from "../../axios/getOrderingMenus";
import { AxiosResponse } from "axios";
import { IMenu } from "../../lib/types";
import { getStore } from "../../../../redux-store/actions";

const handle204 = <T>(res: AxiosResponse<T>, defaultValue: T) =>
  res.status === 200 ? res.data : defaultValue;

function* getMenusSaga() {
  try {
    const token = yield select(selectToken);

    const res: AxiosResponse<{ menus: IMenu[] }> = yield call(
      getOrderingMenus,
      token
    );
    yield put(getMenusSuccess(handle204(res, { menus: [] }).menus));
    yield put(getStore());
  } catch (e) {
    yield put(getMenusFailure(e));
  }
}

export function* watchGetMenusSaga() {
  yield takeLatest(getOrderingMenusAction.requested, getMenusSaga);
}
