export default {
  openingHour: "Open Hours",
  preparationTime: "Preparation time",
  brandCat: "Brand category",
  delivery: "Delivery time & cost",
  brandTags: "Brand Tags",
  coverPhoto: "Cover Photo",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  monday: "Monday",
  posTitle: "Online orders are sent directly to POS",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  AM: "AM",
  PM: "PM",
  selectTimeZoneLabel: "Select time zone",
  setUpPrepTimeTitle: "Setup Preparation Time",
  setUpPrepTimeSubTitle: "What's the average preparation time?",
  from: "From",
  to: "To",
  minutes: "Minutes",
  minimumOrderValue: "Minimum order value",
  setUpDeliveryTitle: "Setup Delivery Time & Cost",
  setUpDeliverySubTitle: "What’s Average preparation time?",
  time: "Time",
  cost: "Cost",
  description: "File Requirements: JPG, PNG up to 5MB.",
  uploadPhoto: "Upload Photo",
  done: "Done",
  unsupported: "unsupported file type",
  routingMethod: "Routing method",
  deliveryMethod: "Delivery Method",
  paymentMethodTitle: "Payment method",
  branchesWorkingHours: "Branches Working Hours",
  pointOfSale: "Point of sale",
  pleaseEnterGroupId: "Please enter group id",
  pleaseEnterPaymentMethodId: "Please enter payment method id",
  paymentMethodId: "Payment Method Id",
  groupId: "Group id",
  save: "Save",
  cancel: "Cancel",
  discard: "Discard",
  posWarningModalText:
    "You have unsaved changes in the current POS entity. Please choose an action.",
  posSettingsUpdatedSuccessfully: "POS settings updated successfully",
  somethingWentWrong: "Something went wrong!",
  posSettings: "POS Settings",
  posSettingsDescription:
    "POS integration related settings and configurations.",
  posEntitiesSettings: "POS Entities Settings",
  posEntitiesSettingsDescription:
    "Select a pos entity, set its configurations, then Save. Repeat for every pos entity.",
  selectPOSEntity: "Select POS Entity",
  menuGroupID: "Menu Group ID",
  onlinePaymentMethodID: "Online Payment Method ID",
  whatIsThisAndWhyDoINeedIt: "What is this and why do I need it?",
  offlineOnlyMenuGroupIdHint_1:
    "This is an additional menu group that collects all POS menu items available for offline points transactions.This menu group should be exclusive to this POS entity.",
  offlineOnlyMenuGroupIdHint_2: "You may need this setting if this POS account is shared with other Koinz brands and if this POS entity uses different menus for offline transactions compared to the Koinz app menu group.Setting a menu group ID for offline - only transactions ensures that received POS offline points are correctly mapped to the appropriate Koinz brand.",
  offlineOnlyMenuGroupId: "Offline Only Menu Group ID",
  offlineOnlyMenuGroupIdPlaceholder: "Please enter offline only menu group id",
  optional: "(Optional)",
};