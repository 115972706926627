import { useQuery } from "react-query";
import {
  getRedemptionInsightsAPI,
  getRedemptionInsightsTotalAPI,
  getRedeemedGiftsAPI,
} from "../../../../axios/Manager/Analytics/Insights/redemption";

export const useGetRedemptionInsights = (token, requestConfigs) => {
  const result = useQuery(
    ["redemption-insights", requestConfigs],
    () => {
      return getRedemptionInsightsAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};

export const useGetRedemptionInsightsTotal = (token, requestConfigs) => {
  const result = useQuery(
    ["redemption-insights-total", requestConfigs],
    () => {
      return getRedemptionInsightsTotalAPI(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};

export const useGetTopRedeemedGifts = (token) => {
  const result = useQuery(
    ["top-redeemed-gifts"],
    () => {
      return getRedeemedGiftsAPI(token).then((res) => res.data);
    },
    {
      enabled: true,
    }
  );

  return result;
};
