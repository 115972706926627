import { getOrderingQRPortalStatusSuccess } from "./../../actions/Portal/index";
import { on, reducer } from "ts-action";
import { getOrderingQRPortalStatusAction } from "../../../constants/actions";
import { withLoadingReducer } from "./../withLoadingState";
import { OrderingQRPortalStatus } from "../../../axios/Portal/getOrderingQRPortalApi";

interface IState {
  status: OrderingQRPortalStatus;
}

const initialState: IState = {
  status: OrderingQRPortalStatus.service_disabled,
};

export const getOrderingQRPortalStatusReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getOrderingQRPortalStatusSuccess, (state, { payload }) => ({
        ...state,
        status: payload.status,
      })),
    ],
    initialState
  ),
  getOrderingQRPortalStatusAction
);

