import {
  downloadExcelSheetFailure,
  downloadExcelSheetSuccess,
} from "../actions/menu-scraping-actions";
import { select, put, takeLatest, call } from "redux-saga/effects";
import { downloadExcelSheetApi } from "../../axios/downloadExcelSheetApi";
import { selectToken } from "../../../../redux-store/selectors";
import { downloadExcelSheetAction } from "../../constants/actions";

function* downloadExcelSheetSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(downloadExcelSheetApi, token);
    yield put(downloadExcelSheetSuccess(res.data));
  } catch (e) {
    yield put(downloadExcelSheetFailure(e));
  }
}

export function* watchDownloadExcelSheet() {
  yield takeLatest(downloadExcelSheetAction.requested, downloadExcelSheetSaga);
}
