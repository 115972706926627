import { useCallback, useState } from "react";

export const useUpdatedAt = () => {
  const [updatedTime, setUpdatedTime] = useState(new Date().getTime());
  const update = useCallback(() => {
    setUpdatedTime(new Date().getTime());
    return null;
  }, []);
  return {
    update,
    updatedTime,
  };
};
