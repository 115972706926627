import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { hot } from "react-hot-loader";

class ScrollToTop extends Component<RouteComponentProps> {
  public componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default hot(module)(withRouter(ScrollToTop));
