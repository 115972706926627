import { Modal, ModalBody } from "reactstrap";
import * as React from "react";
import styles from "./styles.module.sass";
import i18n from "../../i18n/i18n";
import { useRtlClass } from "../../lib";
import { useToggleState } from "../../Utils";
import { ButtonWithSpinner } from "../BrandSettings/DashboardUsers/AddEditUserForm";
import DeleteIcon from "../../assets/icon-delete.svg";

interface IProps {
  modal: boolean;
  onClickDelete: () => Promise<void> | void;
  toggle: () => void;
  text: React.ReactChild;
  title: React.ReactChild;
  removeDelete?: boolean;
  buttonText: string;
  cancelText: string;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
}

const DeleteSingleItemModal: React.FC<IProps> = (props) => {
  const {
    onClickDelete,
    modal,
    toggle,
    title,
    text,
    buttonText,
    removeDelete,
    cancelText,
    disabled,
    setDisabled,
  } = props;
  const [loading, toggleLoading] = useToggleState();
  const handleSubmit = React.useCallback(() => {
    setDisabled(true);
    const promise = onClickDelete();
    if (promise) {
      toggleLoading();
      promise.finally(toggleLoading);
    }
  }, [toggleLoading, loading, onClickDelete]);
  const rtlStyle: React.CSSProperties = {
    direction: i18n.language === "ar" ? "rtl" : "inherit",
  };
  return (
    <Modal
      isOpen={modal}
      size="lg"
      toggle={toggle}
      centered={true}
      className={styles.content}
    >
      <ModalBody className={styles.body}>
        <div className={`${styles.wrapper} ${useRtlClass(styles)}`}>
          {!removeDelete && (
            <img className={styles.logo} src={DeleteIcon} alt="delete-icon" />
          )}
          <div className={styles.title} style={rtlStyle}>
            {title}
          </div>
          <div className={styles.paragraph} style={rtlStyle}>
            {text}
          </div>
          <div className={styles.btnWrapper}>
            <button
              disabled={disabled}
              onClick={toggle}
              className={disabled ? styles.cancelDisabled : styles.cancel}
            >
              {cancelText}
            </button>
            <ButtonWithSpinner
              loading={loading}
              disabled={disabled}
              className={
                disabled ? styles.confirmBtnDisabled : styles.confirmBtn
              }
              onClick={handleSubmit}
            >
              {buttonText}
            </ButtonWithSpinner>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteSingleItemModal;
