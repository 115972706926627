import * as constants from "../../constants";
import {
  ICompetitionEdit,
  IEditCompetitionAction,
  IEditCompetitionSuccessAction,
  IEditCompetitionFailureAction,
} from "../../types";

export const editCompetition = (
  data: ICompetitionEdit
): IEditCompetitionAction => ({
  type: constants.editCompetitionAction.requested,
  payload: data,
});

export const editCompetitionSuccess = (): IEditCompetitionSuccessAction => ({
  type: constants.editCompetitionAction.fulfilled,
});

export const editCompetitionFailure = (
  error: Error
): IEditCompetitionFailureAction => ({
  type: constants.editCompetitionAction.rejected,
  payload: error,
});
