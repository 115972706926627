export default {
  title: "الإعدادات المتقدمة",
  allowReceiptIdTitle: "تفعيل كود الفاتورة",
  allowReceiptIdDescription:
    "هذا سيسمح للكاشير بأن يدخل كود الفاتورة لكل عملية لهدف المراجعة",
  allowPointsExpirationTitle: "تفعيل تاريخ انتهاء النقاط",
  allowPointsExpirationDescription:
    "تفعيل تاريخ انتهاء النقاط حتى تصبح نقاط العميل منتهية بعد فترة من الزمن تقوم بتحديدها أنت",
  customerExpirationPeriodTitle: "ستنتهي صلاحية نقاط العميل بعد",
  allowHideMenuItemsTitle: "تفعيل اخفاء المنتجات من القائمة",
  allowHideMenuItemsDescription:
    "يمكنك اخفاء منتجات معينة من قائمتك في تطبيق العميل",
  blockedUsers: "العملاء المحظورون",
  blockedUsersDescription:
    "العملاء المحظورون لا يمكنهم استلام أو تبديل نقاط في متجرك",
  days: "أيام",
  days_1: "يوم واحد",
  days_2: "يومان",
  days_3: "{{count}} أيام",
  days_4: "{{count}} يوم",
  months_1: "شهر واحد",
  months_2: "شهران",
  months_3: "{{count}} شهور",
  months_4: "{{count}} شهر",
  years: "سنوات",
  years_1: "سنة واحدة",
  years_2: "سنتان",
  years_3: "{{count}} سنوات",
  years_4: "{{count}} سنة",
  confirm: "تأكيد",
  cancel: "إلغاء",
  uninstallPointOfSale: "إلغاء نقاظ البيع (POS)",
  ordering: "Ordering",
  loyalty: "Loyalty",
  "Uninstall POS Loyalty": "إزالة ولاء نقاط البيع",
  "Uninstall POS Ordering": "إزالة طلبات نقاط البيع",
  "Do you want to uninstall loyalty pos integration":
    "هل تريد إزالة  ولاء نقاط البيع؟",
  doYouWantToUninstallOrderingPosIntegration:
    "هل تريد إزالة  طلبات نقاط البيع؟",
};
