import { useMutation } from "react-query";
import { editPointsOperationAPI, IPayload } from "../../axios/PointsOperation";
import { useSelect } from "../../hooks/useSelect";
export const useEditPointsOperations = () => {
  const { token } = useSelect((state) => state.authReducer);
  return useMutation(
    (payload: IPayload) =>
      editPointsOperationAPI(token, { ...payload, remaining: null }),
    {
      retry: 3,
    }
  );
};
