import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import { checkOnStoreWHUpdatingStatusAction } from "../../../../constants/actions";
import {
  checkOnStoreWHUpdatingStatusSuccess,
  checkOnStoreWHUpdatingStatusFailure,
} from "../../../actions/Manager";
import { getStorePickup as checkOnStoreWHUpdatingStatusReq } from "../../../../axios/getStorePickupSettings";

function* checkOnStoreWHUpdatingStatus() {
  try {
    const token = yield select(selectToken);
    const res = yield call(checkOnStoreWHUpdatingStatusReq, token);

    yield put(checkOnStoreWHUpdatingStatusSuccess(res.data));
  } catch (error) {
    yield put(checkOnStoreWHUpdatingStatusFailure(error));
  }
}

export function* watchCheckOnStoreWHUpdatingStatus() {
  yield takeLatest(
    checkOnStoreWHUpdatingStatusAction.requested,
    checkOnStoreWHUpdatingStatus
  );
}
