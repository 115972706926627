import { Tooltip } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import styles from "./styles.module.sass";

interface IProps {
  image?: string;
  imageSize?: "small" | "large";
  name: string;
  subTitle?: string;
}

export const CustomerBox: React.FC<IProps> = (props) => {
  const { image, imageSize, name, subTitle } = props;
  const avatar = useDeepCompareMemo(
    () => (image ? process.env.REACT_APP_ENDPOINT + image : undefined),
    [image]
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        {avatar ? (
          <img className={styles.avatar} src={avatar} />
        ) : (
          <span className={styles.customerName}>{name?.[0]}</span>
        )}
      </div>

      <div>
        {name.length > 18 ? (
          <Tooltip title={name}>
            <span>{name}</span>
          </Tooltip>
        ) : (
          <span>{name}</span>
        )}
        {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
      </div>
    </div>
  );
};
