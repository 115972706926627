import { useContext, useEffect, useRef, useState } from "react";
import DoughnutChart, { IDoughnutProps } from "../DoughnutChart";
import styles from "./styles.module.sass";
import * as doughnutChartConfigs from "../DoughnutChart/configs/gender-segment-configs";
import {
  IGenderSegments,
  useGetCustomersSegmentGenderChart,
} from "../../../../../../Queries/Manager/Analytics/customerSegment.hook";
import { useGetCurrency } from "../../../../../../Components/Pickup/SetupPickup/OrderHistory/PastOrder/PastOrderTable/OrderDetails";
import StoreData from "../../../../../../contexts/StoreData";
import React from "react";
import { useRtlClass } from "../../../../../../shopx-shared-components/hooks/useRtlClassName";
import Strings from "../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../shopx-shared-components/hooks/useTranslation";
import { useIsVisible } from "react-is-visible";
interface IGenderSegmentProps {
  dotColor: string;
  gender: string;
  percentage?: number | undefined;
  count?: number;
  isLoading: boolean;
  average_ticket_size?: number | undefined;
}
const GenderSegment: React.FC<IGenderSegmentProps> = ({
  average_ticket_size,
  gender,
  percentage,
  dotColor,
  count,
  isLoading,
}) => {
  const { storeData } = useContext(StoreData);
  const currency = useGetCurrency(storeData?.id as string);
  const { t } = useTranslation("home");
  return (
    <div className={`${styles.customerGenderWrapper} ${useRtlClass(styles)}`}>
      <div className={styles.genderTitleWrapper}>
        <div className={styles.dot} style={{ backgroundColor: dotColor }} />
        <span className={styles.genderTitle}>{gender}</span>
      </div>

      <span className={styles.totalAndPercentage}>
        {!isLoading ||
        typeof percentage === "number" ||
        typeof count === "number"
          ? `${count || 0} (${percentage || 0}%)`
          : "-"}
      </span>
      <span className={styles.averageTicketSize}>
        {t(Strings.avg_ticket_size)}
      </span>
      <span className={styles.totalAndPercentage}>
        {!isLoading || typeof average_ticket_size === "number" ? (
          <React.Fragment>
            {average_ticket_size} {currency}
          </React.Fragment>
        ) : (
          "-"
        )}
      </span>
    </div>
  );
};
const numberFormatter = (number) => {
  if (number > 999 && number < 1000000) {
    return number / 1000 + "K";
  } else if (number > 1000000) {
    return number / 1000000 + "M";
  } else if (number < 900) {
    return number;
  }
};

export const GenderSegments: React.FC = () => {
  const genderSegmentsRef = useRef<any>(null);
  const visible = useIsVisible(genderSegmentsRef);
  const { data, status, isLoading, refetch } =
    useGetCustomersSegmentGenderChart();
  const { t } = useTranslation("home");
  const [genderSegmentsData, setGenderSegmentsData] = useState<
    IGenderSegments | undefined
  >();
  const [values, setValues] = useState<IDoughnutProps>({
    backgroundColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
    hoverBackgroundColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
    hoverBorderColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
    counts: [0, 0],
    data: [],
    height: 176,
    width: 176,
    borderColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
    labels: ["", ""],
    enableTooltip: false,
  });
  useEffect(() => {
    if (!data && status === "idle" && visible) {
      refetch();
    }
  }, [visible]);
  useEffect(() => {
    if (status === "success") {
      setGenderSegmentsData(data);
    }
  }, [status, data]);
  useEffect(() => {
    if (
      genderSegmentsData &&
      genderSegmentsData.female.percentage > 0 &&
      genderSegmentsData.male.percentage > 0
    ) {
      const { female, male } = genderSegmentsData;
      setValues((prevState) => ({
        ...prevState,
        data: [Number(male.percentage), Number(female.percentage)],
        backgroundColor: doughnutChartConfigs.BACKGROUND_COLOR,
        borderColor: doughnutChartConfigs.BORDER_COLOR,
        hoverBackgroundColor: doughnutChartConfigs.HOVER_COLOR,
        hoverBorderColor: doughnutChartConfigs.HOVER_COLOR,
        counts: [male.count, female.count],
        labels: [t(Strings.male_customers), t(Strings.female_customers)],
        height: 176,
        width: 176,
        enableTooltip: true,
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        data: [100, 0],
        backgroundColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
        borderColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
        hoverBackgroundColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
        hoverBorderColor: doughnutChartConfigs.PLACEHOLDER_COLOR,
        counts: [0, 0],
        labels: [t(Strings.male_customers), t(Strings.female_customers)],
        height: 176,
        width: 176,
        enableTooltip: false,
      }));
    }
  }, [genderSegmentsData]);

  return (
    <div
      ref={genderSegmentsRef}
      className={`${styles.container} ${useRtlClass(styles)}`}
    >
      <div className={styles.main}>
        <div className={styles.doughnutChartWrapper}>
          <DoughnutChart
            doughnutChartConfigs={{ ...doughnutChartConfigs }}
            values={values}
          />
        </div>
        <GenderSegment
          isLoading={isLoading}
          gender={t(Strings.male_customer)}
          dotColor={!isLoading && data?.male ? "#ffa351" : "#f2f2f7"}
          average_ticket_size={data?.male.average_ticket_size}
          percentage={data?.male.percentage}
          count={numberFormatter(data?.male.count)}
        />
        <div className={styles.separator} />
        <GenderSegment
          gender={t(Strings.female_customer)}
          dotColor={!isLoading && data?.female ? "#ffd1a8" : "#f2f2f7"}
          isLoading={isLoading}
          average_ticket_size={data?.female.average_ticket_size}
          count={numberFormatter(data?.female.count)}
          percentage={data?.female.percentage}
        />
      </div>
    </div>
  );
};

export default GenderSegments;
