import { action, payload } from "ts-action";
import {
    removeFraudlentCustomerPoints,
    blockFraudlentCustomer,
    reviewFraudlentCustomer
} from "../../constants";


// remove actions
export const removeFraudlentCustomerPointsRequested = action(
    removeFraudlentCustomerPoints.requested,
    payload<{ phone_number: string }>()
);
export const removeFraudlentCustomerPointsSuccess = action(
    removeFraudlentCustomerPoints.fulfilled,
    payload<{ phone_number: string, state: "none" | "points-remove" | "blocked" | "reviewed" }>()
);
export const removeFraudlentCustomerPointsFailure = action(
    removeFraudlentCustomerPoints.rejected,
    payload<Error>()
);

// block actions
export const blockFraudlentCustomerRequested = action(
    blockFraudlentCustomer.requested,
    payload<{ phone_number: string }>()
);
export const blockFraudlentCustomerSuccess = action(
    blockFraudlentCustomer.fulfilled,
    payload<{ phone_number: string, state: "none" | "points-remove" | "blocked" | "reviewed" }>()
);
export const blockFraudlentCustomerFailure = action(
    blockFraudlentCustomer.rejected,
    payload<Error>()
);



export const reviewFraudlentCustomerRequested = action(
    reviewFraudlentCustomer.requested,
    payload<{ phone_number: string, body: { is_reviewed: 0 | 1 } }>()
);
export const reviewFraudlentCustomerSuccess = action(
    reviewFraudlentCustomer.fulfilled,
    payload<{ phone_number: string, is_reviewed: 0 | 1 }>()
);
export const reviewFraudlentCustomerFailure = action(
    reviewFraudlentCustomer.rejected,
    payload<Error>()
);

// update actions
// to be added later

