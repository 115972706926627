import React from 'react';
import i18n from '../../../../../i18n/i18n';
import styles from "./styles.module.sass";
import {
  InitialCountryIsoCodeProps,
} from './types';

export const InitialCountryIsoCode: React.FC<InitialCountryIsoCodeProps> = (props) => {
  const {
    initialCountry,
  } = props;

  const {
    language,
  } = i18n;

  return (
    <div className={styles.container}>
      {language === 'en' && (
        <>
          <span className={styles.flag}>
            {initialCountry?.icon}
          </span>
          <span className={styles.isoCodeContainer}>
            {initialCountry?.code}
          </span>

          <span className={styles.getIconContainer}>
            {initialCountry?.en_name}
          </span>
        </>
      )}
      {language === 'ar' && (
        <>
          <span className={styles.getIconContainer} >
            {initialCountry?.ar_name}
          </span>
          <span className={styles.isoCodeContainer}>
            {initialCountry?.code}
          </span>
          <span className={styles.flag}>
            {initialCountry?.icon}
          </span>
        </>
      )}
    </div>
  )
}