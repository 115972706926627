import axios from "./axios";
import * as Requests from "./requests";

export const setPaymentMethodApi = (
  token: string,
  payment_methods: string[],
  pos_online_payment_method_id?: string
) => {
  return axios.put(
    Requests.setPaymentMethodURL,
    { payment_methods, pos_online_payment_method_id },
    {
      headers: {
        token,
        no_escaping: "true",
      },
      baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
    }
  );
};
