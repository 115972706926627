import * as requests from "./requests";
import axios from "./axios";
import { IMenu } from "../lib/types";

export const getOrderingMenus = (token: string) => {
  return axios.get<{ menus: IMenu[] }>(requests.ORDERING_MENU_API, {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
  });
};
