import { put, select, takeLatest } from "redux-saga/effects";
import { editBranchAction } from "../../constants";
import { IBranch } from "../../types/shared";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { editBranch } from "../../axios/editBranch";
import { deleteOptimisticProps } from "./editStoreItemSaga";
import { delay } from "redux-saga/effects";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";
import { trackEvent } from "../../tracking/trackEvent";
import { IEditBranchAction } from "../../types/wizard-types";
import { editBranchZones } from "../../Components/Pickup/redux-store/actions/edit-branch-zones-action";
import { defaultRetry } from "../../Components/WizardComponents/Branches/AddBranchForm/redux/getSingleBranchSaga";

function* editBranchSaga(action: IEditBranchAction) {
  const { payload } = action;
  try {
    const token = yield select(selectToken);
    const editedBranchRes = yield defaultRetry(
      editBranch,
      token,
      deleteOptimisticProps(payload) as any
    );
    const branch = editedBranchRes.data as IBranch;
    yield put(actions.editBranchSuccess({ ...payload, ...branch }));
    trackEvent(EVENTS_FOR_TRACKING["Edited a cashier or a branch"]);
    if (
      editedBranchRes.status === 200 &&
      action.type === editBranchAction.requested
    ) {
      yield put(actions.getBranches());
    }
  } catch (e) {
    yield delay(1000);
    yield put(actions.editBranchFailure(payload));
  }
}

export function* watchEditBranchSaga() {
  yield takeLatest(
    [editBranchAction.requested, editBranchZones.type],
    editBranchSaga
  );
}
