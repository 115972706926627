import React, { useContext, useEffect, useState } from "react";
import KoinzBrandVisuals from "../../Components/WizardComponents/BrandVisuals/BrandVisuals";
import AppSwitcher from "./components/AppSwitcher";
import styles from "./styles.module.sass";
import { useTranslation } from "../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../i18n/strings/brandVisuals";
import BreadCrumbs from "../../contexts/BreadCrumbs";
import NavigationStrings from "../../i18n/strings/navigation";
import BannerCards from "./components/BannerCards";
import WhiteLabelMobileMockup from "./components/WhiteLabelMobileMockup";
import { useDispatch } from "react-redux";
import { getBannerCards } from "../../redux-store/actions/brand-visuals-actions";
import { useSelect } from "../../hooks/useSelect";

export const BrandVisuals: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("brandVisuals");
  const { setCrumbs } = useContext(BreadCrumbs);
  const { store } = useSelect((state) => state.storeReducer);
  const [isWhiteLabeled, setIsWhiteLabeled] = useState(false);
  useEffect(() => {
    setCrumbs([
      { name: NavigationStrings.brandListing, url: "", disabled: true },
      { name: NavigationStrings.brandVisuals, url: "/brand/visuals" },
    ]);
  }, [setCrumbs]);

  useEffect(() => {
    dispatch(getBannerCards());
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <span className={styles.headerTitle}>{t(Strings.title)}</span>
        {store.has_white_label && store?.name && (
          <AppSwitcher
            isWhiteLabeled={isWhiteLabeled}
            onToggleActive={setIsWhiteLabeled}
            className={styles.appSwitcher}
          />
        )}
      </div>

      {isWhiteLabeled && store.has_white_label ? (
        <div className={styles.whiteLabeledWrapper}>
          <BannerCards />
          <WhiteLabelMobileMockup />
        </div>
      ) : (
        <KoinzBrandVisuals />
      )}
    </div>
  );
};

export default BrandVisuals;
