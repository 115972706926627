import { takeLatest, select, call } from "redux-saga/effects";
import {
  deleteOrderingSuggestedModifiersGroup,
  deleteOrderingSuggestedModifiersGroupFailure,
  deleteOrderingSuggestedModifiersGroupSuccess,
} from "../actions/modifierGroupsAction";
import { handleOptimism } from "./handleOptimism";
import { selectToken } from "../../../../redux-store/selectors";
import { deleteSuggestedModifiersGroupAPI } from "../../axios/deleteSuggestedModifiersGroup";
import { union } from "ts-action";
import { translateMenuError } from "./changeItemCategorySaga";
import menuErrorMessages from "../../i18n/strings/menuErrorMessages";
import { customNotify } from "../../SharedComponent/Notification";

const actionType = union({ deleteOrderingSuggestedModifiersGroup } as any);
function* deleteSuggestedModifiersGroupSaga(action: any) {
  try {
    const token = yield select(selectToken);
    yield call(deleteSuggestedModifiersGroupAPI, token, action.payload);
  } catch (e) {
    customNotify.error(
      translateMenuError(menuErrorMessages.deleteSuggestedModifierGroup)
    );
    throw e;
  }
}

export function* watchDeleteSuggestedModifiersGroupSaga() {
  yield takeLatest(
    deleteOrderingSuggestedModifiersGroup.type,
    handleOptimism(deleteSuggestedModifiersGroupSaga, {
      failure: deleteOrderingSuggestedModifiersGroupFailure as any,
      success: deleteOrderingSuggestedModifiersGroupSuccess as any,
    })
  );
}
