import { numberFormatter } from "../../../../../Utils/numbers";
import { translate } from "../../../../../helpers/translate";
import Strings from "../../../../../i18n/Manager/strings/insights";
import i18n from "../../../../../i18n/i18n";
import styles from "./styles.module.sass";
import humanizeDuration from "humanize-duration";
const t = translate("insights");
export const columns = (currency, meta) => [
  {
    title: t(Strings.branch_name),
    render: (obj) => (
      <span>
        {i18n.language.includes("ar") && obj.ar_name ? obj.ar_name : obj.name}
      </span>
    ),
    key: i18n.language.includes("ar") ? "ar_name" : "name",
    sorter: false,
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: t(Strings.average_ticket_size),
    dataIndex: "",
    key: "",
    render: (obj) => (
      <span>
        {numberFormatter(
          Number(Number(obj.total_invoices) / Number(obj.total_transactions))
        )}{" "}
        {currency}
      </span>
    ),
  },
  {
    title: t(Strings.redemptions),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => <span>{obj.total_redemptions || 0}</span>,
  },
  {
    title: t(Strings.customers),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => <span>{obj.total_customers || 0}</span>,
  },
  {
    title: t(Strings.transactions),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => <span>{obj.total_transactions || 0}</span>,
  },
  {
    title: t(Strings.acceptance_rate),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => (
      <span>
        {(
          (obj.accepted_orders /
            (obj.total_orders - obj.canceled_by_customer_orders)) *
            100 || 0
        ).toFixed(2)}{" "}
        %
      </span>
    ),
  },
  {
    title: t(Strings.time_to_accept),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => (
      <span>
        {humanizeDuration(obj.average_time_to_accept, {
          language: i18n.language === "ar" ? "ar" : "en",
          maxDecimalPoints: 0,
        })}
      </span>
    ),
  },
  {
    title: t(Strings.fulfillment_rate),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => (
      <span>
        {(
          (obj.completed_orders /
            (obj.total_orders - obj.canceled_by_customer_orders)) *
            100 || 0
        ).toFixed(2)}{" "}
        %
      </span>
    ),
  },
  {
    title: t(Strings.rate),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => (
      <span>
        {obj.average_reviews_rating ? `${obj.average_reviews_rating}/5` : "-"}
      </span>
    ),
  },
  {
    title: t(Strings.status),
    dataIndex: "",
    key: "",
    sorter: false,
    render: (obj) => {
      const fulfillmentRate =
        (obj.completed_orders /
          (obj.total_orders - obj.canceled_by_customer_orders)) *
        100;
      const timeToAccept = Number(obj.average_time_to_accept);
      const on_track =
        fulfillmentRate >= meta.on_track_branches_fulfillment_rate_threshold &&
        timeToAccept <= meta.on_track_branches_time_to_accept_threshold;
      return (
        <div className={styles.statusWrapper}>
          <div
            style={{
              marginRight: i18n.language === "ar" ? 0 : "8px",
              marginLeft: i18n.language === "ar" ? "8px" : 0,
            }}
            className={!!on_track ? styles.onTrackDot : styles.offTrackDot}
          />
          <span className={styles.statusTitle}>
            {!!on_track ? t(Strings.on_track) : t(Strings.off_track)}
          </span>
        </div>
      );
    },
  },
];
