import styles from "./styles.module.sass";
import { translate } from "../../../../../../../../helpers/translate";
import Strings from "../../../../../../../../i18n/Manager/strings/home";
import i18n from "../../../../../../../../i18n/i18n";
const t = translate("home");
export const columns: any[] = [
  {
    title: <span className={styles.branchTitle}>{t(Strings.branch_name)}</span>,
    dataIndex: "branch_name",
    width: 165,
    key: "branch_name",
    render(text, record) {
      return {
        props: {
          style: {
            background: "#f9f9fb",
            padding: "0",
          },
        },
        children: <div>{text}</div>,
      };
    },
    fixed: "left",
  },

  {
    title: (
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t(Strings.acceptance_rate)}</span>
        <div className={styles.sortingWrapper}></div>
      </div>
    ),
    dataIndex: "acceptance_rate",
    width: 127,
    key: "acceptance_rate",
  },
  {
    title: (
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t(Strings.time_to_accept)}</span>
        <div className={styles.sortingWrapper}></div>
      </div>
    ),
    dataIndex: "time_to_accept",
    key: "time_to_accept",
    width: 150,
  },
  {
    title: (
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t(Strings.fulfillment_rate)}</span>
        <div className={styles.sortingWrapper}></div>
      </div>
    ),
    dataIndex: "fulfillment_rate",
    key: "fulfillment_rate",
    width: 117,
  },
  {
    title: (
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t(Strings.status)}</span>
        <div className={styles.sortingWrapper}></div>
      </div>
    ),
    dataIndex: "status",
    render(text, record) {
      return {
        props: {
          style: {
            padding: "10.5px 12px !important",
          },
        },
        children: <div>{text}</div>,
      };
    },
    width: i18n.language === "ar" ? 120 : 100,
    key: "status",
    fixed: "right",
  },
];
