export const ArrowIcon: React.FC<{
  width: string;
  height: string;
  fill: string;
}> = ({ fill, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={"none"}
      viewBox="0 0 12 12"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3 5l3 3 3-3"
      />
    </svg>
  );
};

export default ArrowIcon;
