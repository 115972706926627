import * as constants from "../constants";
import { IEditStoreItemData } from "../axios/editStoreItem";
import { IEditCategory } from "../axios/editCategory";
import { ACTION_PASS_ON_FORM_VALUES } from "../constants";
import { ISelectItemData } from "../Components/FormElements/RenderDropDownSelect";
import { IEditLoyaltyItemData } from "../axios/editLoyaltyItem";
import { IBranch, IOptimisticObject, PickupStatus } from "./shared";
import { QRPortalTypes } from "../Components/Pickup/lib/types";
import { AxiosError } from "axios";

// import {IEditLoyaltyItemData} from "../axios/editLoyaltyItem";

export interface IExcelItem {
  name: string;
  price: number;
}

export interface IStore {
  noMenuSync: 1 | 0;
  facebook_link: string;
  bio: string;
  background_color: string;
  instagram_link: string;
  location: string;
  cover_image: string;
  store_image: string;
  allow_receipt_code: number;
  snapchat_link: string;
  twitter_link: string;
  created_at: number;
  converting_ratio: number;
  country_code: string;
  country_iso_code: string;
  updated_at: number;
  pos_loyalty_enabled: 1 | 0;
  pos_ordering_enabled: 1 | 0;
  noSync: 1 | 0;
  expiration_period: number;
  name: string;
  translated_name?: string;
  ar_name?: string;
  en_name?: string;
  id: string;
  status: number;
  pos: string;
}

export interface IExcelSheetData {
  name: string;
  items: IExcelItem[];
}

export interface IStep {
  created_at: number;
  end_time: number;
  start_time: number;
  id: string;
  updated_at: number;
  key: number;
}

export const OptimisticSuccess = 0;
export const OptimisticLoading = 1;
export const OptimisticFailure = -1;

export interface IPassOnFormValuesAction {
  type: typeof ACTION_PASS_ON_FORM_VALUES;
  payload: (formValues) => void;
}

export interface IGetStoreItemsAction {
  type: typeof constants.getStoreItemsAction.requested;
}

export interface IGetStoreItemsSuccessAction {
  type: typeof constants.getStoreItemsAction.fulfilled;
  payload: ICataloguedItems[];
}

export interface IGetStoreItemsFailureAction {
  type: typeof constants.getStoreItemsAction.rejected;
  payload: AxiosError;
}

export interface ICUDItemsAction {
  type: string;
  payload: ICategorizedItem;
}

export interface IEditStoreItemAction extends ICUDItemsAction {
  type: typeof constants.editStoreItemAction.requested;
  payload: IEditStoreItemData;
}

export interface IEditStoreItemSuccessAction {
  type: typeof constants.editStoreItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IEditStoreItemFailureAction {
  type: typeof constants.editStoreItemAction.rejected;
  payload: IEditStoreItemData;
}

export interface IAddStoreItemAction {
  type: typeof constants.addStoreItemAction.requested;
  payload: IAddStoreItemData & IOptimisticObject;
}

export interface IAddStoreItemSuccessAction {
  type: typeof constants.addStoreItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IAddStoreItemFailureAction {
  type: typeof constants.addStoreItemAction.rejected;
  payload: IAddStoreItemData & IOptimisticObject;
}

export interface IDeleteStoreItemAction {
  type: typeof constants.deleteStoreItemAction.requested;
  payload: IStoreItem;
}

export interface IDeleteStoreItemSuccessAction {
  type: typeof constants.deleteStoreItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IDeleteStoreItemFailureAction {
  type: typeof constants.deleteStoreItemAction.rejected;
  payload: IStoreItem;
}

export interface IIdentifiedObject {
  id: string;
}

export interface IOrderedObject extends IIdentifiedObject {
  order: number;
}

export interface ICataloguedItems extends IOrderedObject, IOptimisticObject {
  name: string;
  deleted?: boolean;
  id: string;
  items: IStoreItem[];
  integration_id?: string;
}

export interface ICategory
  extends IOrderedObject,
  IOptimisticObject<ICategory[]> {
  id: string;
  name: string;
  order: number;
  fallBack?: ICategory[];
}

export interface IStoreItem extends IOrderedObject, IOptimisticObject {
  id: string;
  is_special: number;
  is_voucher?: number;
  name: string;
  price: number;
  is_hidden: number;
  is_in_loyalty_program: number;
  category_id: string;
  integration_id?: string;
  image?: string;
}

export interface IMenuFilters {
  name?: string;
  category?: ISelectItemData;
}

export interface IGetCategoriesAction {
  type: typeof constants.getCategoriesAction.requested;
}

export interface IFilterMenu {
  type: typeof constants.ACTION_FILTER_ADD_FROM_MENU;
  payload: IMenuFilters;
}

export interface IGetCategoriesSuccessAction {
  type: typeof constants.getCategoriesAction.fulfilled;
  payload: ICategory[];
}

export interface IGetCategoriesFailureAction {
  type: typeof constants.getCategoriesAction.rejected;
  payload: AxiosError;
}

export interface IEditCategoryAction {
  type: typeof constants.editCategoryAction.requested;
  payload: IEditCategory;
}

export interface IEditCategorySuccessAction {
  type: typeof constants.editCategoryAction.fulfilled;
  payload: ICategory;
}

export interface IEditCategoryFailureAction {
  type: typeof constants.editCategoryAction.rejected;
  payload: IEditCategory;
}

export interface IAddCategoryAction {
  type: typeof constants.addCategoryAction.requested;
  payload: IOptimisticObject & {
    name: string;
    id: string;
  };
}

export interface IAddCategorySuccessAction {
  type: typeof constants.addCategoryAction.fulfilled;
  payload: IOptimisticObject & ICategory;
}

export interface IAddCategoryFailureAction {
  type: typeof constants.addCategoryAction.rejected;
  payload: IOptimisticObject;
}

export interface IDeleteCategoryAction {
  type: typeof constants.deleteCategoryAction.requested;
  payload: ICataloguedItems;
}

export interface IDeleteCategorySuccessAction {
  type: typeof constants.deleteCategoryAction.fulfilled;
}

export interface IDeleteCategoryFailureAction {
  type: typeof constants.deleteCategoryAction.rejected;
  payload: ICataloguedItems;
}

export interface ICategorizedItem {
  category_id: string;
}

export interface IAddStoreItemData extends ICategorizedItem {
  name: string;
  ar_name?: string;
  en_name?: string;
  order: number;
  price: number;
  image: File[];
  integration_id?: string;
}

export interface IAddSpecialItemData {
  name: string;
  price: number;
  image: File[];
  promo_id?: string;
  promo_code?: string;
  is_voucher?: 1 | 0;
}

export interface IEditSpecialItemData
  extends ICategorizedItem,
  IIdentifiedObject,
  IOptimisticObject {
  name?: string;
  price?: number;
  image?: File[];
  id: string;
}

export interface IEditLoyaltyItemAction {
  type: typeof constants.editLoyaltyItemAction.requested;
  payload: IEditLoyaltyItemData;
}

export interface IEditLoyaltyItemSuccessAction {
  type: typeof constants.editLoyaltyItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IEditLoyaltyItemFailureAction {
  type: typeof constants.editLoyaltyItemAction.rejected;
  payload: IEditLoyaltyItemData;
}

export interface IAddSpecialItemAction {
  type: typeof constants.addSpecialItemAction.requested;
  payload: IOptimisticObject & IAddSpecialItemData;
}

export interface IAddSpecialItemSuccessAction {
  type: typeof constants.addSpecialItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IAddSpecialItemFailureAction {
  type: typeof constants.addSpecialItemAction.rejected;
  payload: IOptimisticObject & IAddSpecialItemData;
}

export interface IEditSpecialItemAction {
  type: typeof constants.editSpecialItemAction.requested;
  payload: IEditSpecialItemData;
}

export interface IEditSpecialItemSuccessAction {
  type: typeof constants.editSpecialItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IEditSpecialItemFailureAction {
  type: typeof constants.editSpecialItemAction.rejected;
  payload: IEditSpecialItemData;
}

export interface IDeleteSpecialItemAction {
  type: typeof constants.deleteSpecialItemAction.requested;
  payload: IStoreItem;
}

export interface IDeleteSpecialItemSuccessAction {
  type: typeof constants.deleteSpecialItemAction.fulfilled;
  payload: IStoreItem;
}

export interface IDeleteSpecialItemFailureAction {
  type: typeof constants.deleteSpecialItemAction.rejected;
  payload: IStoreItem;
}

export interface IGetGiftListAction {
  type: typeof constants.getGiftListAction.requested;
}

export interface IGetGiftListSuccessAction {
  type: typeof constants.getGiftListAction.fulfilled;
  payload: { specialItems: IStoreItem[]; items: ICataloguedItems[] };
}

export interface IGetGiftListFailureAction {
  type: typeof constants.getGiftListAction.rejected;
  payload: AxiosError;
}

export interface IGetStoreAction {
  type: typeof constants.getStoreAction.requested;
}

export interface IGetStoreSuccessAction {
  type: typeof constants.getStoreAction.fulfilled;
  payload: IStore;
}

export interface IGetStoreFailureAction {
  type: typeof constants.getStoreAction.rejected;
  payload: AxiosError;
}

export interface IGetBranchesAction {
  type: typeof constants.getBranchesAction.requested;
  payload: { [key: string]: any };
}

export interface IGetBranchesSuccessAction {
  type: typeof constants.getBranchesAction.fulfilled;
  payload: IBranch[];
}

export interface IGetBranchesFailureAction {
  type: typeof constants.getBranchesAction.rejected;
  payload: AxiosError;
}

export interface IAddBranchData {
  address: string;
  lat: number;
  lng: number;
  numbers: string[];
  id: string;
  name: string;
  integration_id?: string;
  government_code: string;
}

export interface ISignUpData {
  name: string;
  phone: string;
  password: string;
  email: string;
  role: string;
}
export interface IEditBranchData {
  address?: string;
  lat?: number;
  lng?: number;
  numbers?: string[];
  name?: string;
  id: string;
}

// export const signup = (token): ISignUpData{
//   type: typeof constants.signupAction.requested,
//   payload: token,
// });
// export const signupSuccess = (data): ISignupSuccessAction => ({
//   type: signupAction.fulfilled,
//   payload: data
// });
// export const signupFailure = (error): ISignFailureAction => ({
//   type: signupAction.rejected,
//   payload: error
// });

export interface ISignupAction {
  type: typeof constants.signupAction.requested;
  payload: ISignUpData;
}
export interface ISignupSuccessAction {
  type: typeof constants.signupAction.fulfilled;
  payload: ISignUpData;
}
export interface ISignupFailureAction {
  type: typeof constants.signupAction.rejected;
  payload: ISignUpData;
}
export interface IAddBranchAction {
  type: typeof constants.addBranchAction.requested;
  payload: IOptimisticObject & IAddBranchData;
}

export interface IAddBranchSuccessAction {
  type: typeof constants.addBranchAction.fulfilled;
  payload: IBranch;
}

export interface IEditBranchStatuesAction {
  type: typeof constants.editBranchStatuesAction;
  payload: {
    pickup_branches_ids: any;
    delivery_branches_ids: any;
    drive_thru_branches_ids?: any;
  };
}
export interface IAddBranchFailureAction {
  type: typeof constants.addBranchAction.rejected;
  payload: IAddBranchAction["payload"];
}

export interface IEditBranchAction {
  type: typeof constants.editBranchAction.requested;
  payload: IOptimisticObject & IEditBranchData;
}

export interface IEditBranchSuccessAction {
  type: typeof constants.editBranchAction.fulfilled;
  payload: IBranch;
}

export interface IEditBranchFailureAction {
  type: typeof constants.editBranchAction.rejected;
  payload: IEditBranchAction["payload"];
}

export interface IDeleteBranchAction {
  type: typeof constants.deleteBranchAction.requested;
  payload: IOptimisticObject & IBranch;
}

export interface IDeleteBranchSuccessAction {
  type: typeof constants.deleteBranchAction.fulfilled;
  payload: IBranch;
}

export interface IDeleteBranchFailureAction {
  type: typeof constants.deleteBranchAction.rejected;
  payload: IDeleteBranchAction["payload"];
}

export interface IFormChangedData {
  key: string;
  oldValue?: any;
  newValue: any;
}

export interface IEditStoreAction {
  type: typeof constants.editStoreAction.requested;
  payload: IFormChangedData;
}

export interface IEditStoreSuccessAction {
  type: typeof constants.editStoreAction.fulfilled;
}

export interface IEditStoreFailureAction {
  type: typeof constants.editStoreAction.rejected;
  payload: AxiosError;
}

export interface IResetEditFailed {
  type: typeof constants.ACTION_RESET_EDIT_FAILED;
}

export interface ISearchBranchesAction {
  type: typeof constants.ACTION_SEARCH_BRANCHES;
  payload: string;
}

// edit qr portal branch status

export interface IEditQRPortalBranchStatus {
  type: typeof constants.ACTION_EDIT_QR_PORTAL_BRANCH_STATUS;
  payload: { status: PickupStatus; type: QRPortalTypes; id: string };
}

export type StoreViralityMethods = "fixed_points_value" | "points_percentage";
export interface IStoreViralitySettings {
  value: number | undefined;
  enabled: 0 | 1;
  method: StoreViralityMethods;
}

export interface IRecommendedGift {
  store_id: string;
  image: string;
  updated_at: number;
  price: number;
  is_in_loyalty_program: 0 | 1;
  name: string;
  created_at: 0;
  id: string;
  type: number;
  order: number;
  points: number;
}

// fraud list
export type FraudRankType = "medium" | "suspect" | "high";
export interface IFraudlentCutomer {
  customer_id: string;
  id: string;
  phone_number: string;
  last_visit_data: number;
  current_point: number;
  number_of_visits: number;
  fraud_rank: FraudRankType;
  state: "none" | "points-remove" | "blocked" | "reviewed";
  is_reviewed: 1 | 0;
}

export interface IFilters {
  page?: number;
  size?: number;
}

export interface IFraudlentCustomerListAction {
  type: string;
  payload: IFilters | Error | IFraudlentCutomer[];
}
export interface IGetFraudlentCustomerListRequestedAction {
  type: typeof constants.getFraudlentCustomerList.requested;
  payload: IFilters;
}

export interface IGetFraudlentCustomerListSuccessAction {
  type: typeof constants.getFraudlentCustomerList.fulfilled;
  payload: { data: IFraudlentCutomer[]; hasMore: boolean };
}

export interface IGetFraudlentCustomerListFailureAction {
  type: typeof constants.getFraudlentCustomerList.rejected;
  payload: AxiosError;
}

export interface IRemoveFraudlentCustomerPointsAction {
  type: string;
  payload: { phone_number: string } | Error | any;
}
export interface IRemoveFraudlentCustomerPointsRequestedAction {
  type: typeof constants.removeFraudlentCustomerPoints.requested;
  payload: {
    phone_number: string;
    state: "none" | "points-remove" | "blocked" | "reviewed";
  };
}

export interface IRemoveFraudlentCustomerPointsSuccessAction {
  type: typeof constants.removeFraudlentCustomerPoints.fulfilled;
  payload: {
    phone_number: string;
    state: "none" | "points-remove" | "blocked" | "reviewed";
  };
}

export interface IRemoveFraudlentCustomerPointsFailureAction {
  type: typeof constants.removeFraudlentCustomerPoints.rejected;
  payload: AxiosError;
}

export interface IBlockFraudlentCustomerAction {
  type: string;
  payload:
  | {
    customer_id: string;
    state: "none" | "points-remove" | "blocked" | "reviewed";
  }
  | Error
  | any;
}
export interface IBlockFraudlentCustomerRequestedAction {
  type: typeof constants.blockFraudlentCustomer.requested;
  payload: { phone_number: string };
}

export interface IBlockFraudlentCustomerSuccessAction {
  type: typeof constants.blockFraudlentCustomer.fulfilled;
  payload: {
    phone_number: string;
    state: "none" | "points-remove" | "blocked" | "reviewed";
  };
}

export interface IBlockFraudlentCustomerFailureAction {
  type: typeof constants.blockFraudlentCustomer.rejected;
  payload: AxiosError;
}

export interface IReviewFraudlentCustomerAction {
  type: string;
  payload: {
    phone_number: string;
    body: { is_reviewed: 0 | 1 } | Error | any;
  };
}
export interface IReviewFraudlentCustomerRequestedAction {
  type: typeof constants.reviewFraudlentCustomer.requested;
  payload: { phone_number: string; body: { is_reviewed: 1 | 0 } };
}

export interface IReviewFraudlentCustomerSuccessAction {
  type: typeof constants.reviewFraudlentCustomer.fulfilled;
  payload: { phone_number: string; is_reviewed: 1 | 0 };
}

export interface IReviewFraudlentCustomerFailureAction {
  type: typeof constants.reviewFraudlentCustomer.rejected;
  payload: AxiosError;
}
