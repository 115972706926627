import { takeLatest, put, retry, select } from "redux-saga/effects";
import {
  getBranchDataForEdit,
  getBranchDataForEditFailure,
  getBranchDataForEditSuccess,
} from "./actions/branchFormActions";
import { getBranchById } from "../../../../../axios/getBranchById";
import { selectToken } from "../../../../../redux-store/selectors";
import { union } from "ts-action";

const actionType = union({ getBranchDataForEdit } as any);

export const defaultRetry = <Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
) => retry(5, 3000, fn, ...args);

function* getSingleBranchSaga(action: any) {
  try {
    const token = yield select(selectToken);
    const res = yield retry(
      5,
      5000,
      getBranchById,
      token,
      action.payload.branch_id
    );

    yield put(getBranchDataForEditSuccess(res.data));
  } catch (e) {
    yield put(getBranchDataForEditFailure(e));
  }
}

export function* watchGetSingleBranchSaga() {
  yield takeLatest(getBranchDataForEdit.type, getSingleBranchSaga);
}
