import { put, takeLatest, call, select } from "redux-saga/effects";
import * as actions from "../actions/get-competitions-actions";
import { selectToken } from "../selectors";
import { getCompetitions } from "../../axios/getCompetitions";
import { getCompetitionsAction } from "../../constants";
import { AxiosResponse } from "axios";
import { ICompetition } from "../../axios/types";
import moment from "moment";
import { LeaderBoardItem } from "../../models/LeaderBoardItem";
import { IRootReducerState } from "../rootReducer";
import { IStore } from "../../types";
import { tz } from "moment-timezone";

function* getCompetitionsSaga(action: any) {
  try {
    const dateFormat = "DD MM YYYY";
    const startTimeMoment = moment(action.payload.start_time, dateFormat);

    const store: IStore = yield select(
      (state: IRootReducerState) => state.authReducer.storeData
    );

    const monthsMocks = new Array(12)
      .fill(moment(startTimeMoment).startOf("year"))
      .map((d, i) => {
        return new LeaderBoardItem({
          start_time: moment(d).add(i, "month").format("MM YYYY"),
        });
      });
    const token: string = yield select(selectToken);
    const response: AxiosResponse<ICompetition[]> = yield call(
      getCompetitions,
      token,
      {
        year: +moment(action.payload.start_time).format("YYYY"),
      }
    );
    const leaderBoardsData = (response.data || []).map(
      (d) =>
        new LeaderBoardItem({
          ...d,
          start_time: tz(d.start_time.toString(), "x", store.timezone).format(
            "MM YYYY"
          ),
          end_time: tz(d.end_time.toString(), "x", store.timezone).format(
            "MM YYYY"
          ),
        })
    );
    const mergedLeaderBoards = monthsMocks.map((mockedMonth) => {
      const foundLeaderBoard = leaderBoardsData.find(
        (registeredLeaderBoard) =>
          registeredLeaderBoard.date.format("MMMM YYYY") ===
          mockedMonth.date.format("MMMM YYYY")
      );
      return foundLeaderBoard || mockedMonth;
    });
    yield put(actions.getCompetitionsSuccess(mergedLeaderBoards));
  } catch (e) {
    yield put(actions.getCompetitionsFailure(e));
  }
}

export function* watchGetCompetitionsSaga() {
  yield takeLatest(getCompetitionsAction.requested, getCompetitionsSaga);
}
