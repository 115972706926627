import { getBranchWorkingHoursAction } from "../../../../constants/actions";
import { IStoreWorkingHours } from "../../../../../..//types/Manager";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../withLoadingState";
import {
  getBranchWorkingHoursSuccess,
  clearBranchesWorkingHours,
} from "../../../actions";

interface IState {
  branchesWorkingHours: {
    [branch_id: string]: IStoreWorkingHours;
  };
}
const initialValue: IState = {
  branchesWorkingHours: {},
};

export const branchesWorkingHoursReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getBranchWorkingHoursSuccess, (state, { payload }) => {
        return {
          ...state,
          branchesWorkingHours: { ...state.branchesWorkingHours, ...payload },
        };
      }),
      on(clearBranchesWorkingHours, (state) => ({ branchesWorkingHours: {} })),
    ],
    initialValue
  ),
  getBranchWorkingHoursAction
);
