import axios from "../../axios";
import * as routes from "../../api-routes/Manager/Analytics";

export const getLiveUpdatesHistory = async (
  token: string,
  queryParams: any
) => {
  const data = await axios.get(
    `${process.env.REACT_APP_DASHBOARD_ENDPOINT}${routes.getLiveUpdatesHistory}`,
    {
      params: {
        ...queryParams,
      },
      headers: {
        token,
      },
    }
  );
  return data;
};
