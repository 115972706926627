import { useContext } from "react";
import { useQuery } from "react-query";
import { getTotalOrdersValue } from "../../../axios/Manager/Analytics";
import StoreData from "../../../contexts/StoreData";

export const useGetTotalOrders = (type) => {
  const { token } = useContext(StoreData);
  return useQuery(
    ["total-orders-value", type],
    async () => await getTotalOrdersValue(token, type),
    {
      enabled: !!type,
    }
  );
};
