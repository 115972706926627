import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getOrderingItemsAction } from "../../constants/actions";
import {
  getOrderingItemsSuccess,
  getOrderingItemsFailure,
} from "../actions/itemActions";
import { getOrderingMenuItems } from "../../axios/getOrderingMenuItems";
import { handleOptimism } from "./handleOptimism";
import { Visibility } from "../../lib";

function* getItemSaga(action) {
  const token = yield select(selectToken);
  let res = yield call(
    getOrderingMenuItems,
    token,
    action.payload.category_id
    // action.payload.branch_id
  );
  return res.data || [];
}

export function* watchGetItemSaga() {
  yield takeLatest(
    getOrderingItemsAction.requested,
    handleOptimism(getItemSaga, {
      success: getOrderingItemsSuccess as any,
      failure: getOrderingItemsFailure as any,
    })
  );
}
