import * as Requests from "./requests";
import axios from "./axios";
import { IModifiersGroup } from "../lib";

export const CreateOrderingSuggestedModifierGroup = (
  token: string,
  data: IModifiersGroup
) => {
  return axios.post(Requests.CREATE_ORDERING_MENU_MODIFIERS_GROUPS_API, data, {
    headers: {
      token,
    },
  });
};
