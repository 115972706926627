import * as constants from "../../constants";
import {
  IAddSpecialItemAction,
  IAddSpecialItemData,
  IAddSpecialItemFailureAction,
  IAddSpecialItemSuccessAction,
  ICataloguedItems,
  IDeleteSpecialItemAction,
  IDeleteSpecialItemFailureAction,
  IDeleteSpecialItemSuccessAction,
  IEditLoyaltyItemAction,
  IEditLoyaltyItemFailureAction,
  IEditLoyaltyItemSuccessAction,
  IEditSpecialItemAction,
  IEditSpecialItemData,
  IEditSpecialItemFailureAction,
  IEditSpecialItemSuccessAction,
  IGetGiftListAction,
  IGetGiftListFailureAction,
  IGetGiftListSuccessAction,
  IStoreItem,
} from "../../types/wizard-types";
import { IEditLoyaltyItemData } from "../../axios/editLoyaltyItem";
import { IOptimisticObject } from "../../types/shared";

export const editLoyaltyItem = (
  item: IEditLoyaltyItemData
): IEditLoyaltyItemAction => ({
  type: constants.editLoyaltyItemAction.requested,
  payload: item,
});

export const editLoyaltyItemSuccess = (
  editedLoyaltyItem: IStoreItem
): IEditLoyaltyItemSuccessAction => ({
  type: constants.editLoyaltyItemAction.fulfilled,
  payload: editedLoyaltyItem,
});

export const editLoyaltyItemFailure = (
  error: IEditLoyaltyItemData
): IEditLoyaltyItemFailureAction => ({
  type: constants.editLoyaltyItemAction.rejected,
  payload: error,
});

export const addSpecialItem = (
  data: IAddSpecialItemData & IOptimisticObject
): IAddSpecialItemAction => ({
  type: constants.addSpecialItemAction.requested,
  payload: data,
});

export const addSpecialItemSuccess = (
  specialItem: IStoreItem
): IAddSpecialItemSuccessAction => ({
  type: constants.addSpecialItemAction.fulfilled,
  payload: specialItem,
});

export const addSpecialItemFailure = (
  error: IAddSpecialItemData & IOptimisticObject
): IAddSpecialItemFailureAction => ({
  type: constants.addSpecialItemAction.rejected,
  payload: error,
});

export const editSpecialItem = (
  data: IEditSpecialItemData
): IEditSpecialItemAction => ({
  type: constants.editSpecialItemAction.requested,
  payload: data,
});

export const editSpecialItemSuccess = (
  specialItem: IStoreItem
): IEditSpecialItemSuccessAction => ({
  type: constants.editSpecialItemAction.fulfilled,
  payload: specialItem,
});

export const editSpecialItemFailure = (
  error: IEditSpecialItemData
): IEditSpecialItemFailureAction => ({
  type: constants.editSpecialItemAction.rejected,
  payload: error,
});

export const deleteSpecialItem = (
  data: IStoreItem
): IDeleteSpecialItemAction => ({
  type: constants.deleteSpecialItemAction.requested,
  payload: data,
});

export const deleteSpecialItemSuccess = (
  specialItem: IStoreItem
): IDeleteSpecialItemSuccessAction => ({
  type: constants.deleteSpecialItemAction.fulfilled,
  payload: specialItem,
});

export const deleteSpecialItemFailure = (
  error: IStoreItem
): IDeleteSpecialItemFailureAction => ({
  type: constants.deleteSpecialItemAction.rejected,
  payload: error,
});

export const getGiftList = (): IGetGiftListAction => ({
  type: constants.getGiftListAction.requested,
});

export const getGiftListSuccess = (
  specialItems: IStoreItem[],
  items: ICataloguedItems[]
): IGetGiftListSuccessAction => ({
  type: constants.getGiftListAction.fulfilled,
  payload: { specialItems, items },
});

export const getGiftListFailure = (error): IGetGiftListFailureAction => ({
  type: constants.getGiftListAction.rejected,
  payload: error,
});
