import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getOrderingPortalLinkAction } from "../../constants/actions";
import { getOrderingPortalLinkApi } from "../../axios/getOrderingPortalLink";
import {
  getOrderingPortalLinkSuccess,
  getOrderingPortalLinkFailure,
} from "../actions/ordering-portal-link-action";

function* getOrderingPortalLinkSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getOrderingPortalLinkApi, token);
    yield put(getOrderingPortalLinkSuccess(res.data));
  } catch (e) {
    yield put(getOrderingPortalLinkFailure(e));
  }
}

export function* watchGetOrderingPortalLinkSaga() {
  yield takeLatest(
    getOrderingPortalLinkAction.requested,
    getOrderingPortalLinkSaga
  );
}
