import React, { useContext, useMemo, useState } from "react";
// styles
import styles from "./styles.module.sass";
// assets
import UserIcon from "../../../../../../../../Components/Pickup/assets/feather _ user.svg";
import ArrowRight from "../../../../../../../../Components/Pickup/assets/feather _ chevron-right.svg";
import Bag from "../../../../../../../../Components/Pickup/assets/feather _ bag.svg";
import { RATING_ICONS } from "./emojis";
import { Tooltip } from "antd";
import OrderDetails, {
  useGetCurrency,
} from "../../../../../../../../Components/Pickup/SetupPickup/OrderHistory/PastOrder/PastOrderTable/OrderDetails";
import StoreData from "../../../../../../../../contexts/StoreData";
import i18n from "../../../../../../../../i18n/i18n";
import formatDistance from "date-fns/distance_in_words_to_now";
import ar from "date-fns/locale/ar";
import GiftIconPlaceholder from "../../../../../../../../Components/Pickup/assets/live-updates-food.svg";
import { useRtlClass } from "../../../../../../../../shopx-shared-components/hooks/useRtlClassName";
import Strings from "../../../../../../../../i18n/Manager/strings/home";
import { useTranslation } from "../../../../../../../../shopx-shared-components/hooks/useTranslation";
enum OrderType {
  delivery = "delivery",
  pickup = "pickup",
  offline = "offline",
}

export enum TransactionType {
  onlineOrder = "onlineOrder",
  offlineOrder = "offlineOrder",
  redemption = "redemption",
  review = "review",
}

export enum OrderingSource {
  web_portal = "ordering_portal",
  customer_app = "customer_app",
}

const orderTypeIcon = {
  [OrderingSource.customer_app]:
    require("../../../../../../../../Components/Pickup/assets/feather _ phone.svg")
      .default,
  [OrderingSource.web_portal]:
    require("../../../../../../../../Components/Pickup/assets/feather _ globe.svg")
      .default,
};

interface IProps {
  type: TransactionType;
  newTransaction?: boolean;
  data: any;
  customer: {
    id?: string;
    name?: string;
    image?: string;
    phone_number?: string;
    country_code?: string;
  };
  branch: { ar_name: string; en_name: string };
}
export const LiveUpdateTransaction: React.FC<IProps> = ({
  type,
  data,
  customer,
  branch,
  newTransaction,
}) => {
  const { t } = useTranslation("home");
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const toggleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  const orderTypeTitle = {
    [OrderType.delivery]: t(Strings.delivery_order),
    [OrderType.pickup]: t(Strings.pickup_order),
    [OrderType.offline]: t(Strings.offline_order),
  };

  const orderTypeTooltipText = {
    [OrderingSource.customer_app]: t(Strings.ordered_through_koinz_application),
    [OrderingSource.web_portal]: t(Strings.ordered_through_web_portal),
  };
  const { storeData } = useContext(StoreData);
  const currency = useGetCurrency(storeData?.country_iso_code as string);
  let orderTime;
  if (i18n.language === "ar") {
    orderTime = formatDistance(new Date(data.created_at), {
      addSuffix: true,
      locale: ar,
    });
  } else {
    orderTime = formatDistance(new Date(data.created_at), { addSuffix: true });
  }

  const userImage = useMemo(() => {
    if (customer.image) {
      return process.env.REACT_APP_ENDPOINT + customer.image;
    } else return null;
  }, [customer.image]);

  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [moreItems, setMoreItems] = useState(false);

  const hideOrderItems = useMemo(
    () => data.items && data?.items?.filter((value, index, array) => index > 1),
    [data]
  );

  return (
    <div
      className={`${styles.wrapper} ${useRtlClass(styles)} ${newTransaction && styles.newTransactionWrapper
        }`}
    >
      {openOrderDetails &&
        data?.onlineOrder?.id &&
        type === TransactionType.onlineOrder && (
          <OrderDetails
          order_type={data?.onlineOrder?.order_type}
            id={data?.onlineOrder?.id as string}
            open={openOrderDetails}
            onClose={() => setOpenOrderDetails(false)}
          />
        )}

      {/*Time and Branch*/}
      <div className={styles.headerWrapper}>
        <span
          className={styles.headerTitle}
          data-test-id={"cy-live-updates-order-time"}
        >
          {orderTime}
        </span>
        <span
          className={styles.headerTitle}
          data-test-id={"cy-live-updates-order-branch-name"}
        >
          {i18n.language.includes("en") ? branch.en_name : branch.ar_name}
        </span>
      </div>
      {/* Customer Details */}
      <div className={styles.userWrapper}>
        <div className={styles.avatarWrapper}>
          <img
            className={userImage ? styles.userIconPlaceholder : styles.userIcon}
            src={userImage ? userImage : UserIcon}
          />
          {/* If review_component is true and rating prop has value show RATING ICON */}
          {type === TransactionType.review && (
            <img src={RATING_ICONS[data.rating]} className={styles.emoji} />
          )}
        </div>
        {customer.name ? (
          <span
            data-test-id={"cy-live-updates-customer-phone-number"}
            className={styles.telephone}
          >
            {customer.name}
          </span>
        ) : (
          <span
            data-test-id={"cy-live-updates-customer-phone-number"}
            className={styles.telephone}
          >
            {customer.country_code ?? ""} {customer.phone_number ?? ""}
          </span>
        )}
      </div>
      {/* Order Type */}

      <div className={styles.orderTypeWrapper}>
        {type !== TransactionType.review ? (
          <React.Fragment>
            <Tooltip
              title={
                data.ordering_source ? (
                  <span className={styles.tooltipText}>
                    {orderTypeTooltipText[data.ordering_source]}
                  </span>
                ) : (
                  <span className={styles.tooltipText}>
                    {t(Strings.ordered_through_visiting_the_restaurants)}
                  </span>
                )
              }
            >
              <img
                src={
                  data.ordering_source
                    ? orderTypeIcon[data.ordering_source]
                    : Bag
                }
                className={styles.orderTypeIcon}
              />
            </Tooltip>

            <span className={styles.orderTypeTitle}>
              {" "}
              {type === TransactionType.redemption
                ? t(Strings.redeemed, {
                  order_type: `(${orderTypeTitle[data.type]})`,
                })
                : orderTypeTitle[data.type]}
            </span>
          </React.Fragment>
        ) : (
          <span
            data-test-id={"cy-live-update-transaction-reviewed-order-type"}
            className={styles.orderTypeTitle}
          >
            {t(Strings.reviewed, {
              order_type: `(${orderTypeTitle[data.type]})`,
            })}
          </span>
        )}
      </div>
      {/* Redeemed Items */}
      {type === TransactionType.redemption && data?.gift && (
        <div className={styles.redeemedItemsWrapper}>
          <div className={styles.redeemedItemWrapper}>
            <div className={styles.redeemedItemImagePlaceholderWrapper}>
              <img
                className={
                  data?.gift?.image
                    ? styles.redeemedIcon
                    : styles.redeemedIconPlaceholder
                }
                src={
                  data?.gift?.image
                    ? process.env.REACT_APP_DASHBOARD_ENDPOINT +
                    data?.gift?.image
                    : GiftIconPlaceholder
                }
              />
            </div>

            <span
              data-test-id={`cy-live-update-transaction-gift.${data?.gift?.en_name}`}
              className={styles.redeemedItemTitle}
            >
              {i18n.language === "ar"
                ? data?.gift?.ar_name
                : data?.gift?.en_name}
            </span>
          </div>
        </div>
      )}
      {/* Items */}
      {type === TransactionType.onlineOrder && (
        <React.Fragment>
          {data.items && (
            <div className={styles.itemsWrapper}>
              <span
                data-test-id={"cy-live-updates-first-item"}
                className={styles.item}
              >
                {i18n.language.includes("en")
                  ? `${data.items[0].quantity ? `${data.items[0].quantity}x` : ""
                  } ${data.items[0].en_name}`
                  : data.items[0].ar_name
                    ? `${data.items[0].ar_name} ${data.items[0].quantity ? `x${data.items[0].quantity}` : ""
                    }`
                    : `${data.items[0].quantity ? `${data.items[0].quantity}x` : ""
                    } ${data.items[0].en_name}`}
              </span>
              {data.items[1] && (
                <span
                  className={styles.item}
                  data-test-id={"cy-live-updates-second-item"}
                >
                  {i18n.language.includes("en")
                    ? `${data.items[1].quantity
                      ? `${data.items[1].quantity}x`
                      : ""
                    } ${data.items[1].en_name}`
                    : data.items[1].ar_name
                      ? `${data.items[1].ar_name} ${data.items[1].quantity
                        ? `x${data.items[1].quantity}`
                        : ""
                      }`
                      : `${data.items[1].quantity
                        ? `${data.items[1].quantity}x`
                        : ""
                      } ${data.items[1].en_name}`}
                </span>
              )}
              {moreItems && (
                <React.Fragment>
                  {hideOrderItems.map((item, index) => (
                    <React.Fragment>
                      <span
                        key={index}
                        className={styles.item}
                        data-test-id={"cy-live-updates-second-item"}
                      >
                        {i18n.language.includes("en")
                          ? `${item.quantity ? `${item.quantity}x` : ""} ${item.en_name
                          }`
                          : item.ar_name
                            ? `${item.ar_name} ${item.quantity ? `x${item.quantity}` : ""
                            }`
                            : `${item.quantity ? `${item.quantity}x` : ""} ${item.en_name
                            }`}
                      </span>
                    </React.Fragment>
                  ))}
                  <span
                    onClick={() => setMoreItems(!moreItems)}
                    className={styles.moreItems}
                    data-test-id={"cy-live-updates-more-items"}
                  >
                    {t(Strings.less)}
                  </span>
                </React.Fragment>
              )}
              {data.items.length > 2 && !moreItems && (
                <span
                  onClick={() => setMoreItems(!moreItems)}
                  className={styles.moreItems}
                  data-test-id={"cy-live-updates-more-items"}
                >
                  {t(Strings.more_items, {
                    count: data.items.length - 2,
                  })}
                </span>
              )}
            </div>
          )}
        </React.Fragment>
      )}

      {/* Review */}
      {type === TransactionType.review && (
        <div className={styles.reviewWrapper}>
          {/* Tags */}
          <div className={styles.categoriesAndTagsChips}>
            {/* Tags */}
            {(data.categories || []).map((tag, index) => (
              <div className={styles.categoriesChips} key={index}>
                <div
                  className={
                    tag.selected
                      ? data.rating <= 2
                        ? styles.categoryRedChip
                        : styles.categoryChip
                      : data.rating <= 2
                        ? styles.tagRedChip
                        : styles.tagChip
                  }
                >
                  <span
                    data-test-id={"cy-live-update-transaction-tag"}
                    className={tag.selected ? styles.category : styles.tag}
                  >
                    {i18n.language.includes("en")
                      ? tag.en_name
                      : tag.ar_name
                        ? tag.ar_name
                        : tag.en_name}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Text */}
          {type === TransactionType.review && data.feedback && (
            <div
              className={styles.text}
              data-test-id={"cy-live-update-transaction-review-text"}
            >
              {seeMore
                ? data.feedback?.slice(0, data.feedback.length)
                : data.feedback?.slice(0, 135) +
                `${String(data.feedback)?.length > 135 && !seeMore ? "..." : ""
                }`}
              {String(data.feedback)?.length > 135 && (
                <span className={`${styles.readMore}`} onClick={toggleSeeMore}>
                  {seeMore ? t(Strings.show_less) : t(Strings.show_more)}
                </span>
              )}
            </div>
          )}
        </div>
      )}

      {/* Invoice */}
      {(type === TransactionType.onlineOrder ||
        type === TransactionType.offlineOrder) && (
          <div className={styles.invoiceWrapper}>
            <span
              data-test-id={"cy-live-update-transaction-total-invoice"}
              className={styles.totalInvoice}
            >
              {t(Strings.total, {
                total_price: data.total_invoice,
                currency: currency,
              })}
            </span>
            {openOrderDetails && type === TransactionType.onlineOrder && (
              <div
                onClick={() => setOpenOrderDetails(true)}
                className={styles.viewOrderDetailsLink}
              >
                <span className={styles.viewOrderDetailsTitle}>
                  {" "}
                  {t(Strings.view_order_details)}
                </span>
                <img src={ArrowRight} className={styles.arrowRight} />
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default LiveUpdateTransaction;
