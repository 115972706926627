export default {
  toUnlockThisGift: "ليربح هذه الجائزة",
  customerWillPay: "سيدفع عميلك",
  somethingWentWrong: "حدث خطأُ ما!",
  cashToPointsRateTitle: "معدل تحويل الفواتير إلى نقاط",
  cashToPointsRateSubtitle: "كم مما سيدفعه عملائك يعود إليهم على هيئة نقاط.",
  ifYourRateIs: "إذا كان معدل تحويلك:",
  notRecommended: "لا يوصى به",
  recommended: "يوصى به",
  markComplete: "إكمال الخطوة",
  progressAutoSaved: "سيتم حفظ تقدمك تلقائياً",
};
