import axios from "./axios";
import * as Requests from "./requests";
import { IModifiersGroup } from "../lib";

export const getOrderingMenuModifierGroups = (token) => {
  return axios.get<IModifiersGroup[]>(
    Requests.ORDERING_MENU_MODIFIER_GROUPS_API,
    {
      headers: {
        token,
      },
    }
  );
};
