import * as constants from "../../constants";
import {
  IAddStoreItemAction,
  IAddStoreItemData,
  IAddStoreItemFailureAction,
  IAddStoreItemSuccessAction,
  ICataloguedItems,
  IDeleteStoreItemAction,
  IDeleteStoreItemFailureAction,
  IDeleteStoreItemSuccessAction,
  IEditStoreItemAction,
  IEditStoreItemFailureAction,
  IEditStoreItemSuccessAction,
  IGetStoreItemsAction,
  IGetStoreItemsFailureAction,
  IGetStoreItemsSuccessAction,
  IStoreItem,
} from "../../types/wizard-types";
import { IEditStoreItemData } from "../../axios/editStoreItem";
import { IOptimisticObject } from "../../types/shared";
import { AxiosError } from "axios";

export const getStoreItems = (): IGetStoreItemsAction => ({
  type: constants.getStoreItemsAction.requested,
});

export const getStoreItemsSuccess = (
  payload: ICataloguedItems[]
): IGetStoreItemsSuccessAction => ({
  type: constants.getStoreItemsAction.fulfilled,
  payload,
});

export const getStoreItemsFailure = (
  error: AxiosError
): IGetStoreItemsFailureAction => ({
  type: constants.getStoreItemsAction.rejected,
  payload: error,
});

export const editStoreItem = (
  dataToEdit: IEditStoreItemData
): IEditStoreItemAction => ({
  type: constants.editStoreItemAction.requested,
  payload: dataToEdit,
});

export const editStoreItemSuccess = (
  editedStoreItem: IStoreItem
): IEditStoreItemSuccessAction => ({
  type: constants.editStoreItemAction.fulfilled,
  payload: editedStoreItem,
});

export const editStoreItemFailure = (
  error: IEditStoreItemData
): IEditStoreItemFailureAction => ({
  type: constants.editStoreItemAction.rejected,
  payload: error,
});

export const addStoreItem = (
  data: IAddStoreItemData & IOptimisticObject
): IAddStoreItemAction => ({
  type: constants.addStoreItemAction.requested,
  payload: data,
});

export const addStoreItemSuccess = (
  addedStoreItem: IStoreItem
): IAddStoreItemSuccessAction => ({
  type: constants.addStoreItemAction.fulfilled,
  payload: addedStoreItem,
});

export const addStoreItemFailure = (
  error: IAddStoreItemData & IOptimisticObject
): IAddStoreItemFailureAction => ({
  type: constants.addStoreItemAction.rejected,
  payload: error,
});

export const deleteStoreItem = (data: IStoreItem): IDeleteStoreItemAction => ({
  type: constants.deleteStoreItemAction.requested,
  payload: data,
});

export const deleteStoreItemSuccess = (
  deletedStoreItem: IStoreItem
): IDeleteStoreItemSuccessAction => ({
  type: constants.deleteStoreItemAction.fulfilled,
  payload: deletedStoreItem,
});

export const deleteStoreItemFailure = (
  error: IStoreItem
): IDeleteStoreItemFailureAction => ({
  type: constants.deleteStoreItemAction.rejected,
  payload: error,
});
