import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ICompetitionEdit } from "../types";

export const editCompetition = (
  data: ICompetitionEdit,
  token
): AxiosReturn<{}> => {
  return axios.put(Requests.editLeaderBoards(data.id), data, {
    baseURL: process.env.REACT_APP_LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
