import { action, payload } from "ts-action";
import {
  ACTION_FILTER_LOYALTY_MENU_ITEMS_BY_CATEGORY,
  ACTION_SEARCH_LOYALTY_MENU_ITEMS,
  addLoyaltySpecialItemAction,
  deleteLoyaltySpecialItemAction,
  editLoyaltyMenuItemAction,
  editLoyaltySpecialItemAction,
  getLoyaltyMenuAction,
  getLoyaltySpecialItemsAction,
} from "../../../constants";
import {
  IAddSpecialItemData,
  ICataloguedItems,
  IStoreItem,
} from "../../../types/wizard-types";

export type EditLoyaltyMenuItemPayload = {
  id: string;
  is_in_loyalty_program?: number;
  name?: string;
  price?: number;
  image?: string;
  integration_id?: string;
  category_id: string;
};
export const getLoyaltyMenu = action(getLoyaltyMenuAction.requested);
export const getLoyaltyMenuSuccess = action(
  getLoyaltyMenuAction.fulfilled,
  payload<ICataloguedItems[]>()
);
export const getLoyaltyMenuFailure = action(
  getLoyaltyMenuAction.rejected,
  payload<Error>()
);

export const editLoyaltyMenuItem = action(
  editLoyaltyMenuItemAction.requested,
  payload<EditLoyaltyMenuItemPayload>()
);
export const editLoyaltyMenuItemSuccess = action(
  editLoyaltyMenuItemAction.fulfilled,
  payload<IStoreItem>()
);

export const editLoyaltyMenuItemFailure = action(
  editLoyaltyMenuItemAction.rejected,
  payload<Error>()
);

export const searchLoyaltyMenuItems = action(
  ACTION_SEARCH_LOYALTY_MENU_ITEMS,
  payload<string>()
);

export const filterLoyaltyMenuItemsByCategory = action(
  ACTION_FILTER_LOYALTY_MENU_ITEMS_BY_CATEGORY,
  payload<string | undefined>()
);

export const getLoyaltySpecialItems = action(
  getLoyaltySpecialItemsAction.requested
);
export const getLoyaltySpecialItemsSuccess = action(
  getLoyaltySpecialItemsAction.fulfilled,
  payload<IStoreItem[]>()
);
export const getLoyaltySpecialItemsFailure = action(
  getLoyaltySpecialItemsAction.rejected,
  payload<Error>()
);

export const deleteLoyaltySpecialItem = action(
  deleteLoyaltySpecialItemAction.requested,
  payload<string>()
);

export const deleteLoyaltySpecialItemSuccess = action(
  deleteLoyaltySpecialItemAction.fulfilled,
  payload<string>()
);

export const deleteLoyaltySpecialItemFailure = action(
  deleteLoyaltySpecialItemAction.rejected,
  payload<Error>()
);

export const editLoyaltySpecialItem = action(
  editLoyaltySpecialItemAction.requested,
  payload<EditLoyaltyMenuItemPayload & { state: number }>()
);
export const editLoyaltySpecialItemSuccess = action(
  editLoyaltySpecialItemAction.fulfilled,
  payload<EditLoyaltyMenuItemPayload & { state: number }>()
);
export const editLoyaltySpecialItemFailure = action(
  editLoyaltySpecialItemAction.rejected,
  payload<Error>()
);

export const addLoyaltySpecialItem = action(
  addLoyaltySpecialItemAction.requested,
  payload<IAddSpecialItemData>()
);

export const addLoyaltySpecialItemSuccess = action(
  addLoyaltySpecialItemAction.fulfilled,
  payload<IStoreItem>()
);

export const addLoyaltySpecialItemFailure = action(
  addLoyaltySpecialItemAction.rejected,
  payload<Error>()
);
