import { useQuery } from "react-query";
import { getRedemptionsGraphReq } from "../../../axios/Manager/Analytics";

export const useGetRedemptionsChartQuery = (token, requestConfigs) => {
  const result = useQuery(
    ["redemptionsGraph", requestConfigs],
    () => {
      if (!requestConfigs.store_id) return [];
      return getRedemptionsGraphReq(token, {
        ...requestConfigs,
      }).then((res) => res.data);
    },
    {
      enabled: !!requestConfigs,
    }
  );

  return result;
};
