import { action, payload } from "ts-action";
import { getUserRankingAction } from "../../constants";
import { IUsersResponse } from "../../axios/getUsers";

interface IGetUserRankingParams {
  leaderboard_id: string;
  page: number;
  size: number;
  user_type?: number;
}

export interface IGetUserRankingSuccessData {
  page: number;
  data: IUsersResponse;
  pagesCount: number;
}

export const getUserRanking = action(
  getUserRankingAction.requested,
  payload<IGetUserRankingParams>()
);
export const getUserRankingSuccess = action(
  getUserRankingAction.fulfilled,
  payload<IGetUserRankingSuccessData>()
);
export const getUserRankingFailure = action(
  getUserRankingAction.rejected,
  payload<Error>()
);
