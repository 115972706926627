export default {
  email: "email",
  enter_email: "enter_email",
  invalid_email: "invalid_email",
  password: "password",
  enter_password: "enter_password",
  invalid_password: "invalid_password",
  log_in: "log_in",
  logging_in: "logging_in",
  quick_facts_about: "quick_facts_about",
  todays_progress: "todays_progress",
  transactions: "transactions",
  analyze_transactions: "analyze_transactions",
  analyze_redemptions: "analyze_redemptions",
  analyze_feedback: "analyze_feedback",
  from_yesterday: "from_yesterday",
  live_updates: "live_updates",
  new_customers: "new_customers",
  returning_customers: "returning_customers",
  customer_segment: "customer_segment",
  time: "time",
  branch: "branch",
  customer_type: "customer_type",
  customers: "customers",
  orders: "orders",
  ordersAndTransactions: "ordersAndTransactions",
  redemptions: "redemptions",
  sales: "sales",
  ordersTitle: "ordersTitle",
  feedbackTitle: "feedbackTitle",
  adsManagerTitle: "adsManagerTitle",
  insights_card_title: "insights_card_title",
  see_more: "see_more",
  less: "less",
  new: "new",
  home: "home",
  good_morning: "good_morning",
  good_evening: "good_evening",
  good_afternoon: "good_afternoon",
  overview: "overview",
  customer_visits: "customer_visits",
  track_your_orders_progress: "track_your_orders_progress",
  manage_customer_satisfaction: "manage_customer_satisfaction",
  increase_your_customer_base: "increase_your_customer_base",
  analyze_your_restaurant_data: "analyze_your_restaurant_data",
  avg_ticket_size: "avg_ticket_size",
  your_data_will_appear_here: "your_data_will_appear_here",
  most_of_your_customers_are: "most_of_your_customers_are",
  male_customer: "male_customer",
  female_customer: "female_customer",
  analyze_customers: "analyze_customers",
  view_order_details: "view_order_details",
  all_customers: "all_customers",
  app_customers: "app_customers",
  non_app_customers: "non_app_customers",
  search_branch_name: "search_branch_name",
  apply: "apply",
  reset: "reset",
  custom_time: "custom_time",
  all_time: "all_time",
  today: "today",
  yesterday: "yesterday",
  quarter_to_date: "quarter_to_date",
  last_year: "last_year",
  select_all: "select_all",
  offline_transaction: "offline_transaction",
  offline_redemption: "offline_redemption",
  offline_visit: "offline_visit",
  delivery: "delivery",
  pickup: "pickup",
  total: "total",
  customers_tab: "{{label}} {{percentage}}",
  sales_tab: "{{label}} {{percentage}}",
  redemptions_tab: "{{label}} {{percentage}}",
  orders_tab: "{{label}} {{percentage}}",
  males: "males",
  females: "females",
  age_group: "age_group",
  returning_customers_count: "returning_customers_count",
  new_customers_count: "new_customers_count",
  male_customers: "male_customers",
  female_customers: "female_customers",
  reviewed: "reviewed",
  redeemed: "redeemed",
  offline_order: "offline_order",
  delivery_order: "delivery_order",
  pickup_order: "pickup_order",
  ordered_through_web_portal: "ordered_through_web_portal",
  ordered_through_koinz_application: "ordered_through_koinz_application",
  ordered_through_visiting_the_restaurants:
    "ordered_through_visiting_the_restaurants",
  reviews: "reviews",
  see_live_updates_about_your_customers_interactions_here:
    "see_live_updates_about_your_customers_interactions_here",
  offline: "offline",
  online: "online",
  you_have_a_new_transaction: "you_have_a_new_transaction",
  show_more: "show_more",
  show_less: "show_less",
  more_items: "more_items",
  all: "all",
  here_is_store_name_process: "here_is_store_name_process",
  store_performance: "store_performance",
  last_date: "last_date",
  day: "day",
  days: "days",
  month: "month",
  months: "months",
  year: "year",
  years: "years",
  years_range: "years_range",
  cancel: "cancel",
  more_preset_ranges: "more_preset_ranges",
  avg_ticket_size_with_price: "avg_ticket_size_with_price",
  returning: "returning",
  customer_percentage_from_last_30_days:
    "customer_percentage_from_last_30_days",
  order_percentage_from_last_30_days: "order_percentage_from_last_30_days",
  fulfillment_percentage_from_last_30_days:
    "fulfillment_percentage_from_last_30_days",
  redemption_percentage_from_last_30_days:
    "redemption_percentage_from_last_30_days",
  sales_percentage_from_last_30_days: "sales_percentage_from_last_30_days",
  percentage_from_last_30_days: "percentage_from_last_30_days",
  online_orders: "online_orders",
  offline_orders: "offline_orders",
  all_orders: "all_orders",
  pickup_orders: "pickup_orders",
  delivery_orders: "delivery_orders",
  web_portal_orders: "web_portal_orders",
  offline_visits: "offline_visits",
  redemption_type: "redemption_type",
  order_type: "order_type",
  transaction_type: "transaction_type",
  customer_feedback: "customer_feedback",
  overall_rating: "overall_rating",
  unhappy_customers: "unhappy_customers",
  recent_reviews: "recent_reviews",
  branches_performance: "branches_performance",
  branch_name: "branch_name",
  fulfillment_rate: "fulfillment_rate",
  acceptance_rate: "acceptance_rate",
  time_to_accept: "time_to_accept",
  status: "status",
  top_branches: "top_branches",
  lowest_branches: "lowest_branches",
  on_track: "on_track",
  off_track: "off_track",
  analyze_branches: "analyze_branches",
  there_is_no_branches_available: "there_is_no_branches_available",
  this_year: "this_year",
  invoices: "invoices",
  customers_chart_tooltip: "customers_chart_tooltip",
  orders_chart_tooltip: "orders_chart_tooltip",
  redemptions_chart_tooltip: "redemptions_chart_tooltip",
  time_range_more_than_a_year_error: "time_range_more_than_a_year_error",
  seconds: "seconds",
  two_seconds: "two_seconds",
  two_minutes: "two_minutes",
  second: "second",
  minute: "minute",
  minutes: "minutes",
  hour: "hour",
  twoHours: "towHours",
  hours: "hours",
  one_day: "one_day",
  two_day: "two_day",
  days_duration: "days_duration",
  one_month: "one_month",
  two_month: "two_month",
  months_duration: "months_duration",
  year_duration: "year_duration",
  two_years_duration: "two_years_duration",
  years_duration: "years_duration",
  solveIssue: "solveIssue",
  netSales: "netSales",
};
