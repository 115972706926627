import { withLoadingReducer } from "../../../../Pickup/redux-store/reducers/withLoadingState";
import { on, reducer } from "ts-action";
import {
  clearBranchDataForEdit,
  getBranchDataForEditAction,
  getBranchDataForEditSuccess,
} from "./actions/branchFormActions";
import { IGetSingleBranch } from "../../../../../types/shared";
import { removeDeliveryZone } from "../../../../Pickup/redux-store/actions/edit-branch-zones-action";

interface IState {
  branchData?: IGetSingleBranch;
}

const initialState = { branchData: undefined };

export const getSingleBranchReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getBranchDataForEditSuccess, (state, { payload }) => ({
        branchData: payload,
      })),
      on(clearBranchDataForEdit, () => initialState),
      on(removeDeliveryZone, (state: any, { payload }) => {

        return {
          branchData: {
            ...state.branchData,
            delivery_zones: payload.delivery_zones,
          },
        };
      }),
    ] as any,
    initialState
  ),
  getBranchDataForEditAction
);
