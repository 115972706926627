import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";


export const reviewFraudlentCustomer = (phone_number: string, body: { is_reviewed: 0 | 1 }, token: string): AxiosReturn<any> => {
  return axios.post(Requests.reviewFraudlentCustomer(phone_number), body, {
    baseURL: process.env.REACT_APP_DASHBOARD_ENDPOINT,
    headers: {
      token,
    },

  });
};
