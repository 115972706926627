import menu from "./menu";
export default {
  addFromMenu: "Add from menu",
  customGift: "Add custom gift",
  giftName: "Gift Name",
  price: "Price",
  giftList: "Gift List",
  atLeast: "(Add at least 7 items)",
  giftItems: "Gift Items",
  progressAutoSaved: menu.progressAutoSaved,
  markComplete: menu.markComplete,
  addSpecialItemSuccess: '"{{name}}" added successfully to your gift list',
  addSpecialItemFailure: 'Failed to add "{{name}}" to your gift list',
  itemHasNoImageError:
    "Any item that is added to the gift list must have an image, please edit this item in the Menu interface",
};
