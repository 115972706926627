import * as React from "react";
import styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import disabledChipTheme from "./disabledchipTheme";
import chipTheme from "./chipTheme";
import Chips, { Chip } from "react-chips";
import themeDisabled from "./disabledTheme";
import theme from "./theme";
import TickIcon from "../../assets/tick.svg";
import InputErrorIcon from "../../assets/inputError.svg";

interface IChipData {
  name: string;
  id: string;
}

interface IProps extends WrappedFieldProps {
  options: IChipData[];
  label: string;
  disabled?: boolean;
  className?: string;
}

interface IState {
  suggestions: any[];
  allSuggestions: any[];
}

// export const onlyAll = [
//     {
//         name: 'All', id: 'all branches'
//     },
// ];

const extractName = ({ name }) => name;

export class RenderChipsField extends React.Component<IProps, IState> {
  public chipsRef;

  constructor(props: IProps) {
    super(props);
    this.state = {
      suggestions: props.options,
      allSuggestions: props.options,
    };
  }
  public renderChip = ({ name }) => (
    <Chip theme={this.props.disabled ? disabledChipTheme : chipTheme}>
      {name}
    </Chip>
  );

  public filterSuggestions = (opt, val) => {
    const isNotInSelected = !(this.props.input.value || []).find(
      (chip) => chip.id === opt.id
    );
    const isLikeSearch =
      opt?.name?.toLowerCase()?.indexOf(val.toLowerCase()) !== -1;
    const isInSuggestionState = !!this.state.suggestions.find(
      (chip) => chip.id === opt.id
    );
    return isNotInSelected && isLikeSearch && isInSuggestionState;
  };
  public onChange = (chips) => {
    const newSuggestions = this.state.allSuggestions.filter((suggestion) => {
      const isChipSelected = (selectedChip) =>
        suggestion.id === selectedChip.id;
      const chipIsSelected = chips.find(isChipSelected);
      return !chipIsSelected;
    });
    this.setState(
      {
        suggestions: newSuggestions,
      },
      () => {
        this.props.input.onChange(chips);
        this.chipsRef.onSuggestionsFetchRequested(this.chipsRef.state);
      }
    );
  };
  public handleDisableEnter = (e: React.KeyboardEvent) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.stopPropagation();
    }
  };

  public render() {
    const {
      input,
      label,
      className,
      disabled,
      meta: { error, valid, invalid, active, touched },
    } = this.props;
    const isInvalid = touched && invalid;
    const isValid = touched && valid;
    const handleBlur = () => {
      input.onBlur(input.value || []);
    };

    const handleFocus = () => {
      (document.querySelector(
        `.${styles.chipsWrapper} input`
      ) as HTMLInputElement).focus();
    };

    return (
      <div
        onFocusCapture={input.onFocus}
        onClick={handleFocus}
        onBlurCapture={handleBlur}
        className={`${styles.chipsFieldWrapper} ${className || ""}`}
      >
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
        </label>
        <div
          onClick={handleFocus}
          className={styles.chipsWrapper}
          onKeyUp={this.handleDisableEnter}
          style={{
            ...(active ? { borderColor: "#fdbb11" } : {}),
            ...(isValid ? { borderColor: "#38c172" } : {}),
            ...(isInvalid ? { borderColor: "#db3030" } : {}),
          }}
        >
          <Chips
            ref={(d) => {
              this.chipsRef = d;
            }}
            theme={disabled ? themeDisabled : theme}
            alwaysRenderSuggestions={!disabled}
            value={input.value || []}
            onChange={this.onChange}
            suggestions={this.state.suggestions}
            getChipValue={extractName}
            renderSuggestion={extractName}
            getSuggestionValue={extractName}
            renderChip={this.renderChip}
            suggestionsFilter={this.filterSuggestions}
          />
          {isValid && <img src={TickIcon} alt="tick-icon" />}
          {isInvalid && <img src={InputErrorIcon} alt="input-error-icon" />}
        </div>
        {isInvalid && (
          <p
            style={{
              margin: 0,
              padding: "1rem",
              color: "#db3030",
              fontSize: "1.2rem",
            }}
          >
            *{error}
          </p>
        )}
      </div>
    );
  }
}
