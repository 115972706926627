import { Filters } from "../..";
import { X } from "react-feather";
import { Button, Tag } from "antd";
import styles from "../styles.module.sass";
import { MouseEventHandler } from "react";
import moment from "moment";
import { useGetCurrency } from "../../../../../../../Utils";
import { useTranslation } from "../../../../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../../../../i18n/Manager/strings/insights";

interface IProps {
  filters: Filters;
  setFilterValue: Function;
  resetFilters: MouseEventHandler<HTMLElement>;
}

const customerTypes = {
  app_user: "App user",
  unregistered_user: "Unregistered user",
};

export const SelectedFilters: React.FC<IProps> = ({
  filters,
  setFilterValue,
  resetFilters,
}) => {
  const { t } = useTranslation("insights");
  const currency = useGetCurrency();

  const sortByMap = {
    online_orders_count: [t(Strings.online_orders)],
    points_operation_count: [t(Strings.visits)],
    current_points: [t(Strings.current_points)],
    life_time_points: [t(Strings.life_time_points)],
  };

  return (
    <div className={styles.selectedFilters}>
      <span className={styles.title}>{t(Strings.selectedFilters)}:</span>
      {filters.sort_by && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.sortBy)}: {sortByMap[filters.sort_by]}{" "}
            {filters.sort_order === "asc"
              ? `(${t(Strings.asc)})`
              : filters.sort_order === "desc"
              ? `(${t(Strings.desc)})`
              : null}{" "}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => {
              setFilterValue(["sort_by", "sort_order"], [undefined, undefined]);
            }}
          />
        </Tag>
      )}

      {(filters.average_ticket_size[0] !== 0 ||
        filters.average_ticket_size[1] !== 10000) && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.ticket_size)}: {filters.average_ticket_size[0]}{" "}
            {currency} - {filters.average_ticket_size[1]} {currency}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("average_ticket_size", [0, 10000])}
          />
        </Tag>
      )}

      {filters.branch_ids && filters.branch_ids?.length > 0 && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.branch)}: {filters.branch_ids.length}{" "}
            {t(Strings.branchesSelected)}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("branch_ids", [])}
          />
        </Tag>
      )}

      {/*  */}
      {filters.customer_type && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.customerType)}: {customerTypes[filters.customer_type]}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("customer_type", "")}
          />
        </Tag>
      )}

      {/*  */}
      {(filters.age[0] !== 16 || filters.age[1] !== 65) && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.customerAge)}: {filters.age[0]} - {filters.age[1]}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("age", [16, 65])}
          />
        </Tag>
      )}

      {/*  */}
      {filters.gender && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.customerGender)}: {filters.gender}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("gender", undefined)}
          />
        </Tag>
      )}

      {/*  */}
      {(filters.visits_count[0] !== 0 || filters.visits_count[1] !== 500) && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.totalVisits)}: {filters.visits_count[0]} -{" "}
            {filters.visits_count[1]}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("visits_count", [0, 500])}
          />
        </Tag>
      )}

      {/*  */}
      {(filters.total_points[0] !== 0 || filters.total_points[1] !== 50000) && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.totalPoints)}: {filters.total_points[0]} -{" "}
            {filters.total_points[1]}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("total_points", [0, 50000])}
          />
        </Tag>
      )}

      {/*  */}
      {filters.redemptions_count && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.redemptions)}: {filters.redemptions_count}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("redemptions_count", "")}
          />
        </Tag>
      )}

      {/*  */}
      {filters.last_visit_timestamp && (
        <Tag color="#fff" className={styles.tag}>
          <span>
            {t(Strings.lastVisit)}:{" "}
            {moment(filters.last_visit_timestamp[0]).format("DD MMM, YYYY")} -{" "}
            {moment(filters.last_visit_timestamp[1]).format("DD MMM, YYYY")}
          </span>
          <X
            size={15}
            className={styles.xIcon}
            onClick={() => setFilterValue("last_visit_timestamp", undefined)}
          />
        </Tag>
      )}

      {/*  */}
      <Button
        danger
        type="link"
        onClick={resetFilters}
        className={styles.resetAllButton}
      >
        {t(Strings.resetAll)}
      </Button>
    </div>
  );
};
