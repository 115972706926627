import * as React from "react";
import styles from  "./styles.module.sass";
import { hot } from "react-hot-loader";
import CustomerInformation, { OrderType } from "./CustomerInformation";
import BrandInfo from "./BrandInfo/index";
import {
  ICustomerInfo,
  IStore,
  IArea,
  ICountryForCustomer,
  IGovernment,
  IStoreAppInfo,
  IAddress,
} from "../../../../../../redux-store/actions/lib";

interface IOrderInfo {
  CustomerInfo: ICustomerInfo;
  BranchInfo: IStore;
  area: IArea;
  country: ICountryForCustomer;
  government: IGovernment;
  store: IStoreAppInfo;
  currency: string;
  address: IAddress;
  orderType: OrderType;
  customer_country: string;
  sub_district_ar_name: string;
  comment: string;
  delivery_time: number;
  sub_district_en_name: string;
  district_ar_name: string;
  district_en_name: string;
  city_en_name: string;
  delivery_fees: number;
}

const OrderInfo: React.FC<IOrderInfo> = (props) => {
  const {
    orderType,
    BranchInfo,
    address,
    CustomerInfo,
    area,
    country,
    currency,
    government,
    store,
    district_ar_name,
    district_en_name,
    sub_district_ar_name,
    customer_country,
    sub_district_en_name,
    comment,
    delivery_fees,
    city_en_name,
    delivery_time,
  } = props;
  return (
    <div
      className={
        orderType === OrderType.delivery ? styles.wrapper : styles.pickupWrapper
      }
    >
      <CustomerInformation
        address={address}
        city_en_name={city_en_name}
        customer_country={customer_country}
        CustomerInfo={CustomerInfo}
        district_ar_name={district_ar_name}
        district_en_name={district_en_name}
        sub_district_ar_name={sub_district_ar_name}
        sub_district_en_name={sub_district_en_name}
        area={area}
        country={country}
        orderType={orderType}
        government={government}
        comment={comment}
      />
      <div className={styles.separator} />
      <BrandInfo
        currency={currency}
        brandInfo={BranchInfo}
        store={store}
        orderType={orderType}
        delivery_time={delivery_time}
        delivery_fees={delivery_fees}
      />
    </div>
  );
};

export default hot(module)(OrderInfo);
