export default {
  zonesUploading: "Upload Delivery Zones",
  downloadZonesTemplate: "Download zones template",
  uploadExcelZones: "Upload the Excel sheet that includes your zones",
  note:
    "Note: All previous zones in all branches will be removed and replaced with the excel zones",
  verify: "Verify",
  addZones: "Add zones",
  firstStep: "First step:",
  secondStep: "Second step:",
  uploadZonesByExcel: "Upload zones by excel",
  cancel: "Cancel",
  downloadExcelZones:
    "Download the Excel zones template below and add your zones to it ",
  added: "Added",
};
