import React from "react";

interface IProps {
  clicked: boolean;
}
export const InsightsIcon: React.FC<IProps> = ({ clicked }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={clicked ? "#FFA351" : "#AEAEB2"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 16.667V8.333M10 16.667V3.333M5 16.666v-5"
      />
    </svg>
  );
};

export default InsightsIcon;
