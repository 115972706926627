import { useContext } from "react";
import { useQuery } from "react-query";
import { getTodaysTotalSalesReq } from "../../../axios/Manager/Analytics";
import StoreData from "../../../contexts/StoreData";

export const useGetTodaysTotalSales = () => {
  const { token } = useContext(StoreData);
  return useQuery(
    ["todays-total-sales"],
    async () => await getTodaysTotalSalesReq(token),
    {
      retry: 3,
      enabled: !!token,
    }
  );
};
