import axios from "./axios";
import * as Requests from "./requests";

export const getAllBrandCategory = (token: string) => {
  return axios.get(Requests.GET_ALL_BRAND_CATEGORIES_API, {
    headers: {
      token,
    },
    baseURL: process.env.REACT_APP_DASHBOARD_ENDPOINT,
  });
};
