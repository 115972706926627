import { call, put, select, takeLatest } from "redux-saga/effects";
import { editCompetitionAction } from "../../constants";
import { selectAddMore, selectToken } from "../selectors";
import { editCompetitionFailure, editCompetitionSuccess } from "../actions";
import { IEditCompetitionAction } from "../../types";
import { notify } from "react-notify-toast";
import history from "../../helpers/history";
import { editCompetition } from "../../axios/editCompetition";
import {
  areGiftFormsValid,
  getGiftsData,
  touchGiftForms,
} from "./createCompetitionSaga";
import Strings from "../../i18n/strings/leaderboard";
import { translateLeaderboard } from "../../i18n/i18n";

function* editCompetitionSaga(action: IEditCompetitionAction) {
  try {
    const token: string = yield select(selectToken);
    const addMore: string = yield select(selectAddMore);
    const formsLength = addMore ? 5 : 3;
    const shouldCreate = yield* areGiftFormsValid(formsLength);
    if (!shouldCreate) {
      yield* touchGiftForms(formsLength);
      notify.show(
        translateLeaderboard(Strings.errorEnterSpecifiedNumberOfGifts),
        "error"
      );
      throw new Error("Forms are invalid");
    }
    const gifts = yield* getGiftsData(formsLength);
    const data: any = {
      id: action.payload.id,
      gifts,
    };
    yield call(editCompetition, data, token);
    yield put(editCompetitionSuccess());
    notify.show(
      translateLeaderboard(Strings.successCompetitionEdited),
      "success"
    );
    yield call(history.push as any, `/`);
  } catch (e) {
    yield put(editCompetitionFailure(e));
    notify.show(translateLeaderboard(Strings.somethingWentWrong), "error");
  }
}

export function* watchEditCompetitionSaga() {
  yield takeLatest(editCompetitionAction.requested, editCompetitionSaga);
}
