import { reducer, on } from "ts-action";
import {
  withLoadingReducer,
  ILoadingState,
  LoadingStatus,
} from "./withLoadingState";
import { getOrderingItemsAction } from "../../constants/actions";
import {
  getOrderingItemsSuccess,
  createOrderingItem,
  createOrderingItemSuccess,
  createOrderingItemFailure,
  reOrderItem,
  deleteOrderingItem,
  editOrderingItem,
  changeItemCategory,
  editOrderingItemSuccess,
} from "../actions/itemActions";
import { IItem } from "../../lib";
import { updateItemOrdering } from "../../../../redux-store/reducers/menuReducer";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { clearPrevState } from "../../../../redux-store/actions";
import { deleteOrderingMenuSuccess } from "../actions";
interface IState {
  items: string[];
  itemsById: { [x: string]: IItem };
  loading?: LoadingStatus;
}

const initialState: IState = {
  items: [],
  itemsById: {},
  loading: LoadingStatus.loading,
};

const formatItems = (items: IItem[]) => {
  return {
    items: items.map((i) => i.id),
    itemsById: items.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item,
      }),
      {}
    ),
  };
};

export const orderingMenuItemsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(deleteOrderingMenuSuccess, (state, { }) => ({
          ...state,
          items: [],
          itemsById: {}
        })),
        on(clearPrevState, (state, { }) => ({
          ...state,
          loading: undefined,
        })),
        on(getOrderingItemsSuccess, (state, { payload }) => {
          const sortedOrders = payload.length
            ? payload
              .sort((a, b) => a.order - b.order)
              .map((order, i) => ({ ...order, order: i + 1 }))
            : [];
          return formatItems(sortedOrders);
        }),
        on(createOrderingItem, (state, { payload, meta }) => {
          const metaId = String(meta.optimistic.id);

          return {
            ...state,
            items: [...state.items, metaId],
            itemsById: {
              ...state.itemsById,
              [meta.optimistic.id]: {
                ...payload,
                id: metaId,
                order: state.items.length + 1,
              },
            },
            loading: LoadingStatus.loading,
          };
        }),
        on(createOrderingItemSuccess, (state, { meta, payload }) => {
          const metaId = String(meta.optimistic.id);
          delete state.itemsById[metaId];
          return {
            ...state,
            items: state.items.map((item) =>
              metaId === item ? payload.id : item
            ),
            itemsById: {
              ...state.itemsById,
              [payload.id]: payload,
            },
            loading: LoadingStatus.success,
          };
        }),
        on(reOrderItem, (state, { payload }) => {
          const oldItemData = state.itemsById[payload.id];

          const data = updateItemOrdering(
            state.items.map((id) => state.itemsById[id]),
            { ...oldItemData, order: payload.newOrder },
            oldItemData
          );

          return {
            ...state,
            ...formatItems(data),
          };
        }),
        on(deleteOrderingItem, (state, { payload }) => {
          return {
            ...state,
            items: state.items.filter((item) => item !== payload),
          };
        }),
        on(changeItemCategory, (state, { payload }) => {
          return {
            ...state,
            items: state.items.filter((item) => item !== payload.itemId),
          };
        }),
        on(editOrderingItemSuccess, (state, { payload }) => {
          return {
            ...state,
            itemsById: {
              ...state.itemsById,
              [payload.id]: payload,
            },
            loading: LoadingStatus.success,
          };
        }),
        on(editOrderingItem, (state, { payload }) => {
          const oldItemData = state.itemsById[payload.id];
          return {
            ...state,
            itemsById: {
              ...state.itemsById,
              [payload.id]: {
                ...oldItemData,
                ...payload,
                image:
                  payload.image === undefined
                    ? oldItemData.image
                    : payload.image,
              },
            },
            loading: LoadingStatus.loading,
          };
        }),
      ],
      initialState
    ),
    getOrderingItemsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
