import * as constants from "../../constants";
import {
  IDeleteCompetitionFailureAction,
  IDeleteCompetitionSuccessAction,
  IDeleteCompetitionAction,
} from "../../types";

export const deleteCompetition = (id: string): IDeleteCompetitionAction => ({
  type: constants.deleteCompetitionAction.requested,
  payload: id,
});

export const deleteCompetitionSuccess = (
  id: string
): IDeleteCompetitionSuccessAction => ({
  type: constants.deleteCompetitionAction.fulfilled,
  payload: id,
});

export const deleteCompetitionFailure = (
  error: Error
): IDeleteCompetitionFailureAction => ({
  type: constants.deleteCompetitionAction.rejected,
  payload: error,
});
