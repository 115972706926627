import React from "react";
import styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { MutationStatus } from "react-query";
import { ClipLoader } from "react-spinners";

interface IProps {
  onClose: () => void;
  open: boolean;
  deletePointsOperations: () => void;
  status: MutationStatus;
}

export const DeleteOperationPopup: React.FC<IProps> = ({
  open,
  onClose,
  deletePointsOperations,
  status,
}) => {
  return (
    <Modal open={open} className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.closeIcon}>
          <img
            onClick={onClose}
            className={styles.close}
            src={require("../../../../../assets/close-icon.svg").default}
          />
        </div>
        <img
          className={styles.warningIcon}
          src={require("../../../../../assets/warning-icon.svg").default}
        />
        <span className={styles.deleteOperationTitle}>Delete Operation</span>
        <span className={styles.deleteOperationDescription}>
          Are you sure you want to delete this operation ?
        </span>
        <div className={styles.footer}>
          <button onClick={onClose} className={styles.cancelBtn}>
            Cancel
          </button>
          <button
            onClick={deletePointsOperations}
            disabled={status === "loading"}
            className={styles.deleteBtn}
          >
            {status === "loading" ? (
              <ClipLoader color={"#fff"} size={24} />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteOperationPopup;
