import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../selectors";
import { updatePosEntityApi } from "../../../axios/pos";
import {
  updatePosEntity,
  updatePosEntityFailure,
  updatePosEntitySuccess,
} from "../../actions";
import { updatePosEntityAction } from "../../../constants";
import { union } from "ts-action";
import { customNotify } from "../../../Components/Pickup/SharedComponent/Notification";
import Strings from "../../../Components/Pickup/i18n/strings/progressSettings"
import { translate } from "../../../helpers/translate";
const t = translate("progressSettings");
const actionTypes = union({ updatePosEntity });
function* updatePosEntitySaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(updatePosEntityApi, token, payload);
    yield put(updatePosEntitySuccess(res.data.pos));
    customNotify.success(t(Strings.posSettingsUpdatedSuccessfully));
  } catch (error) {
    customNotify.error(t(Strings.somethingWentWrong));
    yield put(updatePosEntityFailure(error as Error));
  }
}

export function* watchUpdatePosEntitySaga() {
  yield takeLatest(updatePosEntityAction.requested, updatePosEntitySaga);
}
