import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { ICountry } from "../types";

export const getCountries = (token): AxiosReturn<{ countries: ICountry[] }> => {
  return axios.get(Requests.getCountries, {
    headers: {
      token,
    },
  });
};
