import * as React from "react";

export interface ITabItemProps {
  title: React.ReactChild;
  children: React.ReactElement<any>;
}

export const TabItem: React.SFC<ITabItemProps> = ({ children }) => children;
export type TabItem = typeof TabItem;
export default TabItem;
