import { call, put, select, takeLatest } from "redux-saga/effects";
import { editLoyaltySpecialItemAction } from "../../../constants";
import { selectToken } from "../../selectors";
import { union } from "ts-action";
import {
  editLoyaltySpecialItem,
  editLoyaltySpecialItemFailure,
  editLoyaltySpecialItemSuccess,
} from "../../actions";
import { editLoyaltySpecialItemApi } from "../../../axios";

const actionTypes = union({
  editLoyaltySpecialItem,
});
function* editLoyaltySpecialItemSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    yield call(editLoyaltySpecialItemApi, token, payload);
    yield put(editLoyaltySpecialItemSuccess(payload));
  } catch (error) {
    yield put(editLoyaltySpecialItemFailure(error as Error));
  }
}

export function* watchEditLoyaltySpecialItemSaga() {
  yield takeLatest(
    editLoyaltySpecialItemAction.requested,
    editLoyaltySpecialItemSaga
  );
}
