import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteCategoryAction } from "../../constants";
import { IDeleteCategoryAction } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { deleteCategory } from "../../axios/deleteCategory";

function* deleteCategorySaga({ payload }: IDeleteCategoryAction) {
  try {
    const token = yield select(selectToken);
    yield call(deleteCategory, token, payload.id);
    yield put(actions.deleteCategorySuccess());
  } catch (e) {
    yield put(actions.deleteCategoryFailure(payload));
  }
}

export function* watchDeleteCategorySaga() {
  yield takeLatest(deleteCategoryAction.requested, deleteCategorySaga);
}
