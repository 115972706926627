import React, { useMemo, useState } from "react";
import styles from  "./styles.module.sass";
import { Collapse, CardBody, Card, Tooltip } from "reactstrap";
import { INote } from "../../../../axios/getNotes";
import { useToggleState, useLocalizedMoment } from "../../../../Utils";
import Icon from "@material-ui/core/Icon/Icon";
import { InjectedTranslateProps } from "react-i18next";
import { translate } from "react-i18next";
import Strings from "../../../../i18n/strings/reviews";
import { useRtlClass } from "../../../../lib";

interface IProps extends InjectedTranslateProps {
  note: INote;
}

export const useNameInitials = (name?: string) => {
  return useMemo(() => {
    const hasMoreThanAscii = [...(name || "")].some(
      (char) => char.charCodeAt(0) > 127
    );
    const joinBy = hasMoreThanAscii ? " " : "";
    return (name || "")
      .split(" ")
      .map((a) => a[0])
      .join(joinBy)
      .toUpperCase();
  }, [name]);
};

const Note: React.FC<IProps> = ({ note, t }) => {
  const [collapse, toggleCollapse] = useToggleState();
  const managerName = note.manager_name || "anonymous";

  const duration = 400;

  const defaultStyle = {
    transition: `all ${duration}ms ease`,
    opacity: 0,
  };
  const transitionStyles = {
    false: { opacity: 0 },
    true: { opacity: 1 },
  };
  const notedOn = useLocalizedMoment(note.created_at, "x").format(
    "DD MMM, YYYY"
  );
  const managerInitials = useNameInitials(managerName);
  const rtlClass = useRtlClass(styles);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className={rtlClass}>
      <button className={styles.btn} onClick={toggleCollapse}>
        <p className={styles.fp}>
          {t(Strings.noteDateTitle)}:{" "}
          <span className={styles.date}>{notedOn}</span>
        </p>
        <div className={styles.sp}>
          <div id={`tt${note.id}`} className={styles.manager}>
            {managerInitials}
          </div>
          <Tooltip
            style={{ fontSize: "1.8rem" }}
            placement="top"
            target={`#tt${note.id}`}
            toggle={toggleToolTip}
            isOpen={tooltipOpen}
          >
            {managerName}
          </Tooltip>
          <Icon className={`${collapse ? styles.arrowC : styles.arrowO}`}>
            {t(Strings.noteArrow)}
          </Icon>
        </div>
      </button>
      <Collapse
        style={{
          ...defaultStyle,
          ...transitionStyles[collapse ? "true" : "false"],
        }}
        in={collapse}
        timeout={duration}
        className={styles.body}
        isOpen={collapse}
      >
        <Card style={{ background: "#fafafa", border: 0 }}>
          <CardBody style={{ padding: 0 }}>
            <div className={styles.wrapper}>{note.description}</div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default translate("reviews")(Note);
