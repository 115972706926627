import { call, put, select, takeLatest } from "redux-saga/effects";
import { reviewFraudlentCustomer } from "../../constants";
import { selectToken } from "../selectors";
import { reviewFraudlentCustomer as apiCall } from "../../axios/reviewFraudlentCustomer";
import { reviewFraudlentCustomerSuccess, reviewFraudlentCustomerFailure } from "../actions/edit-fraud-list-actions";

function* reviewFraudCustomerSaga(action) {
  try {
    const token = yield select(selectToken);
    const payload = action.payload;
    const response = yield call(apiCall, payload.phone_number, payload.body, token)
    yield put(
      reviewFraudlentCustomerSuccess({ phone_number: payload.phone_number, is_reviewed: payload.body.is_reviewed })
    );
  } catch (e: unknown) {
    yield put(reviewFraudlentCustomerFailure(e as Error));
  }
}

export function* watchReviewFraudCustomerSaga() {
  yield takeLatest(reviewFraudlentCustomer.requested, reviewFraudCustomerSaga);
}
