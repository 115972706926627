import { useContext, useState } from "react";
import { getCriticalNumbers } from "../axios/getCriticalNumbers";
import StoreData from "../contexts/StoreData";
import ReviewsFilters from "../contexts/ReviewsFilters";
import {
  createCancellablePromise,
  useUpdatedAt,
  useCancelledPromise,
} from "../Utils";
import { useLocalStorage } from "./useLocalStorage";

export const useGetCriticalReviewsNumber = (applyFilters = true) => {
  const { token } = useContext(StoreData);
  const { update, updatedTime } = useUpdatedAt();
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "failure"
  >("idle");
  const [criticalNumbers, setCriticalNumbers] = useLocalStorage(
    "criticalNumbers",
    0
  );
  const { generalFilters } = useContext(ReviewsFilters);
  const effect = () => {
    return createCancellablePromise(
      getCriticalNumbers(token, applyFilters ? generalFilters : {})
    )
      .then((res) => {
        if (res.status === 200 && res.data) {
          setStatus("success");
          setCriticalNumbers(res.data.critical_reviews_number);
        }
      })
      .catch((err) => {
        setStatus("failure");
      });
  };
  const constantDeps = [token, updatedTime];
  useCancelledPromise(
    effect,
    applyFilters ? [generalFilters, ...constantDeps] : constantDeps
  );
  return {
    criticalNumbers,
    update,
    status,
  };
};
