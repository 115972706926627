import { take, put, race } from "redux-saga/effects";
import {
  editBranchZones,
  editBranchZonesFailure,
  editBranchZonesSubmitted,
  removeDeliveryZone,
} from "../actions/edit-branch-zones-action";
import { editBranchAction } from "../../../../constants";
import {
  notifyZones,
  closeAllNotices,
} from "../../../WizardComponents/Branches/AddBranchForm/CustomNotify";
import i18n from "../../../../i18n/i18n";
import Strings from "../../../../i18n/strings/branches";
import { IZoneItem } from "../../../WizardComponents/Branches/AddBranchForm/lib";

export function* editBranchZonesFormSaga() {
  while (true) {
    const action = yield take(editBranchZones.type);
    const zones = action.payload.zones as IZoneItem[];
    const notify: typeof notifyZones = (str, type, time) => {
      notifyZones(
        i18n.t(str, {
          ns: "branches",
          count: zones.length,
        }),
        type,
        time
      );
    };
    notify(Strings.addBranchZonesLoading, "loading");
    const { editBranchSuccess } = yield race({
      editBranchSuccess: take(editBranchAction.fulfilled),
      editBranchFailure: take(editBranchAction.rejected),
    });
    closeAllNotices();
    if (editBranchSuccess) {
      yield put(removeDeliveryZone(action.payload));
      yield put(editBranchZonesSubmitted());
      notify(Strings.addBranchZonesSuccess, "success", 10);
    } else {
      notify(Strings.addBranchZonesFailure, "error", 15);
      yield put(editBranchZonesFailure());
    }
  }
}
