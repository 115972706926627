import { call, put, select, takeLatest } from "redux-saga/effects";
import { getLoyaltyMenuAction } from "../../../constants";
import { selectToken } from "../../selectors";
import { getStoreItems } from "../../../axios/getStoreItems";
import { ICataloguedItems } from "../../../types/wizard-types";
import { getLoyaltyMenuFailure, getLoyaltyMenuSuccess } from "../../actions";

function* getLoyaltyMenuSaga() {
  try {
    const selectedToken = yield select(selectToken);
    const storeItemsRes = yield call(getStoreItems, selectedToken);
    const menu = storeItemsRes.data as ICataloguedItems[];
    yield put(getLoyaltyMenuSuccess(menu.filter((item) => item.items.length)));
  } catch (e) {
    yield put(getLoyaltyMenuFailure(e as Error));
  }
}

export function* watchGetLoyaltyMenuSaga() {
  yield takeLatest(getLoyaltyMenuAction.requested, getLoyaltyMenuSaga);
}
