import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteStoreItemAction } from "../../constants";
import { IDeleteStoreItemAction } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { deleteStoreItem } from "../../axios/deleteStoreItem";

function* deleteStoreItemSaga(action: IDeleteStoreItemAction) {
  try {
    const token = yield select(selectToken);
    yield call(deleteStoreItem, token, action.payload.id);
    yield put(actions.deleteStoreItemSuccess(action.payload));
  } catch (e) {
    yield put(actions.deleteStoreItemFailure(action.payload));
  }
}

export function* watchDeleteStoreItemSaga() {
  yield takeLatest(deleteStoreItemAction.requested, deleteStoreItemSaga);
}
