import isEqual from "lodash/isEqual";
import transform from "lodash/transform";
import isObject from "lodash/isObject";
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object: any, base: any) => {
  function changes(object1: object, base1: { [x: string]: any; }) {
    return transform(object1, (result: any, value, key) => {
      if (!isEqual(value, base1[key])) {
        result[key] =
          isObject(value) && isObject(base1[key])
            ? changes(value, base1[key])
            : value;
      }
    });
  }
  return changes(object, base);
};
