import { reducer, on } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  IOrderingPortalLink,
  getOrderingPortalLinkSuccess,
} from "../actions/ordering-portal-link-action";
import { getOrderingPortalLinkAction } from "../../constants/actions";

export interface IState {
  web_portal_link: string;
}

const initialState: IState = {
  web_portal_link: "",
};

export const orderingPortalLinkReducer = withLoadingReducer(
  reducer<IOrderingPortalLink>([
    on(getOrderingPortalLinkSuccess, (state: IState, { payload }) => ({
      ...state,
      web_portal_link: payload.web_portal_link,
    })),
  ] as any, initialState),
  getOrderingPortalLinkAction
);
