import crs from "./convertingRatio";
import obs from "./onBoardingSteps";
export default {
  somethingWentWrong: crs.somethingWentWrong,
  next: obs.next,
  back: obs.back,
  uploading: "Menu upload in progress",
  menuProviderLinkLabel: "Enter your brand URL on {{menuProvider}}",
  grabMenu: "Grab your menu",
  addingFromProviderTitle: "Adding your menu using {{menuProvider}}",
  addingFromProviderSubtitle:
    "Find your brand on {{menuProvider}} website, and copy the URL from your browser's address bar",
};
