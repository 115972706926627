export default {
  title: "Advanced Settings",
  allowReceiptIdTitle: "Allow Receipt ID",
  allowReceiptIdDescription:
    "This will allow the cashier to enter the corresponding receipt id of each transaction for tracking purposes",
  allowPointsExpirationTitle: "Allow Points Expiration",
  allowPointsExpirationDescription:
    "This will allow points expiration so that the points expire in a period of time you specify",
  customerExpirationPeriodTitle: "Customers points will expire in",
  allowHideMenuItemsTitle: "Enable hiding menu items",
  allowHideMenuItemsDescription:
    "You can hide certain menu items frm the customer's menu",
  blockedUsers: "Blocked Users",
  blockedUsersDescription:
    "Blocked users won’t be able to receive or redeem points from your brand.",
  days: "{{count}} day",
  days_plural: "{{count}} days",
  months: "{{count}} month",
  months_plural: "{{count}} months",
  years: "{{count}} year",
  years_plural: "{{count}} years",
  confirm: "Confirm",
  cancel: "Cancel",
  uninstallPointOfSale: "Uninstall Point of sale (POS)",
  ordering: "Ordering",
  loyalty: "Loyalty",
  "Uninstall POS Loyalty": "Uninstall POS Loyalty",
  "Uninstall POS Ordering": "Uninstall POS Ordering",
  "Do you want to uninstall loyalty pos integration":
    "Do you want to uninstall loyalty pos integration",
  doYouWantToUninstallOrderingPosIntegration:
    "Do you want to uninstall Ordering pos integration",
};
