import { call, put, select, takeLatest } from "redux-saga/effects";
import { getCategoriesAction } from "../../constants";
import { ICategory } from "../../types/wizard-types";
import { selectToken } from "../selectors";
import * as actions from "../actions";
import { getCategories } from "../../axios/getCategories";

function* getCategoriesSaga() {
  try {
    const selectedToken = yield select(selectToken);
    const categoriesRes = yield call(getCategories, selectedToken);
    const categories = categoriesRes.data as ICategory[];
    yield put(actions.getCategoriesSuccess(categories));
    yield put(actions.getStoreItems());
  } catch (e) {
    yield put(actions.getCategoriesFailure(e));
  }
}

export function* watchGetCategoriesSaga() {
  yield takeLatest(getCategoriesAction.requested, getCategoriesSaga);
}
